import React from "react"
import classes from "./PeriodList.module.css"

const PeriodListClient = ({periods , onClick})=>{
    return(
    <div style={{height : 'calc(100vh - 120px)'}} className="d-flex flex-row flex-wrap pt-2 w-100 justify-content-around align-content-start overflow-auto">
        {
            !!periods &&
            periods.map(item =>(
            <div 
                className={`d-flex flex-column  p-3 px-1 ${classes.card}`}
                style={{minHeight : '220px'}}
                onClick={()=>item.isActive && onClick(item)}
            >
                {
                    !item.isActive &&
                    <div className={`d-flex align-items-center justify-content-center ${classes.alert}`}>
                        <span className={`rtl ${classes.alertTxt}`}>
                            {
                                item.capacityString
                            }
                        </span>
                    </div>
                }
                <div className={`d-flex flex-column ${!item.isActive?classes.deactive : ""}`}>
                    <span className="text-right w-100">
                        {
                            item.title
                        }
                    </span>
                    <span className="mt-2 text-right">
                        {
                            item.description
                        }
                    </span>
                    <span className="mt-2 text-right">
                        {
                            "ساعت شروع :   " + item.startTime
                        }
                    </span>
                    <span className="mt-2 text-right">
                        {
                            "ساعت پایان :   " + item.endTime
                        }
                    </span>
                    <span className="mt-2 text-right">
                        {
                            "ظرفیت :   " + item.capacityString
                        }
                    </span>
                    <span className="mt-2 text-right">
                        {
                            "قیمت :   " + item.priceString
                        }
                    </span>
                </div>
            </div>
            
            ))

        }
        </div>
    )
    

}

export default PeriodListClient
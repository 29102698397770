import React, { Component } from 'react';
import { ApiService } from '../../value';
import RuleCard from '../Cards/RuleCard/RuleCard';



let Self = null;
class MyLazyLoad extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            list: [],
            page: 2,
            prevY: 0,
            loading: false

        }
    }



    componentDidMount() {
        if (this.state.page == 1) {
            this.getList(this.state.page);
        }
        // this.getMesssages(this.state.pageCount,this.state.page);

        var options = {
            root: null, // Page as root
            rootMargin: '0px',
            threshold: 0.9
        };
        // Create an observer
        this.observer = new IntersectionObserver(this.handleObserver.bind(this), options);
        //Observ the `loadingRef`
        this.observer.observe(this.loadingRef);
    }


    handleObserver(entities, observer) {
        const y = entities[0].boundingClientRect.y;
        console.log('gholihgooi',this.props.isfirst());
        
        if (this.props.isfirst(false)||(this.state.prevY > y && this.state.page > 1 && !this.state.finished)) {
            this.getList(this.state.page);
            console.log(this.props.isfirst(),'ggggggggggggggggggggggggggggggggggggggggggggggggggggggggholi');
        }
        this.setState({ prevY: y });
    }

    getList(PageNumber) {
        this.setState({
            loading: true
        })
        this.props.getitemlist( parseInt(this.props.itemlist.length/10)+1) .then(response => {

                if (response.data.result) {

if(this.props.itemlist.length==10)
{
    console.log(this.props.isfirst(),'ggggggggggggggggggggggggggggggggggggggggggggggggggggggggholi');
this.setState({page:2,finished: false  });

                            }
                                            else


                    this.setState({page:this.state.page+1});
                    console.log(response.data.data.length);
                    this.props.isfirst(false)

                        if(response.data.data.length<10){
                            this.setState({
                                finished: true
                            })
                        }
                    

                } else {
                
                        this.setState({
                            finished: true
                        })
                
                
                }

            })
            .catch(err => {
                this.setState({
                    finished: true
                })
                return
            })

    }


    render() {
        return (

            <div style={this.props.style}>
                {this.props.children}
                <div ref={loadingRef => (this.loadingRef = loadingRef)}>
                    <div className={`text-center directionRtl`} style={{ display:this.state.finished&&!this.props.itemlist.length==10? 'none':'block'} }>
                            لطفا صبر کنید...
                
                    </div>
                </div>
            </div>
        )
    }
}


export default MyLazyLoad;







// import React, { Component } from 'react';
// import { ApiService } from '../../value';
// import RuleCard from '../Cards/RuleCard/RuleCard';



// let Self = null;
// class MyLazyLoad extends Component {
//     constructor(props) {
//         super(props);
//         Self = this;
//         this.state = {
//             list: [],
//             page: 1,
//             prevY: 0,
//             loading: false

//         }
//     }



//     componentDidMount() {
//         if (this.state.page == 1) {
//             this.getList(this.state.page);
//         }
//         // this.getMesssages(this.state.pageCount,this.state.page);

//         var options = {
//             root: null, // Page as root
//             rootMargin: '0px',
//             threshold: 0.9
//         };
//         // Create an observer
//         this.observer = new IntersectionObserver(this.handleObserver.bind(this), options);
//         //Observ the `loadingRef`
//         this.observer.observe(this.loadingRef);
//     }


//     handleObserver(entities, observer) {
//         const y = entities[0].boundingClientRect.y;
//         if (this.state.prevY > y && this.state.page > 1 && !this.state.finished) {
//             this.getList(this.state.page);
//         }
//         this.setState({ prevY: y });
//     }

//     getList(PageNumber) {
//         this.setState({
//             loading: true
//         })

//         let data = {
//             pageSize: 10,
//             pageNumber: PageNumber,
//             nameLike: ''
//         }


//         ApiService('Post', `/BuildingRule/GetList/${localStorage.getItem('id')}`, data)
//             .then(response => {

//                 if (response.data.result) {
//                     console.log(response.data.data.length);
//                     if (response.data.data.length > 0 ) {
                      
//                         this.setState({
//                             list: [...this.state.list, ...response.data.data],
//                             page: this.state.page + 1,
//                             loading: false
//                         })

//                         if(response.data.data.length<10){
//                             this.setState({
//                                 finished: true
//                             })
//                         }
//                     }

//                 } else {

//                 }

//             })
//             .catch(err => {

//                 return
//             })

//     }


//     render() {
//         return (<div>

//             <div style={{ height: '20vh', overflow: 'auto', border: '1px solid violet' }}>
//                 {this.state.list.map(item => {
//                     return <RuleCard key={item.id} title={item.title}
//                         description={item.description} date={item.createdDate}
//                         creator={item.creator.name} img={item.creator.profileImageUrl} click={() => {
//                             this.props.history.push({
//                                 pathname: '/rules/View',
//                                 state: {
//                                     id: item.id
//                                 }
//                             })
//                         }} />
//                 })}
//                 <div ref={loadingRef => (this.loadingRef = loadingRef)}>
//                     <div style={{ display: 'block' }}>

//                         <div className={`text-center directionRtl`}>
//                             لطفا صبر کنید...
//                 </div>
//                     </div>
//                 </div>
//             </div>


//         </div>)
//     }
// }


// export default MyLazyLoad;
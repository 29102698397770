import React from 'react';






const ReceiptState = props => {
    return (
        <div className={'p-1 text-light text-center fntBold'} 
         style={{width:'100%',fontSize:10,
             backgroundColor: 
             props.state===1? '#FFAB1B':
                props.state===0 ? '#e33742 ' : 
                props.state===4  ? '#B1E337' :
                props.state===3  ?' #b8c0c5' : '#8a90dc', borderRadius:10}}>
                 {props.state===1? 'در انتظار تایید':
                  props.state ===0 ? 'پرداخت نشده' :
                  props.state ===4 ?  'تایید شده' :
                  props.state === 3 ?  'پرداخت ناقص' 
                  : '  در حال پرداخت' }</div>
    );
};

export default ReceiptState;
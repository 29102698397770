import './App.css';
import Home from './Container/Home/Home';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,


} from 'react-router-dom';
import Login from './Container/Auth/Login/Login';
import Building from './Container/Building/Building';
import Calend from './Container/Calend/Calend';
import AddBuilding from './Container/Building/AddBuilding/AddBuilding'
import AddBuildingMap from './Container/Building/AddBuildingMap/AddBuildingMap';
import EditBuilding from './Container/Building/EditBuilding/EditBuilding';
import EditBuildingMap from './Container/Building/EditBuilding/mapEdit';
import Unit from './Container/Unit/Unit';
import EditUnit from './Container/Unit/EditUnit/EditUnit';
import Rules from './Container/Rules/Rules';
import AddRule from './Container/Rules/AddRule/AddRule';
import RuleView from './Container/Rules/RuleView/RuleView';
import News from './Container/News/News';
import Event from './Container/Event/Event';
import Survey from './Container/Survey/Survey';
import AddEvent from './Container/Event/AddEvent/AddEvent';
import AddReport from './Container/Event/AddReport/AddReport';
import Receipt from './Container/Receipt/Receipt';
import Fund from './Container/Fund/Fund';
import AddDoc from './Container/Fund/AddDoc/AddDoc';
import FundSetting from './Container/Fund/FundSetting/FundSetting';
import DocView from './Container/Fund/DocView/DocView';
import ConfirmCode from './Container/Auth/ConfirmCode/ConfirmCode';
import ReceiptDetail from './Container/Receipt/ReceiptDetail/ReceiptDetail';
import AddReceipt from './Container/Receipt/AddReceipt/AddReceipt';
import PayPage from './Container/Payment/PayPage/PayPage';
import Wallet from './Container/Wallet/Wallet';
import Profile from './Container/Profile/Profile';
import AddSurvey from './Container/Survey/AddSurvey/AddSurvey';
import ProfileInfo from './Container/Auth/ProfileInfo/ProfileInfo';
// import Example from './Container/example/Example';
import SubmitVote from './Container/Survey/SubmitVote/SubmitVote';
import Chat from './Container/Chat/Chat';
import ReceiptSetting from './Container/Receipt/ReceiptSetting/ReceiptSetting';
import Question from './Container/Question/Question';
import Support from './Components/Support/Support';
import AboutUs from './Components/AboutUs/AboutUs';
import RetryPage from './Components/RetryPage/RetryPage';

import BuildingSetting from './Container/Building/BuildingSetting/BuildingSetting';
import Debtors from './Container/Debtors/Debtors';
import Deposits from './Container/Deposits/Deposits';
import ChatContainer from './Container/Chat/ChatContainer/ChatContainer';
import { useEffect, useState } from 'react';
import { registerUserForNotify } from './value';
import ChatContainerTest from './Container/Chat/ChatContainer/ChatContainerTest';
import Bills from './Container/Bills/Bills';
import AddBill from './Container/Bills/AddBills/AddBill';
import History from './Container/History/History';
import UserStatePage from './Container/Debtors/UserStatePage';
import MyLazyLoad from './Components/MyLazyLoad/MyLazyLoad';

import { HubConnectionBuilder } from '@microsoft/signalr';
import ChatTest from './Container/Chat/ChatTest';
import ServiceStepsWrapper from './Container/Services/ServiceStepsWrapper';
import SeviceProviderInfo from './Container/Services/SeviceProviderInfo';
import CLogin from './Container/Auth/CLogin/CLogin';
import Guid from './Components/Guid/Guid';
import ServiceProviderServiceInfo from './Container/Services/ServiceProviderServiceInfo';
import ServiceProviderClients from './Container/Services/ServiceProviderClients';
import { ProviderClientContextProvider } from './context/providerClients';
import Period from './Container/Services/Period';
import { ServiceContextProvider } from './context/serviceContext';

const App = props => {

  const [tokenApp, setTokenApp] = useState(null)
  const [gholi3, setgholi] = useState(null);
  const [seenMess, setSeenMess] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [badgCount, setBadgCount] = useState(0);


  useEffect(() => {
    if (localStorage.getItem('token')) {
      const connection = new HubConnectionBuilder()
        .withUrl('https://api2.homtick.com/homtickhub', { accessTokenFactory: () => `${localStorage.getItem('token')}` })
        .withAutomaticReconnect()
        .build();
        let ver=23;
        if(localStorage.getItem('ver')!=ver)
        {
          //  console.log('abbas way')
            localStorage.setItem('ver',ver);
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                registrations.forEach((registration) => {
                    registration.unregister();
                });
            });
            caches.keys().then((keyList) => {
                return Promise.all(
                    keyList.map((key) => {
                        return caches.delete(key);
                    })
                );
            });
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }

        connection.start()
        .then(result => {
          console.log('Connected!', result);

          connection.on('ReceiveMessage', message => {

            setgholi(message);
            console.log('>>>>>>>>>>>>>>>>>>>message');
            // const updatedChat = [...latestChat.current];
            // updatedChat.push(message);

            // setChat(updatedChat);
          });
          connection.on('SeenMessage', function (userId, messageId) {

            console.log(userId, messageId);
            setSeenMess({ user: userId, mesid: messageId })

          });
          connection.on('DeleteMessage', function (userId, messageId) {

            console.log(userId, messageId);
            setDeleteMessage({ user: userId, mesid: messageId })

          });
          connection.on('BageUpdate', cont => {

            console.log(cont, 'cont');
            setBadgCount(cont);

          });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
  }
    , []);

  const setBadge = (badge) => {
    setBadgCount(badge)

  }

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setTokenApp(localStorage.getItem('token'));




    }
  }, [])

  useEffect(() => {

    if (localStorage.getItem('token') && localStorage.getItem('profile')) { registerUserForNotify() }


  }, [tokenApp])
  console.warn = () => { };
  console.log = () => { };


  const token = localStorage.getItem('token');

  let routes;
  if (token) {
    routes = (
      <Switch>
        <Route path="/info" exact render={() => <ProfileInfo />} />

        <Route path="/" exact render={() => <Home badgCount={badgCount} />} />
        <Route path="/building/unit/editUnit" render={() => <EditUnit />} />
        <Route path="/building/unit" render={() => <Unit />} />
        <Route path="/building/setting" render={() => <BuildingSetting />} />
        <Route path="/building/add" render={() => <AddBuilding />} />
        <Route path="/building/add-1" render={() => <AddBuildingMap />} />
        <Route path="/building/edit" render={() => <EditBuilding />} />
        <Route path="/building/editMapBuilding" render={() => <EditBuildingMap />} />
        <Route path="/building" render={() => <Building />} />



        <Route path="/calc" render={() => <Calend />} />

        {/* <Route path="/editBuilding" render={() => <EditBuilding />} /> */}
        {/* <Route path="/editMapBuilding" render={() => <EditBuildingMap />} /> */}

        <Route path="/rules/add" render={() => <AddRule />} />
          <Route path="/rules/View" render={() => <RuleView />} />
          <Route path="/rules/:id" render={() => <RuleView />} />
        <Route path="/rules" render={() => <Rules />} />



        <Route path="/news" render={() => <News />} />
        <Route path="/event" render={() => <Event />} />
        <Route path="/addEvent" render={() => <AddEvent />} />
        <Route path="/addReport" render={() => <AddReport />} />
        <Route path="/addSurvey" render={() => <AddSurvey />} />
        <Route path="/survey" render={() => <Survey />} />
        {/* <Route path="/submitSurvey" render={() => <SubmitVote />} /> */}

        <Route path="/receipt/receiptSetting" render={() => <ReceiptSetting />} />
        <Route path="/receipt" render={() => <Receipt />} />
        <Route path="/addReceipt" render={() => <AddReceipt />} />
        <Route path="/receiptDetail/:id" render={() => <ReceiptDetail />} />
        <Route path="/debtors" render={() => <Debtors />} />
        <Route path="/deposits" render={() => <Deposits />} />
        <Route path="/debplist" render={() => <UserStatePage />} />




        <Route path="/addDoc" render={() => <AddDoc />} />
        {/* <Route path="/fundSetting" render={() => <FundSetting />} /> */}
        <Route path="/docView" render={() => <DocView />} />
        <Route path="/fund/fundSetting" render={() => <FundSetting />} />
        <Route path="/fund/:id" render={() => <DocView />} />
        <Route path="/fund" render={() => <Fund />} />
        <Route path="/pay" render={() => <PayPage />} />

        {/* <Route path="/debts" render={() => <Debts />} /> */}

        <Route path="/chats/:id" render={() => <Chat newchats={gholi3} seenMessage={seenMess} deleteMessage={deleteMessage} />} />
        <Route path="/chats" render={() => <ChatContainerTest badgCount={badgCount} setBadge={setBadge} deleteMessage={deleteMessage} />} />
        <Route path="/chatsT" render={() => <ChatContainer />} />
        <Route path="/chatee" render={() => <ChatTest />} />


        <Route path="/tesss" render={() => <MyLazyLoad />} />





        <Route path="/bills/add" render={() => <AddBill />} />
        <Route path="/bills" render={() => <Bills />} />


        <Route path="/history" render={() => <History />} />


        <Route path="/wallet/:id" render={() => <DocView />} />
        <Route path="/wallet" render={() => <Wallet />} />
        <Route path="/profile" render={() => <Profile />} />
        <Route path="/question" render={() => <Question />} />
        <Route path="/support" render={() => <Support />} />
        <Route path="/aboutus" render={() => <AboutUs />} />
        <Route path="/retry" render={() => <RetryPage />} />



        {/* <Redirect to="/" /> */}

        <Route path="/service" render={() => <ServiceStepsWrapper />} />
        <Route path="/clogin&:ref" component={CLogin} />



        <Route path='/guid' render={() => <Guid/>} />





      </Switch>

    )


  }

  else {
    routes = (
      <Switch>
        <Route path="/login" exact render={() => <Login />} />
        <Route path="/confirmation" exact render={() => <ConfirmCode />} />
        <Route path="/clogin&:ref" component={CLogin} />
        {/* <Route path="/info" exact render={() => <ProfileInfo />} /> */}
        <Redirect to="/login" />
      </Switch>
    )
  }


  return (
    <BrowserRouter>

      <div className={`noselect`}>
        {routes}
      </div>
    </BrowserRouter>


  );
}

export default App;

import moment from "moment-jalaali";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

const OrderContext = React.createContext();

const useOrderContext = () => {
    const orderContext = React.useContext(OrderContext);
    
    if(orderContext === undefined){
        throw new Error("useOrderContext must use inside a OrderProvider")
    }
    return orderContext
}

const initialState = {
    "recomededDateTime": null,
    "recomededDateTimeString": null,
    "addressId": 0,
    "addressString" : "",
    "groupId": 0,
    "providerServiceId": 0,
    "periods": [
        {
        "id": 0,
        "title": "",
        "description": "",
        "price": 0,
        "priceString": "",
        "capacity": 0,
        "startTime": "",
        "endTime": "",
        "isActive": true,
        "isSelected": true
        }
    ],
    "userId": "",
    "title": "",
    "providerName" : "",
    "providerId": 0,
    "serviceId": 0,
    "paymentMethods" : [],
    "loading" : true
}

const orderReducer = (state=initialState , action) => {
  switch (action.type) {
    case "SET_ORDER":
        return {
            ...state,
            ...action.data
        }
    case "SET_PERIODS":
        return {
            ...state,
            periods : action.data
        }
    case "SET_LOADING":
        return {
            ...state,
            loading : action.data
        }
    default:
        return state;
    }
}

const OrderProvider = ({ children }) => {
    const [orderState, orderDispatch] = React.useReducer(orderReducer, initialState);
    const [loading, setLoading] = React.useState(true);
    const history = useHistory()
    const location = useLocation()

    React.useEffect(() => {
        const oldOrderDetails = localStorage.getItem("orderDetails");
        console.log("Order =>>>>>>>>" , oldOrderDetails)
        if(oldOrderDetails){
            orderDispatch({type : "SET_ORDER" , data : JSON.parse(oldOrderDetails)});
            setLoading(false)
        }
        else if(oldOrderDetails === null && !location.pathname.includes("address")){
            history.replace("/")
        }
        else{
            setLoading(false)
        }
    }, [])

    React.useEffect(() => {
        localStorage.setItem("orderDetails", JSON.stringify(orderState));
    }, [orderState])

    return (
        <OrderContext.Provider value={{ orderState, orderDispatch }}>
            {loading?null:children}
        </OrderContext.Provider>
    );
}

export { OrderProvider, useOrderContext };
import React, {  useState } from 'react';
import prev from '../../Assets/myAsset/next.svg';
import next from '../../Assets/myAsset/next (1).svg'
import moment from 'moment-jalaali';
import DOMPurify from 'dompurify';


moment.loadPersian();

const Calender = props => {
    // const [days, setDays] = useState([null]);
    const [mo, setmo] = useState(parseInt(moment().locale('fa').format('jM')));
    const [ye, setye] = useState(parseInt(moment().locale('fa').format('jYYYY')));

    const activeDates = props.activeDates


    
  //  var html = null;

   // let m = 9;
    const setMonth = (_m, _y) => {
        // var myevents = ['1399/9/17'
        // , '1399/9/11', '1399/9/18']
        var myevents = props.events;
        //console.log('>>>>>>>>>>>>>>>>', myevents);
            var holidays = [ '1399/9/11', '1399/9/18']

        var year = parseInt(_y);
        var pyear = year;
        var nyear = year;
        var month = parseInt(_m);

        var nmonth = month + 1;
        var pmonth = month - 1;
        if (pmonth === 0) {
            pmonth = 12;
            pyear--;
        }
        else if (nmonth === 13) {
            nmonth = 1;
            nyear++;
        }

        //props.getevents(year++)
        // console.log('nmonth', moment().locale('fa').format('jMMM'));
        // console.log(moment(year + '/' + month + '/01', 'jYYYY/jM/jDD').format('ddd'));
        var m = moment(year + '/' + month + '/01', 'jYYYY/jM/jDD').format('ddd');
        var i = moment(year + '/' + month + '/01', 'jYYYY/jM/jDD').add(-1, 'day').format('jDD');
        //console.log(i+m);
        var i1 = moment(nyear + '/' + nmonth + '/01', 'jYYYY/jM/jDD').add(-1, 'day').format('jDD');
        var q = 1;
        if (m === 'شنبه'||m==='sat')
            q = 1;
        else if (m === 'یک‌شنبه')
            q = 2;

        else if (m === 'دوشنبه')
            q = 3;

        else if (m === 'سه‌شنبه')
            q = 4;

        else if (m === 'چهارشنبه')
            q = 5;

        else if (m === 'پنج‌شنبه')
            q = 6;

        else if (m === 'جمعه'||m==='آدینه')
            q = 7;

       // console.log('q='+q);
        var tmp = [];
        var u = 1;
        var str = '';
        for (; u < q; u++) {
            tmp[u - 1] = i - q + u + 1;

            str += ` <td name='` + pyear + '/' + pmonth + '/' + (i - q + u + 1) + `'  id='date` + (i - q + u + 1) + `' class='notmonth'><b  class='previous'>` + (i - q + u + 1) + `</b></td>`
        } u--;
        for (var u2 = 1; u2 <= i1; u2++) {
            tmp[u + u2 - 1] = u2;
            str += ` <td name='` + year + '/' + month + '/' + u2 + `' id='date` + (u2) + `'  class='`;




            if (moment().locale('fa').format('jYYYY/jM/jD') === year + '/' + month + '/' + u2)
                str += `today `;
            if (myevents && myevents.length>0 && myevents.indexOf(year + '/' + month + '/' + u2) >= 0)
                str += `myevents `;
            if (holidays.indexOf(year + '/' + month + '/' + u2) >= 0 ||tmp.length % 7 === 0)
                str += `fri `;
            if(props.checkActive){
                if (activeDates.indexOf(moment(`${year}/${month}/${u2}` , 'jYYYY/jM/jD').format('jYYYY/jMM/jDD')) >= 0)
                    str += `active`;
                else 
                    str += `notmonth`
            }

            str += `'><div style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        "class='` + year + '/' + month + '/' + u2 + `'>` + (u2) + `</div></td>`;
            if (tmp.length % 7 === 0)
                str += ` </tr>
            <tr>`;
        } var r = 35;
        if (tmp.length > 35)
           // r = 42;
        for (var w = 1; tmp.length !== 35 && tmp.length !== 42; w++) {
            // console.log(w);
            tmp[tmp.length] = w;
            str += ` <td  name='` + nyear + '/' + nmonth + '/' + w + `' id='date` + (w) + `' `
            if (tmp.length % 7 === 0)
                str += ` class='fri notmonth'`;
            else
                str += `class='notmonth'`;

            str += `><div class='next' style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        " name='` + nyear + '/' + nmonth + '/' + w + `' id='date` + (w) + `'>` + (w) + `</div></td>`

        }


        var gggggg = `<table
                           id="cal1"
                           class="fc-table"
                        >
                    <tbody>
                           <tr>
                              <th>ش</th>
                             <th>ی</th>
                             <th>د</th>
                             <th>س </th>
                             <th>چ</th>
                             <th>پ</th>
                             <th>ج</th>
                           </tr><tr>`+ str + `</tr>
                     </tbody>
                    </table>`

        return (
            <div onClick={(event) => {!props.DatePicker ?  clickHandler(event):handleClick(event) }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(gggggg) }}></div>

        );

    }
    const handleClick = (event)=>{
        event.stopPropagation();
        if( moment(event.target.className , "jYYYY/jM/jD").isValid()){
            if(props.checkActive){
                if(!event.target.parentElement.className.includes("notmonth")){
                    props.click(event.target.className)
                }
            }
            else{
                props.click(event.target.className) 
            }
        }
    }

    const clickHandler = (e) => {
        clicked( e.target.className);
        // console.log(e.target.name,  e.target.className);
    }
    const clicked = (e) => {
        var tmp = mo;
        if (e === 'previous')
            tmp = mo - 1;
        else if (e === 'next')
            tmp = mo + 1;
        if (tmp === 0) {
            tmp = 12;
            setye(ye - 1);
        }
        else if (tmp === 13) {
            tmp = 1;
            setye(ye + 1);
        }
        if (tmp !== mo)
            setmo(tmp);
    }
    const clickedyear = (e) => {
       // var tmp = mo;
        if (e === 'previous')
            
            setye(ye - 1);
        else if (e === 'next')
            
            setye(ye + 1);
       
    }

    return (
        <div>
        <div style={{ backgroundColor: '#f7f7f7', borderRadius: 20, padding: 5 }}>
            
            <div className={`d-flex justify-content-around align-items-center`}>
                <img onClick={() => clickedyear('next')} src={next} style={{ width: 20, height:'100% '}} alt={'alt'} />
                <div className={`fnt fntBold text-center`} style={{ fontSize: 40 }}>{ye}</div>
                <img onClick={() => clickedyear('previous')} src={prev} style={{ width: 20, height:'100% ' }} alt={'alt'} />
            </div>
            
            
            
            <div className={`d-flex justify-content-between align-items-center`}>
                <img onClick={() => clicked('next')} src={next} style={{ width: 20, height:'100% ' }} alt={'alt'}  />
                <div   > {moment(mo, 'jM').format('jMMMM')}</div>
                <img onClick={() => clicked('previous')} src={prev} style={{ width: 20, height:'100% ' }}  alt={'alt'} />
            </div>


            {setMonth(mo, ye)}

            


        </div>
        <div> </div>
        </div>
    )

}
export default Calender;

import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import SBotton from '../../Buttons/SBotton/SBotton';
import { Checkbox } from '../../CheckBox/CheckBox';
import { ApiService, formatMoney } from '../../../value';



const MessageModal2 = props => {


    const [sending, setSending] = useState(false);

    const [messageText, setMessageText] = useState(props.defaultText? props.defaultText : '')
    const [MessageData, setMessageData] = useState(props.data ? props.data : {
        token:null,
        userRoleInUnit: 1,
        messageType: 2,
        isValidMessage: false,
       

    });

    useEffect(()=>{
       
        if(!props.isClose || props.type == 2){
            setMessageData({
                token:null,
                userRoleInUnit: props.role && (props.role == 'Resident' || props.role == 1) ? 1 : 2,
                messageType: 2,
                isValidMessage: false,
                text : props.defaultText
               
        
            });
            //setMessageText(props.defaultText? props.defaultText : '')
        }
    },[props.isClose])


    const sendMessage = (data) => {


        if(data){
            console.log('2', data);
        if (!data.text || data.text.length == 0) {
            props.onErr('متن پیام را وارد کنید', 'war')
            return

        }

        if (!data.messageType) {
            props.onErr('متن پیام را وارد کنید', 'war')
        }

       


        setSending(true);
        let data1 = data;
        data1.units = props.selectedUnit;
        data1.metaData = props.ReceiptId ? `${props.ReceiptId}` : '';
        
        ApiService('Post', `/Message/Send/${localStorage.getItem('id')}`, data1)
            .then(response => {
                if (response.data.result && response.data.resultCode == 200) {
                    setMessageData(response.data.data);
                    setSending(false);
                }
                else if (response.data.result && response.data.resultCode == 100) {
                    setSending(false);
                    props.onClose();
                    setMessageData({
                        // messageType: 0,
                        // userRoleInUnit: 1,
                        // text: ''
                    });
                   // setselectedUnitForMess([]);
                   // setSendMess(false);
                   props.onErr(response.data.message, 'suc')
                } else {
                    props.onErr(response.data.message, 'war')
                }
            })

        }
    }

    // ارسال پیام برای {props.selectedUnit.length} واحد
    return (
        <Modal    show={props.show} btn={true} click={props.click}   >
            <div  style={{  height:'45vh', overflow:'auto' }}>
                        <div className={`text-right rtl fntBold `} style={{ fontSize: 12 }} >{props.text}</div>
                        <div className={`w-100 mt-2 mb-2 text-center`}> 
                            <textarea disabled={MessageData.token ? true : false}
                                className={`w-100 rtl text-right`}
                                style={{
                                    border: '1px solid grey', borderRadius: 10,
                                    padding: 10, fontSize: 15, textAlign: 'right', height: 100
                                }}
                                placeholder={'متن پیام'}
                                value={props.defaultText? props.defaultText :  messageText}
                                onChange={(e) => {
                                    let tmp = MessageData;
                                    tmp.text = e.target.value;
                                    setMessageData(tmp);
                                    setMessageText(e.target.value)
                                }} />
                                <div>
                            </div>
                            <div className={`text-center rtl mediumF fntBold`} 
                            style={{ color: MessageData.validationMessage && MessageData.validationMessage.length>0 ? 'salmon' : '' }}>
                            {MessageData.validationMessage && MessageData.validationMessage.length>0 ? MessageData.validationMessage : MessageData.infoMessage }
                                </div>
                        </div>
                        <div className={`w-75 mx-auto`}>

                            <SBotton gradian sending={sending} 
                            title={MessageData.isValidMessage ? ' ارسال با هزینه' + formatMoney(MessageData.cost) + 'ریال' : 'تایید پیام'}
                             click={() => {
                                sendMessage(MessageData)
                            }} />
                        </div>
                    </div>
        </Modal>
    )
}
export default MessageModal2;
import React from 'react';
import Layout from '../../../Components/Layout/Layout';
import { Component } from 'react';
import GrayLine from '../../../Components/GrayLine/GrayLine';
import add from "../../../Assets/myAsset/addG.svg";
import redDel from "../../../Assets/myAsset/minusRed.svg";
import check from '../../../Assets/myAsset/checked.svg';
import { ApiService, FaToEnNum, formatMoney, MyBuildingList } from '../../../value';
import NewSelect from '../../../Components/NewSelect/NewSelect';
import Toast from '../../../Components/Toast/Toast';
import { withRouter } from 'react-router-dom';

import classes from "./ReceiptSetting.module.css";


const def =
{

    name: 'انتخاب ساختمان',

};
let Self = null;
class ReceiptSetting extends Component {

    constructor(props) {
        super(props);
        Self = this;
        this.state = {

            buildingName: '',
            loading: true,
            errText: '',
            errType: '',
            personsAmount: {
                key: 0,
                value: 0
            },
            areasAmount: {
                key: 0,
                value: 0
            },
            extraPersonAmount: 0,
            extraAreaAmount: 0,
            emptyUnitAmount: 0,
            chargeInfo: {
                personsAmount: [],
                areasAmount: [],
                extraPersonAmount: 0,
                extraAreaAmount: 0,
                emptyUnitAmount: 0
            }
        }
    }

    componentDidMount() {
        this.getMyBuildingList();

    }




    getMyBuildingList() {
        MyBuildingList(false).then(res => {
            let list = res;
            let x=';'
            if (list) {
                list.find(elem => {
                    if (elem.id == localStorage.getItem('id')) {
                        x = elem.name
                    }
                });
                console.log('xxxxxxxx', x);
                this.setState({

                    buildingName: x
                
                   // loading: false

                });

            }
            this.getData();

        })
        .catch(err => {
            this.props.history.goBack();
        });
        
    }

    // getMyBuildingList() {
    //     MyBuildingList(false).then(res => {
    //         let list = res;
    //         if (list) {
    //             this.setState({
    //                 buildingList: list,
    //                 selectedBuilding: list.find(elem => { return elem.id == localStorage.getItem('id') }),

    //             });
    //             this.getData();
    //         }
    //     })
    //         .catch(err => {
    //             this.setState({
    //                 errText: '',
    //                 errType: '',
    //                 loading: false
    //             })
    //             this.clearErr()

    //         })
    // }
    getData() {
        this.setState({
            loading: true
        })
        ApiService('Get', `/AccRequest/GetChargeTemplate/${localStorage.getItem('id')}`)
            .then(response => {
                if (response.data.result) {
                    this.setState({
                        chargeInfo: response.data.data,
                        extraPersonAmount: response.data.data.extraPersonAmount,
                        extraAreaAmount: response.data.data.extraAreaAmount,
                        emptyUnitAmount: response.data.data.emptyUnitAmount,
                        loading: false
                    })
                } else {
                    this.setState({
                        errText: response.data.message,
                        errType: 'war',
                        loading: false
                    })
                    this.clearErr()
                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType:'err'  ,
                    loading: false      
                });
                this.clearErr();
                return
    
            })

    }
    submit() {
        console.log('this.state.extraAreaAmount', this.state.extraAreaAmount);
        let temp = this.state.chargeInfo;
        temp.extraPersonAmount = this.state.extraPersonAmount ? parseInt(this.state.extraPersonAmount) :0;
        temp.extraAreaAmount =this.state.extraAreaAmount ? parseInt(this.state.extraAreaAmount) :0;
        temp.emptyUnitAmount = this.state.emptyUnitAmount ? parseInt(this.state.emptyUnitAmount):0;
        let data = null;
        this.setState({
            chargeInfo: temp,
            loading: true
        });
        data = this.state.chargeInfo;
        console.log(data);


        ApiService('Post', `/AccRequest/SubmitChargeTemplate/${localStorage.getItem('id')}`, data)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    this.setState({
                        errText: 'با موفقیت ثبت شد',
                        errType: 'suc',
                        loading: false
                    })
                    this.clearErr();
                    setTimeout(() => {
                        this.props.history.push('/receipt')

                    }, 3000);
                }else{
                    this.setState({
                        errText: response.data.message,
                        errType: 'suc',
                        loading: false
                    })
                    this.clearErr();

                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType:'err'  ,
                    loading: false      
                });
                this.clearErr();
                return
    
            })
    }

    clearErr() {
        setTimeout(() => {
            this.setState({
                errText: '',
                errType: ''
            })

        }, 3000)
    }
    closeSelect() {

        this.NewSelect && this.NewSelect.lastObj && this.NewSelect.lastObj.close();
    }
    blurHandler(item) {
        if (item == 1) {
            this.addHandler(1)
                .then(res => {
                    console.log('!!!!!!!!!!!!1', res);
                    if (this.state.chargeInfo.personsAmount.find(elem => elem.key == this.state.personsAmount.key)) {
                        this.setState({
                            errText: `به ازای ${this.state.personsAmount.key} نفر مبلغ وارد شده است`,
                            errType: 'war',
                            personsAmount: {
                                key: '',
                                value: 0
                            }
                        });
                        this.clearErr();
                        return
                    }


                    let tem = this.state.chargeInfo;
                    tem.personsAmount.push(this.state.personsAmount)
                    this.setState({
                        chargeInfo: tem,
                        personsAmount: {
                            key: '',
                            value: 0
                        }
                    })

                })
        } else {
            this.addHandler(2)
                .then(res => {

                    if (this.state.chargeInfo.areasAmount.find(elem => elem.key == this.state.areasAmount.key)) {
                        this.setState({
                            errText: `به ازای ${this.state.areasAmount.key} متر مبلغ وارد شده است`,
                            errType: 'war',
                            areasAmount: {
                                key: '',
                                value: ''
                            }
                        });
                        this.clearErr();
                        return
                    }
                    if (!this.state.areasAmount.key || this.state.areasAmount == 0) {
                        return
                    }
                    let tem = this.state.chargeInfo;
                    tem.areasAmount.push(this.state.areasAmount)
                    this.setState({
                        chargeInfo: tem,
                        areasAmount: {
                            key: '',
                            value: 0
                        }
                    })
                })

        }
    }

   

    async addHandler(item) {
        if (item == 1) {
            await this.setState({
                personsAmount: {
                    value: parseInt(this.state.personsAmount.value),
                    key: this.state.personsAmount.key
                }
            })
            return this.state.personsAmount
        } else {
            await this.setState({
                areasAmount: {
                    value: parseInt(this.state.areasAmount.value),
                    key: this.state.areasAmount.key
                }
            })
            return this.state.areasAmount

        }


    }





    render() {
        return (
            <>
                <Layout loaded={true} title={'تنظیمات محاسبه شارژ'} loading={this.state.loading} isHome={false} gradian btn={true} btnName={'ثبت نهایی'}
                    click={() => {
                        this.submit()
                    }} >
                    <Toast
                        text={this.state.errText}
                        type={this.state.errType}
                    />
                    <div style={{ height: 'inherit' }}>
                        <div className={`p-1`} style={{ height: `calc(100% - 5rem)`, overflow: 'auto' }}>
                            <div className={`text-center`}>{`ساختمان ${this.state.buildingName}`}</div>
                            {/* {this.state.buildingList.length > 0 ? <NewSelect options={this.state.buildingList} plachodler={def}
                                obj={false} sel2={false} value={this.state.selectedBuilding} closeOther={() => { this.closeSelect() }}>
                                {this.state.buildingList.map(item => {
                                    return (
                                        <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                            this.state.buildingList.find(elem => {
                                                if (elem.id == item.id) {
                                                    this.setState({
                                                        selectedBuilding: elem
                                                    })
                                                    localStorage.setItem('id', elem.id);
                                                    this.getData();

                                                }
                                            })
                                        }}>{item.name}</div>
                                    )
                                })}
                            </NewSelect> : null} */}
                            <div className={`d-flex rtl justify-content-between align-items-center mediumF mt-4 mb-3`}>
                                <div className={` rtl text-right`} style={{ fontSize: 12 }}>هزینه شارژ واحد خالی:</div>
                                <div className={`d-flex rtl  mediumF p-1`} style={{ width: 150, textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}  >
                                    <input className={classes.inputS} placeholder={formatMoney(2500000)}
                                        value={formatMoney(this.state.emptyUnitAmount)}
                                        onChange={(e) => {
                                            let tmp = e.target.value.replace(/,/g, "")
                                            if (+tmp === +tmp)
                                                this.setState({
                                                    emptyUnitAmount: tmp

                                                })
                                            else {
                                                this.setState({
                                                    emptyUnitAmount: this.state.emptyUnitAmount
                                                })
                                            }
                                        }}

                                    />
                                    <span>ریال</span>
                                </div>
                            </div>
                            <GrayLine />
                            { /*********************************************** */}
                            <div className={`mt-3 mb-3 `}>
                                <div >
                                    <div className={`fntBold mt-2 mb-2 rtl text-right`}> محاسبه شارژماهیانه براساس نفرات: </div>
                                    <div className={`d-flex rtl justify-content-between align-items-center mediumF mt-3`}>
                                        <span>تا</span>
                                        <div className={`d- rtl  mediumF p-1`} style={{ width: 50, textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}>
                                            <input style={{ width: '100%', textAlign: 'center', border: 0 }}
                                                placeholder={2} type={'number'}
                                                value={this.state.personsAmount.key}
                                                onChange={(e) => {
                                                    this.setState(prevState => ({
                                                        personsAmount: {
                                                            ...prevState.personsAmount,
                                                            key: parseInt(e.target.value)
                                                        }
                                                    }))
                                                }} />
                                        </div>
                                        <span>نفر</span>
                                        <div className={`d-flex rtl  mediumF p-1`} style={{ width: 150, textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}  >
                                            <input style={{ width: '100%', textAlign: 'center', border: 0 }} pattern={'[0-9_,]*'}
                                                placeholder={formatMoney(2500000)}
                                                value={formatMoney(this.state.personsAmount.value)}
                                                onBlur={() => {
                                                    this.blurHandler(1)
                                                }}
                                                onChange={(e) => {
                                                    let tmp = e.target.value.replace(/,/g, "");
                                                    console.log('>>>>>>>>', tmp);
                                                    if (+tmp === +tmp)
                                                        this.setState(prevState => ({
                                                            personsAmount: {
                                                                ...prevState.personsAmount,
                                                                value: tmp
                                                            }
                                                        }))
                                                    else
                                                        this.setState(prevState => ({
                                                            personsAmount: {
                                                                ...prevState.personsAmount,
                                                                value: parseInt(this.state.personsAmount.value)
                                                            }
                                                        }))
                                                }} />
                                            <span>ریال</span>
                                        </div>
                                        <div style={{ width: 25 }}>
                                            <img src={add} className={`w-100`} onClick={() => {

                                                this.addHandler(1)
                                                    .then(res => {
                                                        console.log('!!!!!!!!!!!!1', res);
                                                        if (this.state.chargeInfo.personsAmount.find(elem => elem.key == this.state.personsAmount.key)) {
                                                            this.setState({
                                                                errText: `به ازای ${this.state.personsAmount.key} نفر مبلغ وارد شده است`,
                                                                errType: 'war',
                                                                personsAmount: {
                                                                    key: '',
                                                                    value: 0
                                                                }
                                                            });
                                                            this.clearErr();
                                                            return
                                                        }


                                                        let tem = this.state.chargeInfo;
                                                        tem.personsAmount.push(this.state.personsAmount)
                                                        this.setState({
                                                            chargeInfo: tem,
                                                            personsAmount: {
                                                                key: '',
                                                                value: 0
                                                            }
                                                        })

                                                    })



                                            }} />
                                        </div>
                                    </div>
                                </div>


                                <div className={`mt-4`}>
                                    {this.state.chargeInfo.personsAmount && this.state.chargeInfo.personsAmount.map((item, index) => {
                                        return <div key={index} className={`d-flex rtl justify-content-between align-items-center mediumF `}>
                                            <div className={`d-flex rtl justify-content-end align-items-center`}>
                                                <div style={{ width: '10px', marginLeft: 10 }}><img style={{ width: '100%', marginLeft: 10 }} src={check} alt={'alt'} /></div>
                                                <div style={{ color: '#42e65e', fontSize: 13 }}>تا {item.key} نفر مبلغ شارژ {formatMoney(item.value)} ریال</div>
                                            </div>

                                            <div style={{ width: 15 }}>
                                                <img src={redDel} className={`w-100`} onClick={() => {
                                                   
                                                    let tem = this.state.chargeInfo;
                                                    let x = tem.personsAmount.filter(el => el.key !== item.key);
                                                    this.setState(prevState => ({
                                                        chargeInfo: {
                                                            ...prevState.chargeInfo,
                                                            personsAmount: x
                                                        }
                                                    }))
                                                }} />
                                            </div>
                                        </div>
                                    })}


                                </div>

                                <div className={`d-flex rtl justify-content-between align-items-center mediumF mt-4 mb-3`}>
                                    <div className={` rtl text-right`} style={{ fontSize: 12 }}>به ازای هر نفر بیشتر:</div>
                                    <div className={`d-flex rtl  mediumF p-1`} style={{ width: 150, textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}  >
                                        <input style={{ width: '100%', textAlign: 'center', border: 0 }}
                                            placeholder={formatMoney(2500000)}
                                            value={formatMoney(this.state.extraPersonAmount)}
                                            onChange={(e) => {
                                                let tmp = e.target.value.replace(/,/g, "")
                                                if (+tmp === +tmp)
                                                    this.setState({
                                                        extraPersonAmount: tmp

                                                    })
                                                else {
                                                    this.setState({
                                                        extraPersonAmount: this.state.extraPersonAmount
                                                    })
                                                }

                                            }} />
                                        <span>ریال</span>
                                    </div>
                                </div>

                            </div>


                            <GrayLine />



                            { /***************************************************************************************************************************** */}
                            <div className={`mt-3 mb-3 `}>
                                <div >
                                    <div className={`fntBold mt-2 mb-2 rtl text-right`}> محاسبه شارژماهیانه براساس متراژ: </div>
                                    <div className={`d-flex rtl justify-content-between align-items-center mediumF mt-3`}>
                                        <span>تا</span>
                                        <div className={`d- rtl  mediumF p-1`} style={{ width: 50, textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}>
                                            <input style={{ width: '100%', textAlign: 'center', border: 0 }}
                                                placeholder={100} type={'number'} onChange={(e) => {
                                                    this.setState(prevState => ({
                                                        areasAmount: {
                                                            ...prevState.areasAmount,
                                                            key: parseInt(e.target.value)
                                                        }
                                                    }))
                                                }} />
                                        </div>
                                        <span>متر</span>
                                        <div className={`d-flex rtl  mediumF p-1`}
                                            style={{ width: 150, textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}
                                        >
                                            <input style={{ width: '100%', textAlign: 'center', border: 0 }} pattern={'[0-9_,]*'} placeholder={formatMoney(2500000)}
                                                value={formatMoney(this.state.areasAmount.value)}
                                                onBlur={() => {
                                                    this.blurHandler(2)
                                                }}
                                                onChange={(e) => {
                                                    let tmp = e.target.value.replace(/,/g, "")
                                                    if (+tmp === +tmp)
                                                        this.setState(prevState => ({
                                                            areasAmount: {
                                                                ...prevState.areasAmount,
                                                                value: tmp
                                                            }
                                                        }))
                                                    else
                                                        this.setState(prevState => ({
                                                            areasAmount: {
                                                                ...prevState.areasAmount,
                                                                value: parseInt(this.state.areasAmount.value)
                                                            }
                                                        }))
                                                }} />
                                            <span>ریال</span>
                                        </div>
                                        <div style={{ width: 25 }}>
                                            <img src={add} className={`w-100`} onClick={() => {

                                                this.addHandler(2)
                                                    .then(res => {

                                                        if (this.state.chargeInfo.areasAmount.find(elem => elem.key == this.state.areasAmount.key)) {
                                                            this.setState({
                                                                errText: `به ازای ${this.state.areasAmount.key} متر مبلغ وارد شده است`,
                                                                errType: 'war',
                                                                areasAmount: {
                                                                    key: '',
                                                                    value: ''
                                                                }
                                                            });
                                                            this.clearErr();
                                                            return
                                                        }
                                                        if (!this.state.areasAmount.key || this.state.areasAmount == 0) {
                                                            return
                                                        }
                                                        let tem = this.state.chargeInfo;
                                                        tem.areasAmount.push(this.state.areasAmount)
                                                        this.setState({
                                                            chargeInfo: tem,
                                                            areasAmount: {
                                                                key: '',
                                                                value: 0
                                                            }
                                                        })
                                                    })
                                            }} />
                                        </div>
                                    </div>
                                </div>


                                <div className={`mt-4`}>
                                    {this.state.chargeInfo.areasAmount.map((item, index) => {
                                        return <div key={index} className={`d-flex rtl justify-content-between align-items-center mediumF `}>
                                            <div className={`d-flex rtl justify-content-end align-items-center`}>
                                                <div style={{ width: '10px', marginLeft: 10 }}><img style={{ width: '100%', marginLeft: 10 }} src={check} alt={'alt'} /></div>
                                                <div style={{ color: '#42e65e', fontSize: 13 }}>تا  {item.key} متر مبلغ شارژ {formatMoney(item.value)} ریال</div>
                                            </div>

                                            <div style={{ width: 15 }}>
                                                <img src={redDel} className={`w-100`} onClick={() => {
                                                  
                                                    let tem = this.state.chargeInfo;
                                                    let x = tem.areasAmount.filter(el => el.key !== item.key);
                                                    this.setState(prevState => ({
                                                        chargeInfo: {
                                                            ...prevState.chargeInfo,
                                                            areasAmount: x
                                                        }
                                                    }))
                                                }} />
                                            </div>
                                        </div>
                                    })}

                                    <div className={`d-flex rtl justify-content-between align-items-center mediumF mt-4 mb-3`}>
                                        <div className={` rtl text-right`} style={{ fontSize: 12 }}>به ازای هر متر بیشتر:</div>
                                        <div className={`d-flex rtl  mediumF p-1`} style={{ width: 150, textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}  >
                                            <input style={{ width: '100%', textAlign: 'center', border: 0 }} placeholder={formatMoney(2500000)}
                                                value={formatMoney(this.state.extraAreaAmount)}
                                                onChange={(e) => {
                                                    let tmp = e.target.value.replace(/,/g, "")
                                                    if (+tmp === +tmp)
                                                        this.setState({
                                                            extraAreaAmount: tmp

                                                        })
                                                    else {
                                                        this.setState({
                                                            extraAreaAmount: this.state.extraAreaAmount

                                                        })
                                                    }
                                                }}
                                            />
                                            <span>ریال</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Layout>
            </>
        );

    }


};

export default withRouter(ReceiptSetting);
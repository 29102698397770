import React from "react";
import { useHistory } from "react-router-dom";
import { formatMoney } from '../../value';
import defaultImg from "../../Assets/myAsset/user.png"

const ActiveOrders = ({orders})=>{
    
    const history = useHistory()
    return orders.map(order =>{
        console.log(order)
        return(
            <div className="shadow-card mt-1 mb-2 p-1" onClick={()=>history.push(`/service/orderDetails/${order.id}`)}>
                <div className="d-flex flex-column w-100 p-1 px-2">
                    <div className="d-flex flex-row justify-content-between rtl">
                        <span>
                            {
                                order.titel
                            }
                        </span>
                        <span>
                            {
                                order.reservationDate
                            }
                        </span>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-start rtl mt-2 mb-1">
                        <span>
                            خدمات دهنده :
                        </span>
                        <img 
                            src={
                                (!!order.provider.image &&
                                !!order.provider.image.path &&
                                order.provider.image.path != "") ? 
                                order.provider.image.path : defaultImg
                            }
                            style={{width : 30 , height : 30 , borderRadius : "50%" , marginLeft : 5 , marginRight: 15}}
                        />
                        <span>
                            {
                                `"${order.provider.name}"`
                            }
                        </span>
                    </div>
                    <div className="d-flex flex-row rtl">
                        <span>
                            {
                                `ساعت : ${order.period.startTime}`
                            }
                        </span>
                    </div>
                </div>
            </div>
        )
    })
}

export default ActiveOrders
import React, { useEffect, useState, } from 'react';
import {
    colors,
    formatMoney,
    ApiService,
    MyBuildingList,
    getBuildingInfo,
    FaToEnNum,
    attachFile,
    downloadPdf, downloadExcel
} from '../../value';
import '../../App.css';
import AddBotton from '../../Components/Buttons/AddBotton/AddBotton';
import Layout from '../../Components/Layout/Layout';
import TabBar from '../../Components/TabBar/TabBar';
import location from '../../Assets/myAsset/location.svg';
import check from '../../Assets/myAsset/checked.svg';
import UnitCard from '../../Components/Cards/UnitCard/UnitCard';
import Modal from '../../Components/Modal/Modal';
import SBotton from '../../Components/Buttons/SBotton/SBotton';
import edit from '../../Assets/myAsset/edit.svg';
import SearchBar from '../../Components/SearchBar/SearchBar';
import axios from '../../axios';
import NewSelect from '../../Components/NewSelect/NewSelect';
import { withRouter } from 'react-router-dom';
import Toast from '../../Components/Toast/Toast';

import EmptyList from '../../Components/EmptyList/EmptyList';
import FilterModal from '../../Components/Modal/FilterModal/FilterModal';

import searchImg from '../../Assets/myAsset/loupe.svg';
// import del from '../../Assets/myAsset/clear.svg';
import message from '../../Assets/myAsset/email.svg';
import { Checkbox } from '../../Components/CheckBox/CheckBox';
import settings from '../../Assets/myAsset/settings.svg'
import ModalUploadExcel from '../../Components/Modal/ModalUploadExcel/ModalUploadExcel';
import CpInput from '../../Components/Input/Input';





const Building = props => {

    const [height, setHeight] = useState();

    const [sending, setSending] = useState(false);

    const [downloading, setDownloading] = useState(false);


    //const [tab, setTab] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModalMessage, setShowModalMessage] = useState(false);
    const [showModalChgngMangr, setShowModalChgngMangr] = useState(false);
    const [MessageData, setMessageData] = useState({

        messageType: -1,
        userRoleInUnit: 1
    });
    const [messageText, setMessageText] = useState('');
    const [newManager, setNewManager] = useState(null);



    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(null);
    const [renderItem, setRenderItem] = useState(null);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [buildingList, setBuildingList] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);
    const [buildingInfo, setBuildingInfo] = useState(null);
    const [unitList, setUnitList] = useState([]);
    const [permissions, setPermissions] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const [showType, setShowType] = useState('resident');
    const [showType1, setShowType1] = useState('personsCount');




    const [wallet, setWallet] = useState(null)
    const [mojodi, setMojodi] = useState(null)





    const [search, setSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [sendMess, setSendMess] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState([]);
    const [imgId, setImgId] = useState([]);
    const [imgPath, setImgPath] = useState([])


    let filterData = {};
    useEffect(() => {

        getMyBuildingList()
    }, []);

    useEffect(() => {
        setLoading(true);
        if (localStorage.getItem('id')) {
            getBuildingInfo(localStorage.getItem('id')).then(res => {
                setBuildingInfo(res);
                console.log('))))))))))))))', res.cashDesks.list);
                if (res.cashDesks.list.length > 0) {
                    res.cashDesks.list.find(elem => {
                        if (elem.isOnline) {
                            console.log('wallet', elem);
                            setWallet(elem.balance)

                        } else {
                            console.log('mojoodi', elem);
                            setMojodi(elem.balance)

                        }
                    })
                }




                getBuildingUnits(localStorage.getItem('id'), '');
                if (!activeTab) { setActiveTab(2); }
                setLoading(false);
            })
                .catch(err => {
                    console.log(err);
                    // getDashbordData(0);
                    // setLoading(false);
                    // setErrText(err.data.message);
                    // setErrType('war');
                    // clearErr();
                    // setTimeout(() => {
                    //     props.history.push('/')

                    // }, 2000);
                })
        } else {
            return function cleanup() { }
        }

    }, [selectedBulding])

    useEffect(() => {
        const Hheight = document.getElementById('contentBx').clientHeight;
        setHeight(Hheight - 200);

        if (activeTab == 1) {
            setRenderItem(renderInfo());
            // setTab(true);
        } else {
            setRenderItem(renderUnits());
            //setTab(false);
        }
    }, [height, selectedUnit, activeTab, mojodi, wallet,
        search, searchText, unitList,
        sendMess, showType, showType1, selectAll, MessageData])



    const searchHandler = () => {
        // setLoading(true)
        getBuildingUnits(localStorage.getItem('id'), searchText)
    }


    const getMyBuildingList = () => {
        MyBuildingList(false).then(res => {
            console.log('rrrrrrrrrrrrrrrrrr', res);
            let list = res;
            if (list) {
                setBuildingList(list);
                setSelectedBulding(list.find(elem => {
                    return elem.id == localStorage.getItem('id')
                }));
                if (!activeTab) { setActiveTab(1); }
                // getBuildingInfo(localStorage.getItem('id')).then(res => {
                //     setBuildingInfo(res);
                //     getBuildingUnits(localStorage.getItem('id'), '');
                //     if (!activeTab) { setActiveTab(1); }
                //     setLoading(false);
                // });
            }

        })
            .catch(err => {
                setLoading(false);
                setErrText(err.data.message);
                setErrType('war');
                clearErr();
            })

    }

    const getBuildingUnits = (id, textSearch) => {
        let data = {
            nameLike: `${textSearch}`,
            pageSize: 1000,
            pageNumber: 0
        }
        filterData.nameLike = `${textSearch}`;
        filterData.pageSize = 1000;
        filterData.pageNumber = 0;
        setLoading(true)

        ApiService('Post', `/Building/Units/${id}`, filterData)
            .then(response => {
                if (response.data.result) {
                    setUnitList(response.data.data)
                    setPermissions(response.data.data2)

                    setLoading(false);
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    // setTab(false);
                    setLoading(false);
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const downloadExelSample = () => {
        setDownloading(true)
        downloadExcel(`/Building/UnitsExcelTemplate/${localStorage.getItem('id')}`,'units')
            .then(res => {
                // console.log(res);
                setDownloading(false);
            })
            .catch(err=>{
                setErrText('درخواست با خطا مواجه شد ');
                setErrType('err');
                clearErr();
                setDownloading(false);

            })

    };
    const uploadExcel = (id) => {
        ApiService('GET', `/Building/SubmitUnitsExcelTemplate/${localStorage.getItem('id')}/${id}`)
            .then(res => {
                if (res.data.result) {
                    setSending(false);
                    setErrText('فایل با موفقیت بارگذاری شد')
                    setErrType('suc');
                    setShowModal(false);
                    getBuildingUnits(localStorage.getItem('id'), '');
                    clearErr()


                } else {
                    setSending(false);
                    setErrText(res.data.message)
                    setErrType('war');
                    clearErr()

                }
            })
            .catch(err => {
                setSending(false);
                setErrText('خطای داخلی سرور')
                setErrType('err');
                setShowModal(false);
                setLoading(false);
                clearErr()
            })

    }
    const uploadFile = (files) => {
        setSending(true);
        // this.setState({
        //     uploading: true
        // })
        attachFile(files).then(res => {
            console.log(res);
            uploadExcel(res[0].id);
            // setImgId( res[0].id)
            // setImgPath (res[0].path)
            // uploading: false


        })
            .catch(err => {

            })

    }

    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }


    const selectUnitHandler = () => {
        setSendMess(!sendMess);

        setSearch(false);

    }

    const onSelectUnit = (ch, item) => {
        if (ch) {
            setSelectedUnit(oldArray => [...oldArray, item.id])


        } else {
            setSelectedUnit(selectedUnit.filter(elem => elem !== item.id))
        }



    }

    const selectAllHandler = (check) => {
        setSelectedUnit([]);
        if (check) {
            let tmp = [];
            unitList.forEach(element => {
                tmp.push(element.id)

            });
            setSelectedUnit(tmp);
            setSelectAll(true);

        } else {
            setSelectAll(false);
            setSelectedUnit([])
        }

    }
    const closeSelect = () => {

        NewSelect && NewSelect.lastObj && NewSelect.lastObj.close();
    }
    const sendMessage = () => {
        if (!MessageData.text || MessageData.text.length == 0) {
            setErrText('متن پیام را وارد کنید');
            setErrType('war');
            clearErr();
            return

        }
        if ( MessageData.text.length > 750) {
            setErrText('متن پیام بلند است و قابل ارسال نیست');
            setErrType('war');
            clearErr();
            return

        }


        if (MessageData.messageType<0) {
            setErrText('نوع پیام را انتخاب کنید');
            setErrType('war');
            clearErr();
            return

        }

        if (!MessageData.userRoleInUnit) {
            setErrText('ارسال پیام به ساکنین یا مالکین را مشخص کنید');
            setErrType('war');
            clearErr();
            return

        }


        setSending(true);
        let data = MessageData;
        data.units = selectedUnit;

        ApiService('Post', `/Message/Send/${localStorage.getItem('id')}`, data)
            .then(response => {

                if (response.data.result && response.data.resultCode == 200) {
                    console.log(response.data);
                    setMessageData(response.data.data);
                    setSending(false);
                    if (response.data.data.validationMessage && response.data.data.validationMessage.lenght > 0) {
                        setErrText(response.data.data.validationMessage);
                        setErrType('war');
                        clearErr();
                    }

                }
                else if (response.data.result && response.data.resultCode == 100) {
                    setSending(false);
                    setShowModalMessage(false);
                    setMessageData({
                        // messageType: 0,
                        // userRoleInUnit: 1,
                        // text: ''
                    });
                    setSelectedUnit([]);
                    setSendMess(false);
                    setSelectAll(false);
                    setErrText(response.data.message);
                    setErrType('suc');
                    clearErr();


                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    setSending(false);
                    clearErr();
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setSending(false);
                clearErr();
                return
            })
    }


    const newManagerHandler = () => {
        console.log('newManager', newManager);
        if (!newManager || newManager.length != 11) {
            setErrText(' شماره موبایل را وارد کنید');
            setErrType('war');
            clearErr();
            return
        }
        ApiService('Get', `/Building/RequestChangeBuildingOwner/${localStorage.getItem('id')}/${newManager}`)
            .then(res => {
                if (res.data.result) {
                    setErrText(' درخواست شما ثبت شد');
                    setErrType('suc');
                    clearErr();
                    setShowModalChgngMangr(false)

                } else {
                    setErrText(res.data.message);
                    setErrType('suc');
                    clearErr();
                    setShowModalChgngMangr(false)

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                clearErr();
                return
            })

    }

    const patternClicked = (e)=>{
        console.log(e.target.innerText.split(":")[1].trim())
        setMessageText(`${messageText} ${e.target.innerText.split(":")[1].trim()} `.trim())
    }


    const renderInfo = () => {
        return (buildingInfo ?
            <div > <div className={``} style={{ paddingBottom: '35%' }} >
                <div className={`d-flex  align-items-center justify-content-between w-100 pt-2 pb-2  `} style={{ borderBottom: '2px solid #e8e8e8' }} >
                    {buildingInfo.imagePath ? <div className={`d-flex justify-content-end `} style={{
                        width: 110, height: 80, float: 'left',
                        backgroundImage: "url(" + buildingInfo.imagePath + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        {/* <img alt={'pic'} className={`w-100`} src={buildingInfo.imagePath} /> */}
                    </div> : <div></div>}
                    <div className={`text-right rtl`}>
                        <div className={`fntBold`}>ساختمان {buildingInfo ? buildingInfo.name : ''} </div>
                        <div className={`mt-2 mb-2`} style={{ fontSize: '12px' }}>{`تاریخ ایجاد ساختمان ${buildingInfo ? buildingInfo.dateCreated : ''}`}</div>
                        <div className={`mb-2`} style={{ color: colors.app6, fontSize: '12px' }}>{buildingInfo.floorsNumber} طبقه</div>
                        <div style={{ color: colors.app6, fontSize: '12px' }}>{buildingInfo.unitsCount} واحد</div>
                    </div>
                </div>

                <div className={`text-right rtl w-100 pt-2 pb-2 `} style={{ borderBottom: '2px solid #e8e8e8', lineHeight: '1.8' }} >
                    <div style={{ color: colors.app7, fontSize: '14px' }}>
                        مدیریت
                    </div>
                    <div className={`d-flex align-items-center justify-content-between  w-100`}>

                        <div className={`fntBold`} style={{ color: colors.app8, fontSize: '12px' }}>
                            {buildingInfo.managers.list.map(item => {

                                return <div style={{ color: item.isInPending ? '#ff5e1b' : null }} key={item.id}>{item.name ? item.name : item.mobileNumber}{item.isInPending ? `( منتظر تایید برای مدیریت ) ` : ` ( ${item.roleName} ) `}</div>
                            })}
                        </div>
                        {buildingInfo.pManageAdmins ? <div onClick={() => {
                            setShowModalChgngMangr(true)
                        }} style={{
                            fontSize: 11, borderRadius: 10,
                            border: '1px solid #ff5722', textAlign: 'center',
                            padding: 3, color: '#ff5722'
                        }}>واگذاری مدیریت  </div> : null}
                    </div>

                    <div style={{ color: colors.app7, fontSize: '14px' }}>
                        آدرس
                    </div>
                    <div className={`d-flex align-items-center`}>
                        <div style={{ width: 15, marginLeft: 5 }} ><img alt={'loc'} src={location} style={{ width: '100%' }} /></div>
                        <div className={`fntBold `} style={{ color: colors.app8, fontSize: '12px' }}>{buildingInfo.cityName + ' - '}
                            {buildingInfo.address} پلاک {buildingInfo.plate}
                        </div>
                    </div>
                </div>



                <div className={`text-right rtl w-100 pt-2 pb-2 `} style={{ borderBottom: !wallet && !mojodi ? '' : '2px solid #e8e8e8', lineHeight: '1.8' }} >
                    {mojodi ? <div className={`d-flex align-items-center justify-content-between `}>
                        <div style={{ color: colors.app7, fontSize: '14px' }}> موجودی صندوق:</div>
                        <div className={`fntBold`} style={{ color: colors.app8, fontSize: '12px' }}>{formatMoney(mojodi ? mojodi : 0)}</div>
                    </div> : null}
                    {wallet ? <div className={`d-flex align-items-center justify-content-between `}>
                        <div style={{ color: colors.app7, fontSize: '14px' }}>موجودی کیف‌پول:</div>

                        <div className={`d-flex align-items-center`}>
                            {/* <img style={{ width: '10px', marginLeft: 5 }} src={true ? inc : dec} alt={'alt'} /> */}
                            <div className={`fntBold`} style={{ color: colors.app8, fontSize: '12px' }}>{formatMoney(wallet ? wallet : 0)}</div>
                        </div>
                    </div> : null}
                    {/* <div className={`d-flex align-items-center justify-content-between `}>
                        <div style={{ color: colors.app7, fontSize: '14px' }}> شماره شبا</div>
                        <div className={`fntBold`} style={{ color: colors.app8, fontSize: '12px' }}>{buildingInfo.cashDesks.list[1].shabaNumber != '' ? `IR${buildingInfo.cashDesks.list[1].shabaNumber}` : 'ندارد'}   </div>
                    </div> */}
                </div>
                {buildingInfo.calculationMethods.list.lenght > 0 ? <div className={`text-right rtl w-100 pt-2 pb-2 `} style={{ borderBottom: '2px solid #e8e8e8', lineHeight: '1.8' }} >
                    <div className={`fntBold`} style={{ color: colors.app7, fontSize: '14px' }}>
                        روش تقسیم‌بندی هزینه ساختمان
                    </div>

                    {buildingInfo.calculationMethods.list.map(item => {
                        return (
                            <div key={item.id} className={`d-flex align-items-center`}>
                                <div style={{ width: '10px' }}><img style={{ width: '100%', marginLeft: 10 }} src={check} alt={'alt'} /></div>
                                <div style={{ color: colors.app8, fontSize: '12px' }}>{item.text}</div>
                            </div>
                        );
                    })}
                </div> : null}
                {buildingInfo.utilities.list.length > 0 ?
                    <div className={`text-right rtl w-100 pt-2 pb-2 `} style={{ borderBottom: '2px solid #e8e8e8', lineHeight: '1.8' }} >
                        {buildingInfo.utilities.list.map(item => {
                            return (
                                <div key={item.id} className={`d-flex align-items-center`}>
                                    <div style={{ width: '10px', }}><img style={{ width: '100%', marginLeft: 10 }} src={check} alt={'alt'} /></div>
                                    <div style={{ color: colors.app8, fontSize: '12px' }}> {item.text}</div>
                                </div>
                            )
                        })}
                    </div> : null}
            </div>
                <div style={{ position: 'absolute', bottom: 0, width: '50%', left: '25%' }}>

                    {buildingInfo.pEditBuildingPermissions ?
                        <SBotton title={'ویرایش ساختمان'} gradian click={() => {
                            props.history.push({
                                pathname: '/building/edit',
                                state: {
                                    objInfo1: buildingInfo
                                }
                            });
                        }} /> : null}
                    {/* {buildingInfo.pBtnDelete ? <SBotton title={' حذف ساختمان'} /> : null} */}

                </div>
            </div>
            : null
        )
    }

    const renderUnits = () => {

        return (buildingInfo ?
            <>
                <div className={`d-flex w-100 flex-row-reverse align-items-center justify-content-between`}>
                    {buildingInfo.pBtnGroupEditing ? <div className={`d-flex  flex-start rtl w-100`} style={{ fontSize: 12 }} >
                        <> <div style={{ width: 12, }}><img src={edit} alt={''} style={{ width: '100%' }} /></div>
                            <div className={`mr-2`} onClick={() => {
                                setShowModal(true);
                            }}>ویرایش گروهی واحدها</div></>

                    </div> : null}
                    {buildingInfo.pBtnSendMessage ? <div style={{ width: '30px' }} onClick={() => { selectUnitHandler() }} >
                        <img style={{ width: '100%' }} src={message} />
                    </div> : null}
                    <div className={buildingInfo.pBtnGroupEditing ? `w-25` : `w-100`}>


                        <SearchBar
                            search={search}
                            sortModule={true}
                            onSearch={(e) => { searchHandler() }}
                            click={() => {
                                if (search) {
                                    setSearchText('');
                                    getBuildingUnits(localStorage.getItem('id'), '')
                                    setSearch(false);

                                } else {
                                    setSearch(true);

                                }

                                setSendMess(false);
                            }}
                            clickSort={() => {
                                setFilterModal(true)
                            }} />
                    </div>

                </div>
                {search ?
                    <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                        <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                            style={{
                                padding: 5,
                                border: ' 1px solid grey',
                                borderRadius: 10
                            }}>
                            <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                onKeyUp={event => {

                                    if (event.key === 'Enter') {
                                        searchHandler()
                                    }
                                }}
                                placeholder={'جستجو'}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    // searchHandler()

                                }} />
                            <div style={{ width: 15, }}>
                                <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => { searchHandler() }} />
                            </div>

                        </div>
                        {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                    </div>
                    : null}
                {sendMess ?
                    <div className={`d-flex  justify-content-end align-items-center mt-1 mb-2  w-100`}>
                        <div className={` w-100 text-center`}>
                            <SBotton title={'ارسال پیام'} gradian click={() => {
                                if (selectedUnit.length > 0) {

                                    setShowModalMessage(true);
                                    setMessageData({
                                        text: ''
                                    })
                                    // setMessageData(prev=>({
                                    //     ...prev.MessageData,
                                    //     messageType: 0,
                                    //     userRoleInUnit: 1

                                    // }))
                                } else {

                                    setErrText('هیچ واحدی انتخاب نشده‌است');
                                    setErrType('war');
                                    clearErr();

                                }
                            }} />
                        </div>
                        <div className={'ml-2'} >
                            <Checkbox Name={'selectAll'} checked={selectAll}
                                onChange={(check) => { selectAllHandler(check) }} marginB={'0px'} textStle={{ fontSize: '12px', color: '#9e9e9e' }} />
                        </div>

                    </div> : null}
                {unitList.map(item => {
                    return <div key={item.id} className={`d-flex  justify-content-start align-items-center mt-1 mb-2  w-100`}>
                        <div className={``} style={{ width: sendMess ? '90%' : '100%' }}>
                            <UnitCard name={item.resident ? item.resident.name : 'بدون ساکن'}
                                unit={item.name} floor={item.floor} block={item.blockName}

                                hasApp={item[showType] && item[showType].hasApplication ? item[showType].hasApplication : null}


                                /****************** showType نمایش براساس ساکن یا مالک *********************************** */
                                who={showType == 'resident' ? 'ساکن' : 'مالک'}
                                image={item[showType] ? item[showType].profileImageUrl : null}
                                name={item[showType] ? item[showType].name : ' ندارد'}


                                /*************************showType1 نمایش براساس متراژ نفرات یا بدهکاری و ......  **************************** */
                                additionalData={item[showType1]}
                                textData={showType1 == 'area' ? 'متراژ:' : (showType1 == 'parkingCount' ? 'پارکینگ:' :  (showType1 == 'postalCode' ? 'کدپستی: ' : (showType1 == 'personsCount' ? 'تعداد نفرات:' : '')))}
                                desc={
                                    showType1 == 'residentBedehkari' ? 'بدهکاری ساکن:' :
                                        (showType1 == 'residentBestankari' ? 'بستانکاری ساکن:' :
                                            (showType1 == 'ownerBestankari' ? 'بستانکاری مالک' :
                                                (showType1 == 'ownerBedehkari' ?
                                                    'بدهکاری مالک' : null))
                                        )}
                                amount={((showType1 != 'area') && (showType1 != 'parkingCount') &&  (showType1 != 'postalCode' )&&(showType1 != 'personsCount')) ? item[showType1] : null}


                                /**********************دیتای نمایشی جهت خالی نبودن گوشه سمت چپ اطلاعات واحد******************************* */
                                alternativeText={' نفرات'}
                                alternativeData={item.personsCount}


                                click={() => {

                                    if (!item.isConfirmed && item.pAllowEditUnit) {
                                        props.history.push({
                                            pathname: '/building/unit/editUnit',
                                            state: {
                                                objInfo1: item
                                            }
                                        })
                                    } else if (item.isConfirmed) {
                                        props.history.push({
                                            pathname: '/building/unit',
                                            state: {
                                                unitID: item.id,
                                                unitInfo: item
                                            }
                                        })
                                    }
                                }} />
                        </div>
                        {sendMess ? <div className={`ml-2`} >
                            <Checkbox Name={'selectUNit'} checked={selectAll || selectedUnit.find(elem => elem.id == item.id)} onChange={(check) => {
                                onSelectUnit(check, item)
                            }} marginB={'0px'} textStle={{ fontSize: '12px', color: '#9e9e9e' }} />
                        </div> : null}
                    </div>
                })}
            </> : null
        )
    }
    const tabItems = [
        {
            id: 1,
            title: 'مشخصات',
            content: renderInfo()
        },
        {
            id: 2,
            title: 'واحدها',
            content: renderUnits()
        },

    ]

    const def =
    {
        id: '0',
        text: 'ساختمان خود را انتخاب کنید'
    };

    return (
        <>

            <Layout loaded={true} title={'ساختمان'}
                isHome={false}
                customBack={true}
                path={'/'}



                //btn={tab ?(buildingInfo.pEditBuildingPermissions? true : false) : false }
                // btnName={'ویرایش'} 
                showMenu={true}
                loading={loading} gradian={true}
                displayMenu={!loading} >
                {/* /************************************** */}


                <Modal show={showModalChgngMangr} btn={true} click={() => {
                    setShowModalChgngMangr(false)

                }} >
                    <div className={`rtl text-right mediumF mb-2`}>
                        ‍واگذاری مدیریت به:
                    </div>

                    <CpInput plachodler={'شماره همراه مدیر جدید'}
                        type={"phone"} maxLength={11}
                        value={newManager}
                        onChange={(e) => {
                            if (e.target.value.length <= 11)
                                setNewManager(e.target.value)

                        }} />
                    <div className={`w-75 mx-auto`}>
                        <SBotton gradian sending={sending} title={'ثبت'} click={() => {
                            newManagerHandler()
                        }} />
                    </div>

                </Modal>
                {/* /************************************** */}

                <Modal show={showModalMessage} btn={true} click={() => {
                    setMessageData({
                        // text: '',
                        // userRoleInUnit:null,
                        // messageType: null
                    });
                    setMessageText('');
                    setShowModalMessage(false);
                    //setMessageData({});
                    setSelectedUnit([]);
                    setSendMess(false);
                    setSelectAll(false);
                    setSending(false);


                }}>
                    <div
                        style={{ maxHeight: '70vh', overflow: 'auto' }}
                    >
                        <div className={`text-right rtl fntBold `} style={{ fontSize: 12 }} >ارسال پیام برای {selectedUnit.length} واحد</div>
                        <div className={`w-100 mt-2 mb-2 text-center`}>



                            <div className={`text-right rtl  w-75 mx-auto `}>
                                <Checkbox Type={'Radio'} disabled={MessageData.token ? true : false} textStyle={{ fontSize: '10px' }}
                                    checked={MessageData.userRoleInUnit === 1} Label={' ارسال پیام به ساکنین'}
                                    onChange={checked => {
                                        if (checked) {
                                            let tmp = MessageData;
                                            tmp.userRoleInUnit = 1;
                                            setMessageData(tmp);
                                        }
                                    }} />
                                <Checkbox Type={'Radio'} disabled={MessageData.token ? true : false} textStyle={{ fontSize: '10px' }}
                                    checked={MessageData.userRoleInUnit === 2} Label={'ارسال پیام به مالکین'}
                                    onChange={checked => {
                                        if (checked) {
                                            let tmp = MessageData;
                                            tmp.userRoleInUnit = 2;
                                            setMessageData(tmp);
                                        }
                                    }} />
                            </div>
                            <div className={`text-right rtl  w-75 mx-auto pt-2 pb-2 `} style={{ borderTop: '1px solid grey' }}>
                                <div className={`text-right rtl mediumF mb-2`}>انتخاب نوع پیام:</div>
                                <Checkbox Type={'Radio'} textStyle={{ fontSize: '10px' }} disabled={MessageData.token ? true : false}
                                    checked={(MessageData.messageType === 0)} Label={'ارسال با اولویت نوتیفیکیشن'} Name={'stype'}
                                    onChange={checked => {


                                        if (checked) {
                                            let tmp = MessageData;
                                            tmp.messageType = 0;
                                            setMessageData(tmp);
                                        }
                                    }} />
                                <Checkbox Type={'Radio'} textStyle={{ fontSize: '10px' }} disabled={MessageData.token ? true : false}
                                    checked={MessageData.messageType === 1} Label={'نوتیفیکیشن'} Name={'stype'}
                                    onChange={checked => {

                                        if (checked) {
                                            let tmp = MessageData;
                                            tmp.messageType = 1;
                                            setMessageData(tmp);
                                        }
                                    }} />
                                <Checkbox Type={'Radio'} textStyle={{ fontSize: '10px' }} disabled={MessageData.token ? true : false}
                                    checked={MessageData.messageType === 2} Label={'پیامک'} Name={'stype'}
                                    onChange={checked => {
                                        if (checked) {
                                            let tmp = MessageData;
                                            tmp.messageType = 2;
                                            setMessageData(tmp);
                                        }
                                    }} />
                            </div>
                            <textarea maxlength='700' disabled={MessageData.token ? true : false}
                                className={`w-100 rtl`}
                                style={{
                                    border: '1px solid grey', borderRadius: 10,
                                    padding: 10, fontSize: 15, textAlign: 'right', height: 100
                                }}
                                placeholder={'متن پیام'}
                                value={messageText}
                                onChange={(e) => {

                                    let tmp = MessageData;
                                    tmp.text = e.target.value;

                                    setMessageData(tmp);
                                    setMessageText(e.target.value)
                                }} />
                            <div>
                                <div className={`text-right rtl fntBold mb-2 `} style={{ fontSize: 12 }} >راهنمای متن پیام:</div>
                                <div className={`text-right   `} style={{ fontSize: 14,lineHeight:2 }}>
                                   <div  onClick={patternClicked}> واحد    : [vahed]  </div>
                                   <div  onClick={patternClicked}>  نام ساکن/مالک   :    [name]  </div>
                                   <div  onClick={patternClicked}> بدهی کل شخص   : [bedehi]  </div>
                                    </div>
                            </div>
                            <div className={`text-center rtl mediumF fntBold`}
                                style={{ color: MessageData.validationMessage && MessageData.validationMessage.length>0 ? 'salmon' : '' }}>
                                {MessageData.validationMessage && MessageData.validationMessage.length>0 ? MessageData.validationMessage : MessageData.infoMessage }

                                {/* {MessageData.isValidMessage ? MessageData.infoMessage : MessageData.validationMessage} */}
                            </div>
                        </div>
                        <div className={`w-75 mx-auto`}>

                            <SBotton gradian sending={sending} title={MessageData.isValidMessage ? ' ارسال با هزینه' + formatMoney(MessageData.cost) + 'ریال' : 'تایید پیام'} click={() => {
                                sendMessage()
                            }} />
                        </div>
                    </div>


                </Modal>

                {/* /************************************** */}
                <ModalUploadExcel
                    showModal={showModal}
                    downloadSending={downloading}
                    title={' شما می‌توانید از طریق فایل نمونه زیر اطلاعات واحد را در قالب فایل اکسل وارد کنید.'}
                    close={() => {
                        setShowModal(false);
                    }}
                    sending={sending}
                    download={() => {

                        downloadExelSample();
                    }}
                    upload={(e) => {
                        if (!sending) {
                            if (e.target.files[0]) {
                                uploadFile(e.target.files[0])
                            }
                        }

                    }} />

                <FilterModal show={filterModal} click={() => {
                    setFilterModal(false)
                }}
                    permissions={permissions}
                    submit={(_filterData, showType, showType1) => {
                        setFilterModal(false);
                        setShowType(showType);
                        setShowType1(showType1);
                        filterData = _filterData;
                        setLoading(true);
                        getBuildingUnits(localStorage.getItem('id'), searchText);

                    }} />
                <Toast
                    text={errText}
                    type={errType} />
                <AddBotton title={'اضافه کردن ساختمان '}
                    click={() => { props.history.push('/building/add') }} />
                <div style={{ height: 'inherit' }}>
                    {buildingList.length > 0 ?
                        <>
                            {buildingList.length > 1 ?
                                <div className={`mt-2 mb-3 d-flex justify-content-between flex-row-reverse align-items-center`}>
                                    <NewSelect options={buildingList} plachodler={def} obj={false} sel2={false} value={selectedBulding} closeOther={() => { closeSelect() }}>
                                        <div onClick={(e) => {
                                            buildingList.find(elem => {
                                                if (elem.id == e.target.value) {
                                                    setSelectedBulding(elem);
                                                    localStorage.setItem('id', elem.id);
                                                    setActiveTab(null);

                                                }
                                            })
                                        }}>
                                            {buildingList.map(item => {
                                                return (
                                                    <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                                        buildingList.find(elem => {
                                                            if (elem.id == item.id) {
                                                                setSelectedBulding(elem);
                                                                localStorage.setItem('id', elem.id);
                                                                setActiveTab(null);

                                                            }
                                                        })
                                                    }}>{item.name}</div>
                                                )
                                            })
                                            }
                                        </div>
                                    </NewSelect>
                                    {(buildingInfo && (buildingInfo.pManageAdmins || buildingInfo.pEditBuildingPermissions)) ?
                                        <div className={`mr-3 mt-3 ml-3`} style={{ width: 25 }}>
                                            <img src={settings} alt={''} style={{ width: '100%' }} onClick={() => {
                                                props.history.push({
                                                    pathname: 'building/setting',
                                                    state: {
                                                        managers: buildingInfo.managers,
                                                        pManageAdmins: buildingInfo.pManageAdmins,
                                                        pEditBuildingPermissions: buildingInfo.pEditBuildingPermissions

                                                    }

                                                })

                                            }} />
                                        </div> : null}
                                </div>
                                :
                                buildingList.length == 1 ?
                                    <div className={`mt-2 mb-3 d-flex justify-content-between flex-row-reverse align-items-center`}>
                                        <div className={`w-100 text-right pt-1 pb-1 pr-4 mt-2 mb-2 single`}>
                                            {buildingList[0].name}
                                        </div>
                                        {(buildingInfo && (buildingInfo.pManageAdmins || buildingInfo.pEditBuildingPermissions)) ?
                                            <div className={`mr-3  ml-3`} style={{ width: 25 }}>
                                                <img src={settings} alt={''} style={{ width: '100%' }} onClick={() => {
                                                    props.history.push({
                                                        pathname: 'building/setting',
                                                        state: {
                                                            managers: buildingInfo.managers,
                                                            pManageAdmins: buildingInfo.pManageAdmins,
                                                            pEditBuildingPermissions: buildingInfo.pEditBuildingPermissions

                                                        }

                                                    })

                                                }} />
                                            </div> : null}
                                    </div>
                                    : null
                            }
                            <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`} onClick={props.click}>

                                {tabItems.map(item => {
                                    return (<TabBar title={item.title} key={item.id} id={item.id} activeIndex={activeTab} click={() => {
                                        setActiveTab(item.id);
                                        setRenderItem(item.content);

                                    }} />)
                                })}
                            </div>
                            <div className={`w-100 bb`} style={{ height: activeTab == 1 ? `calc(100% - 14rem)` : `calc(100% - 10rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>{renderItem}</div></> :
                        <EmptyList des={'تاکنون ساختمانی ثبت نشده‌است'} />
                    }
                </div>
            </Layout>
        </>
    );
};

export default withRouter(Building);
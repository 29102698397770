import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../../Components/Layout/Layout';
import NewSelect from '../../Components/NewSelect/NewSelect';
import SearchBar from '../../Components/SearchBar/SearchBar';
import Toast from '../../Components/Toast/Toast';
import { ApiService, MyBuildingList } from '../../value';
import searchImg from '../../Assets/myAsset/loupe.svg';
import Card from '../../Components/Card/Card';
import FilterModal2 from '../../Components/Modal/FilterModal2/FilterModal2';



const History = props => {
    const [loading, setLoading] = useState(true);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [buildingList, setBuildingList] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);
    const [historyList, setHistoryList] = useState([]);
    const [search, setSearch] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [filterData, setFilterData]= useState(null);
    const [searchText, setSearchText] = useState('');





    useEffect(() => {
        getMyBuildingList()
    }, [])

    useEffect(()=>{
        getHistory()
    }, [filterData,filterModal, search ]);

    const getMyBuildingList = () => {
        MyBuildingList(false)
            .then(res => {
                console.log(res);
                let list = res;
                if (list) {
                    setBuildingList(list);
                    setSelectedBulding(list.find(elem => { return elem.id == localStorage.getItem('id') }));
                    getHistory()
                    // getBillsList()
                }
            })
    }


    const getHistory = () => {
        setLoading(true);
        let data = {
            nameLike: searchText,
            pageSize: 1000,
            pageNumber: 0
          }
          if(filterData ){
           // data.sortIsDesc = filterData.sortIsDesc  ;
             
               data.startDate = filterData.startDate;
               data.endDate = filterData.endDate;   
           }
        ApiService('Post', `/Building/UsersHistory/${localStorage.getItem('id')}`, data)
        .then(res=>{
            console.log(res);
            if(res.data.result){
                setHistoryList(res.data.data);
                setLoading(false)

            }else{
                setErrText(res.data.message);
                setErrType('war');
                clearErr()
            }
        })
        .catch(err => {
            setErrText('خطای داخلی سرور');
            setErrType('err');
            setLoading(false);
            clearErr();
            return
        })
    }

    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }



    return (<Layout loaded={true} title={' سوابق'} isHome={false}
        showMenu={true} loading={loading}
        displayMenu={!loading} customBack={true}
        path={'/'}
    >
        <Toast
            text={errText}
            type={errType}
        />
        <FilterModal2 show={filterModal}
            sortInc ={false}
             amount= {false}
              click={() => {
            setFilterModal(false)
        }}
            submit={(filterData) => {
                console.log(filterData);
                setFilterData(filterData)
                setFilterModal(false)
            }} />

        <div style={{ height: 'inherit' }}>
        {buildingList.length > 1 ?
                    <div className={`mt-2 mb-3`} >
                        <NewSelect options={buildingList}  obj={false} sel2={false} value={selectedBulding} >
                            <div onClick={(e) => {
                                buildingList.find(elem => {
                                    if (elem.id == e.target.value) {
                                        setSelectedBulding(elem);
                                        localStorage.setItem('id', elem.id);
                                    }
                                })
                            }}>
                                {buildingList.map(item => {
                                    return (
                                        <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                            buildingList.find(elem => {
                                                if (elem.id == item.id) {
                                                    localStorage.setItem('id', elem.id);
                                                    setSelectedBulding(elem);
                                                    getHistory()


                                                }
                                            })
                                        }}>{item.name}</div>
                                    )
                                })
                                }
                            </div>

                        </NewSelect>
                    </div>
                    :
                    buildingList.length == 1 ?
                        <div className={`w-100 text-right pt-1 pb-1 pr-4 mt-2 mb-2 single`}>
                            {buildingList[0].name}
                        </div>
                        : null
                }

                <div>

                <div >
                            {historyList.length > 0 ? <SearchBar
                                click={() => {
                                    if (search) {
                                        setSearchText('');
                                        setSearch(false);
                                        getHistory()


                                    } else {
                                        setSearch(true);
                                    }
                                }}
                             sortModule = {true}
                            clickSort={() => {
                                setFilterModal(true)
                            }} 
                            /> : null}
                            {search ?
                                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                                        style={{
                                            padding: 5,
                                            border: ' 1px solid grey',
                                            borderRadius: 10
                                        }}>
                                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                            onKeyUp={event => {

                                                if (event.key === 'Enter') {
                                                    console.log(searchText);
                                                   getHistory()
                                                }
                                            }}
                                            placeholder={'جستجو'}
                                            onChange={(e) => {

                                              setSearchText(e.target.value)
                                              if(e.target.value.length == 0){
                                                getHistory()
                                              }
                                              


                                            }} />
                                        <div style={{ width: 15, }} onClick={()=>{
                                            getHistory()
                                        }}>
                                            <img className={`w-100`} src={searchImg} alt={'alt'} />
                                        </div>

                                    </div>
                                    {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                                </div>
                                : null}
                        </div>

                </div>
                <div className={`p-2`} style={{ height: `calc(100% - 8rem)`, overflow: 'auto' }} >
                     {historyList.length>0 &&  historyList.map((item,index) => {
                   return <div className={` p-2 mb-2`} style={{borderBottom:index != historyList.length-1 ? '1px solid grey' : ''}}  key={index} >
                       <div className={``} style={{fontSize:'11px', textAlign:'right', direction:'rtl'}}>{`در تاریخ ${item.date}`} </div>
                       <div className={`mt-2`} style={{fontSize:'12px', textAlign:'right', direction:'rtl'}}>{item.logText}</div>

                </div>
               })}
                </div>
              
        </div>
    </Layout>)
}


export default withRouter(History);

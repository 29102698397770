import { TextField } from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect,forwardRef, useImperativeHandle, useRef, useState } from 'react';
import deepOrange from '@material-ui/core/colors/deepOrange';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import { FaToEnNum, formatMoney } from '../../value';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createMuiTheme({
    typography: {
        fontFamily:'iranyekan',
        fontSize:16
    },
    palette: {
        primary: deepOrange,
    },
    direction: 'rtl',
  
});
const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        marginBottom:15,
        direction: 'rtl',
        borderColor: 'green',
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#424242"
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ff5722"
          },
        //   "& .MuiFormLabel-root" : {
        //       color: "#ff5722"
        //   },
          "& .MuiOutlinedInput-input":{
              padding: '15.5px 12px'
          },
        //   "& .MuiInputLabel-outlined":{
        //     transform: 'translate(-14px, 12px) scale(1)'
        // },
         
          '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
          },
          "& .MuiOutlinedInput-multiline":{
              padding:0
          },
          "& .MuiFormLabel-root.Mui-disabled":{
            color: '#b4b5b961'
          },
        //   ".MuiOutlinedInput-notchedOutline":{
        //       color: '#c5c3c3'
        //   }
        
        
    },
//     root: {
//         "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//             borderColor: "green"
//           },
//           "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
//             borderColor: "red"
//           },
//           "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
//             borderColor: "purple"
//           },
//           "& .MuiOutlinedInput-input": {
//             color: "green"
//           },
//           "&:hover .MuiOutlinedInput-input": {
//             color: "red"
//           },
//           "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
//             color: "purple"
//           },
//           "& .MuiInputLabel-outlined": {
//             color: "green"
//           },
//           "&:hover .MuiInputLabel-outlined": {
//             color: "red"
//           },
//           "& .MuiInputLabel-outlined.Mui-focused": {
//             color: "purple"
//           }
        
// },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        direction: 'rtl',
        marginBottom: 14,
        textAlign:'justify'
        

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    inputsssss: {
        // paddingRight: 10,
        // paddingLeft: 10,
        // textAlign: 'right',
       // fontFamily:'iranyekan',
        fontFamily: 'iranyekan, IRANSans, Tahoma, serif !important',
        // lineHeight:2
    },
    label: {
        left: 'unset',
        right: '25px',
       
        transformOrigin: 'top right',
        fontSize: 16,
    },
    labelRoot: {
        fontFamily:'iranyekan'
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
}));


function CpInput(props, refs) {

    //const type = props.inputType;
    const maxLength = props.maxLength;
    const my_ref = useRef();
    const classes = useStyles();
    const [shrink, setShrink] = useState(false);
    const [value, setValue] = useState(props.value || "");


    useEffect(() => {
        handleValue(props.value);
        if (document.activeElement !== my_ref.current)
            setShrink(!!props.value)
    }, [props.value , props.handleValue]);

  
    const type = props.type;
    let _inputProps = props.inputProps;
    if (type === "number" || type === "phone" || type === "money" || type === "nationalCode") {
        _inputProps = {
            ...props.inputProps,
            inputMode: 'numeric',
            pattern: "[0-9]*"
        }

    }
    if ( type === "postalCode") {
        _inputProps = {
            ...props.inputProps,
            inputMode: 'numeric',
            pattern: "[0-9]{10}"
        }

    }

   

    function handleChange(e) {
        let text = e.target.value;
        handleValue(text);
        if (props.onChange){
            props.onChange(e);
        }
           
    }
  
    const handleValue=(text)=> {
      if (text) {
          if (type === "number") {
              text = FaToEnNum(text);
              if (!isNaN(text) && (text.toString().length <= maxLength || !maxLength)) {
                  setValue(text)
              }
          } else if (type === "phone") {
              text = FaToEnNum(text);
              let max = maxLength;
              if (!max)
                  max = 11;
              if (!isNaN(text) && text.toString().length <= max) {
                  setValue(text)
              }
          } else if (type === "nationalCode" || type === "postalCode") {
              text = FaToEnNum(text);
              let max = maxLength;
              if (!max)
                  max = 10;
              if (!isNaN(text) && text.toString().length <= max) {
                  setValue(text)
              }
          } else if (type === "money") {
              text = text.toString().replace(/,/g, '');
              text = FaToEnNum(text);
              let max = maxLength;
              const maxValue = props.maxValue;
              if (!max)
                  max = 11;
              if (!isNaN(text) && text.length <= max && (!maxValue || maxValue >= text)) {
                  var t = formatMoney(text);
                  setValue(t)
                  // setValue(t == 0 ? '' : t)
              }
          } else if (!maxLength || text.length <= maxLength) {
              setValue(text)
          }
      } else {
          setValue('')
      }
  
  }

  useImperativeHandle(refs, () => ({
    getValue: () => {
        const val = value || props.defaultValue || '';
        return val.replace(/,/g, "");
    },
    setValue: (val) => {
        handleValue(val);
        setShrink(!!val)

    },
    focus() {
        my_ref.current.focus();
    }

}));

    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
            <div style={{position: 'relative', width:props.wdth}}>
                {props.postfix ?
                    <span
                        className={props.perfixClass}
                        style={{
                            position: 'absolute',
                            right: 0,
                            bottom: 5,
                            color: '#989898',
                            fontSize: 10, ...props.postfixStyle
                        }}
                    >{props.postfix}</span>
                    :
                    null}
            <TextField className={`${classes.container} ${classes.root}`} 
            InputProps={{className: classes.inputsssss}}
            {...props}
            inputProps={{..._inputProps,  autoFocus: false, ref: my_ref, lang: "en"}}
            value={value}
            onChange={handleChange}
            type={"text"}
            variant="outlined"
            disabled={props.disabled}
             label={props.plachodler} />
             {props.icon ?
                    <img
                        className={props.iconClass}
                        style={{position: 'absolute',
                        // left: 0,
                         bottom: 8, width: 18, height: 18, ...props.iconstyle}}
                        src={props.icon}
                        alt={""}/>
                    :
                    null}
                {props.perfix ?
                    <span
                        className={props.perfixClass}
                        style={{
                            position: 'absolute',
                            left: 5,
                            bottom: 30,
                            color: '#989898',
                            fontSize: 15, ...props.perfixstyle
                        }}
                    >{props.perfix}</span>
                    :
                    null}
            </div>
        </ThemeProvider>

        </StylesProvider>
        


    );
};

export default forwardRef(CpInput);
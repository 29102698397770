
import React from 'react';
import Modal from '../Modal';
import recyclebin from '../../../Assets/myAsset/recycle-bin.png'
import SBotton from '../../Buttons/SBotton/SBotton';



const DeleteModal = props => {
    return (
        <Modal show={props.show} btn={true} click={props.click} >
            <div style={{ textAlign: 'center' }}>

                <div className={`mx-auto`} style={{ width: '35%' }}>
                    <img className={`w-100`} src={recyclebin} alt={'alt'}  />
                </div>
                <div style={{fontSize:12}}> {props.text}</div> 
                {props.deletable ? <div className={`d-flex justify-content-center w-100`} >
                    <div style={{ width: '25%' }} className={`m-1`}>
                        <SBotton title={'بله'} click={props.delete} />
                    </div>
                    <div style={{ width: '25%' }} className={`m-1`}>
                        <SBotton title={'خیر'} gradian click={props.doNotDelete} />
                    </div>

                </div> : null}

            </div>
        </Modal>
    )
}
export default DeleteModal;

import react , { Component } from 'react';
import { ApiService, formatMoney } from '../../../value';
import CpInput from '../../Input/Input';


import SBotton from '../../Buttons/SBotton/SBotton';
import Card from '../../Card/Card';
import Modal from '../Modal';


const amountList = [
    {
        id: 1,
        amount: 3000000,

    },
    {
        id: 2,
        amount: 2000000,
    },
    {
        id: 3,
        amount: 1000000,
    },

]
let Self = null;
export class ChargeWalletModal extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            activeIndex: null,
            loading: false,
            showModalGate: false,
            gateList:[]
        }
    }

        render(){
            return( <Modal show={this.props.showModalCharge} btn={true} click={this.props.close}>
                     <div className={`d-flex justify-content-around align-items-center`}>
                         {amountList.map(item => {
                             return (
                                 <Card key={item.id}>
                                     <div onClick={() => {
                                         this.setState({
                                            activeIndex : item.id
                                         })
                                         this.AmountValue = item.amount
                                         // setActiveIndex(item.id); setAmountValue(parseInt(FaToEnNum(item.amount)))
                                         }} className={` d-flex flex-column justify-content-center align-items-center align-self-center align-content-center p-1`}
                                         style={{ width: 80, height: 80, border: item.id == this.state.activeIndex ? '2px solid #f74231' : '', borderRadius: 16 }}>
                                         <div className={`mediumF fntBold`}>{formatMoney(item.amount)}</div>
                                         <div className={`grey mediumF`}>ریال</div>
                                     </div>
                                 </Card>
                             )
                         })}
     
                     </div>
     
                     <CpInput plachodler={'مبلغ درخواستی'} type={"money"} perfix={"ریال"} value={this.AmountValue }  ref={e => this.AmountValue = e}  />
                     <div className={`w-75 mx-auto`}>
                         <SBotton title={'پرداخت '} gradian click={() => {
                             this.props.submitPay(this.AmountValue.getValue() )
                         }} />
                     </div>
     
                 </Modal>
            )
        }
}

export default ChargeWalletModal;
import React, { useState } from 'react';
import classes from './BuildingType.module.css'


const BuildingType = props =>{
    const [activeIndex , setActive]= useState(props.activeIndex);

    const toggleClass = (id)=>{
        setActive(id)
      }
   return(
    <div className={ props.active ? classes.active : null }
              onClick={props.click}>{props.title}</div>

  //   <div className={'d-flex flex-row-reverse justify-content-between p-2 align-items-center'} >
  //   {
  //     props.buildingType.map(item =>{
  //       return(
  //         <div key={item.id} className={activeIndex ===item.id ? classes.active : null}
  //          onClick={()=>{toggleClass(item.id)}}>{item.title}</div>
  //       )
  //     })
  //   }
  // </div>

   )
         

  

}
export default BuildingType;
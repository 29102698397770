// import { Calendar } from '@mobiscroll/react';
import React, { useEffect, useState } from 'react';
import AddBotton from '../../Components/Buttons/AddBotton/AddBotton';
import EventCard from '../../Components/Cards/EventCard/EventCard';
import SurveyCard from '../../Components/Cards/SurveyCard/SurveyCard';
import Layout from '../../Components/Layout/Layout';
import SearchBar from '../../Components/SearchBar/SearchBar';
import TabBar from '../../Components/TabBar/TabBar';
import Calender from '../../Components/Cal/Calender';
import NewSelect from '../../Components/NewSelect/NewSelect';
import { ApiService, getBuildingId, MyBuildingList } from '../../value';
import { withRouter } from 'react-router-dom';
import Toast from '../../Components/Toast/Toast';
import EmptyList from '../../Components/EmptyList/EmptyList';
import FilterModal2 from '../../Components/Modal/FilterModal2/FilterModal2';
import searchImg from '../../Assets/myAsset/loupe.svg';







const News = props => {
    const [loading, setLoading] = useState(true);
    const[height, setHeight]= useState();

    const [activeTab, setActiveTab] = useState(null);
    const [renderItem, setRenderItem] = useState(null);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');

    const [buildingList, setBuildingList] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);


    const [eventList, setEventList] = useState([]);
    const [eventDate, setEventDate] = useState([]);

    const [surveyList, setSurveyList] = useState([]);
    const [filterModal, setFilterModal] = useState(false);

    const [search, setSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filterData, setFilterData]= useState(null);


    const [permissions, setPermissions] = useState(false);








    useEffect(() => {
        getMyBuildingList();
    }, [])

    useEffect(() => {
        const Hheight = document.getElementById('contentBx').clientHeight ;
        setHeight(Hheight-200);
        if (activeTab == 1) {
            setRenderItem(renderItem1());
            setLoading(false);
        } else if (activeTab == 2) {
            // getSurveyList(getBuildingId())
            setRenderItem(renderItem2());
            setLoading(false);
            
        } else {
            setRenderItem(renderItem3());
            
            setLoading(false);
        }
    }, [activeTab,height, eventList, surveyList, search, searchText]);

    useEffect(() => {
       
        
            if(activeTab == 1) {
            getEventsList(getBuildingId() );

        }else if(activeTab == 2){
            getSurveyList(getBuildingId())
        }
   
       
    }, [filterData,filterModal, search ]);


    const getMyBuildingList = () => {
        MyBuildingList(false).then(res => {
           // console.log(res);
            let list = res;
            if (list) {
                setBuildingList(list);
                setSelectedBulding(list.find(elem => { return elem.id == getBuildingId() }));
                getEventsList(getBuildingId());
                //getSurveyList(getBuildingId());
            }
        })
        .catch(err=>{
            setActiveTab(3)
            setErrText(err.data.message);
            setErrType('war');
            clearErr();
            setLoading(false);
        })
    }

    const getEventsList = (id) => {

       
        setLoading(true);
        let data = {
           // eventType: null,
            startDate: '',
            endDate: '',
            nameLike: searchText,
            pageSize: 1000,
            pageNumber: 0,
            sortIsDesc: true
        }
        if(filterData ){
         data.sortIsDesc = filterData.sortIsDesc  ;
          // data.eventType =0;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;   
        }
        console.log('dataaaaaaaaa event', data);
        ApiService('Post', `/BuildingEvent/GetList/${id}`, data)
            .then(response => {
                if (response.data.result) {
                    console.log(response);
                    setEventList(response.data.data);
                    setPermissions(response.data.data2)
                   
                    response.data.data.forEach(element => {
                        setEventDate(eventDate =>[...eventDate,element.startDate ])
                        
                    });
                    setActiveTab(1);
                    //setLoading(false);
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    // setTab(false);
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }
    const getSurveyList = (id) => {
        setLoading(true);
        let data = {
            nameLike: searchText,
            pageSize: 100,
            pageNumber: 0,
            sortType  : 1
        }
        if(filterData ){
            if(filterData.sortIsDesc){
                data.sortType  = 1

            }else{
                data.sortType = 0

            }
            // data.sortType = filterData.sortIsDesc  ;
             data.startDate = filterData.startDate;
             data.endDate = filterData.endDate;   
         }
         console.log('dataaaaaaaaa survey', data);
        ApiService('Post', `/Survey/GetList/${id}`, data)
            .then(response => {
                if (response.data.result) {
                    console.log(response);
                    setSurveyList(response.data.data);
                    setPermissions(response.data.data2)
                    setActiveTab(2);
                    // setRenderItem(renderItem2());
                   // setLoading(false);
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    // setTab(false);
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })

    }

    const renderItem1 = () => {
       // console.log(eventList.lengths);
        return (
            <>
                <SearchBar 
            click={() => {
                console.log('lllll', search);
               if(search){
                   setSearchText('');
                   setSearch(false);

               }else{
                   setSearch(true);
               }
           }}  
            sortModule = {true}
            clickSort={() => {
                setFilterModal(true)
            }} />
            {search ?
                    <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                        <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                            style={{
                                padding: 5,
                                border: ' 1px solid grey',
                                borderRadius: 10
                            }}>
                            <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                onKeyUp={event => {

                                    if (event.key === 'Enter') {
                                        getEventsList(getBuildingId());
                                    }
                                }}
                                placeholder={'جستجو'}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                   // searchHandler()

                                }} />
                            <div style={{ width: 15, }}>
                                <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => { 
                                  getEventsList(getBuildingId());
                                     }} />
                            </div>

                        </div>
                        {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                    </div>
                    : null}
                {eventList.length > 0 ?
                    eventList.map(item => {
                        return (
                            <EventCard key={item.id} startDate={item.startDate}
                                startTime={item.startTime}
                                title={item.title}
                                description={item.description}
                                endTime={item.endTime}
                                creator={item.creator.name}
                                profileImage={item.creator.profileImageUrl}
                                click={() => {
                                    props.history.push({
                                        pathname: '/event',
                                        state: {
                                            id: item.id
                                        }
                                    })
                                }} />
                        )
                    })
                    :
                    <EmptyList des={'تاکنون رویدادی ثبت نشده‌است'}/>
                  
                }


            </>
        )

    }

    const renderItem2 = () => {
       // console.log('surveyList', surveyList);
        return (surveyList.length > 0 ?
            <div>
                <SearchBar 
            click={() => {
                console.log('lllll', search);
               if(search){
                   setSearchText('');
                   setSearch(false);
                   getSurveyList(getBuildingId())

               }else{
                   setSearch(true);
               }
           }}  
            sortModule = {true}
            clickSort={() => {
                setFilterModal(true)
            }} />
            {search ?
                    <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                        <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                            style={{
                                padding: 5,
                                border: ' 1px solid grey',
                                borderRadius: 10
                            }}>
                            <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                onKeyUp={event => {

                                    if (event.key === 'Enter') {
                                        getSurveyList(getBuildingId())
                                    }
                                }}
                                placeholder={'جستجو'}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                   // searchHandler()

                                }} />
                            <div style={{ width: 15, }}>
                                <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => { 
                                    getSurveyList(getBuildingId())
                                     }} />
                            </div>

                        </div>
                        {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                    </div>
                    : null}
                {surveyList.map(item => {
                    return <SurveyCard click={() => {
                      //  console.log(item);
                        props.history.push({
                            pathname: '/survey',
                            state: {
                                id: item.id
                            }
                        })
                    }} key={item.id} creator={item.creator.name} createDt={item.createdDate}
                        title={item.title} profileImage={item.creator.profileImageUrl} options={item.options} description={item.description} />
                })
                }
            </div>

            :
            <EmptyList des={'تاکنون نظرسنجی‌ای ثبت نشده‌است'}/>
           
        )

    }

    const renderItem3 = () => {
        return (
            <div >
                <Calender events={eventDate} eventsDetail={eventList} />
            </div>
        )

    }
    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }
    const closeSelect=()=>{
        
        NewSelect&&NewSelect.lastObj&&NewSelect.lastObj.close();
      }


    const def =
    {
        id: '0',
        text: 'ساختمان خود را انتخاب کنید'
    };

    const tabItems = [
        {
            id: 1,
            title: 'رویداد',
            content: renderItem1()
        },
        {
            id: 2,
            title: 'نظرسنجی',
            content: renderItem2()
        },
        {
            id: 3,
            title: 'تقویم‌کاری',
            content: renderItem3()
        },
    ]
    return (

        <Layout loaded={true} title={'اخبار'} isHome={false} showMenu={true} 
        loading={loading} displayMenu={!loading}
        customBack={true}
        path={'/'} >
            <Toast
                text={errText}
                type={errType}
            />
             <FilterModal2 show={filterModal}
             sortInc ={true}
             amount= {false}
              click={() => {
            setFilterModal(false)
        }}
            submit={(filterData) => {
                console.log(filterData);
                setFilterData(filterData)
                setFilterModal(false)
            }} />
            <div style={{ height: 'inherit' }}>
                  
            {buildingList.length > 1 ?
                <div className={`mt-2 mb-3`}>
                    <NewSelect options={buildingList} plachodler={def} obj={false} sel2={false} value={selectedBulding}  closeOther={()=>{closeSelect()}}>
                            {buildingList.map(item => {
                                return (
                                    <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                      buildingList.find(elem => {
                                        if (elem.id == item.id) {
                                          setSelectedBulding(elem);
                                          localStorage.setItem('id', elem.id);
                                          getEventsList(elem.id);
                  
                                        }
                                      })
                                    }}>{item.name}</div>
                                  )
                            })
                            }
                    </NewSelect>
                </div>
                :
                buildingList.length == 1 ?
                    <div className={`w-100 text-right pt-1 pb-1 pr-4 mt-2 mb-2 single`}>
                        {buildingList[0].name}
                    </div>
                    : null
            }
         { permissions && permissions.pBtnInsert ?  <div style={{ display: activeTab == 3 ? 'none' : 'block' }}>
                <AddBotton title={activeTab == 1 ? 'اضافه کردن رویداد' : activeTab == 2 ? 'اضافه کردن نظرسنجی' : ' '} click={() => {
                    if (activeTab == 1) {
                        props.history.push('/addEvent');
                    } else {
                        props.history.push('/addSurvey');
                    }
                }} />
            </div> : null}

            <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`} onClick={props.click}>
                {tabItems.map(item => {
                    return (<TabBar title={item.title} key={item.id} id={item.id} activeIndex={activeTab} click={() => {
                        console.log('eventDate', eventDate);
                       
                           if(item.id==1){
                            getEventsList(getBuildingId() );

                        }else if(item.id==2){
                            getSurveyList(getBuildingId())
                        }else if(item.id==3){
                            setActiveTab(3);
                        }
                   
                        // setActiveTab(item.id);
                        // setRenderItem(item.content);
                    }} />)
                })}
            </div>
            <div className={`w-100 bb `} style={{height: `calc(100% - 12rem)`, overflow: 'auto', paddingRight:3, paddingLeft:3}}>{renderItem}</div>
            </div>
        </Layout>
    )
}
export default withRouter(News);








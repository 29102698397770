import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../../../Components/Layout/Layout';
import searchImg from '../../../Assets/myAsset/loupe.svg';
import user from '../../../Assets/myAsset/user.png'
import { ApiService } from '../../../value';
import Toast from '../../../Components/Toast/Toast';
import EmptyList from '../../../Components/EmptyList/EmptyList';
import mychats from '../../../Assets/myAsset/chat1.svg'
import contacts from '../../../Assets/myAsset/contacts (1).svg'










const ChatContainer = props => {

    const [loading, setLoading] = useState(true);
    const [chatList, setChatList] = useState([]);
    const [contactList, setCantactList] = useState([]);
    const [contactListLoaded, setContactListLoaded] = useState(false);

    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [activeTab, setActiveTab] = useState(1);
    const [searchText, setSearchText] =  useState('')


    useEffect(() => {
        getChatList();
        getContactList()
    }, [])

    useEffect(() => {
        checkLoadALL()
    }, [chatList, contactListLoaded])



    const getChatList = () => {
        ApiService('Get', '/Chat/MyChats')
            .then(res => {
                // console.log(res);
                if (res.data.result) {
                    setChatList(res.data.data);
                    checkLoadALL()

                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr()
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                clearErr();
                return
            })

    }

    const getContactList = () => {
        let data = {
            nameLike: searchText,
            pageSize: 1000,
            pageNumber: 0
        }
        ApiService('Post', '/Chat/Contacts', data)
            .then(res => {
                if (res.data.result) {
                    setCantactList(res.data.data);
                    setContactListLoaded(true)
                    checkLoadALL()

                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr()
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false)
                clearErr();
                return
            })

    }
    const renderLists = () => {
        if (activeTab == 1 && chatList && chatList.length > 0) {
            return (chatList.map((item, index) => {
                return (
                    <div key={index} className={`borderShadow customBorderR p-2 mb-3`}
                        onClick={() => {
                            props.history.push(`/chats/${item._id}`)
                        }}
                        style={{
                            backgroundColor: item._id == '11111111-1111-1111-1111-111111111111' ? '#5f5d5d' : '#f7f7f7',
                            color: item._id == '11111111-1111-1111-1111-111111111111' ? 'white' : 'black'
                        }}>
                        <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 `}>
                            <div className={`d-flex w-100 flex-row-reverse align-items-center `}>
                                <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                                    <img src={item.avatar ? item.avatar : user} style={{ borderRadius: '50%' }} className={`w-100 h-100`} alt={''} />
                                </div>
                                <div style={{ lineHeight: '2' }}>
                                    <div className={` mr-2 text-right rtl`} style={{ fontSize: 12 }}> {item.name}</div>
                                    {item.message && item.message.length > 0 ? <div className={` mr-2 text-right rtl`}
                                        style={{ fontSize: 14, color: item._id == '11111111-1111-1111-1111-111111111111' ? '#d0d0d0' : '#9a9a9e' }}>
                                        {item.message.substring(0, 25)}...
                                   </div> : null}
                                </div>
                            </div>
                            <div className={`d-flex flex-row-reverse align-items-center `}>
                                {/* <Date font={11} date={'15:00  98/05/05'} color={'#fff'} /> */}
                                {item.unReadCount && item.unReadCount > 0 ?
                                    <div style={{
                                        borderRadius: 10,
                                        backgroundColor: 'salmon', fontSize: 10,
                                        padding: 5, width: 17, height: 17,
                                        textAlign: 'center'
                                    }}>{item.unReadCount}</div> : null}
                            </div>
                        </div>
                    </div>
                )
            }))
        } else {
            return (contactList.map((item, index) => {
                return (
                    <div key={index} className={`borderShadow customBorderR p-2 mb-3`}
                        onClick={() => {
                            props.history.push(`/chats/${item._id}`)
                        }}
                        style={{
                            backgroundColor: item._id == '11111111-1111-1111-1111-111111111111' ? '#5f5d5d' : '#f7f7f7',
                            color: item._id == '11111111-1111-1111-1111-111111111111' ? 'white' : 'black'
                        }}>
                        <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 `}>
                            <div className={`d-flex w-100 flex-row-reverse align-items-center `}>
                                <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                                    <img src={item.avatar ? item.avatar : user} style={{ borderRadius: '50%' }} className={`w-100 h-100`} alt={''} />
                                </div>
                                <div style={{ lineHeight: '2' }}>
                                    <div className={` mr-2 text-right rtl`} style={{ fontSize: 12 }}> {item.name}</div>
                                    {item.message && item.message.length > 0 ? <div className={` mr-2 text-right rtl`}
                                        style={{ fontSize: 14, color: item._id == '11111111-1111-1111-1111-111111111111' ? '#d0d0d0' : '#9a9a9e' }}>
                                        {item.message.substring(0, 25)}...
                                   </div> : null}
                                </div>
                            </div>
                            <div className={`d-flex flex-row-reverse align-items-center `}>
                                {/* <Date font={11} date={'15:00  98/05/05'} color={'#fff'} /> */}
                                {item.unReadCount && item.unReadCount > 0 ?
                                    <div style={{
                                        borderRadius: 10,
                                        backgroundColor: 'salmon', fontSize: 10,
                                        padding: 5, width: 17, height: 17,
                                        textAlign: 'center'
                                    }}>{item.unReadCount}</div> : null}
                            </div>
                        </div>
                    </div>
                )
            }))

        }

    }

    const checkLoadALL = () => {
        if (chatList.length > 0 && contactListLoaded) {
            setLoading(false)
        }
    }

    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    return (
        <Layout loaded={true} pose={'fixed'} BxHeight={''} title={activeTab == 1 ? 'چت‌های من '
            : 'مخاطب‌ها'} isHome={false} loading={loading} customBack={true} path={'/'}>
            <Toast text={errText}
                type={errType} />
            <>
                <div style={{ height: '100%', paddingLeft: '1%', paddingRight:'1%' }}>
                    {activeTab == 2 ?<div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                        style={{
                            padding: 5,
                            backgroundColor: '#f7f7f7',
                            borderRadius: 15
                        }}>
                        <input type={'text'} style={{ backgroundColor: '#f7f7f7', width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                            onKeyUp={event => {
                                if (event.key === 'Enter') {

                                   getContactList()
                                }
                            }}
                            placeholder={'جستجو'}
                            onChange={(e) => {
                                
                                setSearchText(e.target.value)
                            }} />
                        <div style={{ width: 15, }}>
                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {  getContactList()
                            }} />
                        </div>
                    </div>
                </div>:null}
                    <div style={{ height: `90%`, overflow: 'auto' }} >
                        {renderLists()}
                    </div>



                </div>
                {/* <div className={`w-100`}>
                <div className={`w-50`}>dd</div>
                <div className={`w-50`}>dssd</div>
            </div> */}
                <div className={`d-flex justify-content-around align-items-center w-100 pt-2 pb-2 `}
                 style={{height:'10%', position: 'fixed', bottom: 0, left: 0, backgroundColor: '#fff' }}>
                    <div className={`text-center `} onClick={() => {
                        setActiveTab(1)
                    }}>
                        <div style={{ width: 35, margin: '0 auto' }}>
                            <img src={mychats} style={{ width: '100%' }} />
                        </div>
                        <div style={{ fontSize: 11, direction: 'rtl', color: 'grey', textAlign: 'center', width: '100%' }}>
                            چت‌های من
                        </div>
                    </div>
                    <div className={`text-center `} onClick={() => {
                        setActiveTab(2)
                    }}>
                        <div style={{ width: 35, margin: '0 auto' }}>
                            <img src={contacts} style={{ width: '100%' }} />
                        </div>
                        <div style={{ fontSize: 11, direction: 'rtl', color: 'grey', textAlign: 'center', width: '100%' }}>
                            مخاطب‌ها
                        </div>
                    </div>

                </div>
            </>

        </Layout>
    )
}
export default withRouter(ChatContainer);




{/* { activeTab == 1 && chatList && chatList.length > 0 ? chatList.map((item, index) => {
                    return (
                        <div key={index} className={`borderShadow customBorderR p-2 mb-3`}
                            onClick={() => {
                                props.history.push(`/chats/${item._id}`)
                            }}
                            style={{
                                backgroundColor: item._id == '11111111-1111-1111-1111-111111111111' ? '#5f5d5d' : '#f7f7f7',
                                color: item._id == '11111111-1111-1111-1111-111111111111' ? 'white' : 'black'
                            }}>
                            <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 `}>
                                <div className={`d-flex w-100 flex-row-reverse align-items-center `}>
                                    <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                                        <img src={item.avatar ? item.avatar : user} style={{ borderRadius: '50%' }} className={`w-100 h-100`} alt={''} />
                                    </div>
                                    <div style={{ lineHeight: '2' }}>
                                        <div className={` mr-2 text-right rtl`} style={{ fontSize: 12 }}> {item.name}</div>
                                        {item.message && item.message.length > 0 ? <div className={` mr-2 text-right rtl`}
                                            style={{ fontSize: 14, color: item._id == '11111111-1111-1111-1111-111111111111' ? '#d0d0d0' : '#9a9a9e' }}>
                                            {item.message.substring(0, 25)}...
                                       </div> : null}
                                    </div>
                                </div>
                                <div className={`d-flex flex-row-reverse align-items-center `}>
                                    {item.unReadCount && item.unReadCount > 0 ?
                                        <div style={{
                                            borderRadius: 10,
                                            backgroundColor: 'salmon', fontSize: 10,
                                            padding: 5, width: 17, height: 17,
                                            textAlign: 'center'
                                        }}>{item.unReadCount}</div> : null}
                                </div>
                            </div>
                        </div>
                    )
                })
                    : 
                    
                     <EmptyList des={'لیست جت شما خالی است'} />
                    }


                     { activeTab == 2 && contactList && contactList.length > 0 ? contactList.map((item, index) => {
                    return (
                        <div key={index} className={`borderShadow customBorderR p-2 mb-3`}
                            onClick={() => {
                                props.history.push(`/chats/${item._id}`)
                            }}
                            style={{
                                backgroundColor: item._id == '11111111-1111-1111-1111-111111111111' ? '#5f5d5d' : '#f7f7f7',
                                color: item._id == '11111111-1111-1111-1111-111111111111' ? 'white' : 'black'
                            }}>
                            <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 `}>
                                <div className={`d-flex w-100 flex-row-reverse align-items-center `}>
                                    <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                                        <img src={item.avatar ? item.avatar : user} style={{ borderRadius: '50%' }} className={`w-100 h-100`} alt={''} />
                                    </div>
                                    <div style={{ lineHeight: '2' }}>
                                        <div className={` mr-2 text-right rtl`} style={{ fontSize: 12 }}> {item.name}</div>
                                        {item.message && item.message.length > 0 ? <div className={` mr-2 text-right rtl`}
                                            style={{ fontSize: 14, color: item._id == '11111111-1111-1111-1111-111111111111' ? '#d0d0d0' : '#9a9a9e' }}>
                                            {item.message.substring(0, 25)}...
                                       </div> : null}
                                    </div>
                                </div>
                                <div className={`d-flex flex-row-reverse align-items-center `}>
                                    {item.unReadCount && item.unReadCount > 0 ?
                                        <div style={{
                                            borderRadius: 10,
                                            backgroundColor: 'salmon', fontSize: 10,
                                            padding: 5, width: 17, height: 17,
                                            textAlign: 'center'
                                        }}>{item.unReadCount}</div> : null}
                                </div>
                            </div>
                        </div>
                    )
                })
                    :
                      <EmptyList des={'لیست مخاطبان شما خالی است'} />
                     } */}



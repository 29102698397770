

import react, { Component } from 'react';
import SBotton from '../../Buttons/SBotton/SBotton';
import Modal from '../Modal';



let Self = null;
export class ModalUploadExcel extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {

        }
    }



    render() {
        return (<Modal show={this.props.showModal} btn={true} click={this.props.close}>
            <div>
            <div className={`w-100 mediumF rtl text-justify mb-2`} >
                       
                        {this.props.title}
             </div>
                    <div className={`w-75 mx-auto`}>
                        <SBotton title={'دانلود نمونه فایل اکسل'} click={this.props.download} sending={this.props.downloadSending} />
                        <div className={`w-100 mx-auto`}>
                            <input name={'excel'}  type="file" hidden id="upload" onChange={!this.props.sending ?this.props.upload : null}
                                accept=".xlsx, .xls, .csv" />
                            <label className={` mt-2 mb-2 w-100`} htmlFor="upload">
                                <SBotton title={' بارگذاری فایل اکسل'} sending={this.props.sending} />
                                {/* // <img style={{ width: '30%', margin: '0 auto', height: '100%' }} src={add} /> */}
                            </label>

                        </div>

                    </div>

            </div>

        </Modal>
        )
    }
}

export default ModalUploadExcel;
import React, {useEffect, useState} from "react"
import classes from "./ServiceProviderOrders.module.css"
import SearchBar from "../../Components/SearchBar/SearchBar"
import searchImg from '../../Assets/myAsset/loupe.svg';
import { useHistory } from "react-router-dom"
import SBotton from "../Buttons/SBotton/SBotton"
import checked from '../../Assets/myAsset/check.svg';
import SendMessageModal from "../SendMessageModal/SendMessageModal"
import Toast from "../Toast/Toast"
import useError from "../../hooks/useError"
import { serviceAxios } from "../../axios"
import ServiceFilterModal from "../Modal/ServiceFilterModal/ServiceFilterModal"
import ReactList from 'react-list';
import LazyLoading from 'react-list-lazy-load';
import DefaultImg from "../../Assets/myAsset/user.png"
import moment from "moment-jalaali";
import EmptyList from "../EmptyList/EmptyList";
import {downloadPdf} from "../../value";

const ServiceProviderOrders = ({setLoading})=>{
    const [orders , setOrders] = React.useState([])
    const [dwnldPdf, setDwnld] = useState(false)

    const [filterData , setFilterData] = React.useState({
        "periodId": null,
        "isArchive": false,
        "isDeleted": false,
        "serviceId": null,
        "startAmount": null,
        "endAmount": null,
        "startDate": "",
        "endDate": "",
        "sortByType": 0,
        "sortIsDesc": true,
        "nameLike": "",
        "pageSize": 10,
        "pageNumber": 1
    })
    const [totalOrders , setTotalOrders] = React.useState(0)
    const [showFilterModal , setShowFilterModal] = React.useState(false)
    const [showSearch , setShowSearch] = React.useState(false)
    const [showMessage , setShowMessage] = React.useState(false)
    const [showMessageModal , setShowMessageModal] = React.useState(false)
    const [selectedOrders , setSelectedOrders] = React.useState([])
    const [selectedOrdersId , setSelectedOrdersI] = React.useState([])
    const [allSelected , setAllSelected] = React.useState(false)
    const {error , setError} = useError();
    const history = useHistory()
    let pageNumber = React.useRef(0)
    let nameLike = React.useRef("")

    React.useEffect(()=>{
        pageNumber.current = 0
        getOrders()
    },[filterData])

    const clearErr = () => {
        setTimeout(() => {
            setError('','');
        }, 3000)
    }
    useEffect(() => {
        if(dwnldPdf)
        { downloadPdfReport();}

    }, [dwnldPdf]);

    const downloadPdfReport = () => {
        setLoading(true);
        downloadPdf('post', `/Order/List/export`, {
            ...filterData,
            startDate : filterData.startDate != "" ?moment(filterData.startDate , "jYYYY/jMM/jDD").format("jYYYY/jMM/jDD") : "",
            endDate : filterData.endDate != "" ?moment(filterData.endDate , "jYYYY/jMM/jDD").add(1 , "days").format("jYYYY/jMM/jDD") : "",
            pageNumber :1
        },true)
            .then(res => {
                setDwnld(false);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                setError('درخواست با خطا مواجه شد ','err');
                clearErr();


            })
    }
    const getOrders = ()=>{
        pageNumber.current++
        setLoading(true)
        serviceAxios.post(
            "/Order/List/list",
            {
                ...filterData,
                startDate : filterData.startDate != "" ?moment(filterData.startDate , "jYYYY/jMM/jDD").format("jYYYY/jMM/jDD") : "",
                endDate : filterData.endDate != "" ?moment(filterData.endDate , "jYYYY/jMM/jDD").add(1 , "days").format("jYYYY/jMM/jDD") : "",
                pageNumber : pageNumber.current 
            }
        ).then(res=>{
            console.log("sssss" , orders)
            console.log("rrrrrrrrrr" , res.data.data)
            pageNumber.current === 1?setOrders([...res.data.data]): setOrders([...orders , ...res.data.data])
            setTotalOrders(res.data.itemsCount)
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
            setError("خطایی رخ داده است. لطفا مجددا تلاش کنید" , "err")
        })
    }

    const onOrderPressed = (order , index)=>{
        if(order.isDelete!=true){
            console.log('abbassssssssssssssss', order)
        if(showMessage){
            const alreadyChoosed = selectedOrders.findIndex(o => o.id === order.id)
            const oldOrderArray = [...selectedOrders]
            const oldIdArray = [...selectedOrdersId]
            alreadyChoosed > -1 ? oldOrderArray.splice(alreadyChoosed , 1) : oldOrderArray.push(order)
            alreadyChoosed > -1 ? oldIdArray.splice(alreadyChoosed , 1) : oldIdArray.push(order.id)
            setSelectedOrders(oldOrderArray)
            setSelectedOrdersI(oldIdArray)
            setAllSelected(false)
        }else{
            history.push(`/service/providerOrderDetails/${order.id}`)
        }
        }
    }

    const allPressed = ()=>{
        const allOrders = allSelected? [] : [...orders]
        const allOrdersId = allSelected? [] : orders.map(o=>o.id)
        setAllSelected(!allSelected)
        setSelectedOrders(allOrders)
        setSelectedOrdersI(allOrdersId)
    }

    const sendMessageClicked = ()=>{
        if(selectedOrders.length > 0){
            setShowMessageModal(true)
            setShowMessage(false)
        }
        else{
            setError("حداقل یک سفارش را انتخاب کنید" , "war")
        }
    }

    const onFilterConfirmed = (filterModel)=>{
        setLoading(true)
        setFilterData({...filterData , ...filterModel})
    }
    
    const nameLikeChanged = (e)=>{
        nameLike.current = e.target.value
        const nl = e.target.value
        setOrders([])
        setTimeout(()=>{    
            if(nl === nameLike.current) {
                setFilterData({...filterData , nameLike : nameLike.current})
            }
        },1000)
    }

    return(
            <div 
                className="d-flex flex-column w-100 mt-1 align-items-center overflow-auto"
                style={{height:'calc(100% - 56px)'}}
            >
               
                <SearchBar
                    click={() => {
                        if (showSearch) {
                            if(showSearch){
                                nameLike.current = ""
                            }
                            setShowSearch(false);
                            filterData.nameLike !== "" && setFilterData({...filterData , nameLike : ""})
    
                        } else {
                            setShowSearch(true);
                        }
                    }}
                    sortModule={true}
                    showMessage={true}
                    clickSort={() => {
                        setShowFilterModal(true)
                    }} 
                    clickMessage={() => {
                        setShowMessage(!showMessage)
                    }}

                    showPdf={true}
                    clickPdf={() => {
                        setDwnld(true)
                    }}
                />
                {
                    showMessage &&
                    <div className="d-flex flex-row w-100 align-items-center">
                        <SBotton
                            title="ارسال پیام"
                            gradian
                            click={sendMessageClicked}
                        />
                        
                        <div 
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                width : 20 , 
                                height : 20 , 
                                margin : 8,
                                backgroundColor : allSelected? "#ff5722" : null,
                                borderRadius : '10%',
                                overflow : "hidden",
                                borderStyle : "solid",
                                borderColor : "#ff5722",
                                borderWidth : 1
                            }}
                            onClick={allPressed}
                        >
                            {
                                allSelected &&
                                <img
                                    src={checked}
                                    
                                    style={{width : 20 , height : 20 , marginRight : "auto" , marginLeft : "auto" , backgroundColor : "#ff5722"}}
                                />
                            }
                        </div>
                    </div>
                }
                {
                    showSearch ?
                    <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                        <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                            style={{
                                padding: 5,
                                border: ' 1px solid grey',
                                borderRadius: 10
                            }}>
                            <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                onKeyUp={event => {

                                    if (event.key === 'Enter') {
                                        
                                    }
                                }}
                                placeholder={'جستجو'}
                                onChange={(e) => {
                                    nameLikeChanged(e)

                                }} 
                            />
                            <div style={{ width: 15, }}>
                                <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                    
                                }} />
                            </div>

                        </div>
                        {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                                <img src={del} style={{ width: '100%' }} />
                            </div> */}

                    </div>:
                    null
                }
                {
                    orders.length > 0?
                    <LazyLoading
                        length={totalOrders}
                        items={orders}
                        onRequestPage={getOrders}
                        pageSize={10}
                    >
                        <ReactList
                            type="uniform"
                            length={orders.length}
                            itemRenderer={(index, key) => {
                                const order = orders[index];
                                return(
                                    <div 
                                        className={`row align-items-center flex-row-reverse ${classes.wrapper}`}
                                        key={index.toString()}
                                        onClick={()=>  onOrderPressed(order , index)}
                                    >
                                        {
                                            showMessage &&
                                            <div 
                                                className="d-flex align-items-center justify-content-center"
                                                style={{
                                                    width : 20 , 
                                                    height : 20 , 
                                                    margin : 8,
                                                    backgroundColor : selectedOrdersId.includes(order.id)? "#ff5722" : null,
                                                    borderRadius : '10%',
                                                    overflow : "hidden",
                                                    borderStyle : "solid",
                                                    borderColor : "#ff5722",
                                                    borderWidth : 1
                                                }}
                                            >
                                                {
                                                    selectedOrdersId.includes(order.id) &&
                                                    <img
                                                        src={checked}
                                                        
                                                        style={{width : 20 , height : 20 , marginRight : "auto" , marginLeft : "auto" , backgroundColor : "#ff5722"}}
                                                    />
                                                }
                                            </div>
            
                                        }
                                        <div 
                                            className="shadow-card d-flex flex-column mb-3 text-right p-2 flex-grow-1 rtl" 
                                        >
                                            <div className="d-flex flex-row justify-content-between">
                                                <div className="d-flex flex-row">
                                                    <img
                                                        src={order.user.profileImageUrl !== ""?order.user.profileImageUrl : DefaultImg}
                                                        style={{
                                                            width : 26,
                                                            height:26,
                                                            borderRadius : "50%",
                                                            marginLeft : 8
                                                        }}
                                                    />
                                                    <span>
                                                        {
                                                            order.user.name
                                                        }
                                                    </span>
                                                </div>
                                                <span>
                                                    {
                                                        order.reservationDate
                                                    }
                                                </span>
                                            </div>
                                            <div className="rtl">
                                                {
                                                    order.titel
                                                }
                                            </div>
                                            <div className="rtl">
                                                {
                                                    order.period.title
                                                }
                                            </div>
                                            <div className="rtl">
                                                {
                                                    "تعداد : " + order.count
                                                }
                                            </div>
                                            <div className="rtl" style={{ color: order.isDelete? 'red':''}}>
                                                {
                                                    `سانس : ${order.period.startTime} الی ${order.period.endTime}`+(order.isDelete?' - حذف شده':'')
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }}
                        />
                    </LazyLoading>:
                    <EmptyList des={"سفارشی یافت نشد"} />
                }
                    
                
                <Toast
                    text={error.errTxt}
                    type={error.errType}
                />
                <ServiceFilterModal
                    show={showFilterModal} 
                    amount={true} 
                    sortInc={true}
                    typeOdDoc={false} 
                    showPeriods={true}
                    showCategory={true}
                    click={() => {
                        setShowFilterModal(false);
                    }}
                    submit={(filterData) => {
                        console.log('>>>>>>>>>>>>>>>>>>',filterData);
                        setShowFilterModal(false)
                        onFilterConfirmed(filterData)
                    }} 
                />

                <SendMessageModal
                    selectedOrders={selectedOrders}
                    showModalMessage={showMessageModal}
                    setShowModalMessage={setShowMessageModal}
                    filterModel={filterData}
                    allInFilter={allSelected}
                    setError={setError}
                /> 
            </div>
    )
}

export default ServiceProviderOrders;
import React from 'react';
import { formatMoney } from '../../../value';
import Card from '../../Card/Card';
import Doer from '../../Doer/Doer';
import dec from '../../../Assets/myAsset/bleachRed.svg';
import inc from '../../../Assets/myAsset/bleachGrn.svg';

const UserDebtCard = props => {
    return (
        <Card width={'100%'} >
            <div className={`w-100 p-2`} style={{ lineHeight: 2 }} onClick={props.click}>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100  `}>
                    <Doer  profileImage={props.item.profileImage}  creator={props.item.name}/>
                    {/* <div className={' fntBold ml-1'} style={{ fontSize: 12 }}>{props.subject}</div> */}

                </div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100  `}>
                    <div className={`rtl text-right`} style={{fontSize:12}}>{props.type == 0 ? 
                    'مبلغ بدهی':'مبلغ بستانکاری'}</div>

                    <div>

                    </div>
                    {/* <div className={`pr-2 pl-2 rtl`} style={{fontSize:14, backgroundColor:' #f7f7f7', borderRadius:15}}>{`${formatMoney(props.item.amount)}  ریال `} </div> */}
                    <div className={`d-flex align-items-center`} style={{fontSize:14, backgroundColor:' #f7f7f7', borderRadius:15}}>
                                <div style={{ fontSize: 10, marginRight: 3 }}>ریال</div>
                                <div style={{ fontSize: 12, }}> {formatMoney(props.item.amount)}</div>
                                <div style={{ width: 20 }}>
                                    <img style={{ width: '50%', marginLeft: 5, marginBottom: 5 }} src={props.type == 0 ? dec : inc} alt={'alt'} />
                                </div>

                            </div>
                </div>
            </div>
        </Card>
    )
}
export default UserDebtCard;
import React, { Component } from 'react';
import Layout from '../../../Components/Layout/Layout';
import CpInput from '../../../Components/Input/Input';
import { Checkbox } from '../../../Components/CheckBox/CheckBox';
import { withRouter } from 'react-router-dom';
import { isUserHomtick, FaToEnNum, ApiService } from '../../../value';
import Toast from '../../../Components/Toast/Toast';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';


let Self = null;
class EditUnit extends Component {
    constructor(props) {
        super(props);
        Self = this;
        // this.unitID = this.props.location.state && this.props.location.state.unitID;
        this.unitInfo = this.props.location.state && this.props.location.state.objInfo1;
        this.state = {

            loading: false,
            errText: '',
            errType: '',

            bed: false,
            bes: false,
            noOne: true,
            showNameOwner: false,
            ownerName: '',
            showName: false,
            ownerTell: '',

            sending:false,

            id: this.unitInfo ? this.unitInfo.id : '',
            name: this.unitInfo ? this.unitInfo.name : '',
            tell: this.unitInfo ? this.unitInfo.tell : null,
            residentName: this.unitInfo ? this.unitInfo.residentName : '',
            blockName: this.unitInfo ? this.unitInfo.blockName : '',
            floor: this.unitInfo ? this.unitInfo.floor : '',
            area: this.unitInfo ? this.unitInfo.area : 0,
            parkingCount: this.unitInfo ? this.unitInfo.parkingCount : 0,
            personsCount: this.unitInfo ? this.unitInfo.personsCount : 0,
            itIsEmpty: this.unitInfo ? this.unitInfo.itIsEmpty : false,
            lastChargeAmount: this.unitInfo ? this.unitInfo.lastChargeAmount : 0,
            debit: this.unitInfo ? this.unitInfo.debit : 0,
            debitType: 1,
            bestankar: this.unitInfo ? this.unitInfo.bestankar : 0,
            postalCode: this.unitInfo ? this.unitInfo.postalCode : '',

            owner:
            {
                id: this.unitInfo && this.unitInfo.owner ? this.unitInfo.owner.id : '',
                mobileNumber: this.unitInfo && this.unitInfo.owner ? this.unitInfo.owner.mobileNumber : '',
                name: ''
            }
            ,
            resident: {
                id: this.unitInfo && this.unitInfo.resident ? this.unitInfo.resident.id : '',
                mobileNumber: this.unitInfo && this.unitInfo.resident ? this.unitInfo.resident.mobileNumber : '',
                name: ''
            }
        }
    }
    componentDidMount() {
        console.log('!!!!!!!!!!!!', this.unitInfo);
        if (!this.unitInfo) {
            this.props.history.push('/building')
        }
    }
    async getUserInfo() {
        const Mobile = FaToEnNum(this.UserMobile.getValue());
        console.log(Mobile);
        if (Mobile.length == 0) {
            this.setState({
                errText: 'شماره موبایل را وارد کنید',
                errType: 'war'
            })
            this.clearErr()
            return
        }
        if (Mobile.length != 11) {
            this.setState({
                errText: 'شماره موبایل قبول نمی‌باشد',
                errType: 'war'
            })
            this.clearErr()
            return
        }
        this.setState({
            loading: true
        })

        await isUserHomtick(Mobile).then(response => {
            console.log(response);
            this.setState({
                resident: {
                    id: response.data.id,
                    name: response.data.name,
                    mobileNumber: response.data.mobileNumber

                },
                residentName: response.data.name,
                tell: response.data.mobileNumber,
                loading: false
            });

        })
            .catch(err => {
                console.log(err);
                this.setState({
                    showName: true,
                    resident: {
                        mobileNumber: Mobile
                    },
                    loading: false
                })
            })
    }
    async getOwnerInfo() {
        const Mobile = FaToEnNum(this.OwnerMobile.getValue());
        console.log(Mobile);
        if (Mobile.length == 0) {
            return
        }
        if (Mobile.length != 11) {
            return
        }
        this.setState({
            loading: true
        })

        await isUserHomtick(Mobile).then(response => {
            console.log('owneeeeeeeer infoooooooo ', response);
            this.setState({
                owner: {
                    id: response.data.id,
                    mobileNumber: this.state.owner.mobileNumber,
                    name: response.data.name
                },

                loading: false,
            });


        })
            .catch(err => {
                console.log('????????????????????????????', this.state.ownerName);
                this.setState({
                    showNameOwner: true,
                    owner: {
                        mobileNumber: Mobile,

                    },
                    loading: false
                })
            })
    }
    submit() {

        if (!this.state.itIsEmpty && (!this.state.resident.mobileNumber || !this.state.personsCount)) {
            this.setState({
                errText: 'شماره همراه ساکن و تعداد ساکنین واحد را وارد کنید ',
                errType: 'war'
            })
            this.clearErr()
            return
        }

        if ((this.state.bed || this.state.bes) && (!parseInt(this.Amount.getValue()) || parseInt(this.Amount.getValue()) === 0)) {
            this.setState({
                errText: ' مبلغ بدهکاری یا بستانکاری را وارد کنید ',
                errType: 'war'
            });
            this.clearErr()
            return
        }

        this.unitInfo.name = this.state.name;
        this.unitInfo.tell = this.state.resident.mobileNumber ? this.state.resident.mobileNumber : '';
        this.unitInfo.owner = (!this.state.owner.id && !this.state.owner.mobileNumber) ? {} : this.state.owner;
        this.unitInfo.residentName = this.state.residentName ? this.state.residentName : '';
        this.unitInfo.blockName = (!this.state.blockName ? '' : this.state.blockName);
        this.unitInfo.postalCode = this.state.postalCode ? this.state.postalCode:'';
        this.unitInfo.floor = this.state.floor;
        this.unitInfo.area = this.state.area ? parseInt(FaToEnNum(this.state.area)) : 0;
        this.unitInfo.parkingCount = this.state.parkingCount ? parseInt(FaToEnNum(this.state.parkingCount)) : 0;
        this.unitInfo.personsCount = this.state.personsCount ? parseInt(FaToEnNum(this.state.personsCount)) : 0;


        if (this.state.resident.mobileNumber) {
            this.unitInfo.debitType = 1;

        } else if (this.state.owner.mobileNumber) {
            this.unitInfo.debitType = 2;

        }

        if (!this.state.itIsEmpty && this.unitInfo.debitType) {
            if (this.state.bed) {
                this.unitInfo.debit = parseInt(this.Amount.getValue()) > 0 ? parseInt(this.Amount.getValue()) : 0;
            } else if (this.state.bes) {

                this.unitInfo.debit = parseInt(this.Amount.getValue()) > 0 ? (-parseInt(this.Amount.getValue())) : 0;
            }
        }
        this.unitInfo.itIsEmpty = this.state.itIsEmpty;
        this.unitInfo.resident = (!this.state.resident.id && !this.state.resident.mobileNumber) ? {} : this.state.resident;
        this.setState({
            sending: true
        })
        // console.log('ققققققققققققق', this.state.owner);
        // console.log('KKKKKKKKKKKKKKKKK', this.unitInfo);
        ApiService('Post', `/Building/SubmitUnits/${localStorage.getItem('id')}`, this.unitInfo)
            .then(res => {
                console.log(res);
                if (res.data.result) {
                    this.setState({
                        errText: 'ویرایش با موفقیت انجام شد.',
                        errType: 'suc',
                        sending: false
                    });
                    this.clearErr();
                    setTimeout(() => {
                    this.props.history.push({
                        pathname: '/building/unit',
                        state: {
                            unitID: res.data.data.id,
                            unitInfo: res.data.data
                        }
                    })
                }, 2000)
                }else{
                    this.setState({
                        errText: res.data.message,
                        errType: 'war',
                        sending: false
                    });
                    

                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType:'err'  ,
                    sending: false    
                });
                this.clearErr();
                return
    
            })

    }


    clearErr() {
        setTimeout(() => {
            this.setState({
                errText: '',
                errType: ''
            });
        }, 2000)

    }
    render() {

        return (
            this.unitInfo ?
                <Layout loaded={true} title={this.unitInfo.name} showMenu={false}  showEdit={false}
                path={'/building'}
            btnName={'ویرایش'}
                    loading={this.state.loading} isHome={false}
                 btn={true} sending={this.state.sending} click={() => { this.submit() }} btnName={'ثبت تغییرات'}
                >
                    <Toast text={this.state.errText} type={this.state.errType} />
                    {/* <div style={{ height: 'inherit' }}> */}
                        <div className={`ml-3 mr-3 rtl pt-2`} style={{ height: `calc(100% - 5rem)`, overflow: 'auto', position: 'relative' }}>
                            <CpInput plachodler={'عنوان واحد'} type={"text"} value={this.state.name}
                                onChange={(e) => {
                                    this.setState({
                                        name: e.target.value
                                    })
                                }} />
                            {!this.unitInfo.isConfirmed ?
                                <>
                                    <CpInput plachodler={'شماره همراه مالک'}
                                        ref={e => this.OwnerMobile = e} type={"phone"}
                                        onChange={(e) => {
                                            if (e.target.value.length == 11) {
                                                this.setState({
                                                    owner: {
                                                        mobileNumber: e.target.value
                                                    },
                                                });
                                            }
                                            if (e.target.value.length == 0) {
                                                this.setState({
                                                    owner: {
                                                        mobileNumber: null
                                                    },

                                                })
                                            }
                                        }}
                                        maxLength={11}
                                        onBlur={() => { this.getOwnerInfo() }}
                                        value={this.state.ownerTell} />

                                    <CpInput disabled={(!this.state.owner.mobileNumber || this.state.owner.mobileNumber.length == 0) || this.state.owner.id}
                                        plachodler={'نام و نام خانوادگی مالک'}
                                        type={"text"}
                                        value={this.state.owner.name}
                                        onChange={(e) => {
                                            // if (!this.state.showNameOwner) {
                                                console.log('iuiuiuiuiu');
                                                this.setState({
                                                    owner: {
                                                        name: e.target.value,
                                                        mobileNumber: this.state.owner.mobileNumber
                                                    },
                                                })
                                            // }

                                        }} />
                                    <div className={`w-100 rtl mb-4`}>
                                        <Checkbox checked={this.state.itIsEmpty ? true : false} textStyle={{ fontSize: '18px' }}
                                            onChange={(check) => {
                                                if (check) {
                                                    this.setState({
                                                        itIsEmpty: true,
                                                        residentName: '',
                                                        personsCount: 0,
                                                        tell: null
                                                    })
                                                } else {
                                                    this.setState({
                                                        itIsEmpty: false,

                                                    })

                                                }
                                            }}
                                            Label="واحد خالی است " />
                                    </div>


                                    <div>
                                        <CpInput disabled={this.state.itIsEmpty} plachodler={'شماره همراه ساکن'}
                                            type={"phone"} maxLength={11}
                                            ref={e => this.UserMobile = e}
                                            value={this.state.resident.mobileNumber}
                                            onChange={(e) => {
                                                if (e.target.value.length == 11) {
                                                    this.setState({
                                                        resident: {
                                                            mobileNumber: e.target.value
                                                        },
                                                    });

                                                }
                                                if (e.target.value.length == 0) {
                                                      this.setState({
                                                        resident: {
                                                            mobileNumber: null
                                                        },
                                                        residentName: ''

                                                    })
                                                }
                                            }}
                                            onBlur={() => { this.getUserInfo() }} />
                                        <CpInput disabled={(
                                            !this.state.resident.mobileNumber || this.state.resident.mobileNumber.length == 0) ||
                                            this.state.resident.id} plachodler={'نام و نام خانوادگی ساکن'}
                                            type={"text"} value={this.state.resident.name} onChange={(e) => {
                                                if (!(!this.state.showName || this.state.itIsEmpty)) {
                                                    let temp = this.state.resident;
                                                    temp.name = e.target.value;
                                                    this.setState({
                                                        residentName: e.target.value,
                                                        resident: temp

                                                    })
                                                }
                                            }} />

                                        <CpInput disabled={this.state.itIsEmpty} plachodler={'تعداد ساکنین'} type={"number"} value={this.state.personsCount} onChange={(e) => {
                                            if (!this.state.itIsEmpty) {
                                                this.setState({
                                                    personsCount: e.target.value
                                                })
                                            }
                                        }} />

                                    </div>

                                </>

                                : null}

                            <div className={`w-50 `}>
                                <CpInput plachodler={'شماره بلوک'} type={"text"} value={this.state.blockName} onChange={(e) => {
                                    this.setState({
                                        blockName: e.target.value
                                    })
                                }} />
                                <CpInput plachodler={'طبقه '} type={"number"} value={this.state.floor} onChange={(e) => {
                                    this.setState({
                                        floor: e.target.value
                                    })
                                }} />
                                <CpInput plachodler={'متراژ'} type={"number"} value={this.state.area} onChange={(e) => {
                                    this.setState({
                                        area: e.target.value
                                    })
                                }} />
                                <CpInput plachodler={'تعداد پارکینگ'} value={this.state.parkingCount} type={"number"} onChange={(e) => {
                                    this.setState({
                                        parkingCount: e.target.value
                                    })
                                }} />


                            <CpInput plachodler={'کدپستی'}  value={this.state.postalCode}type={"postalCode"} onChange={(e) => {
                                this.setState({
                                    postalCode: e.target.value
                                })
                            }} />

                        </div>


                            {!this.unitInfo.isConfirmed&&!this.state.itIsEmpty ?

                                <div className={`text-right rtl pt-2 pb-2`} style={{ borderTop: '1px dotted grey', borderBottom: '1px dotted grey' }}>
                                    <Checkbox Type={'Radio'} Label={'واحد بدهکاری قبلی دارد'} checked={this.state.bed}
                                        onChange={checked => {
                                            if (checked) {
                                                this.setState({
                                                    bed: true,
                                                    bes: false,
                                                    noOne: false
                                                })
                                            }
                                        }} />
                                    <Checkbox Type={'Radio'} Label={'واحد بستانکاری قبلی دارد'} checked={this.state.bes}
                                        onChange={checked => {
                                            if (checked) {
                                                this.setState({
                                                    bes: true,
                                                    bed: false,
                                                    noOne: false
                                                })
                                            }
                                        }} />
                                    <Checkbox Type={'Radio'} Label={'واحد تسویه است'} checked={this.state.noOne}
                                        onChange={checked => {
                                            if (checked) {
                                                this.setState({
                                                    noOne: true,
                                                    bed: false,
                                                    bes: false,


                                                })
                                            }
                                        }} />
                                    {this.state.bed || this.state.bes ?
                                        <CpInput plachodler={'مبلغ*  '} perfix={"ریال"} type={"money"} value={this.Amount}
                                            ref={e => this.Amount = e}
                                        // onChange={(e) => {
                                        //     this.setState({
                                        //         debit: e.target.value
                                        //     })
                                        // }} 

                                        /> : null}
                                </div> : null}









                            {/* <CpInput plachodler={'مبلغ بستانکاری قبلی'} type={"money"} value={this.state.bestankar} onChange={(e) => {
                            this.setState({
                                bestankar: e.target.value
                            })
                        }} /> */}
                            {/* <CpInput plachodler={'شارژ ماهیانه'} type={"money"} value={this.state.lastChargeAmount} onChange={(e)=>{
                             this.setState({
                                lastChargeAmount: e.target.value
                             })
                         }} /> */}
                         {/* <div style={{height:'15%' ,position: 'sticky', display: 'block' ,width:'100%', bottom: '0%',
                                 zIndex:20 ,backgroundColor:'white'}}>
                            <div className={`w-50 mx-auto pb-2  `} style={{
                                 left: '25%'}}>

                                <SBotton gradian title={'ثبت تغییرات'} sending={this.state.sending} click={() => { this.submit() }} />
                            </div>
                        </div> */}
                        </div>
                    {/* </div> */}
                </Layout>
                : <div></div>
        )
    }
}
export default withRouter(EditUnit);
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import PayableCard from '../../../Components/Cards/PayableCard/PayableCard';
import ReceiptState from '../../../Components/Cards/ReceiptState/ReceiptState';
import ReciptCard from '../../../Components/Cards/ReciptCard/ReciptCard';
import Collapsable from '../../../Components/Collapsable/Collapsable';
import Date from '../../../Components/Date/Date';
import Doer from '../../../Components/Doer/Doer';
import EmptyList from '../../../Components/EmptyList/EmptyList';
import GrayLine from '../../../Components/GrayLine/GrayLine';
import Layout from '../../../Components/Layout/Layout';
import DeleteModal from '../../../Components/Modal/DeleteModal/DeleteModal';
import Modal from '../../../Components/Modal/Modal';
import ModalPayDetail from '../../../Components/Modal/ModalPayDetail/ModalPayDetail';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import TabBar from '../../../Components/TabBar/TabBar';
import Toast from '../../../Components/Toast/Toast';
import { ApiService, formatMoney } from '../../../value';
import searchImg from '../../../Assets/myAsset/loupe.svg';
import message from '../../../Assets/myAsset/email.svg';
import warning from '../../../Assets/myAsset/repo.png';

import MessageModal from '../../../Components/Modal/MessageModal/MessageModal';
import MessageModal2 from '../../../Components/Modal/MessageModal2/MessageModal2';



const tabItems = [
    {
        id: 5,
        title: 'مشخصات',

    },
    {
        id: 1,
        title: 'جدول پرداخت',

    },


]
const tabItems1 = [
    {
        id: 5,
        title: 'مشخصات',

    },



]




const ReceiptDetail = props => {
    const [showModal, setShowModal] = useState(false);
    const [showModalMessage, setShowModalMessage] = useState(false);
    const [ShowModalWarning, setShowModalWarning] = useState(false);

    const [showModal2, setShowModal2] = useState(false);
    const [showModalMessage2, setShowModalMessage2] = useState(false);
    const [metaDataCardToCard, setMetaDataCardToCard] = useState([]);


    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(null);
    const [renderItem, setRenderItem] = useState(null);
    const [doc, setDoc] = useState(null);


    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalPay1, setDeleteModalPay] = useState(false);


    const [modalDetail, setModalDetail] = useState(false);

    const [itemInfo, setItemInfo] = useState(null);



    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');

    const [sumPayds, setSumPayds] = useState(null);



    const [cardToCards, setCardToCards] = useState(0);
    const [cash, setCash] = useState(0);
    const [onLine, setOnLine] = useState(0);
    const [wallet, setWallet] = useState(0);

    const [check, setCheck] = useState(0);
    const [bestankari, setBestankari] = useState(0);

    const [exchangeBedehi, setExchangeBedehi] = useState(0);






    const [search, setSearch] = useState(false);
    const [searchText, setSearchText] = useState('');



    const [unPayeds, setUnPayed] = useState([]);
    const [payeds, setPayeds] = useState([]);

    const [partiallyPayeds, setPartiallyPayed] = useState([]);
    const [waittings, setWaitting] = useState([]);
    const [selectedUnitForMess, setselectedUnitForMess] = useState([]);
    const [MessageData, setMessageData] = useState(null);


    const [itemForDel, setItemForDel] = useState(null);

    const [tabItemsList, setTabItemsList] = useState(tabItems)



    const [tabItemsDetail, setTabItemsDetail] = useState(

        [
            {
                id: 1,
                title: 'تسویه شده',

            },
            {
                id: 2,
                title: 'تسویه نشده ',

            },
            {
                id: 3,
                title: 'ناقص',

            },
            {
                id: 4,
                title: 'در انتظار',

            },

        ]
    )

    let docId = props.match.params.id

    // const [openn, setOpenn] = useState(false);
    // let attachedClasses = [classes.collapsible];
    useEffect(() => {
        // if (props.location.state && props.location.state.DocId && localStorage.getItem('id')) {
        getDetailOfDoc(docId, localStorage.getItem('id'))
        // } else {
        //    // props.history.push('/reciept')
        // }

    }, []);



    useEffect(() => {
        if (activeTab < 5) {
            let tmp = null;
            if (activeTab == 1) {
                tmp = payed();
            } else if (activeTab == 2) {
                tmp = unPayed();
            }
            else if (activeTab == 3) {
                tmp = partiallyPayed();
            } else if (activeTab == 4) {
                tmp = waitting();
            }
            setRenderItem(renderPaymentInfo(tmp))
            //setLoading(false);
        }
        else if (activeTab == 5) {
            setRenderItem(renderDetail())
            // setLoading(false);
        }

    }
        , [activeTab, doc, sumPayds, payeds,
            unPayeds, waittings, partiallyPayeds,
            tabItemsDetail, searchText
            , search,
            cardToCards, cash, onLine, wallet, check, , exchangeBedehi]);


    useEffect(() => {
        setData(doc)
    }
        , [searchText])

    const getDetailOfDoc = (id, buildingId) => {
        setLoading(true)
        ApiService('Get', `/AccRequest/RequestDocumentDetail/${buildingId}/${id}`)
            .then(response => {
                console.log('*********************************',response);
                if (response.data.result) {
                    setDoc(response.data.data);
                    setData(response.data.data)
                    if (!response.data.data.pViewPaymentTable) {
                        setTabItemsList(tabItems1);
                        setActiveTab(5)
                    }

                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    // setTab(false);
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })


    }


    const setData = (document) => {
        if (document) {


            let sum = 0;
            let unPayd = [];
            let payed = [];
            let partioalPayed = [];
            let waittingP = [];
            let isPaying = [];


            let cardToCards = 0;
            let online = 0;
            let credit = 0;
            let wallet = 0;
            let bestankari = 0;
            let check = 0;
            let exChangeBdehi = 0

            document.dividedRequests.forEach(element => {

                element.payLogData.forEach(item => {
                    console.log('********', item);
                    if (item.paidMethod != 7) {
                        sum += item.amount;
                    }

                    if (item.paidMethod == 1 || item.paidMethod == 3) {
                        online = online + item.amount

                    } else if (item.paidMethod == 2) {
                        credit += item.amount

                    } else if (item.paidMethod == 4) {
                        cardToCards += item.amount

                    } else if (item.paidMethod == 5) {
                        bestankari += item.amount

                    } else if (item.paidMethod == 6) {
                        check += item.amount

                    } else if (item.paidMethod == 7) {
                        exChangeBdehi += item.amount

                    }

                });
                if (searchText == '' ||
                    //element.area.toString().find(this.searchText)+   .search("ooo") 
                    (element.unit.blockName ? element.unit.blockName.search(searchText) : -1) >= 0 ||
                    (element.unit.floor ? element.unit.floor.search(searchText) : -1) >= 0 ||
                    (element.unit.name ? element.unit.name.search(searchText) : -1) >= 0 ||
                    ((element.unit.owner && element.unit.owner.name) ? element.unit.owner.name.search(searchText) : -1) >= 0 ||
                    ((element.unit.owner && element.unit.owner.mobileNumber) ? element.unit.owner.mobileNumber.search(searchText) : -1) >= 0 ||
                    ((element.unit.resident && element.unit.resident.name) ? element.unit.resident.name.search(searchText) : -1) >= 0 ||
                    ((element.unit.resident && element.unit.resident.mobileNumber) ? element.unit.resident.mobileNumber.search(searchText) : -1) >= 0
                ) {

                    console.log('elementelementelementelement', element);
                    if (element.payStatus == 4) {


                        payed.push(element);
                    } else if (element.payStatus == 0) {
                        unPayd.push(element);
                    } else if (element.payStatus == 1) {
                        waittingP.push(element);
                    } else if (element.payStatus == 3) {
                        partioalPayed.push(element);

                    } else if (element.payStatus == 2) {
                        isPaying.push(element);
                    }

                }

            });
            let temp = tabItemsDetail;
            temp[0].badge = payed.length;
            temp[1].badge = unPayd.length;
            temp[2].badge = partioalPayed.length;
            temp[3].badge = waittingP.length;

            /***********************   set units that havent paied their receipt      ***************************** */
            let unitsForSendingMessage = [];
            unPayd.forEach(element => {
                unitsForSendingMessage.push(element.unit.id)
            });

            partioalPayed.forEach(element => {
                unitsForSendingMessage.push(element.unit.id)
            });

            setselectedUnitForMess(unitsForSendingMessage);

            /********************* set which methods were paied******************************* */
            setCardToCards(cardToCards)
            setCash(credit)
            setOnLine(online)
            setWallet(wallet)
            setBestankari(bestankari)
            setCheck(check)
            setExchangeBedehi(exChangeBdehi)



            setTabItemsDetail(temp)
            if (waittingP.length > 0) {
                setActiveTab(4)
            } else if (unPayd.length > 0) {
                setActiveTab(2)
            } else if (partioalPayed.length > 0) {
                setActiveTab(3)
            } else {
                setActiveTab(1)
            }
            setSumPayds(sum);
            setPayeds(payed);
            setUnPayed(unPayd);
            setPartiallyPayed(partioalPayed);
            setWaitting(waittingP);
            setLoading(false)
        }

    }


    const sendMessage = (data) => {


        if (data) {
            console.log('2', data);
            if (!data.text || data.text.length == 0) {
                setErrText('متن پیام را وارد کنید');
                setErrType('war');
                clearErr();
                return

            }

            if ( MessageData.text.length > 750) {
                setErrText('متن پیام بلند است و قابل ارسال نیست');
                setErrType('war');
                clearErr();
                return

            }
            if (!data.messageType) {
                setErrText('نوع پیام را انتخاب کنید');
                setErrType('war');
                clearErr();
                return

            }




            // setSending(true);
            let data1 = data;
            data1.units = selectedUnitForMess;

            ApiService('Post', `/Message/Send/${localStorage.getItem('id')}`, data1)
                .then(response => {
                    console.log(response);
                    if (response.data.result && response.data.resultCode == 200) {
                        setMessageData(response.data.data);
                        // setSending(false);
                    }
                    else if (response.data.result && response.data.resultCode == 100) {
                        // setSending(false);
                        setShowModalMessage(false);
                        setMessageData({
                            // messageType: 0,
                            // userRoleInUnit: 1,
                            // text: ''
                        });
                        setselectedUnitForMess([]);
                        // setSendMess(false);
                        //setSelectAll(false);
                        setErrText(response.data.message);
                        setErrType('suc');
                        clearErr();


                    } else {
                        setErrText(response.data.message);
                        setErrType('war');
                        clearErr();
                    }
                })
                .catch(err => {
                    setErrText('خطای داخلی سرور');
                    setErrType('err');
                    setLoading(false);
                    clearErr();
                    return
                })

        }
    }


    const deleteReciept = () => {
        setLoading(true)
        ApiService('Get', `/AccRequest/RequestDocumentDelete/${localStorage.getItem('id')}/${doc.id}`)
            .then(res => {
                console.log(res);
                if (res.data.result) {
                    setErrText('سند با موفقیت حذف شد.');
                    setErrType('suc');
                    clearErr();
                    props.history.push('/receipt')
                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr();
                    setLoading(false)

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })

    }
    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 2000)
    }

    const payed = () => {
        return (<>
            {payeds.length > 0 ? payeds.map(item => {
                return <div key={item.unitId}>
                    <PayableCard key={item.id} onClick={() => {
                        setItemInfo(item)
                        setModalDetail(true)
                    }}
                        payable={item.pBtnPayment}
                        subject={`${item.unit.name} ( ${((doc.usersType == 0 || doc.usersType == 2) && item.unit.resident) ?
                            item.unit.resident.name : ((doc.usersType == 1 || doc.usersType == 2) && item.unit.owner) ? item.unit.owner.name : ''})`}
                        date={item.dateCreated}
                        amount={item.amount}
                        usersRole={item.usersRole}
                        payableAmount={item.amountPayable}
                        click={(e) => {
                            props.history.push({
                                pathname: '/pay',
                                state: {
                                    payData: item.payData,
                                    callBackUrl: window.location.href
                                }
                            });
                            e.stopPropagation();

                        }}
                        state={item.payStatus} />
                </div>
            }) :
                <EmptyList des={'فیش  پرداخت شده وجود ندارد'} />}



        </>
        )
    }
    const unPayed = () => {
        return (
            <>
                {unPayeds.length > 0 ? unPayeds.map(item => {
                    return <div key={item.unitId}>

                        <PayableCard key={item.id} onClick={() => {
                            // setItemInfo(item)
                            // setModalDetail(true)
                        }}
                            payable={item.pBtnPayment && item.payStatus != 4}
                            subject={`${item.unit.name} ( ${((doc.usersType == 0 || doc.usersType == 2) && item.unit.resident) ?
                                item.unit.resident.name : ((doc.usersType == 1 || doc.usersType == 2) && item.unit.owner) ? item.unit.owner.name : ''})`}
                            date={item.dateCreated}
                            amount={item.amount}
                            usersRole={item.usersRole}
                            payableAmount={item.amountPayable}
                            click={(e) => {
                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: item.payData,
                                        callBackUrl: window.location.href
                                    }
                                });
                                e.stopPropagation();

                            }}
                            state={item.payStatus} />

                    </div>
                }) :

                    <EmptyList des={'فیش تسویه نشده وجود ندارد'} />}
            </>
        )
    }
    const partiallyPayed = () => {
        return (
            <>
                {partiallyPayeds.length > 0 ? partiallyPayeds.map(item => {
                    // console.log(item);
                    return <div key={item.unitId}>

                        <PayableCard key={item.id} onClick={() => {
                            setItemInfo(item)
                            setModalDetail(true)
                        }}
                            payable={item.pBtnPayment}
                            subject={`${item.unit.name} ( ${((doc.usersType == 0 || doc.usersType == 2) && item.unit.resident) ?
                                item.unit.resident.name : ((doc.usersType == 1 || doc.usersType == 2) && item.unit.owner) ? item.unit.owner.name : ''})`}
                            date={item.dateCreated}
                            amount={item.amount}
                            usersRole={item.usersRole}
                            payableAmount={item.amountPayable}
                            click={(e) => {
                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: item.payData,
                                        callBackUrl: window.location.href
                                    }
                                });
                                e.stopPropagation();

                            }}
                            state={item.payStatus} />


                    </div>
                }) :

                    <EmptyList des={'فیش  پرداخت شده‌ی ناقص  وجود ندارد'} />}
            </>
        )
    }
    const waitting = () => {
        return (
            <>

                {waittings.length > 0 ? waittings.map(item => {
                    console.log('wwwwwwwwww', item);
                    return <div key={item.unitId}>



                        <PayableCard key={item.id}
                            onClick={() => {
                                setItemInfo(item)
                                setModalDetail(true)
                            }}
                            payable={item.pBtnPayment}
                            subject={`${item.unit.name} ( ${((doc.usersType == 0 || doc.usersType == 2) && item.unit.resident) ?
                                item.unit.resident.name : ((doc.usersType == 1 || doc.usersType == 2) && item.unit.owner) ? item.unit.owner.name : ''})`}
                            date={item.dateCreated}
                            amount={item.amount}
                            usersRole={item.usersRole}
                            payableAmount={item.amountPayable}
                            click={(e) => {
                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: item.payData,
                                        callBackUrl: window.location.href
                                    }
                                });
                                e.stopPropagation();

                            }}
                            state={item.payStatus} />

                    </div>
                }) :

                    <EmptyList des={'فیش در انتظار تایید وجود ندارد'} />}
            </>
        )
    }

    const collapseHeader = () => {
        return (
            <div className={`d-flex justify-content-between align-items-center flex-row-reverse`} style={{ width: '90%' }}>
                <div className={`rtl text-right mediumF fntBold `}> جمع کل پرداخت:</div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse fntBold `} >
                    <div className={`pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(sumPayds)}</div>
                    <div className={`grey smallF`}>ریال</div>
                </div>
            </div>


        )
    }
    const collapsContent = () => {
        return (<>
            {cardToCards > 0 ? <div className={`d-flex justify-content-between align-items-center flex-row-reverse backGreyLight p-2 mb-2`} style={{ borderRadius: 15, width: '100%' }}>
                <div className={`rtl text-right mediumF `}> کارت به کارت  :</div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                    <div className={`pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(cardToCards)}</div>
                    <div className={`grey smallF`}>ریال</div>
                </div>
            </div> : null}

            {cash > 0 ? <div className={`d-flex justify-content-between align-items-center flex-row-reverse backGreyLight p-2 mb-2`} style={{ borderRadius: 15, width: '100%' }}>
                <div className={`rtl text-right mediumF `}>  نقدی   :</div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                    <div className={`pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(cash)}</div>
                    <div className={`grey smallF`}>ریال</div>
                </div>
            </div> : null}


            {onLine > 0 ? <div className={`d-flex justify-content-between align-items-center flex-row-reverse backGreyLight p-2 mb-2`} style={{ borderRadius: 15, width: '100%' }}>
                <div className={`rtl text-right mediumF `}> آنلاین  :</div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                    <div className={`pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(onLine)}</div>
                    <div className={`grey smallF`}>ریال</div>
                </div>
            </div> : null}

            {check > 0 ? <div className={`d-flex justify-content-between align-items-center flex-row-reverse backGreyLight p-2 mb-2`} style={{ borderRadius: 15, width: '100%' }}>
                <div className={`rtl text-right mediumF `}> چک  :</div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                    <div className={`pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(check)}</div>
                    <div className={`grey smallF`}>ریال</div>
                </div>
            </div> : null}
            {bestankari > 0 ? <div className={`d-flex justify-content-between align-items-center flex-row-reverse backGreyLight p-2 mb-2`} style={{ borderRadius: 15, width: '100%' }}>
                <div className={`rtl text-right mediumF `}> بستانکاری  :</div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                    <div className={`pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(bestankari)}</div>
                    <div className={`grey smallF`}>ریال</div>
                </div>
            </div> : null}
            {exchangeBedehi > 0 ? <div className={`d-flex justify-content-between align-items-center flex-row-reverse backGreyLight p-2 mb-2`} style={{ borderRadius: 15, width: '100%' }}>
                <div className={`rtl text-right mediumF `}> انتقال بدهی  :</div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                    <div className={`pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(exchangeBedehi)}</div>
                    <div className={`grey smallF`}>ریال</div>
                </div>
            </div> : null}



            {/* <div className={`d-flex justify-content-between align-items-center flex-row-reverse backGreyLight p-2 mb-2`} style={{ borderRadius: 15, width: '100%' }}>
                <div className={`rtl text-right mediumF `}> کارت به کارت  :</div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                    <div className={`pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(1500000)}</div>
                    <div className={`grey smallF`}>ریال</div>
                </div>
            </div> */}
        </>
        )
    }



    const deletePayment = () => {
        ApiService('Post', `/AccRequest/RemovePayData/${localStorage.getItem('id')}/${itemForDel.id}`)
            .then(res => {

                if (res.data.result) {
                    console.log('pppppppppp', docId);
                    setErrText('پرداختی با موفقیت حذف شد');
                    setErrType('suc');
                    setDeleteModalPay(false);
                    // setModalDetail(false);
                    getDetailOfDoc(docId, localStorage.getItem('id'));
                    clearErr();

                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr();

                }

            })
            .catch(err => {

                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })


    }


    const renderDetail = () => {

        return (<div>
            {  doc ? <><div className={`d-flex justify-content-between align-items-center flex-row-reverse mb-2`}>
                <div className={`rtl text-right mediumF `}>مبلغ  :</div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                    <div className={`backGreyLight pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(doc.amount)}</div>
                    <div className={`grey mediumF`}>ریال</div>
                </div>

            </div>
                <div className={`d-flex  align-items-center flex-row-reverse mb-2`} >
                    <div className={`mediumF rtl`} style={{ borderRadius: 25 }} >صادر شده توسط:</div>
                    <div className={`grey mediumF pr-2`}>{doc.requester.name}</div>
                </div>
                <div className={`d-flex  align-items-center flex-row-reverse mb-2`} >
                    <div className={`mediumF rtl`} style={{ borderRadius: 25 }} >صادر شده برای:</div>
                    <div className={`grey mediumF pr-2`}>{doc.usersType == 1 ?
                        'مالکین'
                        :
                        'ساکنین'}</div>
                </div>
                <div className={`d-flex  align-items-center flex-row-reverse mb-2`} >
                    <div className={`mediumF rtl`} style={{ borderRadius: 25 }} >دسته‌بندی:</div>
                    <div className={`grey mediumF pr-2`}>{doc.accountingCategory.text}</div>
                </div>
                <div className={`d-flex  align-items-center flex-row-reverse mb-2`} >
                    <div className={`mediumF rtl`} style={{ borderRadius: 25 }} >مهلت پرداخت:</div>
                    <div className={`grey mediumF pr-2`}>{doc.deadLineDate}</div>
                </div>
                {/* {doc.attachments ? <div className={`rtl`}>
                                <div className={` `} onClick={(e) => {
                                                window.open(doc.attachments[0].path);
                                                e.stopPropagation();
                                            }} style={{
                                    width: 110, height: 80,
                                    backgroundImage: "url(" + doc.attachments[0].path + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div> : null} */}

                <div className={`mediumF text-dark mt-2 mb-3 fntBold text-right rtl`} >توضیحات: </div>
                <div className={`grey mediumF text-right rtl`} style={{ maxHeight: 300, overflow: 'auto', whiteSpace: "pre-wrap" }}>
                    {doc.description}
                </div>

                {doc.files && doc.files.length > 0 ?
                    <div className={`rtl mt-4 d-flex align-content-stretch flex-wrap w-100  justify-content-start align-items-baseline`}>
                        {doc.files.map((item, index) => {
                            return <div key={index}
                            >
                                <div className={` `} onClick={(e) => {
                                    window.open(item.path);
                                    e.stopPropagation();
                                }} style={{
                                    width: 110, height: 80,
                                    backgroundImage: "url(" + item.path + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div>
                        })}

                    </div>


                    : null}





            </> : null}
        </div>)
    }
    const renderPaymentInfo = (action) => {
        return (<div> {  doc ? <>
            <div style={{ marginBottom: 14 }}>
                <Collapsable header={collapseHeader()} content={collapsContent()} />
            </div>

            <div className={`d-flex w-100 align-items-center justify-content-between`}>
                <div style={{ width: '20px' }}>
                    {doc.pBtnSendSms ? <img style={{ width: '100%' }} src={message} onClick={() => { if (waittings.length > 0) { setShowModalWarning(true) } else { setShowModalMessage(true) } }} /> : null}
                </div>
                <div style={{ width: '50%' }} >
                    <SearchBar
                        click={() => {
                            if (search) {
                                setSearchText('');
                                setSearch(false);
                                setData(doc)


                            } else {
                                setSearch(true);
                            }
                        }}



                    // sortModule={true}
                    // clickSort={() => {
                    //     setFilterModal(true);
                    //     setSearchText('');
                    //     setSearch(false);
                    // }} 


                    />
                </div>



            </div>



            {search ?
                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                        style={{
                            padding: 5,
                            border: ' 1px solid grey',
                            borderRadius: 10
                        }}>
                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                            onKeyUp={event => {

                                if (event.key === 'Enter') {
                                    setData(doc)
                                }
                            }}
                            placeholder={'جستجو'}
                            onChange={(e) => {
                                setSearchText(e.target.value);


                            }} />
                        <div style={{ width: 15, }}>
                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                setData(doc)

                            }} />
                        </div>

                    </div>
                    {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                                   <img src={del} style={{ width: '100%' }} />
                                </div> */}
                </div>
                : null}











            <div className={`d-flex flex-row-reverse align-items-center justify-content-between w-100`} >
                {tabItemsDetail.map(item => {
                    //console.log(item);
                    return (<TabBar title={item.title} key={item.id} font={'0.7rem'} badge={item.badge ? item.badge : null}
                        id={item.id} activeIndex={activeTab} click={() => {
                            if (item.id == 1) {
                                setActiveTab(1);
                            } else if (item.id == 2) {
                                setActiveTab(2);
                            } else if (item.id == 3) {
                                setActiveTab(3);

                            } else if (item.id == 4) {
                                setActiveTab(4);

                            }
                        }} />)
                })}
            </div>
            <div className={`w-100 bb`}>{action}</div>
        </> : null}
        </div>)
    }





    const setAnswer = (key, answer) => {
       
        ApiService('Get', `/App/SetQuestion/${key}/${answer}`)
            .then(res => {
                if (res.data.result) {
                    // setErrType('suc');
                    // setErrText('با موفقیت اعمال شد');
                    if(answer == 'YES'){
                        setShowModal2(true)
                    setModalDetail(false);
                    getDetailOfDoc(docId, localStorage.getItem('id'));
                    clearErr();

                    }else{
                        setModalDetail(false);
                        setErrType('suc');
                     setErrText('با موفقیت اعمال شد');
                    getDetailOfDoc(docId, localStorage.getItem('id'));
                    clearErr();

                    }
                    

                } else {
                    setErrType('war');
                    setErrText(res.data.message);
                    clearErr();


                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                clearErr();
                return
            })
    }


    return (<>
        {  doc && tabItemsList ?
         <Layout loaded={true} title={doc.subject} isHome={false} loading={loading} showMenu={false} customBack={true}
            path={'/receipt'} showEdit={doc.pBtnDelete} del={() => {
                setDeleteModal(true)
            }} >
            <Toast
                text={errText}
                type={errType}
            />

            <Modal show={ShowModalWarning} btn={true} click={() => {
                setShowModalWarning(false)
            }}>
                <div style={{ textAlign: 'center' }}>

                    <div className={`mx-auto`} style={{ width: '35%' }}>
                        <img className={`w-100`} src={warning} alt={'alt'} />
                    </div>
                    <div className={`rtl`} style={{ fontSize: 12, textAlign: 'center' }}>
                        ابتدا فیش‌های در انتظار تایید را بررسی کنید.
                </div>
                </div>
            </Modal>
            <MessageModal show={showModalMessage}
                guide={['نام واحد   : [vahed]' ,
                    'نام ساکن/مالک   :    [name]',
                    'مبلغ قابل پرداخت در فیش   :    [amount]',
                    'بدهی کل شخص   : [bedehi]']}
                defaultText={`مالک/ساکن [vahed] ساختمان [sakhteman] ، [name] لطفا فیش خود به مبلغ [amount] ریال را پرداخت فرمایید`}
                ReceiptId={props.match.params.id}
                text={`ارسال پیام یادآوری به ${selectedUnitForMess.length} واحدی که فیش را پرداخت نکرده‌اند `}
                onErr={(txt) => {
                    setErrText(txt);
                    setErrType('war');
                    clearErr();
                    return
                }}
                role={doc.usersType==1?2 : 1}

                selectedUnit={selectedUnitForMess}
                // sendMessage={(messData) => {
                //     console.log('messDatamessDatamessDatamessData', messData);
                //     messData.units = selectedUnitForMess
                //     setMessageData(messData);
                //    // sendMessage(messData)
                // }}
                data={null}
                onClose={() => {
                    setShowModalMessage(false)
                }}
                click={() => {
                    setShowModalMessage(false);

                }}
                isClose={showModalMessage} />

            <Modal show={showModal2} btn={true} click={() => {
                setShowModal2(false)
            }}>
                <div className={`rtl text-right mediumF mb-4`}>
                    {metaDataCardToCard ? `تایید کارت به کارت به مبلغ ${metaDataCardToCard.amount} با موفقیت اعمال شد.` : null}
                </div>
                <div className={`w-50 mx-auto`}>
                    <SBotton title={'ارسال پیام '} gradian click={() => {
                        console.log('پیام');
                        setShowModal2(false)
                        setShowModalMessage2(true)
                    }} />
                </div>


            </Modal>
        {doc.dividedRequests[0]?
            <MessageModal2 show={showModalMessage2} isClose={showModalMessage2}
                text={metaDataCardToCard && metaDataCardToCard.payer ? ` ارسال پیام تایید کارت به کارت به ${metaDataCardToCard.payer.name} ` : null}
                defaultText={metaDataCardToCard ? `کارت به کارت شما به مبلغ ${metaDataCardToCard.amount} ریال توسط مدیر تایید شد.` : null}
                onErr={(txt, type) => {
                    setErrText(txt);
                    setErrType(type);
                    clearErr();
                    return
                }}
                type={true}
                selectedUnit={[doc.dividedRequests[0].unitId]}
                role={metaDataCardToCard.role}
                data={null}
                onClose={() => {
                    setShowModalMessage2(false)
                }}

                click={() => {
                    setShowModalMessage2(false)
                }}
                type={2}
                isClose={showModalMessage2} />
            :null}
            <ModalPayDetail
                subject={doc.subject}
                showModal={modalDetail}
                itemInfo={itemInfo}
                close={() => {
                    setModalDetail(false)
                }}
                setAnswer={(key, answer,elementDetail) => {
                    
                    console.log(key, answer, elementDetail);
                    elementDetail.unitId=doc.dividedRequests[0].unit.id;
                    elementDetail.role=(elementDetail.payer.id==doc.dividedRequests[0].unit.resident.id?1:2)
                    setMetaDataCardToCard(elementDetail)
                   setAnswer(key, answer, elementDetail );

                }}

                delete={(element) => {
                    console.log('??????????/');
                    setItemForDel(element);
                    setDeleteModalPay(true);
                    setModalDetail(false);

                }} />


            <DeleteModal show={deleteModal} deletable={true}
                delete={() => { deleteReciept() }}
                doNotDelete={() => { setDeleteModal(false) }}
                text={'از حذف فیش اطمینان دارید؟'}
                click={() => {
                    setDeleteModal(false)
                }} />


            <DeleteModal show={deleteModalPay1} deletable={true}
                delete={() => { deletePayment() }}
                doNotDelete={() => { setDeleteModalPay(false) }}
                text={'از حذف پرداخت اطمینان دارید؟'}
                click={() => {
                    console.log('vvvvv');
                    setDeleteModalPay(false)
                }} />
            <Modal show={showModal} btn={true} click={() => {
                setShowModal(false);
            }}>
                <div style={{ position: 'absolute', top: 20, right: 16, width: 90 }}>
                    <ReceiptState state={1} />
                </div>
                <div className={`w-100  rtl text-center`} >
                    شارژ آبان ۹۹
             </div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100 mb-2`}>
                    <Doer />
                    <div>
                        <Date date={'98/05/03'} time={'12:00'} font={12} />
                    </div>
                </div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse`}>
                    <div className={`rtl text-right mediumF `}>مبلغ قابل پرداخت:</div>
                    <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                        <div className={`backGreyLight pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(258000000)}</div>
                        <div className={`grey smallF`}>ریال</div>
                    </div>
                </div>
                <div className={`w-75 mx-auto mt-3`}>
                    <SBotton title={' باشه'} gradian />
                </div>

            </Modal>
            {/* <GrayLine /> */}
            <div style={{ width: '100%', textAlign: 'center', fontSize: 12 }}>
                {`صادر شده برای ${doc.usersType == 1 ?
                    'مالکین'
                    : 'ساکنین'}`}
            </div>

            {doc.pBtnPayment ? <div> <SBotton font={13} title={`پرداخت کامل بدهی من  ${formatMoney(doc.payDataForCurrentUser.amount)} ریال`} gradian click={() => {
                props.history.push({
                    pathname: '/pay',
                    state: {
                        payData: doc.payDataForCurrentUser,
                        callBackUrl: window.location.href
                    }
                });
            }} /></div> : null}
            <div style={{ height: 'inherit' }}>
                <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`}>
                    {tabItemsList.map(item => {
                        return (<TabBar title={item.title} key={item.id} id={item.id} length={tabItemsList.length} activeIndex={activeTab} parentmin={activeTab < 5 ? 0 : 5} parentmax={activeTab < 5 ? 4 : 5} click={() => {

                            if (item.id < 5) {
                                setActiveTab(1)
                            } else if (item.id == 5) {
                                setActiveTab(5)
                            }
                            //setActiveTab(item.id);
                            // setRenderItem(item.content);
                        }} />)
                    })}
                </div>
                <div className={`w-100 bb`} style={{ height: `calc(100% - 9rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>{renderItem}</div>
            </div>
        </Layout> : null}
    </>
    );
};

export default withRouter(ReceiptDetail);
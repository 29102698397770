import React from 'react';
import Layout from '../../../Components/Layout/Layout';
import CpInput from '../../../Components/Input/Input';

import { Checkbox } from '../../../Components/CheckBox/CheckBox';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import Modal from '../../../Components/Modal/Modal';
import NewSelect from '../../../Components/NewSelect/NewSelect';
import { ApiService, FaToEnNum, formatMoney, MyBuildingList } from '../../../value';
import { Component } from 'react';
import Toast from '../../../Components/Toast/Toast';

import des from '../../../Assets/myAsset/bleachRed.svg';
import inc from '../../../Assets/myAsset/bleachGrn.svg';
import check from '../../../Assets/myAsset/checked.svg';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import { withRouter } from 'react-router-dom';
import ChargeWalletModal from '../../../Components/Modal/ChargeWalletModal/ChargeWalletModal';
import PayGatesModal from '../../../Components/Modal/PayGatesModal/PayGatesModal';
import RequestMoney from '../../../Components/Modal/RequestMoney/RequestMoney';



const def =
{

    name: 'انتخاب صندوق مقصد',

};


let Self = null;
class FundSetting extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {

            buildingName: '',
            loading: true,
            errText: '',
            errType: '',
            paymentGate: false,
            showModalCharge: false,
            activeIndex: null,
            showMOdalRequestMoney: false,
            showModal: false,
            showDatePicker: false,
            buildingInfo: null,
            closeDate: null,

            getDesks: false,
            desksListFrom: [
                {
                    availableBalance: null,
                    balance: null,
                    deskType: 3,
                    groupId: null,
                    id: 58,
                    isOnline: false,
                    name: "انتخاب صندوق مقصد "
                }
            ],
            selectedFrom: null,
            desksListTo: [{
                availableBalance: 0,
                balance: 0,
                // deskType: 3,
                // groupId: null,
                // id: 58,
                // isOnline: false,
                name: "انتخاب صندوق مقصد "
            }],
            selectedTo: null,
            amount: null

        }


    }
    componentDidMount() {
        if(localStorage.getItem('id'))
        {this.getMyBuildingList();
       
        this.getSourceDesks();}
        else{
            this.props.history.goBack();
        }
    }


    getMyBuildingList() {
        this.setState({ loading: true })
        ApiService('Get', `/AccRequest/BuildingCashDesks/${localStorage.getItem('id')}`)
            .then(response => {
                console.log('&*&*&*&*&*******', response);
                if (response.data.result) {
                    this.setState({
                        buildingInfo: response.data.data,

                    })
                } else {
                    this.setState({
                        errText: response.data.message,
                        errType: 'war',
                        loading: false
                    })
                    this.clearErr();

                }
                this.checkLoadAll()

            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType:'err'  ,
                    loading: false      
                });
                this.clearErr();
    
            })


    }

    checkLoadAll() {
        if (this.state.buildingInfo && this.state.getDesks) {
            this.setState({
                loading: false
            })
        }
    }

    getSourceDesks() {
        this.setState({ loading: true })
        ApiService('Post', `AccRequest/RequestTransferSources/${localStorage.getItem('id')}`, null)
            .then(res => {
                console.log(res);
                if (res.data.result) {
                    this.setState({
                        getDesks: true,
                        desksListFrom: res.data.data,

                    });

                }else{
                    this.setState({
                        errText: res.data.message,
                        errType: 'war',
                        loading: false
                    });
                    this.clearErr();

                }
                this.checkLoadAll()
            })

            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType:'err'  ,
                    loading: false      
                });
                this.clearErr();
    
            })


    }

    closeDeskRquest() {
        let data = {
            toDate: this.state.closeDate
        }
        ApiService('Post', `AccRequest/RequestLockBuildingCashDesk/${localStorage.getItem('id')}`, data)

            .then(res => {
                if (res.data.result) {
                    this.setState({

                        errText: 'بسنن صندوق اعمال شد',
                        errType: 'suc',
                        showModal: false
                    });
                    this.clearErr();

                } else {
                    this.setState({
                        errText: res.data.message,
                        errType: 'war',
                        showModal: false
                    });
                    this.clearErr();
                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType:'err'  ,
                    loading: false      
                });
                this.clearErr();
    
            })
    }
    // getDestinationDesks(deskId) {
    //     ApiService('Post', `/AccRequest/RequestTransferDestinations/${localStorage.getItem('id')}/${deskId}`, null)
    //         .then(res => {
    //             console.log('+++++++++++++', res);
    //             if (res.data.result) {
    //                 this.setState({
    //                     desksListTo: res.data.data,
    //                     loading: false
    //                 });
    //             }
    //         })
    //         .catch(err => {
    //             this.setState({
    //                 errText: err.data.message,
    //                 errType: 'err',
    //             });
    //             this.clearErr();
    //         })
    // }



    submitPay(amount) {
        if (parseInt(amount) < 100000) {
            this.setState({
                errText: 'مبلغ وارد شده مجاز نمی‌باشد',
                errType: 'war',
            })
            this.clearErr()
            return

        }
        this.setState({
            loading: true,
        })

        let data = {};
        data = {
            callBackUrl:  window.location.href,
            amountPayable: parseInt(amount),
            payFor: 1,
            param: localStorage.getItem('id')
        }
        console.log(data);
        ApiService('Post', `/Payment/GeneratePaymentUrl`, data)
            .then(response => {
                console.log(response);
                if (response.data.result) {

                    this.setState({
                        loading: false,
                        showModalGate: true,
                        showModalCharge: false,
                        gateList: response.data.data
                    })


                }else{
                    this.setState({
                        loading: false,
                        errText: response.data.message,
                        errType:'war'


                    })
                    this.clearErr()

                }
            })
    }


    submitTransfer(fData) {
        console.log('*gtgtgtgtgtggtgtgtgt**',fData);
        let data = fData;
        
        this.setState({
            loading: true})
        data.number = `IR`.concat(fData.number);
        ApiService('Post', `/AccRequest/RequestTransferAmount/${localStorage.getItem('id')}`, data)
            .then(res => {
                if (res.data.result) {
                    if(res.data&&res.data.data[0]&&res.data.data[0].id)
                    {
                        this.setState({
                            loading: false,
                            showModalGate: true,
                            showMOdalRequestMoney: false,
                            showModalCharge: false,
                            gateList: res.data.data
                        })
                    }
                    else{
                    this.setState({
                        errText: 'درخواست شما در 48 ساعت آینده بررسی خواهد شد',
                        errType: 'suc',
                        showMOdalRequestMoney: false,
                        loading: false,
                    });  }
                    this.clearErr();
                    this.getMyBuildingList();
                    this.getSourceDesks();
              

                } else {
                    this.setState({
                        errText: res.data.message,
                        errType: 'war',
                        loading: false,
                    })
                    this.clearErr()
                }


            })
            .catch(err => {

                this.setState({
                    loading: false,})   
console.log(err,'errrrrrror');
            })
    }





    clearErr() {
        setTimeout(() => {
            this.setState({
                errText: '',
                errType: '',
            })
        }, 3000)
    }


    closeSelect() {

        this.NewSelect && this.NewSelect.lastObj && this.NewSelect.lastObj.close();
    }
    render() {
        return (
            <>

                { this.state.buildingInfo && this.state.buildingInfo.currentBuilding? <Layout loaded={true}
                    title={`تنظیمات صندوق ${this.state.buildingInfo.currentBuilding.name}`}
                    customBack={true}
                    path={'/fund'}
                    isHome={false}
                    loading={this.state.loading}
                    showMenu={false} >
                    <PayGatesModal
                        showModal={this.state.showModalGate}
                        close={() => {
                            this.setState({
                                showModalGate: false
                            })
                        }}
                        gateList={this.state.gateList} />
                    

                    <ChargeWalletModal
                        showModalCharge={this.state.showModalCharge}
                        close={() => {
                            this.setState({
                                showModalCharge: false
                            })
                        }}
                        submitPay={(amount) => {
                            console.log('1', amount);
                            this.submitPay(amount)

                        }} />
                    <RequestMoney showModalReq={this.state.showMOdalRequestMoney}
                        close={() => {
                            this.setState({
                                showMOdalRequestMoney: false
                            })

                        }}
                        desksListFrom={this.state.desksListFrom}

                        submit={(finalData, isOnlineToOfline) => {
                            if(finalData.number)
                            {finalData.number=finalData.number.toLowerCase().replace('ir','')}
                            if (isOnlineToOfline && (!finalData.number || finalData.number.length!=24 || !finalData.ownerName)) {
                                console.log('12');
                                this.setState({
                                    errText: 'شماره شبا را به درستی و نام دارنده حساب را مشخص کنید',
                                    errType: 'war'
                                });
                                    console.log(finalData,'abbas');
                                this.clearErr();
                                return
                            }
                            
                            if (!finalData.sourceCashDeskId) {
                                console.log('13');
                                this.setState({
                                    errText: 'صندوق مبدا  را مشخص کنید',
                                    errType: 'war'
                                });
                                this.clearErr();
                                return
                            }
                            if (!finalData.amount || finalData.amount == 0) {
                                console.log('14');
                                this.setState({
                                    errText: '  مبلغ را مشخص کنید',
                                    errType: 'war'
                                });
                                this.clearErr();
                                return
                            }
                            if (isOnlineToOfline && (!finalData.sourceCashDeskId || !finalData.destinationCashDeskId)) {
                                console.log('15');
                                this.setState({
                                    errText: 'صندوق مبدا و مقصد را مشخص کنید',
                                    errType: 'war'
                                });
                                this.clearErr();
                                return

                            }
                           
                            this.submitTransfer(finalData);


                        }}
                    />

                    <Modal show={this.state.showModal} btn={true} click={() => {
                        this.setState({
                            showModal: false
                        })
                    }}>

                        <div className={`mediumF text-justify mb-3 mt-3 rtl fntBold `} >
                            در صورت بستن صندوق‌ها امکان ویرایش سند‌های پیش از این تاریخ وجود ندارد
                    </div>
                        <CpInput plachodler={'تاریخ بستن صندوق '} type={"text"} value={this.state.closeDate} onClick={() => {
                            this.setState({
                                showDatePicker: true
                            })
                        }} />

                        <div className={`w-75 mx-auto`}>
                            <SBotton title={'ثبت درخواست'} gradian={true} click={()=>{
                                this.closeDeskRquest()
                            }} />
                        </div>

                    </Modal>
                    <DatePicker showPicker={this.state.showDatePicker} disMiss={() => {
                        this.setState({
                            showDatePicker: false
                        })
                    }} click={(e) => {
                        this.setState({
                            closeDate: e,
                            showDatePicker: false
                        })


                    }} />
                    <Toast
                        text={this.state.errText}
                        type={this.state.errType}
                    />

                    <div style={{ height: 'inherit' }}>
                        <div className={`p-1`} style={{ height: `calc(100% - 2rem)`, overflow: 'auto' }}>
                            <SBotton click={() => {
                                this.setState({
                                    showMOdalRequestMoney: true
                                })
                            }} title={'درخواست وجه / جابه‌جایی بین صندوق‌ها '} />
                            <SBotton click={() => {
                                this.setState({
                                    showModal: true
                                })
                            }} title={'بستن صندوق'} />
                            <div className={`borderShadow  mt-2 mb-2`} style={{ minHeight: '79%', borderRadius: '10px' }}>
                                <div className={`text-center mediumF pt-1 pb-1 text-light`}
                                    style={{ backgroundColor: '#FF5E1B', borderTopLeftRadius: '10px', borderTopRightRadius: 10 }}>لیست صندوق‌ها</div>
                                <div className={`p-2`} style={{ height: '100%', overflow: 'auto', borderRadius: '10px' }}>
                                    {this.state.buildingInfo && this.state.buildingInfo.list.map((item, index) => {
                                        return <div key={index} className={`p-2`} style={{ borderBottom: index != this.state.buildingInfo.list.length-1 ? '1px solid grey' : ''}}>
                                            <div className={`d-flex justify-content-between flex-row-reverse align-items-center p-1  `}>
                                                <div className={`d-flex justify-content-between flex-row-reverse align-items-center  text-right `}>
                                                    <div style={{ width: '10px' }}>
                                                        <img style={{ width: '100%', marginLeft: 10 }} src={check} alt={'alt'} />
                                                    </div>
                                                    <div className={`fntBold`} style={{ fontSize: 12 }}>  {item.name}</div>
                                                </div>



                                                <div className={`d-flex`}>
                                                    {/* <div className={`p-1`}
                                                        style={{ fontSize: 10, border: '1px solid #FF5E1B', margin: 3, borderRadius: 10, color: '#FF5E1B' }}  >
                                                        {item.isOnline ? 'درخواست وجه' : 'انتقال وجه'}
                                                    </div> */}
                                                    {item.isOnline ?
                                                        <div className={`p-1`} style={{ fontSize: 10, border: '1px solid #FF5E1B', margin: 3, borderRadius: 10, color: '#FF5E1B' }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    showModalCharge: true
                                                                })
                                                            }}  >
                                                            افزایش اعتبار
                                                    </div> : null}
                                                </div>

                                            </div>
                                            {item.balance != 0 ?
                                                <div className={`d-flex justify-content-between flex-row-reverse  align-items-center pr-2 pl-2 `}>
                                                    <div className={`rtl`} style={{ fontSize: 12, }}> موجودی:</div>
                                                    <div className={`d-flex justify-content-between   align-items-center`}>
                                                        <div className={`grey mediumF `} style={{ marginRight: 5 }}>ریال</div>
                                                        <div style={{ fontSize: 14 }}>
                                                            {formatMoney(item.balance)}
                                                        </div>

                                                        {item.availableBalance && item.availableBalance != 0 ?
                                                            <div style={{ width: 10, height: 10, display: 'flex', marginLeft: 5 }}>
                                                                <img className={`w-100`} src={item.availableBalance > 0 ? inc : des} />
                                                            </div> : null}
                                                    </div>
                                                </div>
                                                : <div style={{ fontSize: 14 }}>
                                                    <div className={`d-flex justify-content-between flex-row-reverse    align-items-center`}>
                                                        <div className={`rtl`} style={{ fontSize: 12, }}> موجودی:</div>
                                                        <div className={`d-flex justify-content-between   align-items-center`}>
                                                            <div className={`grey mediumF `} style={{ marginRight: 5 }}>ریال</div>
                                                            <div style={{ fontSize: 14 }}>
                                                                0
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>

                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                </Layout> : null}
            </>
        );
    }
};

export default withRouter(FundSetting);


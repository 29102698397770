import React from 'react';
import Card from '../Card/Card';
import UserImg from '../../Assets/myAsset/user.png';
import classes from "./ProviderImage.module.css"


const ProviderImage = ({provider , onClick}) => {
    return (
        <div className='col-6' onClick={onClick}>
            <Card width={"100%"} height={"180px"} >
                <div className={`m-3 text-center w-100`}>
                    <img
                        src={provider.provider.image?provider.provider.image.thumbnileImagePath : UserImg}
                        style={{ 
                            width: "80%",
                            height : "90px"
                        }
                    }/>
                    <div className={`rtl mt-3 w-100 overflow-hidden ${classes.text}`}>{provider.providerName}</div>
                </div>
            </Card>
        </div>
    )
}
export default ProviderImage;
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Layout from '../../Components/Layout/Layout';
import OthersChat from '../../Components/Cards/OthersChat/OthersChat';
import MyChat from '../../Components/Cards/MyChat/MyChat';
import send from '../../Assets/myAsset/paper-plane.svg'
import attach from '../../Assets/myAsset/attachment-clip.svg'
import { ApiService, attachFile } from '../../value';
import { DeviceUUID } from 'device-uuid'
import GrayLine from '../../Components/GrayLine/GrayLine';




let Self = null;
class ChatTest extends Component {


    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            uploading: false,
            errText: '',
            errType: '',
            chatDetailsList: [],
            chatUserData: null,
            loading: true,
            textMess: '',
            img: {
                imgId: null,
                imgPath: null
            },
            footerW:0
        }
        this.mesRef = React.createRef();
        this.userId = this.props.match.params.id;
    }

    componentDidMount() {
        // this.setState({
        //     footerW: document.getElementById('baseBx').clientWidth
        // });
        
        this.getlastchat();


    }

    componentDidUpdate(prevProps) {
        if (this.props.newchats != prevProps.newchats) {
            if (this.props.newchats.user && (this.props.newchats.user._id == this.userId || this.props.newchats.user._id == JSON.parse(localStorage.getItem('profile')).id)) {
                console.log('pppppppppp', this.props.newchats);
                let isexist = false;
                if (this.state.chatDetailsList)
                    for (let i = 0; i < this.state.chatDetailsList.length; i++) {
                        if (this.state.chatDetailsList[i].localId == this.props.newchats.localId) {
                            let tmp = this.state.chatDetailsList;
                            tmp[i] = this.props.newchats.localId;
                            this.setState({
                                chatDetailsList: tmp
                            });
                            isexist = true;
                            break;
                        }
                    }
                if (!isexist) {

                    let tmp = [this.props.newchats];
                    if (this.state.chatDetailsList)
                        this.setState({ chatDetailsList: tmp.concat(this.state.chatDetailsList) });
                    else
                        this.setState({ chatDetailsList: tmp });
                    this.seen(this.props.newchats._id)
                }
            }
        }
        if (this.props.seenMessage != prevProps.seenMessage ) {
            let gg=false;
            for (let i = 0; i < this.state.chatDetailsList.length; i++) {
                if (gg|| (this.state.chatDetailsList[i]._id == this.props.seenMessage.mesid && this.state.chatDetailsList[i].user._id == this.props.seenMessage.user)) {
                    let tmp = this.state.chatDetailsList;
                    tmp[i].received = true;

                    gg=true;
                    this.setState({
                        chatDetailsList: tmp
                    });
                   
                }
            }

        }

    }




    getlastchat() {

        let data = {
            pageSize: 1000,
            pageNumber: 0,
            nameLike: ""
        }
        ApiService('Post', `/Chat/ChatMessages/${this.userId}`, data).then(response => {
            if (response.data.result) {
                this.setState({
                    chatDetailsList: response.data.data,
                    chatUserData: response.data.data2,
                    loading: false
                });
                if (response.data.data && response.data.data.length > 0 && !response.data.data[0].messageIsForYou) {
                    this.seen(response.data.data[0]._id)
                }

                this.scrollToBottom();


            } else {
                this.setState({
                    errText: response.data.message,
                    errType: 'war'
                });
                setTimeout(() => {
                    this.clearErr();
                }, 3000);

            }

        })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    loading: false
                });
                this.clearErr();

            })

    }
    clearErr() {
        this.setState({
            errText: '',
            errType: ''
        });
    }
    seen(id) {
        let data = {
            id: id,
            userId: this.userId,
            actionType: 0
        }
        ApiService('Post', `/Chat/SetMessageAction`, data).then(response => {

        })

    }
    delete(id) {
        let data = {
            id: id,
            userId: this.userId,
            actionType: 1
        }
        ApiService('Post', `/Chat/SetMessageAction`, data).then(response => {
            if (response.data.result) {
                console.log('chatDetailsList', this.state.chatDetailsList);
                this.setState({
                    chatDetailsList: this.state.chatDetailsList.filter(elem => {
                        return elem._id != id
                    })
                })


            }

        })

    }


    uploadFile(files) {
        this.setState({
            uploading: true
        })

        attachFile(files).then(res => {
            this.setState({
                img: {
                    imgId: res[0].id,
                    imgPath: res[0].path,
                },
                uploading: false
            }, () => { this.sendMessage() })
        })
            .catch(err => {

            })

    }
    generateLocalId() {
        for (let i = 0; i < this.state.chatDetailsList.length; i++) {
            if (this.state.chatDetailsList[i]._id) {
                return new DeviceUUID().get() + '_' + (`${this.state.chatDetailsList[i]._id + 1 + i}`)

            } else if (this.state.chatDetailsList[i]._id + 1 == this.state.chatDetailsList.length) {
                return new DeviceUUID().get() + '_' + (`${1 + i}`)
            }
        }
        return new DeviceUUID().get() + '__1'

    }

    scrollToBottom = () => {
        this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight;
    };
    sendMessage = () => {
        console.log('this.state.img.imgIdthis.state.img.imgIdthis.state.img.imgId', this.state.img.imgId);
        if (this.state.textMess.length > 0 || this.state.img.imgId) {
            let data = {

                _id: 0,
                text: this.state.textMess,
                user: {
                    _id: this.userId,

                },
                localId: this.generateLocalId(),
                messageIsForYou: true,
                pBtnDelete: true
            }
            if (this.state.img.imgId) {
                data.image = `${this.state.img.imgId}`

            }
            let tmp = [data];
            //     this.setState({ chatDetailsList: tmp.concat(this.state.chatDetailsList) });
            console.log('dataaaa', data);
            ApiService('Post', `/Chat/SendMessage`, data)
                .then(res => {
                    this.setState({
                        textMess: ''
                    })
                    if (res.data.result) {
                        let tmp1 = [res.data.data];
                        this.setState({ chatDetailsList: tmp1.concat(this.state.chatDetailsList) });
                        this.scrollToBottom()
                        // window.location.reload();

                    } else {
                        this.setState({
                            errText: res.data.message,
                            errType: 'war'
                        });
                        setTimeout(() => {
                            this.clearErr();
                        }, 3000);
                    }
                })
                .catch(err => {
                    this.setState({
                        errText: 'خطای داخلی سرور',
                        errType: 'err',
                    });
                    this.clearErr();

                })
        }


    }



    updateBasedOnChanges(gholi) { }



    render() {
        return (

            <div id={'heed'} style={{ maxWidth: 450, margin: '0 auto', position: 'relative' }}>
               
                <div id={'section'}></div>
                <div id={'footer'}></div>
            </div>


            


          
        )
    }
}

export default withRouter(ChatTest);




{/* <Layout loaded={true} pose={''} title={this.state.chatUserData ? this.state.chatUserData.name : ''}
                avatar={this.state.chatUserData && this.state.chatUserData.avatar ? this.state.chatUserData.avatar : null}
                loading={this.state.loading} isHome={false} showMenu={false} paddSet={true} headerPos={'fixed'} headerPad={'5px'} >

                <div className={``} style={{ height: '100%' }}>
                    <div style={{ height: 'calc(100% - 50px)', overflow: 'auto', padding: '2%', marginTop:'50px' }} ref={this.mesRef}>
                        <GrayLine />
                        {
                            this.state.chatDetailsList ? this.state.chatDetailsList.map((item1, index) => {
                                return (item1.messageIsForYou ?
                                    <MyChat key={index} item={item1} delete={() => {
                                        console.log(item1._id);
                                        this.delete(item1._id)

                                    }} /> : <OthersChat key={index} item={item1} />);
                            }).reverse() : null
                        }
                       
                    </div>
                    <div className={` pl-2 pr-2 d-flex flex-row  justify-content-between rtl align-items-center pt-2 pb-2`} 
                    style={{ 
                        position:'fixed',
                        width:this.state.footerW,
                        // height: '15%',
                     bottom: 0 }}>
                     


                        <div className={` text-center`} style={{ width: '10%' }} >
                            <input type="file" id="upload" hidden onChange={(e) => {
                                if (e.target.files[0]) {
                                    console.log(e.target.files[0]);
                                    this.uploadFile(e.target.files[0])
                                }
                            }} />
                            <label className={`text-center `} style={{ width: '70%' }} htmlFor="upload">
                                <div style={{ width: '100%' }}>
                                    <img style={{ width: '100%', margin: '0 auto', height: '100%' }} src={attach} />
                                </div>
                            </label>
                        </div>
                        <div style={{ width: '100%' }}>
                            <div className={`p-2 d-flex flex-row w-100 justify-content-between rtl align-items-center`} style={{ backgroundColor: '#f7f7f7', borderRadius: 10 }}>
                                <input className={`w-100 `} type={'text'} placeholder={'تایپ کنید'} value={this.state.textMess}
                                 onKeyUp={event => {

                                    if (event.key === 'Enter') {
                                        this.sendMessage()
                                    }
                                }}
                                    onChange={(e) => {
                                        this.setState({
                                            textMess: e.target.value
                                        })
                                    }}
                                    style={{ border: '0', backgroundColor: '#f7f7f7' }} />
                                <div style={{ width: 20 }} onClick={() => {
                                    this.sendMessage()
                                }}>
                                    <img className={`w-100`} src={send} />
                                </div>
                            </div>

                        </div>




                    </div>
                    

                </div>


            </Layout> */}

import React from "react"
import classes from "./ServiceInfo.module.css"
import User from "../../Assets/myAsset/user.png"

const ServiceInfo = ({service})=>{

    console.log(service)
    return(
        <div 
            className={`d-flex flex-column p-3 px-4 w-100 ${classes.card}`}
        >
            <div className="row flex-row-reverse align-items-center">
                <img
                    src={(!!service.service.image && service.service.image.thumbnileImagePath !== "")?service.service.image.thumbnileImagePath:User}
                    className={`${classes.img}`}
                />
                <span className="mx-2">
                    {
                        service.serviceName
                    }
                </span>
            </div>
            <div className="row flex-row-reverse align-items-center px-2 text-right mt-3">
                <span>
                    : توضیحات 
                </span>
                <span className="px-2">
                    {
                        service.service.description
                    }
                </span>
            </div>
        </div>
    )
}

export default ServiceInfo
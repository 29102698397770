
import React from "react"
import { useHistory } from "react-router-dom"
import { serviceAxios } from "../../axios"
import SBotton from "../../Components/Buttons/SBotton/SBotton"
import Layout from "../../Components/Layout/Layout"
import { useOrderContext } from "../../context/orderContext"
import Add from "../../Assets/myAsset/add.svg"
import Minus from "../../Assets/myAsset/minus.svg"
import { formatMoney } from "../../value"
import Toast from "../../Components/Toast/Toast"
import useError from "../../hooks/useError"
import defaultImg from "../../Assets/myAsset/user.png"
import ProviderInfo from "../../Components/ProviderInfo/ProviderInfo"
import ServiceBanner from "../../Components/ServiceBanner/ServiceBanner"
import TwoOptionModal from "../../Components/TwoOptionModal/TwoOptionModal"


const OrderConfirm = ({})=>{
    const{orderState , orderDispatch} = useOrderContext()
    const [sending , setSending] = React.useState(false)
    const [showProviderInfo , setShowProviderInfo] = React.useState(false)
    const {error , setError} = useError();
    const history = useHistory()
    const getUrl = window.location;
    const baseUrl = getUrl .protocol + "//" + getUrl.host;
    let odrderId = React.useRef(null)

    const getPaymentMethod = ()=>{
        setSending(true)
        serviceAxios.post(
            "Order/GetPaymentScreanData",
            orderState
        ).then(res=>{
            console.log(res)   
            const data = res.data.data
            if(res.data.resultCode === 200){
                if(data.amountPayable > 0){
                    setSending(false)
                    orderDispatch({type : "SET_ORDER" , data : {paymentMethods : data.methods}})
                    history.push("/service/pay")
                }else{
                    applyPay(res.data.data.methods[0])
                }
            }else{
                setSending(false)
                setError(res.data.message , "err")
            }
        }).catch(err=>{
            setSending(false)
            setError("خطایی رخ داده است. لطفا دوباره تلاش کنید" , "err")
        })
    }

    const applyPay = (payData)=>{
        let data = {
            data : orderState,
            callBackUrl: `${baseUrl}/service/pay/callback`,
            amountPayable: orderState.periods[0].price * orderState.periods[0].requestedCount,
            amount: orderState.periods[0].price * orderState.periods[0].requestedCount,
            param: "",
            payFor:8,
            ...payData
        };
        serviceAxios.post("Order/ApplyPayMethod" , data)
        .then(res=>{
            console.log(res.data)
            if(res.data.resultCode === 200){
                odrderId.current = res.data.data2
                setError("سفارش با موفقیت ثبت شد" , "suc")
                setTimeout(()=>{
                    history.replace("/");
                    history.push(`/service/orderDetails/${odrderId.current}`);
                },2000)
            }else{
                setSending(false)
                setError(res.data.message , "err")
            }
        }).catch(err=>{
            setSending(false)
            console.log(err)
            setError("خطایی رخ داده است. لطفا دوباره تلاش کنید" , "err")
        })
    }
    const addPressed = ()=>{
        const capacity = orderState.periods[0].capacity
        let requestedCount = orderState.periods[0].requestedCount
        if(requestedCount < capacity){
            orderDispatch({type : "SET_ORDER" , data : {periods:[{...orderState.periods[0] , requestedCount : requestedCount + 1}]}})
        }
    }

    const minusPressed = ()=>{
        let requestedCount = orderState.periods[0].requestedCount
        if(requestedCount > 1){
            orderDispatch({type : "SET_ORDER" , data : {periods:[{...orderState.periods[0] , requestedCount : requestedCount - 1}]}})
        }
    }

    console.log(orderState)
    return(
        <Layout
            title={"جزییات سفارش"}  
            loaded={true}
            loading={false}
            showMenu={true}
            displayMenu={true}
        >
            <div className="d-flex flex-column w-100 h-100 overflow-auto">
                <ServiceBanner
                    banner={orderState.serviceImage}
                />
                <div className="d-flex flex-row justify-content-start rtl mt-3">
                    <span>
                        خدمت :
                    </span>
                    <span className="mx-2">
                       {orderState.title}
                    </span>
                </div>
                <div className="rtl mt-3" style={{whiteSpace : "pre-wrap" , textAlign : "right"}}>
                    <span className="rtl ">
                        توضیحات :
                    </span>
                    <span className="mx-2 text-right">
                       {
                            ((!!orderState.periods[0].description)? orderState.periods[0].description : "" )+ 
                            ((orderState.periods[0].description === null || orderState.providerInfo.service.description === null)? "" : " - ") +
                            (!!orderState.providerInfo.service.description?orderState.providerInfo.service.description:"")
                       }
                    </span>
                </div>
                <div className="d-flex flex-column w-100 align-items-start rtl mb-3 mt-3">
                    <span>
                        خدمات دهنده :
                    </span>
                    <div className="d-flex flex-row w-100 justify-content-start rtl mt-2">
                        <img 
                            src={
                                !!orderState.providerInfo.provider.user && !!orderState.providerInfo.provider.user.profileImageUrl &
                                orderState.providerInfo.provider.user.profileImageUrl != "" ? 
                                orderState.providerInfo.provider.user.profileImageUrl : defaultImg
                            }
                            style={{width : 30 , height : 30 , borderRadius : "50%" , marginLeft : 5 , marginRight: 5}}
                        />
                        <span>
                            {
                                !!orderState.providerInfo.provider.user?orderState.providerInfo.provider.user.name : orderState.providerInfo.provider.building.name
                            }
                        </span>
                        <span className="mx-2" style={{color : "#ff5722"}} onClick={()=>setShowProviderInfo(!showProviderInfo)}>
                            {
                                showProviderInfo ? "کمتر" : "بیشتر"
                            }
                        </span>
                    </div>
                </div>
                
                {
                    showProviderInfo &&
                    <div className={`m-2`}>
                        <ProviderInfo    
                            info={orderState.providerInfo.provider}
                        />
                    </div>
                }
                <div className="d-flex flex-row justify-content-start rtl mt-3">
                    <span>
                        آدرس انتخابی :
                    </span>
                    <span className="mx-2">
                       {orderState.addressString}
                    </span>
                </div>
                <div className="d-flex flex-row justify-content-start rtl mt-3">
                    <span>
                        سانس :
                    </span>
                    <span className="mx-2">
                       {orderState.periods[0].title}
                    </span>
                </div>
                <div className="d-flex flex-row justify-content-start rtl mt-3">
                    <span>
                        تاریخ :
                    </span>
                    <span className="mx-2">
                       {orderState.recomededDateTimeString}
                    </span>
                </div>
                <div className="d-flex flex-row justify-content-start rtl mt-3">
                    <span>
                        ساعت :
                    </span>
                    <span className="mx-2">
                       {orderState.periods[0].startTime}
                    </span>
                </div>
                <div className="d-flex flex-row justify-content-start rtl mt-3">
                    <span>
                        ظرفیت :
                    </span>
                    <span className="mx-2">
                       {orderState.periods[0].capacityString}
                    </span>
                </div>
                <div className="d-flex flex-row justify-content-start rtl mt-3">
                    <span>
                        تعداد :
                    </span>
                    <img
                        src={Add}
                        style={{width : "20px" , height : "20px"}}
                        className="mx-2"
                        onClick={addPressed}
                    />
                    <span className="mx-2">
                       {orderState.periods[0].requestedCount}
                    </span>
                    <img
                        src={Minus}
                        style={{width : "20px" , height : "20px"}}
                        className="mx-2"
                        onClick={minusPressed}
                    />
                </div>
                <div className="d-flex flex-row justify-content-start rtl mt-3">
                    <span>
                        قیمت :
                    </span>
                    <span className="mx-2">
                       {formatMoney(orderState.periods[0].price * orderState.periods[0].requestedCount)} ریال
                    </span>
                </div>

                <div className="d-flex flex-column justify-content-end align-items-center">
                    <div className="w-75 mt-5">
                        <SBotton
                            title={"تایید سفارش"}
                            click={getPaymentMethod}
                            sending={sending}
                            gradian
                        />
                    </div>
                </div>
                <Toast
                    text={error.errTxt}
                    type={error.errType}
                />
            </div>
        </Layout>
    )
}

export default OrderConfirm
import { Autocomplete, TextField  } from "@mui/material";
import classes from "./AddressDetail.module.css"
import React from "react";
import Modal from "../Modal/Modal";
import axios , { serviceAxios } from "../../axios";
import SBotton from "../Buttons/SBotton/SBotton";
import Toast from "../Toast/Toast";
import { ThemeProvider , createTheme } from "@mui/material/styles";

const theme = createTheme({
    direction : "rtl"
 });

const style = {
    width: '90%',
    textAlign: 'right',
    margin : "10px",
    direction : "rtl",

}

const defaultAddress ={
    "id": 0,
    "cityId": 0,
    "name" : "",
    "cityName": "",
    "addressString": "",
    "latitude": 35.74120339073109,
    "longitude": 51.45528611013458,
    "unitNumber": "",
    "plate": "",
}

const AddressesDetail = ({provinces , onClose , show , callback , address=defaultAddress})=>{

    let selectedProvince = React.useRef()

    const [addressDetail , setAddressDetail] = React.useState(address)
    const [loading , setLoading] = React.useState(false)
    const [error , setError] = React.useState({})
    const [cities , setCities] = React.useState([])

    const provinceChanged = e =>{
        if(e.target.value){
            const id = provinces.find(item => item.text === e.target.value).id
            selectedProvince.current = {id , text : e.target.value}
            getCities()
        }
    }

    const getCities = ()=>{
        if(selectedProvince.current.id){
            axios({
                method: 'Post',
                url: '/City/List',
                data: {
                    provinces: false,
                    provinceId: parseInt(selectedProvince.current.id),
                    nameLike: '',
                    pageSize: 1000,
                    pageNumber: 0
                }
            })
            .then(res => {
                console.log(res);
                setCities(res.data.data)
            })
            .catch(err => {
                console.log(err.response);
            })
        }
    }

    const setCity = (e)=>{
        console.log(e.target.value)
        if(e.target.value){
            const city = cities.find(item => item.text === e.target.value)
            if(city){
                const id = cities.find(item => item.text === e.target.value).id
                setAddressDetail({
                    ...addressDetail ,
                    cityId : parseInt(id),
                    cityName : e.target.value
                })
            }
        }
    }

    const onAddressChanged = (e)=>{
        setAddressDetail({
            ...addressDetail ,
            addressString : e.target.value
        })
    }

    const onNameChanged = (e)=>{
        setAddressDetail({
            ...addressDetail ,
            name : e.target.value
        })
    }

    const onPlateChanged = (e)=>{
        setAddressDetail({
            ...addressDetail ,
            plate : e.target.value
        })
    }

    const onUnitChanged = (e)=>{
        setAddressDetail({
            ...addressDetail ,
            unitNumber : e.target.value
        })
    }

    const registerAddress = ()=>{
        if(
            addressDetail.cityId > 0 &&
            addressDetail.addressString != "" &&
            addressDetail.name != "" &&
            addressDetail.unitNumber != "" &&
            addressDetail.plate != ""

        ){
            setLoading(true)
            serviceAxios({
                method: 'Post',
                url: '/Address/Submit',
                data: {...addressDetail}
            }).then(res =>callback())
        }else{
            console.log(addressDetail)
            setLoading(false)
            setError({
                errText : "تمام فیلدها را پر کنید",
                errType : "war"
            })
        }
    }

    return(
        <ThemeProvider theme={theme}>
            <Modal
                show={show}
                click={onClose}
            >
                <div className="h-100 bg-white">
                    <div  dir="rtl" className={`d-flex flex-column position-relative px-auto ${classes.container}`}>
                        <Autocomplete
                            options={provinces.map(item =>{return{id : item.id , label : item.text}})}
                            sx={style}
                            renderInput={(params)=><TextField className={`${classes.input}`} {...params} label="استان*"/>}
                            onSelect={provinceChanged}
                        />
                        <Autocomplete
                            options={cities.map(item =>{return{id : item.id , label : item.text}})}
                            sx={style}
                            renderInput={(params)=><TextField className={`${classes.input}`} {...params} label="شهر*"/>}
                            onSelect={setCity}
                        />
                        
                        <TextField
                            sx={style}
                            label="نام آدرس*"
                            value={addressDetail.name}
                            onChange={onNameChanged}
                            type="text"
                        />
                        
                        <TextField
                            sx={style}
                            label="آدرس دقیق*"
                            value={addressDetail.addressString}
                            onChange={onAddressChanged}
                            type="text"
                        />
                        <TextField
                            sx={style}
                            label="پلاک*"
                            value={addressDetail.plate}
                            onChange={onPlateChanged}
                            type="text"
                        />
                        <TextField
                            sx={style}
                            label="واحد*"
                            value={addressDetail.unitNumber}
                            onChange={onUnitChanged}
                            type="text"
                        />
                        <div style={{width : "90%"}}>
                            <SBotton
                                title={'ثبت موقعیت'} 
                                gradian={true} 
                                click={registerAddress} 
                                sending={loading}
                            />
                        </div>
                    </div>
                    <Toast
                        text={error.errText}
                        type={error.errType}
                    />

                </div>
            </Modal>
        </ThemeProvider>
        
    )
}


export default AddressesDetail
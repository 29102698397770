
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import CpInput from '../../../Components/Input/Input';
import Layout from '../../../Components/Layout/Layout';
import Toast from '../../../Components/Toast/Toast';
import { ApiService, attachFile } from '../../../value';
import loadingGif from '../../../Assets/gifs/22.gif'
import UploadImg from '../../../Components/UploadImg/UploadImg';
import { FaToEnNum } from '../../../value';






let Self = null;
class AddEvent extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            loading: false,
            buildingId: null,
            id: this.props.location.state ? this.props.location.state.event.id : 0,



            imgId: (this.props.location.state && this.props.location.state.event.fileId) ? this.props.location.state.event.fileId  : null,
            imgPath: (this.props.location.state && this.props.location.state.event.filePath) ? this.props.location.state.event.filePath : '',
            uploading: false,

            showDatePicker: false,

            title: this.props.location.state ? this.props.location.state.event.title : '',
            description: this.props.location.state ? this.props.location.state.event.description : '',
            startDate: this.props.location.state ? this.props.location.state.event.startDate : '',
            startTime: this.props.location.state ? this.props.location.state.event.startTime.replace("PM", "") : '',
            endTime: this.props.location.state ? this.props.location.state.event.endTime.replace("PM", "") : '',

            errText: '',
            errType: ''
        }
    }

    componentDidMount() {
        if (localStorage.getItem('id')) {
            this.setState({
                buildingId: localStorage.getItem('id')
            })
        } else {
            this.props.history.push('/news');
        }

    }

    uploadFile(files) {
        this.setState({
            uploading: true
        })
        attachFile(files).then(res => {
            this.setState({
                imgId: res[0].id,
                imgPath: res[0].path,
                uploading: false
            })

        })
            .catch(err => {

            })

    }



    submit() {
        this.setState({
            loading: true
        });

        let data = {
            id: this.state.id,
            title: this.state.title,
            description: this.state.description,
            startDate: this.state.startDate,
            startTime: FaToEnNum(this.state.startTime),
            endDate: this.state.startDate,
            endTime: FaToEnNum(this.state.endTime)
        };
          if(  this.state.imgId)
             data.fileId = this.state.imgId; 
        
        console.log('dataaaaa', data);
        ApiService('Post', `/BuildingEvent/Submit/${this.state.buildingId}`, data)
            .then(response => {
                console.log('Submit', response);
                if (response.data.result) {
                    this.setState({
                        loading: false,
                        errText: ' با موفقیت انجام شد.',
                        errType: 'suc'
                    });
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/event',
                            state: {
                                id: response.data.data.id
                            }
                        })
                    }, 2000)
                }
                else {
                    this.setState({
                        loading: false,
                        errText: response.data.message,
                        errType: 'war'
                    });
                    this.clearErr()
                    
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    errText: 'خطای داخلی سرور',
                    errType: 'war'
                });
                this.clearErr()
               
                return
            })


    }

    clearErr() {
        setTimeout(() => {
            this.setState({
                errText: '',
                errType: '',
            })
        }, 2000)
    }



    render() {
        return (
            <Layout loaded={true} title={this.props.location.state && this.props.location.state.event ? 'ویرایش رویداد' : ' ثبت رویداد جدید'}
                isHome={false}

                showMenu={false} loading={this.state.loading} >
                <DatePicker showPicker={this.state.showDatePicker} disMiss={() => {
                    this.setState({
                        showDatePicker: false
                    })
                }} click={(e) => {
                    this.setState({
                        startDate: e,
                        showDatePicker: false
                    })


                }} />
                <Toast
                    text={this.state.errText}
                    type={this.state.errType}
                />
                <div style={{ height: 'inherit' }}>
                    <div className={`pt-2`} style={{ height: `calc(100% - 3rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>
                        <div style={{ lineHeight: 2 }}>
                            <CpInput plachodler={'عنوان رویداد'} type={"text"} maxLength={50} value={this.state.title} onChange={(event) => {
                                this.setState({
                                    title: event.target.value
                                })
                            }} />
                            <CpInput plachodler={' توضیحات'} multiline={true} maxLength={250} type={"text"} style={{whiteSpace: "pre-wrap"}} value={this.state.description} onChange={(event) => {
                                this.setState({
                                    description: event.target.value
                                })
                            }} />
                            {/* <CpInput plachodler={'تاریخ رویداد'} type={"text"} value={this.state.startDate} onClick={() => {
                                this.setState({
                                    showDatePicker: true
                                })
                            }} onChange={(event) => {
                                this.setState({
                                    startDate: event.target.value
                                })
                            }} /> */}
                            <div className={`d-flex align-items-center rtl `} onClick={() => {
                                this.setState({
                                    showDatePicker: true
                                })
                            }}
                             style={{marginBottom:15,padding:'15.5px 12px', color:'#0000008a', border:'1px solid #424242', borderRadius:4}}>
                                <div>تاریخ رویداد:  </div>
                                <div className={`mr-3`} style={{color:'#000000de'}} >{this.state.startDate}</div>
                                
                            </div>
                            <CpInput plachodler={'   ساعت شروع رویداد مانند : 18:00'} type={"text"} value={this.state.startTime.replace("AM", "")} onChange={(event) => {
                                this.setState({
                                    startTime: event.target.value
                                })
                            }} />
                            <CpInput plachodler={'ساعت پایان رویداد  مانند : 19:30'} type={"text"} value={this.state.endTime.replace("AM", "")} onChange={(event) => {
                                this.setState({
                                    endTime: event.target.value
                                })
                            }} />
                            {this.state.imgPath.length < 1 ?
                                <>
                                    <div className={`rtl fntBold mediumF text-right mt-2`} > عکس‌ مرتبط را اضافه کنید. </div>

                                    {!this.state.uploading ?
                                        <UploadImg
                                            onChange={(e) => {
                                                if (e.target.files[0]) {
                                                    this.uploadFile(e.target.files[0])
                                                }
                                            }}>
                                        </UploadImg>
                                        :
                                        <div className={`w-100 text-center`}><img style={{ width: '10%', height: '100%' }} src={loadingGif} /></div>}
                                </>
                                :
                                <div className={`text-right pb-3 rtl d-flex align-items-center`}>
                                    <div className={` `} style={{
                                        width: 110, height: 80,
                                        backgroundImage: "url(" + this.state.imgPath + ")",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                    </div>
                                    {/* <img src={this.state.imgPath} style={{ width: 80 }} /> */}
                                    <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} onClick={() => {
                                        this.setState({
                                            imgPath: ''
                                        });
                                    }}> حذف</span>
                                </div>
                            }
                        </div>
                      { !this.state.uploading ? <div style={{ position: "absolute", bottom: 0, width: '50%', left: '25%' }}>
                            <SBotton title={this.state.id != 0 ?  'ویرایش ' :
                            'ثبت رویداد'} gradian={true} click={() => {
                                this.submit();
                            }} />
                        </div> : null}
                    </div>
                </div>

            </Layout>
        )
    }
}
export default withRouter(AddEvent);
import SBotton from "../Buttons/SBotton/SBotton"
import Modal from "../Modal/Modal"

const TwoOptionModal = ({title , showModal , setShowModal , callback1 , callback2})=>{
    return(
        <Modal 
            show={showModal} 
            btn={true} 
            click={() => setShowModal(false)}
        >

            <div className={`rtl text-right mediumF`}>
                {title}
            </div>

            <div className="row">
                <div className={`w-25 mx-auto`}>
                    <SBotton title={'خیر'}  click={callback2} />
                </div>
                <div className={`w-25 mx-auto`}>
                    <SBotton title={'بله'} gradian click={callback1} />
                </div>
            </div>

        </Modal>
    )
}

export default TwoOptionModal
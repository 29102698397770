import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout/Layout';
import CpInput from '../../Components/Input/Input';
import { Checkbox } from '../../Components/CheckBox/CheckBox';
import Avatar from 'react-avatar-edit';
import img from '../../Assets/myAsset/user.png'
import Modal from '../../Components/Modal/Modal';
import { ApiService, attachFile, getProfile } from '../../value';
import UploadImg from '../../Components/UploadImg/UploadImg';
import Login from '../Auth/Login/Login';
import Toast from '../../Components/Toast/Toast';
import GrayLine from '../../Components/GrayLine/GrayLine';

const Profile = props => {

    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [preview, setPreview] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [src, setSrc] = useState(null);

    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');


    const [name, setName] = useState('');
    const [Lname, setLName] = useState('');
    const [gender, setGender] = useState('');
    const [imgId, setImgId] = useState(null);
    const [deleteImg, setDeleteImg] = useState(false);




    useEffect(() => {
        getProfileInfo(true);
    }, [])

    const onClose = () => {
        setPreview(null)
    }

    const dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const onCrop = (preview) => {
        setPreview(preview)


    }
    const updateprofile = (data) => {
        data.name = name.length < 1 ? info.name : name;
        data.lastName = Lname.length < 1 ? info.lastName : Lname;
        data.gender = gender.length < 1 ? info.gender : gender;
        data.pAllowSendNotifyToMe = info.pAllowSendNotifyToMe;
        data.pAutoPaymentIsEnabled = info.pAutoPaymentIsEnabled;
        data.pUsersCanChatWithMe = info.pUsersCanChatWithMe;
        ApiService('Post', '/User/setProfile', data)
            .then(response => {

                if (response.data.result) {
                    setInfo(response.data.data);
                    if(response.data.data.profileImage){
                        setSrc(response.data.data.profileImage.path) 
                    }
                   
                    localStorage.setItem('profile', JSON.stringify(response.data.data));
                    localStorage.setItem('uuid', JSON.stringify(response.data.data.id));

                    setErrText('اطلاعات با موفقیت ثبت شد');
                    setErrType('suc');
                    clearErr()
                    setLoading(false);
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr()
                    setLoading(false);
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    const submit = () => {
        setLoading(true);
        if ((name.length < 1 && info.lastName.length < 1) || (Lname.length < 1 && info.lastName.length < 1)) {
            setErrText('نام و نام‌خانوادگی ضروری است');
            setErrType('war');
            setLoading(false);
            return
        }
        let file = null;
        let data = null;
        if (preview) {
            file = dataURLtoFile(preview, 'filename.jpeg');
            attachFile(file).then((response) => {
                setImgId(response[0].id);
                data = {
                    changedProfileImage: true,
                    profileImageId: response[0].id
                }
                updateprofile(data)
            });
        } else {
            data = {}
            if (deleteImg) {
                data = {
                    changedProfileImage: true,
                    profileImageId: 0
                }
            }
            updateprofile(data)

        }

    }

    const removeImg = () => {
        setDeleteImg(true);
        setPreview(null)
    }


    const getProfileInfo = (force) => {
        getProfile(force).then(response => {
            console.log(response);
            setInfo(response);
            setSrc(response.profileImage ? response.profileImage.path : null)
            setLoading(false);
        });
    }
    return (<>


        {/*  */}
        {info ?
            <Layout loaded={true} title={'ناحیه کاربری'} isHome={false} btn={true} btnName={'ثبت'} loading={loading} gradian
                click={() => {
                    submit();
                }} >
                <Toast
                    text={errText}
                    type={errType}
                />


                <Modal show={showModal} btn={true} click={() => {
                    setShowModal(false);
                }}>
                    <div className="d-flex justify-content-center align-items-center flex-column fnt" style={{ fontSize: 10 }}>
                        <Avatar
                            label={'انتخاب تصویر'}
                            borderStyle={{
                                textAlign: 'center',
                                border: '1px dashed black'
                            }}

                            width={90}
                            height={90}
                            onCrop={(preview) => { onCrop(preview) }}
                            onClose={() => { onClose() }}
                            src={src}
                        />
                    </div>

                </Modal>
                <div style={{ height: 'inherit' }}>
                    <div style={{ height: `calc(100% - 2rem)`, overflow: 'auto' }}>
                        {!preview ?
                            <div onClick={() => {
                                setShowModal(true);
                            }} style={{ width: 90, height: 90, margin: '15px auto 2px', position: 'relative' }}>
                                <img src={(src && !deleteImg) ? src : img} style={{ width: '100%', borderRadius: '50%', height: '100%' }} />
                                {/* {(src && !deleteImg)  ? src : img} */}
                                {(src && !deleteImg) ? <span className={`badg`} onClick={(e) => { e.stopPropagation(); removeImg() }}></span> : null}
                            </div>
                            :
                            <div style={{ width: 90, height: 90, margin: '15px auto 2px', position: 'relative' }}>
                                <img src={preview} alt="Previewghg" style={{ width: '100%', borderRadius: '50%' }} onClick={() => {
                                    setShowModal(true);
                                }} />
                                <span className={`badg`} onClick={(e) => { e.stopPropagation(); removeImg() }}></span>
                            </div>}
                        <div className={`p-2 m-2 fntBold text-center`} style={{ fontSize: 20 }}>{info.mobileNumber}</div>
                        <div className={`m-2 `}>
                            <CpInput plachodler={' نام '} type={"text"} value={info.name} onChange={(event) => {
                                setName(event.target.value);
                            }} />
                            <CpInput plachodler={'  نام‌خانوادگی'} value={info.lastName} type={"text"} onChange={(event) => {
                                setLName(event.target.value);
                            }} />
                            <div className={`text-right rtl d-flex justify-content-around`}>
                                <Checkbox Type={'Radio'} Label={'زن'} checked={info.gender === 'زن'}
                                    onChange={checked => setGender(checked ? 'زن' : null)} />
                                <Checkbox Type={'Radio'} Label={'مرد'} checked={info.gender === 'مرد'}
                                    onChange={checked => setGender(checked ? 'مرد' : null)} />
                            </div>
                            <GrayLine />
                            <div className={`text-center rtl fntBold mb-2`}>دسترسی های شخصی</div>
                            <div className={`text-right rtl`}>
                                <Checkbox Label={'هومتیک اجازه پرداخت هزینه های ساختمان را از کیف پول من دارد'} checked={info.pAutoPaymentIsEnabled}
                                    onChange={checked => { let tmp = info; tmp.pAutoPaymentIsEnabled = checked; setInfo(tmp); }} />
                                <Checkbox Label={'نوتیفیکیشن دریافت می کنم'} checked={info.pAllowSendNotifyToMe}
                                    onChange={checked => { let tmp = info; tmp.pAllowSendNotifyToMe = checked; setInfo(tmp); }} />
                                <Checkbox Label={'دیگران اجازه دارند با من چت کنند'} checked={info.pUsersCanChatWithMe}
                                    onChange={checked => { let tmp = info; tmp.pUsersCanChatWithMe = checked; setInfo(tmp); }} />

                            </div>
                        </div>
                    </div>
                </div>

            </Layout> : <Layout loaded={true} title={'ناحیه کاربری'} isHome={false} btn={true} btnName={'ثبت'} loading={loading} gradian >
            </Layout>}
        {/*  */}

    </>)

}
export default Profile;
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../Components/Layout/Layout';
import RadioImage from '../../Components/RadioImage/RadioImage';
import Toast from '../../Components/Toast/Toast';
import { serviceAxios } from "../../axios";
import { useOrderContext } from '../../context/orderContext';

const ServicesList = ()=>{
    const [loading , setLoading] = React.useState(true)
    const [services , setServices] = React.useState([])
    const [error , setError] = React.useState({errText : "" , errType : "war"})
    const {orderState , orderDispatch} = useOrderContext()
    const history = useHistory()

    React.useEffect(()=>{
        serviceAxios.post(
            `Service/GroupServices/${orderState.groupId}/${orderState.addressId}`
        ).then(res=>{
            console.log(res.data)
            setServices([...res.data.data])
            setLoading(false)
        }).catch(err =>{
            console.error(err)
        })
    },[])

    const selectService = (service)=>{
        orderDispatch({type : 'SET_ORDER' , data : {serviceId : service.id , title : service.name}})
        history.push(`/service/providers`)
    }

    return (
        <Layout 
            loaded={true} 
            title={'لیست خدمات'}
            isHome={false}  
            loading={loading}
            showMenu={true}
            displayMenu={true}
        >
            <Toast
                text={error.errText}
                type={error.errType}
            />
            <div className='container mt-3'>
                <div className='row justify-content-around align-content-start overflow-auto' style={{height : 'calc(100vh - 55px)'}}>
                    {
                        services.map((item)=>(

                            <RadioImage
                                service={item}
                                onClick={()=>selectService(item)}
                            />
                        ))
                    }
                </div>
            </div>
            
        </Layout>

    )
}
export default ServicesList;

import classes from './FilterModal2.module.css';
import React from 'react';
import Modal from '../Modal';
import SBotton from '../../Buttons/SBotton/SBotton';
import { Component } from 'react';
import { Checkbox } from '../../CheckBox/CheckBox';
import Collapsable from '../../Collapsable/Collapsable';
import DatePicker from '../../DatePicker/DatePicker';
import { formatMoney } from '../../../value';
import recyclebin from '../../../Assets/myAsset/recycle-bin.png'
import NewSelect from '../../NewSelect/NewSelect';

let Self = null;
class FilterModal2 extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            amountS: '',
            amountE: '',
            dateS: 'از تاریخ',
            dateE: 'تا تاریخ',
            selectedDesk: null

        }
        // this.showtype = 'personsCount';
        // this.showtype1= 'resident';

        this.filterData = {
            sortIsDesc: true,
            sortByType: 0,
            priceType0: null,
            priceType1: null,
            cashDeskId: null

        }
    }


    render() {
        return (<>

            <Modal show={this.props.show} btn={true} click={this.props.click} >
                <DatePicker showPicker={this.state.showDatePicker} disMiss={() => {
                    this.setState({
                        showDatePicker: false
                    })
                }} click={(e) => {
                    if (this.state.firstOne) {
                        this.filterData.startDate = e
                        this.setState({
                            dateS: e,
                            showDatePicker: false
                        })
                    } else {
                        this.filterData.endDate = e
                        this.setState({
                            dateE: e,
                            showDatePicker: false
                        })

                    }


                }} />
                <div style={{
                    textAlign: 'center',
                    height: '65vh'
                }}>

                    <div style={{ height: '90%', overflow: 'auto', position: 'relative' }}>

                        {this.props.cashDesk && this.props.cashDesk.length > 0 ? <div className={`m-2 p-2`}>
                            <NewSelect options={this.props.cashDesk}
                                obj={false} sel2={false}
                                value={this.state.selectedDesk ? this.state.selectedDesk : ''} >
                                <div >
                                    {this.props.cashDesk.map((item, index) => {
                                        return (
                                            <div style={{ padding: '2%' }} key={index} value={item.key} onClick={(e) => {
                                                //  console.log('111', this.props.cashDesk);

                                                this.props.cashDesk.find(elem => {
                                                    if (elem.key == item.key) {
                                                        //   console.log(elem);
                                                        this.setState({
                                                            selectedDesk: elem
                                                        })
                                                        this.filterData.cashDeskId = elem.key
                                                    }
                                                })
                                            }}>{item.text}</div>
                                        )
                                    })
                                    }
                                </div>
                            </NewSelect>


                        </div> : null}



                        {this.props.typeOdDoc ? <div className={`d-flex justify-content-between align-items-center m-2 p-2 borderShadow `} style={{ border: 1, borderRadius: 10 }}>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Label={'درآمد '} checked={this.filterData.priceType == 1} textStyle={{ fontSize: '10px' }}
                                    onChange={checked => {
                                        if (checked) {
                                            this.filterData.priceType1 = 1
                                        } else {
                                            this.filterData.priceType1 = null
                                        }
                                    }} Name={'dec'} />
                            </div>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Label={'هزینه '} textStyle={{ fontSize: '10px' }}
                                    checked={this.filterData.priceType == 0}
                                    Name={'dec'} onChange={checked => {
                                        if (checked) {
                                            this.filterData.priceType0 = 0
                                        } else {
                                            this.filterData.priceType0 = null
                                        }
                                    }} />
                            </div>
                        </div> : null}







                        {this.props.sortInc ? <> <div className={`fntBold mt-2 mb-2`} style={{ direction: 'rtl', textAlign: 'right', fontSize: 12 }} >مرتب‌سازی براساس:</div>

                            <div className={`d-flex justify-content-between align-items-center m-2 p-2 borderShadow `} style={{ border: 1, borderRadius: 10 }}>
                                <div className={`rtl`} style={{ width: '45%' }}>
                                    <Checkbox Type={'Radio'} Label={'بیشترین '} checked={this.filterData.sortIsDesc} textStyle={{ fontSize: '10px' }}
                                        onChange={checked => {
                                            if (checked) {

                                                this.filterData.sortIsDesc = true

                                            }
                                        }} Name={'dec'} />
                                </div>
                                <div className={`rtl`} style={{ width: '45%' }}>
                                    <Checkbox Type={'Radio'} Label={'کمترین '} textStyle={{ fontSize: '10px' }}
                                        //  checked={!this.filterData.sortIsDesc} 
                                        Name={'dec'} onChange={checked => { if (checked) { this.filterData.sortIsDesc = false } }} />
                                </div>
                            </div> </> : null}


                        {this.props.amount ? <div className={`d-flex justify-content-between align-items-center  m-2 p-2 borderShadow   `}
                            style={{ border: 1, borderRadius: 10 }}>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Type={'Radio'} Label={'مبلغ '} textStyle={{ fontSize: '10px' }}
                                    onChange={checked => { if (checked) { this.filterData.sortByType = 1 } }} Name={'type'} />
                            </div>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Type={'Radio'} Label={'تاریخ '} checked={this.filterData.sortByType == 0} textStyle={{ fontSize: '10px' }}
                                    //  checked={!this.filterData.sortIsDesc} 
                                    Name={'type'} onChange={checked => { if (checked) { this.filterData.sortByType = 0 } }} />
                            </div>
                        </div> : null}

                        {this.props.amount ?
                            <div
                            // className={`d-flex justify-content-around flex-row-reverse p-2`}
                            >
                                <div className={`d-flex justify-content-around align-items-center flex-row-reverse p-2`}>
                                    <div className={`rtl mediumF`}> از مبلغ:</div>
                                    <div className={`d-flex rtl  p-2 m-1`} style={{ textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}  >
                                        <input className={classes.inputS} placeholder={'از مبلغ '}
                                            value={formatMoney(this.state.amountS)}

                                            onChange={(e) => {
                                                let tmp = e.target.value.replace(/,/g, "")
                                                if (+tmp === +tmp) {

                                                    this.setState({
                                                        amountS: tmp

                                                    });
                                                    this.filterData.startAmount = parseInt(tmp)
                                                }
                                                else {
                                                    this.setState({
                                                        amountS: this.state.dateS
                                                    })
                                                }
                                            }}
                                        />
                                        <span>ریال</span>
                                    </div>
                                </div>


                                <div className={`d-flex justify-content-around align-items-center flex-row-reverse p-2`}>
                                    <div className={`rtl mediumF`}> تا مبلغ:</div>
                                    <div className={`d-flex rtl  mediumF p-2 m-1`} style={{ textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}  >
                                        <input className={classes.inputS} placeholder={'تا مبلغ '}
                                            value={formatMoney(this.state.amountE)}
                                            onChange={(e) => {
                                                let tmp = e.target.value.replace(/,/g, "")
                                                if (+tmp === +tmp) {

                                                    this.setState({
                                                        amountE: tmp

                                                    });
                                                    this.filterData.endAmount = parseInt(tmp)


                                                }
                                                else {

                                                    this.setState({
                                                        amountE: this.state.dateE
                                                    })
                                                }
                                            }}
                                        />
                                        <span>ریال</span>
                                    </div>
                                </div>
                            </div> : null}

                        <div className={`d-flex justify-content-between flex-row-reverse p-2 mb-3`}>
                            <div className={`p-2 m-1 `} onClick={() => {
                                this.setState({
                                    showDatePicker: true,
                                    firstOne: true
                                })
                            }}
                                style={{ width: '40%', borderRadius: 10, border: '1px solid #c2c7d2', fontSize: 11 }}>{this.state.dateS}</div>
                            <div className={`p-2 m-1`}
                                onClick={() => {
                                    this.setState({
                                        showDatePicker: true,
                                        firstOne: false
                                    })
                                }}
                                style={{ width: '40%', borderRadius: 10, border: '1px solid #c2c7d2', fontSize: 11 }}>{this.state.dateE}</div>
                            <div style={{ width: 40 }} onClick={() => {
                                this.setState({
                                    dateS: 'از تاریخ',
                                    dateE: 'تا تاریخ'
                                });
                                this.filterData.startDate = ''
                                this.filterData.endDate = ''
                            }}>
                                <img src={recyclebin} alt={'alt'} style={{ width: '100%' }} />
                            </div>
                        </div>


                    </div>
                    <div style={{ width: '95%', position: 'absolute', backgroundColor: 'white', height: '10%' }}>
                        <div className={`w-50 mx-auto`}>
                            <SBotton title={'فیلتر'} gradian click={() => { this.props.submit(this.filterData) }} />
                        </div>

                    </div>

                </div>
            </Modal>
        </>
        )
    }

}
export default FilterModal2;








import React from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import { useHistory } from "react-router-dom";
import MapP from "../../Components/Map/Map";
import SBotton from "../../Components/Buttons/SBotton/SBotton";
import AddressesDetail from "../../Components/AddressDetail/AddressDetail";
import { useAddressContext } from "../../context/addressContext";
import axios from "../../axios";

const initialState = {
    loading  : false,
    lat : 35.74245203307214,
    lng : 51.44703541419062,
    showModal : false,
    provinces : [],
    cities : []
}

const reducer = (state=initialState , action) =>{
    switch(action.type){
        case "SET_LOADING":
            return {...state , loading : action.data}
        case "SET_COORDS":
            return {...state , ...action.data , showModal : true}
        case "SET_PROVINCES":
            return {...state , provinces : action.data}
        case "SET_SHOW_MODAL":
            return {...state , showModal : action.data}
        default : 
            return state
    }
}

const AddAddress = ()=>{
    const {getAddresses , addressDispatch} = useAddressContext()
    const [addressState , setAddressState] = React.useReducer(reducer , initialState)
    let coords = React.useRef({lat : initialState.lat , lng : initialState.lng})
    const history = useHistory()

    React.useEffect(()=>{
        axios({
            method: 'Post',
            url: '/City/List',
            data: {
                provinces: true,
                provinceId: 0,
                nameLike: '',
                pageSize: 1000,
                pageNumber: 0
            }
        })
        .then(res => {
            console.log(res);
            setAddressState({type : "SET_PROVINCES" , data : res.data.data})
        })
        .catch(err => {
            console.log(err.response);
        })
    },[])

    const addressRegistered = ()=>{
        getAddresses(addressDispatch)
        history.goBack()
    }

    return(
        <Layout
            title="افزودن آدرس"
            loading={addressState.loading}
            isHome={false}
            loaded={true}
            showMenu={true}
            displayMenu={true}
        >
            <div id="address" className={`w-100 mx-auto`} style={{ height: `calc(100vh - ${56 + 'px'})` }} >
                <MapP 
                    onMove={event => coords.current = {lat : event.getCenter().lat , lng : event.getCenter().lng}} 
                    lat={addressState.lat} 
                    lng={addressState.lng}
                    containerStyle={{width : "100%" , height : "100%"}}
                >
                    <div className={`w-100`}>
                        <div style={{ width: '100px', left : "calc(50% - 50px)", bottom: 10, position: 'fixed'}}>
                            <SBotton title={'ثبت موقعیت'} gradian={true} 
                                click={() => setAddressState({type : "SET_COORDS" , data : coords.current})} 
                            />
                        </div>
                    </div>
                </MapP>
            </div>
            <AddressesDetail
                show={addressState.showModal}
                provinces={addressState.provinces}
                onClose={()=>setAddressState({type : "SET_SHOW_MODAL" , data : false})}
                callback={addressRegistered}
            />
            
        </Layout>
    )
}

export default withRouter(AddAddress);
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from '../../../Components/Modal/Modal';
import { ApiService, colors } from '../../../value';
import Switch from "react-switch";
import PayableCard from '../../../Components/Cards/PayableCard/PayableCard';
import EmptyList from '../../../Components/EmptyList/EmptyList';









const ReceiptListBasedOnUser = props => {


    const [docs, setDocs] = useState([]);
    const [permissions, setPermissions] = useState(null);
    const [withArchive, setWithArchive] = useState(false);
    const [show , setShow]=useState(props.show? props.show : true);



    useEffect(()=>{
         if(props.userId && props.show)
        getMyListRequestDocuments()
    },[props.show, props.userId,
         withArchive
        ])






 const getMyListRequestDocuments = () => {
   // setLoading(true);
    let data = {
        withArchivedRequests: withArchive,
        assignedToMe: true,
        userId: props.userId,
        nameLike: '',
        pageSize: 10000,
        pageNumber: 0,
        sortIsDesc: true
    }
    // if (filterData) {
    //     data.sortIsDesc = filterData.sortIsDesc;
    //     data.sortByType = filterData.sortByType;
    //     data.startAmount = filterData.startAmount;
    //     data.endAmount = filterData.endAmount;
    //     data.startDate = filterData.startDate;
    //     data.endDate = filterData.endDate;
    // }

    ApiService('POST', `/AccRequest/ListRequestDocuments/${localStorage.getItem('id')}`, data)
        .then(res => {
            console.log('************', res);
            if (res.data.result) {
                setDocs(res.data.data);
                setPermissions(res.data.data2);
             
               
            } else {
                props.showerr(res.data.message,'war');
               
            }

        })
        .catch(err => {
            props.showerr('خطای داخلی سرور','err');
            
             return
        })
}
return(


    <Modal show={props.show} btn={true} click={props.click} >
        <div style={{maxHeight:'60vh', overflow:'auto'}}>


        <div className='d-flex align-items-center justify-content-end mt-3 mb-3 ' style={{
                width: '100%',
            }}>
                <div className='d-flex align-items-center mr-2 '>
                    {/* <img style={{ width: 15, height: 15, marginLeft: 10 }}
                                    src={BaseUrlImage + '/transaction.png'} alt={""} /> */}
                    <span style={{ color: colors.app7, fontSize: 12 }}>نمایش آرشیو</span>
                </div>
                {/* <div style={{width:50}}> */}
                <Switch
                    width={45}
                    height={23}
                    onChange={() => { setWithArchive(!withArchive) }
                    }
                    checked={withArchive}
                    className="react-switch"
                />
                {/* </div> */}
            </div>
            {docs.length > 0 ?
                <div>

                    {docs.map((item, index) => {
                        return <PayableCard key={index} onClick={() => {
                            props.history.push({
                                pathname: `/receiptDetail/${item.id}`,
                                // state: {
                                //     DocId: item.id
                                // }
                            })
                        }}
                            payable={item.pBtnPayment}
                            subject={item.subject}
                            date={item.dateCreated}
                            amount={item.amount}
                            usersRole={item.usersRole}
                            payableAmount={item.amountPayable}
                            click={(e) => {
                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: item.payData,
                                        callBackUrl: window.location.href
                                    }
                                });
                                e.stopPropagation();

                            }}
                            state={item.payStatus} />


                    })}
                </div>
                :
                <EmptyList des={'تاکنون فیشی ثبت نشده‌است'} />
            }


        </div>
        </Modal>
)

}


export default withRouter(ReceiptListBasedOnUser);
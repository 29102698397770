import React from 'react';
import Card from '../../Card/Card';
import Date from '../../Date/Date';
import Doer from '../../Doer/Doer';
import PriceBx from '../../PriceBx/PriceBx';
import attachment from '../../../Assets/myAsset/attach.png'





const FundCard = props => {
    return (
        <Card width={'100%'} onClick={props.click}  >
            <div className={`w-100 p-2`} style={{ lineHeight: 2 }}>
                <div className={` d-flex flex-row-reverse justify-content-between`}>
                   
                    <div className={`fntBold text-right rtl mediumF d-flex`}>
                    {props.attachment ? <div>
                        <img src={attachment} style={{width:22, paddingLeft:6}}/>
                    </div> : null}
                        {props.subject}</div>
                    <div>
                        <Date date={props.date} font={12} />
                    </div>
                </div>
                <div className={`grey smallF rtl text-right mb-1 `}>{props.category}</div>
                <div className={`w-100  d-flex flex-row-reverse justify-content-between align-items-center`}>
                    <div style={{width:'40%'}}>
                        <Doer creator={props.creator} profileImage={props.profileImage} />
                    </div>
                    <div style={{width:'60%'}}>
                        <PriceBx type={props.type} change={props.change} amount={props.amount} />
                    </div>
                </div>
            </div>

        </Card>
    )
}
export default FundCard;
import React from 'react';
import { formatMoney } from '../../value';


const SingleSelect = props => {
    return (
       <div className={'d-flex justify-content-between flex-row-reverse align-items-center p-2 w-100 mt-2 mb-1'} 
       style={{ backgroundColor: '#F7F7F7', borderRadius: 12 }}>
         
         <div className={`pr-2 mediumF grey`}>{props.label}</div>
                        <div className={`fntBold mediumF`}  >{formatMoney(props.amount)}</div>
       </div>
    );
};

export default SingleSelect;
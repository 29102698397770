import React, { useEffect, useState } from 'react';
import AddBotton from '../../Components/Buttons/AddBotton/AddBotton';
import RuleCard from '../../Components/Cards/RuleCard/RuleCard';
import Layout from '../../Components/Layout/Layout';
import NewSelect from '../../Components/NewSelect/NewSelect';
import SearchBar from '../../Components/SearchBar/SearchBar';
import Toast from '../../Components/Toast/Toast';
import { ApiService, MyBuildingList } from '../../value';
import empty from '../../Assets/myAsset/empty.png';
import { withRouter } from 'react-router-dom';
import EmptyList from '../../Components/EmptyList/EmptyList';
import FilterModal2 from '../../Components/Modal/FilterModal2/FilterModal2';
import searchImg from '../../Assets/myAsset/loupe.svg';







const Rules = props => {
    //const[height, setHeight]= useState();
    const [loading, setLoading] = useState(true);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');

    const [buildingList, setBuildingList] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);


    const [ruleList, setRuleList] = useState([]);

    const [search, setSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [permissions, setPermissions] = useState(false);




    useEffect(() => {
      
        getMyBuildingList();
    }, []);


    useEffect(() => {
        if(localStorage.getItem('id')){
            getRuleList(localStorage.getItem('id'))
        }
        
    }, [search ]);

  
    // const getMyBuildingList = () => {
    //     ApiService('Get', '/Building/MyBuildings')
    //         .then(response => {
    //             if (response.data.result) {
    //                 setBuildingList(response.data.data);
    //                 if (localStorage.getItem('id')) {
    //                     setSelectedBulding(response.data.data.find(elem => { return elem.id == localStorage.getItem('id') }));
    //                     getRuleList(localStorage.getItem('id'))
    //                     setLoading(false);
    //                 } else {
    //                     setSelectedBulding(response.data.data[0]);
    //                     getRuleList(localStorage.getItem('id'))
    //                     //setLoading(false);
    //                 }
    //                 //getBuildingInfo(localStorage.getItem('id') ? localStorage.getItem('id') : response.data.data[0].id);

    //             } else {
    //                 setErrText(response.data.message);
    //                 setErrType('war');
    //                 clearErr();
    //                 // setTab(false);
    //                 setLoading(false);
    //             }

    //         });
    // }




    const getMyBuildingList = () => {
        MyBuildingList(false).then(res => {
            let list = res;
            if (list) {
                setBuildingList(list);
                setSelectedBulding(list.find(elem => { return elem.id == localStorage.getItem('id') }));
                getRuleList(localStorage.getItem('id'))
            }

        })
        .catch(err => {
            setLoading(false);
            setErrText(err.data.message);
            setErrType('war');
            clearErr();
        })

    }




    const getRuleList = (id) => {
        setLoading(true);
        let data = {
            pageSize: 100,
            pageNumber: 0,
            nameLike: searchText
        }
       
        console.log('dataaaa rule', data);
        ApiService('Post', `/BuildingRule/GetList/${id}`, data)
            .then(response => {
                console.log(response.data);
                if (response.data.result) {
                    setRuleList(response.data.data);
                    setPermissions(response.data.data2)
                    setLoading(false);
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    const closeSelect=()=>{
       
        NewSelect&&NewSelect.lastObj&&NewSelect.lastObj.close();
      }
    const def =
    {
        id: '0',
        text: 'ساختمان خود را انتخاب کنید'
    };
    return (
        <Layout loaded={true} title={'قوانین ساختمان'} isHome={false} showMenu={true} loading={loading} displayMenu={!loading}>
            <Toast
                text={errText}
                type={errType}
            />
            
            <div style={{height:'inherit'}}>
            {buildingList.length > 1 ?
                                <div className={`mt-2 mb-3`} >
                                    <NewSelect options={buildingList} plachodler={def} obj={false} sel2={false} value={selectedBulding}  closeOther={()=>{closeSelect()}}>
                                        <div onClick={(e) => {
                                            buildingList.find(elem => {
                                                if (elem.id == e.target.value) {
                                                    setSelectedBulding(elem);
                                                    localStorage.setItem('id', elem.id);
                                                }
                                            })
                                        }}>
                                            {buildingList.map(item => {
                                                return (
                                                    <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                                      buildingList.find(elem => {
                                                        if (elem.id == item.id) {
                                                          setSelectedBulding(elem);
                                                          localStorage.setItem('id', elem.id);
                                                          getRuleList( elem.id)
                                  
                                                        }
                                                      })
                                                    }}>{item.name}</div>
                                                  )
                                            })
                                            }
                                        </div>

                                    </NewSelect>
                                </div>
                                :
                                buildingList.length == 1 ?
                                    <div className={`w-100 text-right pt-1 pb-1 pr-4 mt-2 mb-2 single`}>
                                        {buildingList[0].name}
                                    </div>
                                    : null
                            }
            { permissions && permissions.pBtnInsert ?<AddBotton title={'اضافه کردن قانون '}  click={() => {
                props.history.push('/rules/add');
            }} />:null}
            { ruleList.length > 0 ?
             <>   
             
             
             <SearchBar 
            click={() => {
                console.log('lllll', search);
               if(search){
                   setSearchText('');
                   setSearch(false);
                   getRuleList(localStorage.getItem('id'))

               }else{
                   setSearch(true);
               }
           }}  
            // sortModule = {true}
            // clickSort={() => {
            //     setFilterModal(true)
            // }} 
            />
            {search ?
                    <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                        <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                            style={{
                                padding: 5,
                                border: ' 1px solid grey',
                                borderRadius: 10
                            }}>
                            <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                onKeyUp={event => {

                                    if (event.key === 'Enter') {
                                        getRuleList(localStorage.getItem('id'))
                                    }
                                }}
                                placeholder={'جستجو'}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                   // searchHandler()

                                }} />
                            <div style={{ width: 15, }}>
                                <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => { 
                                   getRuleList(localStorage.getItem('id'))
                                     }} />
                            </div>

                        </div>
                        {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                    </div>
                    : null}
             <div style={{height: `calc(100% - 12rem)`, overflow: 'auto',  paddingRight:3, paddingLeft:3}}>
               { ruleList.map(item =>{
                   return <RuleCard key={item.id} title={item.title}
                    description={item.description} date={item.createdDate} 
                    creator={item.creator.name}  img={item.creator.profileImageUrl} click={() => {
                        props.history.push({
                            pathname: '/rules/View',
                            state: {
                                id: item.id
                            }
                        })
                    }}/>
               })}
               </div>
            </> :
              <EmptyList des={'تاکنون قانونی ثبت نشده‌است'}/>
               
            }
            </div>
        </Layout>
    )
}
export default withRouter(Rules);



import React from 'react';
import { formatMoney } from '../../../value';
import Card from '../../Card/Card';
import Date from '../../Date/Date';
import Doer from '../../Doer/Doer';

const DebtsCard = props => {
    return (
        <Card width={'100%'} >
            <div className={`w-100 p-2`} style={{ lineHeight: 2 }} onClick={props.click}>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100  `}>
                    {/* <Doer /> */}
                    <div className={' fntBold ml-1'} style={{ fontSize: 12 }}>{props.subject}</div>
                    {/* <div>
                        <div> <Date date={'98/05/03'} time={'12:00'} font={12} /></div>
                    </div> */}
                </div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100  `}>
                    <div style={{fontSize:12}}>{props.name}</div>
                    <div className={`pr-2 pl-2 rtl`} style={{fontSize:14, backgroundColor:' #f7f7f7', borderRadius:15}}>{`${formatMoney(props.amount)}  ریال `} </div>
                </div>
            </div>
        </Card>
    )
}
export default DebtsCard;
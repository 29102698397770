import React from "react";
import { serviceAxios } from "../../axios";
import SBotton from "../Buttons/SBotton/SBotton";
import { Checkbox } from "../CheckBox/CheckBox";
import Modal from "../Modal/Modal";

const SendMessageModal = ({selectedOrders , showModalMessage , setShowModalMessage , filterModel , allInFilter , setError})=>{
    
    const [selectAll, setSelectAll] = React.useState(false);
    const [MessageData, setMessageData] = React.useState({
        token : "",
        messageType: null,
        userRoleInUnit: 1
    })
    const [sendMess, setSendMess] = React.useState(false);
    const [messageText, setMessageText] = React.useState('');

    console.log("allInFilter" , allInFilter)

    const send = ()=>{
        if (!messageText || messageText.length == 0) {
            setError('متن پیام را وارد کنید')
            return

        }
        if (MessageData.messageType === null) {
            setError('نوع پیام را انتخاب کنید')
            return

        }
        
        serviceAxios.post("OrderMessage/Send",
            {
                "token": MessageData.token,
                "text": messageText,
                "orders": selectedOrders.map(o => o.id),
                "messageType": MessageData.messageType,
                "metaData": "",
                "filterModel": filterModel,
                "allInFilter": allInFilter
            }
        ).then((res)=>{
            console.log(res)
            setMessageData(res.data.data)
            if(res.data.resultCode === 100){
                setError(res.data.message , 'suc')
                setShowModalMessage(false)
            }
        }).catch(err=>{

        })
    }

    const patternClicked = (e)=>{
        console.log(e.target.innerText.split(":")[1].trim())
        setMessageText(`${messageText} ${e.target.innerText.split(":")[1].trim()} `.trim())

    }

    console.log("MessageData" , MessageData)
    return(
        
        <Modal show={showModalMessage} btn={true} click={() => {
        
            setMessageText('');
            setMessageData({
                token : "",
                messageType: null
            })
            setShowModalMessage(false);
            //setMessageData({});
            setSendMess(false);
            setSelectAll(false);


        }}>
            <div
                style={{ maxHeight: '85vh', overflow: 'auto' }}
            >
                <div className={`text-right rtl fntBold mb-3`} style={{ fontSize: 12 }} >ارسال پیام برای {allInFilter?"همه سفارش ها" :`${selectedOrders.length} سفارش`} </div>
                
                <div className={`text-right rtl  w-75 mx-auto pt-2 pb-2 `} style={{ borderTop: '1px solid grey' }}>
                                <div className={`text-right rtl mediumF mb-2`}>انتخاب نوع پیام:</div>
                                <Checkbox Type={'Radio'} textStyle={{ fontSize: '10px' }} disabled={MessageData.token ? true : false}
                                    checked={(MessageData.messageType === 0)} Label={'ارسال با اولویت نوتیفیکیشن'} Name={'stype'}
                                    onChange={checked => {
                                        if (checked) {
                                            setMessageData({...MessageData , token : "" , isValidMessage : false , messageType : 0});
                                        }
                                    }} />
                                <Checkbox Type={'Radio'} textStyle={{ fontSize: '10px' }}
                                    checked={MessageData.messageType === 1} Label={'نوتیفیکیشن'} Name={'stype'}
                                    onChange={checked => {
                                        if (checked) {
                                            setMessageData({...MessageData , token : "" , isValidMessage : false , messageType : 1});
                                        }
                                    }} />
                                <Checkbox Type={'Radio'} textStyle={{ fontSize: '10px' }}
                                    checked={MessageData.messageType === 2} Label={'پیامک'} Name={'stype'}
                                    onChange={checked => {
                                        if (checked) {
                                            setMessageData({...MessageData , token : "" , isValidMessage : false , messageType : 2});
                                        }
                                    }} />
                            </div>
                <div className={`w-100 mt-2 mb-2 text-center`}>

                    <textarea
                        className={`w-100 rtl`}
                        style={{
                            border: '1px solid grey', borderRadius: 10,
                            padding: 10, fontSize: 15, textAlign: 'right', height: 100
                        }}
                        placeholder={'متن پیام'}
                        value={messageText}
                        onChange={(e) => {
                            setMessageData({...MessageData , token : "" , isValidMessage : false})
                            let tmp = MessageData;
                            tmp.text = e.target.value;

                            setMessageData(tmp);
                            setMessageText(e.target.value)
                        }} />
                    <div>
                        <div className={`text-right rtl fntBold mb-2 `} style={{ fontSize: 12 }} >راهنمای متن پیام:</div>
                        <div className={`text-right   `} style={{ fontSize: 14,lineHeight:2 }}>
                           <div onClick={patternClicked}>  نام مشتری   :    [name]  </div>
                           <div onClick={patternClicked}> تاریخ سفارش   : [date]  </div>
                           <div onClick={patternClicked}> خدمات دهنده  : [providername]  </div>
                           <div onClick={patternClicked}> عنوان خدمت  : [title]  </div>
                           <div onClick={patternClicked}> تعداد خرید  : [count]  </div>
                           <div onClick={patternClicked}> عنوان سانس  : [periodtitle]  </div>
                           <div onClick={patternClicked}> شروع سانس  : [periodstart]  </div>
                           <div onClick={patternClicked}> پایان سانس  : [periodend]  </div>
                           <div onClick={patternClicked}> مبلغ سفارش  : [amount]  </div>
                        </div>
                    </div>
                    <div className={`text-center rtl mediumF fntBold`}
                        style={{ color: MessageData.validationMessage && MessageData.validationMessage.length>0 ? 'salmon' : '' }}>
                        {MessageData.validationMessage && MessageData.validationMessage.length>0 ? MessageData.validationMessage : MessageData.infoMessage }

                        {/* {MessageData.isValidMessage ? MessageData.infoMessage : MessageData.validationMessage} */}
                    </div>
                </div>
                <div className={`w-75 mx-auto`}>

                    <SBotton 
                        gradian  
                        title={MessageData.isValidMessage ? ' ارسال با هزینه' + (MessageData.cost) + 'ریال' : 'تایید پیام'} 
                        click={send} 
                    />
                </div>
            </div>


        </Modal>
    )
}

export default SendMessageModal
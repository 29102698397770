import React from 'react';
import menuSvg from '../../Assets/myAsset/menu.svg';
import chatSvg from '../../Assets/myAsset/chat.svg';
import back from '../../Assets/myAsset/left-arrow.svg';
import del from '../../Assets/myAsset/delete.png';
import { withRouter } from 'react-router-dom';
import { Badge, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';


 const styles = theme => ({
        // margin: {
        //   margin: theme.spacing.unit * 2
        // },
        customBadge: {
          backgroundColor: "#ff6333",
          color: "white"
        }
      });


const Header = (props) => {


    const { classes } = props;


   

    

    const theme = createMuiTheme({
        typography: {
          fontFamily: [
            'iranyekan',
            'Arial',
            'sans-serif'
          ].join(','),
        }
      });
    return (
        <div className={`d-flex flex-row-reverse justify-content-between align-items-center `}
            style={{ height: 50, padding: props.headerPad ? props.headerPad : '4% 5% 0%', width: props.Width, backgroundColor: 'white', position: props.headerPos ? props.headerPos : '' }}>
            <div style={{ width: props.avatar? '' : '28px', }} onClick={props.click}>
                <img className={`w-100 p-1 h-100`} style={{ display: props.show ? "block" : 'none' }} src={menuSvg} alt={'loading'} />
                <img className={`w-100 p-1 h-100`} onClick={props.del} style={{ display: props.showEdit ? "block" : 'none' }} src={del} alt={'loading'} />
                {props.avatar ?
                    <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                        <img src={props.avatar} style={{ borderRadius: '50%' }} className={`w-100 h-100`} alt={''} />
                    </div>
                    : null}
            </div >
            <div style={{ fontSize: 14, textAlign: 'center' , whiteSpace : "pre-wrap"}}>{props.title} </div>
            <ThemeProvider theme={theme}>
            <Badge badgeContent={props.isHome ? props.badgCount : null} max={99} color="error"  classes={{ badge: classes.customBadge }}  >
            
            <div style={{ width: 28, fontFamily:'iranyekan' }}>
            <img className={`w-100 p-1 h-100`} src={props.isHome ? chatSvg : back} onClick={() => {
                    props.isHome ? props.history.push('/chats') : props.customBack ?
                        props.history.push({
                            pathname: props.path,
                            state: {
                                objInfo1: props.obj,
                            }
                        })
                        : props.history.goBack();
                }} alt={'loading'} />
                </div>
            </Badge>
            </ThemeProvider>


            {/* <div style={{ width: 28 }}>
                <img className={`w-100 p-1 h-100`} src={props.isHome ? chatSvg : back} onClick={() => {
                    props.isHome ? props.history.push('/chats') : props.customBack ?
                        props.history.push({
                            pathname: props.path,
                            state: {
                                objInfo1: props.obj,
                            }
                        })
                        : props.history.goBack();
                }} alt={'loading'} />
                <span class="badge">{props.badgCount}</span>
            </div> */}

        </div>
    )

   
}
Header.propTypes = {
    classes: PropTypes.object.isRequired
  };
export default withStyles(styles)(withRouter(Header));


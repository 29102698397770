import React from 'react';
import { colors, formatMoney } from "../../../value";
import classes from "./Income.module.css"
import SBotton from '../../Buttons/SBotton/SBotton';
import Card from '../../Card/Card';
import Date from '../../Date/Date';
import Doer from '../../Doer/Doer';
import dec from '../../../Assets/myAsset/bleachRed.svg';
import inc from '../../../Assets/myAsset/bleachGrn.svg';
import { withRouter } from 'react-router-dom';




const Incoms = props => {
    return (
        <Card onClick={props.click} width={'100%'} >
            <div className={`w-100 p-2`}>
                <div className={`d-flex flex-row-reverse align-items-center w-100`}>
                    <div style={{ fontSize: '20px', fontWeight: '600', marginLeft: '20px' }} className={`text-right `}>صندوق</div>
                    <div onClick={props.clickMore} style={{ fontSize: '11px', color: colors.app2 }}>اطلاعات بیشتر</div>

                </div>

                {props.cases.map((item , index) => { 
               return <div key={index} style={{borderBottom:index != props.cases.length-1 ? '1px solid grey': null}} className={` pb-2 pt-2`}> <div className={`d-flex flex-row-reverse align-items-center justify-content-between w-100 `}>
                    {/* <Doer creator={item.creator.name}
                    //  profileImage={props.profileImage}
                      /> */}
                      <div className={` mr-2 text-right`} style={{ fontSize: 10 }}>{item.creator.name}</div>
                    <div style={{ width: 'fit-content' }}>
                        <Date date={item.dateCreated} font={11} />
                    </div>
                </div>
               
                    <div className={`w-100 p-2 mt-1 d-flex flex-row-reverse justify-content-between ${classes.eachCase}`} onClick={()=>{
                        props.history.push({
                            pathname: '/docView',
                            state: {
                              id: item.id
                            }
                          })
                    }}>
                   <div className={`rtl text-right`} style={{maxWidth:'75%'}}>{item.priceType == 0 ? ' درآمد بابت ' : 'هزینه بابت '}{item.subject}</div>

                   <div className={`d-flex`} style={{ color: '#757575' }}>
                       <div>ریال</div>
                       <div> {formatMoney(item.amount)}</div>
                       <div style={{width:12}}>
                           <img style={{ width:'100%', marginLeft: 5}} src={item.priceType == 0 ? inc : dec} alt={'alt'} />
                       </div>

                   </div>

               </div>
              </div>
               }) }
            </div>

        </Card >
    );
};

export default withRouter(Incoms);


{/* <div className={`d-flex justify-content-around align-items-center w-100 `}>

</div>
<div className={`d-flex flex-column align-items-center w-75 `} >
    <div className={`d-flex flex-row-reverse justify-content-start align-items-center w-100 pl-2`}>
        <div className={classes.title}>{props.title}</div>
        <div onClick={props.clickMore} style={{ fontSize: '11px', color: colors.app2 }}>اطلاعات بیشتر</div>
    </div>

    <div className={`d-flex flex-row-reverse justify-content-between align-items-center w-100 pl-2`}>
        <div className={classes.subTitle}>{props.subTitle}</div>
        <div>
            <div className={`d-flex ${classes.amount}`}>{formatMoney(props.amount)}
                <img style={{ width: '12px', marginLeft: 5 }} src={props.change ? inc : dec} alt={'alt'} />
            </div>
            <div></div>
        </div>
    </div>
</div>
</div>

<div className={`w-100 p-2 mt-1 d-flex flex-row-reverse justify-content-between ${classes.eachCase}`}>
<div style={{ fontSize: '13px' }}>
    {props.subject}
</div>
<div className={`d-flex justify-content-between`} style={{ width: '30%', fontSize: '12px', color: colors.app6 }}>
    <div>{formatMoney(25000000)}</div>
    <img style={{ width: '10px', marginLeft: 5 }} src={dec} alt={'alt'} />

</div>
</div>

</div> */}
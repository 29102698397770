import React from 'react';
import {colors} from '../../value'


const ProgressBar = props => {
    return (
        <div className={`w-100`}  style={{backgroundColor: colors.app5, height:6, borderRadius:3}} >
        <div  style={{backgroundColor: colors.app1,height:'inherit',borderRadius:3, width: props.wdth }}>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;
import React, { useEffect, useState } from 'react';
import Backdrop from '../Backdrop/Backdrop';
import load from '../../Assets/gifs/25.gif';
import waitting from '../../Assets/lotties/waiting.json';
import Lottie from 'react-lottie'
const Loading = props => {
    const [W, setW] = useState(0);
    const [height, seHeight] = useState(0);
    useEffect(()=>{
        const WBase = document.getElementById('baseBx').clientWidth;
        const HBx = document.getElementById('baseBx').clientHeight;
        setW(WBase);
        seHeight(HBx);
    },[])
    
    const defultOption ={
        loop:true,
        autoplay:true,
        animationData: waitting,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        
    }
    return (W !==0 ?
        <div style={{width:W,height:props.show? `${height}px`:'0%', display:props.show? 'block' : 'none' , position:'absolute' ,zIndex:1002}}>
            <Backdrop backgroundColor={props.backgroundColor}
              position={'relative'}
                click={props.click}
                show={props.show}/>
                     <div style={{position:'absolute',
                      top:'calc(50% - 100px)',
                      left:'calc(50% - 100px)',
                     }}>   
                            <Lottie options={defultOption}  height={200} width={200}/>
                           
                     </div>  
                   
              
           
        </div>
        :<div></div>
    )
}
export default Loading;

import { Component } from 'react';
import axios from '../../../axios';
import { ApiService, MyBuildingList } from '../../../value';
import Layout from '../../../Components/Layout/Layout';
import CpInput from '../../../Components/Input/Input';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import Modal from '../../../Components/Modal/Modal';
import MySelect from '../../../Components/MySelect/MySelect';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import MapP from "../../../Components/Map/Map";
import { withRouter } from 'react-router-dom';
import Toast from '../../../Components/Toast/Toast';
import NewSelect from '../../../Components/NewSelect/NewSelect';
import { createBrowserHistory } from 'history';
import arrow from '../../../Assets/myAsset/down-arrow.svg'

const browserHistory = createBrowserHistory();



const def =
{
    id: '0',
    text: 'استان'
};
const def2 =
{
    id: '0',
    text: 'شهر'
};


let Self = null;
class AddBuildingMap extends Component {


    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            showOptions: false,
            showOptions2: false,
            searchText: '',
            searchText1: '',
            wdth: '50%',
            finished: false,
            loading: false,
            selectLoc: false,
            showModal: false,
            lat: this.props.location.state && this.props.location.state.objInfo1.latitude > 0 ? this.props.location.state.objInfo1.latitude : 35.74245203307214,
            lng: this.props.location.state && this.props.location.state.objInfo1.latitude > 0 ? this.props.location.state.objInfo1.longitude : 51.44703541419062,
            address: this.props.location.state && this.props.location.state.objInfo1.address != '' ? this.props.location.state.objInfo1.address : '',
            pelak: this.props.location.state && this.props.location.state.objInfo1.plate != 0 ? this.props.location.state.objInfo1.plate : '',
            sending:false,
            province: [],
            province2: [],
            cities: [],
            cities2: [],

            errText: '',
            errType: '',
            selectedProvince: this.props.location.state && this.props.location.state.objInfo1.provinceName != '' ? this.props.location.state.objInfo1.provinceName : '',
            selectedProvinceId: this.props.location.state && this.props.location.state.objInfo1.provinceId ? this.props.location.state.objInfo1.provinceId : null,
            selectedCity: this.props.location.state && this.props.location.state.objInfo1.cityName != '' ? this.props.location.state.objInfo1.cityName : '',
            selectedCityId: this.props.location.state && this.props.location.state.objInfo1.cityId != '' ? this.props.location.state.objInfo1.cityId : null,
        }
        this.BuildingInfo = this.props.location.state && this.props.location.state.objInfo1;
        this.Methods = this.props.location.state && this.props.location.state.Methods;
    }
    componentDidMount() {
        console.log(this.BuildingInfo);
        if (!this.BuildingInfo) {
            this.props.history.push('/Building')
        }
        this.getCities();
    }
    getCities() {
        axios({
            method: 'Post',
            url: '/City/List',
            data: {
                provinces: true,
                provinceId: 0,
                nameLike: '',
                pageSize: 1000,
                pageNumber: 0
            }
        })
            .then(res => {
                if (res.data.result) {
                    this.setState({
                        province: res.data.data,
                        province2: res.data.data,
                    })

                } else {
                }
            })
            .catch(err => {
                console.log(err.response);
            })
    }

    getCoordinate(e) {
        this.setState({
            lat: e.getCenter().lat,
            lng: e.getCenter().lng,
        });

    }


    saveLoc() {
        this.BuildingInfo.latitude = this.state.lat;
        this.BuildingInfo.longitude = this.state.lng;
        // console.log('this.BuildingInfo', this.BuildingInfo);
        this.setState({
            showModal: true
        })
    }
    setProvince(id) {
        this.state.province.find((element) => {
            if (element.id === id) {
                this.setState({
                    selectedProvince: element.text,
                    selectedProvinceId: id
                })
            }
        })
        this.setState({
            loading: true
        });
        axios({
            method: 'Post',
            url: '/City/List',
            data: {
                provinces: false,
                provinceId: parseInt(id),
                nameLike: '',
                pageSize: 1000,
                pageNumber: 0
            }
        })
            .then(res => {
                //   console.log(res);
                if (res.data.result) {
                    this.setState({
                        cities: res.data.data,
                        cities2:res.data.data,
                        loading: false
                    })
                    //console.log('>>>', this.state.province);

                } else {
                    //console.log('jhjhj');
                }
            })
            .catch(err => {
                console.log(err.response);
            })

    }

    setCity(id) {
        this.state.cities.find((element) => {
            if (element.id === id) {
                this.setState({
                    selectedCity: element.text,
                    selectedCityId: id
                })
            }
        })
    }

    nextStep() {
        if (this.state.address.length < 1
            || this.state.pelak.length < 1
            || this.state.selectedProvince.length < 1
            || this.state.selectedCity.length < 1) {
            this.setState({
                errText: 'تمام فیلدها الزامی است',
                errType: 'war'
            });
            setTimeout(() => {
                this.clearErr();
            }, 3000);
            return;
        } else {
            // console.log('this.state.selectedProvinceId', this.state.selectedProvinceId);
            this.BuildingInfo.address = this.state.address;
            this.BuildingInfo.plate = this.state.pelak;
            this.BuildingInfo.cityName = this.state.selectedCity;
            this.BuildingInfo.provinceName = this.state.selectedProvince;
            this.BuildingInfo.cityId = parseInt(this.state.selectedCityId);
            this.BuildingInfo.provinceId = parseInt(this.state.selectedProvinceId);
            this.setState({sending:true});
            ApiService('Post', `/Building/SubmitBuilding/${0}`, this.BuildingInfo)
                .then(res => {
                    if (res.data.result) {
                        //this.BuildingInfo.name = '';
                        browserHistory.replace({
                            pathname: '/Building',
                            state: {}
                        });
                        MyBuildingList(true).then(res => {
                            window.location.reload();
                        })



                    } else {
                        if (res.data.resultCode === 555) {
                            this.setState({
                                errText: res.data.message,
                                errType: 'err'
                            });
                            setTimeout(() => {
                                this.clearErr();
                                this.setState({sending:false});
                            }, 3000);
                        }
                    }
                })

        }
    }
    clearErr() {
        this.setState({
            errText: '',
            errType: '',
            sending:false
        });
    }
    closeSelect() {
        this.setState({
            showOptions: false,
            sending:false

        })
        this.NewSelect && this.NewSelect.lastObj && this.NewSelect.lastObj.close();
    }



    render() {
        return (
            <>

                <Modal show={this.state.showModal} btn={true} click={() => {
                    this.setState({
                        showModal: false
                    })
                }}>

                    <div className={'  w-100 mt-2 mb-1 position-relative'}
                        style={{ border: '1px solid #000000de', borderRadius: 4 }}>
                        <div className={`d-flex justify-content-between flex-row-reverse align-items-center`}>
                            <input value={this.state.searchText}
                                placeholder={'انتخاب استان*'}
                                onClick={() => {
                                    this.setState({
                                        showOptions: !this.state.showOptions
                                    })
                                    this.forceUpdate()
                                }}
                                onChange={(e) => {
                                    if (e.target.value.length < 0) {
                                        this.setState({
                                            selectedProvince: '',
                                            selectedProvinceId: null
                                        })
                                    }
                                    this.setState({
                                        searchText: e.target.value
                                    })


                                    if (e.target.value.length > 2) {
                                        let tmp = [];
                                        this.state.province2.forEach(element => {
                                            if ((element.text ? element.text.search(e.target.value) : -1) >= 0) {
                                                tmp.push(element);

                                            }
                                            this.setState({
                                                province: tmp
                                            })

                                        })
                                    } else {
                                        this.setState({
                                            province: this.state.province2
                                        })
                                    }


                                    // this.forceUpdate()
                                }}
                                style={{
                                    border: 0, width: '90%',
                                    direction: 'rtl', textAlign: 'right',
                                    padding: '15.5px 12px'
                                }} />
                            <div onClick={() => {
                                this.setState({
                                    showOptions: !this.state.showOptions
                                })
                                this.forceUpdate()
                            }}
                                className={`p-2`}
                                style={{ border: 0, width: '10%' }} >
                                <img style={{ width: 10, height: '100%' }} src={arrow} />
                            </div>
                        </div>
                        <div className={`position-absolute shadow`} style={{
                            zIndex: '100',
                            width: '96%', left: '2%',
                            maxHeight: 250,
                            overflow: "auto",
                            backgroundColor: '#fff',
                            border: '1px solid #f7f7f7',
                            marginBottom: 2,
                            borderRadius: 8,
                            borderLeft: '1px solid #bfbfbf',
                            borderRight: '1px solid #bfbfbf',
                            borderBottom: '1px solid #bfbfbf',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            marginTop: '2px',
                            display: this.state.showOptions ? 'block' : 'none'
                        }}

                        >
                            {this.state.province.map(item => {
                                return (
                                    <div style={{ padding: '2%', textAlign: 'right' }}
                                        key={item.id} value={item.id}
                                        onClick={() => {
                                            this.setProvince(item.id);
                                            this.setState({
                                                searchText: item.text,
                                                showOptions: false
                                            })
                                            // this.setState({
                                            //     selectedTypeDoc: item.text,
                                            //     showOptions: false
                                            // })
                                        }}>{item.text}</div>
                                )
                            })
                            }
                        </div>
                    </div>
                    {/* <NewSelect options={this.state.province} plachodler={def} closeOther={() => { this.closeSelect() }}
                        obj={false} sel2={true}
                        value={this.state.selectedProvince}>
                        <div>
                            {this.state.province.map(item => {
                                return (
                                    <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                        this.setProvince(item.id);
                                    }}>{item.text}</div>
                                )
                            })
                            }
                        </div>

                    </NewSelect> */}

                    <div className={'  w-100 mt-2 mb-1 position-relative'}
                        style={{ border: '1px solid #000000de', borderRadius: 4 }}>
                        <div className={`d-flex justify-content-between flex-row-reverse align-items-center`}>
                            <input value={this.state.searchText1}
                                placeholder={'انتخاب شهر*'}
                                onClick={() => {
                                    this.setState({
                                        showOptions2: !this.state.showOptions2
                                    })
                                    this.forceUpdate()
                                }}
                                onChange={(e) => {
                                    if (e.target.value.length < 0) {
                                        this.setState({
                                            selectedCity: '',
                                            selectedCityId: null
                                        })
                                    }
                                    this.setState({
                                        searchText1: e.target.value
                                    })


                                    if (e.target.value.length > 2) {
                                        let tmp = [];
                                        this.state.cities2.forEach(element => {
                                            if ((element.text ? element.text.search(e.target.value) : -1) >= 0) {
                                                tmp.push(element);

                                            }
                                            this.setState({
                                                cities: tmp
                                            })

                                        })
                                    } else {
                                        this.setState({
                                            cities: this.state.cities2
                                        })
                                    }


                                    // this.forceUpdate()
                                }}
                                style={{
                                    border: 0, width: '90%',
                                    direction: 'rtl', textAlign: 'right',
                                    padding: '15.5px 12px'
                                }} />
                            <div onClick={() => {
                                this.setState({
                                    showOptions2: !this.state.showOptions2
                                })
                                this.forceUpdate()
                            }}
                                className={`p-2`}
                                style={{ border: 0, width: '10%' }} >
                                <img style={{ width: 10, height: '100%' }} src={arrow} />
                            </div>
                        </div>
                        <div className={`position-absolute shadow`} style={{
                            zIndex: '100',
                            width: '96%', left: '2%',
                            maxHeight: 250,
                            overflow: "auto",
                            backgroundColor: '#fff',
                            border: '1px solid #f7f7f7',
                            marginBottom: 2,
                            borderRadius: 8,
                            borderLeft: '1px solid #bfbfbf',
                            borderRight: '1px solid #bfbfbf',
                            borderBottom: '1px solid #bfbfbf',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            marginTop: '2px',
                            display: this.state.showOptions2 ? 'block' : 'none'
                        }}

                        >
                            {this.state.cities.map(item => {
                                return (
                                    <div style={{ padding: '2%', textAlign: 'right' }}
                                        key={item.id} value={item.id}
                                        onClick={() => {
                                            this.setCity(item.id);
                                            this.setState({
                                                searchText1: item.text,
                                                showOptions2: false
                                            })
                                            // this.setState({
                                            //     selectedTypeDoc: item.text,
                                            //     showOptions: false
                                            // })
                                        }}>{item.text}</div>
                                )
                            })
                            }
                        </div>
                    </div>
                    {/* <NewSelect options={this.state.cities} plachodler={def2} obj={false} sel2={true} value={this.state.selectedCity} closeOther={() => { this.closeSelect() }}>
                        <div>
                            {this.state.cities.map(item => {
                                return (
                                    <div style={{ padding: '2%' }} key={item.id} onClick={() => {
                                        this.setCity(item.id)

                                    }} value={item.id}>{item.text}</div>
                                )
                            })
                            }
                        </div>
                    </NewSelect> */}
                    <div className={`mt-3 `}>
                        <CpInput plachodler={'آدرس دقیق'} type={"text"} value={this.state.address} onChange={(e) => {
                            this.setState({
                                address: e.target.value,
                            })
                        }} />
                        <CpInput plachodler={' پلاک'} type={"text"} value={this.state.pelak} onChange={(e) => {
                            this.setState({
                                pelak: e.target.value
                            })
                        }} />
                    </div>

                    <div className={`w-75 mx-auto`}>
                        <SBotton title={'ثبت ساختمان'} gradian  click={() => {
                            this.nextStep();
                        }}  sending={this.state.sending} />
                    </div>
                </Modal>
                {this.BuildingInfo ? <Layout loaded={true} title={' افزودن ساختمان جدید'} isHome={false}
                    customBack={true}
                    path={'/building/add'}
                    obj={this.BuildingInfo}
                    paddSet={true} scroll={true}
                    loading={this.state.loading}>
                    <Toast
                        text={this.state.errText}
                        type={this.state.errType}
                    />
                    <ProgressBar wdth={this.state.wdth} />
                    <div className={`w-100 mx-auto`} style={{ height: `calc(100vh - ${56 + 'px'})` }} >
                        {/*                <div onClick={() => {*/}
                        {/*                      setShowModal(true);*/}
                        {/*                }}>*/}
                        {/*map*/}
                        {/*                </div>*/}
                        <MapP onMove={event => this.getCoordinate(event)} lat={this.state.lat} lng={this.state.lng}
                              containerStyle={{width : "100%" , height : "100%"}}
                              userLocation
                        >
                            <div>
                                {/* <img /> */}
                            </div>
                            <div style={{ display: this.state.selectLoc ? 'none' : 'block', width: '100px', left: '47%', bottom: 10, position: 'fixed' }}>
                                <SBotton title={'ثبت موقعیت'} gradian={true} click={() => {
                                    this.saveLoc()
                                }} />
                            </div>

                        </MapP>

                    </div>
                </Layout> : <div></div>}
            </>
        );
    }

};



export default withRouter(AddBuildingMap);





import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../Components/Layout/Layout';
import Toast from '../../Components/Toast/Toast';
import { serviceAxios } from "../../axios";
import useError from '../../hooks/useError';
import SBotton from '../../Components/Buttons/SBotton/SBotton';

const ServiceCallback = ()=>{
    const [loading , setLoading] = React.useState(true)
    const [order , setOrder] = React.useState(null)
    const {error , setError} = useError()
    const history = useHistory()
    const {id} = useParams()

    React.useEffect(()=>{
        serviceAxios.get(
            `/Order/Detail/${id}`
        ).then(res=>{
            console.log(res)
            setOrder(res.data.data)
            setLoading(false)
        }).catch(error =>{
            console.log(error)
            setLoading(false)
        })
    },[])

    return (
        <Layout 
            loaded={true} 
            title={'وضعیت سفارش'}
            isHome={false}  
            loading={loading}
            customBack={true}
            path="/"
        >
            {
                !loading &&
                <div className='container mt-3'>
                    {
                        !!order?
                        <>
                            <div className='d-flex flex-row justify-content-center w-100'>
                                {
                                    `سفارش ${id}`
                                }
                            </div>
                            <h4 className='text-center mt-4' style={{color : "green"}}>
                                پرداخت موفق
                            </h4>
                            <p className='mt-4 text-center'>
                                سفارش شما ثبت گردید
                            </p>
                            <div className='d-flex flex-row rtl mt-4'>
                                {
                                    !!order &&
                                    <div className='d-flex flex-grow-1 mx-1'>
                                        <SBotton
                                            title="جزییات"
                                            click={()=>{history.replace("/");history.push(`/service/orderDetails/${id}`);}}
                                            gradian
                                        />
                                    </div>
                                }
                                <div className='d-flex flex-grow-1 mx-1'>
                                    <SBotton
                                        title="خانه"
                                        click={()=>history.push(`/`)}
                                    />
                                </div>
                            </div>
                        </>:
                        null
                    }
                </div>
                
            }
            <Toast
                text={error.errTxt}
                type={error.errType}
            />
        </Layout>

    )
}
export default ServiceCallback;
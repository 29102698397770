import React from 'react';
import Layout from '../../../Components/Layout/Layout';
import CpInput from '../../../Components/Input/Input';
import { Checkbox } from '../../../Components/CheckBox/CheckBox';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import { Component } from 'react';
import AddBotton from '../../../Components/Buttons/AddBotton/AddBotton';
import GrayLine from '../../../Components/GrayLine/GrayLine';
import redDel from "../../../Assets/myAsset/minusRed.svg";
import { ApiService, isUserHomtick, MyBuildingList } from '../../../value';
import Toast from '../../../Components/Toast/Toast';
import NewSelect from '../../../Components/NewSelect/NewSelect';
import { withRouter } from 'react-router-dom';
import Modal from '../../../Components/Modal/Modal';
import DeleteModal from '../../../Components/Modal/DeleteModal/DeleteModal';

const def =
{

    name: 'انتخاب ساختمان',

};
let Self = null;
class BuildingSetting extends Component {

    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            // buildingList: [],
            // selectedBuilding: [],
            buildingName: '',
            loading: true,
            errText: '',
            errType: '',

            showModalAddManager: false,
            deleteModalShow:false,
            selectForDel:null,

            managers: {},
            selectedManager: null,

            getAccessInfo: false,
            accessInfo: {
                pUsersAllowViewCashDesk: false,
                pUsersViewTransactions: false,
                pUsersViewBedehkarPersons: false,
                pUsersViewBestankarPersons: false,
                pUsersViewPaymentTable: false
            },
            managersAcces: [
                {
                    key: "1000001", text: "ثبت و ویرایش اخبار و نظرسنجی", value: false
                },
                {
                    key: "1000002", text: "ثبت و ویرایش فیش و شارژ", value: false
                },
                {
                    key: "1000003", text: "تایید پرداخت", value: false
                },
                {
                    key: "1000004", text: "ارسال پیام", value: false
                },
                {
                    key: "1000005", text: "ویرایش واحد ها", value: false
                },
                {
                    key: "1000006", text: "دسترسی به صندوق", value: false
                },
                {
                    key: "1000007", text: "ثبت و ویرایش هزینه ها و درآمدها", value: false
                },
                {
                    key: "1000008", text: "درخواست وجه یا پرداخت از کیف پول ساختمان", value: false
                }
                ,
                {
                    key: "1000009", text: "مشاهده شماره تلفن ساکنین و مالکین", value: false
                }
            ],
            newManager: {

                permissions: [],
                hasSpecialCashDesk: true

            }

        }
    }
    componentDidMount() {
        if (this.props.location.state && this.props.location.state.pManageAdmins) {
            this.pManageAdmins = this.props.location.state.pManageAdmins;
            this.pEditBuildingPermissions = this.props.location.state.pEditBuildingPermissions;
            if (!this.props.location.state.pManageAdmins && !this.props.location.state.pEditBuildingPermissions) {
                this.props.history.push('/building')

            } else {
                this.getMyBuildingList();
                this.getBuildingAccess();
            }

        } else {
            this.props.history.push('/building')
        }



    }

    getMyBuildingList() {
        MyBuildingList(false).then(res => {
            let list = res;
            let x = '';
            if (list) {
                list.find(elem => {
                    if (elem.id == localStorage.getItem('id')) {
                        x = elem.name
                    }
                });
                console.log('xxxxxxxx', x);
                this.setState({

                    buildingName: x

                    // loading: false

                });

            }

        })
            .catch(err => {
                this.setState({
                    errText: '',
                    errType: '',
                    loading: false
                })
                this.clearErr()

            });
        this.checkLoadAll()

    }



    getBuildingAccess() {
        this.setState({
            loading: true
        })
        ApiService('POST', `/Building/GetBuildingAccess/${localStorage.getItem('id')}`, null)
            .then(response => {
                console.log('!!!!!!!!!!!!!!!11', response);
                if (response.data.result) {
                    console.log(response);
                    this.setState({
                        accessInfo: response.data.data,

                        getAccessInfo: true
                    })

                } else {
                    this.setState({
                        errText: response.data.message,
                        errType: 'war',
                        loading: false
                    });
                    this.clearErr();


                }
                this.checkLoadAll()
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    loading: false
                });
                this.clearErr();
                return
            })
    }


    submitAccess() {
        this.setState({
            loading: true
        })
        let data = this.state.accessInfo;
        console.log('dataaaaaacccsesssaaaaaaaa', data);
        ApiService('POST', `/Building/SubmitBuildingAccess/${localStorage.getItem('id')}`, data)
            .then(response => {
                console.log('))))))))))))))))))))))))))', response);
                if (response.data.result) {
                    this.setState({
                        accessInfo: response.data.data,
                        errText: 'دسترسی‌ها ثبت شد',
                        errType: 'suc',
                        loading: false
                    });
                    this.clearErr();

                } else {
                    this.setState({
                        errText: response.data.message,
                        errType: 'war',
                        loading: false
                    })
                    this.clearErr();

                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    loading: false
                });
                this.clearErr();
                return
            })
    }

    checkLoadAll() {
        console.log(this.state.accessInfo);
        if (this.state.buildingName.length > 0 && this.state.getAccessInfo) {
            this.setState({
                loading: false,
            })
        }
    }

    async getUserInfo(Mobile) {
        if (Mobile.length == 0) {
            return
        }
        if (Mobile.length != 11) {
            return
        }
        this.setState({
            loading: true
        })

        await isUserHomtick(Mobile).then(response => {
            console.log('owneeeeeeeer infoooooooo ', response);
            this.setState(prev => ({
                newManager: {
                    ...prev.newManager,
                    id: response.data.id,
                    name: response.data.name

                }
            }))



        })
    }

    addManager() {
        let data = this.state.newManager;

        data.setAsDeleted = false;
        console.log('data ===============', data);
        ApiService('Post', `/Building/AddUserToBuildingRole/${localStorage.getItem('id')}`, data)
            .then(res => {
                if (res.data.result) {


                    this.setState({
                        errText: this.state.selectedManager ? 'با موفقیت ویرایش شد.' :
                            'با موفقیت افزوده شد.',
                        errType: 'suc'
                    })
                    this.setState({
                        showModalAddManager: false
                    })
                    this.clearErr();

                    this.getBuildingAccess();



                }else{
                    this.setState({
                        errText: res.data.message,
                        errType: 'war'
                    })
                    this.clearErr();

                }


            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    loading: false
                });
                this.clearErr();
                return
            })
    }

   
    deleteManager(){
      let data =this.state.selectForDel;
        data.setAsDeleted = true
        console.log(data);
        ApiService('Post', `/Building/DeleteUserFromBuildingRole/${localStorage.getItem('id')}/${data.dbId}`, null)
        .then(res => {
            console.log('________جوااااااااااااااب_________', res);
            if (res.data.result) {
                this.setState({
                    errText: 'حذف با موفقیت انجام شد',
                    errType:'suc'        
                })
                this.setState({
                    deleteModalShow: false
                })
                this.clearErr();
                this.getBuildingAccess();
            }else{
                this.setState({
                    errText: res.data.message,
                    errType:'err'        
                });
                this.clearErr();

            }


        })
        .catch(err => {
            this.setState({
                errText: 'خطای داخلی سرور',
                errType:'err'  ,
                deleteModalShow: false      
            });
            this.clearErr();
            return

        })

    }
    clearErr() {
        setTimeout(() => {
            this.setState({
                errText: '',
                errType: ''
            })

        }, 3000)
    }
    closeSelect() {
        this.setState({
            showOptions: false
        })
        this.NewSelect && this.NewSelect.lastObj && this.NewSelect.lastObj.close();
    }

    render() {
        return (
            <>

                <Layout loaded={true} title={`تنظیمات ساختمان ${this.state.buildingName}`} loading={this.state.loading} isHome={false}  >
                    <Toast
                        text={this.state.errText}
                        type={this.state.errType}
                    />
                    {/* <Modal show={this.state.deleteModal}>

                    </Modal> */}
                     <DeleteModal show={this.state.deleteModalShow} deletable={true}
                        delete={() => { this.deleteManager() }}
                        doNotDelete={() => { this.setState({
                            deleteModalShow : false
                        }) }}
                        text={'از حذف مدیر اطمینان دارید؟'} click={() => {
                             this.setState({
                                deleteModalShow : false
                            })
                        }} />
                    {/* /****************************** Add Manager ****************** */}
                    <Modal show={this.state.showModalAddManager} btn={true} click={() => {
                        this.setState({
                            showModalAddManager: false
                        })
                    }}>
                        <div  >
                            <div className={`text-center mediumF fntBold mb-3`}>{this.state.selectedManager ?
                                `ویرایش مدیر ${this.state.selectedManager.name}` :
                                'افزودن مدیر'}
                            </div>
                            <CpInput plachodler={'شماره موبایل'} type={"phone"}
                                disabled={this.state.selectedManager ? true : false}
                                value={this.state.newManager ? this.state.newManager.mobileNumber : ''} maxLength={11} onChange={(e) => {
                                    if (e.target.value.length == 11) {
                                        let temp = this.state.newManager;
                                        temp.mobileNumber = e.target.value
                                        this.setState({
                                            newManager: temp
                                        })

                                    }
                                    if (e.target.value.length == 0) {
                                        this.setState({
                                            newManager: {
                                                mobileNumber: null,
                                                // id: null,
                                                // name:''

                                            }
                                        })
                                    }

                                }} />
                            <CpInput plachodler={'سمت '} maxLength={20}
                                type={"text"}
                                value={this.state.newManager ? this.state.newManager.roleName : ''}
                                onChange={(e) => {
                                    let temp = this.state.newManager;
                                    temp.roleName = e.target.value;
                                    this.setState({
                                        newManager: temp
                                    })
                                    console.log(this.state.newManager);

                                }} />

                            {/* <CpInput plachodler={' نام و نام‌خانوادگی'} disabled={!this.state.newManager.id? false: true } value={this.state.newManager.name} type={"text"} /> */}
                            {this.state.managersAcces.map((item, index) => {
                                return <div key={index} className={`rtl text-right`}>
                                    <Checkbox Name={'permision'} textStyle={{ fontSize: '12px' }}
                                        Label={item.text}
                                        checked={this.state.newManager && this.state.newManager.permissions && this.state.newManager.permissions[index] && this.state.newManager.permissions[index].value}
                                        onChange={(check) => {
                                            ///     console.log(  this.state.managersAcces[item.key].text);

                                            let temp = this.state.newManager;
                                            temp.permissions[index].value = check;
                                            this.setState({
                                                newManager: temp
                                            })

                                        }} />
                                </div>
                            })}
                            <div className={`w-50 mx-auto`}>
                                <SBotton title={'ثبت '} click={() => {
                                    this.addManager()
                                }} />
                            </div>
                        </div>



                    </Modal>


                   






                    <div style={{ height: 'inherit' }}>
                        <div className={`p-1`} style={{ height: `calc(100% - 1rem)`, overflow: 'auto' }}>
                            {<>
                                <div className={`w-100 text-right rtl pb-2 `}>
                                    <div className={'mt-2 mb-2 fntBold mediumF'}>تنظیمات دسترسی واحدها:</div>
                                    <Checkbox Name={'permision'}
                                        checked={this.state.accessInfo.pUsersViewBedehkarPersons}
                                        textStyle={{ fontSize: '12px' }} Label="دسترسی  واحدها به لیست بدهکاران"
                                        onChange={(check) => {
                                            this.setState(prev => ({
                                                accessInfo: {
                                                    ...prev.accessInfo,
                                                    pUsersViewBedehkarPersons: check
                                                }
                                            }))
                                        }} />
                                    <Checkbox Name={'permision'}
                                        checked={this.state.accessInfo.pUsersViewBestankarPersons}
                                        managersAcces
                                        textStyle={{ fontSize: '12px' }} Label="دسترسی  واحدها به لیست بستانکاران"
                                        onChange={(check) => {
                                            this.setState(prev => ({
                                                accessInfo: {
                                                    ...prev.accessInfo,
                                                    pUsersViewBestankarPersons: check
                                                }
                                            }))
                                        }} />
                                    <Checkbox Name={'permision'}
                                        checked={this.state.accessInfo.pUsersAllowViewCashDesk}
                                        textStyle={{ fontSize: '12px' }} Label="دسترسی  واحدها به جزییات صندوق"
                                        onChange={(check) => {
                                            this.setState(prev => ({
                                                accessInfo: {
                                                    ...prev.accessInfo,
                                                    pUsersAllowViewCashDesk: check
                                                }
                                            }))
                                        }} />
                                    <Checkbox Name={'permision'}
                                        checked={this.state.accessInfo.pUsersViewTransactions}
                                        textStyle={{ fontSize: '12px' }} Label="دسترسی  واحدها به جزییات تراکنش‌ها "
                                        onChange={(check) => {
                                            this.setState(prev => ({
                                                accessInfo: {
                                                    ...prev.accessInfo,
                                                    pUsersViewTransactions: check
                                                }
                                            }))
                                        }} />
                                    <Checkbox Name={'permision'}
                                        checked={this.state.accessInfo.pUsersViewPaymentTable} textStyle={{ fontSize: '12px' }} Label="دسترسی  واحدها به جدول پرداخت"
                                        onChange={(check) => {
                                            this.setState(prev => ({
                                                accessInfo: {
                                                    ...prev.accessInfo,
                                                    pUsersViewPaymentTable: check
                                                }
                                            }))
                                        }} />
                                </div>
                                <div className={`w-50 mx-auto`}>
                                    <SBotton title={'ثبت دسترسی‌ها'} click={() => {
                                        console.log(this.state.accessInfo);
                                        this.submitAccess()
                                    }} />
                                </div>



                            </>}
                            <GrayLine />
                            <div className={`w-100 text-right  pt-2 `}>
                                <AddBotton title={'اضافه کردن مدیر '}
                                    click={() => {
                                        this.setState({
                                            newManager: {
                                                permissions: this.state.managersAcces,
                                                mobileNumber: '',
                                                roleName: '',
                                                hasSpecialCashDesk: true
                                            },
                                            showModalAddManager: true,
                                            selectedManager: null
                                        })
                                    }} />
                                {this.state.accessInfo.managers ?
                                    <div className={` mt-2 mb-3  w-100 text-right `}>
                                        <div className={`fntBold mt-2 mb-2`}>فهرست مدیریت ساختمان</div>
                                        {this.state.accessInfo.managers.list.length > 0 ?
                                            this.state.accessInfo.managers.list.map((item, index) => {
                                                return <div key={index} className={`d-flex align-items-center rtl justify-content-between p-1 m-2 `}
                                                    style={{ border: '1px solid grey', borderRadius: 10 }}
                                                    onClick={(e) => {
                                                        if (item.isEditable) {
                                                            this.setState({
                                                                showModalAddManager: true,
                                                                selectedManager: item,
                                                                newManager: {
                                                                    mobileNumber: item.mobileNumber,

                                                                    roleName: item.roleName,
                                                                    permissions: item.access

                                                                }
                                                            })

                                                        }
                                                        e.stopPropagation();
                                                        

                                                    }} >
                                                    <div style={{ fontSize: 12 }}>{item.name ? item.name : item.mobileNumber}{` ( ${item.roleName} ) `}</div>
                                                    {item.isEditable ? <div onClick={(e)=>{
                                                        console.log('kkkkk');
                                                        this.setState({
                                                            deleteModalShow: true,
                                                            selectForDel: item
                                                        })
                                                         e.stopPropagation();

                                                    }} style={{ width: 15 , cursor: 'pointer' }}><img style={{ width: '100%' }} src={redDel} /></div> : null}
                                                </div>
                                            })
                                            : null}
                                            
                                    </div> : null}
                            </div>
                        </div>
                    </div>

                </Layout>
            </>
        );

    }


};

export default withRouter(BuildingSetting);
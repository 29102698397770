import React from 'react';
import Card from '../../Card/Card';


const Energy = props => {
    return (
        <Card width={props.width} >
            <div className={`d-flex flex-column align-items-center w-100 p-2`}>
                <div className={'text-center'} >
                    <img src={props.img} style={{ width: '30%' }} alt={'alt'} />
                </div>
                <div className={`d-flex flex-column align-items-center `} >
                    <div className={`grey pt-2 `} style={{fontSize:'14px'}}>{props.amount} </div>
                    <div className={`grey  `} style={{fontSize:'15px'}}>{props.title}</div>
                    {/* <div style={{ fontWeight: '600', fontSize:'21px' }}>{props.title} </div> */}
                    
                </div>
            </div>
        </Card>
    );
};

export default Energy;
import { DialogContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import Date from '../../../Components/Date/Date';
import Doer from '../../../Components/Doer/Doer';
import Layout from '../../../Components/Layout/Layout';
import DeleteModal from '../../../Components/Modal/DeleteModal/DeleteModal';
import Modal from '../../../Components/Modal/Modal';
import Toast from '../../../Components/Toast/Toast';
import { ApiService, formatMoney } from '../../../value';



const DocView = props => {
    const [doc, setDoc] = useState(null);
    const [sendingBtn, setSendingBtn] = useState(false);
    const [docID, setDocID] = useState(null);
    const [buildingID, setBuildingID] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [deletePermision, setDeletePermision] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);


    useEffect(() => {
        if( props.match.params.id&& localStorage.getItem('id')) {
            setDocID(props.match.params.id)
            setBuildingID(localStorage.getItem('id'))
            getDetailOfDoc( props.match.params.id, localStorage.getItem('id'))
        }
        else
        {
            if (props.location.state && props.location.state.id && localStorage.getItem('id')) {
                setDocID(props.location.state.id);
                setBuildingID(localStorage.getItem('id'))
                getDetailOfDoc(props.location.state.id, localStorage.getItem('id'))
            } else {
                props.history.push('/')
            }
        }

    }, []);


    const getDetailOfDoc = (id, buildingId) => {
        ApiService('Get', `/AccRequest/AccDocumentDetail/${buildingId}/${id}`)
            .then(response => {
               
                if (response.data.result) {
                    setDoc(response.data.data);
                    setLoading(false);
                    isSeen(id, buildingId)
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    // setTab(false);
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })

    }

    const deleteDoc = (id, buildingId) => {
        setSendingBtn(true)
        setLoading(true);
        ApiService('Get', `/AccRequest/AccDocumentDelete/${buildingId}/${id}`)
            .then(response => {
                if (response.data.result) {
                    setLoading(false);
                    setErrText('سند با موفقیت حذف شد.');
                    setErrType('suc');
                    setDeleteModal(false);
                    //clearErr()

                    setTimeout(() => {
                        props.history.push('/fund')
                    }, 2000)


                }else{
                    setLoading(false);
                    setErrText(response.data.message);
                    setErrType('war');
                    setDeleteModal(false);

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const isSeen = (id, buildingId) => {
        ApiService('Get', `/AccRequest/Seen/${buildingId}/${id}/Acc`)
        .then(res=>{
            console.log('seeeeeeeeeen',res);
        })

    }
    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    return (doc ?
        <>

            <Layout loaded={true} isHome={false} showMenu={false} loading={loading}
                customBack={true}
                 path={props.location.state.backUrl? props.location.state.backUrl : '/'}
                >
                <Toast
                    text={errText}
                    type={errType}
                />






                <DeleteModal show={deleteModal} deletable={true}
                    delete={() => { deleteDoc(docID, buildingID) }}
                    doNotDelete={() => { setDeleteModal(false) }}
                    text={'از حذف سند اطمینان دارید؟'} click={() => {
                        setDeleteModal(false)
                    }} />



                <div style={{ height: 'inherit' }}>
                    <div style={{ height: `calc(100% - 6rem)`, overflow: 'auto', width: '90%', marginLeft: '5%' }}>
                        <div className={`mt-2 mb-2`}>
                            <div className={`w-25 text-light mb-2 text-center mx-auto`} style={{ backgroundColor: doc.priceType == 1 ? '#E33742' : '#0adf7b', borderRadius: 10 }}> {doc.priceType == 0 ? 'درآمد' : 'هزینه'}</div>
                            <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100`}>
                                <Doer creator={doc.creator.name} profileImage={doc.creator.profileImageUrl} />
                                <div>
                                    <Date date={doc.dateCreated} font={12} />
                                </div>
                            </div>

                          {doc.userRoleName ?  <div className={`d-flex align-items-center flex-row-reverse w-100`}>
                                <div className={`mediumF text-dark mt-1 mb-1 rtl fntBold text-right ml-2`}>نقش مدیر: </div>
                                <div style={{ fontSize: 12 }}>{doc.userRoleName}</div>
                            </div> : null}

                            {doc.accountingCategory ? <div className={`grey mediumF mb-3 mt-2 text-right`}>{doc.accountingCategory.text}</div> : null}
                            {/* <div className={`d-flex justify-content-between align-items-center flex-row-reverse mb-3`}> */}
                                <div className={`mediumF text-dark mt-3 rtl fntBold text-right`}>عنوان: </div>
                                
                            {/* </div> */}
                            <div className={`d-flex justify-content-between align-items-center flex-row-reverse mb-4`}>
                                    <div className={` text-dark text-center `}>{doc.subject}</div>

                                </div>
                            <div className={`d-flex justify-content-between align-items-center flex-row-reverse mb-3`}>
                                <div>مبلغ </div>
                                <div className={`d-flex justify-content-between align-items-center flex-row-reverse`}>
                                    <div className={` text-dark text-center pl-3 pr-3 pt-1 pb-1 ml-2 `} style={{ backgroundColor: '#D4D4D4', borderRadius: 15 }}>{formatMoney(doc.amount)}</div>
                                    <div>ریال</div>
                                </div>
                            </div>
                            <div style={{ borderTop: '1px solid #D4D4D4' }}>
                                <div className={`mediumF text-dark mt-3 mb-3 rtl fntBold text-right`} >توضیحات</div>
                                <div className={`grey mediumF text-right rtl`} style={{ whiteSpace: "pre-wrap" }}>
                                    {doc.description}
                                </div>
                            </div>


                            {doc.attachments && doc.attachments.length > 0 ?
                    <div className={`rtl mt-4 d-flex align-content-stretch flex-wrap w-100  justify-content-start align-items-baseline`}>
                        {doc.attachments.map((item, index) => {
                            return <div key={index}
                            >
                                <div className={` `} onClick={(e) => {
                                    window.open(item.path);
                                    e.stopPropagation();
                                }} style={{
                                    width: 110, height: 80,
                                    backgroundImage: "url(" + item.path + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div>
                        })}

                    </div>


                    : null}

                            {/* {doc.attachments ? <div className={`rtl`}>
                                <div className={` `} onClick={(e) => {
                                                window.open(doc.attachments[0].path);
                                                e.stopPropagation();
                                            }} style={{
                                    width: 110, height: 80,
                                    backgroundImage: "url(" + doc.attachments[0].path + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div> : null} */}

                            <div className={`w-75 mx-auto position-absolute  `} style={{ bottom: 0, left: '12.5%' }}>
                                {doc.pBtnEdit ? <SBotton title={'ویرایش'} gradian={true} click={() => {
                                    console.log('docdocdocdocdoc', doc);
                                    props.history.push({
                                        pathname: '/addDoc',
                                        state: {
                                            doc: doc,
                                        }
                                    });
                                }} /> : null}

                                {doc.pBtnDelete ? <SBotton title={'حذف'} sending={sendingBtn} click={() => {
                                    setDeleteModal(true)
                                    //  deleteDoc(docID, buildingID);
                                }} /> : null}
                            </div>

                        </div>
                    </div>
                </div>
            </Layout>
        </>
        : null
    );
};

export default withRouter(DocView);
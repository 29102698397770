
import classes from './ServiceFilterModal.module.css';
import React from 'react';
import Modal from '../Modal';
import SBotton from '../../Buttons/SBotton/SBotton';
import { Component } from 'react';
import { Checkbox } from '../../CheckBox/CheckBox';
import Collapsable from '../../Collapsable/Collapsable';
import DatePicker from '../../DatePicker/DatePicker';
import { formatMoney } from '../../../value';
import recyclebin from '../../../Assets/myAsset/recycle-bin.png'
import NewSelect from '../../NewSelect/NewSelect';
import { serviceAxios } from '../../../axios';
import moment from 'moment-jalaali';

let Self = null;
class ServiceFilterModal extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            amountS: '',
            amountE: '',
            isDeleted : false,
            dateS: props.showDateRange? 'از تاریخ' : 'تاریخ',
            dateE: 'تا تاریخ',
            selectedDesk: null,
            periods : [],
            selectedPeriod : {}

        }
        // this.showtype = 'personsCount';
        // this.showtype1= 'resident';

        this.filterData = {
            "startAmount": null,
            "endAmount": null,
            "isArchive" : false,
            "startDate": "",
            "endDate": "",
            "sortByType": 0,
            "sortIsDesc": true,
            "sortByType": 0,
            "periodId" : null,
            "showType" : "active"
        }
    }

    getPeriods = (date)=>{
        serviceAxios.post(
            `Period/List/${this.props.serviceId?this.props.serviceId : 0}`,
            {
                "date": moment(date , "jYYYY/jMM/jDD").format("YYYY-MM-DD"),
                "passed": true,
                "nameLike": "",
                "pageSize": 1000,
                "pageNumber": 1
            }
        ).then(res=>{
            this.setState({periods : res.data.data})
        }).catch(error =>{

        })
    }

    render() {
        return (
        <>
            <Modal show={this.props.show} btn={true} click={this.props.click} >
                <DatePicker showPicker={this.state.showDatePicker} disMiss={() => {
                    this.setState({
                        showDatePicker: false
                        })
                    }} 
                    click={(e) => {
                        if (this.state.firstOne) {
                            this.filterData.startDate = e
                            this.filterData.endDate = this.props.showDateRange? this.state.dateE : e
                            this.setState({
                                dateS: e,
                                dateE : this.props.showDateRange? this.state.dateE : e,
                                showDatePicker: false
                            })
                        }else{
                            this.filterData.endDate = e
                            this.setState({
                                dateE: e,
                                showDatePicker: false
                            })
                        }
                            
                        !this.props.showDateRange && this.getPeriods(e)
                    }} 
                />
                <div style={{
                    textAlign: 'center',
                    height: '65vh'
                }}>

                    <div style={{ height: '90%', overflow: 'auto', position: 'relative' }}>
                        {
                            this.props.showCategory ?
                            <>
                                <div className={`fntBold mt-2 mb-2`} style={{ direction: 'rtl', textAlign: 'right', fontSize: 12 }} >نحوه نمایش سفارشات:</div>
                                <div className={`d-flex justify-content-between align-items-center m-2 p-2 borderShadow `} style={{ border: 1, borderRadius: 10 }}>
                                    <div className={`rtl`} style={{ width: '45%' }}>
                                        <Checkbox Type={'Radio'} Label={'حذف شده ها'} checked={this.filterData.isDeleted} textStyle={{ fontSize: '10px' }}
                                            onChange={checked => {
                                                if (checked) {
    
                                                    this.filterData.isDeleted = true
                                                    this.filterData.isArchive = false
                                                }
                                            }} Name={'dec'} />
                                    </div>
                                    <div className={`rtl`} style={{ width: '45%' }}>
                                        <Checkbox 
                                            Type={'Radio'} 
                                            Label={'پایان یافته ها '} 
                                            textStyle={{ fontSize: '10px' }}
                                            checked={this.filterData.isArchive} 
                                            Name={'dec'} 
                                            onChange={checked => { if (checked) { this.filterData.isArchive = true;this.filterData.isDeleted = false } }} 
                                        />
                                    </div>
                                    <div className={`rtl`} style={{ width: '45%' }}>
                                        <Checkbox 
                                            Type={'Radio'} 
                                            Label={'سفارشات باز'} 
                                            textStyle={{ fontSize: '10px' }}
                                            checked={!this.filterData.isDeleted} 
                                            Name={'dec'} 
                                            onChange={checked => { if (checked) { this.filterData.isDeleted = false;this.filterData.isArchive = false } }} 
                                        />
                                    </div>
                                </div>
                            </>:
                            null
                        }
                        {this.props.sortInc ? <> <div className={`fntBold mt-2 mb-2`} style={{ direction: 'rtl', textAlign: 'right', fontSize: 12 }} >مرتب‌سازی براساس:</div>

                            <div className={`d-flex justify-content-between align-items-center m-2 p-2 borderShadow `} style={{ border: 1, borderRadius: 10 }}>
                                <div className={`rtl`} style={{ width: '45%' }}>
                                    <Checkbox Type={'Radio'} Label={'بیشترین '} checked={this.filterData.sortIsDesc} textStyle={{ fontSize: '10px' }}
                                        onChange={checked => {
                                            if (checked) {

                                                this.filterData.sortIsDesc = true

                                            }
                                        }} Name={'dec'} />
                                </div>
                                <div className={`rtl`} style={{ width: '45%' }}>
                                    <Checkbox Type={'Radio'} Label={'کمترین '} textStyle={{ fontSize: '10px' }}
                                        //  checked={!this.filterData.sortIsDesc} 
                                        Name={'dec'} onChange={checked => { if (checked) { this.filterData.sortIsDesc = false } }} />
                                </div>
                            </div> </> : null}


                        {this.props.amount ? <div className={`d-flex justify-content-between align-items-center  m-2 p-2 borderShadow   `}
                            style={{ border: 1, borderRadius: 10 }}>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Type={'Radio'} Label={'مبلغ '} textStyle={{ fontSize: '10px' }}
                                    onChange={checked => { if (checked) { this.filterData.sortByType = 1 } }} Name={'type'} />
                            </div>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Type={'Radio'} Label={'تاریخ '} checked={this.filterData.sortByType == 0} textStyle={{ fontSize: '10px' }}
                                    //  checked={!this.filterData.sortIsDesc} 
                                    Name={'type'} onChange={checked => { if (checked) { this.filterData.sortByType = 0 } }} />
                            </div>
                        </div> : null}

                        {this.props.amount ?
                            <div
                            // className={`d-flex justify-content-around flex-row-reverse p-2`}
                            >
                                <div className={`d-flex justify-content-around align-items-center flex-row-reverse p-2`}>
                                    <div className={`rtl mediumF`}> از مبلغ:</div>
                                    <div className={`d-flex rtl  p-2 m-1`} style={{ textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}  >
                                        <input className={classes.inputS} placeholder={'از مبلغ '}
                                            value={formatMoney(this.state.amountS)}

                                            onChange={(e) => {
                                                let tmp = e.target.value.replace(/,/g, "")
                                                if (+tmp === +tmp) {

                                                    this.setState({
                                                        amountS: tmp

                                                    });
                                                    this.filterData.startAmount = parseInt(tmp)
                                                }
                                                else {
                                                    this.setState({
                                                        amountS: this.state.dateS
                                                    })
                                                }
                                            }}
                                        />
                                        <span>ریال</span>
                                    </div>
                                </div>


                                <div className={`d-flex justify-content-around align-items-center flex-row-reverse p-2`}>
                                    <div className={`rtl mediumF`}> تا مبلغ:</div>
                                    <div className={`d-flex rtl  mediumF p-2 m-1`} style={{ textAlign: 'center', border: '1px solid grey', borderRadius: 5 }}  >
                                        <input className={classes.inputS} placeholder={'تا مبلغ '}
                                            value={formatMoney(this.state.amountE)}
                                            onChange={(e) => {
                                                let tmp = e.target.value.replace(/,/g, "")
                                                if (+tmp === +tmp) {

                                                    this.setState({
                                                        amountE: tmp

                                                    });
                                                    this.filterData.endAmount = parseInt(tmp)


                                                }
                                                else {

                                                    this.setState({
                                                        amountE: this.state.dateE
                                                    })
                                                }
                                            }}
                                        />
                                        <span>ریال</span>
                                    </div>
                                </div>
                            </div> : null}

                        <div className={`d-flex justify-content-center flex-row-reverse p-2 mb-3`}>
                            <div 
                                className={`p-2 m-1 `} 
                                onClick={() => {
                                    this.setState({
                                        showDatePicker: true,
                                        firstOne: true
                                    })
                                }}
                                style={{ width: '40%', borderRadius: 10, border: '1px solid #c2c7d2', fontSize: 11 }}
                            >
                                    {this.state.dateS}
                            </div>
                            {
                                this.props.showDateRange ?
                                <div 
                                    className={`p-2 m-1 `} 
                                    onClick={() => {
                                        this.setState({
                                            showDatePicker: true,
                                            firstOne: false
                                        })
                                    }}
                                    style={{ width: '40%', borderRadius: 10, border: '1px solid #c2c7d2', fontSize: 11 }}
                                >
                                        {this.state.dateE}
                                </div>:
                                null
                            }
                                
                            <div style={{ width: 40 }} onClick={() => {
                                this.setState({
                                    dateS: this.props.showDateRange? 'از تاریخ' : 'تاریخ',
                                    dateE: 'تا تاریخ',
                                    selectedPeriod : {},
                                    periodId : null
                                });
                                this.filterData.startDate = ''
                                this.filterData.endDate = ''
                                this.filterData.periodId = null
                            }}>
                                <img src={recyclebin} alt={'alt'} style={{ width: '100%' }} />
                            </div>
                        </div>

                        {
                            this.props.showPeriods && !this.state.dateS.includes("تاریخ") &&
                            <div style={{ width: '70%', margin: 'auto', position: 'relative' }}>
                                <NewSelect 
                                    options={this.state.periods} 
                                    plachodler={"سانس را انتخاب کنید"} 
                                    obj={false} 
                                    sel2={false} 
                                    value={this.state.selectedPeriod.title} 
                                >
                                    
                                    {
                                        this.state.periods.map(item => {
                                            return (
                                                <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                                    this.state.periods.find(elem => {
                                                        if (elem.id == item.id) {
                                                            this.setState({selectedPeriod : elem});
                                                            this.filterData.periodId = elem.id
                                                        }
                                                    })
                                                }}>{item.title}</div>
                                            )
                                        })
                                    }
                                </NewSelect>
                            </div>
                        }
                    </div>
                    
                    <div style={{ width: '95%', position: 'absolute', backgroundColor: 'white', height: '10%' }}>
                        <div className={`w-50 mx-auto`}>
                            <SBotton title={'فیلتر'} gradian click={() => { this.props.submit(this.filterData) }} />
                        </div>

                    </div>

                </div>
            </Modal>
        </>
        )
    }

}
export default ServiceFilterModal;








import React from 'react';
import add from '../../../Assets/myAsset/plus.svg'


const AddBotton = props => {
    return (
       <div className={`d-flex flex-row-reverse mb-1  align-items-center justify-content-center`}
       onClick={props.click}
       style={{backgroundColor:'#FF5E1B', borderRadius:'15px', cursor: 'pointer'}}>
           <div className={`text-right text-white `} style={{width:'90%', fontSize:'14px', padding:'8px 19px'}}>{props.title}</div>
           <div style={{width:'10%'}}>
               <img className={`w-50 m-2 h-100`} src={props.img ? props.img : add} alt={'alt'}/>
           </div>

       </div>
    );
};

export default AddBotton;
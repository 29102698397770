import React from "react";

const useError = () => {
    const [error, setErr] = React.useState({errTxt : "" , errType : "war"});

    React.useEffect(() => {
        if(error.errTxt !== ''){
            clearErr();
        }
    }, [error.errTxt]);

    const setError = (text, type="war") =>{
        setErr({errTxt : text , errType : type})
    }

    const clearErr = () => {
        setTimeout(() => {
            setErr({errTxt : "" , errType : ""});
        }, 3000)
    }

    return { error , setError }
}

export default useError;
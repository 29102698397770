

import react , { Component } from 'react';
import Modal from '../Modal';



let Self = null;
export class PayGatesModal extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
           
        }
    }


    
        render(){
            return( <Modal show={this.props.showModal} btn={true} click={this.props.close}>
                   <div>
                            <div className={`mb-4 `} style={{ textAlign: 'right', direction: 'rtl', fontSize: '15px' }}>درگاه پرداخت را انتخاب کنید</div>
                            <div className={`d-flex align-content-stretch flex-wrap w-100  justify-content-center align-items-baseline  `}>
                                {this.props.gateList && this.props.gateList.map(item => {
                                    return (
                                        <a key={item.id} href={`${item.url}`} style={{ width: "35%" }}>
                                            <div className={`m-2 text-center p-1 borderShadow customBorderR `} style={{ width: "fit-content" }}>
                                                <div className={`mx-auto`} style={{ width: "50%" }}><img style={{ width: "100%" }} src={item.imageUrl} /></div>
                                                <div style={{ fontSize: '12px' }}>{item.name}</div>
                                            </div>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
     
                 </Modal>
            )
        }
}

export default PayGatesModal;
import React, { useEffect, useState } from 'react';
import classes from './BaseBx.module.css'
import { useHistory } from 'react-router-dom'




const BaseBX = props => {

    

const [ locationKeys, setLocationKeys ] = useState([])
const history = useHistory()


    useEffect(() => {
        return history.listen(location => {
          if (history.action === 'PUSH') {
            setLocationKeys([ location.key ])
          }
      
            console.log('history',history);
          if (history.action === 'POP') {
            if (locationKeys[1] === location.key) {
            
            
            
                 setLocationKeys(([ _, ...keys ]) => keys)
      
              // Handle forward event
      
            } else {
             setLocationKeys((keys) => [ location.key, ...keys ])
                console.log('gggggggggggggg',window.location.href)
                console.log('locationKeys',locationKeys);
              // Handle back event
      
            }
          }
        })


        window.onpopstate = ()=> {

            console.log('gggggggggggggg',window.location.href)



        }



      }, [ locationKeys, ])





    return(
        <div id={'baseBx'} className={classes.baseBX} style={{ position:  'relative', overflow:'hidden', height: props.BxHeight? props.BxHeight : '100vh'}}>
            {props.children}
        </div>
    )
}
export default BaseBX;

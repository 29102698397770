import React from 'react';
import Date from '../../Date/Date';
import Card from '../../Card/Card';
import Doer from '../../Doer/Doer';
import { formatMoney } from '../../../value';





const RequestCard = props => {

const stateviw=()=>{



switch(props.item.transferStatus){
 case 0:
     return (  <div className={`text-center text-light`} style={{fontSize:10,padding:1,backgroundColor:'#FFAB1B', borderRadius:15,width:'30%', margin:'0 auto'}}>تنظیم شده</div>)
     break;
     case 1:
  return(  <div className={`text-center text-light`} style={{fontSize:10,padding:1,backgroundColor:'#FFAB1B', borderRadius:15,width:'30%', margin:'0 auto'}}>منتظر تایید</div>)
  break;
  case 2:
return(  
    <div className={`text-center text-light`} style={{fontSize:10,padding:1,backgroundColor:'#b1e337', borderRadius:15,width:'30%', margin:'0 auto'}}>ثبت شده</div>)
    break;
    case 3:
 return(  
    <div className={`text-center text-light`} style={{fontSize:10,padding:1,backgroundColor:'#e33742', borderRadius:15,width:'30%', margin:'0 auto'}}>ابطال شده</div>)
    break;
}
}


    return (<>
       {props.item ? <Card width={'100%'} >
            <div className={`w-100 p-2`} style={{ lineHeight: 2 }} onClick={props.click}>
                {stateviw()}
                <div className={`d-flex w-100 flex-row-reverse justify-content-between align-items-center `}>
                    <Doer profileImage={props.item.requester.profileImageUrl} creator={props.item.requester.name} />
                    <div>
                        <Date date={props.item.requestDate} font={11} />
                    </div>
                </div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse mt-2`}>
                    <div className={`rtl text-right fntBold mediumF mr-2`} style={{ fontSize: 12 }}>مبلغ :</div>
                    <div className={`grey mr-2 rtl ml-2`} style={{ fontSize: 11 }}>{formatMoney(props.item.amount)}ریال</div>
                </div>
               {props.item.description ? <div className={`d-flex justify-content-between align-items-center flex-row-reverse mt-2`}>
                    {/* <div className={`rtl text-right fntBold mediumF mr-2`} style={{ fontSize: 12 }}>توضیحات:</div> */}
                    <div className={`grey mr-2 rtl ml-2 rtl text-right `} style={{ fontSize: 11 }}>
                        <span className={`rtl text-right fntBold `} style={{color:'#212529', fontSize:12}}>توضیحات: </span>
                        {props.item.description}</div>
                </div> : null}
            </div>
        </Card> : null}
        </>
    );
};

export default RequestCard;
import React, { useEffect, useState } from 'react';
import classes from './Home.module.css'
import Card from "../../Components/Card/Card";

import AddBotton from '../../Components/Buttons/AddBotton/AddBotton';
import HomeCard from '../../Components/Cards/HomeCards/HomeCard';
import Layout from '../../Components/Layout/Layout';
import axios, { serviceAxios } from '../../axios';
import { withRouter , useHistory } from 'react-router-dom';
import { ApiService, getProfile, MyBuildingList } from '../../value';
import NewSelect from '../../Components/NewSelect/NewSelect';
import Toast from '../../Components/Toast/Toast';
import SurveyCard from '../../Components/Cards/SurveyCard/SurveyCard';
import ChargeCard from '../../Components/Cards/ChargeCard/ChargeCard';
import EmptyList from '../../Components/EmptyList/EmptyList';
import Incoms from '../../Components/Cards/Incoms/Incoms';
import EventCard from '../../Components/Cards/EventCard/EventCard';
import QuestionCard from '../../Components/Cards/QuestionCard/QuestionCard';
import Energy from '../../Components/Cards/Energy/Energy';
import increas from '../../Assets/myAsset/growth.png';
import decreas from '../../Assets/myAsset/decreasing.png'

import equal from '../../Assets/myAsset/stocks.png'
import Modal from '../../Components/Modal/Modal';
import SBotton from '../../Components/Buttons/SBotton/SBotton';
import MessageModal2 from '../../Components/Modal/MessageModal2/MessageModal2';
import ActiveOrders from '../../Components/ActiveOrders/ActiveOrders';
import NeedRateOrder from '../../Components/NeedRateOrder/NeedRateOrder';
import OpenOrder from "../../Components/NeedRateOrder/OpenOrder";
import OrderPass from "../../Components/NeedRateOrder/OrderPass";

// import { askForPermissioToReceiveNotifications } from '../../push-notification';

const Home = props => {

  const [loading, setLoading] = useState(true);
  const [buildingList, setBuildingList] = useState([]);
  const [selectedBulding, setSelectedBulding] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [errText, setErrText] = useState('');
  const [errType, setErrType] = useState('');

  const [badgCount, setBadgCount] = useState(0);

  const [showModal,setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);

  const [metaDataCardToCard,setMetaDataCardToCard] = useState([]);

  const history = useHistory()


  // const [name, setName] = useState(0);
  // const [mobile, setMobile] = useState(0);
  // const [wallet, setWallet] = useState(0);

  //const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    // askForPermissioToReceiveNotifications()
    getHomeData()

  }, []);

  useEffect(() => {
    localStorage.removeItem("orderDetails")
    return () => {
      console.log("cleaned up");
    };
  }, []);
  useEffect(() => {
    setBadgCount(props.badgCount)
  }, [props.badgCount])

  // useEffect(() => {

  // }, []);


  const getHomeData = () => {
    let forcProf = false;
    if (localStorage.getItem('profile')) {
      forcProf = true
    } else {
      forcProf = false;
    }
    getProfile(forcProf).then(response => {
      if (response.name.length < 1 && response.lastName.length < 1) {
        props.history.push({
          pathname: '/info'
        })
      } else {
        MyBuildingList(true).then(res => {
          let list = res;
          if (list) {
            setBuildingList(list);
            setSelectedBulding(list.find(elem => { return elem.id == localStorage.getItem('id') }));
            if (localStorage.getItem('id')) { getDashbordData(localStorage.getItem('id')); }
            // setLoading(false);
          }
        })
          .catch(err => {
            getDashbordData(0);
            localStorage.removeItem("list");
            localStorage.removeItem("id");
            setLoading(false);
            setErrText(err.data.message);
            setErrType('war');
            clearErr();
          })

      }
    })
      .catch(err => {
        setLoading(false);
        setErrText(err.data.message);
        setErrType('war');
        clearErr();
      })

    // MyBuildingList(true).then(res => {
    //   let list = res;
    //   console.log('(((((((((((9', list);

    //   if (list) {
    //     setBuildingList(list);
    //     setSelectedBulding(list.find(elem => { return elem.id == localStorage.getItem('id') }));
    //     if(localStorage.getItem('id'))
    //    { getDashbordData(localStorage.getItem('id'));}

    //   }


    // })
    //   .catch(err => {
    //     setLoading(false);
    //   })


  }

  const getDashbordData = (id) => {

    setLoading(true);
    setBadgCount(props.badgCount)

    ApiService('Get', `/App/GetDashboard/${id}`)
      .then(response => {
        if (response.data.result) {
          setDashboardData(response.data.data.rows);
          setLoading(false)
        } else {
          setErrText(response.data.message);
          setErrType('war');
          clearErr();
          // setTab(false);
          setLoading(false);
        }

      })
      .catch(err => {
        setErrText('خطای داخلی سرور');
        setErrType('err');
        setLoading(false);
        clearErr();
        return
      })

  }

  const sumOfDebt = (items) => {
    let sum = 0
    items.data.forEach(element => {
      sum += parseInt(element.amountPayable);
    });
    return sum;
  }
  const clearErr = () => {
    setTimeout(() => {
      setErrText('');
      setErrType('');
    }, 3000)
  }
  const closeSelect = () => {

    NewSelect && NewSelect.lastObj && NewSelect.lastObj.close();
  }

  const setAnswer = (key, answer, title, metaData) => {
   
    ApiService('Get', `/App/SetQuestion/${key}/${answer}`)
      .then(res => {
        if (res.data.result) {
          if (title == 'کارت به کارت') {
            if (answer == 'YES') {
              getDashbordData(localStorage.getItem('id'));    
                        setMetaDataCardToCard(metaData)
              setShowModal(true)

            } else {
              setErrType('suc');
              setErrText('با موفقیت اعمال شد');
              clearErr();
            }

          } 
          else {

            setErrType('suc');
            setErrText('با موفقیت اعمال شد');

            clearErr();
            // window.location.reload();
            if (answer == 'DeleteBuilding') {
              window.location.reload();

            } else {
              getDashbordData(localStorage.getItem('id'));
            }
          }


        } else {
          setErrType('war');
          setErrText(res.data.message);
          clearErr()


        }
      })
      .catch(err => {
        setErrText('خطای داخلی سرور');
        setErrType('err');
        clearErr();
        return
      })
  }



  const setAction = (dataId, key) => {
    ApiService('Get', `/App/SetAction/${key}/${dataId}`)
      .then(res => {
        if (!res.data.result) {
          setErrType('war');
          setErrText(res.data.message);
          clearErr()
        }
      })
      .catch(err => {
        setErrText('خطای داخلی سرور');
        setErrType('err');
        clearErr();
        return
      })
  }

  const setErr = (errText , errType)=>{
    setErrText(errText);
    setErrType(errType);
    clearErr();
  }

  const IsAuthenticated = () => {
    console.log('IsAuthenticated func');
    axios({
      method: 'Get',
      url: '/User/IsAuthenticated'
    })
      .then(res => {
        console.log(res);
        if (res.result) {
          props.history.push({
            pathname: '/'
          })
        } else {
          console.log('jhjhj');
          // props.history.push({
          //     pathname: '/login'
          //   })
        }
      })
      .catch(err => {

        console.log(err.response);
        //   props.history.push({
        //     pathname: '/login'
        //   })
      })
  }

  //   const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);
  //   const sideDrawerClosedHandler = () => {
  //     setSideDrawerIsVisible(false);
  //   };
  const sampleOp = [
    {
      value: 1,
      label: 'نگین'
    },
    {
      value: 2,
      label: 'پارس'
    },
    {
      value: 3,
      label: 'sffcs'
    },

  ];
  const def =
  {
    id: '0',
    text: 'ساختمان خود را انتخاب کنید'
  };
  return (
    buildingList ?
      <Layout loaded={true} isHome={true} showMenu={true} loading={loading} displayMenu={true} loaded={true} badgCount={badgCount}>
        <Toast
          text={errText}
          type={errType}
        />
        <Modal show={showModal} btn={true} click={() => {
          setShowModal(false)
        }}>
          <div className={`rtl text-right mediumF mb-4`}>
            { metaDataCardToCard ? `تایید کارت به کارت به مبلغ ${metaDataCardToCard.amount} با موفقیت اعمال شد.`: null}
          </div>
          <div className={`w-50 mx-auto`}>
            <SBotton title={'ارسال پیام '} gradian click={() => {
             console.log('پیام');
             setShowModal(false)
             setShowModalMessage(true)
            }} />
          </div>
        

        </Modal>

        <MessageModal2 show={showModalMessage}  isClose={showModalMessage}
                text={metaDataCardToCard && metaDataCardToCard.user ? ` ارسال پیام تایید کارت به کارت به ${metaDataCardToCard.user.name} `: null}
                defaultText={metaDataCardToCard  ?`کارت به کارت شما به مبلغ ${metaDataCardToCard.amount} توسط مدیر تایید شد.`: null}
                onErr={(txt,type) => {
                    setErrText(txt);
                    setErrType(type);
                    clearErr();
                    return
                }}      
                type={true}
                 selectedUnit={[metaDataCardToCard.unit]}
                 role={metaDataCardToCard.role}
                // sendMessage={(messData) => {
                //     console.log('messDatamessDatamessDatamessData', messData);
                //     messData.units = metaDataCardToCard.unit
                //     setMessageData(messData);
                //     sendMessage(messData)
                // }}
                data={null}
                onClose={() => {
                    setShowModalMessage(false)
                }}

                click={() => {
                    setShowModalMessage(false)
                }}
                type={2}
                isClose={showModalMessage}  />
        <div className={`mt-2 mb-2`} style={{ height: 'inherit' }}>
          {buildingList.length > 1 ?
            <div className={`mt-2 mb-2`} >
              <NewSelect options={buildingList} plachodler={def} obj={false} sel2={false} value={selectedBulding} closeOther={() => { closeSelect() }}>
                {/* <div> */}
                {buildingList.map(item => {
                  return (
                    <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                      buildingList.find(elem => {
                        if (elem.id == item.id) {
                          setSelectedBulding(elem);
                          localStorage.setItem('id', elem.id);
                          getDashbordData(elem.id);

                        }
                      })
                      console.log(item);
                    }}>{item.name}</div>
                  )
                })}
              </NewSelect>
            </div>
            :
            buildingList.length == 1 ?
              <div className={`w-100 text-right pt-1 pb-1 pr-4 mt-2 mb-2 ${classes.single}`}>
                {buildingList[0].name}
              </div>
              : null
          }


          <div className={`pl-1 pr-1`} style={{ height: `calc(100% - 2rem)`, overflow: 'auto' }}>



            {buildingList.length == 0 ? <div className={`mb-2`}>
              <AddBotton title={'اضافه کردن ساختمان'} click={() => { props.history.push('/building/add') }} />
            </div> : null}

            {/* <button onClick={askForPermissioToReceiveNotifications} >Click here to receive notifications</button> */}



            {dashboardData.length > 0 ?
              dashboardData.map((item, index) => {
                return (
                  <div key={index}>
                    {item.componentType == 'Survey' ?

                      <SurveyCard creator={item.data.creator.name} createDt={item.data.createdDate}
                        title={item.data.title} profileImage={item.data.creator.profileImageUrl} options={item.data.options} Btn={true}
                        clickBtn={() => {
                          props.history.push({
                            pathname: '/survey',
                            state: {
                              id: item.data.id
                            }
                          })
                        }} />


                      : (item.componentType == 'RequestDocument' ?
                        <HomeCard title={' بدهکاری'}
                          pay={(e) => {
                            props.history.push({
                              pathname: '/pay',
                              state: {
                                payData: item.data[0].payData,
                                callBackUrl: window.location.href
                              }
                            });
                            e.stopPropagation();
                          }}
                          subTitle={'مبلغ بدهی'}
                          rows={item.data}
                          amount={sumOfDebt(item)}
                          clickMore={() => {
                            props.history.push({
                              pathname: '/receipt',

                            })
                          }}
                          change={false}
                          bedehi={true} /> :
                        (item.componentType == 'ChargeAlert' ?
                          <ChargeCard mounth={item.data.title}
                            dontShow={() => {
                              setAnswer(item.data.key, null)
                            }} click={() => {
                              props.history.push({
                                pathname: '/addReceipt',
                                state: {
                                  tab: 2,
                                  buildingId: localStorage.getItem('id')
                                }
                              })
                            }} /> :

                          (item.componentType == 'Ad' ?
                            <Card width={'100%'}  >
                              <img className={`w-100 mx-auto h-100`} style={{ borderRadius: 'inherit' }}
                                alt={'loading'} src={item.data.image.path}
                                onLoad={() => {
                                  setAction(item.data.id, 'adview')
                                  console.log('image is loaded', item.data);
                                }}
                                onClick={() => {
                                    if (item.data.url) {
                                      setAction(item.data.id, 'adclick');
                                      if(item.data.url.includes('app.homtick.com'))
                                          window.location.href = item.data.url
                                      else
                                        window.open(item.data.url);
                                  }

                                }} />
                            </Card> :
                            (item.componentType == 'AccDocument' ?
                            <Incoms cases={item.data}
                              // type={item.data[0].priceType}

                              // amount={item.data[0].amount}
                              //   subject={item.data[0].subject}
                              clickMore={() => {
                                props.history.push({
                                  pathname: '/fund',
                                  // state: {
                                  //   id: item.data[0].id
                                  // }
                                })
                              }}
                            // creator={item.data[0].creator.name} createDt={item.data[0].dateCreated}
                            // profileImage={item.data[0].creator.profileImageUrl} 

                            />
                            //  :(item.componentType == 'BillRequestDocument' ?
                            // <Incoms cases={item.data}
                            //   clickMore={() => {
                            //     props.history.push({
                            //       pathname: '/fund',
                                  
                            //     })
                            //   }}
                            

                            // />
                             :

                              (item.componentType == "Events" ?
                                <EventCard key={item.data.id} startDate={item.startDate}
                                  startTime={item.data.startTime}
                                  title={item.data.title}
                                  description={item.data.description}
                                  endTime={item.data.endTime}
                                  creator={item.data.creator.name}
                                  profileImage={item.data.creator.profileImageUrl}
                                  click={() => {
                                    props.history.push({
                                      pathname: '/event',
                                      state: {
                                        id: item.data.id
                                      }
                                    })
                                  }} />
                                :
                                (item.componentType == "Question" ?
                                  <QuestionCard
                                    text={item.data.text}
                                    img={item.data.file ? item.data.file.path : null}
                                    options={item.data.options}
                                    setAnswer={(id) => {
                                      setAnswer(item.data.key, id, item.data.title, item.data.metaData)


                                    }}
                                  // key={item.data.key}

                                  /> :
                                  (item.componentType == "Energy" && item.data.items.length > 0 ?


                                    <div className={'d-flex align-content-stretch flex-wrap w-100  justify-content-between align-items-baseline '} onClick={() => {
                                        props.history.push({
                                            pathname: '/bills',

                                        })
                                    }}>
                                      {(item.data.items.map((item1, index) => {
                                        return (<Energy key={index}
                                          width={item.data.items.length == 1 ? '100%' : item.data.items.length == 2 ? '48%' : item.data.items.length == 3 ? '32%' : item.data.items.length == 4 ? '24%' : '0%'}
                                          img={item1.energyAmountState == 0 ? equal : item1.energyAmountState == 1 ? increas : decreas}
                                          title={item1.name}
                                        // amount={'128KW'}

                                        />)
                                      })
                                      )}
                                    </div>

                                    :
                                    item.componentType == "ActiveOrders" ?
                                    <ActiveOrders
                                      orders={item.data.data}
                                    />:
                                    item.componentType == "OrdersNeedToRating" ?
                                            <NeedRateOrder
                                                orders={item.data}
                                                setError={setErr}
                                                getDashbordData={getDashbordData}
                                            />:
                                    item.componentType == "LoginOrders" ?
                                            <OpenOrder
                                                orders={item.data.data}
                                                setError={setErr}
                                                getDashbordData={getDashbordData}
                                            />:
                                    item.componentType == "OrderPass" ?
                                                <OrderPass
                                                    orders={item.data.data}
                                                    setError={setErr}
                                                    getDashbordData={getDashbordData}
                                                />:
                                    null
                                  )



                                  // <EmptyList des={'تاکنون اطلاعاتی ثبت نشده‌است'} />


                                ))))))
                                
                    } 

                  </div>
                )
              })
              :

              null}
             
              {/* <button onClick={()=>history.push('/service/address')}>
              خدمات
              </button> 
              <button className='mx-3' onClick={()=>history.push('/service/provider')}>
              خدمات دهنده
              </button>  */}
          </div>

          {/* <div className={`d-flex justify-content-between`}>
          <InfoCard title={'مصرف برق ماه'} description={'125 KW'} img={lamp} />
          <InfoCard title={'بارانی'} description={'17'} degree={'سانتیگراد'} img={env} />
        </div> */}



        </div>
      </Layout>
      : null

  )
}
export default withRouter(Home);

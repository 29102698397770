
import React, { Component } from 'react';
import CpInput from '../../../Components/Input/Input';
import Layout from '../../../Components/Layout/Layout';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import { Checkbox } from '../../../Components/CheckBox/CheckBox';
import BuildingType from '../BuildingType/BuildingType';
import axioss from '../../../axios';

import Toast from '../../../Components/Toast/Toast';
import { withRouter } from 'react-router-dom';
import UploadImg from '../../../Components/UploadImg/UploadImg';
import { attachFile } from '../../../value';
import Modal from '../../../Components/Modal/Modal';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import loadingGif from '../../../Assets/gifs/22.gif'



const buildingType = [
  {
    id: 0,
    title: 'مسکونی'
  },
  {
    id: 1,
    title: 'تجاری'
  },
  {
    id: 2,
    title: 'اداری'
  },
  {
    id: 3,
    title: 'سایر'
  }];
let Self = null;
class AddBuilding extends Component {
  constructor(props) {
    super(props);
    Self = this;
    this.state = {
      file: null,
      wdth: '50%',
      loading: true,

      calculationMethods: [],
      unitUtilities: [],
      buildingUtilities: [],

      showErr: false,
      errText: '',
      errType: '',

      imgSrc: [],
      uploading: false,

      activeIndex: (this.props.location.state && this.props.location.state.objInfo1) ? parseInt(this.props.location.state.objInfo1.buildingType) : 0,
      showModal: false


    };
    if (this.props.location.state) {
      this.BuildingInfo =
      {
        address: this.props.location.state.objInfo1.address != '' ? this.props.location.state.objInfo1.address : '',
        imageId: 25,
        imagePath: this.props.location.state.objInfo1.imagePath ? this.props.location.state.objInfo1.imagePath : null,
        buildingType: this.props.location.state.objInfo1.buildingType != 0 ? this.props.location.state.objInfo1.buildingType : null,
        floorsNumber: this.props.location.state.objInfo1.floorsNumber != 0 ? this.props.location.state.objInfo1.floorsNumber : null,
        //parkingNumber: this.props.location.state.objInfo1.parkingNumber != 0 ? this.props.location.state.objInfo1.parkingNumber : null,
        unitsCount: this.props.location.state.objInfo1.unitsCount != 0 ? this.props.location.state.objInfo1.unitsCount : null,
        plate: this.props.location.state.objInfo1.plate != 0 ? this.props.location.state.objInfo1.plate : null,
        cityName: this.props.location.state.objInfo1.cityName != '' ? this.props.location.state.objInfo1.cityName : '',
        provinceName: this.props.location.state.objInfo1.provinceName != '' ? this.props.location.state.objInfo1.provinceName : '',
        cityId: this.props.location.state.objInfo1.cityId > 0 ? this.props.location.state.objInfo1.cityId : null,
        provinceId: this.props.location.state.objInfo1.provinceId > 0 ? this.props.location.state.objInfo1.provinceId : null,
        latitude: this.props.location.state.objInfo1.latitude > 0 ? this.props.location.state.objInfo1.latitude : null,
        longitude: this.props.location.state.objInfo1.latitude > 0 ? this.props.location.state.objInfo1.longitude : null,
        cashDesks: {
          list: [
            {
              initialBalance: this.props.location.state.objInfo1.cashDesks.list[0].initialBalance ? this.props.location.state.objInfo1.cashDesks.list[0].initialBalance : 0,
              shabaNumber: '',
              ownerName: '',
            }
          ]
        },
        calculationMethods: {
          list: []
        },
        utilities: {
          list: this.props.location.state.objInfo1.utilities.list.length > 0 ? this.props.location.state.objInfo1.utilities.list : []
        },
        id: 0,
        name: this.props.location.state.objInfo1.name != '' ? this.props.location.state.objInfo1.name : ''
      }


    } else {
      this.BuildingInfo =
      {
        address: '',
        // imagePath: '',
        imageId: null,
        buildingType: null,
        floorsNumber: null,
        //  parkingNumber:  null,
        unitsCount: null,
        cityName: '',
        provinceName: '',
        cityId: null,
        provinceId: null,
        plate: '',
        latitude: null,
        longitude: null,
        cashDesks: {
          list: [
            {
              initialBalance: null,
              shabaNumber: '',
              ownerName: '',
            }
          ]
        },
        calculationMethods: {
          list: []
        },
        utilities: {
          list: []
        },
        id: 0,
        name: ''
      }
    }
  }




  componentDidMount() {
    this.GetBuildingFormData();
  }


  selectTypeOfBuilding(id) {
    this.setState({
      activeIndex: id
    })
  }





  GetBuildingFormData() {
    axioss({
      method: 'Get',
      url: '/Building/GetBuildingFormData'
    })
      .then(res => {
        if (res.data.result) {
          this.setState({
            calculationMethods: res.data.data.calculationMethods,
            unitUtilities: res.data.data.unitUtilities,
            buildingUtilities: res.data.data.buildingUtilities,
            loading: false
          })

        } else {
          console.log('jhjhj');

        }
      })
      .catch(err => {
        console.log(err);
      })
  }
  clearErr() {
    this.setState({
      errText: '',
      errType: ''
    });
  }

  addUtilities(item) {
    this.BuildingInfo.utilities.list.push(item);
  }
  removeUtilities(item) {
    this.BuildingInfo.utilities.list = this.BuildingInfo.utilities.list.filter(item1 => item1.id !== item.id);
  }

  insertBuilding() {
    const Title = Self.Title.getValue();
    const floorNum = Self.floorNum.getValue();
    const unitNum = Self.unitNum.getValue();
    const initialBalance = Self.Amount.getValue();



    if (Title.length < 1) {
      this.setState({
        errText: 'عنوان ساختمان را وارد کنید',
        errType: 'war'
      });
      setTimeout(() => {
        this.clearErr();
      }, 3000);

      return;
    }
    if (Self.floorNum && floorNum.length == 0) {
      this.setState({
        errText: 'تعداد طبقات ساختمان را وارد کنید',
        errType: 'war'
      });
      setTimeout(() => {
        this.clearErr();
      }, 3000);
      return;
    }

    if (Self.unitNum && unitNum.length == 0) {
      this.setState({
        errText: 'تعداد طبقات ساختمان را وارد کنید',
        errType: 'war'
      });
      setTimeout(() => {
        this.clearErr();
      }, 3000);
      return;
    }
    this.setState({
      showModal: true
    })
    this.BuildingInfo.buildingType = this.state.activeIndex;
    this.BuildingInfo.name = Title;
    this.BuildingInfo.floorsNumber = parseInt(floorNum);
    this.BuildingInfo.unitsCount = parseInt(unitNum);
    if (!initialBalance) {
      this.BuildingInfo.cashDesks.list[0].initialBalance = 0;
    } else {
      this.BuildingInfo.cashDesks.list[0].initialBalance = parseInt(initialBalance);

    }
    console.log(this.BuildingInfo);
    // this.props.history.push({
    //   pathname: '/addBuilding-1',
    //   state: {
    //     objInfo1: this.BuildingInfo,
    //     Methods: this.state.calculationMethods
    //   }
    // });
  }

  uploadFile(files) {
    this.setState({
      uploading: true
    })
    attachFile(files).then(res => {
      console.log('>>>>>>>>>>>>>>>>', res);
      this.BuildingInfo.imageId = res[0].id;
      this.BuildingInfo.imagePath = res[0].path;
      let arrayOfImg = [...this.state.imgSrc];
      arrayOfImg.push(URL.createObjectURL(files));
      this.setState({
        imgSrc: arrayOfImg,
        uploading: false
      })
    })
      .catch(err => {

      })

  }
  nextStep() {
    console.log('!!!!!!!!!!!!!!!!!!!!!!!!', this.BuildingInfo);
    this.props.history.push({
      pathname: '/building/add-1',
      state: {
        objInfo1: this.BuildingInfo,
        Methods: this.state.calculationMethods
      }
    });
  }
  render() {
    return (
      <Layout loaded={true} title={'افزودن ساختمان'}
      customBack={true}
      path={'/building'}
        isHome={false} btn={!this.state.uploading}
        btnName={'مرحله بعد'} gradian
        click={() => { this.insertBuilding() }}
        loading={this.state.loading}>
        <Modal show={this.state.showModal} btn={true} click={() => {
          this.setState({
            showModal: false
          })
        }}>
          <div className={`rtl text-right mediumF`}>
            تعداد طبقات و واحدها در آینده قابل ویرایش نمی‌باشد.
          </div>



          <div className={`w-75 mx-auto`}>
            <SBotton title={'مرحله بعد '} gradian click={() => {
              this.nextStep();
            }} />
          </div>

        </Modal>
        <Toast
          text={this.state.errText}
          type={this.state.errType}
        />
        <ProgressBar wdth={this.state.wdth} />
        <div className={'d-flex flex-row-reverse justify-content-between p-2 align-items-center'} >
          {buildingType.map(item => {
            return (
              <BuildingType key={item.id} title={item.title}
                active={item.id == this.state.activeIndex}
                click={() => { this.selectTypeOfBuilding(item.id) }} />
            )
          })

          }

        </div>
        <div style={{ height: 'inherit',}}>
        <div className={`m-3 pt-1 `} style={{ height: `calc(100% - 10rem)`, overflow: 'auto' }}>
          <CpInput plachodler={'نام*'} type={"text"} maxLength={20} value={this.BuildingInfo.name} ref={e => this.Title = e} />
          <CpInput plachodler={'طبقات*'} type={"number"} maxLength={2} value={this.BuildingInfo.floorsNumber} ref={e => this.floorNum = e} />

          <CpInput plachodler={'تعداد واحد*'} maxLength={4} type={"number"} value={this.BuildingInfo.unitsCount} ref={e => this.unitNum = e} />
          <CpInput plachodler={' موجودی صندوق*'} type={"money"} perfix={"ریال"} value={this.BuildingInfo.cashDesks.list[0].initialBalance} ref={e => this.Amount = e} />
          <div className={`w-100 rtl`}>
            {this.state.buildingUtilities.map(item => {
              return (
                <Checkbox key={item.id}
                  onChange={(check) => {
                    if (check) {
                      this.addUtilities(item);
                    } else {
                      this.removeUtilities(item)
                    }
                  }}
                  checked={this.BuildingInfo.utilities.list.find(elem => {
                    return elem.id === item.id
                  })}
                  textStle={{ fontSize: '12px', color: '#9e9e9e' }}
                  Label={item.text} />
              )
            })}

            <div>
              {this.state.imgSrc.length < 1 && !this.BuildingInfo.imagePath  ?
                <div>
                  <div className={`mt-3`} style={{ fontSize: 12, direction: 'rtl', textAlign: 'right' }}>انتخاب عکس ساختمان</div>
                  {!this.state.uploading ?
                      <UploadImg onChange={(e) => {
                        console.log(e.target.files);
                        if (e.target.files[0]) {
                          this.uploadFile(e.target.files[0])
                        }
                      }}>
                      </UploadImg>
                    :
                    <div className={`w-100 text-center`}><img style={{ width: '10%' ,height:'100%'}} src={loadingGif} /></div>}
                </div>
                :
                <div className={`text-right pb-3 rtl d-flex align-items-center`}>
                  <div className={` `} style={{
                                width: 110, height: 80,
                                backgroundImage: "url(" +this.BuildingInfo.imagePath+ ")",
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            </div>

                            <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} onClick={() => {
                                this.setState({
                                    imgPath: '',
                                    imgSrc:[]
                                });
                                this.BuildingInfo.imagePath=null
                            }}> حذف</span>
                </div>
              }
            </div>
          </div>
        </div>
        </div>
      </Layout>

    );
  }

};

export default withRouter(AddBuilding);
import React from "react"
import { serviceAxios } from "../../axios"
import Layout from "../../Components/Layout/Layout"
import ProviderInfo from "../../Components/ProviderInfo/ProviderInfo"
import ServiceList from "../../Components/ServiceList/ServiceList"
import ServiceProviderOrders from "../../Components/ServiceProviderOrders/ServiceProviderOrders"
import TabBar from "../../Components/TabBar/TabBar"


const tabItems = [
    {
        id: 1,
        title: 'مشخصات'
    },
    {
        id: 2,
        title: 'سفارشات'
    },
    {
        id: 3,
        title: 'خدمت ها'
    }
]

const SeviceProviderInfo = ({})=>{
    const [info , setInfo] = React.useState(null)
    const [services , setServices] = React.useState(null)
    const [loading , setLoading] = React.useState(true)
    const [activeTab, setActiveTab] = React.useState(2);

    React.useEffect(()=>{
        serviceAxios({
            method : "get",
            url : "/Provider/GetInfo"
        }).then(res=>{
            console.log(info)
            setInfo(res.data.data)
            setLoading(false)
        })
    },[])

    React.useEffect(()=>{
        serviceAxios({
            method : "get",
            url : "/ProviderServices/GetList"
        }).then(res=>{
            console.log(info)
            setServices(res.data.data)
            setLoading(false)
        })
    },[])

    const onTabPressed = (tabId)=>{
        setActiveTab(tabId)
    }

    return(
        <Layout
            title={"اطلاعات خدمات دهنده"}
            loaded={true}
            loading={loading}
            showMenu={true}
            displayMenu={true}
        > 
            <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`}>
                {
                    tabItems.map(item => {
                        return (
                            <TabBar 
                                title={item.title} 
                                key={item.id} 
                                id={item.id} 
                                activeIndex={activeTab} 
                                click={()=>onTabPressed(item.id)} 
                            />
                        )
                    })
                }
            </div>

            <div className={`d-flex flex-column align-items-center mb-1 mx-1 h-100`}>
                {
                    activeTab === 1?
                    <>
                        {
                            !!info && 
                                <ProviderInfo
                                    info={info}
                                />

                        }
                    </>:
                    activeTab===2?
                    <ServiceProviderOrders
                        setLoading={setLoading}
                    />:
                    <ServiceList
                        services={services}
                    />
                }
            </div>

          
        </Layout>
    )
}

export default SeviceProviderInfo
import classes from "./AddressRow.module.css"
import deleteIcon from "../../Assets/myAsset/delete.png"

const AddressRow = ({address , onDelete , onClick})=>{
    return(
        <div 
            className={`${classes.container}`}
            onClick={onClick}
        >
            {
                address.allowDelete &&
                <div className={`${classes.deleteWrapper}`} onClick={(e)=>onDelete(e , address.id)}>
                    <img
                        className={`${classes.img}`}
                        src={deleteIcon}
                    />
                </div>
            }
            <div className={`${classes.addressWrapper}`}>
                <div className="d-flex flex-row-reverse">
                    <span>
                        {address.name}
                    </span>
                </div>
                <div className="d-flex flex-row rtl mt-2">
                    <span className="text-right">
                        {address.addressString}
                    </span>
                </div>
                <div className="d-flex flex-row-reverse justify-content-between my-2">
                    <span>
                        {address.plate} : پلاک
                    </span>
                    <span>
                        واحد : {address.unitNumber}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default AddressRow
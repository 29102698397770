import React, { useState } from 'react';
import classes from './UploadingImg.module.css';
import add from '../../Assets/myAsset/add.svg'



const UploadImg = (props) => {
    return (<><div>

        <input name={'img'} type="file" id="img" hidden onChange={props.onChange}  accept=" image/jpeg, image/png"/>
        <label className={`${classes.lbl} mt-2 mb-2`} htmlFor="img">
            <img style={{ width: '30%', margin: '0 auto',height:'100%' }} src={add} />
        </label> </div>
        <div className={`d-flex align-content-stretch flex-wrap w-100  justify-content-end align-items-baseline mb-2 `}>
          {props.children}
        </div>


    </>)
}

export default UploadImg;



/*
const [src, setSrc] = useState([])

    const arrayOfImg = [];
    const onChangeHandler = (e) => {
        if(e.target.files[0])
        {let arrayOfImg = [...src];
        arrayOfImg.push(URL.createObjectURL(e.target.files[0]))
        setSrc(arrayOfImg);}
    }
    const removeImg = (item) => {
        console.log('=====',item);
        let arrayOfImg2 = [...src];
        var index = arrayOfImg2.indexOf(item)
        if (index !== -1) {
            arrayOfImg2.splice(index, 1);
            setSrc(arrayOfImg2);
        }
        console.log(">>>>",src);

    }
    return (<><div>

        <input multiple type="file" id="upload" onChange={
             (event) => { props.onChange(event); onChangeHandler(event) }
            }  hidden />
        <label className={`${classes.lbl} mt-2 mb-2`} htmlFor="upload">
            <img style={{ width: '30%', margin: '0 auto' }} src={add} />
        </label> </div>
        <div className={`d-flex align-content-stretch flex-wrap w-100  justify-content-end align-items-baseline mb-2 `}>
        {src.map(item => {

            return (
                <div key={item} className={`mr-1`}>
                    <img src={item} style={{ width: 80 }} onClick={(item)=>props.click} />
                    <span onClick={() => {props.remove(item); removeImg(item) }} className={classes.badg}></span>
                </div>)
        })}
        </div>


    </>)
}


*/
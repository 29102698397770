import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Layout from '../../Components/Layout/Layout';
import RadioImage from '../../Components/RadioImage/RadioImage';
import Toast from '../../Components/Toast/Toast';
import { serviceAxios } from "../../axios";
import { useOrderContext } from '../../context/orderContext';
import EmptyList from '../../Components/EmptyList/EmptyList';

const ServicesGroup = ()=>{
    const [loading , setLoading] = React.useState(true)
    const [serviceGroups , setServiceGroups] = React.useState([])
    const [error , setError] = React.useState({errText : "" , errType : "war"})
    const {orderState , orderDispatch} = useOrderContext()
    const history = useHistory()

    React.useEffect(()=>{
        serviceAxios.post(
            `Service/Groups/${orderState.addressId}`
        ).then(res=>{
            console.log(res)
            setServiceGroups([...res.data.data])
            setLoading(false)
        }).catch(err =>{
            console.error(err)
        })
    },[])

    const onGroupSelect = (groupId)=>{
        orderDispatch({type : 'SET_ORDER' , data : {groupId : groupId}})
        history.push(`/service/GroupServices`)
    }

    return (
        <Layout 
            loaded={true} 
            title={'گروه خدماتی'}
            isHome={false}  
            loading={loading}
            showMenu={true}
            displayMenu={true}
        >
            <Toast
                text={error.errText}
                type={error.errType}
            />
            <div className='container mt-3 h-100'>
                <div className='row justify-content-around align-content-start overflow-auto' style={{height : 'calc(100vh - 55px)'}}>
                    {
                        serviceGroups.length > 0 ?
                        serviceGroups.map((item)=>(

                            <RadioImage
                                service={item}
                                onClick={()=>onGroupSelect(item.id)}
                            />
                        )):
                        <EmptyList des="در آدرس شما خدمات دهنده ای یافت نشد"/>
                    }
                </div>
            </div>
            
        </Layout>

    )
}
export default ServicesGroup;
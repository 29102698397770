
import React from 'react';
import Calender from '../Cal/Calender';
import Modal from '../Modal/Modal';

const DatePicker = props => {
    return (

        <div>
            <Modal show={props.showPicker} click={props.disMiss} zIndex={'1002'} >
                <Calender click={props.click} DatePicker={true} checkActive={props.checkActive} activeDates={props.activeDates || []}/>
            </Modal>

        </div>

    )
}
export default DatePicker;
import { data } from "jquery"
import moment from "moment-jalaali"
import React from "react"
import { useHistory } from "react-router-dom"
import { serviceAxios } from "../../axios"
import SBotton from "../../Components/Buttons/SBotton/SBotton"
import DatePicker from "../../Components/DatePicker/DatePicker"
import EmptyList from "../../Components/EmptyList/EmptyList"
import Layout from "../../Components/Layout/Layout"
import PeriodListClient from "../../Components/PeriodListClient/PeriodListClient"
import { useOrderContext } from "../../context/orderContext"


const ServicePeriod = ({})=>{
    const [loading , setLoading] = React.useState(true)
    const [showDatePicker , setShowDatePicker] = React.useState(false)
    const [periods , setPeriods] = React.useState([])
    const [activeDates , setActiveDates] = React.useState([])
    const {orderState , orderDispatch} = useOrderContext()
    const history = useHistory()

    React.useEffect(()=>{
        console.log(orderState)
        const params = {
            "recomededDateTime": orderState.recomededDateTime,
            "recomededDateTimeString": orderState.recomededDateTimeString,
            "addressId": orderState.addressId,
            "providerServiceId": orderState.providerServiceId,
            "periods": [],
            "userId": "",
            "title": "",
            "providerId": orderState.providerId,
            "serviceId": orderState.serviceId
        }

        serviceAxios.post(
            `Order/GetPreOrderData`,
            params
        ).then(res=>{
            console.log(res.data)
            setDate(res.data.data.recomededDateTimeString)
            setLoading(false)
            setPeriods(res.data.data.periods)
            setActiveDates(res.data.data.activeDates)
        }).catch(err =>{
            console.error(err)
            setLoading(false)
        })
    },[orderState.recomededDateTime])

    const setDate = (date)=>{
        setShowDatePicker(false)
        orderDispatch({
            type : "SET_ORDER" , 
            data : {
                recomededDateTime : moment(date, 'jYYYY/jMM/jDD').format("YYYY-MM-DD"),
                recomededDateTimeString : date
            }})
    }

    const periodSelected = (period)=>{
        orderDispatch({type:"SET_ORDER" , data : {periods : [{...period , isSelected : true , requestedCount : 1}]}})
        history.push("/service/orderConfirm")
    }

    console.log(activeDates)
    return(
        <Layout
            title={`انتخاب سانس\n${orderState.title} - ${orderState.providerName}`}
            loaded={true}
            loading={loading}
            showMenu={true}
            displayMenu={true}
        >
            <div className='container w-100'>
                <div className="d-flex flex-column w-100 mt-3 align-items-center">
                    <div className="w-50">
                        <SBotton
                            title={`تاریخ : ${orderState.recomededDateTimeString}`}
                            click={()=>setShowDatePicker(true)}
                            gradian
                        />
                    </div>
                </div>
                {
                    (periods.length > 0 && !loading) ? 
                    <div className='row justify-content-around'>
                        <PeriodListClient
                            periods={periods}
                            onClick={(period)=>periodSelected(period)}
                        />
                    </div>:
                    <div>
                        <EmptyList
                            des={`در تاریخ ${orderState.recomededDateTimeString} سانسی یافت نشد`}
                        />
                    </div>
                }
               
            </div>
            
            <DatePicker 
                showPicker={showDatePicker} 
                disMiss={() => {
                    setShowDatePicker(false)
                }} 
                click={setDate}
                checkActive={true}
                activeDates={activeDates}
            /> 
        </Layout>
    )
}

export default ServicePeriod
import React from "react";

const ServiceBanner = ({banner})=>{
    const [ height , setHeight] = React.useState(120)

    React.useEffect(()=>{
        setHeight((document.getElementById("baseBx").offsetWidth) * 0.4)
    },[])

    return(
        <div className="w-100">
            <img
                src={banner}
                style={{width : "100%" , height }}
            />
        </div>
    )
}

export default ServiceBanner
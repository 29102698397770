import React from 'react';
import { Cal } from '../Calendar/MyCalendar';
const Calend = props => {
    return(
        <div>
            <Cal/>
        </div>
    )
}
export default Calend

import React from "react"
import { serviceAxios } from "../../axios"
import SBotton from "../Buttons/SBotton/SBotton"
import DatePicker from "../DatePicker/DatePicker"
import CpInput from "../Input/Input"
import TimePicker from "../TimePicker/TimePicker"
import Toast from "../Toast/Toast"
import {useParams} from "react-router-dom"
import moment from "moment-jalaali"
import { useServiceContext } from "../../context/serviceContext"
import Modal from "../Modal/Modal"

const initialState = {
    "id": 0,
    "title": "",
    "description": "",
    "extraPrice": 0,
    "capacity": "",
    "startTime": "",
    "endTime": "",
    "startDateTime": "",
    "endDateTime": "",
    "dayOfWeeks": []
}

const days = [
    {id: 6 , title: "شنبه"},
    {id: 0 , title: "یکشنبه"},
    {id: 1 , title: "دوشنبه"},
    {id: 2 , title: "سه شنبه"},
    {id: 3 , title: "چهارشنبه"},
    {id: 4 , title: "پنج شنبه"},
    {id: 5 , title: "جمعه"}
]

const reducer = (state , action)=>{
    switch(action.type){
        case "EDIT_TITLE":
            return{...state , title : action.data}
        
        case "EDIT_DESC":
            return{...state , description : action.data}
                
        case "EDIT_START_DATE":
            return{...state , startDateTime : action.data}
                
        case "EDIT_END_DATE":
            return{...state , endDateTime : action.data}
        
        case "EDIT_START_TIME":
            return{...state , startTime : action.data}

        case "EDIT_END_TIME":
            return{...state , endTime : action.data}

        case "EDIT_CAPACITY":
            return{...state , capacity : action.data}

        case "EDIT_DAYS":
            return{...state , dayOfWeeks : action.data}

        case "EDIT_PRICE":
                return{...state , price : action.data}

        default : 
            return {...state}
    }
}

const Multi = ()=>{
    const [period , setPeriod] = React.useReducer(reducer , initialState)
    const [loading , setLoading] = React.useState(false)
    const [showDatePicker , setShowDatePicker] = React.useState(false)
    const [showSuccessModal , setShowSuccessModal] = React.useState(false)
    const [error , setError] = React.useState({errTxt : "" , errType : ""})
    const [timePicker , setTimePicker] = React.useState({
        show : false,
        label : "",
        onSetTime : ()=>false
    })
    const {serviceId} = useParams()
    const [service] = useServiceContext()

    let dateType = React.useRef("")

    React.useEffect(()=>{
        setPeriod({
            type : "EDIT_PRICE" ,
            data : service.price
        })
    },[])

    const setTitle = (e)=>{
        setPeriod({type : "EDIT_TITLE" , data : e.target.value})
    }

    const setDesc = (e)=>{
        setPeriod({type : "EDIT_DESC" , data : e.target.value})
    }

    const setStartTime = (time)=>{
        setTimePicker({show : false})
        setPeriod({type : "EDIT_START_TIME" , data : time})
        console.log(time)
    }

    const setEndTime = (time)=>{
        setTimePicker({show : false})
        setPeriod({type : "EDIT_END_TIME" , data : time})
        console.log(time)
    }

    const setDate = (date)=>{
        setShowDatePicker(false)
        if(dateType.current === "startDate"){
            setPeriod({type : "EDIT_START_DATE" , data : date})
        }else{
            setPeriod({type : "EDIT_END_DATE" , data : date})
        }
    }

    const setPrice = (e)=>{
        setPeriod({type : "EDIT_PRICE" , data : e.target.value})
    }

    const setCapacity = (e)=>{
        setPeriod({type : "EDIT_CAPACITY" , data : e.target.value})
    }
    
    const dayClicked = (day)=>{
        const d = [...period.dayOfWeeks]
        if(period.dayOfWeeks.includes(day.id)){
            d.splice(d.indexOf(day.id) , 1)
        }else{
            d.push(day.id)
        }
        
        setPeriod({type : "EDIT_DAYS" , data :d})
    }

    const register = ()=>{
        if(period.title !== "" && period.description !=="" && period.startDateTime !== "" && period.endDateTime !== "" && 
            period.startTime !== "" && period.endTime !== "" && period.capacity !== ""){
            if(period.endTime > period.startTime){
                if(/^[0-9]*$/.test(period.capacity)){
                    setLoading(true)
                    serviceAxios({
                        method: 'Post',
                        url: `Period/AddCycle/${serviceId}`,
                        data: {
                            ...period,
                            capacity : parseInt(period.capacity),
                            startDateTime : moment(period.startDateTime , 'jYYYY/jMM/jDD').format("YYYY-MM-DD"),
                            endDateTime : moment(period.endDateTime , 'jYYYY/jMM/jDD').format("YYYY-MM-DD"),
                            extraPrice : parseInt(period.price) - parseInt(service.price)                            
                        }
                    })
                    .then(res => {
                        console.log(res);
                        setLoading(false)
                        if(res.data.result) {
                            errorHandler("سانس با موفقیت ثبت شد", "suc")
                            setShowSuccessModal(true)
                        }
                        else
                            errorHandler(res.data.message, "err")
                    })
                    .catch(err => {
                        console.log(err.response);
                        setLoading(false)
                    })
                }else{
                    errorHandler("ظرفیت را وارد کنید" , "err")
                }
            }
            else{
                errorHandler("زمان پایان باید بزرگتر از زمان شروع باشد")
            }
        }
        else{
            errorHandler("تمام فیلدها را پر کنید")
        }
    }

    const errorHandler = (text , type="war")=>{
        setError({
            errType : type,
            errTxt : text
        })
        setTimeout(()=>{
            setError({
                errType : "",
                errTxt : ""
            })
        },3000)
    }

    return(
        <div className="d-flex w-100 flex-column overflow-auto">
            <CpInput 
                plachodler={'عنوان'}
                disabled={false}
                wdth={"95%"}
                value={period.title} 
                onChange={setTitle}
            />
            <CpInput 
                plachodler={'توضیحات'}
                disabled={false}
                wdth={"95%"}
                value={period.description} 
                onChange={setDesc}
            />
            <div
                onClick={()=>{dateType.current = "startDate"; setShowDatePicker(true)}}
            >
                <CpInput 
                    plachodler={'تاریخ شروع'}
                    disabled={false}
                    wdth={"95%"}
                    value={period.startDateTime} 
                />
            </div>
            <div
                onClick={()=>{dateType.current = "endDate"; setShowDatePicker(true)}}
            >
                <CpInput 
                    plachodler={'تاریخ پایان'}
                    disabled={false}
                    wdth={"95%"}
                    value={period.endDateTime} 
                />
            </div>
            <div
                onClick={()=> setTimePicker({show : true , onSetTime : setStartTime , label : "ساعت شروع"})}
            >
                <CpInput 
                    plachodler={'ساعت شروع'}
                    disabled={false}
                    wdth={"95%"}
                    value={period.startTime} 
                />
            </div>
            <div
                onClick={()=> setTimePicker({show : true , onSetTime : setEndTime , label : "ساعت پایان"})}
            >
                <CpInput 
                    plachodler={'ساعت پایان'}
                    disabled={false}
                    wdth={"95%"}
                    value={period.endTime} 
                />
            </div>
            <CpInput 
                plachodler={'ظرفیت'}
                disabled={false}
                wdth={"95%"}
                onChange={setCapacity}
                value={period.capacity} 
            />
            {
                service.hasPrice &&
                <CpInput 
                    plachodler={'مبلغ (ریال)'}
                    disabled={false}
                    wdth={"95%"}
                    onChange={setPrice}
                    value={period.price} 
                />
            }
            <div className="d-flex rtl my-3" style={{width : "95%"}}>
                <h6>
                    روزهای تکرار
                </h6>
            </div>
            <div className="d-flex flex-row flex-wrap justify-content-around rtl" style={{overflow : "hidden" , width : "95%"}}>
                {
                    days.map(day=>(
                        <div
                            className="card m-2"
                            style={{
                                minWidth : "100px" ,
                                height : 40  ,
                                backgroundColor : period.dayOfWeeks.includes(day.id) ? "rgb(255, 87, 34)" : "#fff" ,
                                color : period.dayOfWeeks.includes(day.id) ? "#fff" : "#000"
                            }}
                            onClick={()=>dayClicked(day)}
                        >
                            <div className="text-center pt-2">
                                <p>
                                    {
                                        day.title
                                    }
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div style={{width : "95%"}}>
                <SBotton
                    title="ثبت"
                    click={register}
                    sending={loading}
                    gradian
                />
            </div>
            <DatePicker 
                showPicker={showDatePicker} 
                disMiss={() => {
                    setShowDatePicker(false)
                }} 
                click={setDate}
            /> 
            <TimePicker
                label={timePicker.label}
                onSetTime={timePicker.onSetTime}
                show={timePicker.show}
                onClose={()=>setTimePicker({show : false})}
            />
            <Toast
                text={error.errTxt}
                type={error.errType}
            />

            <Modal
                show={showSuccessModal}
                click={()=>setShowSuccessModal(false)}
            >
                <div className="d-flex flex-column align-items-center">
                    <div className="text-center m-3">
                        سانس با موفقیت ثبت شد
                    </div>
                    <div className="w-50 align-self-center">
                        <SBotton
                            title="تایید"
                            click={()=>setShowSuccessModal(false)}
                            sending={loading}
                            gradian
                        />
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default Multi
import React from 'react';
import classes from './SInput.module.css';
import iranflag from '../../Assets/myAsset/rr.jpg'

const SInput = props => {
    const  maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
         object.target.value = object.target.value.slice(0, object.target.maxLength)
          }
        }

        return(
            props.ttype != 2 ? 
            
            
            <div className={`w-100 text-center d-flex align-items-center  text-dark p-2`} style={{ backgroundColor: 'white', borderRadius: 8,border: props.error ? '2px solid black': 0 }}>
            <div style={{display: props.prefix ? 'block' : 'none'}} >
                <img className={`h-100`} src={iranflag} style={{ width: '25px' }} />
            </div>
            <div className={`ml-2`} style={{display: props.prefix ? 'block' : 'none',fontSize:16, paddingTop:1, paddingBottom:1}}>+98</div>

            <div style={{width: props.prefix ? '' : '100%'}}>
                <input style={{width:'inherit',textAlign:props.prefix ? 'left' :'center' ,border:'0' }}
                   inputMode={props.type == 'number' ? 'numeric' : 'text'}
                   maxLength={props.maxLength}
                   onInput={(event) => maxLengthCheck(event)}
                   
                    className={`${classes.input}`}
                    name={props.name}
                    type={props.type}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    value={props.value}
                    onKeyUp={props.onKeyUp} />
            </div>


        </div>
            
            
            :  



            <div className={`m-2`} style={{ width: 40, height: 40, backgroundColor: 'white', borderRadius: 8}} >
            <input style={{ width: 'inherit', height: 'inherit',border: props.error ? '2px solid black': 0,borderRadius: 8  }}
                inputMode={props.type == 'number' ? 'numeric' : 'text'}
                onInput={(event) => maxLengthCheck(event)}
                name={props.name}
                type={props.type}
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
                maxLength={props.maxLength}
                className={`text-center ${classes.input}`}
                id={props.id}
                
                onKeyUp={props.onKeyUp}/>
        </div>
        )

   
};

export default SInput;








import React, { useState } from 'react';
import { colors } from '../../value';



const TabBar = props => {
   // const [tab, setTab] = useState(props.active);
   // const [renderItem, setRenderItem] = useState(props.contentOfActive);
   // const handler = (index, content) => {
   //    setTab(index);
   //    setRenderItem(content)
   // }

   return (
      <div
         onClick={props.click}
         className={` text-center p-2  position-relative rtl text-right`}
         style={{ width:props.length==1 ? '100%' : '50%'   , fontSize: props.font ? props.font : '1.8vh',
          fontWeight: (props.activeIndex == props.id||(props.parentmin<=props.id&&props.parentmax>=props.id))? 'bold' : '', color: props.activeIndex == props.id ? '' : '#757575' }}>

         {props.title}{props.badge ? `(${props.badge})`: null}
         {<div className={`w-75 mx-auto mt-2 mb-2`} style={{ height: 4, backgroundColor: (props.activeIndex == props.id||(props.parentmin<=props.id&&props.parentmax>=props.id))? `${colors.app2}` : '' }}></div>}
      </div>
  
   )
};

export default TabBar;
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import Date from '../../../Components/Date/Date';
import Doer from '../../../Components/Doer/Doer';
import Layout from '../../../Components/Layout/Layout';
import Toast from '../../../Components/Toast/Toast';
import { ApiService } from '../../../value';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const RuleView = props => {
    const [loading, setLoading] = useState(true);
    const [rule, setRule] = useState(null);
    const [ruleID, setRuleID] = useState(null);
    const [buildingID, setBuildingID] = useState(null);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');


    useEffect(() => {

        if (props.location.state && props.location.state.id && localStorage.getItem('id')) {
            setRuleID(props.location.state.id);
            setBuildingID(localStorage.getItem('id'))
            getDetailOfRule(props.location.state.id, localStorage.getItem('id'))
        } else {
            props.history.push('/rules')
        }

    }, []);

    const getDetailOfRule = (id, buildingId) => {
        ApiService('Get', `/BuildingRule/Detail/${buildingId}/${id}`)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setRule(response.data.data);
                    setLoading(false)
                    isSeen(id, buildingId)
                } else {
                    //  قانون پیدا نشد
                    props.history.push('/rules')
                    // console.log('222');
                    // setErrText(response.data.message);
                    // setErrType('war');
                    // clearErr();
                    // setTab(false);
                    // setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                props.history.push('/rules')
            })
    }
    const isSeen = (id, buildingId) => {
        ApiService('Get', `/BuildingRule/Seen/${buildingId}/${id}`)
        .then(res=>{
            console.log('seeeeeeeeeen',res);
        })

    }

    const deleteRule = (id, buildingId) => {
        setLoading(true);
        ApiService('Get', `/BuildingRule/Delete/${buildingId}/${id}`)
            .then(response => {
                if (response.data.result) {
                    setLoading(false);
                    setErrText('قانون با موفقیت حذف شد.');
                    setErrType('suc');

                    setTimeout(() => {
                        props.history.push('/rules')
                    }, 2000)


                }else{
                    setLoading(false);
                    setErrText(response.data.message);
                    setErrType('war');

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }
    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    return (
        rule ?
            <Layout loaded={true} title={rule.title} isHome={false} loading={loading} >
                <Toast
                    text={errText}
                    type={errType}
                    second={5000}
                />
                <div style={{ height: 'inherit' }}>
                    <div style={{ height: `calc(100% - 6rem)`, overflow: 'auto', }}>
                        <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 mb-3`}>
                            <Doer creator={rule.creator.name} profileImage={rule.creator.profileImageUrl} />
                            {/* <div className={`d-flex flex-row-reverse justify-content-between align-items-center w-100 mt-2 `}> */}
                            <div>
                                <Date date={rule.createdDate} font={11} />
                            </div>
                            {/* </div> */}
                        </div>


                        <CopyToClipboard text={rule.description}
                                         onCopy={() =>{ setErrText('قانون کپی شد.');
                                             setErrType('suc');}}>
                           <div className={`text-right rtl mediumF grey p-2 m-1`} style={{ lineHeight: 1.8, whiteSpace: "pre-wrap" }}>
                            {rule.description}
                        </div></CopyToClipboard>
                        {rule.filePath ? <div className={`rtl`}>
                            <div className={` `} onClick={(e) => {
                                                window.open(rule.filePath);
                                                e.stopPropagation();
                                            }} style={{
                                width: 110, height: 80,
                                backgroundImage: "url(" + rule.filePath + ")",
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            </div>
                        </div> : null}
                        <div className={`w-75 mx-auto position-absolute  `} style={{ bottom: 0, left: '12.5%' }}>
                          { rule.pBtnDelete ?  <SBotton gradian={false} title={'حذف'} click={() => {
                                deleteRule(ruleID, buildingID);
                            }} /> : null}
                        </div>
                    </div>
                </div>

            </Layout>
            : null
    )
}
export default withRouter(RuleView);
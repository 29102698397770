import React, { Component } from 'react';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import Layout from '../../../Components/Layout/Layout';
import CpInput from '../../../Components/Input/Input';
import { ApiService, attachFile } from '../../../value';
import { withRouter } from 'react-router-dom';
import minus from '../../../Assets/myAsset/minus.svg'
import { Checkbox } from '../../../Components/CheckBox/CheckBox';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import loadingGif from '../../../Assets/gifs/22.gif'
import UploadImg from '../../../Components/UploadImg/UploadImg';
import Toast from '../../../Components/Toast/Toast';

let Self = null;
class AddSurvey extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            loading: false,
            buildingId: null,
            sendingBtn: false,
            id: this.props.location.state ? this.props.location.state.survey.id : 0,

            errText: '',
            errType: '',
            showDatePicker: false,


            imgId: this.props.location.state ? this.props.location.state.survey.fileId :null,
            imgPath: this.props.location.state ? this.props.location.state.survey.filePath :null,
            uploading: false,

            optionText: {
                text: '',
                id: 0
            },


            title: this.props.location.state ? this.props.location.state.survey.title : '',
            description: this.props.location.state ? this.props.location.state.survey.description : '',
            deadline: this.props.location.state ? this.props.location.state.survey.expireDate : '',
            options: this.props.location.state ? this.props.location.state.survey.options : [],
            multipleSelect: this.props.location.state ? this.props.location.state.survey.multipleSelect : false,
            viewResultType: this.props.location.state ? this.props.location.state.survey.viewResultType : 0
        }
    }

    componentDidMount() {
        

        if (localStorage.getItem('id')) {
            this.setState({
                buildingId: localStorage.getItem('id')
            })
        } else {
            this.props.history.push('/news');
        }

    }
    addToOption(item) {
        console.log('itemmmmmmm', item);
        this.state.options.push(item);
        this.setState({
            optionText: {
                text: '',
                id: item.id
            }
        })

    }
    uploadFile(files) {
        this.setState({
            uploading: true
        })
        attachFile(files).then(res => {
            this.setState({
                imgId: res[0].id,
                imgPath: res[0].path,
                uploading: false
            })

        })
            .catch(err => {

            })

    }

    removeOption(item) {
        console.log(item);
        let newOption = this.state.options.filter(item1 => item1.text !== item.text);
        console.log(newOption);
        this.setState({
            options: newOption
        })

    }

    submitSurvey(buildingId) {
        let data = {
            id: this.state.id,
           
            title: this.state.title,
            description: this.state.description,
            expireDate: this.state.deadline,
            multipleSelect: this.state.multipleSelect,
            viewResultType: this.state.viewResultType,
            options: this.state.options
        }
        if(this.state.imgId) {
            data.fileId = this.state.imgId
        }
        if (!this.state.sendingBtn) {
            this.setState({
            loading: true,
            sendingBtn: true
        });
            ApiService('Post', `/Survey/Submit/${buildingId}`, data)
                .then(response => {
                    console.log('Submit', response);
                    if (response.data.result) {
                        this.setState({
                            loading: false,
                            sendingBtn:false,
                        });
                        this.props.history.push({
                            pathname: '/survey',
                            state: {
                                id: response.data.data.id
                            }
                        })
                    }
                    else {
                        this.setState({
                            loading: false,
                            sendingBtn:false,
                            errText: response.data.message,
                            errType: 'war'
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        errText: 'خطای داخلی سرور',
                        errType:'err'  ,
                        sendingBtn:false,
                        loading: false      
                    });
                    this.clearErr();
                    return
        
                })
        }
    }
    render() {
        return (

            <Layout loaded={true} title={ (this.props.location.state && this.props.location.state.survey)? 'ویرایش نظرسنجی':
                ' ثبت نظرسنجی'} isHome={false} showMenu={false} loading={this.state.loading} >
                   <Toast
                    text={this.state.errText}
                    type={this.state.errType}
                    second={5000}
                />
                <DatePicker showPicker={this.state.showDatePicker} disMiss={() => {
                    this.setState({
                        showDatePicker: false
                    })
                }} click={(e) => {
                    console.log(e);
                    this.setState({
                        deadline: e,
                        showDatePicker: false
                    })


                }} />

                <div style={{ height: 'inherit' }}>
                    <div className={`pt-2`} style={{ height: `calc(100% - 4rem)`, overflow: 'auto', }}>

                        <CpInput plachodler={'عنوان نظرسنجی'} maxLength={50} type={"text"} value={this.state.title} onChange={(event) => {
                            this.setState({
                                title: event.target.value
                            })
                        }} />
                        <CpInput plachodler={' توضیحات'} maxLength={250} multiline={true} value={this.state.description} style={{whiteSpace: "pre-wrap"}} type={"text"} onChange={(event) => {
                            this.setState({
                                description: event.target.value
                            })
                        }} />

                        {/* <CpInput plachodler={'تاریخ پایان نظرسنجی'} onClick={() => {
                            this.setState({
                                showDatePicker: true
                            })
                        }} type={"text"} value={this.state.deadline} onChange={(event) => {
                            this.setState({
                                deadline: event.target.value
                            })
                        }} /> */}
                         <div className={`d-flex align-items-center rtl `} onClick={() => {
                                this.setState({
                                    showDatePicker: true
                                })
                            }}
                             style={{marginBottom:15,padding:'15.5px 12px', color:'#0000008a', border:'1px solid #424242', borderRadius:4}}>
                                <div>تاریخ پایان نظرسنجی:  </div>
                                <div className={`mr-3`} style={{color:'#000000de'}} >{this.state.deadline}</div>
                                
                            </div>
                        <div className={`d-flex justify-content-between rtl`}>
                            <CpInput plachodler={'عنوان گزینه'} wdth={'60%'} type={"text"} value={this.state.optionText.text} onChange={(event) => {
                                this.setState({
                                    optionText: {
                                        text: event.target.value,
                                        id: 0
                                    },

                                })
                            }} />

                            <div style={{ width: '20%', marginLeft: '10%' }}>
                                <div style={{
                                    border: '1px solid #ff5722', color: '#ff5722',
                                    fontSize: 10, borderRadius: '15px', padding: 5,
                                    position: 'relative', top: 12, textAlign: 'center'
                                }} onClick={() => {
                                    console.log(this.state.optionText);
                                    this.addToOption(this.state.optionText)
                                }}> افزودن گزینه </div>
                                {/* <SBotton title={'ثبت گزینه'} font={10} click={() => {
                                console.log(this.state.optionText);
                                this.addToOption(this.state.optionText)
                            }} /> */}
                            </div>

                        </div>

                        <div className={`d-flex align-content-stretch flex-wrap w-100 rtl  justify-content-between align-items-baseline `}>
                            {
                                this.state.options.map(item => {
                                    return (
                                        <div key={item.text} className={`d-flex justify-content-between  rtl mt-1 `} style={{ minWidth: '45%', padding: '5px 10px', border: '1px solid #000000de', borderRadius: 4 }}>
                                            <div style={{ fontSize: 13 }}> {item.text}</div>
                                            <div onClick={() => { this.removeOption(item) }}> <img src={minus} style={{ width: 20, padding: 5, height: '100%' }} /></div>
                                        </div>
                                    )
                                })
                            }
                        </div>


                        <div className={`w-100 rtl mt-2`}>
                            <Checkbox
                                onChange={(check) => {
                                    this.setState({
                                        multipleSelect: check
                                    })
                                }}
                                checked={this.state.multipleSelect}
                                textStle={{ fontSize: '12px', color: '#9e9e9e' }}
                                Label={'انتخاب بیشتر از یک گزینه '} />
                            <Checkbox
                                onChange={(check) => {

                                    this.setState({
                                        viewResultType: check ? 1 : 0
                                    })
                                }}
                                checked={this.state.viewResultType === 1}
                                textStle={{ fontSize: '12px', color: '#9e9e9e' }}
                                Label={'نتیجه نظرسنجی نمایش داده نشود '} />
                        </div>
                        {!this.state.imgPath  ?
                            <>
                                <div className={`rtl fntBold mediumF text-right mt-2`} > عکس‌ مرتبط را اضافه کنید. </div>

                                {!this.state.uploading ?
                                    <UploadImg
                                        onChange={(e) => {
                                            if (e.target.files[0]) {
                                                this.uploadFile(e.target.files[0])
                                            }
                                        }}>
                                    </UploadImg>
                                    :
                                    <div className={`w-100 text-center `}><img style={{ width: '10%', height: '100%' }} src={loadingGif} /></div>}
                            </>
                            :
                            <div className={`text-right pb-3 rtl d-flex align-items-center`}>
                                <div className={` `} style={{
                                    width: 110, height: 80,
                                    backgroundImage: "url(" + this.state.imgPath + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                                {/* <img src={this.state.imgPath} style={{ width: 80 }} /> */}
                                <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} onClick={() => {
                                    this.setState({
                                        imgPath: ''
                                    });
                                }}> حذف</span>
                            </div>
                        }





                    </div>
                   {!this.state.uploading ? <div style={{ position: "absolute", bottom: 0, width: '50%', left: '25%' }}>
                        <SBotton sending={this.state.sendingBtn} 
                        title={this.props.location.state && this.props.location.state.survey ? 'ثبت ویرایش ' : 'ثبت نظرسنجی'} gradian={true} click={() => {
                            this.submitSurvey(this.state.buildingId)
                        }} />
                    </div> : null}
                </div>
            </Layout>

        )
    }

}
export default withRouter(AddSurvey);
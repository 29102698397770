import classes from './NavigationItem.module.css';
import React from 'react';
import { NavLink } from 'react-router-dom';
const NavigationItem = props => {
    return (
        <div className={`d-flex flex-row-reverse justify-content-start align-items-center   ${classes.NavigationItem}`}
            style={{
                colore: props.single ? '' : '#241a11',
                fontWeight: props.single ? '600' : '',
                fontSize: props.single ? '15px' : '14px'
            }}>
            <NavLink to={props.link}
                exact={props.exact}
                activeClassName={classes.active}
            >{props.children}
            </NavLink>

        </div>
    )
}
export default NavigationItem;


import React from 'react';
import user from '../../Assets/myAsset/user.png'

const Doer = props => {
    return (
        <div className={`d-flex w-100 flex-row-reverse align-items-center `}>
            <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                <img src={props.profileImage ? props.profileImage : user} className={`w-100 h-100`} alt={''} />
            </div>
            <div className={` mr-2 text-right`} style={{ fontSize: 12 }}> {props.creator}</div>
        </div>
    )
}
export default Doer;
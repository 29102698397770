import { StaticTimePicker , LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import SBotton from '../Buttons/SBotton/SBotton';
import CpInput from "../Input/Input"
import Modal from '../Modal/Modal';

const TimePicker = ({show , label , onSetTime , onClose})=>{
    const [value , setValue] = React.useState()
    
    const setTime = ()=>{
        if(!!value){
            let h = value["$H"] > 9 ? value["$H"] : "0" + value["$H"]
            let m = value["$m"] > 9 ? value["$m"] : "0" + value["$m"]
            onSetTime(`${h}:${m}`)
        }else{
            onSetTime(`${new Date().getHours()}:${new Date().getMinutes()}`)
        }
    }

    console.log(value)
    return(
        <Modal
            show={show} 
            btn={true} 
            click={onClose }
        >   
            {
                show &&
                <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticTimePicker
                            displayStaticWrapperAs="mobile"
                            label={label}
                            value={value}
                            onChange={(newValue) => {
                                console.log(newValue)
                                setValue(newValue);
                            }}
                            ampm={false}
                            onClose={()=>console.log(value)}
                            renderInput={(params) => (
                                    <CpInput 
                                        plachodler={label}
                                        disabled={false}
                                        wdth={"95%"}
                                        value={value} 
                                        {...params} 
                                    />
                                )
                            }
                        />
                    </LocalizationProvider>
                    <div className={`w-25 mx-auto`}>
                        <SBotton title={'تایید'}  click={setTime} />
                    </div>
                </>
            }
        </Modal>
    )
}

export default TimePicker
import React from 'react';
import { formatMoney } from '../../../value';
import del from '../../../Assets/myAsset/clear.svg';



const UnitList = props => {
    return (
         <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100 borderShadow p-2  `} style={{ borderRadius: 10 }}>
                <div className={`fntBold mediumF`}> {props.unitName}</div>
                <div className={`rtl w-50 d-flex align-items-center pl-2` } style={{backgroundColor: '#f7f7f7', borderRadius: 15}}>
                    <input className={`w-100 `}
                        style={{ display: props.display ? 'block': 'none', border: 0, textAlign: 'center',backgroundColor: '#f7f7f7', borderRadius: 15, direction: 'ltr' }}
                        inputMode="numeric"
                        placeholder={`${formatMoney(props.plcholder)}`}
                        type={"number"}
                       
                        onChange={props.onChange}
                        value={props.value} />
                        <span className={`pr-1 pl-2`} style={{fontSize:9,  }}>ریال</span>
                        <div onClick={props.delete}  style={{display: props.showDel ? 'block': 'none', width:'12px'}}>
                            <img style={{width:'100%',height:'100%'}} src={del}/>
                            </div>
                </div>
            </div>
        // </div>



    )

}
export default UnitList;



import React from 'react';
import { colors, formatMoney } from "../../../value";
import Date from '../../Date/Date';
import Card from '../../Card/Card';
import ReceiptState from '../ReceiptState/ReceiptState';
import tick from "../../../Assets/myAsset/check.svg";





const PayableCard = props => {
    return (
        <Card width={'100%'} onClick={props.onClick} >
            <div className={`w-100 p-2`} style={{ lineHeight: 2 }} >
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse`}>
                    <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-50`}>
                       {props.stateOfRec == 4 ? <div className={`ml-2 mb-1`} style={{width:20}}>
                            <img src={tick} className={`w-100`}/>
                        </div>: null}
                        {/* <div className={'p-1 text-light text-center fntBold'} 
         style={{width:'15px'}}>
              <img style={{width:'100%'}} src={cmplt}/>
             </div> */}
                        <div className={' fntBold ml-1 rtl text-right w-100 '} style={{ fontSize: 12 }}>{props.subject}</div>
                    </div>
                    <div >
                        <Date font={12} date={props.date} />
                    </div>
                </div>
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse align-content-stretch flex-wrap `}>
                    <div className={`d-flex justify-content-between align-items-center  pt-1 pb-1 pl-2`}
                        style={{ fontSize: 12, }}>
                        {formatMoney(props.amount)}
                        <div style={{ direction: 'rtl', textAlign: 'right', fontSize: '13px' }}>مبلغ فیش:</div>

                    </div>
                    <div>
                        {props.payable  ? <div className={`d-flex justify-content-between  align-items-center flex-row-reverse  pt-1 pb-1 pl-2`}
                        style={{ borderRadius: 15, backgroundColor: '#eaeaea', fontSize: 14, }}>
                        <div className={`rtl mr-1`}> {formatMoney(props.payableAmount)}ریال</div>
                        <div className={'pr-1 pl-1 text-light mr-2 rtl'} onClick={props.click}
                            style={{ display: props.payable ? 'block' : 'none', backgroundColor: colors.app1, borderRadius: 15, fontSize: 12, cursor: 'pointer' }}>قابل پرداخت
                        </div>
                    </div> :props.state ?
                            <ReceiptState state={props.state} />
                            : <div className={`rtl text-right  `} style={{ fontSize: 12 }}>
                            {props.usersRole != 1 ?
                                'صادر شده برای مالکین ' :
                                'صادر شده برای ساکنین'}</div>}
                    </div>
                    {/* {props.payable ? <div className={`d-flex justify-content-between  align-items-center flex-row-reverse pr-2 pt-1 pb-1 pl-2`}
                        style={{ borderRadius: 15, backgroundColor: '#eaeaea', fontSize: 11, }}>
                        <div className={`rtl`}> {formatMoney(props.payableAmount)}ریال</div>
                        <div className={'pr-1 pl-1 text-light mr-2 rtl'} onClick={props.click}
                            style={{ display: props.payable ? 'block' : 'none', backgroundColor: colors.app1, borderRadius: 15, fontSize:10, cursor: 'pointer' }}>قابل پرداخت
                        </div>
                    </div> : null} */}
                </div>
            </div>
        </Card>
    );
};

export default PayableCard;
import React from 'react';
import SBotton from '../../Buttons/SBotton/SBotton';
import Card from '../../Card/Card';
import Date from '../../Date/Date';
import Doer from '../../Doer/Doer';




const SurveyCard = props => {
    return (
        <Card onClick={props.click} width={'100%'} >
            <div className={`w-100 p-2`}>
                <div className={`d-flex flex-row-reverse align-items-center justify-content-between w-100 `}>
                    <Doer creator={props.creator} profileImage={props.profileImage} />
                    <div>
                        <Date date={props.createDt} font={13} />
                    </div>
                </div>
                <div className={`mediumF fntBold text-right rtl mt-2 mr-2 `}> {props.title.substring(0, 45)} {props.title.length>45 ? '...' : '' }   </div>
                <div className={`d-flex align-content-stretch flex-wrap w-100  justify-content-center align-items-baseline `} style={{ fontSize: 10 }}>


                    {props.options.map(item => { return <div key={item.id} className={`pr-3 pl-3 pt-1 pb-1 m-1 rtl text-right`} 
                    style={{ backgroundColor: '#dedbdbc2', borderRadius: 8 }}> {item.percentage}% {item.text}</div> })}

                    {/* <div className={`p-1 m-1 rtl`}> 25% ytghhfhgkjghjhghjhk </div> */}
                </div>
                {props.description ? <div className={`grey mediumF text-right rtl mt-1 `}>
                    {props.description.substring(0, 45)} {props.description.length>45 ? '...' : '' }
                </div> : null}
                {props.Btn ?
                    <div style={{ width: '80%', margin: '0 auto' }} >
                        <SBotton title={'شرکت در نظرسنجی'} click={props.clickBtn} gradian />
                    </div>
                    : null
                }



            </div>



        </Card>
    );
};

export default SurveyCard;
import React, {  useState } from 'react';
import arrow from '../../Assets/myAsset/down-arrow.svg'
import { formatMoney } from '../../value';
import dec from '../../Assets/myAsset/bleachRed.svg';
import inc from '../../Assets/myAsset/bleachGrn.svg';
import { Component } from 'react';

let lastObj = null;




const def =
{
    id: 4,
    value: 0,
    text: 'ساختمان خود را انتخاب کنید',

};
export class NewSelect extends Component{

    constructor(props) {
        super(props);
      
        this.state = {
            show: false,
            value:this.props.plachodler ? this.props.plachodler : def
    }
}

    //const [select, setSelect] = useState(false);
    close() {
        this.setState({
            show: false
        })
    }

    componentWillUnmount() {
        lastObj=undefined;
     }
 
    render() {
    return (
        <div className={'w-100 mt-2 position-relative '}  onBlur={ () => {console.log('main', 'blur'); this.props.closeOther();} }>
            <div className={'d-flex justify-content-between  align-items-center '}
                style={{
                    backgroundColor: this.props.sel2 ? '#fff' : '#F7F7F7',
                    border: '1px solid',
                    borderColor: this.props.sel2 ? '#000000de' : '#F7F7F7',
                    borderRadius: this.props.sel2 ? 4 : 12
                }}>
                <div style={{ width: 12, }}>
                    <img src={arrow} alt={''} style={{ width: '100%', marginLeft: 10, transform: `rotate(${this.state.show ? 180 : 0}deg)` }} />
                </div>

                <div className={'w-100 text-right pt-1 pb-1'} onClick={() => {

                    console.log(lastObj);
                   
                  lastObj&&lastObj!==this&&lastObj.close(); if(!this.state.show) {lastObj=this;  if(this.props.closeOther)this.props.closeOther();}    this.setState({show:!this.state.show});
            }} >
                    {!this.props.obj ?
                        /******************************************** */
                        // this.props.value != '' ? this.props.value.name : value.text
                        <div className={`p-1`} style={{ opacity: this.props.value !== '' ? 2 : 0.6 }} >
                             {/* {this.props.value !== '' ? (this.props.value.name || this.props.value.text || this.props.value) : this.state.value.text}  */}
                             {!this.props.value?(this.props.label?this.props.label:'انتخاب کنید') :this.props.value !== '' ? (this.props.value.name || this.props.value.text || this.props.value) : this.state.value.text}
                             </div> :
                        /******************************************** */
                      <div className={`p-2 d-flex justify-content-between align-items-center flex-row-reverse`}>
                            <div className={`pr-2 fntBold mediumF grey `}>{!this.props.value?(this.props.label?this.props.label:'انتخاب کنید') :this.props.value !== '' ? (this.props.value.name || this.props.value.text || this.props.value) : this.state.value.text}</div>
                            {this.props.desk ? <div className={`fntBold mediumF ml-2`} style={{ display: this.props.select ? 'flex' : 'none' }} >{formatMoney(this.props.desk)}
                                {this.props.desk !== 0 ?

                                    <div style={{ width: 10, }}>
                                        <img src={this.props.desk >0 ? inc : dec} alt={''} style={{ width: '100%', marginLeft: 10 }} />
                                    </div>


                                    // <img style={{ width: '10px', marginLeft: 5 }} src={props.desk >0 ? inc : dec} alt={'alt'} />
                                    : null}
                            </div>: null}
                        </div>
                    }
                </div>

            </div>
            <div className={'text-right position-absolute shadow'} onClick={() => {
                lastObj&&lastObj!==this&&lastObj.close();
                 if(!this.state.show)  {lastObj=this; this.props.closeOther();}     this.setState({show:!this.state.show});
            }} style={{
                zIndex: '100',
                width: '94%', left: '3%',
                maxHeight: 250,
                // minHeight: 150,
                overflow: "auto",
                backgroundColor: '#fff',
                marginBottom: 2,
                borderLeft: '1px solid #bfbfbf',
                borderRight: '1px solid #bfbfbf',
                borderBottom: '1px solid #bfbfbf',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                display: (this.state.show) ? 'block' : 'none'
            }}>
                {this.props.children}
            </div>
        </div>
    );

}
}
export default NewSelect;
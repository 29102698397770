import React, {Component, useState} from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../../../Components/Layout/Layout';
import NewSelect from '../../../Components/NewSelect/NewSelect';
import Toast from '../../../Components/Toast/Toast';
import CpInput from '../../../Components/Input/Input';
import { ApiService, colors } from '../../../value';
import Switch from "react-switch";
import DeleteModal from "../../../Components/Modal/DeleteModal/DeleteModal";




const billType = [
    {
        id: 0,
        name: 'آب'
    },
    {
        id: 1,
        name: 'گاز'
    },
    {
        id: 2,
        name: 'برق'
    },
]


let Self = null;
class AddBill extends Component {

    constructor(props) {
        super(props);
        Self = this;
        this.state = {

            titleLayout: '',
            isdeleting:false,

            loading: false,
            isSending: false,
            uploading: false,
            selectedType: this.props.location.state && this.props.location.state.billDetail ? this.props.location.state.billDetail.billType : null,
            title: this.props.location.state && this.props.location.state.billDetail && this.props.location.state.billDetail.subject ? this.props.location.state.billDetail.subject : '',
            identityNum: this.props.location.state && this.props.location.state.billDetail && this.props.location.state.billDetail.billKey ? this.props.location.state.billDetail.billKey : null,
            autoPay: this.props.location.state && this.props.location.state.billDetail && this.props.location.state.billDetail.autoPay ? this.props.location.state.billDetail.autoPay : false,
            disabledInput: this.props.location.state && this.props.location.state.billDetail ? true : false



        }
        this.tit = ''
        if (this.props.location.state && this.props.location.state.billDetail)
            this.billDetail = this.props.location.state.billDetail
    }


    componentDidMount() {
        this.title()
    }
    clearErr() {
        setTimeout(() => {
            this.setState({
                errText: '',
                errType: ''
            });
        }, 2000);

    }

    submitBill() {
        if (!this.billDetail && !this.state.identityNum) {
            this.setState({
                errText: 'شناسه‌ی قبض را وارد نمایید',
                errType: 'war',
                isSending: false
            })
            this.clearErr()
            return
        }

        if (!this.billDetail && !this.state.selectedType) {
            this.setState({
                errText: 'نوع قبض را مشخص کنید',
                errType: 'war',
                isSending: false
            })
            this.clearErr()
            return
        }


        let data = {
            id: this.props.location.state && this.props.location.state.billDetail ? this.props.location.state.billDetail.id : 0,
            billKey: this.state.identityNum,
            billType: this.billDetail ? this.state.selectedType : this.state.selectedType.id,
            subject: this.state.title,
            autoPay: this.state.autoPay
        }
        console.log('data', data);
        this.setState({
            isSending: true
        });
        let BuldingID = null;
        if (this.props.location.state && this.props.location.state.userBill) {
            BuldingID = 0

        } else {
            BuldingID = localStorage.getItem('id')

        }

        ApiService('Post', `/Bill/Add/${BuldingID}`, data)
            .then(res => {
                console.log(res);
                if (res.data.result) {
                    this.setState({
                        errText: res.data.message,
                        errType: 'suc'
                    })
                    setTimeout(() => {
                        if (this.props.location.state && this.props.location.state.userBill) {
                            this.props.history.push('/wallet')
                        } else {
                            this.props.history.push('/bills')
                        }


                    }, 2000);

                } else {
                    this.setState({
                        errText: res.data.message,
                        errType: 'war',
                        isSending: false
                    })
                    this.clearErr()
                }
            })

    }


    edit_new() {
        if (!this.billDetail && !this.state.identityNum) {
            this.setState({
                errText: 'شناسه‌ی قبض را وارد نمایید',
                errType: 'war',
                isSending: false
            })
            this.clearErr()
            return
        }

        if (!this.billDetail && !this.state.selectedType) {
            this.setState({
                errText: 'نوع قبض را مشخص کنید',
                errType: 'war',
                isSending: false
            })
            this.clearErr()
            return
        }


        let data = {
            id: this.props.location.state && this.props.location.state.billDetail ? this.props.location.state.billDetail.id : 0,
            billKey: this.state.identityNum,
            billType: this.billDetail ? this.state.selectedType : this.state.selectedType.id,
            subject: this.state.title,
            autoPay: this.state.autoPay
        }
        console.log('data', data);
        this.setState({
            isSending: true
        });
        let BuldingID = null;
        if (this.props.location.state && this.props.location.state.userBill) {
            BuldingID = 0

        } else {
            BuldingID = localStorage.getItem('id')

        }

        ApiService('Post', `/Bill/Edit`, data)
            .then(res => {
                console.log(res);
                if (res.data.result) {
                    this.setState({
                        errText: res.data.message,
                        errType: 'suc'
                    })
                    setTimeout(() => {
                        if (this.props.location.state && this.props.location.state.userBill) {
                            this.props.history.push('/wallet')
                        } else {
                            this.props.history.push('/bills')
                        }


                    }, 2000);

                } else {
                    this.setState({
                        errText: res.data.message,
                        errType: 'war',
                        isSending: false
                    })
                    this.clearErr()
                }
            })

    }

    deleteBill () {
        if ( this.props.location.state && this.props.location.state.billDetail ) {
            ApiService('Get', `/Bill/Delete/${this.props.location.state.billDetail.id}`)
                .then(res => {
                    console.log(res);
                    if (res.data.result) {

                        this.setState({
                            errText: 'با موفقیت حذف شد',
                            errType: 'suc',
                            isdeleting:false
                        })
                        this.clearErr();
                        setTimeout(() => {
                            if (this.props.location.state && this.props.location.state.userBill) {
                                this.props.history.push('/wallet')
                            } else {
                                this.props.history.push('/bills')
                            }


                        }, 2000);

                    } else {

                        this.setState({
                            errText: res.data.message,
                            errType: 'war',
                            isdeleting:false
                        })
                        this.clearErr();

                    }
                })
                .catch(err => {
                    this.setState({
                        errText:'خطای داخلی سرور',
                        errType: 'war',
                        isdeleting:false
                    })
                    this.clearErr();
                    return
                })
        }
    }



    title() {
        this.setState({
            loading: true
        })
        let name = '';
        if(JSON.parse(localStorage.getItem('list'))){
            let list = JSON.parse(localStorage.getItem('list'));
            
            list.find(elem => {
                if (elem.id == localStorage.getItem('id')) {
                    console.log(elem.name);
                    name = elem.name
                }
    
            })
        }
       
        if (this.billDetail ) {
            this.tit = 'ویرایش قبض  ';
            this.setState({
                loading: false
            })

        } else {
            console.log('22222');
            this.tit = 'ثبت قبض  '
            this.setState({
                loading: false
            })

        }
        if(this.props.location.state && this.props.location.state.userBill){
            console.log('۳۳۳');
            this.tit = this.tit + 'من'
            this.setState({
                loading: false
            })
        }else{
            console.log('۵۵۵۵');
            this.tit = this.tit + `ساختمان ${name}`
            this.setState({
                loading: false
            })
        }
        
        
    }




    edit() {

        this.setState({
            isSending: true
        })
        ApiService('Get', `/Bill/SetAutoPayState/${this.billDetail.id}/${this.state.autoPay}`)
            .then(res => {
                console.log(res);
                if (res.data.result) {
                    this.setState({
                        errText: 'ویرایش با موفقیت صورت گرفت',
                        errType: 'suc'
                    })
                    setTimeout(() => {
                        if(this.props.location.state && this.props.location.state.userBill){
                            this.props.history.push({
                                pathname: '/wallet',
                                state: {
                                     tab: 3
                                }
                            })
                        }else{
                             this.props.history.push('/bills')
                        }
                       

                    }, 2000);

                } else {
                    this.setState({
                        errText: res.data.message,
                        errType: 'war',
                        isSending: false
                    })
                    this.clearErr()
                }
            })


    }


    // title={`${this.billDetail ? 'ویرایش قبض' : 'ثبت قبض'}
    //     ${this.props.location.state && this.props.location.state.userBill ? 'من' :
    //             'ساختمان'
    //         }`}

    render() {
        return (<Layout loaded={true} title={this.tit} isHome={false}
                        btn={this.state.uploading ? false : true} gradian sending={this.state.isSending}
                        click={() => {
                            if (this.billDetail) {
                                this.edit_new()
                            } else {
                                this.submitBill()
                            }

                        }} btnName={this.billDetail?'ویرایش': 'ثبت' }
                         btn2={this.state.uploading ||!this.billDetail ? false : true} sending2={this.state.isSending}
                        click2={() => {
                            this.setState({
                                isdeleting: true
                            });
                        }} btnName2={'حذف'}loading={this.state.loading} >
            <DeleteModal show={this.state.isdeleting} deletable={true}
                         delete={() => { this.deleteBill() }}
                         doNotDelete={() => {  this.setState({
                             isdeleting: false
                         }); }}
                         text={'از حذف قبض اطمینان دارید؟'}
                         click={() => {
                             this.setState({
                                 isdeleting: false
                             });
                         }} />
            <Toast
                text={this.state.errText}
                type={this.state.errType}
            />

            <div style={{ height: 'inherit' }}>

                {this.props.location.state && this.props.location.state.billDetail ?


                    <div className={`p-2 w-100 text-right`} style={{ backgroundColor: '#f7f7f7', border: '1px solid #f7f7f7', borderRadius: 12 }}>{this.props.location.state.billDetail.billType == 0 ? 'قبض آب'
                        : this.props.location.state.billDetail.billType == 1 ? 'قبض گاز' : 'قبض برق'}</div>

                    : <NewSelect options={billType} value={this.state.selectedType} >
                        <div>
                            {billType.map((item, index) => {
                                return (
                                    <div key={index} className={`p-2 d-flex justify-content-between align-items-center flex-row-reverse`}
                                        onClick={() => {
                                            billType.find(elem => {
                                                if (elem.id == item.id) {
                                                    console.log(elem.id);
                                                    this.setState({
                                                        selectedType: elem
                                                    })
                                                    // localStorage.setItem('id', elem.id);
                                                    // setSelectedBulding(elem);
                                                    //getDebtorsList();
                                                    //setSelectedBulding(elem);

                                                }
                                            })
                                        }}>
                                        <div className={`pr-2 fntBold mediumF grey`}>{item.name}</div>

                                    </div>
                                )
                            })}
                        </div>
                    </NewSelect>}
                <div className={`mt-3 `} >
                    <CpInput plachodler={'عنوان قبض'} disabled={false} value={this.state.title} type={"text"} onChange={(e) => {
                        this.setState({
                            title: e.target.value
                        })
                    }} />
                    <CpInput plachodler={'شناسه قبض '} disabled={this.state.disabledInput} value={this.state.identityNum} type={"number"} onChange={(e) => {
                        this.setState({
                            identityNum: e.target.value
                        })
                    }} />

                    <div className='d-flex align-items-center justify-content-end mt-3 mb-3 ' style={{
                        width: '100%',
                    }}>
                        <div className='d-flex align-items-center mr-2 '>
                            <span style={{ color: colors.app7, fontSize: 12 }}> پرداخت اتوماتیک</span>
                        </div>
                        <Switch
                            width={45}
                            height={23}
                            onChange={() => {
                                this.setState({
                                    autoPay: !this.state.autoPay
                                })
                            }
                            }
                            checked={this.state.autoPay}
                            className="react-switch"
                        />
                    </div>
                </div>
            </div>
        </Layout>)
    }
}

export default withRouter(AddBill);
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from '../../Components/Layout/Layout';
import Toast from '../../Components/Toast/Toast';
import { serviceAxios } from "../../axios";
import ProviderImage from '../../Components/ProviderImage/ProviderImage';
import { useOrderContext } from '../../context/orderContext';
import UserImg from '../../Assets/myAsset/user.png';

const ServiceProvidersList = ()=>{
    const [loading , setLoading] = React.useState(true)
    const [providers , setProviders] = React.useState([])
    const [error , setError] = React.useState({errText : "" , errType : "war"})
    const {orderState , orderDispatch} = useOrderContext()
    const history = useHistory()

    console.log("orderState" , orderState)
    React.useEffect(()=>{
        serviceAxios.post(
            `ProviderServices/ServiceProviders/${orderState.serviceId}/${orderState.addressId}`
        ).then(res=>{
            console.log(res.data)
            setProviders([...res.data.data])
            setLoading(false)
        }).catch(err =>{
            console.error(err)
        })
    },[])

    const selectProvider = (provider)=>{
        console.log(provider)
        orderDispatch({type : "SET_ORDER" , data : {
            providerServiceId : provider.id , 
            providerName : provider.providerName , 
            providerInfo : provider ,
            serviceImage : provider.image?provider.image.path:UserImg
        }})
        history.push(`/service/periods`)
    }

    return (
        <Layout 
            loaded={true} 
            title={`لیست خدمات دهندگان\n${orderState.title}`}
            isHome={false}  
            loading={loading}
            showMenu={true}
            displayMenu={true}
        >
            <Toast
                text={error.errText}
                type={error.errType}
            />
            <div className='container mt-3'>
                <div className='row justify-content-around align-content-start overflow-auto'  style={{height : 'calc(100vh - 55px)'}}>
                    {
                        providers.map((item)=>(

                            <ProviderImage
                                provider={item}
                                onClick={()=>selectProvider(item)}
                            />
                        ))
                    }
                </div>
            </div>
            
        </Layout>

    )
}
export default ServiceProvidersList;
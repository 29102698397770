import React from "react"
import Layout from "../../Components/Layout/Layout"
import classes from "./OrdersHistory.module.css"
import SearchBar from "../../Components/SearchBar/SearchBar"
import searchImg from '../../Assets/myAsset/loupe.svg';
import { useHistory } from "react-router-dom"
import { formatMoney } from "../../value"
import { serviceAxios } from "../../axios"
import ServiceFilterModal from "../../Components/Modal/ServiceFilterModal/ServiceFilterModal"
import ReactList from "react-list"
import LazyLoading from 'react-list-lazy-load';
import moment from "moment-jalaali"
import Toast from "../../Components/Toast/Toast";
import useError from "../../hooks/useError";
import EmptyList from "../../Components/EmptyList/EmptyList";
import defaultImg from "../../Assets/myAsset/user.png"

const OrdersHistory = ()=>{
    const [loading , setLoading] = React.useState(true)
    const [orders , setOrders] = React.useState([])
    const [showFilterModal , setShowFilterModal] = React.useState(false)
    const [showSearch , setShowSearch] = React.useState(false)
    const [searchText, setSearchText] = React.useState('');
    const [totalOrders , setTotalOrders] = React.useState(0)
    const [filterData , setFilterData] = React.useState({
        "periodId": null,
        "isArchive": null,
        "serviceId": null,
        "startAmount": null,
        "endAmount": null,
        "startDate": "",
        "endDate": "",
        "sortByType": 0,
        "sortIsDesc": true,
        "nameLike": "",
        "pageSize": 10,
        "pageNumber": 1
    })
    const history = useHistory()
    let pageNumber = React.useRef(0)
    let nameLike = React.useRef("")
    const {error , setError} = useError()
    
    React.useEffect(()=>{
        pageNumber.current = 0
       getOrders()
    },[filterData])

    
    const getOrders = ()=>{
        pageNumber.current++
        setLoading(true)
        serviceAxios.post(
            "/Order/List/list",
            {
                ...filterData,
                startDate : filterData.startDate != "" ?moment(filterData.startDate , "jYYYY/jMM/jDD").format("jYYYY/jMM/jDD") : "",
                endDate : filterData.endDate != "" ?moment(filterData.endDate , "jYYYY/jMM/jDD").add(1 , "days").format("jYYYY/jMM/jDD") : "",
                pageNumber : pageNumber.current 
            }
        ).then(res=>{
            pageNumber.current === 1?setOrders([...res.data.data]): setOrders([...orders , ...res.data.data])
            setTotalOrders(res.data.itemsCount)
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
        })
    }

    const onFilterConfirmed = (filterModel)=>{
        setLoading(true)
        setFilterData({...filterData , ...filterModel})
    }

    const nameLikeChanged = (e)=>{
        nameLike.current = e.target.value
        const nl = e.target.value
        setOrders([])
        setTimeout(()=>{    
            if(nl === nameLike.current) {
                setFilterData({...filterData , nameLike : nameLike.current})
            }
        },1000)
    }

    return(
        
        <Layout
            title={"تاریخچه سفارشات"}
            loaded={true}
            loading={loading}
            showMenu={true}
            displayMenu={true}
        > 
            <div className="d-flex flex-column w-100 h-100 mt-1 align-items-center overflow-auto">
               
                <SearchBar
                    click={() => {
                        if (showSearch) {
                            if(showSearch){
                                nameLike.current = ""
                            }
                            setShowSearch(false);
                            filterData.nameLike !== "" && setFilterData({...filterData , nameLike : ""})
    
                        } else {
                            setShowSearch(true);
                        }
                    }}
                    sortModule={true}
                    clickSort={() => {
                        setShowFilterModal(true)
                    }} 
                />
                {
                    showSearch ?
                    <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                        <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                            style={{
                                padding: 5,
                                border: ' 1px solid grey',
                                borderRadius: 10
                            }}>
                            <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                onKeyUp={event => {

                                    if (event.key === 'Enter') {
                                        
                                    }
                                }}
                                placeholder={'جستجو'}
                                onChange={(e) => {
                                    nameLikeChanged(e)
                                }} />
                            <div style={{ width: 15, }}>
                                <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                    
                                }} />
                            </div>

                        </div>
                        {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                                <img src={del} style={{ width: '100%' }} />
                            </div> */}

                    </div>:
                    null
                }
                {
                    orders.length > 0 ?
                    <LazyLoading
                        length={totalOrders}
                        items={orders}
                        onRequestPage={getOrders}
                        pageSize={10}
                    >
                        <ReactList
                            type="uniform"
                            length={orders.length}
                            itemRenderer={(index , key) =>{
                                const order = orders[index];
                                return(
                                <div 
                                    className={`shadow-card d-flex flex-column mb-3 text-right p-2 w-100 rtl  ${classes.wrapper}` }
                                    key={key}
                                    onClick={()=>history.push(`/service/orderDetails/${order.id}`)}
                                >
                                    <div className="d-flex flex-row justify-content-between mb-2">
                                        <span>
                                            {
                                                order.provider.name
                                            }
                                        </span>
                                        <span>
                                            {
                                                order.reservationDate
                                            }
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-start mb-2">
                                        <img 
                                            src={
                                                !!order.provider.image &&
                                                !!order.provider.image.path &&
                                                order.provider.image.path != "" ? 
                                                order.provider.image.path : defaultImg
                                            }
                                            style={{width : 30 , height : 30 , borderRadius : "50%" , marginLeft : 5}}
                                        />
                                        <span>
                                            {
                                                order.provider.user?.name
                                            }
                                        </span>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between mb-2">
                                        <span>
                                            {
                                               order.period.description
                                            }
                                        </span>
                                        <span>
                                        {
                                            "قیمت : " + (order.totalPrice > 0 ? (`${formatMoney(order.totalPrice)} ریال`) : "رایگان")
                                        }
                                        </span>
                                    </div>
                                </div>
                            )}}
                        />
                    </LazyLoading>:
                    <EmptyList des={"سفارشی یافت نشد"} />
                        
                }
            </div>
            <Toast
                text={error.errTxt}
                type={error.errType}
            />
            <ServiceFilterModal
                show={showFilterModal} 
                amount={true} 
                sortInc={true}
                typeOdDoc={false} 
                showPeriods={false} 
                showCategory={false}
                showDateRange={true}
                click={() => {
                    setShowFilterModal(false);
                }}
                submit={(filterData) => {
                    console.log('>>>>>>>>>>>>>>>>>>',filterData);
                    if(filterData.endDate < filterData.startDate){
                        setError("تاریخ پایان نمی تواند از تاریخ شروع کوچکتر باشد" , "war")
                        filterData.endDate = filterData.startDate
                    }
                    setShowFilterModal(false)
                    onFilterConfirmed(filterData)
                }} 
            />
        </Layout>
    )
}

export default OrdersHistory;
import React from 'react';
import Date from '../../Date/Date';
import seencheck from '../../../Assets/myAsset/double-check.svg'
import delivercheck from '../../../Assets/myAsset/double-checkgray.svg'
import sendcheck from '../../../Assets/myAsset/chat-check.svg'
import waitcheck from '../../../Assets/myAsset/chat-loading.json'
import Lottie from 'react-lottie';
import del from '../../../Assets/myAsset/delete.png'

const MyChat = props => {




    const lottieoptions = {
        loop: true,
        autoplay: true,
        animationData: waitcheck,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }

    }




    return (
        <div style={{ direction: 'rtl' }}>
            <div className={`p-1 m-2 `} style={{ maxWidth: '80%',wordWrap:'break-word',  width: 'fit-content', borderRadius: 10, backgroundColor: '#b2bdbf52' }}>
                {props.item.text.length>0 ?<div className={`text-right`} style={{ fontSize: 13 }}>
                    {props.item.text}
                </div> :
                <div>
                    <div className={` `} onClick={(e) => {
                                    window.open(props.item.image);
                                    e.stopPropagation();
                                }} style={{
                          backgroundColor:'white',
                                width: 100, height: 80,
                                backgroundImage: "url(" + props.item.image+ ")",
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            </div> 
                    
                    </div>}
                <div className={`d-flex justify-content-between align-items-center`}>

                    <div className={`d-flex align-items-center`}>
                        <div style={{ width: 15 }}> {!props.item.received && !props.item.send && !props.item._id ?
                            <Lottie options={lottieoptions}></Lottie> :
                            (<img style={{ width: '100%' }} src={props.item.received ? seencheck :
                                props.item.send ? delivercheck :
                                    props.item._id ? sendcheck : null} />)}</div>
                        {/* <Date date={'98/05/03'} font={10} /> */}
                        <div style={{ fontSize: 10, color: 'grey', marginRight: 5 }}>{props.item.createdAtFa} {props.item.createdTimeFa}</div>

                    </div>



                    {props.item.pBtnDelete ? <div style={{ fontSize: 12, color: 'grey', marginRight: 5, width: 15 }} onClick={props.delete}>
                        <img style={{ width: '100%' }} src={del} />
                    </div> : null}

                </div>

            </div>
        </div>
    )
}
export default MyChat;
import React from "react";
import { serviceAxios } from "../axios";

const ProviderClientContext = React.createContext()

const useProviderClientContext = ()=>{
    const providerClientContext = React.useContext(ProviderClientContext)
    if(providerClientContext === undefined){
        throw new Error("useProviderClientContext must be used inside a client")
    }

    return providerClientContext
}

const ProviderClientContextProvider = ({children})=>{
    const [clients , setClients] = React.useState([])
    return(
        <ProviderClientContext.Provider value={[clients , setClients]}>
            {children}
        </ProviderClientContext.Provider>
    )
}

export {ProviderClientContextProvider , useProviderClientContext }
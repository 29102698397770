import React from "react"
import Layout from "../../Components/Layout/Layout"
import { getOrderHistory } from "../../mocks/orderHistory"
import { useHistory, useParams } from "react-router-dom"
import { Rating } from "@mui/material"
import CpInput from '../../Components/Input/Input';
import SBotton from "../../Components/Buttons/SBotton/SBotton"
import { formatMoney } from "../../value"
import { serviceAxios } from "../../axios"
import useError from "../../hooks/useError"
import Toast from "../../Components/Toast/Toast"
import TwoOptionModal from "../../Components/TwoOptionModal/TwoOptionModal"

const ProviderOrderDetails = ()=>{
    const [loading , setLoading] = React.useState(true)
    const [sending , setsending] = React.useState(false)
    const [showCancelDialog , setShowCancelDialog] = React.useState(false)
    const [order , setOrder] = React.useState(null)
    const {error , setError} = useError();
    const { id } = useParams()
    const history = useHistory()

    React.useEffect(()=>{
        getOrder()
    },[])

    const getOrder = ()=>{
        setLoading(true)
        serviceAxios.get(
            `/Order/Detail/${id}`
        ).then(res=>{
            setOrder(res.data.data)
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
            setError("خطایی رخ داده است. لطفا مجددا تلاش کنید" , "err")
        })
    }

    const onCancelClicked = ()=>{
        setShowCancelDialog(true)
    }

    const cancelOrder = ()=>{
        setShowCancelDialog(false)
        setsending(true)
        serviceAxios.get(
            `/Order/Delete/${id}`,
        ).then(res=>{
            setsending(false)
            if(res.data.resultCode == 200){
                history.goBack()
            }
            else{
                setError(res.data.message , "err")
            }

        }).catch(error =>{
            console.log(error)
            setsending(false)
            setError("خطایی رخ داده است. لطفا مجددا تلاش کنید" , "err")
        })
    }

    console.log(order)
    return(
        
        <Layout
            title={"جزییات سفارشات"}
            loaded={true}
            loading={loading}
            showMenu={true}
            displayMenu={true}
        > 
            {
                order &&
                <div className="d-flex flex-column align-items-center h-100 overflow-auto">
                    <div className="d-flex flex-row w-100 justify-content-end mt-2">
                        <span>
                            {
                                order.user.name
                            }
                        </span>
                        <img
                            src={order.user.profileImageUrl}
                            style={{
                                width : 30,
                                height:30,
                                borderRadius : "50%",
                                marginLeft : 8
                            }}
                        />
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-end mt-3">
                        <h6>
                            {
                                order.titel
                            }
                        </h6>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-end mb-2">
                        <p>
                            {
                                "شماره سفارش : " + order.id
                            }
                        </p>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-end mt-2">
                        <p>
                            {
                                order.period.description
                            }
                        </p>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-end mt-2">
                        <p>
                            {
                                `تاریخ : ${order.reservationDate}`
                            }
                        </p>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-end mt-2">
                        <p>
                            {
                                `ساعت : ${order.period.startTime} الی ${order.period.endTime}`
                            }
                        </p>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-end mt-2">
                        <p>
                            {
                                `تعداد : ${order.count}`
                            }
                        </p>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-end mt-2">
                        <p>
                            {
                                `مبلغ : ${order.totalPrice > 0 ? (formatMoney(order.totalPrice) + " ریال") : "رایگان"}`
                            }
                        </p>
                    </div>
                    <div className="d-flex flex-row w-100 justify-content-end mt-2">
                        <h6 className="rtl">
                            امتیاز سفارش:
                        </h6>
                    </div>
                    
                    {
                        order.commentDate !== "" ?
                        <>
                            <Rating 
                                readOnly
                                value={order.rate * 0.5} 
                                precision={0.5}
                                size="large"
                                sx={{
                                    color : "#ff5d1c"
                                }}
                            />  
                            <p className="w-100 text-right mt-2">
                                {
                                    order.comment
                                }
                            </p>
                        </>:
                        <p className="w-100 text-right mt-2 px-3">
                            نظری برای سفارش ثبت نشده است
                        </p>
                    }

                  
            
                    {
                        order.allowToDalete &&
                        <div className="w-50">
                            <SBotton
                                title="کنسل کردن"
                                click={onCancelClicked}
                                sending={sending}
                                gradian
                            />
                        </div>
                    }
                </div>
            }
        
            <Toast
                text={error.errTxt}
                type={error.errType}
            />
            <TwoOptionModal
                title={"از حذف این سفارش مطمئن هستید؟"}
                showModal={showCancelDialog}
                setShowModal={setShowCancelDialog}
                callback1={cancelOrder}
                callback2={()=>setShowCancelDialog(false)}
            />
        </Layout>
    )
}

export default ProviderOrderDetails;
import React from "react"
import classes from "./PeriodList.module.css"
import { Link, useHistory } from "react-router-dom"
import ReactList from 'react-list';
import LazyLoading from 'react-list-lazy-load';


const PeriodList = ({periods , getPeriods , serviceId  , allPeriodsLength=100 , isEditable})=>{
    
    const history = useHistory()

    const onClick = (item)=>{
        if(!isEditable) return false
        localStorage.setItem("oldPeriod", JSON.stringify(item))
        history.push(`/service/provider/Period/Details/${serviceId}`)
    }

    return(
    <div style={{overflow : "auto"}} className="w-100 px-2 pt-2 h-100">
        {
            !!periods &&
            <LazyLoading
                length={allPeriodsLength}
                pageSize={10}
                items={periods}
                onRequestPage={getPeriods}
                loadMargin={2}
            >
                <ReactList
                    type="uniform"
                    length={periods.length}
                    itemRenderer={(idx, key) => {
                        const item = periods[idx];
                        return(
                            <div 
                                className={`d-flex flex-column w-100 p-3 px-4 ${classes.card}`}
                                onClick={()=>onClick(item)}
                                key={key}
                            >
                                <div className="row flex-row-reverse">
                                    <div className="col-6">
                                        <div className="row flex-row-reverse">
                                            <span className="mx-2 text-right">
                                                {
                                                    item.title
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row flex-row">
                                            <span className=" text-muted">
                                                {
                                                    item.dateTimeString
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row flex-row-reverse align-items-center">
                                    <span className="mx-2 mt-2">
                                        {
                                            item.description
                                        }
                                    </span>
                                </div>
                                <div className="row flex-row align-items-center rtl">
                                    <span className="mx-2 mt-2">
                                        {
                                            "ساعت شروع :   " + item.startTime
                                        }
                                    </span>
                                </div>
                                <div className="row flex-row align-items-center rtl">
                                    <span className="mx-2 mt-2">
                                        {
                                            "ساعت پایان :   " + item.endTime
                                        }
                                    </span>
                                </div>
                            </div>
                        
                        )}
                    }
                />
            </LazyLoading>
        }
        </div>
    )
    

}

export default PeriodList
import React, { useEffect, useState } from 'react';
import GrayLine from '../../Components/GrayLine/GrayLine';
import Layout from '../../Components/Layout/Layout';
import TabBar from '../../Components/TabBar/TabBar';
import searchImg from '../../Assets/myAsset/loupe.svg';
import { ApiService, colors, FaToEnNum, formatMoney, isUserHomtick } from '../../value'
import TransactionCard from '../../Components/Cards/TransactionCard/TransactionCard';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { Checkbox } from '../../Components/CheckBox/CheckBox';
import Modal from '../../Components/Modal/Modal';
import CpInput from '../../Components/Input/Input';
import Toast from '../../Components/Toast/Toast';
import SBotton from '../../Components/Buttons/SBotton/SBotton';
import { withRouter } from 'react-router-dom';
import FilterModal2 from '../../Components/Modal/FilterModal2/FilterModal2';
import EmptyList from '../../Components/EmptyList/EmptyList';
import hasApp from '../../Assets/lotties/appinstall.json';
import hasnotApp from '../../Assets/lotties/havenotapp.json';
import Switch from "react-switch";



import Lottie from 'react-lottie'
import PayableCard from '../../Components/Cards/PayableCard/PayableCard';
import FundCard from '../../Components/Cards/FundCard/FundCard';

const tabItems = [
    {
        id: 1,
        title: 'مشخصات',

    },
    {
        id: 2,
        title: 'تراکنش‌ها',

    },
    {
        id: 3,
        title: 'فیش‌ها',

    },

]

const tabItems1 = [
    {
        id: 1,
        title: 'مشخصات',

    },


]

const Unit = props => {
    const [tab, setTab] = useState(true);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(1);
    const [renderItem, setRenderItem] = useState(null);
    const [showModalChngResident, setShowModalChngResident] = useState(false);
    const [showModalChngOwner, setShowModalChngOwner] = useState(false);
    const [tabItemsList, setTabItemsList] = useState(tabItems)

    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');


    const [name, setName] = useState(null);
    const [showName, setShowName] = useState(false);
    const [issending, setissending] = useState(false);
    const [newMobile, setNewMobile] = useState(null);
    const [personsCount, setPersonsCount] = useState(props.location.state
        && props.location.state.unitInfo
        && props.location.state.unitInfo.personsCount ? props.location.state.unitInfo.personsCount : null);
    const [unitUserType, setUnitUserType] = useState(null);

    const [userID, setUserID] = useState(null);
    const [userMobile, setUserMobile] = useState(null);
    const [userSent, setUserSent] = useState(false);



    const [makeEmpty, setMakeEmpty] = useState(false);
    const [changeInfo, setChangeInfo] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');


    const [unitInfo, setUnitInfo] = useState(null);


    const [transactionList, setTransactionList] = useState([]);


    const [search, setSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filterData, setFilterData] = useState(null);
    const [filterModal, setFilterModal] = useState(false);


    const [docs, setDocs] = useState([]);
    const [permissions, setPermissions] = useState(null);
    const [withArchive, setWithArchive] = useState(false);



    const haveNApp = {
        loop: false,
        autoplay: true,
        animationData: hasnotApp,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }

    }
    const haveApp = {
        loop: false,
        autoplay: true,
        animationData: hasApp,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }

    }

    useEffect(() => {

        if (props.location.state && props.location.state.unitInfo && localStorage.getItem('id')) {
            getUnitInfo(props.location.state.unitInfo.id);
            getTransactions(props.location.state.unitInfo.id);
            getReceiptOfUnt(props.location.state.unitInfo.id);

        } else {
            props.history.push('/building')
        }

    }, [changeInfo]);



    // useEffect(()=>{
    //     console.log('**************',unitInfo);
    //     getUnitInfo()
    // },[unitInfo])


    useEffect(() => {
        setFilterData(null)
    }, [activeTab])

    useEffect(() => {

        if (activeTab == 1) {
            setRenderItem(renderInfo());
            setTab(true);
        } else if (activeTab == 2) {
            setRenderItem(renderTransactions());
            setTab(false);
        } else {
            setRenderItem(renderReceipt());
            setTab(null);
        }
    }, [activeTab, unitInfo, showName, name, tabItemsList, docs, withArchive,
        options, newMobile, changeInfo,
        transactionList, searchText, search]);


    useEffect(() => {
        if (activeTab == 2) {
            console.log('ffff');
            getTransactions(props.location.state.unitInfo.id);
        } else {
            getReceiptOfUnt(props.location.state.unitInfo.id);
        }
    }, [withArchive, filterData, searchText, filterModal])



    const getTransactions = (id) => {

        console.log('unitInfo.id', filterData);
        setLoading(true);
        let data = {

            sortIsDesc: true,
            nameLike: searchText,
            pageSize: 1000,
            pageNumber: 0
        }

        if (filterData) {
            data.sortIsDesc = filterData.sortIsDesc;
            data.sortByType = filterData.sortByType;
            data.startAmount = filterData.startAmount;
            data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }


        ApiService('Post', `/AccRequest/UnitTransactionList2/${localStorage.getItem('id')}/${id}`, data)
            .then(response => {
                if (response.data.result) {
                    setTransactionList(response.data.data);
                    //setActiveTab(1);
                    setLoading(false);
                } else {
                    setTransactionList([])
                    setTabItemsList(tabItems1);
                    // setErrText(response.data.message);
                    // setErrType('war');
                    // clearErr();
                    setActiveTab(1)
                    setLoading(false);
                }
            })
            .catch(err => {
                setTransactionList([])
                setTabItemsList(tabItems1);
                setActiveTab(1)
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }




    const getUnitInfo = (id) => {
        setLoading(true);
        let data = {
            unitId: id,
            nameLike: '',
            pageSize: 100,
            pageNumber: 1

        }
        ApiService('Post', `/Building/Units/${localStorage.getItem('id')}`, data)
            .then(res => {
                console.log(res);
                if (res.data.result) {
                    setUnitInfo(res.data.data[0]);
                    setLoading(false);


                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr();

                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })

    }

    const getReceiptOfUnt = (id) => {
        setLoading(true);
        let data = {
            withArchivedRequests: withArchive,
            assignedToMe: true,
            unitId: id,
            nameLike: searchText,
            pageSize: 10000,
            pageNumber: 0,
            sortIsDesc: true
        }
        if (filterData) {
            data.sortIsDesc = filterData.sortIsDesc;
            data.sortByType = filterData.sortByType;
            data.startAmount = filterData.startAmount;
            data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }
        console.log('dataccccccccccccccccccccccccc', data);
        ApiService('POST', `/AccRequest/ListRequestDocuments/${localStorage.getItem('id')}`, data)
            .then(res => {
                console.log('************', res);
                if (res.data.result) {
                    setDocs(res.data.data);
                    setPermissions(res.data.data2);
                    setLoading(false);

                } else {
                    // setErrText(res.data.message);
                    // setErrType('war');
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }


    const getUserInfo = () => {
        const Mobile = FaToEnNum(newMobile);
        if (Mobile.length != 0) {
            console.log(Mobile.length);
            if (Mobile.length != 11) {
                setErrText('شماره موبایل را به درستی وارد کنید');
                setErrType('war');
                clearErr();
                return
            }


            isUserHomtick(Mobile).then(response => {
                setShowName(!response.data.hasApplication);
                setLoading(true);
                setUserID(response.data.id);
                setUserMobile(Mobile);
                setName(response.data.name)
                setUserSent(true);
                setLoading(false);
                setName(response.data.name);
            })
                .catch(err => {
                    console.log(err);
                    setUserMobile(Mobile);
                    setUserID('');
                    setUserSent(true);
                    setShowName(true);
                    setLoading(false);
                })
        }
    }

    const changeResident = (userType) => {
        console.log('UUUUUUUUUUU', unitInfo);
        setLoading(true);
        ApiService('Post', `/Building/ChangeUnitOwnerOrResidentData/${localStorage.getItem('id')}/${unitInfo.id}/${userType}`, null)
            .then(res => {
                console.log('>>>>>>>>>>>>>>>', res);
                if (res.data.result) {
                    if (res.data.data && res.data.data.options && res.data.data.needQuestionIfChangUser) {
                        setChangeInfo(res.data.data)
                        setOptions(res.data.data.options);
                        if (userType == 1) {
                            setShowModalChngResident(true);
                            if (unitInfo.resident) {
                                setNewMobile(unitInfo.resident.mobileNumber);
                                setName(unitInfo.resident.name)
                            }


                        }
                        else {
                            setShowModalChngOwner(true);
                            if (unitInfo.owner) {
                                setNewMobile(unitInfo.owner.mobileNumber);
                                setName(unitInfo.owner.name);
                            }
                        }
                        setUnitUserType(userType);
                        setLoading(false);

                    } else {
                        if (userType == 1) {
                            setShowModalChngResident(true);
                            if (unitInfo.resident) {
                                setNewMobile(unitInfo.resident.mobileNumber);
                                setName(unitInfo.resident.name)
                            }
                            setLoading(false);
                        } else {

                            setShowModalChngOwner(true);
                            if (unitInfo.owner) {
                                setNewMobile(unitInfo.owner.mobileNumber);
                                setName(unitInfo.owner.name);
                            }
                            setLoading(false);
                        }

                    }


                } else {
                    setErrText(res.data.message)
                    setErrType('war')
                    setLoading(false);
                    clearErr()

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                clearErr();
                return
            })


    }

    const submitChange = (userType) => {
        setissending(true);
        console.log('personsCountpersonsCount', personsCount);
        console.log(selectedOption);
        if (!makeEmpty && !personsCount && userType == 1) {
            setErrText('تعداد ساکنین جدید را وارد کنید');
            setErrType('war');
            clearErr();
            return
        }

        if (options && options.length > 0 && selectedOption === '' && userType == 1) {
            setErrText('یکی ار گزینه ها را انتخاب کنید');
            setErrType('war');
            clearErr();
            return
        }
        let data = null

        if (makeEmpty) {
            data = {
                unitId: unitInfo.id,
                unitUserType: userType,
                setAsEmpty: makeEmpty,
                selectedOption: selectedOption
            }

        } else if (!userMobile && !userID) {
            data = {
                unitId: unitInfo.id,
                unitUserType: userType,
                setAsEmpty: makeEmpty,
                newUser: {
                    id: unitInfo.resident.id,
                    name: unitInfo.resident.name,
                    mobileNumber: unitInfo.resident.mobileNumber,
                },
                personsCount: parseInt(FaToEnNum(personsCount)),
                selectedOption: selectedOption
            }
        }

        else {
            data = {
                unitId: unitInfo.id,
                unitUserType: userType,
                setAsEmpty: makeEmpty,
                newUser: {
                    name: name,
                    mobileNumber: userMobile,
                },
                personsCount: parseInt(FaToEnNum(personsCount)),
                selectedOption: selectedOption
            }
            if( userID.length>0)

                data.newUser.id= userID;
        }



        console.log('dataaaaaaaaaaaaaaaaaaaa', data);

        ApiService('POST', `/Building/SubmitUnitOwnerOrResidentData/${localStorage.getItem('id')}`, data)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setErrText('با موفقیت ثبت شد');
                    setErrType('suc');
                    clearErr();
                    setShowModalChngResident(false);
                    getUnitInfo(props.location.state.unitInfo.id);
                    //  window.location.reload()

                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                clearErr();
                return
            })
    }


    const submitChangeOwner = (userType) => {
        setissending(true)
        console.log('userMobile    userID', userMobile, userID);

        if (options && options.length > 0 && selectedOption === '' && userType == 2) {
            setErrText('یکی ار گزینه ها را انتخاب کنید');
            setErrType('war');
            clearErr();
            return
        }

        if (userID || userMobile||name) {
            let data = {
                unitId: unitInfo.id,
                unitUserType: userType,
                selectedOption: selectedOption,
                newUser: {
                    id: userID,
                    name: name,
                    mobileNumber: userMobile,
                },
            }
            if(!data.newUser.mobileNumber && !data.newUser.id)
            {
                data.newUser.mobileNumber=unitInfo.owner.mobileNumber;
                data.newUser.id=unitInfo.owner.id;
            }
            console.log('submitChangeOwner', data);
            ApiService('POST', `/Building/SubmitUnitOwnerOrResidentData/${localStorage.getItem('id')}`, data)
                .then(response => {
                    console.log(response);
                    if (response.data.result) {
                        setErrText('با موفقیت ثبت شد');
                        setErrType('suc');
                        clearErr();
                        setShowModalChngOwner(false);
                        getUnitInfo(props.location.state.unitInfo.id);
                        // props.history.push({
                        //     pathname: '/building',
                        //     state: {
                        //         tabID: 2
                        //     }

                        // })
                    } else {
                        setErrText(response.data.message);
                        setErrType('war');
                        clearErr();

                    }
                })
                .catch(err => {
                    setErrText('خطای داخلی سرور');
                    setErrType('err');
                    clearErr();
                    return
                })

        }

    }



    const renderInfo = () => {
        return (unitInfo ?
            <>
                <div className={`d-flex flex-row-reverse justify-content-between`}>
                    <div className={`text-right w-100 p-2`}>
                        <div className={`fntBold mediumF`}> {unitInfo.name} </div>
                        <div className={`grey smallF rtl`}>{unitInfo.area} متر مربع</div>
                    </div>
                    <div className={`text-center w-100 p-2`}>
                        <div className={`fntBold mediumF rtl`}>طبقه {unitInfo.floor}  {unitInfo.blockName ? `بلوک ${unitInfo.blockName}` : ``}</div>
                        <div className={`grey smallF rtl`} >{unitInfo.parkingCount} عدد پارکینگ</div>
                    </div>
                </div>
                <GrayLine />


                <div className={`mb-3 `} style={{ lineHeight: 1.8 }}>
                    <div className={`d-flex flex-row-reverse justify-content-between`}>
                        <div className={`grey mediumF fntBold text-right `}>{unitInfo.personsCount ? `ساکن (${unitInfo.personsCount}نفر)` : 'بدون ساکن'}</div>
                        {unitInfo.pAllowEditResident ? <div style={{ width: '32%' }}>
                            <div onClick={() => {

                                setShowName((unitInfo&&unitInfo.resident&&unitInfo.resident.hasApplication)? !unitInfo.resident.hasApplication:false);
                                changeResident(1);


                            }}
                                style={{
                                    fontSize: 11, borderRadius: 10,
                                    border: '1px solid #ff5722', textAlign: 'center',
                                    padding: 2, color: '#ff5722'
                                }}>
                                {unitInfo.resident ?
                                    ' تغییر ساکن' :
                                    'ثبت ساکن'}
                            </div>

                        </div> : null}


                    </div>



                    {unitInfo.resident && unitInfo.resident.profileImageUrl ?
                        <div className={`w-100 mr-0 `} style={{
                            width: 60, height: 60,
                            backgroundImage: "url(" + unitInfo.resident.profileImageUrl + ")",
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        }}>

                        </div>
                        : null}






                    <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-2`}>
                        {unitInfo.resident ? <div className={`fntBold mediumF text-right`} >  {`${unitInfo.resident.name}`}</div> : null}
                        <div className={`fntBold mediumF d-flex flex-row-reverse align-items-center`}>


                            {unitInfo.resident && unitInfo.resident.mobileNumber && unitInfo.resident.mobileNumber.indexOf('*') < 0 ?
                                <a href={unitInfo.resident && unitInfo.resident.mobileNumber && unitInfo.resident.mobileNumber.indexOf('*') < 0 ?
                                    ` tel: ${unitInfo.resident.mobileNumber}` : ''}>
                                    <div >{unitInfo.resident ? unitInfo.resident.mobileNumber : null}

                                    </div>
                                </a> :
                                <div >{unitInfo.resident ? unitInfo.resident.mobileNumber : null}</div>}

                            {unitInfo.resident ? <div style={{ width: 50 }}>
                                <Lottie options={unitInfo.resident ? (unitInfo.resident.hasApplication ? haveApp : haveNApp) : null} />
                            </div> : null}

                        </div>
                    </div>



                    {unitInfo.resident && unitInfo.pymentDataResident ? (unitInfo.pymentDataResident.amountPayable > 0 ? <div className={`d-flex justify-content-between  align-items-center flex-row-reverse  `}>
                        <div style={{ direction: 'rtl', textAlign: 'right', fontSize: 12 }}>بدهی ساکن:</div>
                        <div className={`d-flex justify-content-between  align-items-center flex-row-reverse  pt-1 pb-1 pl-2`}
                            style={{ borderRadius: 15, backgroundColor: '#eaeaea', fontSize: 14, }}>
                            <div className={`rtl mr-1`}> {formatMoney(unitInfo.pymentDataResident.amountPayable)}ریال</div>
                            <div className={'pr-1 pl-1 text-light mr-2 rtl'} onClick={() => {
                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: unitInfo.pymentDataResident,
                                        callBackUrl: window.location.href
                                    }
                                });

                            }}
                                style={{ display: 'block', backgroundColor: colors.app1, borderRadius: 15, fontSize: 12, cursor: 'pointer' }}>قابل پرداخت
                        </div>
                        </div>
                    </div> :
                        <div className={`d-flex justify-content-center`}>
                            <div style={{
                                fontSize: 11, borderRadius: 10,
                                border: '1px solid #ff5722', textAlign: 'center',
                                padding: 2, color: '#fff', backgroundColor: '#ff5722', width: '47%', margin: 5
                            }} onClick={() => {
                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: unitInfo.pymentDataResident,
                                        callBackUrl: window.location.href,
                                        unitInfo: props.location.state.unitInfo
                                    }
                                });

                            }}>افزایش بستانکاری </div>
                            {unitInfo.residentBestankari > 0 && false ? <div style={{
                                fontSize: 11, borderRadius: 10,
                                border: '1px solid #ff5722', textAlign: 'center',
                                padding: 2, color: '#ff5722', width: '47%', margin: 5
                            }}>{`عودت  ${formatMoney(unitInfo.residentBestankari)}ریال`}</div> : null}
                        </div>) : null

                    }


                </div>
                <GrayLine />
                <div className={`mb-3`} style={{ lineHeight: 1.8 }}>
                    <div className={`d-flex flex-row-reverse justify-content-between`}>
                        <div className={`grey mediumF fntBold text-right`}>مالک</div>
                        {unitInfo.pAllowEditResident ? <div style={{ width: '32%' }}>
                            <div onClick={() => {
                                if(unitInfo && unitInfo.owner!=null&&unitInfo.owner.hasApplication!=null)
                                setShowName(!unitInfo.owner.hasApplication);
                                changeResident(2);
                                // setName(props.location.state.unitInfo.owner ?props.location.state.unitInfo.owner.name:'')
                                // setNewMobile(props.location.state.unitInfo.owner ?props.location.state.unitInfo.owner.mobileNumber: null)

                            }} style={{ fontSize: 11, borderRadius: 10, border: '1px solid #ff5722', textAlign: 'center', padding: 2, color: '#ff5722' }}>
                                {unitInfo.owner ?

                                    ' تغییر مالک' :
                                    'ثبت مالک'}
                            </div>

                        </div> : null}


                    </div>



                    {unitInfo.owner && unitInfo.owner.profileImageUrl ?
                        <div className={`w-100 mr-0 `} style={{
                            width: 60, height: 60,
                            backgroundImage: "url(" + unitInfo.owner.profileImageUrl + ")",
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        }}>

                        </div>
                        : null}
                    {<div className={`d-flex flex-row-reverse justify-content-between mt-2 align-items-center`}>
                        <div className={`fntBold mediumF`} >{unitInfo.owner ? unitInfo.owner.name : 'ندارد'}</div>
                        <div className={`fntBold mediumF d-flex flex-row-reverse align-items-center`}>
                            {unitInfo.owner && unitInfo.owner.mobileNumber && unitInfo.owner.mobileNumber.indexOf('*') < 0 ? <a href={unitInfo.owner && unitInfo.owner.mobileNumber && unitInfo.owner.mobileNumber.indexOf('*') < 0 ?
                                ` tel: ${unitInfo.owner.mobileNumber}` : ''}>
                                <div >{unitInfo.owner ? unitInfo.owner.mobileNumber : null}</div>
                            </a> : <div >{unitInfo.owner ? unitInfo.owner.mobileNumber : null}</div>}
                            {unitInfo.owner ? <div style={{ width: 50 }}>
                                <Lottie options={unitInfo.owner ? (unitInfo.owner.hasApplication ? haveApp : haveNApp) : null} />
                            </div> : null}
                        </div>
                    </div>}

                    {unitInfo.owner && unitInfo.pymentDataOwner ? (unitInfo.pymentDataOwner.amountPayable > 0 ?
                        <div className={`d-flex justify-content-between  align-items-center flex-row-reverse  `}>
                            <div style={{ direction: 'rtl', textAlign: 'right', fontSize: 12 }}>بدهی مالک:</div>
                            <div className={`d-flex justify-content-between  align-items-center flex-row-reverse  pt-1 pb-1 pl-2`}
                                style={{ borderRadius: 15, backgroundColor: '#eaeaea', fontSize: 14, }}>
                                <div className={`rtl mr-1`}> {formatMoney(unitInfo.pymentDataOwner.amountPayable)}ریال</div>
                                <div className={'pr-1 pl-1 text-light mr-2 rtl'} onClick={() => {
                                    props.history.push({
                                        pathname: '/pay',
                                        state: {
                                            payData: unitInfo.pymentDataOwner,
                                            callBackUrl: window.location.href
                                        }
                                    });

                                }}
                                    style={{ display: 'block', backgroundColor: colors.app1, borderRadius: 15, fontSize: 12, cursor: 'pointer' }}>قابل پرداخت
                        </div>
                            </div>
                        </div> :
                        <div className={`d-flex justify-content-center`}>
                            <div className={`d-flex justify-content-center align-items-center`} style={{
                                fontSize: 11, borderRadius: 10,
                                border: '1px solid #ff5722', textAlign: 'center',
                                padding: 2, color: '#fff', backgroundColor: '#ff5722', width: '47%', margin: 5
                            }} onClick={() => {
                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: unitInfo.pymentDataOwner,
                                        callBackUrl: window.location.href
                                    }
                                });

                            }}>افزایش بستانکاری</div>

                            {unitInfo.ownerBestankari > 0 && false ? <div style={{
                                fontSize: 11, borderRadius: 10,
                                border: '1px solid #ff5722', textAlign: 'center',
                                padding: 2, color: '#ff5722', width: '47%', margin: 5
                            }}>{`عودت بستانکاری${formatMoney(unitInfo.ownerBestankari)}ریال`}</div> : null}

                        </div>) : null

                    }
                </div>
                <GrayLine />
                <div className={`mb-2`} style={{ lineHeight: 1.8 }}>



                    {/* <div className={`d-flex flex-row-reverse justify-content-start fntBold mediumF`}>
                        <div style={{ marginLeft: 5, direction: 'rtl' }}>
                            {(unitInfo.residentBedehkari + unitInfo.ownerBedehkari) - (unitInfo.residentBestankari + unitInfo.ownerBestankari) > 0 ?

                                'بدهکاری : '

                                :
                                ((unitInfo.residentBedehkari + unitInfo.ownerBedehkari) - (unitInfo.residentBestankari + unitInfo.ownerBestankari) < 0 ?
                                    'بستانکاری : '
                                    : null)
                            }

                        </div>
                        {(unitInfo.residentBedehkari + unitInfo.ownerBedehkari) - (unitInfo.residentBestankari + unitInfo.ownerBestankari) != 0 ? <div>{formatMoney(Math.abs((unitInfo.residentBedehkari + unitInfo.ownerBedehkari) - (unitInfo.residentBestankari + unitInfo.ownerBestankari)))}</div> : null}
                    </div> */}







                    {/* {<div className={`d-flex flex-start flex-row-reverse`}>
                        <div style={{ width: 15, }}><img src={checked} alt={'alt'} style={{ width: '100%', marginLeft: 5 }} /></div>
                        <div>انباری </div>
                    </div>} */}

                </div>
                {/* <GrayLine />
                <div className={`mb-2`} style={{ lineHeight: 1.8 }}>
                    <div className={`d-flex flex-row-reverse justify-content-between fntBold mediumF`}>
                        <div>شارژماهیانه</div>
                        <div>{formatMoney(5000000)}</div>
                    </div>
                    <div className={`d-flex flex-row-reverse justify-content-between fntBold mediumF`}>
                        <div>بدهکاری</div>
                        <div>{formatMoney(150000)}</div>
                    </div>
                </div> */}


            </> : null
        )
    }

    const renderTransactions = () => {
        return (unitInfo ?
            <div>
                <div className={`d-flex flex-row-reverse justify-content-between fntBold p-2 customBorderR mb-1`} style={{ backgroundColor: '#f7f7f7', fontSize: 12 }}>
                    <div> بدهکاری ساکن</div>
                    <div className={`rtl`}> {formatMoney(unitInfo.residentBedehkari)} ریال </div>
                </div>
                <div className={`d-flex flex-row-reverse justify-content-between fntBold p-2 customBorderR mb-1`} style={{ backgroundColor: '#f7f7f7', fontSize: 12 }}>
                    <div> بستانکاری ساکن</div>
                    <div className={`rtl`}> {formatMoney(unitInfo.residentBestankari)} ریال </div>
                </div>
                <div className={`d-flex flex-row-reverse justify-content-between fntBold p-2 customBorderR mb-1`} style={{ backgroundColor: '#f7f7f7', fontSize: 12 }}>
                    <div> بدهکاری مالک</div>
                    <div className={`rtl`}> {formatMoney(unitInfo.ownerBedehkari)} ریال </div>
                </div>
                <div className={`d-flex flex-row-reverse justify-content-between fntBold p-2 customBorderR mb-1`} style={{ backgroundColor: '#f7f7f7', fontSize: 12 }}>
                    <div> بستانکاری مالک</div>
                    <div className={`rtl`}> {formatMoney(unitInfo.ownerBestankari)} ریال </div>
                </div>

                {transactionList && transactionList.length > 0 ? <>
                    <SearchBar
                        click={() => {
                            if (search) {
                                setSearchText('');
                                setSearch(false);
                                console.log(unitInfo.id);
                                getTransactions(unitInfo.id)

                            } else {
                                setSearch(true);
                            }
                        }}
                        sortModule={true}
                        clickSort={() => {
                            setFilterModal(true);
                            setSearchText('');
                            setSearch(false);
                        }} />
                    {search ?
                        <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                            <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                                style={{
                                    padding: 5,
                                    border: ' 1px solid grey',
                                    borderRadius: 10
                                }}>
                                <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                    onKeyUp={event => {

                                        if (event.key === 'Enter') {
                                            getTransactions(unitInfo.id)
                                        }
                                    }}
                                    placeholder={'جستجو'}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);

                                    }} />
                                <div style={{ width: 15, }}>
                                    <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                        getTransactions(unitInfo.id)
                                    }} />
                                </div>

                            </div>
                            {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                        </div>
                        : null}
                    {transactionList.map((item, index) => {
                        return <div key={index} className={`mt-2 mb-2`}>
                            {/* <TransactionCard
                                subject={item.subject}
                                itemInfo={item}
                            /> */}

                            <FundCard
                                key={index} subject={item.subject}
                                date={item.dateCreated}
                                type={item.priceType}
                                category={item.accountingCategory ? item.accountingCategory.text : null}
                                amount={item.amount} category={item.accountingCategory ? item.accountingCategory.text : null}
                                change={item.priceType}
                                profileImage={item.creator.profileImageUrl}
                                creator={item.creator.name}
                                attachment={item.hasAttachment}
                                // click={() => {
                                //     props.history.push({
                                //         pathname: '/docView',
                                //         state: {
                                //             id: item.id,
                                //             backUrl: '/unit'
                                //         }
                                //     })
                                // }}
                                 />
                        </div>
                    })}

                </> : <EmptyList des={'تاکنون تراکنشی ثبت نشده‌است'} />}
            </div> : <EmptyList des={'تاکنون تراکنشی ثبت نشده‌است'} />
        )
    }



    const renderReceipt = () => {
        return (<div>
            { docs.length > 0 ? <SearchBar
                click={() => {
                    if (search) {
                        setSearchText('');
                        setSearch(false);
                        getReceiptOfUnt()


                    } else {
                        setSearch(true);
                    }
                }}
                sortModule={true}
                clickSort={() => {
                    setFilterModal(true);
                    setSearchText('');
                    setSearch(false);
                }} /> : null}
            {search ?
                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                        style={{
                            padding: 5,
                            border: ' 1px solid grey',
                            borderRadius: 10
                        }}>
                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                            onKeyUp={event => {

                                if (event.key === 'Enter') {
                                    getReceiptOfUnt()
                                }
                            }}
                            placeholder={'جستجو'}
                            onChange={(e) => {
                                setSearchText(e.target.value);

                            }} />
                        <div style={{ width: 15, }}>
                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                getReceiptOfUnt()

                            }} />
                        </div>

                    </div>

                </div>
                : null}

            <div className='d-flex align-items-center justify-content-end mt-3 mb-3 ' style={{
                width: '100%',
            }}>
                <div className='d-flex align-items-center mr-2 '>
                    {/* <img style={{ width: 15, height: 15, marginLeft: 10 }}
                                    src={BaseUrlImage + '/transaction.png'} alt={""} /> */}
                    <span style={{ color: colors.app7, fontSize: 12 }}>نمایش آرشیو</span>
                </div>
                {/* <div style={{width:50}}> */}
                <Switch
                    width={45}
                    height={23}
                    onChange={() => { setWithArchive(!withArchive) }
                    }
                    checked={withArchive}
                    className="react-switch"
                />
                {/* </div> */}
            </div>
            {docs.length > 0 ?
                <div>

                    {docs.map((item, index) => {
                        return <PayableCard key={index} onClick={() => {
                            props.history.push({
                                pathname: `/receiptDetail/${item.id}`,
                                // state: {
                                //     DocId: item.id
                                // }
                            })
                        }}
                            payable={item.pBtnPayment}
                            subject={item.subject}
                            date={item.dateCreated}
                            amount={item.amount}
                            usersRole={item.usersRole}
                            payableAmount={item.amountPayable}
                            click={(e) => {
                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: item.payData,
                                        callBackUrl: window.location.href
                                    }
                                });
                                e.stopPropagation();

                            }}
                            state={item.payStatus} />


                    })}
                </div>
                :
                <EmptyList des={'تاکنون فیشی ثبت نشده‌است'} />
            }

            {/* <PayableCard payable={false} state={3} title={'شارژ مهر'} /> */}
        </div>)
    }
    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
            setissending(false);
        }, 3000)
    }




    return (unitInfo && tabItemsList ?
        <Layout loaded={true} title={unitInfo.name}
            showMenu={false} isHome={false} btn={activeTab == 1 ? unitInfo.pAllowEditUnit : false}
            loading={loading}
            customBack={true}
            path={'/building'}
            btnName={'ویرایش'}
            click={() => {
                props.history.push({
                    pathname: '/building/unit/editUnit',
                    state: {
                        objInfo1: unitInfo
                    }
                });
            }}>
            <Toast text={errText}
                type={errType} />


            <FilterModal2 show={filterModal} amount={true} sortInc={true} click={() => {
                setFilterModal(false);
                setFilterData(null);
            }}
                submit={(filterData) => {
                    console.log('filterData', filterData);

                    setFilterData(filterData)
                    setFilterModal(false)
                }} />
            <Modal show={showModalChngOwner} btn={true} click={() => {
                setShowModalChngOwner(false);
                setNewMobile(null)
                if(unitInfo.owner!=null)
                setShowName(!unitInfo.owner.hasApplication);
                setName('');
                setChangeInfo([])
                setOptions([])

            }}>
                <div style={{ height: '60vh', overflow: 'auto', paddingTop: 5, paddingBottom: 5 }}>
                    <div className={`w-100 rtl mb-4`}>
                        <CpInput plachodler={'شماره همراه مالک'}
                            type={"phone"} maxLength={11}
                            value={newMobile}
                            onChange={(e) => {
                                setShowName(false);
                                setName('');
                                if (e.target.value.length <= 11)
                                    setNewMobile(e.target.value)
                            }}
                            onBlur={() => { getUserInfo() }} />
                        {showName ?
                            <CpInput plachodler={'نام و نام خانوادگی مالک'}
                                type={"text"} value={name} onChange={(e) => {
                                    setName(e.target.value)
                                 setUserSent(true)
                                }} /> : <CpInput disabled plachodler={'نام و نام خانوادگی مالک'}
                                    type={"text"} value={name} />}


                        {changeInfo && changeInfo.needQuestionIfChangUser ?

                            <div className={`rtl`}>
                                {<div style={{ textAlign: 'right', fontSize: 14, padding: 5 }}>{changeInfo.message}</div>}
                                {options && options.length > 0 && options.map((item, index) => {
                                    return (
                                        <Checkbox key={index}
                                            Type={'Radio'}
                                            textStle={{ fontSize: '12px', color: '#9e9e9e' }}
                                            onChange={(check) => {
                                                if (check) {
                                                    setSelectedOption(item.id)
                                                }

                                            }}
                                            Label={item.text} />
                                    )
                                })}
                            </div> : null
                        }


                        <div className={`mt-3 w-50 mx-auto`}>
                            <SBotton sending={issending} title={(userSent) ? 'ثبت تغییر' : 'استعلام کاربر'} click={() => {
                                if (userSent) {
                                    submitChangeOwner(2);
                                }


                            }} />

                        </div>
                    </div>
                </div>
            </Modal>
            <Modal show={showModalChngResident} btn={true} click={() => {
                setShowModalChngResident(false);
                setNewMobile(null)
                setShowName(false);
                setName('');
                setChangeInfo([])
                setOptions([])
            }}>
                <div style={{ height: '60vh', overflow: 'auto' }}>
                    <div className={`w-100 rtl mb-4`} >
                        <Checkbox checked={makeEmpty} textStle={{ fontSize: '12px', color: '#9e9e9e' }}
                            onChange={(check) => {


                                if (check) {
                                    setMakeEmpty(true);
                                    setName('');
                                    setNewMobile(null);
                                    setPersonsCount(0);

                                } else {
                                    setMakeEmpty(false)
                                }
                            }}
                            Label="واحد خالی شود " />
                    </div>
                    {!makeEmpty ? <> <CpInput plachodler={'شماره همراه ساکن'}
                        type={"phone"} maxLength={11}
                        value={newMobile}
                        onChange={(e) => {
                            setShowName(false);
                            setName('');
                            if (e.target.value.length <= 11)
                                setNewMobile(e.target.value)

                        }}
                        onBlur={() => { getUserInfo() }} />
                        {showName ?
                            <CpInput plachodler={'نام و نام خانوادگی ساکن'}
                                type={"text"} value={name} onChange={(e) => {

                                    setName(e.target.value)
                                }} /> :
                            <CpInput disabled plachodler={'نام و نام خانوادگی ساکن'}
                                type={"text"} value={name} />}
                        <CpInput plachodler={'تعداد ساکنین'} type={"number"} value={personsCount} onChange={(e) => {
                            setPersonsCount(e.target.value)
                        }} /> </> : null}

                    {changeInfo && changeInfo.needQuestionIfChangUser ?
                        <div className={`rtl`}>
                            {changeInfo.message ? <div style={{ textAlign: 'right', fontSize: 14, padding: 5 }}>{changeInfo.message}</div> : null}
                            {options && options.length > 0 && options.map((item, index) => {
                                console.log('>>>>>>>>>>', item);
                                return (
                                    <Checkbox key={index}
                                        Type={'Radio'}
                                        textStle={{ fontSize: '12px', color: '#9e9e9e' }}
                                        onChange={(check) => {
                                            if (check) {
                                                setSelectedOption(item.id)
                                            }

                                        }}
                                        Label={item.text} />
                                )
                            })}
                        </div> : null
                    }
                    <SBotton  sending={issending} title={'ثبت تغییر'} click={() => {
                        submitChange(1);

                    }} />
                </div>




            </Modal>
            <GrayLine />
            <div style={{ height: 'inherit' }}> <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`} >
                {tabItemsList.map(item => {
                    return (<TabBar title={item.title} length={tabItemsList.length} key={item.id} id={item.id} activeIndex={activeTab} click={() => {
                        setActiveTab(item.id);
                        // setRenderItem(item.content);
                    }} />)
                })}
            </div>
                <div className={`w-100 bb`} style={{ height: `calc(100% - 5rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>{renderItem}</div>
            </div>
        </Layout> : null
    )

}
export default withRouter(Unit);
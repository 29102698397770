import React from "react"
import classes from "./ProviderInfo.module.css"
import User from "../../Assets/myAsset/user.png"
import phone from "../../Assets/myAsset/phone.png"
import MapP from "../Map/Map"

const ProviderInfo = ({info})=>{
    const [showMore , setShowMore] = React.useState(true)
    console.log(info)
    return(
        <div className={`${classes.divider}`}>
            <div 
                className={`d-flex flex-column p-3 px-m-4 ${classes.card} h-100 overflow-auto`}
            >
                <div className="row flex-row rtl align-items-center">
                    <img
                        src={(!!info.image && !!info.image.path && info.image.path !== "")?info.image.path:User}
                        className={`${classes.img}`}
                    />
                    <span className="mx-2">
                        {
                            info.name
                        }
                    </span>
                </div>
                <div className="row flex-row rtl align-items-center px-2 text-right mt-3">
                    <span>
                        آدرس:
                    </span>
                    <span>
                        {
                            " " + (!!info.mapLocation.address ? info.mapLocation.address : " ---- " )+ " "
                        }
                    </span>
                </div>
                <div className={`${classes.mapWrapper}`}>
                    <MapP
                        containerStyle={{
                            width : "100%",
                            height : "200px"
                        }}
                        hideMarker={true}
                        userLocation={false}
                        lat={info.mapLocation.latitude}
                        lng={info.mapLocation.longitude}
                        coordinates={[ info.mapLocation.longitude , info.mapLocation.latitude]}
                        hideZoom={true}
                    />
                </div>
                {
                    !!info.tell &&
                    <div className="d-flex flex-row rtl mt-3" onClick={()=>window.open(`tel:${info.tell}`)}>
                        {
                            <>
                                <img
                                    src={phone}
                                    style={{width : 25 , height : 30}}
                                />
                                <span className="mx-2">
                                    شماره تماس : {info.tell}
                                </span>
                            </>
                        }
                    </div>
                }
            </div>
            <h5 className="text-right mt-2">
                {info.name}
            </h5>
            {
                !!info.description && info.description.length > 100 ?
                <>
                    <p className="text-right mt-2">
                        { 
                            showMore?info.description.slice(0 , 100) + "..." : info.description
                        }
                    </p>
                    <p 
                        className={`w-100 text-center ${classes.more}`} 
                        onClick={()=>setShowMore(!showMore)}
                    >
                        {
                            !showMore?"کمتر":"بیشتر"                           
                        }
                    </p>
                </>: 
                info.description ?
                <p className="text-right mt-2">
                    {
                        info.description 
                    }
                </p>:
                null
            }
        </div>
    )
}

export default ProviderInfo
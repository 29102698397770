import React from 'react';
import search from '../../Assets/myAsset/loupe.svg';
import sort from '../../Assets/myAsset/sort.svg'
import message from '../../Assets/myAsset/email.svg';
import pdf from "../../Assets/myAsset/pdf.svg";

const SearchBar = props => {
    return (
        <div className={`d-flex flex-row-reverse justify-content-between align-items-center pr-2 pl-2 mt-2 mb-2 w-100`}>
            <div style={{ width:  20, }} >
                 <img className={`w-100`} src={search} alt={'alt'} onClick={props.click} /> 
            </div>
            <div className='d-flex flex-row'>
                {props.sortModule? <div style={{ width: 20, }} onClick={props.clickSort}> <img className={`w-100`} src={sort} alt={'alt'} /></div> : null}
                {props.showMessage? <div style={{ width: 20, }} onClick={props.clickMessage}> <img className={`w-100 mx-2`} src={message} alt={'alt'} /></div> : null}
                {props.showPdf?  <div style={{ width: 20 }} onClick={props.clickPdf}> <img className={`w-100 mx-3`} src={pdf}  alt={'alt'}  />    </div>: null}
            </div>
        </div>
    )
}
export default SearchBar;


{/* <div className={`d-flex flex-row-reverse justify-content-between align-items-center pr-2 pl-2 mt-2 mb-2 w-100`}>
<div style={{ width: props.search ? '' : 20, }} >
    {!props.search ? <img className={`w-100`} src={search} alt={'alt'} onClick={props.click} /> :
        <div className={`d-flex flex-row-reverse justify-content-between  w-100`} 
        style={{padding: 5,
            border:' 1px solid grey',
            borderRadius: 10}}>
            <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }} placeholder={'جستجو'} />
            <div style={{ width:  15, }}>
                <img className={`w-100`} src={search} alt={'alt'} onClick={props.onSearch} />
            </div>

        </div>}
</div>
<div style={{ width: 20, }} onClick={props.clickSort}> <img className={`w-100`} src={sort} alt={'alt'} /></div>


</div> */}
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../../../Components/Layout/Layout';
import searchImg from '../../../Assets/myAsset/loupe.svg';
import user from '../../../Assets/myAsset/user.png'
import { ApiService } from '../../../value';
import Toast from '../../../Components/Toast/Toast';
import EmptyList from '../../../Components/EmptyList/EmptyList';
import mychats from '../../../Assets/myAsset/chat1.svg'
import contacts from '../../../Assets/myAsset/contacts (1).svg';
import back from '../../../Assets/myAsset/left-arrow.svg';
import { Badge, createMuiTheme, ThemeProvider } from '@material-ui/core';











const ChatContainer = props => {

    const theme = createMuiTheme({
        typography: {
          fontFamily: [
            'iranyekan',
            'Arial',
            'sans-serif'
          ].join(','),
        }
      });

    const [loading, setLoading] = useState(true);
    const [chatList, setChatList] = useState([]);
    const [contactList, setCantactList] = useState([]);
    const [contactListLoaded, setContactListLoaded] = useState(false);

    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [activeTab, setActiveTab] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [Width, setW] = useState(null);

    useEffect(() => {
        getChatList();

    }, [props.badgCount,props.deleteMessage])


    useEffect(() => {
        setW(document.getElementById('heed').clientWidth);
        getChatList();
        getContactList()
    }, [])

    useEffect(() => {
        checkLoadALL()
    }, [chatList, contactListLoaded])


    const getChatList = () => {
        ApiService('Get', '/Chat/MyChats')
            .then(res => {
                // console.log(res);
                if (res.data.result) {
                    setChatList(res.data.data);
                    let unreadCount = 0
                    res.data.data.forEach(element => {
                        unreadCount += element.unReadCount;
                    });
                    props.setBadge(unreadCount)
                    checkLoadALL()

                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr()
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                clearErr();
                return
            })

    }

    const getContactList = () => {
        let data = {
            nameLike: searchText,
            pageSize: 1000,
            pageNumber: 0
        }
        ApiService('Post', '/Chat/Contacts', data)
            .then(res => {
                if (res.data.result) {
                    setCantactList(res.data.data);
                    setContactListLoaded(true)
                    checkLoadALL()

                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr()
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false)
                clearErr();
                return
            })

    }
    const renderLists = () => {
        if (activeTab == 1 && chatList && chatList.length > 0) {
            return (chatList.map((item, index) => {
                return (
                    <div key={index} className={`borderShadow customBorderR p-2 mb-3`}
                        onClick={() => {
                            props.history.push(`/chats/${item._id}`)
                        }}
                        style={{
                            backgroundColor: item._id == '11111111-1111-1111-1111-111111111111' ? '#5f5d5d' : '#f7f7f7',
                            color: item._id == '11111111-1111-1111-1111-111111111111' ? 'white' : 'black'
                        }}>
                        <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 `}>
                            <div className={`d-flex w-100 flex-row-reverse align-items-center `}>
                                <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                                    <img src={item.avatar ? item.avatar : user} style={{ borderRadius: '50%' }} className={`w-100 h-100`} alt={''} />
                                </div>
                                <div style={{ lineHeight: '2' }}>
                                    <div className={` mr-2 text-right rtl`} style={{ fontSize: 12 }}> {item.name}</div>
                                    {item.message && item.message.length > 0 ? <div className={` mr-2 text-right rtl`}
                                        style={{ fontSize: 14, color: item._id == '11111111-1111-1111-1111-111111111111' ? '#d0d0d0' : '#9a9a9e' }}>
                                        {item.message.substring(0, 25)}...
                                   </div> : null}
                                </div>
                            </div>
                            <div className={`pl-4`}>
                            <ThemeProvider theme={theme}>
                                <Badge badgeContent={item.unReadCount && item.unReadCount > 0 ? item.unReadCount : null} max={99} color="error"  >
                                </Badge>
                            </ThemeProvider>
                            </div>

                            {/* <div className={`d-flex flex-row-reverse align-items-center `}>
                                {item.unReadCount && item.unReadCount > 0 ?
                                    <div style={{
                                        borderRadius: 10,
                                        backgroundColor: 'salmon', fontSize: 10,
                                        padding: 5, width: 17, height: 17,
                                        textAlign: 'center'
                                    }}>{item.unReadCount}</div> : null}
                            </div> */}
                        </div>
                    </div>
                )
            }))
        } else {
            return (contactList.map((item, index) => {
                return (
                    <div key={index} className={`borderShadow customBorderR p-2 mb-3`}
                        onClick={() => {
                            props.history.push(`/chats/${item._id}`)
                        }}
                        style={{
                            backgroundColor: item._id == '11111111-1111-1111-1111-111111111111' ? '#5f5d5d' : '#f7f7f7',
                            color: item._id == '11111111-1111-1111-1111-111111111111' ? 'white' : 'black'
                        }}>
                        <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 `}>
                            <div className={`d-flex w-100 flex-row-reverse align-items-center `}>
                                <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                                    <img src={item.avatar ? item.avatar : user} style={{ borderRadius: '50%' }} className={`w-100 h-100`} alt={''} />
                                </div>
                                <div style={{ lineHeight: '2' }}>
                                    <div className={` mr-2 text-right rtl`} style={{ fontSize: 12 }}> {item.name}</div>
                                    {item.message && item.message.length > 0 ? <div className={` mr-2 text-right rtl`}
                                        style={{ fontSize: 14, color: item._id == '11111111-1111-1111-1111-111111111111' ? '#d0d0d0' : '#9a9a9e' }}>
                                        {item.message.substring(0, 25)}...
                                   </div> : null}
                                </div>
                            </div>
                            <div className={`d-flex flex-row-reverse align-items-center `}>
                                {/* <Date font={11} date={'15:00  98/05/05'} color={'#fff'} /> */}
                                {item.unReadCount && item.unReadCount > 0 ?
                                    <div style={{
                                        borderRadius: 10,
                                        backgroundColor: 'salmon', fontSize: 10,
                                        padding: 5, width: 17, height: 17,
                                        textAlign: 'center'
                                    }}>{item.unReadCount}</div> : null}
                            </div>
                        </div>
                    </div>
                )
            }))

        }

    }

    const checkLoadALL = () => {
        if (chatList.length > 0 && contactListLoaded) {
            setLoading(false)
        }
    }

    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    return (
        <div id={'heed'} style={{ maxWidth: 450, margin: '0 auto', position: 'relative' }}>
            <div className={``}
                style={{}}>
                <div className={`d-flex  justify-content-between align-items-center `} style={{ height: 50, padding: '4% 5% 0%' }}>
                    <div className={`mr-2`} style={{ width: '20px' }} onClick={() => {
                        props.history.push('/')
                    }}>
                        <img className={`w-100 bold h-100`} src={back} alt={'بازگشت'} />
                    </div>
                    <div className={'w-100'} style={{ fontSize: 14, textAlign: 'center' }}> {activeTab == 1 ? 'چت‌های من ' : 'مخاطب‌ها'} </div>
                </div>

                <div className={`p-2`}>
                    <div style={{ height: `calc( 100vh - 8rem )`, overflow: 'auto', paddingBottom: '20px', paddingLeft:'2%', paddingRight:'2%' }}>
                        {renderLists()}
                    </div>


                </div>
                <div className={`d-flex justify-content-around align-items-center  `} style={{ width: Width ? Width : 400, position: 'fixed', bottom: 0, height: '10%', backgroundColor: '#fff' }}>
                    <div className={`text-center`} onClick={() => {
                        setActiveTab(1)
                    }}>
                        <div style={{ width: 25, margin: '0 auto' }}>
                            <img src={mychats} style={{ width: '100%' }} />
                        </div>
                        <div style={{ fontSize: 11, direction: 'rtl', color: 'grey', textAlign: 'center', width: '100%' }}>
                            چت‌های من
                        </div>
                    </div>
                    <div className={`text-center`} onClick={() => {
                        setActiveTab(2)
                    }}>
                        <div style={{ width: 25, margin: '0 auto' }}>
                            <img src={contacts} style={{ width: '100%' }} />
                        </div>
                        <div style={{ fontSize: 11, direction: 'rtl', color: 'grey', textAlign: 'center', width: '100%' }}>
                            مخاطب‌ها
                        </div>
                    </div>

                </div>



            </div>

        </div>
    )
}
export default withRouter(ChatContainer);




{/* { activeTab == 1 && chatList && chatList.length > 0 ? chatList.map((item, index) => {
                    return (
                        <div key={index} className={`borderShadow customBorderR p-2 mb-3`}
                            onClick={() => {
                                props.history.push(`/chats/${item._id}`)
                            }}
                            style={{
                                backgroundColor: item._id == '11111111-1111-1111-1111-111111111111' ? '#5f5d5d' : '#f7f7f7',
                                color: item._id == '11111111-1111-1111-1111-111111111111' ? 'white' : 'black'
                            }}>
                            <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 `}>
                                <div className={`d-flex w-100 flex-row-reverse align-items-center `}>
                                    <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                                        <img src={item.avatar ? item.avatar : user} style={{ borderRadius: '50%' }} className={`w-100 h-100`} alt={''} />
                                    </div>
                                    <div style={{ lineHeight: '2' }}>
                                        <div className={` mr-2 text-right rtl`} style={{ fontSize: 12 }}> {item.name}</div>
                                        {item.message && item.message.length > 0 ? <div className={` mr-2 text-right rtl`}
                                            style={{ fontSize: 14, color: item._id == '11111111-1111-1111-1111-111111111111' ? '#d0d0d0' : '#9a9a9e' }}>
                                            {item.message.substring(0, 25)}...
                                       </div> : null}
                                    </div>
                                </div>
                                <div className={`d-flex flex-row-reverse align-items-center `}>
                                    {item.unReadCount && item.unReadCount > 0 ?
                                        <div style={{
                                            borderRadius: 10,
                                            backgroundColor: 'salmon', fontSize: 10,
                                            padding: 5, width: 17, height: 17,
                                            textAlign: 'center'
                                        }}>{item.unReadCount}</div> : null}
                                </div>
                            </div>
                        </div>
                    )
                })
                    : 
                    
                     <EmptyList des={'لیست جت شما خالی است'} />
                    }


                     { activeTab == 2 && contactList && contactList.length > 0 ? contactList.map((item, index) => {
                    return (
                        <div key={index} className={`borderShadow customBorderR p-2 mb-3`}
                            onClick={() => {
                                props.history.push(`/chats/${item._id}`)
                            }}
                            style={{
                                backgroundColor: item._id == '11111111-1111-1111-1111-111111111111' ? '#5f5d5d' : '#f7f7f7',
                                color: item._id == '11111111-1111-1111-1111-111111111111' ? 'white' : 'black'
                            }}>
                            <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 `}>
                                <div className={`d-flex w-100 flex-row-reverse align-items-center `}>
                                    <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                                        <img src={item.avatar ? item.avatar : user} style={{ borderRadius: '50%' }} className={`w-100 h-100`} alt={''} />
                                    </div>
                                    <div style={{ lineHeight: '2' }}>
                                        <div className={` mr-2 text-right rtl`} style={{ fontSize: 12 }}> {item.name}</div>
                                        {item.message && item.message.length > 0 ? <div className={` mr-2 text-right rtl`}
                                            style={{ fontSize: 14, color: item._id == '11111111-1111-1111-1111-111111111111' ? '#d0d0d0' : '#9a9a9e' }}>
                                            {item.message.substring(0, 25)}...
                                       </div> : null}
                                    </div>
                                </div>
                                <div className={`d-flex flex-row-reverse align-items-center `}>
                                    {item.unReadCount && item.unReadCount > 0 ?
                                        <div style={{
                                            borderRadius: 10,
                                            backgroundColor: 'salmon', fontSize: 10,
                                            padding: 5, width: 17, height: 17,
                                            textAlign: 'center'
                                        }}>{item.unReadCount}</div> : null}
                                </div>
                            </div>
                        </div>
                    )
                })
                    :
                      <EmptyList des={'لیست مخاطبان شما خالی است'} />
                     } */}



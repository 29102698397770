import React from "react";
import { useHistory } from "react-router-dom";
import { serviceAxios } from "../../axios";
import SBotton from "../Buttons/SBotton/SBotton";

const OpenOrder= ({orders , setError , getDashbordData})=>{
    const history = useHistory()
    const [sending , setSending] = React.useState(false)

    const sendopenreq = (orderId)=>{
        setSending(true)
        serviceAxios.get(
            `/Order/OpenDoor/${orderId}`,

        ).then(res=>{
            console.log(res.data)
            setSending(false)
            setError("تایید شد" , "suc")

        }).catch(error =>{
            setSending(false)
            setError("خطایی رخ داده است. لطفا مجددا تلاش کنید" , "err")
        })
    }

    return orders.map(order =>{
        return(
            <div className="shadow-card mt-4 mb-3 p-1" onClick={()=>history.push(`/service/orderDetails/${order.id}`)} key={order.id.toString()}>
                <div className="d-flex flex-column w-100 p-1 px-2">
                    <div className="d-flex flex-row justify-content-between rtl">
                        <span>
                            {
                                `سفارش ${order.titel}  به شروع شد`
                            }
                        </span>
                        <span>
                            {
                                order.reservationDate
                            }
                        </span>
                    </div>
                    {/*<div className="d-flex flex-row rtl mt-1">*/}
                    {/*    <span>*/}
                    {/*        {*/}
                    {/*            `برای ورود  "${order.provider.name}" امتیاز دهید`*/}
                    {/*        }*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    <div className="d-flex flex-row rtl">
                        <div className="d-flex flex-grow-1 m-1">
                            <SBotton
                                title="درخواست ورود"
                                click={(e)=>{ e.stopPropagation(); sendopenreq(order.id);}}
                                gradian={true}
                                sending={sending}
                            />
                        </div>

                    </div>
                </div>
            </div>
        )
    })
}


export default OpenOrder

import React, { useEffect, useState } from 'react';
import SBotton from '../../Components/Buttons/SBotton/SBotton';
import Date from '../../Components/Date/Date';
import Doer from '../../Components/Doer/Doer';
import Layout from '../../Components/Layout/Layout';

import sample from '../../Assets/myAsset/daily.png';
import { withRouter } from 'react-router-dom';
import { ApiService } from '../../value';
import Toast from '../../Components/Toast/Toast';
import { grey } from '@material-ui/core/colors';
import GrayLine from '../../Components/GrayLine/GrayLine';

const Event = props => {

    const [event, setEvent] = useState(null);
    const [eventID, setEventID] = useState(null);
    const [buildingID, setBuildingID] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');

    useEffect(() => {

        if (props.location.state && props.location.state.id && localStorage.getItem('id')) {
            setEventID(props.location.state.id);
            setBuildingID(localStorage.getItem('id'))
            getDetailOfEvent(props.location.state.id, localStorage.getItem('id'))
        } else {
            props.history.push('/news')
        }

    }, []);
    const getDetailOfEvent = (id, buildingId) => {
        ApiService('Get', `/BuildingEvent/Detail/${buildingId}/${id}`)
            .then(response => {
                if (response.data.result) {
                    setEvent(response.data.data);
                    console.log(response.data.data);
                    setLoading(false);
                    isSeen(id, buildingId);
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    // setTab(false);
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }
    const isSeen = (id, buildingId) => {
        ApiService('Get', `/BuildingEvent/Seen/${buildingId}/${id}`)
            .then(res => {
                console.log('seeeeeeeeeen', res);
            })

    }


    const deleteEvent = (id, buildingId) => {
        setLoading(true);
        ApiService('Get', `/BuildingEvent/Delete/${buildingId}/${id}`)
            .then(response => {
                if (response.data.result) {
                    setLoading(false);
                    setErrText('رویداد با موفقیت حذف شد.');
                    setErrType('suc');

                    setTimeout(() => {
                        props.history.push('/news')
                    }, 2000)


                }else{
                    setLoading(false);
                    setErrText(response.data.message);
                    setErrType('suc');
                    clearErr()

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }
    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }


    return (

        event ?


            <Layout loaded={true} title={event.title}
                isHome={false} showMenu={false}
                loading={loading}
                customBack={true}
                path={'/news'}
            >
                <Toast
                    text={errText}
                    type={errType}
                />
                <div style={{ height: 'inherit' }}>
                    <div style={{ height: `calc(100% - 9rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>
                        <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 mb-3`}>
                            <Doer creator={event.creator.name} profileImage={event.creator.profileImageUrl} />
                            <div><Date date={event.startDate} font={12} /></div>
                        </div>

                        <div className={`d-flex w-100 d-flex justify-content-end  mt-2   `}>
                            <div className={`  mediumF mt-2  `}>{event.startTime}</div>
                            <div className={` fntBold mediumF mt-2 rtl ml-2  `}>ساعت شروع:</div>

                        </div>


                        <div className={`d-flex w-100 d-flex justify-content-end  mt-2 mb-2  `}>
                            <div className={`  mediumF mt-2  `}>{event.endTime}</div>
                            <div className={` fntBold mediumF mt-2 rtl  ml-2  `}>ساعت پایان:</div>

                        </div>

                        <GrayLine />
                        <div >
                            <div className={` fntBold mediumF mt-2  text-right`}>توضیحات</div>
                            <div className={`grey mediumF rtl  p-2 text-right`} style={{ lineHeight: 1.8, maxHeight: '25%', overflow: 'auto', whiteSpace: "pre-wrap" }}>
                                {event.description}
                            </div>
                            {event.filePath ? <div className={`rtl`}>
                                <div className={` `} onClick={(e) => {
                                                window.open(event.filePath);
                                                e.stopPropagation();
                                            }} style={{
                                    width: 110, height: 80,
                                    backgroundImage: "url(" + event.filePath + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                            </div> : null}

                            {event.reports.length > 0 ?

                                <>
                                    <div className={` fntBold mediumF mt-2 mb-2 text-right`}>گزارش</div>



                                    <>
                                        <div className={` text-center`} style={{ width: '60%', marginLeft: '20%' }}>

                                            {event.reports && event.reports[0] && event.reports[0].filePaths ? <div className={`rtl`}>
                                                <div className={` `} onClick={(e) => {
                                                window.open(event.reports[0].filePaths[0].path);
                                                e.stopPropagation();
                                            }} style={{
                                                    width: 110, height: 80,
                                                    backgroundImage: "url(" + event.reports[0].filePaths[0].path + ")",
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat'
                                                }}>
                                                </div>
                                            </div> : null}



                                            {/* <img src={event.reports[0].filePaths && event.reports[0].filePaths[0].path} alt={''} style={{ width: '100%', margin: '0 auto' }} /> */}
                                        </div>
                                        <div className={`grey mediumF rtl  p-2 text-right`} style={{ lineHeight: 1.8, maxHeight: '25%', overflow: 'auto' }}>
                                            {event.reports[0].description}
                                        </div>
                                    </>


                                </> :
                                null
                            }
                        </div>
                        <div className={`w-75 mx-auto position-absolute  `} style={{ bottom: 0, left: '12.5%' }}>
                            {event.pBtnEdit ? <SBotton title={'ویرایش رویداد'} gradian={true} click={() => {

                                props.history.push({
                                    pathname: '/addEvent',
                                    state: {
                                        event: event,
                                    }
                                });
                            }} /> : null}
                            {event.pBtnEdit ? <SBotton title={event.reports.length > 0 ? 'ویرایش گزارش' : 'ثبت گزارش'} gradian={true} click={() => {
                                if (event.reports.length > 0) {
                                    console.log(event);
                                    props.history.push({
                                        pathname: '/addReport',
                                        state: {
                                            event: event,
                                            eventId: eventID,
                                            buldingId: buildingID
                                        }
                                    });
                                } else {
                                    props.history.push({
                                        pathname: '/addReport',
                                        state: {
                                            eventId: eventID,
                                            buldingId: buildingID
                                        }
                                    });
                                }

                            }} /> : null}
                            {event.pBtnDelete ? <SBotton title={'حذف'} click={() => {
                                deleteEvent(eventID, buildingID);
                            }} /> : null}
                        </div>



                    </div>
                </div>

            </Layout>
            : null
    )
}
export default withRouter(Event);
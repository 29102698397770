import React, { useState } from 'react';

import Layout from '../../Components/Layout/Layout';

// import GradientButton from '../Buttons/GradientButton/GradientButton';

const Support = props => {

    const [loading, setLoading] = useState(false);

  return (

    <Layout title={' پشتیبانی '} isHome={false}
     loaded={true}  loading={loading} 
     displayMenu={!loading} showMenu={true}
     customBack={true}
     path={'/'} >

    </Layout>
   
  );
}

export default Support;
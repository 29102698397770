import React from 'react';
import '../../App.css';
import classes from './Card.module.css';

const Card = props => {
  return (
    <div
      onClick={props.onClick}
      style={{ height: props.height, width: props.width }}
      className={` d-flex justify-content-center align-Items-center borderShadow customBorderR mt-1 mb-3 ${classes.container}`}
    >
      {props.children}
    </div>
  );
};

export default Card;

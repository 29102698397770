import React from 'react';
import del from '../../../Assets/myAsset/delete.svg';
import edit from '../../../Assets/myAsset/edit.svg'

import { colors, formatMoney } from '../../../value';
import Card from '../../Card/Card';
import Date from '../../Date/Date';
import light from '../../../Assets/myAsset/unnamed.svg';
import gas from '../../../Assets/myAsset/adminlogo.svg'
import water from '../../../Assets/myAsset/ab.svg'
import SBotton from "../../Buttons/SBotton/SBotton";


const BillCard = props => {
    return (
        <Card width={'100%'}    onClick={props.item.pBtnEdit?props.edit:null } >
            <div className={`w-100 p-2`} >

                <div className={`d-flex justify-content-between  flex-row-reverse w-100 align-items-center`} >
                    <div>
                         <div style={{ width: '32px' }} >
                        <img src={props.item.billType==0? water : props.item.billType==1? gas :light } style={{ width: '100%' }} />
                    </div>
                    </div>
                    {props.item.autoPay ?  <div style={{fontSize:12, backgroundColor:'#008800', borderRadius:12, color:'#fff', padding:5}}>
                        اتوماتیک
                    </div> : null}
                    <div className={`d-flex justify-content-end  flex-row-reverse  align-items-center`} >


                        {props.item.pBtnEdit?  <SBotton title={'به روز رسانی'}  click={props.update} />: null}
                    </div>
                </div>

<div className={`d-flex justify-content-between  flex-row-reverse w-100 align-items-center`}>
                <div className={`mt-2`} style={{ fontSize: 14, textAlign: 'right', direction: 'rtl' }}>{props.item.subject}</div>
                <div className={`mt-2`} style={{ fontSize: 14, textAlign: 'left', direction: 'rtl' }}>{props.item.billKey}</div>
</div>
                <div className={`d-flex justify-content-between flex-row-reverse align-items-center mt-2`} >
                   { <div className={`d-flex justify-content-between flex-row-reverse text-right`}>
                        <div style={{ fontSize: 11, textAlign: 'right', direction: 'rtl' }}> {props.item.pBtnPayment  ?`درج:` : 'پرداخت'}</div>
                        <Date font={11} date={props.item.lastUpdateDate} />
                    </div>}

                    {props.item.pBtnPayment  ?
                    <div className={`d-flex justify-content-between  align-items-center flex-row-reverse  pt-1 pb-1 pl-2`}
                        style={{ borderRadius: 15, backgroundColor: '#eaeaea', fontSize: 14, }}>
                        <div className={`rtl mr-1`}> {formatMoney(props.item.paymentData.amountPayable)}ریال</div>
                        <div className={'p-1 text-light mr-1 rtl'} onClick={props.click}
                            style={{ display: 'block', backgroundColor: colors.app1, borderRadius: 15, fontSize: 11, cursor: 'pointer' }}>قابل پرداخت
                        </div>
                    </div>
                     : null} 

                </div>

            </div>


        </Card>
    )
}

export default BillCard;
import React, { Component } from 'react';
import axios from 'axios';

export default class CLogin extends Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {
        
        if(this.props.match.params.ref){
            localStorage.clear();
            console.log(this.props.match.params.ref);
             this.login()
        }else{
            this.props.history.push('/login')
        }
    }

    //    qlHQL7h1HIjiL8P3pnOFyoGbduQ_X6MuACxuf7hPc98

    login(){
        let data = `grant_type=refresh_token&refresh_token=${this.props.match.params.ref}&client_id=HomTickMobileClient&client_secret=4f5b555b83f844ef8d8022646a6fb4c2-HomTickMobileSecret`;
        console.log('data', data);
        axios.post('https://auth3.homtick.com/connect/token', data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                if (res.data) {
                    localStorage.setItem('token', res.data.access_token);
                    localStorage.setItem('ref', res.data.refresh_token);
                    localStorage.setItem('codeTime', new Date().getTime());  
                                     window.location.href='/';                  
                    // props.history.push({
                    //     pathname: '/',
                    //     state: {
                    //         mobileNum: mobileNumber,
                    //         deviceCode: uuid
                    //     }
                    // });
                } else {
                    localStorage.clear();
                }
            })
            .catch(err => {
                if (err.response.status === 401 || err.response.status === 400) {
                    localStorage.clear();
                    window.location.href = '/login'
                }

            });
    }

    


    render() {
        return (
            <div></div>
        )
    }
}
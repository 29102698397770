import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout/Layout';
import settings from '../../Assets/myAsset/settings.svg'
import AddBotton from '../../Components/Buttons/AddBotton/AddBotton';
import NewSelect from '../../Components/NewSelect/NewSelect';
import salary from '../../Assets/myAsset/salary.svg'
import SingleSelect from '../../Components/NewSelect/SingleSelect';
import TabBar from '../../Components/TabBar/TabBar';
import SearchBar from '../../Components/SearchBar/SearchBar';
import FundCard from '../../Components/Cards/FundCard/FundCard';
import Modal from '../../Components/Modal/Modal';
import CpInput from '../../Components/Input/Input';
import MySelect from '../../Components/MySelect/MySelect';
import SBotton from '../../Components/Buttons/SBotton/SBotton';
import { ApiService, downloadPdf, formatMoney } from '../../value';
import { withRouter } from 'react-router-dom';
import Toast from '../../Components/Toast/Toast';
import dec from '../../Assets/myAsset/bleachRed.svg';
import inc from '../../Assets/myAsset/bleachGrn.svg';
import searchImg from '../../Assets/myAsset/loupe.svg';
import EmptyList from '../../Components/EmptyList/EmptyList';
import FilterModal2 from '../../Components/Modal/FilterModal2/FilterModal2';
// import MyChart from '../../Components/MyChart1/MyChart1';
import MyChart from '../../Components/MyChart1/MyChart1';
import RequestCard from '../../Components/Cards/RequestCard/RequestCard';
import NoAccess from '../../Components/NoAccess/NoAccess';
import moneybag from '../../Assets/myAsset/money-bag.png';
import pdf from '../../Assets/myAsset/pdf.svg'








const tabItems = [


    {
        id: 1,
        title: 'نمودار',
    },
    {
        id: 2,
        title: 'تراکنش‌ها',
    },
    {
        id: 3,
        title: 'درخواست وجه‌ها',
    },


];

const tabItemsTrans = [
    {
        id: 1,
        title: 'نمودار',
    },
    {
        id: 2,
        title: 'تراکنش‌ها',
    },


];



const tabItemsCha = [

    {
        id: 1,
        title: 'نمودار',
    },

];


const Fund = props => {

    const [loading, setLoading] = useState(true);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [showModalFund, setShowModalFund] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [renderItem, setRenderItem] = useState(null);
    const [tabItemsList, setTabItemsList] = useState(tabItems)
    const [buildingList, setBuildingList] = useState([]);
    const [buildingDesk, setBuildingDesk] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);
    const [buildingInfo, setBuildingInfo] = useState(null);
    const [wallet, setWallet] = useState(0);
    const [cashDesk, setCashDesk] = useState(0);

    const [transactionList, setTransactionList] = useState([]);
    const [filterModal, setFilterModal] = useState(false);


    const [search, setSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filterData, setFilterData] = useState(null);

    const [chartData, setChartData] = useState(null);



    const [permissions, setPermissions] = useState(null);
    const [Requests, setRequests] = useState([]);


    const [dwnldPdf, setDwnld] = useState(false)







    useEffect(() => {
        if (activeTab == 1) {
            setRenderItem(renderCharts())

        } else if (activeTab == 2) {
            setRenderItem(renderTransactions())

        } else if (activeTab == 3) {
            setRenderItem(renderRequests())
        } else {
            return function cleanup() { }
        }
    }, [activeTab, search, searchText, chartData, tabItemsList, Requests, transactionList]);

    useEffect(() => {
        if (localStorage.getItem('id')) {
            getMyBuildingList();
        } else {
            props.history.push('/');


        }
    }, []);

    useEffect(() => {
         getTransactions();
        //getChart();
        //getRequests();
    }, [filterData, filterModal, search]);


    useEffect(() => {
        if(dwnldPdf)
       { downloadPdfSample();}
      
   }, [dwnldPdf]);



    const getMyBuildingList = () => {
        setLoading(true);
        ApiService('Get', `/AccRequest/BuildingCashDesks/${localStorage.getItem('id')}`)
            .then(response => {
                if (response.data.result) {
                    if (response.data.data) {
                        setBuildingList(response.data.data.buildings);
                        let currentbuilding;
                        if (response.data.data.currentBuilding) {
                            setSelectedBulding(response.data.data.currentBuilding);
                            setCashDesk(response.data.data.filterCashDesks)
                            currentbuilding = response.data.data.currentBuilding;
                            setPermissions(response.data.data);
                            if (!response.data.data.pTabTransactions && !response.data.data.pTabCharts)
                                setLoading(false)
                            else {
                                if (response.data.data.pTabTransactions) {
                                    getTransactions();
                                    setTabItemsList(tabItems)
                                }

                                else {

                                    if (response.data.data.pTabCharts) {
                                        getChart();
                                        setTabItemsList(tabItemsCha)
                                    }

                                }
                            }

                            setBuildingDesk(response.data.data.list);
                            let wallet = 0
                            for (let i = 0; i < response.data.data.list.length; i++) {
                                if (response.data.data.list[i].isOnline) {
                                    wallet += response.data.data.list[i].balance;
                                }
                            }
                            setWallet(wallet);

                        } else {

                            setLoading(false)
                            setPermissions({ gholi: true });

                        }
                    }
                }

                else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    // setTab(false);
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }



    const downloadPdfSample = () => {
        setLoading(true)

         let data = {
            cashDeskId: 0,
            nameLike: searchText,
            pageSize: 1000,
            pageNumber: 0,
            sortIsDesc: true,
            // priceType:0        0 hazine ------ 1 daramad
        }

        if (filterData) {
            if(filterData.priceType0==0  && filterData.priceType1!=1 ){
                data.priceType = filterData.priceType0
             }else if(filterData.priceType0!=0  && filterData.priceType1==1){
                data.priceType = filterData.priceType1
             }

             if(filterData.cashDeskId){
                 console.log('filterData.cashDeskId', filterData.cashDeskId);
                data.cashDeskId = filterData.cashDeskId

             }
            
            data.sortIsDesc = filterData.sortIsDesc;
            data.sortByType = filterData.sortByType;
            data.startAmount = filterData.startAmount;
            data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }
        console.log(JSON.stringify( data).replaceAll('"','\"'))

        downloadPdf('post',`/AccRequest/ListDocuments/${localStorage.getItem('id')}/export`, data)
        .then(res => {
            // console.log(res);
            setDwnld(false);
            setLoading(false);
        })
        .catch(err=>{
            setLoading(false);
            setErrText('درخواست با خطا مواجه شد ');
            setErrType('err');
            clearErr();


        })
        // let data = {
        //     cashDeskId: 0,
        //     nameLike: searchText,
        //     pageSize: 1000,
        //     pageNumber: 0,
        //     sortIsDesc: true,
        //     // priceType:0        0 hazine ------ 1 daramad
        // }
        // axios.post(`https://api2.homtick.com/api/V1/AccRequest/ListDocuments/${localStorage.getItem('id')}/export`, data, {
        //     headers: {

        //         'Authorization': `bearer ${localStorage.getItem('token')}`,
        //         'Content-Type': 'application/json'

        //     },
        //     responseType: 'blob',
        // })
        //     .then(response => {
        //         console.log(response.data);
        //         const url = window.URL.createObjectURL(new Blob([response.data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'report.pdf');
        //         document.body.appendChild(link);
        //         link.click();
                
        //     })

    };

    const getTransactions = () => {
        setLoading(true);
        let data = {
            cashDeskId: 0,
            nameLike: searchText,
            pageSize: 1000,
            pageNumber: 0,
            sortIsDesc: true,
            // priceType:0        0 hazine ------ 1 daramad
        }
        if (filterData) {
            if(filterData.priceType0==0  && filterData.priceType1!=1 ){
                data.priceType = filterData.priceType0
             }else if(filterData.priceType0!=0  && filterData.priceType1==1){
                data.priceType = filterData.priceType1
             }

             if(filterData.cashDeskId){
                 console.log('filterData.cashDeskId', filterData.cashDeskId);
                data.cashDeskId = filterData.cashDeskId

             }
            
            data.sortIsDesc = filterData.sortIsDesc;
            data.sortByType = filterData.sortByType;
            data.startAmount = filterData.startAmount;
            data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }

        ApiService('Post', `/AccRequest/ListDocuments/${localStorage.getItem('id')}/list`, data)
            .then(response => {
                if(!dwnldPdf){
                     console.log('!!!!!!ccbcbcbcbvbcv!!!!!!!!!!!!!!!', response);
                if (response.data.result) {
                    setTransactionList(response.data.data);
                    setActiveTab(2);
                    setLoading(false);
                } else {
                    setTransactionList([])
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    // setTab(false);
                    setLoading(false);
                }
                }else{
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'report.pdf');
                    document.body.appendChild(link);
                    link.click();
                }
               
            })
        setLoading(false);
    }


    const getRequests = () => {
        setLoading(false)
        let data = {
            nameLike: searchText,
            pageSize: 100,
            pageNumber: 0
        }
        if (filterData) {

            // data.sortIsDesc = filterData.sortIsDesc;
            // data.sortByType = filterData.sortByType;
            // data.startAmount = filterData.startAmount;
            // data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }
        ApiService('Post', `/AccRequest/RequestTransferList/${localStorage.getItem('id')}`, data)
            .then(res => {
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!gholi', res);
                if (res.data.result) {
                    setRequests(res.data.data)

                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr();
                    setLoading(false)

                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }


   



    const getChart = () => {
        setLoading(true);
        let data = {
            timingType: 0,
            groupingType: 0,
            docType: 0,
            accCategoryId: 0
        }
        ApiService('Post', `/Chart/Get/${localStorage.getItem('id')}`, data)
            .then(res => {
                if (res.data.result) {
                    setChartData(res.data.data);
                    setLoading(false);
                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr();
                    setLoading(false);

                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    const closeSelect = () => {

        NewSelect && NewSelect.lastObj && NewSelect.lastObj.close();
    }

    const renderCharts = () => {
        if (chartData && chartData.dataList) {

            return (<>

                {chartData.dataList[0] && chartData.dataList[0].length > 0 ?
                    <>
                        <div className={`text-right mediumF rtl mt-2 mb-2`}>هزینه</div>
                        <div>
                            <MyChart data={chartData.dataList[0]} type={chartData.dataList[0].length < 2 ? 'bar' : 'area'} />
                        </div>
                    </>
                    : null}


                {chartData.dataList[1] && chartData.dataList[1].length > 0 ?
                    <>
                        <div className={`text-right mediumF rtl mt-2 mb-2`}>درآمد</div>
                        <div>
                            <MyChart data={chartData.dataList[1]} type={chartData.dataList[0].length < 2 ? 'bar' : 'area'} />
                        </div>
                    </> : null}

                {chartData.dataList[0] && chartData.dataList[0].length == 0 && chartData.dataList[1] && chartData.dataList[1].length == 0 ?
                    <EmptyList desc={'اطلاعاتی جهت نمایش نمودار وجود ندارد'} /> : null}
            </>




            )
        }
    }
    const renderTransactions = () => {
        return (<div>
            <div className={`d-flex align-items-center pl-2 pr-2`}>
                <div style={{width:25}} onClick={()=>{
                    setDwnld(true)
                }}>
                    <img className={`w-100`} src={pdf}/>
                </div>
                <SearchBar
                click={() => {
                    if (search) {
                        setSearchText('');
                        setSearch(false);
                        getTransactions()

                    } else {
                        setSearch(true);
                    }
                }}
                sortModule={true}
                clickSort={() => {
                    setFilterModal(true);
                    setSearchText('');
                    setSearch(false);
                }} />
            </div>
            
            {search ?
                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                        style={{
                            padding: 5,
                            border: ' 1px solid grey',
                            borderRadius: 10
                        }}>
                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                            onKeyUp={event => {

                                if (event.key === 'Enter') {
                                    getTransactions()
                                }
                            }}
                            placeholder={'جستجو'}
                            onChange={(e) => {
                                setSearchText(e.target.value);

                            }} />
                        <div style={{ width: 15, }}>
                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                getTransactions()
                            }} />
                        </div>

                    </div>
                    {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                </div>
                : null}
            {transactionList.length > 0 ? transactionList.map((item, index) => {
                return <FundCard
                    key={index} subject={item.subject}
                    date={item.dateCreated}
                    type={item.priceType}
                    category={item.accountingCategory ? item.accountingCategory.text : null}
                    amount={item.amount} category={item.accountingCategory ? item.accountingCategory.text : null}
                    change={item.priceType}
                    profileImage={item.creator.profileImageUrl}
                    creator={item.creator.name}
                    attachment={item.hasAttachment}
                    click={() => {
                        props.history.push({
                            pathname: `/fund/${item.id}`,
                            state: {
                            //    id: item.id,
                                backUrl:'/fund'
                            }
                        })
                    }} />
            }) :
                // <div style={{width:'100%'}}>
                <EmptyList des={'تاکنون تراکنشی ثبت نشده‌است'} />
                // </div>

            }

        </div>)
    }


    const renderRequests = () => {
        return (
            <div>

                {Requests && Requests.length > 0 ? <SearchBar
                    click={() => {
                        if (search) {
                            setSearchText('');
                            setSearch(false);
                            getRequests()

                        } else {
                            setSearch(true);
                        }
                    }}
                    sortModule={true}
                    clickSort={() => {
                        setFilterModal(true);
                        setSearchText('');
                        setSearch(false);
                    }} /> : null}
                {search ?
                    <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                        <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                            style={{
                                padding: 5,
                                border: ' 1px solid grey',
                                borderRadius: 10
                            }}>
                            <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                onKeyUp={event => {

                                    if (event.key === 'Enter') {
                                        getRequests()
                                    }
                                }}
                                placeholder={'جستجو'}
                                onChange={(e) => {
                                    setSearchText(e.target.value);

                                }} />
                            <div style={{ width: 15, }}>
                                <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                    getRequests()
                                }} />
                            </div>

                        </div>
                        {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                    </div>
                    : null}







                {Requests.length > 0 ?
                    Requests.map((item, index) => {
                        return <RequestCard key={index} item={item}
                        />
                    })

                    : <EmptyList des={'تاکنون درخواستی ثبت نشده‌است'} />
                }



            </div>
        )

    }


    const renderBildingSelect = () => {
        return <>{buildingList.length > 1 ?
            <div className={`mt-2 mb-3`}>
                <NewSelect options={buildingList} plachodler={def} closeOther={() => { closeSelect() }}
                    obj={true}
                    sel2={false}
                    select={selectedBulding ? true : false}
                    value={selectedBulding}
                    desk={selectedBulding.cashDeskAmount}
                >
                    <div>
                        {buildingList.map((item, index) => {
                            return (
                                <div key={index} className={`p-2 d-flex justify-content-between align-items-center flex-row-reverse`}
                                    onClick={() => {
                                        buildingList.find(elem => {
                                            if (elem.id == item.id) {
                                                localStorage.setItem('id', elem.id);
                                                getMyBuildingList();
                                                //setSelectedBulding(elem);

                                            }
                                        })
                                    }}>
                                    <div className={`pr-2 fntBold mediumF grey`}>{item.name}</div>
                                    {item.cashDeskAmount ? <div className={`fntBold mediumF`} style={{ display: 'flex' }} >{formatMoney(item.cashDeskAmount)}
                                        {item.cashDeskAmount != 0 ?
                                            <div style={{ width: '10px' }}>
                                                <img style={{ width: '100%', marginLeft: 5 }}
                                                    src={item.cashDeskAmount > 0 ? inc : item.cashDeskAmount < 0 ? dec : null} alt={'alt'} />
                                            </div>
                                            : null}
                                    </div> : null}
                                </div>
                            )
                        })}
                    </div>
                </NewSelect>
            </div>
            :
            buildingList.length == 1 ?
                <div className={`w-100 text-right pt-1 pb-1  mt-2 mb-2 single`}>
                    <div className={`p-2 d-flex justify-content-between align-items-center flex-row-reverse`}>
                        <div className={`pr-2 mediumF grey`}> {buildingList[0].name}</div>
                        <div className={`fntBold mediumF`} style={{ display: 'flex' }} >{formatMoney(buildingList[0].cashDeskAmount)}
                            {buildingList[0].cashDeskAmount != 0 ? <img style={{ width: '10px', marginLeft: 5, height: '100%' }}
                                src={buildingList[0].cashDeskAmount > 0 ? inc : buildingList[0].cashDeskAmount < 0 ? dec : null} alt={'alt'} /> : null}
                        </div>
                    </div>

                </div>
                : null
        } </>
    }
    const def =
    {
        id: '0',
        text: 'ساختمان خود را انتخاب کنید'
    };
    return (

        <Layout loaded={true} title={'صندوق ساختمان'} isHome={false}
            showMenu={true} loading={loading}
            displayMenu={!loading}
            customBack={true}
            path={'/'} >
            <Toast
                text={errText}
                type={errType}
            />
            <FilterModal2 show={filterModal} amount={true} sortInc={true}
            cashDesk={cashDesk}
            typeOdDoc={true} 
            click={() => {
                setFilterModal(false);
                setFilterData(null);
            }}
                submit={(filterData) => {
                    console.log('>>>>>>>>>>>>>>>>>>',filterData);
                    setFilterData(filterData)
                    setFilterModal(false)
                }} />
            {permissions && permissions.gholi ? <>
                {buildingList.length > 0 ? <div className={`mt-2 mb-3`}>
                    <NewSelect options={buildingList} plachodler={def} closeOther={() => { closeSelect() }}
                        obj={true}
                        sel2={false}
                        select={selectedBulding ? true : false}
                        desk={selectedBulding.cashDeskAmount}
                        label={'ساختمان خود را انتخاب کنید'}
                    >
                        <div>
                            {buildingList.map((item, index) => {
                                return (
                                    <div key={index} className={`p-2 d-flex justify-content-between align-items-center flex-row-reverse`}
                                        onClick={() => {
                                            buildingList.find(elem => {
                                                if (elem.id == item.id) {
                                                    localStorage.setItem('id', elem.id);
                                                    getMyBuildingList();
                                                    //setSelectedBulding(elem);

                                                }
                                            })
                                        }}>
                                        <div className={`pr-2 fntBold mediumF grey`}>{item.name}</div>
                                        {item.cashDeskAmount ? <div className={`fntBold mediumF`} style={{ display: 'flex' }} >{formatMoney(item.cashDeskAmount)}
                                            {item.cashDeskAmount != 0 ?
                                                <div style={{ width: '10px' }}>
                                                    <img style={{ width: '100%', marginLeft: 5 }}
                                                        src={item.cashDeskAmount > 0 ? inc : item.cashDeskAmount < 0 ? dec : null} alt={'alt'} />
                                                </div>
                                                : null}
                                        </div> : null}
                                    </div>
                                )
                            })}
                        </div>
                    </NewSelect>
                </div> : null}
                <NoAccess Text={'شما دسترسی لازم برای ورود به این صندوق را ندارید'}></NoAccess>   </>
                : permissions && !permissions.pTabCharts && !permissions.pTabTransactions && selectedBulding && selectedBulding.cashDeskAmount ?
                    <> {renderBildingSelect()} <div style={{ width: '50%', margin: '0 auto' }}><img style={{ width: '100%' }} src={moneybag} /></div> <div className={'mt-3 text-center mediumF'}>موجودی صندوق ساختمان {selectedBulding.name} {formatMoney(selectedBulding.cashDeskAmount)} ریال می باشد</div></>

                    : permissions && tabItemsList ?
                        <>
                            <Modal show={showModalFund} btn={true} click={() => {
                                setShowModalFund(false);
                            }}>
                                <div className={`d-flex justify-content-between align-items-center flex-row-reverse mb-3 mt-3`}>
                                    <div className={`rtl text-right mediumF `}>موجودی کیف پول  :</div>
                                    <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                                        <div className={`backGreyLight pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(250000)}</div>
                                        <div className={`grey smallF`}>ریال</div>
                                    </div>
                                </div>

                                <div className={`text-center mediumF mt-3 mb-3`}>مبلغ تخصیص تنخواه را وارد کنید</div>

                                <CpInput plachodler={'مبلغ درخواستی'} perfix={"ریال"} type={"money"} />
                                <MySelect placeholder={'  انتخاب کاربر '} select2={true} />
                                <div className={`w-75 mx-auto`}>
                                    <SBotton title={'تایید'} gradian />
                                </div>

                            </Modal>

                            <div style={{ height: 'inherit' }}>
                                {renderBildingSelect()}
                                {wallet && selectedBulding ? <div className={`mt-2 mb-2`}>
                                    <SingleSelect amount={wallet} label={`کیف پول  ${selectedBulding.name}`} />
                                </div> : null}
                                <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100 `}>
                                    {permissions.pBtnAccDoc ? <AddBotton title={' ثبت سند  '} click={() => {
                                        props.history.push('/addDoc');
                                    }} /> : null}
                                    {permissions.pBtnSettings ? <div className={`mr-3 ml-3`} style={{ width: 40 }}>
                                        <img src={settings} alt={''} style={{ width: '100%' }} onClick={() => {
                                            props.history.push({
                                                pathname: '/fund/fundSetting',
                                                state: {
                                                    desk: buildingDesk
                                                }
                                            })

                                        }} />
                                    </div> : null}

                                </div>
                                {/* <AddBotton title={'  تخصیص تنخواه  '} img={salary} click={() => {
                setShowModalFund(true);


                 props.history.push({
                                pathname: '/fund/fundSetting',
                                state:{
                                    desk: 
                                }
                                )};
            }} /> */}


                                <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`} onClick={props.click}>
                                    {tabItemsList.map(item => {
                                        return (<TabBar title={item.title} key={item.id} length={tabItemsList.length} id={item.id} activeIndex={activeTab} click={() => {
                                            setActiveTab(item.id);
                                            if (item.id == 3) {
                                                getRequests()
                                            } else if (item.id == 1) {
                                                getChart()

                                            } else if (item.id == 2) {
                                                getTransactions()
                                            }

                                        }} />)
                                    })}
                                </div>
                                <div className={`w-100 bb`} style={{ height: `calc(100% - 14rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>{renderItem}</div>
                            </div>
                        </>
                        : null}
        </Layout>

    );
};

export default withRouter(Fund);
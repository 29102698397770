import Mapir from "mapir-react-component"
import React from "react"
import marker from '../../Assets/myAsset/placeholder.svg'


export const Map = Mapir.setToken({
    transformRequest: (url) => {
        return {
            url: url,
            headers: {
                'x-api-key': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjU1OTQ1OGNmZjNmOGZhMDI4ODM3YTAwNmRlZWNmZjU2NGY4OTE5MGE0ZmJjZTZjZGFjZGIzOTVmMzhkYzVmYTNjN2RkNTliNDUyZTExYmQwIn0.eyJhdWQiOiIxMTUxNiIsImp0aSI6IjU1OTQ1OGNmZjNmOGZhMDI4ODM3YTAwNmRlZWNmZjU2NGY4OTE5MGE0ZmJjZTZjZGFjZGIzOTVmMzhkYzVmYTNjN2RkNTliNDUyZTExYmQwIiwiaWF0IjoxNjA1NTYxNzY1LCJuYmYiOjE2MDU1NjE3NjUsImV4cCI6MTYwODA2NzM2NSwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.cfQaZmrkz-q7l3UZG7q_pEdvQS4xPipjMGNO_7RfZCCu_2eIIAWyvtxX1fT5t2bqbmSdyxx2yqB4hZPx3kF3s14_gAwu3tohI9XEwTm4uN-vH57Fi6WXi8DpkzFc7v-aEB2xxdanYDvY8uL_RW3Jm1CQI6vM9r0V8BFqFFWiw4zlWygH4JACEelQNQq1Pn5jtQAg5TYLfaTW3mw3p-R_oJHruhGfP3168vwdh-Naw90YCoUzfbnpT73bI6R7cJ8kl765dDFSZHzLhenJlQYA246fAyyl8WafHP1Cl4NlFV3WhkBChHbr8sk6soxhOI4bB_24VKn0ptYa9hfL-5OsPw', //Mapir access token
                'Mapir-SDK': 'reactjs'
            }
        }

    }
});

const MapP = props => {

    return (
        <Mapir 
            containerStyle={props.containerStyle}
            Map={Map} 
            center={[props.lng, props.lat]} 
            userLocation={props.userLocation} 
            onDragEnd={props.onMove} 
            zoom={[15]} 
        >
          
            {
                props.hideMarker ?
                null:
                <div style={{ width: '40px', position: 'absolute', top: '45%', left : "calc(50% - 20px)"}}>
                    <img style={{width: '40px' }} src={marker} onClick={props.click} />
                </div>
            }

            {props.children}
            {
                props.hideZoom?
                null:
                <Mapir.ZoomControl/>
            }
           
            {
                props.coordinates &&
                <Mapir.Marker
                    coordinates={props.coordinates}
                    anchor="bottom"
                    Image={marker}
                />
            }
        </Mapir>
    )

}
export default MapP;

import React, { useEffect, useState } from 'react';
import BaseBX from '../BaseBx/BaseBx';
import ContentBx from '../BaseBx/ContentBx/ContentBx';
import SBotton from '../Buttons/SBotton/SBotton';
import Header from '../Header/Header';
import Loading from '../Loading/Loading';
import MenuDrawer from '../MenuDrawer/MenuDrawer';
import RetryPage from '../RetryPage/RetryPage';




const Layout = props => {
    const [sideDrawerIsVisible, setSideDrawerIsVisible] = useState(false);
    const [Height, setH] = useState(0);
    const [Width, setW] = useState(null);

    const sideDrawerClosedHandler = () => {
        setSideDrawerIsVisible(false);

    };

    useEffect(() => {

        setW(document.getElementById('baseBx').clientWidth);
        //setH(document.getElementById('header').clientHeight);
    })
    return (
        <BaseBX loading={props.loading} overflow={props.loading}  BxHeight={props.BxHeight}>
            { props.loaded ? <>
                <Loading show={props.loading} />
              <MenuDrawer
                    //Height={Height}
                    Width={Width}
                    display={props.displayMenu}
                    open={sideDrawerIsVisible}
                    closed={sideDrawerClosedHandler}
                    name={props.name}
                    mobile={props.mobile}
                    wallet={props.wallet}
                   
                />
                { !props.retry ? <Header


                badgCount={props.badgCount}
                Width={Width}
                headerPos = {props.headerPos}
                headerPad={props.headerPad}
                    show={props.showMenu}
                    showEdit={props.showEdit}
                    title={props.title}
                    isHome={props.isHome}
                    customBack={props.customBack}
                    path={props.path}
                    obj={props.obj}
                    del={props.del}
                    avatar={props.avatar}
                    click={() => {
                        if (props.showMenu) { setSideDrawerIsVisible(true); }

                    }} />: null}
                <ContentBx paddSet={props.paddSet} scroll={props.scroll} pose={props.pose}>

                    {props.children}


                </ContentBx>
            </> : <RetryPage />}
            <div className={`w-50 mx-auto mb-4 mt-4 `} style={{ position: 'absolute', display: props.btn  ? 'block' : 'none', left: '25%', bottom: 0,zIndex:20}}>
                <SBotton gradian={props.gradian} title={props.btnName} sending={props.sending} click={props.click} />
                {props.btn2 ?  <SBotton title={props.btnName2} sending={props.sending2} click={props.click2} /> : null}

            </div>
        </BaseBX>
    );
};

export default Layout;
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import classes from './Survey.module.css'
import SBotton from '../../Components/Buttons/SBotton/SBotton';
import Date from '../../Components/Date/Date';
import Doer from '../../Components/Doer/Doer';
import Layout from '../../Components/Layout/Layout';
import Toast from '../../Components/Toast/Toast';
import { ApiService, colors, getBuildingId } from '../../value';
import message from '../../Assets/myAsset/email.svg';
import MessageModal from '../../Components/Modal/MessageModal/MessageModal';



const Survey = props => {
    const [loading, setLoading] = useState(true);
    const [submitVote, setSubmitVote] = useState(true);

    const [sendingBtn, setSendingBtn] = useState(false);

    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');

    const [survey, setSurvey] = useState(null);
    const [surveyID, setSurveyID] = useState(null);
    const [buildingID, setBuildingID] = useState(null);


    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedUnitForMess, setselectedUnitForMess] = useState([]);
    const [showModalMessage, setShowModalMessage] = useState(false);
    const [MessageData, setMessageData] = useState(null);





    useEffect(() => {
        if (props.location.state && props.location.state.id && getBuildingId()) {
            setSurveyID(props.location.state.id);
            setBuildingID(getBuildingId ())
            getDetailOfSurvey(props.location.state.id, getBuildingId())
        } else {
            props.history.push('/news')
        }
    }, []);

    const getDetailOfSurvey = (id, buildingId) => {
        ApiService('Get', `/Survey/Detail/${buildingId}/${id}`)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setSurvey(response.data.data);

                    if(response.data.data.unitsNotParticipate && response.data.data.unitsNotParticipate.length>0 ){
                        let temp =[]
                        response.data.data.unitsNotParticipate.forEach(element => {
                            temp.push(element.id);
                            
                            
                        });
                        setselectedUnitForMess(temp)
                    }

                    setLoading(false);
                    isSeen(id, buildingId)
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    // setTab(false);
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }
    const isSeen = (id, buildingId) => {
        ApiService('Get', `/Survey/Seen/${buildingId}/${id}`)
            .then(res => {
                console.log('seeeeeeeeeen', res);
            })

    }

    const addVote = (item) => {

        if (survey.multipleSelect) {
            if (selectedOption.length > 0 && selectedOption.find(elem => { return elem.id === item.id })) {
                setSelectedOption(selectedOption.filter(elem => elem.id !== item.id));
            } else {
                setSelectedOption(selectedOption => [...selectedOption, item]);
            }
        } else {
            setSelectedOption(item);
        }
    }
    const submit = () => {
        let data = {
            options: []
        };
        if (selectedOption.length > 0) {
            selectedOption.forEach(element => {
                data.options.push(element.id)

            });
        } else {
            data.options.push(selectedOption.id)
        }
        setLoading(true);
        ApiService('Post', `/Survey/SubmitComment/${buildingID}/${survey.id}`, data)
            .then(response => {
                if (response.data.result) {
                    setErrText('نظر شما با موفقیت ثبت شد');
                    setErrType('suc');
                    clearErr();
                    setTimeout(() => {
                        getDetailOfSurvey(props.location.state.id, getBuildingId())
                    }, 2000)
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    setLoading(false);
                    clearErr();
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const deleteSurvey = (id, buildingId) => {
        setSendingBtn(true)
        setLoading(true);
        ApiService('Get', `/Survey/Delete/${buildingId}/${id}`)
            .then(response => {
                if (response.data.result) {
                    setLoading(false);
                    setErrText('نظرسنجی با موفقیت حذف شد.');
                    setErrType('suc');

                    setTimeout(() => {
                        props.history.push('/news')
                    }, 2000)


                }else{
                    setLoading(false);
                    setErrText(response.data.message);
                    setErrType('war');

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const sendMessage = (data) => {


        if (data) {
            console.log('2', data);
            if (!data.text || data.text.length == 0) {
                setErrText('متن پیام را وارد کنید');
                setErrType('war');
                clearErr();
                return

            }

            if ( MessageData.text.length > 750) {
                setErrText('متن پیام بلند است و قابل ارسال نیست');
                setErrType('war');
                clearErr();
                return

            }
            if (!data.messageType) {
                setErrText('نوع پیام را انتخاب کنید');
                setErrType('war');
                clearErr();
                return

            }




            // setSending(true);
            let data1 = data;
            data1.units = selectedUnitForMess;

            console.log('', data);
            ApiService('Post', `/Message/Send/${getBuildingId()}`, data1)
                .then(response => {
                    console.log(response);
                    if (response.data.result && response.data.resultCode == 200) {
                        setMessageData(response.data.data);
                        // setSending(false);
                    }
                    else if (response.data.result && response.data.resultCode == 100) {
                        // setSending(false);
                        setShowModalMessage(false);
                        setMessageData({
                            // messageType: 0,
                            // userRoleInUnit: 1,
                            // text: ''
                        });
                        setselectedUnitForMess([]);
                        // setSendMess(false);
                        //setSelectAll(false);
                        setErrText(response.data.message);
                        setErrType('suc');
                        clearErr();


                    } else {
                        setErrText(response.data.message);
                        setErrType('war');
                        clearErr();
                    }
                })
                .catch(err => {
                    setErrText('خطای داخلی سرور');
                    setErrType('err');
                    setLoading(false);
                    clearErr();
                    return
                })

        }
    }
    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }


    return (survey ?
        <Layout loaded={true} title={survey.allowSubmit ? 'ثبت نظرسنجی' : 'نظرسنجی'} isHome={false} showMenu={false} loading={loading} >
            <Toast
                text={errText}
                type={errType}
            />

            <MessageModal show={showModalMessage}  isClose={showModalMessage}
                guide={['نام واحد   : [vahed]',
                    'نام ساکن/مالک   :    [name]'
                    ] }
                text={`ارسال پیام یادآوری به ${selectedUnitForMess.length} واحدی که در نظرسنجی شرکت نکرده‌اند `}
                onErr={(txt) => {
                    setErrText(txt);
                    setErrType('war');
                    clearErr();
                    return
                }}
                
                type={true}
                selectedUnit={selectedUnitForMess}
                sendMessage={(messData) => {
                    console.log('messDatamessDatamessDatamessData', messData);
                    messData.units = selectedUnitForMess
                    setMessageData(messData);
                    sendMessage(messData)
                }}
                data={null}

                onClose={() => {
                    setShowModalMessage(false)
                }}

                click={() => {
                    setShowModalMessage(false)
                }} />
            <div style={{ height: 'inherit' }}>
                <div style={{ height: survey.allowSubmit ? `calc(100% - 8rem)` : `calc(100% - 4rem)`, overflow: 'auto', }}>





                    <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 mb-3`}>
                        <Doer creator={survey.creator.name} profileImage={survey.creator.profileImageUrl} />
                       {survey.pBtnMessage ? <div style={{ width: '25px' }} onClick={() => {setShowModalMessage(true)  }} >
                            <img style={{ width: '100%' }} src={message} />
                        </div> : null}
                    </div>



                    <div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 mb-1`}>
                        <div className={`mt-2 mb-2`} style={{ fontSize: '13px' }}>{survey.title}</div>
                        <div><Date date={survey.createdDate} font={12} /></div>
                    </div>

                    <div className={`d-flex justify-content-start flex-row-reverse align-items-center  mb-1 ml-2 `} style={{ fontSize: '13px' }}>
                        <div className={`mt-2 mb-2 rtl text-right `} style={{ fontSize: '13px' }}>تاریخ پایان نظرسنجی:</div>
                        <div className={`mr-2`}>{survey.expireDate}</div>
                        {/* <span className={`mr-2`}>ساعت</span> */}
                        {/* <div className={`mr-2`}>{survey.expireTime}</div> */}
                    </div>




                    <div>
                        <div className={`grey  rtl  p-2 text-right`}
                            style={{ lineHeight: 1.8, maxHeight: '25%', overflow: 'auto', wordWrap:'break-word', whiteSpace: "pre-wrap", fontSize: 13 }}>
                            {survey.description}
                        </div>
                        {survey.filePath ? <div className={`rtl`}>
                            <div className={` `} onClick={(e) => {
                                window.open(survey.filePath);
                                e.stopPropagation();
                            }} style={{
                                width: 110, height: 80,
                                backgroundImage: "url(" + survey.filePath + ")",
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            </div>
                        </div> : null}
                        {!survey.allowSubmit ?
                            <div className={`w-100 mt-4 mb-4`}>
                                {survey.options.map(item => {
                                    return <div key={item.id}><div className={`d-flex justify-content-between flex-row-reverse align-items-center w-100 mb-1 smallF bold`}>
                                        <div>{item.text}</div>
                                        <div>{item.percentageNum}%</div>
                                    </div>
                                        <div className={`mb-3`} style={{ height: 7, borderRadius: 10, backgroundColor: "#d2d2d2", direction: 'rtl' }}>
                                            <div style={{ backgroundColor: colors.app1, height: 'inherit', width: `${item.percentage}%`, borderRadius: 10 }}></div>
                                        </div>
                                    </div>
                                })}
                            </div> :
                            <div className={`w-100 mt-4 mb-4  `}>
                                {survey.options.map(item => {
                                    return <div key={item.id} >
                                        <div onClick={() => {
                                            addVote(item);

                                        }} className={` p-2 text-center bold m-2`} style={{
                                            color: (selectedOption.length > 0 && (selectedOption.find(elem => { return elem.id === item.id })) || selectedOption.id === item.id) ? '#fff' : '#000',
                                            borderRadius: '20px',
                                            background: (selectedOption.length > 0 && (selectedOption.find(elem => { return elem.id === item.id })) || selectedOption.id === item.id) ? "linear-gradient(to bottom, #ff403e, #ff5d1c)" : '#c6c6c6'
                                        }}> {item.text}</div>
                                    </div>
                                })}

                            </div>
                        }



                    </div>

                    <div className={`w-75 mx-auto position-absolute mt-4  `} style={{ bottom: 0, left: '12.5%' }}>
                        {survey.allowSubmit ? <SBotton title={'ثبت نظر'} gradian={true} click={() => {

                            submit()
                        }} /> : null}

                        <div className={`d-flex justify-content-around flex-row-reverse align-items-center`}>
                            {survey.pBtnEdit ? <div className={`m-1 w-100`}>
                                <SBotton title={'ویرایش'} gradian={true} click={() => {
                                    props.history.push({
                                        pathname: '/addSurvey',
                                        state: {
                                            survey: survey,
                                        }
                                    });
                                }} />

                            </div> : null}

                            {survey.pBtnDelete ? <div className={`m-1 w-100`}>
                                <SBotton title={'حذف'} sending={sendingBtn} click={() => {
                                    deleteSurvey(surveyID, buildingID);
                                }} />
                            </div> : null}


                        </div>

                    </div>

                </div>
            </div>

        </Layout>
        : null
    )
}
export default withRouter(Survey);
import React from "react"
import moment from "moment-jalaali"
import { useHistory } from "react-router-dom"
import axios, { serviceAxios } from "../../axios"
import Layout from "../../Components/Layout/Layout"
import { useOrderContext } from "../../context/orderContext"
import { Checkbox } from "../../Components/CheckBox/CheckBox"
import Date from "../../Components/Date/Date"
import Toast from "../../Components/Toast/Toast"
import PayGatesModal from "../../Components/Modal/PayGatesModal/PayGatesModal"
import useError from "../../hooks/useError"
import { formatMoney } from "../../value"
import ChargeWalletModal from "../../Components/Modal/ChargeWalletModal/ChargeWalletModal"


const PayService = ({})=>{

    const {orderState , orderDispatch} = useOrderContext()
    const {error , setError} = useError();
    const [method, setMethod] = React.useState(orderState.paymentMethods[0].methodKey);
    const [walletAmount, setWalletAmount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [showPaymentGate, setShowPaymentGate] = React.useState(false);
    const [showModalCharge, setShowModalCharge] = React.useState(false);
    const [gateList, setGateList] = React.useState([]);
    const history = useHistory()

    console.log(orderState)
    const getUrl = window.location;
    const baseUrl = getUrl .protocol + "//" + getUrl.host;

    React.useEffect(()=>{
        const walletData = orderState.paymentMethods.find(item=>item.methodKey === "UserBag")
        walletData !== undefined && setWalletAmount(walletData.availableAmount)
    },[])

    const renderpaymethod = (item, index) => {
        return (
            <div key={index} className={`d-flex flex-row justify-content-between align-items-center`}>
                <div className="d-flex flex-row align-items-center">
                    <Checkbox
                        Type={'Radio'} 
                        Label={item.title} 
                        onChange={() => {
                            setMethod(item.methodKey);
                        }}
                        checked={method === item.methodKey}
                    />
                    {
                        item.methodKey == 'UserBag' ? 
                        <div style={{ fontSize: 11, marginBottom: 6, marginRight: 3, color: '#FF5E1B' }}
                            onClick={() => {
                                setShowModalCharge(true)
                        }}> 
                            افزایش اعتبار
                        </div> : 
                        null
                    }
                </div>  
                {
                    item.methodKey == 'UserBag'? 
                    <div className={` d-flex flex-grow-0 grey`} style={{ fontSize: 10, marginBottom: 6 }}>
                        اعتبار  : {formatMoney(item.availableAmount)} ریال
                    </div> : 
                    null
                }
            </div>)

    }

    const getPaymentGates = (params)=>{
        axios.post(
            "/Payment/GeneratePaymentUrl",
            {...params , callBackUrl: `${baseUrl}/service/pay/callback/${params.param}`}
        ).then(res=>{
            console.log(res)
            setLoading(false)
            
            if(res.data.resultCode === 200){
                setGateList(res.data.data)
                setShowPaymentGate(true)
            }else{
                setError(res.data.message , "err")
            }
        }).catch(err=>{
            setLoading(false)
            console.log(err)
            setError("خطایی رخ داده است. لطفا دوباره تلاش کنید" , "err")
        })
    }

    const onPayPressed = ()=>{
        if(method === 'UserBag' && (walletAmount < orderState.periods[0].price * orderState.periods[0].requestedCount)){
            setError("موجودی کافی نیست" , "err")
            return
        }
        setLoading(true)
        let data = {
            methodKey: method,
            data : orderState,
            callBackUrl: `${baseUrl}/service/pay/callback`,
            amountPayable: orderState.periods[0].price * orderState.periods[0].requestedCount,
            amount: orderState.periods[0].price * orderState.periods[0].requestedCount,
            param: "",
            payFor:8,
        };

        serviceAxios.post("Order/ApplyPayMethod" , data)
        .then(res=>{
            console.log(res.data)
            if(res.data.resultCode === 200){
                if(res.data.data){
                    getPaymentGates(res.data.data2)
                }
                else{
                    setError("سفارش با موفقیت ثبت شد" , "suc")
                    setTimeout(()=>{
                        history.replace("/");
                        history.push(`/service/orderDetails/${res.data.data2}`);
                    },2000)
                }
            }else{
                setLoading(false)
                setError(res.data.message , "err")
            }
        }).catch(err=>{
            setLoading(false)
            console.log(err)
            setError("خطایی رخ داده است. لطفا دوباره تلاش کنید" , "err")
        })
    }

    const chargeWallet = amount =>{
        let data = {
            amountPayable: parseInt(amount),
            callBackUrl: `${baseUrl}/service/orderConfirm`,
            payFor: 0
        };

        axios.post("Payment/GeneratePaymentUrl" , data)
        .then(res=>{
            console.log(res.data)
            setLoading(false)
            if(res.data.resultCode === 200){
                if(res.data.data){
                    setGateList(res.data.data)
                    setShowModalCharge(false)
                    setShowPaymentGate(true)
                }
                else{
                    setError("خطایی رخ داده است. لطفا دوباره تلاش کنید" , "err")
                }
            }else{
                setError(res.data.message , "err")
            }
        }).catch(err=>{
            setLoading(false)
            console.log(err)
            setError("خطایی رخ داده است. لطفا دوباره تلاش کنید" , "err")
        })
    }

    return(
        <Layout 
            loaded={true} 
            title={`پرداخت`}
            loading={loading} 
            btnName={'پرداخت '} 
            btn={true} 
            gradian 
            click={onPayPressed} 
            showMenu={true}
            displayMenu={true}
        >
            <div style={{ height: 'inherit' , width : "100%"}}>
                <div style={{width : "100%" ,height: `calc(100% - 5rem)`, overflow: 'auto' }}>
                    <div className={`w-100 d-flex justify-content-between align-items-center flex-row-reverse`}>
                        <div> <Date date={moment().format("jYYYY/jMM/jDD")} font={12} /></div>
                    </div>
                    <div className={`d-flex justify-content-start align-items-center flex-row-reverse`}>
                        <div className={`rtl text-right mediumF `}>مبلغ قابل پرداخت:</div>
                        <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                            <div className={`backGreyLight pr-3 pl-3 rtl`} style={{ borderRadius: 25 }} >{formatMoney(orderState.periods[0].price * orderState.periods[0].requestedCount)} ریال</div>
                        </div>
                    </div>

                    <div className={`w-100 p-2 rtl`}>
                        {orderState.paymentMethods.map((item, index) => renderpaymethod(item, index))}
                    </div>
                </div>
            </div>
            <Toast
                text={error.errTxt}
                type={error.errType}
            />
            <PayGatesModal
                showModal={showPaymentGate}
                close={() => {
                    setShowPaymentGate(false)
                }}
                gateList={gateList} 
            />
            <ChargeWalletModal
                showModalCharge={showModalCharge}
                close={() => {
                    setShowModalCharge(false)
                }}
                submitPay={(amount) => {
                    chargeWallet(amount);

            }} />
        </Layout>
    )
}

export default PayService
import React ,{ useEffect, useState } from 'react';
import {colors} from '../../value';
import classes from './Modal.module.css';
import Backdrop from '../../Components/Backdrop/Backdrop'

// import GradientButton from '../Buttons/GradientButton/GradientButton';

const Modal = props => {
  // const [modalH, setModalH] = useState(null);

 
  // const Hheight = document.getElementById('modal') ? document.getElementById('modal').clientHeight : 0;
  const WBase = document.getElementById('baseBx') ? document.getElementById('baseBx').clientWidth : 400;
   return ( 
    <div>
      <Backdrop
        backgroundColor={props.backgroundColor}
        click={props.click}
        show={props.show}
      />
      <div id={'modal'}
        style={{
          padding: props.padding,
          width:WBase - 30 ,
          // maxHeight: '85vh',
          // overflow:'auto',
          zIndex: props.zIndex? props.zIndex : 1001,
         
          transform: props.show
            ? `translate(-50%,  ${props.heightTop ? props.heightTop : '5vh'})`
            : 'translate(-50%,-150vh)'
    
        }}
        className={classes.Modal} >
        <div className={`w-100 text-left`} onClick={props.click} style={{display: props.btn ? 'block': 'none', color: colors.app1}}>بازگشت</div>
        <div className={`mt-3 mb-3`}>
          
        {props.children}
             </div>
       
      </div>
    </div>
  );
};

export default Modal;

import Profile from "../../Assets/myAsset/user.png"
import classes from "./ClientCard.module.css"
import deleteIcon from "../../Assets/myAsset/delete.png"

const ClientCard = ({
    id,
    name,
    roleName,
    profileImageUrl,
    deletePressed
})=>{
    return(
        <div className="shadow-card my-3 py-3 px-2 rtl">
            <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row">
                    <img
                        src={(!!profileImageUrl && profileImageUrl !== "")?profileImageUrl : Profile}
                        className={`${classes.img}`}
                    />
                    <span className="text-right px-2">
                        {
                            `${name} (${roleName})`
                        }
                    </span>
                </div>
                <img
                    className={`${classes.img}`}
                    src={deleteIcon}
                    onClick={()=>deletePressed(id)}
                />
            </div>
        </div>
    )
}

export default ClientCard
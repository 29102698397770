import React, { Component } from 'react';
import Layout from '../../../Components/Layout/Layout';
import NewSelect from '../../../Components/NewSelect/NewSelect';
import CpInput from '../../../Components/Input/Input';
import { Checkbox } from '../../../Components/CheckBox/CheckBox';

import {ApiService, attachFile, downloadExcel, FaToEnNum, formatMoney, MyBuildingList} from '../../../value';
import UnitList from '../UnitList/UnitList';
import UploadImg from '../../../Components/UploadImg/UploadImg';
import { withRouter } from 'react-router-dom';
import arrow from '../../../Assets/myAsset/down-arrow.svg';
import Toast from '../../../Components/Toast/Toast';
import loadingGif from '../../../Assets/gifs/22.gif'
import TabBar from '../../../Components/TabBar/TabBar';
import edit from '../../../Assets/myAsset/edit.svg';
import axios from '../../../axios';
import ModalUploadExcel from '../../../Components/Modal/ModalUploadExcel/ModalUploadExcel';
import SearchBar from '../../../Components/SearchBar/SearchBar';
import searchImg from '../../../Assets/myAsset/loupe.svg';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import NoAccess from '../../../Components/NoAccess/NoAccess';











const def =
{
    id: 100,
    value: 0,
    label: 'انتخاب دسته‌بندی',

};
const def1 =
{
    id: 100,
    text: ' انتخاب گروه واحدها',

};
const def2 =
{
    id: 100,
    text: 'تقسیم براساس ',

};
const divideType = [
    {
        id: 1,
        text: 'مساوی بین واحدها ',

    },
    {
        id: 2,
        text: 'تعداد پارکینگ',

    },
    {
        id: 3,
        text: 'متراژ',

    },
    {
        id: 4,
        text: 'تعداد نفرات',

    },
    {
        id: 5,
        text: 'مطابق اکسل',

    }];

const selectedUnitType = [
    {
        id: 1,
        text: ' همه واحدها ',

    },
    {
        id: 2,
        text: 'چند واحد خاص ',

    },
    {
        id: 3,
        text: 'واحد‌های خالی',

    },
    {
        id: 4,
        text: 'واحد‌های پر',

    }];





const tabItems = [
    {
        id: 1,
        title: ' فیش'
    },
    {
        id: 2,
        title: 'شارژ '
    },

]


let Self = null;
class AddReceipt extends Component {

    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            amount: null,
            //    sharj = 1 fish = 0
            typeOfDoc: null,

            loading: true,
            isSending: false,
            downloading: false,
            search: false,
            showModalUpload: false,
            uploading: false,
            isLoad: false,
            buildingList: [],
            selectedBulding: [],
            errText: '',
            errType: '',
            showOptions: false,

            selectedDivideType: '',
            displayCheckBox: false,

            categoryList: [],
            selectedCategoryType: null,
            selectedTypeDoc: '',
            selectedUnitType: '',
            mounts: [],
            selectedmount: null,

            imgId: [],
            imgPath: [],

            units: [],
            unitsCharge: [],


            amountOfUnits: [],

            editedamountOfUnits: [],

            title: '',
            description: '',
            userType: 2,




            activeTab: 1,
            renderItem: null,


            costomize: false,
            unitToShow: [],
            unitToShowForCharge: [],
            showDatePicker: false,
            deadLine: ''




        }
        this.searchText = '';
        this.excelunit = [];
        this.loadNode = 0;
        this.loadedNode = 0;
        this.unitListToShow = [];
        this.userUnitSelected = [];
        this.amountOfcharge = [];
        this.id = localStorage.getItem('id');
        if (this.props.location.state && this.props.location.state.buildingId) {
        }
    }
    componentDidMount() {
        if (this.id == null) {
            this.props.history.push('/')
        } else {
            this.getData();
        }

    }

    async handleOptions() {
        await this.setState({
            showOptions: !this.state.showOptions,
        })

    }

    searchInUnits() {
        if (this.searchText.length > 0) {
            let tmp = [];
            this.state.units.forEach(element => {
                if (
                    //element.area.toString().find(this.searchText)+   .search("ooo") 
                    (element.blockName ? element.blockName.search(this.searchText) : -1) >= 0 ||
                    (element.floor ? element.floor.search(this.searchText) : -1) >= 0 ||
                    (element.name ? element.name.search(this.searchText) : -1) >= 0 ||
                    ((element.owner && element.owner.name) ? element.owner.name.search(this.searchText) : -1) >= 0 ||
                    ((element.owner && element.owner.mobileNumber) ? element.owner.mobileNumber.search(this.searchText) : -1) >= 0 ||
                    ((element.resident && element.resident.name) ? element.resident.name.search(this.searchText) : -1) >= 0 ||
                    ((element.resident && element.resident.mobileNumber) ? element.resident.mobileNumber.search(this.searchText) : -1) >= 0
                ) {
                    tmp.push(element);
                }

            });
            this.setState({
                unitToShow: tmp
            })
        }
        else {
            this.setState({
                unitToShow: this.unitListToShow
            });
        }
    }


    searchInUnitsCharge() {

        if (this.searchText.length > 0) {
            let tmp = [];
            this.state.unitsCharge.forEach(element => {
                if (
                    //element.area.toString().find(this.searchText)+   .search("ooo") 
                    (element.unit.blockName ? element.unit.blockName.search(this.searchText) : -1) >= 0 ||
                    (element.unit.floor ? element.unit.floor.search(this.searchText) : -1) >= 0 ||
                    (element.unit.name ? element.unit.name.search(this.searchText) : -1) >= 0 ||
                    ((element.unit.owner && element.unit.owner.name) ? element.unit.owner.name.search(this.searchText) : -1) >= 0 ||
                    ((element.unit.owner && element.unit.owner.mobileNumber) ? element.unit.owner.mobileNumber.search(this.searchText) : -1) >= 0 ||
                    ((element.unit.resident && element.unit.resident.name) ? element.unit.resident.name.search(this.searchText) : -1) >= 0 ||
                    ((element.unit.resident && element.unit.resident.mobileNumber) ? element.unit.resident.mobileNumber.search(this.searchText) : -1) >= 0
                ) {
                    tmp.push(element);
                }

            });
            this.setState({
                unitToShowForCharge: tmp
            })




        }
        else {
            this.setState({
                unitToShowForCharge: this.state.unitsCharge
            });
        }

    }
    closeSelect() {

        this.NewSelect && this.NewSelect.lastObj && this.NewSelect.lastObj.close();
    }






    renderItemFish() {
        return (
            <div>

                <div className={'  w-100 mt-2 mb-1 position-relative'}
                    style={{ border: '1px solid #000000de', borderRadius: 4 }}>
                    <div className={`d-flex justify-content-between flex-row-reverse align-items-center`}>
                        <input value={this.state.selectedTypeDoc}
                            placeholder={'انتخاب دسته‌بندی*'}
                            onClick={() => {
                                this.handleOptions()
                            }}
                            onChange={(e) => {
                                this.setState({
                                    selectedTypeDoc: e.target.value
                                })
                                this.forceUpdate()
                            }}
                            style={{
                                border: 0, width: '90%',
                                direction: 'rtl', textAlign: 'right',
                                padding: '15.5px 12px'
                            }} />
                        <div onClick={() => {
                            this.setState({
                                showOptions: !this.state.showOptions
                            })
                            this.forceUpdate()
                        }}
                            className={`p-2`}
                            style={{ border: 0, width: '10%' }} >
                            <img style={{ width: 10, height: '100%' }} src={arrow} />
                        </div>
                    </div>
                    <div className={`position-absolute shadow`} style={{
                        zIndex: '100',
                        width: '96%', left: '2%',
                        maxHeight: 250,
                        overflow: "auto",
                        backgroundColor: '#fff',
                        border: '1px solid #f7f7f7',
                        marginBottom: 2,
                        borderRadius: 8,
                        borderLeft: '1px solid #bfbfbf',
                        borderRight: '1px solid #bfbfbf',
                        borderBottom: '1px solid #bfbfbf',
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        marginTop: '2px',
                        display: this.state.showOptions ? 'block' : 'none'
                    }}

                    >
                        {this.state.categoryList.map(item => {
                            return (
                                <div style={{ padding: '2%', textAlign: 'right' }}
                                    key={item.id} value={item.id}
                                    onClick={() => {
                                        this.setState({
                                            selectedTypeDoc: item.text,
                                            showOptions: false
                                        })
                                    }}>{item.text}</div>
                            )
                        })
                        }
                    </div>
                </div>
                <div className={`mt-2`} >
                    <CpInput plachodler={'عنوان هزینه'} value={this.state.title} type={"text"} onChange={(e) => {
                        this.setState({
                            title: e.target.value
                        })
                    }} />
                    <CpInput plachodler={'مبلغ'} perfix={"ریال"} maxLength={22} type={"money"} value={this.state.amount}
                        onBlur={() => { this.computingUnitsAmount(1) }}
                        onChange={(event) => {
                            this.setState({
                                amount: event.target.value
                            })
                        }} />
                    {/* <CpInput plachodler={' مهلت پرداخت'} value={this.state.deadLine} type={"text"} onClick={() => {
                        this.setState({
                            showDatePicker: true
                        })
                    }} /> */}
                    <div className={`d-flex align-items-center rtl `} onClick={() => {
                        this.setState({
                            showDatePicker: true
                        })
                    }}
                        style={{ marginBottom: 15, padding: '15.5px 12px', color: '#0000008a', border: '1px solid #424242', borderRadius: 4 }}>
                        <div>مهلت پرداخت:  </div>
                        <div className={`mr-3`} style={{ color: '#000000de' }} >{this.state.deadLine}</div>

                    </div>
                    <CpInput plachodler={'توضیحات'} type={"text"} value={this.state.description} style={{ whiteSpace: "pre-wrap" }} multiline={true} onChange={(e) => {
                        this.setState({
                            description: e.target.value
                        })
                    }} />
                    <div className={`rtl fntBold mediumF text-right`} >پرداخت توسط :</div>
                    <div className={`w-100 rtl`}>
                        <Checkbox textStle={{ fontSize: '12px', color: '#9e9e9e' }} checked={this.state.userType == 2}
                            onChange={() => {
                                this.setState({
                                    userType: 2
                                })
                            }} Type={'Radio'} Label="ساکن" />
                        <Checkbox textStle={{ fontSize: '12px', color: '#9e9e9e' }} checked={this.state.userType == 1}
                            onChange={() => {
                                this.setState({
                                    userType: 1
                                })
                            }} Type={'Radio'} Label="مالک" />

                    </div>



                    <NewSelect options={divideType} obj={false} plachodler={def2} value={this.state.selectedDivideType}
                        closeOther={() => { this.closeSelect() }} >

                        {divideType.map(item => {
                            return (
                                <div style={{ padding: '2%' }} key={item.id} value={item.id}
                                    onClick={() => {
                                        if (item.id == 5) {
                                            if (!this.excelunit || this.excelunit.length == 0) {
                                                this.setState({
                                                    showModalUpload: true,
                                                    typeOfDoc: 0,
                                                })
                                            }
                                            else {
                                                this.unitListToShow = [];
                                                this.userUnitSelected = [];
                                                this.setState({
                                                    displayCheckBox: false
                                                })
                                                for (let i = 0; i < this.excelunit.length; i++) {
                                                    this.unitListToShow.push(this.excelunit[i])
                                                }
                                                this.setSelectedUnits(item.id, this.state.selectedDivideType.id);

                                            }

                                        }
                                        else {
                                            divideType.find(elem => {
                                                if (elem.id == item.id) {
                                                    this.setState({
                                                        selectedDivideType: elem
                                                    });
                                                    this.computingUnitsAmount(item.id)
                                                    // if (elem.id == 1) {
                                                    //     this.computingUnitsAmount()
                                                    // }

                                                }
                                            })
                                        }
                                    }}>{item.text}</div>
                            )
                        })
                        }
                        {/* </div> */}
                    </NewSelect>

                    <NewSelect options={selectedUnitType} obj={false} plachodler={def1}
                        value={this.state.selectedUnitType} closeOther={() => { this.closeSelect() }}  >
                        {selectedUnitType.map(item => {
                            return (
                                <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={(e) => {
                                    this.setState({
                                        editedamountOfUnits: []
                                    })

                                    this.editedamountOfUnits = [];
                                    this.userUnitSelected = [];
                                    this.setState({
                                        amountOfUnits: []
                                    })
                                    if (parseInt(FaToEnNum(this.state.amount)) > 0) {
                                        if (this.state.selectedDivideType) {
                                            selectedUnitType.find(elem => {
                                                if (elem.id == item.id) {
                                                    this.setState({
                                                        selectedUnitType: elem
                                                    }); if (this.state.selectedDivideType.id == 5 && item.id != 1) {
                                                        this.setState({ selectedDivideType: divideType[0] })

                                                    }
                                                    if (item.id == 1) {
                                                        this.unitListToShow = [];
                                                        this.userUnitSelected = [];
                                                        this.setState({
                                                            displayCheckBox: false
                                                        })
                                                        for (let i = 0; i < this.state.units.length; i++) {
                                                            this.unitListToShow.push(this.state.units[i])
                                                        }
                                                        this.setSelectedUnits(item.id, this.state.selectedDivideType.id);



                                                    } else if (item.id == 2) {
                                                        this.userUnitSelected = [];
                                                        this.unitListToShow = [];

                                                        this.setState({
                                                            displayCheckBox: true
                                                        })
                                                        for (let i = 0; i < this.state.units.length; i++) {
                                                            this.unitListToShow.push(this.state.units[i])
                                                        }
                                                        this.setSelectedUnits(item.id, this.state.selectedDivideType.id);

                                                    } else if (item.id == 3) {
                                                        this.userUnitSelected = [];
                                                        this.unitListToShow = []
                                                        this.setState({
                                                            displayCheckBox: false
                                                        })
                                                        for (let i = 0; i < this.state.units.length; i++) {
                                                            if (this.state.units[i].itIsEmpty) {
                                                                this.unitListToShow.push(this.state.units[i]);
                                                            }
                                                        }
                                                        this.setSelectedUnits(item.id, this.state.selectedDivideType.id);

                                                    } else if (item.id == 4) {
                                                        this.userUnitSelected = [];
                                                        this.unitListToShow = []
                                                        this.setState({
                                                            displayCheckBox: false
                                                        })
                                                        for (let i = 0; i < this.state.units.length; i++) {
                                                            if (!this.state.units[i].itIsEmpty) {
                                                                this.unitListToShow.push(this.state.units[i]);
                                                            }
                                                        }
                                                        this.setSelectedUnits(item.id, this.state.selectedDivideType.id);

                                                    }
                                                };
                                            })
                                        } else {
                                            this.unitListToShow = [];
                                            this.setState({
                                                errText: 'نوع تقسیم‌بندی مبلغ را انتخاب کنید',
                                                errType: 'war',
                                            })
                                            this.clearErr();
                                        }


                                    } else {
                                        this.setState({
                                            errText: 'مبلغ فیش را وارد کنید',
                                            errType: 'war',
                                        })
                                        this.clearErr();
                                    }

                                }}>{item.text}</div>
                            )
                        })}
                    </NewSelect>



                    <>
                        <div className={`rtl fntBold mediumF text-right mt-2`} > عکس‌های مرتبط را اضافه کنید. </div>

                        {!this.state.uploading ?
                            <UploadImg
                                onChange={(e) => {
                                    if (e.target.files[0]) {
                                        this.uploadFile(e.target.files[0], null, null)
                                    }
                                }}
                            >
                            </UploadImg>
                            :
                            <div className={`w-100 text-center`}><img style={{ width: '10%', height: '100%' }} src={loadingGif} /></div>}
                    </>

                    {this.state.imgPath.length > 0 ?
                        <div className={`rtl mt-4 d-flex align-content-stretch flex-wrap w-100  justify-content-start align-items-baseline`}>
                            {this.state.imgPath.map((item, index) => {
                                return <div key={index} className={`text-right pb-3 rtl d-flex align-items-center`}>
                                    <div className={` `} style={{
                                        width: 110, height: 80,
                                        backgroundImage: "url(" + item + ")",
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat'
                                    }}>
                                    </div>

                                    <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} onClick={() => {
                                        let temp = this.state.imgPath;
                                        let tmp2 = this.state.imgId;
                                        temp.splice(index, 1);
                                        tmp2.splice(index, 1);

                                        this.setState({
                                            imgPath: temp,
                                            imgId: tmp2
                                        });
                                    }}> حذف</span>
                                </div>
                            })}
                        </div>
                        : null}


                    <div className={`d-flex  justify-content-between align-items-center flex-row-reverse  w-100`}>
                        <div className={`d-flex  flex-start rtl w-100`} style={{ fontSize: 12 }} >
                            <> <div style={{ width: 12, }}><img src={edit} alt={''} style={{ width: '100%' }} /></div>
                                <div className={`mr-2`} onClick={() => {
                                    this.setState({
                                        showModalUpload: true,
                                        typeOfDoc: 0,
                                    })
                                }}>ثبت گروهی فیش</div></>


                        </div>
                        <div className={``}>
                            <SearchBar
                                // search={search} 
                                onSearch={() => { }} click={() => {
                                    if (this.state.unitToShow.length > 0) {
                                        if (this.state.search) {
                                            this.searchText = '';
                                            this.searchInUnits()
                                        }
                                        this.setState({
                                            search: !this.state.search
                                        })

                                    }
                                    // if(!this.state.search){
                                    //     if(this.state.units.length>0)

                                    // this.setState({
                                    //     search: !this.state.search
                                    // })
                                    // else
                                    // {
                                    //     this.setState({
                                    //         errText:'',
                                    //         errType:'war'
                                    //     });
                                    //     this.clearErr();
                                    // }
                                    // }
                                    // else{

                                    // this.setState({
                                    //     search: !this.state.search
                                    // })
                                    //     this.searchText='';
                                    // }
                                }} />
                        </div>

                    </div>
                    {this.state.search ?
                        <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                            <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                                style={{
                                    padding: 5,
                                    border: ' 1px solid grey',
                                    borderRadius: 10
                                }}>
                                <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                    onKeyUp={event => {


                                    }}
                                    placeholder={'جستجو'}
                                    onChange={(e) => {
                                        this.searchText = e.target.value;
                                        this.searchInUnits()
                                        // this.setSelectedUnits(this.state.selectedUnitType.id, this.state.selectedDivideType.id);
                                    }} />
                                <div style={{ width: 15, }}>
                                    <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                        //searchHandler()
                                    }} />
                                </div>

                            </div>
                            {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                        </div>
                        : null}

                    {

                        this.state.unitToShow.length > 0 ?
                            this.state.unitToShow.map((item, index) => {
                                return (
                                    <div key={item.id} className={`d-flex justify-content-between align-items-center flex-row-reverse w-100 p-2  `} >
                                        <div style={{ display: this.state.displayCheckBox ? 'block' : 'none' }}>
                                            <Checkbox checked={this.userUnitSelected.find(elem => { return elem.id == item.id })}
                                                onChange={(check) => {
                                                    if (check) {
                                                        this.addToUnitList(item);
                                                    } else {
                                                        this.removeFromUnitList(item.id);
                                                    }

                                                }} /></div>
                                        <UnitList
                                            display={this.userUnitSelected.find(elem => { return elem.id == item.id })}
                                            delete={() => { this.deleteEdidet(item.id) }}
                                            showDel={this.state.editedamountOfUnits.find(elem => { return elem.unitId == item.id })}
                                            unitName={item.name}
                                            plcholder={this.amountOfPlchldr(item, this.state.amountOfUnits) ? this.amountOfPlchldr(item, this.state.amountOfUnits) : 0}
                                            onChange={(e) => { this.changeAmount(e, item.id) }}

                                        //value={this.setinputvalue(item.id).toString().replace(/,/g, '')}

                                        />
                                    </div>
                                )
                            }) :
                            null
                        // <div className={`d-flex justify-content-center align-items-center h-100 w-100`}>
                        //     <img style={{ width: '15%' }} src={load} style={{ zIndex: 100 }} />
                        // </div>


                    }





                </div>
            </div>
        )

    }
    renderItemSharj() {
        return (

            <div >
                {this.state.mounts.length > 0 ?
                    <div >

                        <div className={`p-2 d-flex align-items-center justify-content-between`}>



                            {this.state.mounts.length > 0 ?
                                <NewSelect options={this.state.mounts} plachodler={def}
                                    obj={false} sel2={false} value={this.state.selectedmount.text}>
                                    {this.state.mounts.map(item => {
                                        return (
                                            <div style={{ padding: '2%' }} key={item.key} value={item.text} onClick={() => {
                                                this.state.mounts.find(elem => {
                                                    if (elem.key == item.key) {
                                                        this.setState({
                                                            selectedmount: elem

                                                        })


                                                    }
                                                })
                                            }}>{item.text}</div>
                                        )
                                    })}
                                </NewSelect> : null}





                        </div>
                        <div className={`mt-2 mb-2 `} style={{ width: '90%', margin: '0 auto' }}>
                            {/* <CpInput plachodler={' مهلت پرداخت'} value={this.state.deadLine} type={"text"} onClick={() => {
                            this.setState({
                                showDatePicker: true
                            })
                        }} /> */}
                            <div className={`d-flex align-items-center rtl `} onClick={() => {
                                this.setState({
                                    showDatePicker: true
                                })
                            }}
                                style={{ marginBottom: 15, padding: '15.5px 12px', color: '#0000008a', border: '1px solid #424242', borderRadius: 4 }}>
                                <div>مهلت پرداخت:  </div>
                                <div className={`mr-3`} style={{ color: '#000000de' }} >{this.state.deadLine}</div>

                            </div>
                        </div>

                        <div className={`d-flex  justify-content-between align-items-center flex-row-reverse  w-100`}>
                            <div className={`d-flex  flex-start rtl w-100`} style={{ fontSize: 12 }} >
                                <> <div style={{ width: 12, }}><img src={edit} alt={''} style={{ width: '100%' }} /></div>
                                    <div className={`mr-2`} onClick={() => {
                                        this.setState({
                                            showModalUpload: true,
                                            typeOfDoc: 1,
                                        })
                                    }}>ثبت گروهی شارژ</div></>


                            </div>
                            <div className={``}>
                                <SearchBar
                                    onSearch={() => { }} click={() => {
                                        if (this.state.unitsCharge.length > 0) {
                                            if (this.state.search) {
                                                this.searchText = '';
                                                this.searchInUnitsCharge()
                                            }
                                            this.setState({
                                                search: !this.state.search
                                            })
                                        }

                                    }} />
                            </div>

                        </div>

                        {this.state.search ?
                            <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                                <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                                    style={{
                                        padding: 5,
                                        border: ' 1px solid grey',
                                        borderRadius: 10
                                    }}>
                                    <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                        onKeyUp={event => {


                                        }}
                                        placeholder={'جستجو'}
                                        onChange={(e) => {
                                            this.searchText = e.target.value;
                                            this.searchInUnitsCharge()
                                            //setSearchText(e.target.value)
                                        }} />
                                    <div style={{ width: 15, }}>
                                        <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                            //searchHandler()
                                        }} />
                                    </div>

                                </div>
                                {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                            </div>
                            : null}



                        {this.state.unitToShowForCharge.map((item, index) => {

                            return (
                                <div key={index} className={`d-flex justify-content-between align-items-center flex-row-reverse w-100 p-2  `} >
                                    <UnitList
                                        display={true}
                                        // delete={() => { this.deleteEdidet(item.id) }}
                                        // showDel={this.state.editedamountOfUnits.find(elem => { return elem.unitId == item.id })}
                                        unitName={item.unit.name}
                                        plcholder={item.amount}
                                        onChange={(e) => {
                                            this.amountOfcharge = this.amountOfcharge.filter(item1 => item1.unitId !== item.unitId);
                                            if (e.target.value.length > 0)
                                                this.amountOfcharge.push({ unitId: item.unitId, amount: e.target.value });



                                        }}
                                    // value={}
                                    //value={formatMoney(this.setinputvalue(item.id))}

                                    />
                                </div>
                            )
                        })}


                    </div> : <NoAccess Text={'شما شارژهای تا ماه آتی را ثبت کرده‌اید. جهت ثبت شارژ اضافه بر سازمان می‌توانید از تب فیش استفاده نمایید'}></NoAccess>}
            </div>

        )
    }







    getData() {
        // if(!localStorage.getItem('id')){
        //     this.props.history.push('/');

        // }
        localStorage.getItem('id')
        this.setState({
            loading: true
        })
        if (this.state.isLoad != false)
            this.setState({
                isLoad: false,
            });
        this.getBuildingUnits(localStorage.getItem('id'));
        this.getMyBuildings();
        this.getCategory();
        this.getChargeTemp(localStorage.getItem('id'));
    }




    downloadExelSample() {
        this.setState({
            downloading: true
        })
        downloadExcel(`/AccRequest/RequestDocExcelTemplate/${localStorage.getItem('id')}`,'SampleReceipt')
            .then(res => {
                // console.log(res);
                this.setState({
                    downloading: false
                })
            })
            .catch(err=>{

                this.setState({
                    errText: 'دانلود با خطا مواجه شد',
                    errType: 'err',
                    downloading: false
                })
                this.clearErr()

            })
    };



    uploadExcel(id, type) {

        ApiService('Get', `/AccRequest/GetRequestDocBaseFile/${localStorage.getItem('id')}/${id}/${type}`)
            .then(res => {
                if (res.data.result) {

                    if (type == 1) {
                        this.setState({
                            unitsCharge: res.data.data.dividedRequests,
                            unitToShowForCharge: res.data.data.dividedRequests,
                            errText: 'فایل با موفقیت بارگذاری شد',
                            errType: 'suc',
                            showModalUpload: false,
                            uploading: false,


                        })

                    }
                    else {
                        this.excelunit = res.data.data.dividedRequests;
                        let tmp = [];
                        let tmpunit = [];
                        res.data.data.dividedRequests.forEach(element => {

                            tmpunit.push(element.unit);
                            tmp.push({
                                unitId: element.unitId, amount: element.amount
                            })

                        });

                        this.unitListToShow = tmpunit;
                        this.userUnitSelected = tmpunit;
                        this.userUnitSelected = tmpunit;
                        this.setState({
                            unitToShow: tmpunit
                        })





                        this.setState({
                            amount: res.data.data.amount,
                            selectedDivideType: {
                                id: 5,
                                text: 'مطابق اکسل',

                            },
                            selectedUnitType: {
                                id: 1,
                                text: ' همه واحدها ',

                            },

                            amountOfUnits: tmp,
                            errText: 'فایل با موفقیت بارگذاری شد',
                            errType: 'suc',
                            showModalUpload: false,
                            uploading: false,


                        })

                    }
                    this.clearErr()



                } else {
                    this.setState({
                        errText: res.data.message,
                        errType: 'err',
                        uploading: false,


                    })


                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    uploading: false,


                })
                this.clearErr();
                return

            })

    }


    addToUnitList(item) {
        this.userUnitSelected.push(item);
        this.computingUnitsAmount(this.state.selectedDivideType.id);
    }


    getChargeTemp(buildingId) {
        ApiService('Get', `/AccRequest/GetChargeRequestDocBaseTemplate/${buildingId}`)
            .then(response => {
                let tmp = null;

                if (response.data.result) {

                    response.data.data.availableMonth.forEach(element => {
                        if (element.key == response.data.data.chargeYearMonthNum)
                            tmp = element;
                    });
                    if (response.data.resultCode == 100) {

                        console.log('show modal');
                    }


                    this.setState({
                        unitsCharge: response.data.data.dividedRequests,
                        unitToShowForCharge: response.data.data.dividedRequests,
                        mounts: response.data.data.availableMonth,
                        selectedmount: tmp
                    });

                    this.checkLoadedAll();
                } else {

                    this.setState({
                        loading: false,
                        activeTab: 1
                    })
                }

            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    loading: false,


                })
                this.clearErr();
                return

            })
    }


    removeFromUnitList(item) {
        this.userUnitSelected = this.userUnitSelected.filter(item1 => item1.id !== item);
        this.forceUpdate();
        this.computingUnitsAmount(this.state.selectedDivideType.id);
    }

    getMyBuildings() {
        this.loadNode++;
        MyBuildingList(false).then(res => {
            let list = res;
            if (list) {
                this.setState({
                    buildingList: list,
                    selectedBulding: list.find(elem => { return elem.id == localStorage.getItem('id') }),
                    isLoad: true

                });

            }
            this.checkLoadedAll();

        })
            .catch(err => {
                this.setState({
                    errText: err.data.message,
                    errType: 'war',
                    loading: false
                })
            })
    }
    getBuildingUnits(id) {
        this.loadNode++;
        let data = {
            nameLike: '',
            pageSize: 1000,
            pageNumber: 0
        }
        ApiService('Post', `/Building/Units/${id}`, data)
            .then(response => {
                if (response.data.result) {
                    this.setState({
                        units: response.data.data,
                    })
                    // setUnitList(response.data.data)
                    // setLoading(false);
                }

                else {
                    this.setState({
                        errText: response.data.message,
                        errType: 'war',
                        loading: false
                    })

                    this.clearErr();

                }

                this.checkLoadedAll();
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    loading: false,


                })
                this.clearErr();
                return
            })
    }

    getCategory() {
        this.loadNode++;
        ApiService('Get', `/AccountingCategory/GetList/${localStorage.getItem('id')}`)
            .then(response => {
                if (response.data.result) {
                    this.setState({
                        categoryList: response.data.data,
                    });


                } else {
                    this.setState({
                        errText: response.data.message,
                        errType: 'war',
                        loading: false
                    })
                }
                this.checkLoadedAll();

            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    loading: false,


                })
                this.clearErr();
                return

            })
    }

    submitCategory(text) {
        if (!this.state.categoryList.find(elem => {
            return elem.text == text
        })) {
            this.setState({
                loading: true,

            });
            let data = {
                id: '0',
                text: text
            }
            ApiService('Post', `/AccountingCategory/Submit/${this.id}`, data)
                .then(response => {
                    if (response.data.result) {
                        this.setState({
                            loading: false,
                            selectedCategoryType: response.data.data.text,

                        });



                        this.submitwithNewCategory(response.data.data)


                    } else {
                        this.setState({
                            errText: response.data.message,
                            errType: 'war',
                            loading: false
                        })
                    }
                })
                .catch(err => {
                    this.setState({
                        errText: 'خطای داخلی سرور',
                        errType: 'err',
                        loading: false,
                    })
                    this.clearErr();
                    return

                })

        }



    }



    submitwithNewCategory(catg) {
        this.setState({
            isSending: true,

        })
        if (this.state.title.length < 1) {
            this.setState({
                errText: 'عنوان هزینه را وارد کنید ',
                errType: 'war',
                isSending: false
            })
            this.clearErr();
            return
        }
        if (!this.state.amount) {
            this.setState({
                errText: 'مبلغ فیش را وارد کنید  ',
                errType: 'war',
                isSending: false
            });
            this.clearErr();
            return
        }

        if (this.state.selectedDivideType.length < 1) {
            this.setState({
                errText: 'نوع تقسیم‌بندی فیش را انتخاب کنید  ',
                errType: 'war',
                isSending: false
            });
            this.clearErr();
            return
        }
        if (this.state.selectedUnitType.length < 1) {
            this.setState({
                errText: 'گروه واحدها را انتخاب کنید  ',
                errType: 'war',
                isSending: false
            });
            this.clearErr();
            return
        }
        if (this.state.amountOfUnits.length < 1) {
            this.setState({
                errText: 'واحدی جهت فیش انتخاب نشده است  ',
                errType: 'war',
                isSending: false
            });
            this.clearErr();
            return
        }
        if (this.state.selectedTypeDoc == '') {
            this.setState({
                errText: 'نوع دسته‌بندی هزینه را انتخاب کنید  ',
                errType: 'war',
                isSending: false
            });
            this.clearErr();
            return
        }
        let catgory = catg
        // let images = [];
        // if (this.state.imgId) {
        //     images.push({
        //         id: this.state.imgId
        //     })
        // }
        let images = [];
        if (this.state.imgId.length > 0) {
            this.state.imgId.forEach(element => {
                images.push({
                    id: element
                })
            });

        }
        let data = {
            requestType: 0,
            accountingCategory: catgory,
            subject: this.state.title,
            description: this.state.description,
            amount: parseInt(FaToEnNum(this.state.amount)),

            usersType: this.state.userType,

            buildingId: parseInt(this.id),
            //isPublished: true,
            dividedRequests: this.state.amountOfUnits,
            files: images
        }
        if (this.state.deadLine.length > 0) {
            data.deadLineDate = this.state.deadLine
        }
        ApiService('Post', `/AccRequest/SubmitRequestDocument/${localStorage.getItem('id')}`, data)
            .then(response => {
                if (response.data.result) {
                    this.setState({
                        errText: 'با موفقیت ثبت شد. ',
                        errType: 'suc',

                    });

                    setTimeout(() => {
                        // this.props.history.push('/receipt')
                        this.props.history.push({
                            pathname: `/receiptDetail/${response.data.data}`,

                        })
                    }, 2000)
                } else {
                    this.setState({
                        errText: response.data.message,
                        errType: 'war',
                        loading: false,
                        isSending: false,
                    })


                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    loading: false,
                    isSending: false,
                });
                this.clearErr();
                return

            })
    }

    //const [amount , setAmount]= useState();
    uploadFile(files, excel, type) {
        this.setState({
            uploading: true
        })

        attachFile(files).then(res => {
            if (excel) {
                this.uploadExcel(res[0].id, type);

            } else {
                let tmpImgId = this.state.imgId;
                let tmpImgPath = this.state.imgPath;
                tmpImgId.push(res[0].id);
                tmpImgPath.push(res[0].path)

                this.setState({
                    imgId: tmpImgId,
                    imgPath: tmpImgPath,
                    uploading: false
                })

                // this.setState({
                //     imgId: res[0].id,
                //     imgPath: res[0].path,
                //     uploading: false
                // })

            }

        })
            .catch(err => {

            })

    }
    setinputvalue(index) {
        let tmp = '';
        this.state.editedamountOfUnits.forEach(element => {
            if (element.unitId == index)
                tmp = element.amount;

        });
        return tmp;
    }

    changeAmount(e, index) {
        if (e.target.value == null || e.target.value.length < 1) {
            this.removeFromUnitList(index);
            this.deleteEdidet(index);
        }
        else {
            let tmp = this.state.editedamountOfUnits;
            let tmp1 = true;
            tmp.forEach(element => {
                if (element.unitId == index) {
                    tmp1 = false;
                    element.amount = parseInt(FaToEnNum(e.target.value));
                }
            });
            if (tmp1)
                tmp.push({
                    unitId: index,
                    amount: parseInt(FaToEnNum(e.target.value))
                });

            this.setState({ editedamountOfUnits: tmp });

            this.computingUnitsAmount(this.state.selectedDivideType.id);



        }
    }


    async deleteEdidet(index) {
        let tmp = this.state.editedamountOfUnits.filter(item => index !== item.unitId);
        await this.setState({ editedamountOfUnits: tmp });
        this.computingUnitsAmount(this.state.selectedDivideType.id);

    }

    computingUnitsAmount(divisionType) {
        let otheramount = 0;
        // if(this.state.amount){
        if ((this.state.amount < 1)) {
            this.setState({
                errText: 'مبلغ فیش را وارد کنید',
                errType: 'war',
            })
            this.clearErr();
            return
        }

        // }


        if (this.userUnitSelected.length > 0) {
            let tmpunit = this.userUnitSelected;
            let tmplistamount = this.excelunit;
            let NewArray = []
            this.state.editedamountOfUnits.forEach(element => {
                tmpunit = tmpunit.filter(item => element.unitId !== item.id);
                tmplistamount = tmplistamount.filter(item => element.unitId !== item.unitId);
                otheramount += element.amount;
                NewArray.push({
                    unitId: element.unitId,
                    amount: element.amount
                })

            });
            let initialAmount = parseInt(FaToEnNum(this.state.amount)) - otheramount;







            if (divisionType == 1) {


                let unitAmount = Math.round(initialAmount / tmpunit.length + 0.49);
                this.setState({
                    amountOfUnits: []
                })

                for (let i = 0; i < tmpunit.length; i++) {
                    NewArray.push({
                        unitId: tmpunit[i].id,
                        amount: unitAmount
                    })
                }
                this.setState({
                    amountOfUnits: NewArray
                })

            } else if (divisionType == 2) {
                let cont = 0;

                tmpunit.forEach(element => {
                    cont += parseInt(element.parkingCount);
                });

                if (cont == 0 && this.state.editedamountOfUnits.length == 0) {
                    this.setState({
                        errText: 'لطفا پیش از ثبت سند اطلاعات تعداد پارکینگ ها را کامل نمایید',
                        errType: 'war',
                    })
                    this.clearErr();
                    return;
                }
                let unitAmount = initialAmount / cont;
                this.setState({
                    amountOfUnits: []
                })

                for (let i = 0; i < tmpunit.length; i++) {
                    NewArray.push({
                        unitId: tmpunit[i].id,
                        amount: Math.round(unitAmount * parseInt(tmpunit[i].parkingCount) + 0.49)
                    });
                }
                this.setState({
                    amountOfUnits: NewArray
                })


            } else if (divisionType == 3) {
                let cont = 0;
                tmpunit.forEach(element => {
                    cont += parseInt(element.area);
                });

                if (cont == 0 && this.state.editedamountOfUnits.length == 0) {
                    this.setState({
                        errText: 'لطفا پیش از ثبت سند اطلاعات متراژ ها را کامل نمایید',
                        errType: 'war',
                    })
                    this.clearErr();
                    return;
                }
                let unitAmount = initialAmount / cont;
                this.setState({
                    amountOfUnits: []
                })

                for (let i = 0; i < tmpunit.length; i++) {
                    NewArray.push({
                        unitId: tmpunit[i].id,
                        amount: Math.round(unitAmount * parseInt(tmpunit[i].area) + 0.49)
                    });
                }
                this.setState({
                    amountOfUnits: NewArray
                })

            }

            else if (divisionType == 4) {
                let cont = 0;
                tmpunit.forEach(element => {
                    cont += parseInt(element.personsCount);
                });
                if (cont == 0 && this.state.editedamountOfUnits.length == 0) {
                    this.setState({
                        errText: 'لطفا پیش از ثبت سند اطلاعات تعداد نفرات را کامل نمایید',
                        errType: 'war',
                    })
                    this.clearErr();
                    return;
                }
                let unitAmount = initialAmount / cont;
                this.setState({
                    amountOfUnits: []
                })

                for (let i = 0; i < tmpunit.length; i++) {
                    NewArray.push({
                        unitId: tmpunit[i].id,
                        amount: Math.round(unitAmount * parseInt(tmpunit[i].personsCount) + 0.49)
                    });
                }
                this.setState({
                    amountOfUnits: NewArray
                })

            }
            else if (divisionType == 5) {

                let tmp = otheramount;
                tmplistamount.forEach(element => {
                    tmp += element.amount;
                });
                tmplistamount.forEach(element => {
                    NewArray.push({
                        unitId: element.unitId,
                        amount: element.amount
                    })
                });

                this.setState
                    ({
                        amountOfUnits: NewArray,
                        amount: tmp
                    })


            }


        }
        else {
            this.setState({
                amountOfUnits: []
            });
        }

    }

    setSelectedUnits(unitGroupType, divisionType) {
        if (unitGroupType != 2) {

            for (let i = 0; i < this.unitListToShow.length; i++) {
                this.userUnitSelected.push(this.unitListToShow[i])
            }
        }
        this.computingUnitsAmount(divisionType);
        this.searchInUnits();
    }

    amountOfPlchldr(item, amountArray) {
        if (amountArray.length > 0) {
            for (var i = 0; i < amountArray.length; i++) {
                if (amountArray[i].unitId == item.id) {
                    return amountArray[i].amount
                }
            }
        } else {
            return 0
        }
    }


    submitRecipt() {
        this.setState({isSending:true,})
        if (this.state.title.length < 1) {
            this.setState({
                errText: 'عنوان هزینه را وارد کنید ',
                errType: 'war'
            })
            this.clearErr();
            return
        }
        if (!this.state.amount) {
            this.setState({
                errText: 'مبلغ فیش را وارد کنید  ',
                errType: 'war'
            });
            this.clearErr();
            return
        }

        if (this.state.selectedDivideType.length < 1) {
            this.setState({
                errText: 'نوع تقسیم‌بندی فیش را انتخاب کنید  ',
                errType: 'war'
            });
            this.clearErr();
            return
        }
        if (this.state.selectedUnitType.length < 1) {
            this.setState({
                errText: 'گروه واحدها را انتخاب کنید  ',
                errType: 'war'
            });
            this.clearErr();
            return
        }
        if (this.state.amountOfUnits.length < 1) {
            this.setState({
                errText: 'واحدی جهت فیش انتخاب نشده است  ',
                errType: 'war'
            });
            this.clearErr();
            return
        }
        if (!this.state.selectedTypeDoc) {
            this.setState({
                errText: 'نوع دسته‌بندی هزینه را انتخاب کنید  ',
                errType: 'war'
            });
            this.clearErr();
            return
        }
        if (this.state.categoryList.find(elem => elem.text == this.state.selectedTypeDoc)) {
            let catgory = this.state.categoryList.find(elem => { return elem.text == this.state.selectedTypeDoc });
            let images = [];
            if (this.state.imgId.length > 0) {
                this.state.imgId.forEach(element => {
                    images.push({
                        id: element
                    })
                });

            }
            let data = {
                requestType: 0,
                accountingCategory: catgory,
                subject: this.state.title,
                description: this.state.description,
                amount: parseInt(FaToEnNum(this.state.amount)),

                usersType: this.state.userType,

                buildingId: parseInt(this.id),
                //isPublished: true,
                dividedRequests: this.state.amountOfUnits,
                files: images
            }
            if (this.state.deadLine.length > 0) {
                data.deadLineDate = this.state.deadLine
            }



            ApiService('Post', `/AccRequest/SubmitRequestDocument/${localStorage.getItem('id')}`, data)
                .then(response => {
                    if (response.data.result) {
                        this.setState({
                            errText: 'با موفقیت ثبت شد. ',
                            errType: 'suc',
                        });
                        setTimeout(() => {
                            //this.props.history.push('/receipt')
                            this.props.history.push({
                                pathname: `/receiptDetail/${response.data.data}`,

                            })
                        }, 2000)
                    }
                    else {
                        this.setState({
                            errText: response.data.message,
                            errType: 'war',
                            isSending: false
                        });
                        this.clearErr();
                        return
                    }
                })
                .catch(err => {
                    this.setState({
                        errText: 'خطای داخلی سرور',
                        errType: 'err',

                        isSending: false,
                    });
                    this.clearErr();
                    return

                })
        } else {
            this.submitCategory(this.state.selectedTypeDoc)
        }

    }

    submitCharge() {

        this.setState({isSending:true,})
        let tmp = [];
        this.state.unitsCharge.forEach(element => {
            tmp.push({ unitId: element.unitId, amount: element.amount });

        });
        this.amountOfcharge.forEach(element => {

            tmp = tmp.filter(item1 => item1.unitId !== element.unitId);


            tmp.push({ unitId: element.unitId, amount: parseInt(element.amount) })
        });
        let amo = 0
        tmp.forEach(element => {
            amo += element.amount
        });

        if (amo == 0) {
            this.setState({
                errText: 'مبلغ شارژ را وارد نمایید',
                errType: 'war'
            });
            this.clearErr();
        }
        else {
            let data = {
                usersType: 2,
                requestType: 1,
                buildingId: parseInt(this.id),
                amount: amo,
                //isPublished: true,

                dividedRequests: tmp,
                subject: 'شارژ ' + this.state.selectedmount.text,
                chargeYearMonthNum: this.state.selectedmount.key,
                chargeIsCustomized: (this.amountOfcharge.length > 0 ? true : false),
            }
            if (this.state.deadLine.length > 0) {
                data.deadLineDate = this.state.deadLine
            }
            ApiService('Post', `/AccRequest/SubmitRequestDocument/${localStorage.getItem('id')}`, data)
                .then(response => {
                    if (response.data.result) {
                        this.setState({
                            errText: 'شارژ ' + this.state.selectedmount.text + ' با موفقیت ثبت شد. ',
                            errType: 'suc',
                        });
                        setTimeout(() => {
                            //this.props.history.push('/receipt')
                            this.props.history.push({
                                pathname: `/receiptDetail/${response.data.data}`,

                            })
                        }, 2000)
                    }
                    else {
                        this.setState({
                            errText: response.data.message,
                            errType: 'war'
                        });
                        this.clearErr();
                        return
                    }
                })
                .catch(err => {
                    this.setState({
                        errText: 'خطای داخلی سرور',
                        errType: 'err',
                        loading: false,
                    });
                    this.clearErr();
                    return

                })
        }
    }


    checkLoadedAll() {
        if (this.state.categoryList.length > 0 && this.state.units.length > 0 && this.state.buildingList.length > 0 && this.state.unitsCharge.length > 0) {
            this.setState({
                loading: false,
                renderItem: this.renderItemFish()
            })

            this.ActiveTabHandler(this.props.location.state && this.props.location.state.tab ? this.props.location.state.tab : 1);
        }

    }
    faildLoad() {
        this.loadNode = 0;
        this.loadedNode = 0;
        this.setState({ isLoad: 'error' });
    }

    clearErr() {
        setTimeout(() => {
            this.setState({
                errText: '',
                errType: '',
                isSending:false,
            })
        }, 2000)
    }

    async ActiveTabHandler(tabid) {
        await this.setState({
            activeTab: tabid,

        })
    }
    closeSelect() {
        this.setState({
            showOptions: false
        })
        this.NewSelect && this.NewSelect.lastObj && this.NewSelect.lastObj.close();
    }






    render() {
        return (
            <Layout loaded={true} title={' ثبت فیش‌'} isHome={false} btn={this.state.uploading || (this.state.activeTab == 2 && (!this.state.mounts || this.state.mounts.length < 1)) ? false :


                true} sending={this.state.isSending} click={() => {
                    this.state.activeTab == 1 ?
                        this.submitRecipt() : this.submitCharge()
                }} btnName={this.state.activeTab == 1 ? 'ثبت' : 'صدور شارژ'} loading={this.state.loading} gradian>
                <Toast
                    text={this.state.errText}
                    type={this.state.errType}
                />
                <DatePicker showPicker={this.state.showDatePicker} disMiss={() => {
                    this.setState({
                        showDatePicker: false
                    })
                }} click={(e) => {
                    this.setState({
                        deadLine: e,
                        showDatePicker: false
                    })


                }} />
                <ModalUploadExcel
                    showModal={this.state.showModalUpload}
                    title={
                        this.state.activeTab == 1 ?
                            ' شما می‌توانید از طریق فایل نمونه زیر فیش  را در قالب فایل اکسل ثبت کنید.' :

                            ' شما می‌توانید از طریق فایل نمونه زیر شارژ  را در قالب فایل اکسل ثبت کنید.'
                    }
                    close={() => {
                        this.setState({
                            showModalUpload: false
                        })
                    }}
                    downloadSending={this.state.downloading}
                    sending={this.state.isSending}
                    download={() => {
                        this.downloadExelSample();
                    }}
                    upload={(e) => {
                        if (e.target.files[0]) {
                            this.uploadFile(e.target.files[0], true, this.state.typeOfDoc)
                        }
                    }}
                />
                <div style={{ height: 'inherit' }}>
                    {this.state.buildingList.length > 0 ? <NewSelect options={this.state.buildingList} plachodler={def}
                        obj={false} sel2={false} value={this.state.selectedBulding} closeOther={() => { this.closeSelect() }}>
                        {this.state.buildingList.map(item => {
                            return (
                                <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                    this.state.buildingList.find(elem => {
                                        if (elem.id == item.id) {
                                            this.setState({
                                                selectedBulding: elem,
                                                units: [],
                                                categoryList: [],
                                                selectedTypeDoc: '',
                                                selectedUnitType: '',
                                            })
                                            this.unitListToShow = [];
                                            localStorage.setItem('id', elem.id);
                                            this.getData();

                                        }
                                    })
                                }}>{item.name}</div>
                            )
                        })}
                    </NewSelect> : null}

                    <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`} >

                        {tabItems.map(item => {
                            return (<TabBar title={item.title} key={item.id} id={item.id} activeIndex={this.state.activeTab} click={() => {
                                this.ActiveTabHandler(item.id);
                                this.setState({
                                    deadLine: ''
                                })
                            }} />)
                        })}

                    </div>
                    {this.state.activeTab == 1 ?
                        <div className={`w-100 bb`} style={{ height: `calc(100% - 12rem)`, overflow: 'auto' }}>{this.renderItemFish()}
                            {/* <div style={{height:'15%'}}></div> */}
                        </div> : <div className={`w-100 bb`} style={{ height: `calc(100% - 12rem)`, overflow: 'auto' }}>{this.renderItemSharj()}</div>}
                </div>
            </Layout>
        )
    }
}
export default withRouter(AddReceipt);
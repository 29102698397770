import React, { Component, useState } from 'react';
import Layout from '../../../Components/Layout/Layout';
import CpInput from '../../../Components/Input/Input';
import NewSelect from '../../../Components/NewSelect/NewSelect';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import { ApiService, attachFile, formatMoney } from '../../../value';
import { data } from 'jquery';
import Toast from '../../../Components/Toast/Toast';
import arrow from '../../../Assets/myAsset/down-arrow.svg'
import { withRouter } from 'react-router-dom';
import loadingGif from '../../../Assets/gifs/22.gif'
import UploadImg from '../../../Components/UploadImg/UploadImg';







const sampleOp = [
  {
    id: 1,
    text: 'تعمیرات',

  },
  {
    id: 2,
    text: 'تاسیسات',

  },
  {
    id: 3,
    text: 'اجاره',

  },

];



const def2 =
{
  id: -1,
  text: '*انتخاب صندوق',

};
let Self = null;
class AddDoc extends Component {
  constructor(props) {
    super(props);
    Self = this;
    this.state = {
      loading: true,
      income: null,
      expense: null,
      active: this.props.location.state ? this.props.location.state.doc.priceType : 1,

      edit: this.props.location.state ? true : false,

      errText: '',
      errType: '',
      addCtgry: false,
      sending: false,


      imgId: [],
      imgPath: (this.props.location.state && this.props.location.state.doc.attachments) ? this.props.location.state.doc.attachments : [],
      uploading: false,
      docType: this.props.location.state ? this.props.location.state.doc.priceType : '',

      id: this.props.location.state ? this.props.location.state.doc.id : 0,
      categoryList: [],
      desksList: [],
      selectedDesk: this.props.location.state ? this.props.location.state.doc.cashDesk : '',
      selectedTypeDoc: this.props.location.state && this.props.location.state.doc.accountingCategory ? this.props.location.state.doc.accountingCategory.text : '',
      selectedCategoryType: null,
      title: this.props.location.state ? this.props.location.state.doc.subject : '',
      amount: null,
      description: this.props.location.state ? this.props.location.state.doc.description : '',
      categoryName: '',
      showOptions: false


    }
    this.id = localStorage.getItem('id');
    this.Amount = this.props.location.state ? this.props.location.state.doc.amount : null;
  }

  componentDidMount() {
    if (this.id == null) {
      this.props.history.push('/')
    } else {
      this.getCategory();
    }


  }
  getCategory() {
    ApiService('Get', `/AccountingCategory/GetList/${this.id}`)
      .then(response => {
        if (response.data.result) {
          this.setState({
            categoryList: response.data.data,
          });
          this.getDesksList();

        } else {
          this.setState({
            errText: response.data.message,
            errType: 'war',
            loading: false
          })
        }
      })
      .catch(err => {
        this.setState({
          errText: 'خطای داخلی سرور',
          errType: 'err',
          loading: false
        });
        this.clearErr();

      })
  }

  getDesksList() {
    ApiService('Get', `/AccRequest/GetSubmitDocumentCashDesks/${this.id}/${this.state.active}`)
      .then(response => {
        if (response.data.result) {
          this.setState({
            desksList: response.data.data,
            loading: false
          })
        } else {
          this.setState({
            errText: response.data.message,
            errType: 'war',
            loading: false
          })
        }
      })
      .catch(err => {
        this.setState({
          errText: 'خطای داخلی سرور',
          errType: 'err',
          loading: false
        });
        this.clearErr();

      })
  }

  uploadFile(files) {
    this.setState({
      uploading: true,
     
        sending: true
      
    })

    attachFile(files).then(res => {

      // this.setState({
      //   imgId: res[0].id,
      //   imgPath: res[0].path,
      //   uploading: false
      // })
      let tmpImgId = this.state.imgId;
      let tmpImgPath = this.state.imgPath;
      tmpImgId.push(res[0].id);
      tmpImgPath.push(res[0].path)

      this.setState({
          imgId: tmpImgId,
          imgPath: tmpImgPath,
          uploading: false,
          sending: false
      })



    })
      .catch(err => {

      })

  }

  deleteFile(accDocumentId,fileId){
    this.setState({
      sending: true
    })
    ApiService('Get', `/AccRequest/AccDocumentDeleteFile/${this.id}/${accDocumentId}/${fileId}`)
      .then(response => {
        if (response.data.result) {
          this.setState({
            sending: false
          })
         
        }else{

          this.setState({
            errText: response.data.message,
            errType: 'war',
            loading: false
          })


         
        }
      })
      .catch(err => {
        this.setState({
          errText: 'خطای داخلی سرور',
          errType: 'err',
          loading: false
        });
        this.clearErr();

      })

  }


  submitCategory(text) {
    this.setState({
      loading: true
    });
    let data = {
      id: '0',
      text: text
    }
    ApiService('Post', `/AccountingCategory/Submit/${this.id}`, data)
      .then(response => {
        if (response.data.result) {
          this.setState({
            loading: false,
            selectedCategoryType: response.data.data
          });
          let data = {
            id: this.state.id,
            priceType: this.state.active,
            subject: this.state.title,
            description: this.state.description,
            accountingCategory: {
              id: response.data.data.id,
              text: response.data.data.text
            },
            cashDesk: {
              id: `${this.state.selectedDesk.id}`
            },
            amount: parseInt(Self.Amount.getValue())
          }
          let images = [];
          if (this.state.imgId.length>0) {
              this.state.imgId.forEach(element => {
                   images.push({
                  id: element
              })
              });

             data.attachments = images
          }
          ApiService('Post', `/AccRequest/SubmitDocument/${this.id}`, data)
            .then(response => {
              if (response.data.result) {
                this.setState({
                  errText: 'سند با موفقیت ثبت شد',
                  errType: 'suc',
                });
                setTimeout(() => {
                  this.props.history.push({
                    pathname: '/docView',
                    state: {
                      id: response.data.data
                    }
                  })
                }, 3000)
              } else {
                this.setState({
                  errText: response.data.message,
                  errType: 'war',
                  sending: false,
                  loading: false,
                });
              }

            })
            .catch(err => {
              this.setState({
                errText: 'خطای داخلی سرور',
                errType: 'err',
                sending: false,
                loading: false,
              });
              this.clearErr();

            })

        } else {
          this.setState({
            errText: response.data.message,
            errType: 'war',
            sending: false
          })
        }
      })
      .catch(err => {
        this.setState({
          errText: 'خطای داخلی سرور',
          errType: 'err',
          loading: false,
          sending: false,
        });
        this.clearErr();

      })
  }

  submitDocument() {

    if (this.state.title.length < 1) {
      this.setState({
        errText: 'عنوان را وارد کنید',
        errType: 'war',
      });
      this.clearErr();
      return
    }
    if (!Self.Amount.getValue()) {
      this.setState({
        errText: 'مبلغ را وارد کنید',
        errType: 'war',
      });
      this.clearErr();
      return
    }
    if (this.state.selectedTypeDoc.length < 1) {
      this.setState({
        errText: 'دسته‌بندی مورد نظر را انتخاب کنید',
        errType: 'war',
      });
      this.clearErr();
      return
    }
    if (this.state.selectedDesk.length < 1) {
      this.setState({
        errText: 'صندوق مورد نظر را انتخاب کنید',
        errType: 'war',
      });
      this.clearErr();
      return
    }

    this.setState({
      sending: true
    })
    if (this.state.categoryList.find(elem => elem.text == this.state.selectedTypeDoc)) {
      let catgory = this.state.categoryList.find(elem => { return elem.text == this.state.selectedTypeDoc });

      this.setState({
        selectedCategoryType: catgory
      });
      let data = {
        id: this.state.id,
        priceType: this.state.active,
        subject: this.state.title,
        description: this.state.description,
        attachments: [],
        accountingCategory: {
          id: catgory.id,
          text: catgory.text
        },
        cashDesk: {
          id: `${this.state.selectedDesk.id}`
        },
        amount: parseInt(Self.Amount.getValue())
      }




      let images = [];
            if (this.state.imgId.length>0 ) {
                this.state.imgId.forEach(element => {
                     images.push({
                    id: element
                })
                });
               data.attachments = images
            }
            if (this.state.imgPath.length>0 ) {
              this.state.imgPath.forEach(element => {
                   images.push({
                  id: element.id
              })
              });

             data.attachments = images
          }
      // if (this.state.imgId) {
      //   data.attachments.push({
      //     id: this.state.imgId
      //   })
      // }

      ApiService('Post', `/AccRequest/SubmitDocument/${this.id}`, data)
        .then(response => {
          if (response.data.result) {
            this.setState({
              errText: this.state.edit ? 'سند با موفقیت ویرایش شد' :
                'سند با موفقیت ثبت شد',
              errType: 'suc',
            });
             setTimeout(() => {
            //   this.props.history.push({
            //     pathname: '/docView',
            //     state: {
            //       id: response.data.data
            //     }
            //   })
              this.props.history.push({
                pathname: '/fund'
              })


            }, 2000)
          } else {
            this.setState({
              errText: response.data.message,
              errType: 'war',
              sending: false
            });
          }
        })
        .catch(err => {
          this.setState({
            errText: 'خطای داخلی سرور',
            errType: 'err',
            loading: false,
            sending: false
          });
          this.clearErr();

        })
    } else {
      this.submitCategory(this.state.selectedTypeDoc);
    }
  }



  clearErr() {
    setTimeout(() => {
      this.setState({
        errText: '',
        errType: '',
      })
    }, 3000)
  }

  closeSelect() {
    this.setState({
      showOptions: false
    })
    this.NewSelect && this.NewSelect.lastObj && this.NewSelect.lastObj.close();
  }
  async handleOptions() {
    await this.setState({
      showOptions: !this.state.showOptions,
    })

  }


  render() {
    return (
      <Layout loaded={true} title={!this.state.edit ? ' ثبت سند جدید' : 'ویرایش سند'} isHome={false} showMenu={false} loading={this.state.loading} >
        <Toast
          text={this.state.errText}
          type={this.state.errType}
        />
        <div style={{ height: 'inherit' }}>
          <div style={{ height: `calc(100% - 4rem)`, overflow: 'auto', width: '90%', marginLeft: '5%' }}>
            {!this.state.edit ? <div className={`d-flex justify-content-between align-items-center flex-row-reverse`}>
              <div className={this.state.active == 1 ? `gradian pt-2 pb-2    text-center` : ` pt-2 pb-2    text-center`}
                onClick={() => {
                  if (!this.state.edit) {
                    this.setState({
                      active: 1
                    })
                  }
                }} style={{ color: this.state.active == 1 ? "white" : '#212522', fontSize: 13, borderRadius: 30, width: '40%' }} >
                هزینه
            </div>
              <div className={this.state.active == 0 ? `gradian pt-2 pb-2    text-center` : ` pt-2 pb-2    text-center`} onClick={() => {
                if (!this.state.edit) {
                  this.setState({
                    active: 0
                  })
                }
              }}
                style={{ color: this.state.active == 0 ? "white" : '#212522', borderRadius: 30, fontSize: 13, width: '40%' }}>
                درآمد
            </div>
            </div> :
              <div className={`w-25 text-light mb-2 text-center mx-auto`}
                style={{ backgroundColor: this.state.docType == 1 ? '#E33742' : '#0adf7b', borderRadius: 10 }}>
                {this.state.docType == 0 ? 'درآمد' : 'هزینه'}</div>}
            <NewSelect options={this.state.desksList} plachodler={def2} closeOther={() => { this.closeSelect() }}
              obj={false} sel2={true}
              value={this.state.selectedDesk ? this.state.selectedDesk : ''} >
              <div >
                {this.state.desksList.map(item => {
                  return (
                    <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={(e) => {

                      this.state.desksList.find(elem => {
                        if (elem.id == item.id) {
                          this.setState({
                            selectedDesk: elem
                          })
                        }
                      })
                    }}>{item.name}( <span style={{ color: item.balance < 0 ? 'salmon' : '' }}>{item.balance < 0 ? `${formatMoney(item.balance).replace('-', '')}-` : formatMoney(item.balance)}</span>)</div>
                  )
                })
                }
              </div>
            </NewSelect>

           



            <div className={'  w-100 mt-2 mb-1 position-relative'}
              style={{ border: '1px solid #000000de', borderRadius: 4 }}>
              <div className={`d-flex justify-content-between flex-row-reverse align-items-center`}>
                <input value={this.state.selectedTypeDoc}
                  placeholder={'انتخاب دسته‌بندی*'}
                  onClick={() => {
                    this.handleOptions()
                  }}
                  onChange={(e) => {
                    this.setState({
                      selectedTypeDoc: e.target.value
                    })
                    this.forceUpdate()
                  }}
                  style={{
                    border: 0, width: '90%',
                    direction: 'rtl', textAlign: 'right',
                    padding: '15.5px 12px'
                  }} />
                <div onClick={() => {
                  this.setState({
                    showOptions: !this.state.showOptions
                  })
                  this.forceUpdate()
                }}
                  className={`p-2`}
                  style={{ border: 0, width: '10%' }} >
                  <img style={{ width: 10, height: '100%' }} src={arrow} />
                </div>
              </div>
              <div className={`position-absolute shadow`} style={{
                zIndex: '100',
                width: '96%', left: '2%',
                maxHeight: 250,
                overflow: "auto",
                backgroundColor: '#fff',
                border: '1px solid #f7f7f7',
                marginBottom: 2,
                borderRadius: 8,
                borderLeft: '1px solid #bfbfbf',
                borderRight: '1px solid #bfbfbf',
                borderBottom: '1px solid #bfbfbf',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                marginTop: '2px',
                display: this.state.showOptions ? 'block' : 'none'
              }}

              >
                {this.state.categoryList.map(item => {
                  return (
                    <div style={{ padding: '2%', textAlign: 'right' }}
                      key={item.id} value={item.id}
                      onClick={() => {
                        this.setState({
                          selectedTypeDoc: item.text,
                          showOptions: false
                        })
                      }}>{item.text}</div>
                  )
                })
                }
              </div>
            </div>



            <div className={`mt-2 `} >
              <CpInput plachodler={this.state.active == 1 ? ' عنوان هزینه*' : 'عنوان درآمد*'} value={this.state.title} type={"text"} onChange={(e) => {
                this.setState({
                  title: e.target.value
                })
              }} />
              <CpInput plachodler={'مبلغ '} value={this.Amount} type={"money"} perfix={"ریال"} maxLength={20} ref={e => this.Amount = e} />
              <CpInput plachodler={'توضیحات'} type={"text"} value={this.state.description} style={{ whiteSpace: "pre-wrap" }} multiline={true} onChange={(e) => {
                this.setState({
                  description: e.target.value
                })
              }} />

            </div>





           
              <>
                <div className={`rtl fntBold mediumF text-right mt-2`} > عکس‌های مرتبط را اضافه کنید. </div>

                {!this.state.uploading ?
                  <UploadImg
                    onChange={(e) => {
                      if (e.target.files[0]) {
                        this.uploadFile(e.target.files[0])
                      }
                    }}
                  >
                  </UploadImg>
                  :
                  <div className={`w-100 text-center`}><img style={{ width: '10%', height: '100%' }} src={loadingGif} /></div>}
              </>

              {this.state.imgPath.length>0 ? 
                       <div className={`rtl mt-4 d-flex align-content-stretch flex-wrap w-100  justify-content-start align-items-baseline`}>
                       {this.state.imgPath.map((item,index)=>{
                        
                         let path = item.path ? item.path : item
                          return <><div key={index} className={`text-right pb-3 rtl d-flex align-items-center`}>
                            <div className={` `} style={{
                                width: 110, height: 80,
                                backgroundImage: "url(" + path+ ")",
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            </div> 

                            <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} 
                            onClick={() => {
                               
                                let temp = this.state.imgPath;
                                let tmp2 = this.state.imgId;
                                temp.splice(index, 1);
                                tmp2.splice(index, 1);
                                
                                this.setState({
                                    imgPath: temp,
                                    imgId: tmp2
                                });
                                if(this.props.location.state.doc && item.id){
                                   this.deleteFile(this.props.location.state.doc.id,item.id)
                                }
                                
                            }
                            }> حذف</span>
                        </div></>})}
                        </div>
                        : null}
              
              {/* <div className={`text-right pb-3 rtl d-flex align-items-center`}>
                <div className={` `} style={{
                  width: 110, height: 80,
                  backgroundImage: "url(" + this.state.imgPath + ")",
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat'
                }}>
                </div>

                <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} onClick={() => {
                  this.setState({
                    imgPath: '',
                    imgId: null
                  });
                }}> حذف</span>
              </div> */}
           



          </div>
          {!this.state.uploading ? <div className={`w-75 mx-auto mt-3`}>
            <SBotton title={'ثبت'} gradian={true} sending={this.state.sending} click={() => {
              this.submitDocument();
            }} />
          </div> : null}
        </div>
      </Layout>
    );
  }
};

export default withRouter(AddDoc);
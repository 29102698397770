 import firebase from "firebase";


const config = {
        apiKey: "AIzaSyCwp_edRYwRIdEByBCcTkI9KYzJNOelrMY",
    authDomain: "homtick-67bab.firebaseapp.com",
    projectId: "homtick-67bab",
    storageBucket: "homtick-67bab.appspot.com",
    messagingSenderId: "799765177791",
    appId: "1:799765177791:web:4c5084230020a7f4d2ed0a",
    measurementId: "G-4TN5TMTE7D"
}


 export  const initializeFirebase = () => {
    firebase.initializeApp(config);
};



// export const askForPermissioToReceiveNotifications = async () => {
//     try {
//         const messaging = firebase.messaging();
//         await Notification.requestPermission();
//         const token = await messaging.getToken();
//         console.log('token: ', token);
//         localStorage.setItem('fbt', token )
//         return token;
//     } catch (error) {
//         console.error(error);
//     }
// };


export const askForPermissioToReceiveNotifications = () => {
   
    return new Promise((resolve, reject) => {
        const messaging = firebase.messaging();
         Notification.requestPermission().then(res =>{
             console.log('permiss', res);
              messaging.getToken()
         .then(token=>{       
                    resolve(token);
         })
         .catch(err => {
              reject(err);
         });
         }).catch(err => {
            reject(err);
       });
        
       
                
                   
              


    })
}
import React from 'react';
import classes from './PriceBx.module.css';
import { colors, formatMoney } from "../../value";
import dec from '../../Assets/myAsset/bleachRed.svg';
import inc from '../../Assets/myAsset/bleachGrn.svg';



const PriceBx = props => {
    return (
        <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100 pr-2 pl-2`} style={{backgroundColor: colors.app9, borderRadius:20}}>
            <div className={`fntBold text-right rtl smallF`} >
              {props.type ==1 ? 'هزینه': 'درآمد'}  :
            </div>
            <div>
                <div className={`d-flex grey align-items-center ${classes.amount}`} style={{fontSize:14}}>{formatMoney(props.amount)}
                    <img style={{ width: '12px', marginLeft: 5,height:'100%' }} src={props.change ==0 ? inc : dec} alt={'alt'} />
                </div>
            </div>
            <div style={{fontSize:11, opacity:0.6}}>
                ریال
            </div>
        </div>
    )
}
export default PriceBx;
import React from "react"
import Layout from "../../Components/Layout/Layout"
import Multi from "../../Components/Period/Multi"
import Single from "../../Components/Period/Single"
import TabBar from "../../Components/TabBar/TabBar"
import { useHistory } from 'react-router-dom'

const tabItems = [
    {
        id: 1,
        title: 'تکی'
    },
    {
        id: 2,
        title: 'دوره ای'
    }
]

const Period = ({location})=>{
    const [loading , setLoading] = React.useState(false)
    const [isEdit , setIsEdit] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState(1);
    const history = useHistory()

    React.useEffect(()=>{
        const oldPeriod = localStorage.getItem("oldPeriod")
        if(oldPeriod){
            setIsEdit(true)
        }
    },[])

    const onTabPressed = (tabId)=>{
        setActiveTab(tabId)
    }


    return(
        
        <Layout
            title={isEdit?"ویرایش سانس":"افزودن سانس"}
            loaded={true}
            loading={loading}
            showMenu={true}
            displayMenu={true}
        > 
            <div className="overflow-auto h-100">
                <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100 `}>
                    {
                        !isEdit && tabItems.map(item => {
                            return (
                                <TabBar 
                                    title={item.title} 
                                    key={item.id} 
                                    id={item.id} 
                                    activeIndex={activeTab} 
                                    click={()=>onTabPressed(item.id)} 
                                />
                            )
                        })
                    }
                </div>
                <div className={`d-flex flex-column align-items-end p-2 my-3 mx-1 pb-2 overflow-hidden`}>
                    {
                        activeTab === 1?
                        <Single/>:
                        <Multi/>    
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Period
import React, { Component, useState } from 'react';
import logo from '../../../Assets/myAsset/LOGO-HOMTICK.png';
import SInput from '../../../Components/Input/SInput';
import BaseBX from "../../../Components/BaseBx/BaseBx";
import { withRouter } from 'react-router-dom';
import { Checkbox } from '../../../Components/CheckBox/CheckBox';
import add from '../../../Assets/myAsset/add.svg'
import Toast from '../../../Components/Toast/Toast';
import loadingGif from '../../../Assets/gifs/22.gif'

import { ApiService, attachFile, getProfile } from '../../../value';
import Modal from '../../../Components/Modal/Modal';
import Avatar from 'react-avatar-edit';
import axios from 'axios';



let Self = null;
class ProfileInfo extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {

            showModal: false,
            loading: false,
            name: '',
            lName: '',
            Gender: null,

            errText: '',
            errType: '',


            preview: null,
            src: null,
            deleteImg: false
        }
    }



    onClose() {
        this.setState({
            preview: null
        })

    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    onCrop(preview) {
        this.setState({
            preview: preview
        })
    }

    clearErr() {
        this.setState({
            errText: '',
            errType: ''
        });
    }

    submit() {
        this.setState({
            loading: true
        })
        if (this.state.name.length < 1 || this.state.lName.length < 1) {
            this.setState({
                errText: 'نام و نام‌خانوادگی خود را وارد کنید',
                errType: 'war',
                loading: false
            });
            setTimeout(() => {
                this.clearErr();
            }, 3000);
            return;
        }

        let data = null;

        if (this.state.preview) {

            let file = this.dataURLtoFile(this.state.preview, 'filename.jpeg');
            attachFile(file).then((response) => {
                data = {
                    name: `${this.state.name}`,
                    lastName: `${this.state.lName}`,
                    gender: this.state.Gender,
                    changedProfileImage: true,
                    profileImageId: response[0].id
                }
                axios.post('https://api2.homtick.com/api/V1/User/setProfile',data,{  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `bearer ${localStorage.getItem('token')}`
                   }
                  }).then(response => {
                             if (response.data.result) {
                            this.setState({
                                errText: 'اطلاعات با موفقیت ثبت شد',
                                errType: 'suc'
                            })
                            getProfile(true).then(response => {
                                this.props.history.push({
                              pathname: '/'
                          });
                      })
                       
                        } else {
                            this.setState({
                                errText: response.data.message,
                                errType: 'err'
                            })
                        }
                    })
                // ApiService('Post', '/User/setProfile', data)
                //     .then(response => {
                //         console.log(response);
                //         if (response.data.result) {
                //             this.setState({
                //                 errText: 'اطلاعات با موفقیت ثبت شد',
                //                 errType: 'suc'
                //             })
                //             setTimeout(() => {
                //                 this.props.history.push({
                //                     pathname: '/'
                //                 });
                //             }, 2000);

                //         } else {
                //             this.setState({
                //                 errText: response.data.message,
                //                 errType: 'err'
                //             })
                //         }
                //     })
            })

        } else {
            if (this.state.deleteImg) {
                data = {
                    name: `${this.state.name}`,
                    lastName: `${this.state.lName}`,
                    gender: this.state.Gender,
                    changedProfileImage: true,
                    profileImageId: 0
                }


            } else {
                data = {
                    name: `${this.state.name}`,
                    lastName: `${this.state.lName}`,
                    gender: this.state.Gender,

                }
            }
          

            axios.post('https://api2.homtick.com/api/V1/User/setProfile',data,{  headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${localStorage.getItem('token')}`
               }
              }).then(response => {
                  console.log(response);
                         if (response.data.result) {
                        this.setState({
                            errText: 'اطلاعات با موفقیت ثبت شد',
                            errType: 'suc'
                        })
                        getProfile(true).then(response => {
                                  this.props.history.push({
                                pathname: '/'
                            });
                        })
                      
                    } else {
                        this.setState({
                            errText: response.data.message,
                            errType: 'err'
                        })
                    }
                })
            // ApiService('Post', '/User/setProfile', data)
            //     .then(response => {
            //         console.log(response);
            //         if (response.data.result) {
            //             this.setState({
            //                 errText: 'اطلاعات با موفقیت ثبت شد',
            //                 errType: 'suc'
            //             })
            //             setTimeout(() => {
            //                 this.props.history.push({
            //                     pathname: '/'
            //                 });
            //             }, 2000);
            //         } else {
            //             this.setState({
            //                 errText: response.data.message,
            //                 errType: 'err'
            //             })
            //         }
            //     })
            //     .catch(err=>{
                    
            //     })

        }
       
    }
    removeImg() {
        console.log('remo');
        this.setState({
            deleteImg: true,
            preview: null
        })
    }

    render() {
        return (

            <BaseBX>
                <Toast
                    text={this.state.errText}
                    type={this.state.errType}
                />
                <Modal show={this.state.showModal} btn={true} click={() => {
                    this.setState({
                        showModal: false
                    })
                }}>
                    <div className="d-flex justify-content-center align-items-center flex-column fnt" style={{ fontSize: 10 }}>
                        <Avatar
                            label={'انتخاب تصویر'}
                            borderStyle={{
                                textAlign: 'center',
                                border: '1px dashed black'
                            }}

                            width={90}
                            height={90}
                            onCrop={(preview) => { this.onCrop(preview) }}
                            onClose={() => { this.onClose() }}
                            src={this.state.src}
                        />
                    </div>

                </Modal>
                <div style={{ height: '100vh', overflow: "hidden", backgroundColor: '#FF3F3F' }}>
                    <div className={`d-flex flex-column align-content-between justify-content-between align-items-center h-100  `}>
                        <div className={`d-flex flex-column align-content-around justify-content-around align-items-center p-4 w-100  `}>
                            <div style={{ width: '30%', }}>
                                <img src={logo} style={{ width: '100%' }} />
                            </div>
                            <div className={`text-light text-center mediumF mb-4`}>مشخصات خود را برای ثبت نام در هومتیک وارد کنید</div>
                            <div className={`text-light mediumF mb-4`}>عکس پروفایل خود را انتخاب کنید</div>
                            {!this.state.preview ? <div onClick={() => {
                                this.setState({
                                    showModal: true
                                })
                            }} style={{ width: 70, height: 70, border: '1px dotted #fff', marginBottom: '10%' }}
                                className={`d-flex flex-column align-content-between justify-content-center align-items-center  `}>
                                <img style={{ width: 20 }} src={add} />
                            </div> :
                                <div style={{ width: 70, height: 70, margin: '15px auto 10px', position: 'relative' }}>
                                    <img src={this.state.preview} alt="Previewghg" style={{ width: '100%', borderRadius: '50%' }} onClick={() => {
                                        this.setState({
                                            showModal: true
                                        })
                                    }} />
                                    <span className={`badg`} onClick={(e) => { e.stopPropagation(); this.removeImg() }}></span>
                                </div>}
                            <div className={`d-flex justify-content-between align-items-center rtl  w-100`}>
                                <div style={{ width: '45%' }}>
                                    <SInput placeholder={'نام'} type={"text"} maxLength={15} onChange={(event) => {
                                        this.setState({
                                            name: event.target.value
                                        })
                                    }} />
                                </div>
                                <div style={{ width: '45%' }}>
                                    <SInput placeholder={'نام خانوادگی'} type={"text"} maxLength={20} onChange={(event) => {
                                        this.setState({
                                            lName: event.target.value
                                        })
                                    }} />
                                </div>

                            </div>
                            <div className={`text-right rtl w-100 mt-4`}>
                                <Checkbox Type={'Radio'} Label={'خانم'}
                                    checkedColor={'#fff'} color1={'#fff'}
                                    checked={this.state.Gender == 'زن'}
                                    onChange={checked => this.setState({ Gender: checked ? 'زن' : null })} />
                                <Checkbox Type={'Radio'} checkedColor={'black'}
                                    checkedColor={'#fff'} color1={'#fff'}
                                    Label={'آقا'}
                                    checked={this.state.Gender == 'مرد'}
                                    onChange={checked => this.setState({ Gender: checked ? 'مرد' : null })} />
                            </div>
                        </div>
                        <div className={`d-flex flex-column align-content-around justify-content-center align-items-center pl-4 pr-4  w-100   `} style={{ height: '20%' }}>
                            <div className={`pl-3 pr-3 pt-2 pb-2 text-dark w-50 mx-auto text-center fntBold `}
                                onClick={() => { this.submit() }}
                                style={{ backgroundColor: '#FFF', border: '1px solid #ff3f3f', borderRadius: 25, fontSize: 20 }}>
                                {!this.state.loading ?
                                    'ورود'
                                    : <div style={{ width: '30%',margin:'0 auto' }}> <img src={loadingGif} style={{ width: '100%', margin: ' 0 auto', }} /></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </BaseBX>
        )
    }



}
export default withRouter(ProfileInfo);

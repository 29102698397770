import React, { useEffect, useState } from 'react'
import { Checkbox } from '../../../Components/CheckBox/CheckBox';
import Date from '../../../Components/Date/Date';
import Doer from '../../../Components/Doer/Doer';
import GrayLine from '../../../Components/GrayLine/GrayLine';
import Layout from '../../../Components/Layout/Layout';
import PayBx from '../../../Components/PayBx/PayBx';
import TabBar from '../../../Components/TabBar/TabBar';
import { ApiService, attachFile, FaToEnNum, formatMoney } from '../../../value';
import CpInput from '../../../Components/Input/Input';
import UploadImg from '../../../Components/UploadImg/UploadImg';
import loadingGif from '../../../Assets/gifs/22.gif'
import Toast from '../../../Components/Toast/Toast';
import { withRouter } from 'react-router-dom';
import DatePicker from '../../../Components/DatePicker/DatePicker';
import Modal from '../../../Components/Modal/Modal';
import ChargeWalletModal from '../../../Components/Modal/ChargeWalletModal/ChargeWalletModal';
import PayGatesModal from '../../../Components/Modal/PayGatesModal/PayGatesModal';


const PayPage = props => {
    const [sending, setSending] = useState(false);
    const [showModalCharge, setShowModalCharge] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paymentGate, setPaymentGate] = useState(false);
    const [gateList, setGateList] = useState([]);
    const [method, setMethod] = useState('');
    const [info, setInfo] = useState(null);
    const [customAmount, setCustomAmount] = useState(null);





    const [availableAmount, setAvailableAmount] = useState(null);




    const [imgPath, setImgPath] = useState('');
    const [uploading, setUploading] = useState(false);
    const [imgId, setImgId] = useState(null);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [showDatePicker, setShowDatePicker] = useState(null);

    const [checkNum, setCheckNum] = useState(null);
    const [checkDate, setCheckDate] = useState(null);
    const [CartToCardDate, setCartToCardDate] = useState(null);


    let payData = props.location.state && props.location.state.payData ? props.location.state.payData : null;
    let callBackUrl1 = props.location.state && props.location.state.callBackUrl ? props.location.state.callBackUrl : null;
    useEffect(() => {
        if (props.location.state && props.location.state.payData) {
            getPaymentScreanData();
        } else {
            props.history.push('/receipt')

        }


    }, []);







    const getDetailOfDoc = (id, buildingId) => {
        ApiService('Get', `/AccRequest/RequestDocumentDetail/${buildingId}/${id}`)
            .then(response => {
                console.log(response);
                if (response.data.result) {

                    setLoading(false)
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();

                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })


    }

    const getPaymentScreanData = () => {
        setLoading(true);
        let data = payData;
        console.log('!!!!!!!datadatadatadatadata!!!!!!', data);
        ApiService('POST', `/AccRequest/GetPaymentScreanData`, data)
            .then(res => {
                if (res.data.result) {
                    setInfo(res.data.data);
                    setCustomAmount(res.data.data.amountPayable)
                    setLoading(false);

                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr();
                    setTimeout(() => {
                        props.history.goBack()

                    }, 2000);
                    return;

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })

    }


    const uploadFile = (files) => {
        setUploading(true);
        attachFile(files).then(res => {
            setImgId(res[0].id);
            setImgPath(res[0].path);
            setUploading(false);
        })
            .catch(err => {

            })

    }


    const onlinePay = () => {
        if (parseInt(FaToEnNum(customAmount) < 100000)) {
            setErrText('مبلغ وارد شده مجاز نمی‌باشد');
            setErrType('war');
            clearErr();
            return;

        }
        setShowModalCharge(false);
        setLoading(true);
        let data = {
            //methodKey: method,
            callBackUrl: callBackUrl1,
            amountPayable: parseInt(FaToEnNum(customAmount)),
            amount: payData.amountPayable,
            param: payData.param,
            payFor: payData.payFor,
        };
        ApiService('POST', `/Payment/GeneratePaymentUrl`, data)
            .then(response => {
                console.log(response);
                if (response.data.result) {
                    setGateList(response.data.data)
                    setPaymentGate(true);
                    setLoading(false);

                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    setLoading(false);
                    return;

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const walletInc = (amount) => {
        if (parseInt(amount) < 100000) {
            setErrText('مبلغ وارد شده مجاز نمی‌باشد');
            setErrType('war');
            clearErr();
            return;

        }

        setLoading(true);
        let data = {};

        data = {
            callBackUrl: window.location.href,
            amountPayable: parseInt(amount),
            payFor: 0,

        }
        ApiService('Post', `/Payment/GeneratePaymentUrl`, data)
            .then(response => {
                if (response.data.result) {
                    setGateList(response.data.data);
                    setShowModalCharge(false);
                    setPaymentGate(true);
                    setLoading(false);

                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();

                    setLoading(false);
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }
    const renderpaymethod = (item, index) => {
        if (!item.isEnabled)
            return (<div key={index} />)
        return (
            <div key={index} className={`d-flex justify-content-between align-items-center `}>
                <div className={`d-flex align-items-center`}>  <Checkbox

                    disabled={(item.availableAmount < 1 && (item.methodKey == 'UserBag' || item.methodKey == "Bestankari" || item.methodKey == "UserBestankari"))}

                    Type={'Radio'} Label={item.title} onChange={(check) => {
                        if (check) {
                            setMethod(item.methodKey);
                            setAvailableAmount(item.availableAmount)
                        }
                    }} />
                    {item.methodKey == 'UserBag' ? <div style={{ fontSize: 11, marginBottom: 6, marginRight: 3, color: '#FF5E1B' }}
                        onClick={() => {
                            setShowModalCharge(true)
                        }}>افزایش اعتبار</div> : null}
                </div>
                {item.methodKey == 'UserBag' || item.methodKey == "Bestankari" || item.methodKey == "UserBestankari" ? <div className={`grey`} style={{ fontSize: 10, marginBottom: 6 }}>
                    اعتبار  : {formatMoney(item.availableAmount)} ریال
                                    </div> : null}
            </div>)

    }

    const submitPay = () => {
        if (method.length == 0) {
            setErrText('نوع پرداخت را انتخاب کنید');
            setErrType('war');
            clearErr();
            return
        }
        let data = {
            methodKey: method,
            amount: info.amountPayable,
            amountPayable: parseInt(FaToEnNum(customAmount)),
            callBackUrl: callBackUrl1,
            param: payData.param,
            payFor: payData.payFor,
            data: {}
        }

        if (method == 'CheckPaper' && checkDate) {
            data.data.Date = checkDate;

        } else if (method == 'CardToCard' && CartToCardDate) {
            data.data.Date = CartToCardDate;
        }

       

        if (method == 'UserBag' && availableAmount < parseInt(FaToEnNum(customAmount))) {
            setErrText('مبلغ وارد شده ار موجودی کیف‌‌پول بیشتر است');
            setErrType('war');
            clearErr();
            return
        }
        if (method == 'Bestankari' && availableAmount < parseInt(FaToEnNum(customAmount))) {
            setErrText('مبلغ وارد شده ار موجودی بستانکاری بیشتر است');
            setErrType('war');
            clearErr();
            return
        }

        if (imgId) {
            data.data.FileId = imgId;
        }
        if (checkNum) {
            data.data.SerialNumber = checkNum;
        }
        if (checkDate) {
            data.data.Date = checkDate;
        }
        if (method == 'CheckPaper' && (!checkNum || !checkDate || !imgId)) {
            setErrText('تمامی اطلاعات چک الزامی است');
            setErrType('war');
            clearErr();
            return

        }
        if (method == 'CardToCard' && !imgId) {
            setErrText('تصویر کارت به کارت الزامی است');
            setErrType('war');
            clearErr();
            return

        }

        console.log('))))))))))))))))))))))))))))))))))))))))))))))))', data);
        setSending(true);
        ApiService('POST', `/AccRequest/ApplyPayMethod`, data)
            .then(res => {
                console.log(res);
                if (res.data.result) {
                    setErrText('با موفقیت پرداخت شد.');
                    setErrType('suc');
                    clearErr();
                    if(props.location.state.unitInfo){
                       props.history.push({
                        pathname: '/building/unit',
                        state: {
                            unitInfo: props.location.state.unitInfo
                        }
                    }); 
                    }else{
                       setTimeout(() => {
                        props.history.goBack();
                        //props.history.push('/receipt')
                   }, 2000); 
                    }
                    
                    


                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr();
                    setSending(false);

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setSending(false);
                clearErr();
                return
            })

    }
    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }





    return (
        <>
            <Modal show={paymentGate} btn={true} click={() => {
                setPaymentGate(false);
            }}>
                <div>
                    <div className={`mb-4 `} style={{ textAlign: 'right', direction: 'rtl', fontSize: '15px' }}>درگاه پرداخت را انتخاب کنید</div>
                    <div className={`d-flex align-content-stretch flex-wrap w-100  justify-content-center align-items-baseline  `}>
                        {gateList.map(item => {
                            return (
                                <a key={item.id} href={`${item.url}`} style={{ width: "35%" }}>
                                    <div className={`m-2 text-center p-1 borderShadow customBorderR `} style={{ width: "fit-content" }}>
                                        <div className={`mx-auto`} style={{ width: "50%" }}><img style={{ width: "100%" }} src={item.imageUrl} /></div>
                                        <div style={{ fontSize: '12px' }}>{item.name}</div>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </div>
            </Modal>
            <PayGatesModal
                showModal={paymentGate}
                close={() => {
                    setPaymentGate(false)
                }}
                gateList={gateList} />

            <ChargeWalletModal
                showModalCharge={showModalCharge}
                close={() => {
                    setShowModalCharge(false)
                }}
                submitPay={(amount) => {
                    console.log('1', amount);
                    submitPay(amount)

                }} />
            <ChargeWalletModal
                showModalCharge={showModalCharge}
                close={() => {
                    setShowModalCharge(false)
                }}
                submitPay={(amount) => {
                    console.log('1', amount);
                    walletInc(amount)

                }} />
            <Layout loaded={true} title={`پرداخت ${info && info.title ? info.title : ''}`} isHome={false}
                loading={loading || sending} btnName={'پرداخت '} btn={!uploading} gradian click={() => {
                    if (customAmount && customAmount != '' && parseInt(FaToEnNum(customAmount)) > 0) {
                        if (method == 'Online') {
                            onlinePay()
                        } else {
                            submitPay()
                        }
                    }
                    else {
                        setErrText('لطفا مبلغ را به درستی وارد نمایید');
                        setErrType('war');
                        clearErr();
                        setSending(false);
                    }

                }}  >

                <DatePicker showPicker={showDatePicker} disMiss={() => {

                    setShowDatePicker(false)

                }} click={(e) => {
                    if (method == "CheckPaper") {
                        setShowDatePicker(false);
                        setCheckDate(e)

                    } else {
                        setShowDatePicker(false);
                        setCartToCardDate(e)

                    }




                }} />
                <Toast
                    text={errText}
                    type={errType}
                />
                {info ? <div style={{ height: 'inherit' }}>
                    <div style={{ height: `calc(100% - 5rem)`, overflow: 'auto' }}>
                        <div className={`w-100 d-flex justify-content-between align-items-center flex-row-reverse`}>
                            <Doer creator={info.user.name} profileImage={info.user.profileImageUrl} />
                            <div> <Date date={info.dateCreated} font={12} /></div>
                        </div>
                        <div className={`d-flex justify-content-start align-items-center flex-row-reverse`}>
                            <div className={`rtl text-right mediumF `}>مبلغ قابل پرداخت:</div>
                            <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                                <div className={`backGreyLight pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(info.amountPayable)}</div>
                                <div className={`grey smallF`}>ریال</div>
                            </div>
                        </div>




                        <div className={`d-flex justify-content-start align-items-center flex-row-reverse mt-4 mb-2`}>
                            <div className={`rtl text-right  ml-3 `} style={{ fontSize: 12, marginBottom: 15 }} >مبلغ دلخواه :</div>
                            <div style={{ width: '70%' }}>
                                <CpInput plachodler={' مبلغ دلخواه '} type={"money"} perfix={"ریال"} value={customAmount} onChange={(e) => {
                                    setCustomAmount(e.target.value)
                                    // setCustomAmount(parseInt(FaToEnNum(e.target.value)))
                                }} />
                            </div>
                        </div>

                        <div className={`w-100 p-2 rtl`}>
                            {info.methods.map((item, index) => renderpaymethod(item, index))}
                        </div>
                        <GrayLine />
                        {method == "CheckPaper" ? <div>
                            <CpInput plachodler={'شماره چک'} type={"text"} onChange={(e) => {
                                setCheckNum(e.target.value)
                            }} />
                            {/* <CpInput plachodler={'تاریخ چک '} type={""} value={checkDate} onClick={() => {
                                setShowDatePicker(true)
                            }} /> */}
                            <div className={`d-flex align-items-center rtl `} onClick={() => {
                               setShowDatePicker(true)
                            }}
                             style={{marginBottom:15,padding:'15.5px 12px', color:'#0000008a', border:'1px solid #424242', borderRadius:4}}>
                                <div>تاریخ چک:  </div>
                                <div className={`mr-3`} style={{color:'#000000de'}} >{checkDate}</div>
                                
                            </div>
                        </div> : null}
                        {method == "CardToCard" ? <div>

                            <CpInput plachodler={'تاریخ  کارت به کارت '} type={""} value={CartToCardDate} onClick={() => {
                                setShowDatePicker(true)
                            }} />
                        </div> : null}

                        {(method == "CheckPaper" || method == "CardToCard") ?
                            <div>
                                {imgPath.length < 1 ?
                                    <>
                                        <div className={`rtl fntBold mediumF text-right mt-2`} > عکس‌ مرتبط را اضافه کنید. </div>

                                        {!uploading ?
                                            <UploadImg
                                                onChange={(e) => {
                                                    if (e.target.files[0]) {
                                                        uploadFile(e.target.files[0])
                                                    }
                                                }}>
                                            </UploadImg>
                                            :
                                            <div className={`w-100 text-center`}><img style={{ width: '10%', height: '100%' }} src={loadingGif} /></div>}
                                    </>
                                    :
                                    <div className={`text-right pb-3 rtl d-flex align-items-center`}>
                                        <div className={` `} style={{
                                            width: 110, height: 80,
                                            backgroundImage: "url(" + imgPath + ")",
                                            backgroundPosition: 'center',
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat'
                                        }}>
                                        </div>
                                        {/* <img src={this.state.imgPath} style={{ width: 80 }} /> */}
                                        <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} onClick={() => {
                                            setImgPath('')
                                        }}> حذف</span>
                                    </div>
                                }   </div>
                            : null}
                    </div>
                </div> : null}
            </Layout></>
    )
}
export default withRouter(PayPage);
import React, { Component } from 'react';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import Layout from '../../../Components/Layout/Layout';
import CpInput from '../../../Components/Input/Input';
import { ApiService, attachFile } from '../../../value';
import { withRouter } from 'react-router-dom';
import UploadImg from '../../../Components/UploadImg/UploadImg';
import loadingGif from '../../../Assets/gifs/22.gif'



let Self = null;
class AddReport extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            loading: false,
            id: this.props.location.state && this.props.location.state.event ?
                this.props.location.state.event.reports[0].id : 0,


            imgId: this.props.location.state && this.props.location.state.event && this.props.location.state.event.reports[0].fileIds ? this.props.location.state.event.reports[0].fileIds[0] : null,
            imgPath: this.props.location.state && this.props.location.state.event && this.props.location.state.event.reports[0].filePaths ? this.props.location.state.event.reports[0].filePaths[0].path :  '',
            uploading: false,

            description: this.props.location.state && this.props.location.state.event ? this.props.location.state.event.reports[0].description : '',
            buildingId: null,
            eventId: null,



            errText: '',
            errType: ''
        }
    }

    componentDidMount() {
        console.log('****************',this.props.location.state.event);
        if (this.props.location.state && this.props.location.state.eventId && this.props.location.state.buldingId) {
            this.setState({
                buildingId: this.props.location.state.buldingId,
                eventId: this.props.location.state.eventId
            })
        } else {
            this.props.history.push('/news');
        }
    }



    deleteFile(accDocumentId,fileId){
        this.setState({
          sending: true
        })
        ApiService('Get', `/AccRequest/AccDocumentDeleteFile/${this.state.buildingId}/${accDocumentId}/${fileId}`)
          .then(response => {
              console.log('999999999999',response);
            if (response.data.result) {
              this.setState({
                sending: false
              })
             
            }else{
    
              this.setState({
                errText: response.data.message,
                errType: 'war',
                loading: false
              })
    
    
             
            }
          })
          .catch(err => {
            this.setState({
              errText: 'خطای داخلی سرور',
              errType: 'err',
              loading: false
            });
            this.clearErr();
    
          })
    
      }
      clearErr() {
        setTimeout(() => {
          this.setState({
            errText: '',
            errType: '',
          })
        }, 3000)
      }

    submitReport(buildingId, eventId) {
        this.setState({
            loading: true
        });
        let imgArray = [];
        if(this.state.imgId){
            imgArray.push(this.state.imgId)

        }
        let data = {
            id: this.state.id,
            description: this.state.description,
            fileIds: imgArray
        }
        console.log('>>>', data);
        ApiService('Post', `/BuildingEvent/SubmitReport/${buildingId}/${eventId}`, data)
            .then(response => {
                console.log('Submit', response);
                if (response.data.result) {
                    this.setState({
                        loading: false,
                    });
                    // this.props.history.push('/news');
                    this.props.history.push({
                        pathname: '/event',
                        state: {
                            id: eventId
                        }
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        errText: response.data.message,
                        errType: 'war'
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    errText: 'خطای داخلی سرور',
                    errType: 'war'
                });
               
                return
            })
    }
    uploadFile(files) {
        this.setState({
            uploading: true
        })
        attachFile(files).then(res => {
            this.setState({
                imgId: res[0].id,
                imgPath: res[0].path,
                uploading: false
            })
         
        })
            .catch(err => {

            })

    }
    render() {
        return (
            this.state.buildingId && this.state.eventId ?
                <Layout loaded={true} title={' ثبت  گزارش'} isHome={false} showMenu={false} loading={this.state.loading} >
                    <div style={{ lineHeight: 2 }}>
                        <CpInput plachodler={' توضیحات'} multiline={true} value={this.state.description} style={{whiteSpace: "pre-wrap"}} type={"text"} onChange={(event) => {
                            this.setState({
                                description: event.target.value
                            })
                        }} />
                        {this.state.imgPath.length < 1 ?
                            <>
                                <div className={`rtl fntBold mediumF text-right mt-2`} > عکس‌ مرتبط را اضافه کنید. </div>

                                {!this.state.uploading ?
                                    <UploadImg
                                        onChange={(e) => {
                                            if (e.target.files[0]) {
                                                this.uploadFile(e.target.files[0])
                                            }
                                        }}>
                                    </UploadImg>
                                    :
                                    <div className={`w-100 text-center`}><img style={{ width: '10%', height: '100%' }} src={loadingGif} /></div>}
                            </>
                            :
                            <div className={`text-right pb-3 rtl d-flex align-items-center`}>
                                <div className={` `} style={{
                                    width: 110, height: 80,
                                    backgroundImage: "url(" + this.state.imgPath + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}>
                                </div>
                                {/* <img src={this.state.imgPath} style={{ width: 80 }} /> */}
                                <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} onClick={() => {
                                    this.setState({
                                        imgPath: '',imgId:''
                                    });
                                    if(this.props.location.state.eventId && this.state.imgId){
                                        this.deleteFile(this.props.location.state.eventId,this.state.imgId)
                                     }
                                }}> حذف</span>
                            </div>
                        }
                    </div>
                    <div style={{ position: "absolute", bottom: 0, width: '50%', left: '25%' }}>
                        <SBotton title={this.props.location.state && this.props.location.state.event ? 'ویرایش گزارش' : 'ثبت گزارش'} gradian={true} click={() => {
                            this.submitReport(this.state.buildingId, this.state.eventId)
                        }} />
                    </div>
                </Layout>
                :
                <></>
        )
    }

}
export default withRouter(AddReport);
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import AddBotton from '../../Components/Buttons/AddBotton/AddBotton';
import BillCard from '../../Components/Cards/BillCard/BillCard';
import EmptyList from '../../Components/EmptyList/EmptyList';
import Layout from '../../Components/Layout/Layout';
import DeleteModal from '../../Components/Modal/DeleteModal/DeleteModal';
import NewSelect from '../../Components/NewSelect/NewSelect';
import Toast from '../../Components/Toast/Toast';
import { ApiService, MyBuildingList } from '../../value';


const def =
{
    id: '0',
    text: 'ساختمان خود را انتخاب کنید'
};

const Bills = props => {
    const [loading, setLoading] = useState(true);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [buildingList, setBuildingList] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletId, setDeletId] = useState(null)
    const [billsList, setBillsList] = useState([]);
    const [permission, setPermission] = useState(null)



    useEffect(() => {
        getMyBuildingList()
    }, [])


    const getMyBuildingList = () => {
        MyBuildingList(false)
            .then(res => {
                console.log(res);
                let list = res;
                if (list) {
                    setBuildingList(list);
                    setSelectedBulding(list.find(elem => { return elem.id == localStorage.getItem('id') }));
                    getBillsList()
                }
            })
    }


    const getBillsList = () => {
        setLoading(true);
        ApiService('Get', `/Bill/GetList/${localStorage.getItem('id')}`)
            .then(res => {
                if (res.data.result) {
                    setBillsList(res.data.data);
                    setPermission(res.data.data2)
                    setLoading(false);


                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    setLoading(false);
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }
    const deleteBill = () => {
        console.log('delete', deletId);
        if (deletId) {
            ApiService('Get', `/Bill/Delete/${deletId}`)
                .then(res => {
                    console.log(res);
                    if (res.data.result) {
                        setErrText('با موفقیت حذف شد');
                        setErrType('suc');
                        clearErr()
                        setDeleteModal(false)
                        getBillsList()
                        setDeletId(null)



                    } else {
                        setErrText(res.data.message);
                        setErrType('war');
                        clearErr();
                        setDeletId(null)


                    }
                })
                .catch(err => {
                    setErrText('خطای داخلی سرور');
                    setErrType('err');
                    clearErr();
                    return
                })
        }
    }
    const updateBill = (id) => {

        if (id) {
            ApiService('Get', `/Bill/Update/${id}`)
                .then(res => {
                    console.log(res);
                    if (res.data.result) {
                        setErrText('با موفقیت به روز گردید');
                        setErrType('suc');
                        clearErr()
                        setDeleteModal(false)
                        getBillsList()
                        setDeletId(null)



                    } else {
                        setErrText(res.data.message);
                        setErrType('war');
                        clearErr();
                        setDeletId(null)


                    }
                })
                .catch(err => {
                    setErrText('خطای داخلی سرور');
                    setErrType('err');
                    clearErr();
                    return
                })
        }
    }




    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    return (
        <Layout loaded={true} title={'لیست قبوض'} isHome={false}
            showMenu={true} loading={loading}
            displayMenu={!loading} customBack={true}
            path={'/'}
        >
            <DeleteModal show={deleteModal} deletable={true}
                delete={() => { deleteBill() }}
                doNotDelete={() => { setDeleteModal(false) }}
                text={'از حذف قبض اطمینان دارید؟'}
                click={() => {
                    setDeleteModal(false)
                }} />
            <Toast
                text={errText}
                type={errType}
            />

            <div style={{ height: 'inherit' }}>
                {buildingList.length > 1 ?
                    <div className={`mt-2 mb-2`} >
                        <NewSelect options={buildingList} plachodler={def} obj={false} sel2={false} value={selectedBulding} >
                            <div onClick={(e) => {
                                buildingList.find(elem => {
                                    if (elem.id == e.target.value) {
                                        setSelectedBulding(elem);
                                        localStorage.setItem('id', elem.id);
                                    }
                                })
                            }}>
                                {buildingList.map(item => {
                                    return (
                                        <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                            buildingList.find(elem => {
                                                if (elem.id == item.id) {
                                                    localStorage.setItem('id', elem.id);
                                                    setSelectedBulding(elem);
                                                    getBillsList()


                                                }
                                            })
                                        }}>{item.name}</div>
                                    )
                                })
                                }
                            </div>

                        </NewSelect>
                    </div>
                    :
                    buildingList.length == 1 ?
                        <div className={`w-100 text-right pt-1 pb-1 pr-4 mt-2 mb-2 single`}>
                            {buildingList[0].name}
                        </div>
                        : null
                }
               {permission && permission.pBtnInsert ? <AddBotton title={' ثبت قبض'} click={() => {
                    props.history.push({
                        pathname: '/bills/add',
                        state: {
                            // buildingId: selectedBulding.id
                        }
                    })
                }} /> : null}


                {billsList && billsList.length > 0 ?
                    <div className={`mt-2`} style={{ height:  `calc(100% - 5rem)` , overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>
                        {billsList.map((item, index) => {
                            return <BillCard key={index} item={item}
                                delete={() => {
                                    setDeleteModal(true);
                                    setDeletId(item.id)

                                }}
                                 update={(e)=>{updateBill(item.id);

                                     e.stopPropagation();}}
                                 edit={() => {
                                    props.history.push({
                                        pathname: '/bills/add',
                                        state: {
                                            billDetail: item
                                        }
                                    })
                                }}
                                click={(e) => {
                                    e.stopPropagation();
                                    console.log('item', item);
                                    props.history.push({
                                        pathname: '/pay',
                                        state: {
                                            payData: item.paymentData,
                                            callBackUrl: window.location.href
                                        }
                                    });

                                }} />
                        })}
                    </div> : <EmptyList des={'قبض وجود ندارد'} />}





            </div>
        </Layout>
    )

}
export default withRouter(Bills);
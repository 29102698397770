import React from "react";
import { useHistory } from "react-router-dom";
import { serviceAxios } from "../../axios";
import SBotton from "../Buttons/SBotton/SBotton";

const NeedRateOrder = ({orders , setError , getDashbordData})=>{
    const history = useHistory()
    const [sending , setSending] = React.useState(false)

    const setSeenOrder = (orderId)=>{
        setSending(true)
        serviceAxios.post(
          "/Order/SubmitRate",
          {
              "orderId": parseInt(orderId),
              "rate": -1,
              "comment": ""
            }
        ).then(res=>{
          console.log(res.data)
          setSending(false)
          getDashbordData(localStorage.getItem("id"))
    
        }).catch(error =>{
            setSending(false)
            setError("خطایی رخ داده است. لطفا مجددا تلاش کنید" , "err")
        })
    }

    return orders.map(order =>{
        return(
            <div className="shadow-card mt-4 mb-3 p-1" onClick={()=>history.push(`/service/orderDetails/${order.id}`)} key={order.id.toString()}>
                <div className="d-flex flex-column w-100 p-1 px-2">
                    <div className="d-flex flex-row justify-content-between rtl">
                        <span>
                            {
                                `سفارش ${order.titel}  به پایان رسید`
                            }
                        </span>
                        <span>
                            {
                                order.reservationDate
                            }
                        </span>
                    </div>
                    <div className="d-flex flex-row rtl mt-1">
                        <span>
                            {
                                `لطفا به عملکرد "${order.provider.name}" امتیاز دهید`
                            }
                        </span>
                    </div>
                    <div className="d-flex flex-row rtl">
                        <div className="d-flex flex-grow-1 m-1">
                            <SBotton
                                title="ثبت نظر"
                                click={(e)=>{ e.stopPropagation(); history.push(`/service/orderDetails/${order.id}`)}}
                                gradian
                            />
                        </div>
                        <div className="d-flex flex-grow-1 m-1">
                            <SBotton
                                title="نشانم نده"
                                sending={sending}
                                click={(e)=>{ e.stopPropagation(); setSeenOrder(order.id);}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    })
}


export default NeedRateOrder
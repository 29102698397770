
import { Component } from 'react';
import Layout from '../../../Components/Layout/Layout';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import MapP from "../../../Components/Map/Map";
import { withRouter } from 'react-router-dom';




const def =
{
    id: '0',
    text: 'استان'
};
const def2 =
{
    id: '0',
    text: 'شهر'
};


let Self = null;
class EditBuildingMap extends Component {


    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            wdth: '50%',
            finished: false,
            loading: false,
            selectLoc: false,
            //showModal: false,
            lat: this.props.location.state && this.props.location.state.objInfo1.latitude > 0 ? this.props.location.state.objInfo1.latitude : 35.74245203307214,
            lng: this.props.location.state && this.props.location.state.objInfo1.latitude > 0 ? this.props.location.state.objInfo1.longitude : 51.44703541419062,
            // address: this.props.location.state && this.props.location.state.objInfo1.address != '' ? this.props.location.state.objInfo1.address : '',
            // pelak: this.props.location.state && this.props.location.state.objInfo1.plate != 0 ? this.props.location.state.objInfo1.plate : '',

            // province: [],
            // cities: [],

            errText: '',
            errType: '',
            // selectedProvince: this.props.location.state && this.props.location.state.objInfo1.provinceName != '' ? this.props.location.state.objInfo1.provinceName : '',
            // selectedProvinceId: this.props.location.state && this.props.location.state.objInfo1.provinceId ? this.props.location.state.objInfo1.provinceId : null,
            // selectedCity: this.props.location.state && this.props.location.state.objInfo1.cityName != '' ? this.props.location.state.objInfo1.cityName : '',
            // selectedCityId: this.props.location.state && this.props.location.state.objInfo1.cityId != '' ? this.props.location.state.objInfo1.cityId : null,
        }
        this.BuildingInfo = this.props.location.state && this.props.location.state.objInfo1;
    }
    componentDidMount() {
        if (!this.BuildingInfo) {
            this.props.history.push({
                pathname: '/building',
                state: {
                    objInfo1: this.BuildingInfo,
                    Methods: this.state.calculationMethods
                }
            });
        }
    }


    getCoordinate(e) {
        this.setState({
            lat: e.getCenter().lat,
            lng: e.getCenter().lng,
        });

    }


    saveLoc() {
        this.BuildingInfo.latitude = this.state.lat;
        this.BuildingInfo.longitude = this.state.lng;
        // console.log("**********", this.BuildingInfo);
        this.props.history.push({
            pathname: '/building/edit',
            state: {
                objInfo1: this.BuildingInfo,
            }
        });
    }






    clearErr() {
        this.setState({
            errText: '',
            errType: ''
        });
    }



    render() {
        return (
            this.BuildingInfo ?
                <>
                    <Layout loaded={true} title={'  تغییر موقعیت ساختمان '} isHome={false}
                        customBack={true}
                        path={'/building/edit'}
                        obj={this.BuildingInfo}
                        paddSet={true} scroll={true}
                        loading={this.state.loading}>


                        <div className={`w-100 mx-auto`}>
                            <MapP onMove={event => this.getCoordinate(event)} lat={this.state.lat} lng={this.state.lng}>
                                <div style={{ display: this.state.selectLoc ? 'none' : 'block', width: '60%', left: '20%', bottom: 10, position: 'fixed' }}>
                                    <SBotton title={'ثبت موقعیت'} gradian={true} click={() => {
                                        this.saveLoc()
                                    }} />
                                </div>

                            </MapP>

                        </div>
                    </Layout>
                </> : <div></div>
        );
    }

};



export default withRouter(EditBuildingMap);





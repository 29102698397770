import React, { Component, useState } from 'react';
import Layout from '../../../Components/Layout/Layout';
import BuildingType from '../BuildingType/BuildingType';
import CpInput from '../../../Components/Input/Input';
import { Checkbox } from '../../../Components/CheckBox/CheckBox';
import { withRouter } from 'react-router-dom';
import { ApiService, attachFile, MyBuildingList } from '../../../value';
import NewSelect from '../../../Components/NewSelect/NewSelect';
import Toast from '../../../Components/Toast/Toast';
import SBotton from '../../../Components/Buttons/SBotton/SBotton';
import UploadImg from '../../../Components/UploadImg/UploadImg';
import loadingGif from '../../../Assets/gifs/22.gif'


const buildingType = [
    {
        id: 0,
        title: 'مسکونی'
    },
    {
        id: 1,
        title: 'تجاری'
    },
    {
        id: 2,
        title: 'اداری'
    },
    {
        id: 3,
        title: 'سایر'
    }]

const def =
{
    id: '0',
    text: '*استان'
};
const def2 =
{
    id: '0',
    text: '*شهر'
};
let Self = null;
class EditBuilding extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            loading: true,
            height: null,
            buildingUtilities: [],
            province: [],
            cities: [],
            provincel: this.props.location.state && this.props.location.state.objInfo1.provinceName,
            city: this.props.location.state && this.props.location.state.objInfo1.cityName,


            showErr: false,
            errText: '',
            errType: '',

            activeIndex: this.props.location.state && this.props.location.state.objInfo1.buildingType,

            uploading: false,
            imgSrc: [],



            name: this.props.location.state && this.props.location.state.objInfo1.name,
            floorNumber: this.props.location.state && this.props.location.state.objInfo1.floorsNumber,
            address: this.props.location.state && this.props.location.state.objInfo1.address,
            plate: this.props.location.state && this.props.location.state.objInfo1.plate,

        };

        this.heightU = 0;
        this.props.location.state ?
            this.BuildingInfo =
            {
                address: this.props.location.state.objInfo1.address != '' ? this.props.location.state.objInfo1.address : '',
                imagePath: this.props.location.state.objInfo1.imagePath != '' ? this.props.location.state.objInfo1.imagePath : null,
                imageId: this.props.location.state.objInfo1.imageId != '' ? this.props.location.state.objInfo1.imageId : null,
                buildingType: this.props.location.state.objInfo1.buildingType  ? this.props.location.state.objInfo1.buildingType : null,
                floorsNumber: this.props.location.state.objInfo1.floorsNumber != 0 ? this.props.location.state.objInfo1.floorsNumber : null,
                unitsCount: this.props.location.state.objInfo1.unitsCount != 0 ? this.props.location.state.objInfo1.unitsCount : null,
                plate: this.props.location.state.objInfo1.plate != 0 ? this.props.location.state.objInfo1.plate : null,
                cityName: this.props.location.state.objInfo1.cityName != '' ? this.props.location.state.objInfo1.cityName : '',
                provinceName: this.props.location.state.objInfo1.provinceName != '' ? this.props.location.state.objInfo1.provinceName : '',
                cityId: this.props.location.state.objInfo1.cityId  ? this.props.location.state.objInfo1.cityId : 0,
                provinceId: this.props.location.state.objInfo1.provinceId  ? this.props.location.state.objInfo1.provinceId : 0,
                latitude: this.props.location.state.objInfo1.latitude  ? this.props.location.state.objInfo1.latitude : 0,
                longitude: this.props.location.state.objInfo1.latitude  ? this.props.location.state.objInfo1.longitude : 0,
                //   cashDesks: {
                //     list: []
                //   },
                //   calculationMethods: {
                //     list: []
                //   },
                utilities: {
                    list: this.props.location.state.objInfo1.utilities.list.length > 0 ? this.props.location.state.objInfo1.utilities.list : []
                },
                id: this.props.location.state.objInfo1.id,
                name: this.props.location.state.objInfo1.name != '' ? this.props.location.state.objInfo1.name : ''
            } :
            this.BuildingInfo = null


         //console.log('111111111', this.props.location.state.objInfo1);
        // console.log('22222222', this.BuildingInfo);



    }
    componentDidMount() {
        console.log('building detail:::::;', this.props.location.state.objInfo1);
        if (document.getElementById('contentBx')) {
            this.heightU = document.getElementById('contentBx').clientHeight;
            this.setState({
                height: this.heightU
            })
        }

        if (this.BuildingInfo != null) {
            this.GetBuildingFormData();
            this.getCities();
        } else {
            this.props.history.push({
                pathname: '/building',
                state: {
                    objInfo1: this.BuildingInfo,
                    Methods: this.state.calculationMethods
                }
            });
        }
    }
    GetBuildingFormData() {
        ApiService('Get', '/Building/GetBuildingFormData')
            .then(response => {
                if (response.data.result) {
                    this.setState({
                        buildingUtilities: response.data.data.buildingUtilities,
                    });
                    this.getCities();
                    this.setProvince(this.BuildingInfo.provinceId)
                }else{
                    this.setState({
                        errText: response.data.message,
                        errType: 'war'
                    });
                    setTimeout(() => {
                        this.clearErr();
                    }, 3000);

                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                });
                this.clearErr();
                return
            })
    }

    selectTypeOfBuilding(id) {
        this.setState({
            activeIndex: id
        });
        this.BuildingInfo.buildingType = id;
    }


    getCities() {
        ApiService('Post', '/City/List', {
            provinces: true,
            provinceId: 0,
            nameLike: '',
            pageSize: 1000,
            pageNumber: 0
        })
            .then(response => {
                if (response.data.result) {
                    this.setState({
                        province: response.data.data,
                        loading: false
                    });
                    this.state.province.find(elem => {
                        if (elem.id === this.BuildingInfo.provinceId) {
                            this.setState({
                                provincel: elem.text
                            });

                        }
                    });
                    if (this.state.cities.length > 0) {
                        this.state.cities.find(elem => {
                            if (elem.id === this.BuildingInfo.cityId) {
                                this.setState({
                                    city: elem.text
                                });
                            }
                        })
                    }
                }else{
                    this.setState({
                        errText: response.data.message,
                        errType: 'war'
                    });
                    setTimeout(() => {
                        this.clearErr();
                    }, 3000);
                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',

                });
                setTimeout(() => {
                    this.clearErr();
                }, 3000);
                return
            })

    }

    setProvince(id) {
        this.setState({
            loading: true
        });
        this.state.province.find((element) => {
            if (element.id === id) {
                this.setState({
                    provincel: element.text,
                    city: ''

                });
                this.BuildingInfo.provinceId = parseInt(element.id);
                this.BuildingInfo.provinceName = element.text
            }
        })

        ApiService('Post', '/City/List', {
            provinces: false,
            provinceId: parseInt(id),
            nameLike: '',
            pageSize: 1000,
            pageNumber: 0
        })

            .then(res => {
                if (res.data.result) {
                    this.setState({
                        cities: res.data.data,
                        loading: false
                    });

                }else{
                    this.setState({
                        errText: res.data.message,
                        errType: 'war'
                    });
                    setTimeout(() => {
                        this.clearErr();
                    }, 3000);
                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',

                })
            })
            setTimeout(() => {
                this.clearErr();
            }, 3000);


    }

    setCity(id) {
        this.state.cities.find((element) => {
            if (element.id === id) {
                this.setState({
                    city: element.text,
                });
                this.BuildingInfo.cityId = parseInt(element.id);
                this.BuildingInfo.cityName = element.text

            }
        })
    }

    clearErr() {
        this.setState({
            errText: '',
            errType: ''
        });
    }

    changeLocation() {

        this.BuildingInfo.name = this.state.name;
        this.BuildingInfo.floorsNumber = parseInt(this.state.floorNumber);
        this.BuildingInfo.address = this.state.address;
        this.BuildingInfo.plate = this.state.plate;
        this.BuildingInfo.buildingType = this.state.activeIndex;
        this.props.history.push({
            pathname: '/building/editMapBuilding',
            state: {
                objInfo1: this.BuildingInfo,
            }
        });
        console.log('gholiiiiii',this.BuildingInfo);

    }

    uploadFile(files) {
        this.setState({
            uploading: true
        })
        attachFile(files).then(res => {
            console.log('>>>>>>>>>>>>>>>>', res);
            this.BuildingInfo.imageId = res[0].id
            let arrayOfImg = [...this.state.imgSrc];
            this.BuildingInfo.imagePath=res[0].path;
            arrayOfImg.push(URL.createObjectURL(files));
            this.setState({
                imgSrc: arrayOfImg,
                uploading: false
            })
        })
            .catch(err => {

            })

    }
    closeSelect() {
        this.setState({
            showOptions: false
        })
        this.NewSelect && this.NewSelect.lastObj && this.NewSelect.lastObj.close();
    }

    addUtilities(item) {
        this.BuildingInfo.utilities.list.push(item);
    }
    removeUtilities(item) {
        this.BuildingInfo.utilities.list = this.BuildingInfo.utilities.list.filter(item1 => item1.id !== item.id);
    }
    submit() {
        if (this.state.provincel.length < 1 || this.state.city.length < 1 || this.state.name.length < 1 || this.state.floorNumber.length < 1 || this.state.address.length < 1 || this.state.plate.length < 1) {

            this.setState({
                errText: 'تمامی فیلدهای ستاره‌دار الزامی است',
                errType: 'war'
            });
            setTimeout(() => {
                this.clearErr();
            }, 3000);
            return
        }

        this.BuildingInfo.name = this.state.name;
        this.BuildingInfo.floorsNumber = parseInt(this.state.floorNumber);
        this.BuildingInfo.address = this.state.address;
        this.BuildingInfo.plate = this.state.plate;
        this.BuildingInfo.buildingType = this.state.activeIndex;

        this.BuildingInfo.unitsCount = 20;

        this.setState({
            loading: true
        })
        console.log('dataaaa', this.BuildingInfo);
        ApiService('Post', `/Building/SubmitBuilding/${this.BuildingInfo.id}`, this.BuildingInfo)
            .then(res => {
                if (res.data.result) {
                    this.setState({
                        errText: 'ویرایش با موفقیت ثبت شد',
                        errType: 'suc'
                    });
                    MyBuildingList(true).then(res => {

                        setTimeout(() => {
                            this.props.history.push({
                                pathname: '/building',
                                state: {
                                    objInfo1: null,
                                }
                            });
                        }, 3000);

                        //window.location.reload();
                    })
                    // setTimeout(() => {
                    //     this.clearErr();
                    // }, 3000);


                } else {
                    if (res.data.resultCode === 555) {
                        this.setState({
                            errText: res.data.message,
                            errType: 'err',
                            loading: false
                        });
                        setTimeout(() => {
                            this.clearErr();
                        }, 3000);
                    }
                }
            })
            .catch(err => {
                this.setState({
                    errText: 'خطای داخلی سرور',
                    errType: 'err',
                    loading: false

                });
                this.clearErr();
            })

    }
    render() {
        return (
            this.BuildingInfo ?
                <Layout loaded={true} title={this.BuildingInfo.name} loading={this.state.loading}
                    isHome={false} btn={true} btnName={'ثبت تغییرات'}
                    customBack={true}
                    path={'/building'}
                    gradian click={() => {
                        this.submit();
                    }}>
                    <Toast
                        text={this.state.errText}
                        type={this.state.errType}
                    />
                    <div className={'d-flex flex-row-reverse justify-content-between p-2 align-items-center'} >
                        {buildingType.map(item => {
                            return (
                                <BuildingType key={item.id} title={item.title}
                                    active={item.id == this.state.activeIndex}
                                    click={() => { this.selectTypeOfBuilding(item.id) }} />
                            )
                        })
                        }
                    </div>

                    <div style={{
                        height: `inherit`
                    }}>
                        <div className={`m-3`} style={{ height: `calc(100% - 10rem)`, overflow: 'auto' }} >
                            {this.state.imgSrc.length < 1 && !this.BuildingInfo.imagePath ? <div>
                                <div className={`mt-3`} style={{ fontSize: 12, direction: 'rtl', textAlign: 'right' }}>انتخاب عکس ساختمان</div>
                                {!this.state.uploading ? <UploadImg onChange={(e) => {
                                    console.log(e.target.files);
                                    if (e.target.files[0]) {
                                        this.uploadFile(e.target.files[0])
                                    }
                                }}>
                                    {this.state.imgSrc.length > 0 ? <div className={`mr-1`}>
                                        <img src={this.state.imgSrc} style={{ width: 100, height: '100%' }} />
                                        <span className={`badg`}></span>
                                    </div> : null}
                                </UploadImg>
                                    :
                                    <div className={`w-100 text-center`}><img style={{ width: '10%' }} src={loadingGif} /></div>}
                            </div>
                                :
                                <div className={`text-right pb-3 rtl`}>
                                    <img src={this.BuildingInfo.imagePath ? this.BuildingInfo.imagePath : this.state.imgSrc} style={{ width: 70, height: '100%' }} />
                                    <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} onClick={() => {
                                        this.setState({
                                            imgSrc: []
                                        });
                                        this.BuildingInfo.imagePath = null
                                        this.BuildingInfo.imageId = null
                                    }}> حذف</span>
                                </div>
                            }



                            <CpInput plachodler={'نام*'} type={"text"} maxLength={20} value={this.BuildingInfo.name} onChange={(e) => {
                                this.setState({
                                    name: e.target.value
                                })
                            }} />
                            <CpInput plachodler={'طبقات*'} disabled type={"number"} maxLength={2} value={this.BuildingInfo.floorsNumber} onChange={(e) => {
                                this.setState({
                                    floorNumber: e.target.value
                                })
                            }} />
                            <CpInput plachodler={'تعداد واحد'} disabled type={"number"} maxLength={4} value={this.BuildingInfo.unitsCount} />
                            <div className={`w-50 mx-auto`}>
                                <SBotton title={'تغییر موقعیت'} click={() => {
                                    this.changeLocation();

                                }} />
                            </div>

                            <div className={` mb-3`}>
                                <NewSelect options={this.state.province} plachodler={def} obj={false} sel2={true} value={this.state.provincel} closeOther={() => { this.closeSelect() }}>
                                    <div >
                                        {this.state.province.map(item => {
                                            return (
                                                <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={(e) => {
                                                    this.setProvince(item.id);
                                                }}>{item.text}</div>
                                            )
                                        })
                                        }
                                    </div>

                                </NewSelect>
                                <NewSelect options={this.state.cities} plachodler={def2} obj={false} sel2={true} value={this.state.city} closeOther={() => { this.closeSelect() }}  >
                                    <div >
                                        {this.state.cities.map(item => {
                                            return (
                                                <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={(e) => {
                                                    this.setCity(item.id)
                                                }}>{item.text}</div>
                                            )
                                        })
                                        }
                                    </div>
                                </NewSelect>

                            </div>
                            <CpInput plachodler={'آدرس دقیق*'} type={"text"} value={this.BuildingInfo.address} onChange={(e) => {
                                this.setState({
                                    address: e.target.value
                                })
                            }} />
                            <CpInput plachodler={' پلاک*'} type={"text"} value={this.BuildingInfo.plate} onChange={(e) => {
                                this.setState({
                                    plate: e.target.value
                                })
                            }} />
                            <div className={`w-100 rtl`}>
                                {this.state.buildingUtilities.map(item => {
                                    return (
                                        <Checkbox key={item.id}

                                            onChange={(check) => {
                                                if (check) {
                                                    this.addUtilities(item);
                                                } else {
                                                    this.removeUtilities(item)
                                                }
                                            }}
                                            checked={this.BuildingInfo.utilities.list.find(elem => {
                                                return elem.id === item.id
                                            })}
                                            textStle={{ fontSize: '12px', color: '#9e9e9e' }}
                                            Label={item.text} />
                                    )
                                })}
                            </div>



                        </div>
                    </div>

                </Layout>

                : <div></div>

        )
    }


}
export default withRouter(EditBuilding);
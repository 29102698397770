import React, { useEffect, useState } from 'react';
import classes from './ContentBx.module.css'
const ContentBx = props => {
   
    const[height, setHeight]= useState();
    useEffect(() => {
        const Hheight = document.getElementById('baseBx').clientHeight;
        setHeight(Hheight-50);
    },)
   
    return(
        <div className={classes.contentBX} id={'contentBx'} style={{position: props.pose?props.pose:'',
            // position:'relative' ///////////////////modal moshkel peyda mikone//////
             height: `calc(100% - 50px)`,padding: props.paddSet? '0px': '1% 5% 0% 5%',overflow: 'hidden'}}>
            {props.children}
        </div>
    )
}
export default ContentBx;

import axios from 'axios';

const config = {
  headers: {
      'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/x-www-form-urlencoded'
  }
}
//  export const refreshToken = () => {
//   // let mobile = localStorage.getItem('mobileNum');
//    let refToken = localStorage.getItem('ref');
//    let data = `grant_type=refresh_token&refresh_token=${refToken}&client_id=HomTickMobileClient&client_secret=4f5b555b83f844ef8d8022646a6fb4c2-HomTickMobileSecret`;

//    axios.post('https://oaut.drkomak.com/connect/token',data, config)
//    .then(res=>{
//      if(res.data.access_token){
//       localStorage.setItem('token',res.data.access_token );
//       localStorage.setItem('ref',res.data.refresh_token );
//       window.location.reload();
//      }else{
//        console.log('nashod');
//      }
//    })
//    .catch(err=>{
//      console.log('catch error');
//      console.log(err.response);
//    })
//  }
export const axiosUpdate = () => {
  instance.defaults.headers.post[
    'Authorization'
  ] = `bearer ${localStorage.getItem('token')}`;
  instance.defaults.headers.common[
    'Authorization'
  ] = `bearer ${localStorage.getItem('token')}`;
  instance.defaults.headers.post['Content-Type'] = 'application/json';
};

const serviceInstance = axios.create({
   baseURL : 'https://api2.homtick.com/api/Reservation/V1'
    // baseURL: 'http://localhost:5003/api/Reservation/V1'

})
const instance = axios.create({
     baseURL: 'https://api2.homtick.com/api/V1'
    //baseURL: 'http://localhost:5003/api/V1'

});

instance.defaults.headers.post[
  'Authorization'
] = `bearer ${localStorage.getItem('token')}`;
instance.defaults.headers.common[
  'Authorization'
] = `bearer ${localStorage.getItem('token')}`;
instance.defaults.headers.post['Content-Type'] = 'application/json';

serviceInstance.defaults.headers.post[
  'Authorization'
] = `bearer ${localStorage.getItem('token')}`;
serviceInstance.defaults.headers.common[
  'Authorization'
] = `bearer ${localStorage.getItem('token')}`;
serviceInstance.defaults.headers.post['Content-Type'] = 'application/json';

export default instance;
export {serviceInstance as serviceAxios}


import react, { Component } from 'react';
import Modal from '../Modal';
import CpInput from '../../../Components/Input/Input';
import SBotton from '../../Buttons/SBotton/SBotton';
import NewSelect from '../../NewSelect/NewSelect';
import { ApiService, formatMoney } from '../../../value';




let Self = null;
export class RequestMoney extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            errText: '',
            errType: '',
            isOnlineToOfline: false,
            finalData: {
                numberType: 'Other'

            },
            selectedFrom: null,
            desksListFrom: [
                {
                    availableBalance: null,
                    balance: null,
                    deskType: 3,
                    groupId: null,
                    id: 58,
                    isOnline: false,
                    name: "انتخاب صندوق مبدا "
                }
            ],
            desksListTo: {
                availableBalance: 0,
                balance: 0,
                // deskType: 3,
                // groupId: null,
                // id: 58,
                // isOnline: false,
                name: "انتخاب صندوق مقصد "
            },
            userBag: {
                number: "",
                numberType: null,
                ownerName: null
            }

        }
    }

    componentDidMount() {
        if (this.props.user) {
            ApiService('Get', `/User/MyBag`)
                .then(res => {
                    if (res.data.result) {
                        if (res.data.data.userBag.number && res.data.data.userBag.number.length > 0
                            && res.data.data.userBag.ownerName && res.data.data.userBag.ownerName.length > 0
                        )
                            this.setState({
                                userBag: {
                                    number: res.data.data.userBag.number,
                                    // numberType: res.data.data.userBag.numberType,
                                    ownerName: res.data.data.userBag.ownerName
                                }
                            })


                    }
                })

        }
    }
    getDestinationDesks(deskId) {
        ApiService('Post', `/AccRequest/RequestTransferDestinations/${localStorage.getItem('id')}/${deskId}`, null)
            .then(res => {
                if (res.data.result) {
                    if (res.data.data.length > 0) {
                        let temp = this.state.finalData;
                        temp.destinationCashDeskId = res.data.data[0].id;
                        if (res.data.data[0].number && res.data.data[0].number.length > 0) { temp.number = res.data.data[0].number.toLowerCase().replace('ir',''); }
                        if (res.data.data[0].ownerName && res.data.data[0].ownerName.length > 0) { temp.ownerName = res.data.data[0].ownerName; }
                        this.setState({
                            selectedTo: res.data.data[0],
                            finalData: temp,
                            desksListTo: res.data.data,
                            isOnlineToOfline: this.state.selectedFrom.isOnline && !res.data.data[0].isOnline
                        });
                        // this.setState({
                        //     desksListTo: res.data.data,
                        //     selectedTo:null
                        // });



                    }

                }
            })
            .catch(err => {
                this.setState({
                    errText: err.data.message,
                    errType: 'err',
                });
                this.clearErr();
            })
    }
    submit() {
        console.log('!!!!!!!!!!!!!!', this.Amount.getValue());
        let temp = this.state.finalData;
        temp.amount = parseInt(this.Amount.getValue())
        this.setState({
            finalData: temp

        }, console.log('!!!!!!!!!!!!!!', this.state.finalData))
    }


    clearErr() {
        setTimeout(() => {
            this.setState({
                errText: '',
                errType: '',
            })
        }, 3000)
    }
    closeSelect() {

        this.NewSelect && this.NewSelect.lastObj && this.NewSelect.lastObj.close();
    }



    render() {
        return (
            <Modal show={this.props.showModalReq} btn={true} click={this.props.close}>
                <div style={{ maxHeight: '65vh', overflow: 'auto' }}>
                    {!this.props.user ? <>
                        <div className={`text-right mediumF pt-1 pb-1 rtl text-right fntBold `}>جابه‌جایی بین صندوق‌ها:</div>
                        <div className={`d-flex justify-content-between align-items-center flex-row-reverse`}>
                            <div className={'mt-2 text-center'} style={{ fontSize: 13, width: '10%' }}>از</div>
                            <NewSelect options={this.props.desksListFrom} closeOther={() => { this.closeSelect() }}
                                obj={false} sel2={true}
                                value={this.state.selectedFrom ? this.state.selectedFrom : this.state.desksListFrom[0]}
                            >
                                <div >
                                    {this.props.desksListFrom && this.props.desksListFrom.map(item => {
                                        return (
                                            item.balance > 0 ?
                                                <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={(e) => {
                                                    this.props.desksListFrom.find(elem => {
                                                        if (elem.id == item.id) {
                                                            console.log('from', elem);
                                                            let temp = this.state.finalData;
                                                            if (temp.number && temp.number.length > 0) { temp.number = temp.number.toLowerCase().replace('ir',''); }
                                                            if (temp.ownerName && temp.ownerName.length > 0) { temp.ownerName = temp.ownerName; }
                                                            
                                                            temp.sourceCashDeskId = elem.id
                                                            this.setState({
                                                                selectedFrom: elem,
                                                                finalData: temp
                                                            });
                                                            this.getDestinationDesks(elem.id);
                                                        }
                                                    })
                                                }}>{item.name}( <span style={{ color: item.balance < 0 ? 'salmon' : '' }}>{item.balance < 0 ? `${formatMoney(item.balance).replace('-', '')}-` : formatMoney(item.balance)}</span>)</div>
                                                : null)
                                    })
                                    }
                                </div>
                            </NewSelect>
                        </div>
                        <div className={`d-flex justify-content-between align-items-center flex-row-reverse mt-2 mb-2`}>
                            <div className={'mt-2 text-center'} style={{ fontSize: 13, width: '10%' }}>به</div>
                            {this.state.desksListTo.length > 0 ?
                                <NewSelect options={this.state.desksListTo} closeOther={() => { this.closeSelect() }}
                                    obj={false} sel2={true}
                                    value={this.state.selectedTo?this.state.selectedTo:this.state.desksListTo[0]}

                                >
                                    {this.state.desksListTo.length > 0 ? <div >
                                        {this.state.desksListTo.map(item => {

                                            return (
                                                //item.balance ? 
                                                <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={(e) => {

                                                    this.state.desksListTo.find(elem => {
                                                        if (elem.id == item.id) {
                                                            console.log('Tooo', item);

                                                            let temp = this.state.finalData;
                                                            temp.destinationCashDeskId = item.id;
                                                            if (item.number && item.number.length > 0) { temp.number = item.number.toLowerCase().replace('ir',''); }
                                                            if (item.ownerName && item.ownerName.length > 0) { temp.ownerName = item.ownerName; }
                                                            this.setState({
                                                                selectedTo: item,
                                                                finalData: temp,
                                                                isOnlineToOfline: this.state.selectedFrom.isOnline && !item.isOnline
                                                            });
                                                            // let temp = this.state.finalData;
                                                            // temp.destinationCashDeskId = elem.id;

                                                            // this.setState({
                                                            //     selectedTo: item,
                                                            //     finalData: temp
                                                            // });
                                                            // if (!elem.isOnline && this.state.selectedFrom.isOnline) {
                                                            //     this.setState({
                                                            //         isOnlineToOfline: true
                                                            //     })

                                                            // }
                                                        }
                                                    })
                                                }}>{item.name}( <span style={{ color: item.balance < 0 ? 'salmon' : '' }}>{item.balance < 0 ? `${formatMoney(item.balance).replace('-', '')}-` : formatMoney(item.balance)}</span>)
                                                </div>
                                                // : null
                                            )
                                        })
                                        }
                                    </div> : null}
                                </NewSelect> : <div className={`w-100 text-center`} style={{ fontSize: 12, color: 'salmon' }}>{this.state.selectedFrom ?
                                    'صندوق مقصد وجود ندارد' : 'صندوق مبدا را انتخاب کنید'}</div>}
                        </div>
                    </> : null}
                    <CpInput disabled={this.state.desksListTo == 0} plachodler={'مبلغ'} perfix={"ریال"} maxLength={22} type={"money"}
                        ref={e => this.Amount = e} />
                    {this.state.selectedTo && this.state.selectedFrom && !this.state.selectedTo.isOnline && this.state.selectedFrom.isOnline ?
                        <div className={` mt-2`} style={{ width: '100%', }}>
                            <div className={`rtl text-center fntBold mb-2 `} style={{ fontSize: 14, }}>بانک‌های در اولویت : ملی / سامان / پارسیان  </div>
                            <CpInput plachodler={'شماره شبا'}
                                value={this.state.finalData.number ? this.state.finalData.number : null}
                                type={"text"} perfix={"IR"} maxLength={24}
                                onChange={(e) => {
                                    let temp = this.state.finalData;
                                    temp.number = e.target.value.substring(0, 24)
                                    this.setState({
                                        finalData: temp

                                    })
                                }} />
                            <CpInput plachodler={'نام و نام‌خانوادگی دارنده حساب '} type={"text"}
                                value={this.state.finalData.ownerName ? this.state.finalData.ownerName : null}
                                onChange={(e) => {
                                    let temp = this.state.finalData;
                                    temp.ownerName = e.target.value
                                    this.setState({
                                        finalData: temp

                                    })
                                }} />
                        </div> : null}


                    {this.props.user ?
                        <div className={` mt-2`} style={{ width: '100%', }}>
                            <div className={`rtl text-center fntBold mb-2 `} style={{ fontSize: 14, }}>بانک‌های در اولویت : ملی / سامان / پارسیان  </div>
                            <CpInput plachodler={'شماره شبا'} type={"text"} perfix={"IR"}
                                value={this.state.userBag.number ? this.state.userBag.number : null}
                                maxLength={24} onChange={(e) => {
                                    let temp = this.state.finalData;
                                    temp.number = e.target.value
                                    this.setState({
                                        finalData: temp

                                    })
                                }} />
                            <CpInput plachodler={'نام و نام‌خانوادگی دارنده حساب '} type={"text"}
                                value={this.state.userBag.ownerName ? this.state.userBag.ownerName : null}
                                onChange={(e) => {
                                    let temp = this.state.finalData;
                                    temp.ownerName = e.target.value
                                    this.setState({
                                        finalData: temp

                                    })
                                }} />
                        </div> : null}
                    <div className={` w-75 text-center mx-auto `} style={{ opacity: this.state.desksListTo.length == 0 ? '0.4' : ' 2' }}>
                        <SBotton title={!this.props.user ?
                            'ثبت جابه‌جایی'
                            : 'ثبت درخواست'} click={() => {
                                let temp = this.state.finalData;
                                temp.amount = parseInt(this.Amount.getValue())
                                this.setState({
                                    finalData: temp

                                }, this.props.submit(this.state.finalData, this.state.isOnlineToOfline))
                            }} />
                    </div>

                </div>
            </Modal>)
    }
}
export default RequestMoney;
import React from 'react';
import classes from './SBotton.module.css';
import loadingGif from '../../../Assets/gifs/22.gif'




const SBotton = props => {
    let attachedClasses = [];

    if(props.gradian && !props.disabled){
        attachedClasses = [`gradian`, `text-light`];

    }else {
        attachedClasses = [classes.nBack];

    }
    if(props.disabled){
        attachedClasses.push(classes.disabled);
    }
    return (
       <div onClick={!props.sending ?props.click:null} className={`${classes.btn} p-2  w-100 mt-2 mb-2  text-center fntBold ${attachedClasses.join(' ')}`}
        style={{fontSize:props.font ? props.font : 12, cursor: 'pointer'}}>
           {props.sending ?  <img src={loadingGif} style={{ width: '20%', margin: ' 0 auto' }} />  : props.title } 
       </div>
    );
};

export default SBotton;
import React from "react"
import classes from "./ServiceList.module.css"
import User from "../../Assets/myAsset/user.png"
import { Link, useHistory } from "react-router-dom"
import { useServiceContext , setServiceAsCache } from "../../context/serviceContext"
import EmptyList from "../EmptyList/EmptyList"



const ServiceList = ({services})=>{
    const history = useHistory()
    const [ , setService] = useServiceContext()

    const onClick = (item)=>{
        setServiceAsCache( setService , item)
        history.push(
            `/service/provider/${item.id}`
        )
    }

    if(!!services ){
        if(services.length > 0)
        return (
            <div className={`d-flex w-100 flex-column align-items-center overflow-auto pt-2 ${classes.container}`}>
                {
                    services.map(item =>(
                        <div 
                            className={`d-flex flex-column p-3 px-4 ${classes.card}`}
                            onClick={()=>onClick(item)}
                        >
                                <div className="row flex-row-reverse">
                                    <div className="col-6">
                                        <div className="row flex-row-reverse">
                                            <img
                                                src={(!!item.service.icon && !!item.service.icon.thumbnileImagePath && item.service.icon.thumbnileImagePath !== "")?item.service.icon.thumbnileImagePath:User}
                                                className={`${classes.img}`}
                                            />
                                            <span className="mx-2 text-right">
                                                {
                                                    item.service.name
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row flex-row rtl justify-content-end px-2 align-items-center">
                                            <span className={`${classes.text} px-1`}>از</span>
                                            <span className={`${classes.text} text-muted`}>
                                                {
                                                    " "+item.startDate + " "
                                                }
                                            </span>
                                            <span className={`${classes.text} px-1`}> تا </span>
                                            <span className={`${classes.text} text-muted`}>
                                                {
                                                    item.expireDate
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row flex-row-reverse align-items-center">
                                    <span className="mx-2 mt-2">
                                        {
                                            item.service.description
                                        }
                                    </span>
                                </div>
                        </div> 
                        
                    ))
                }
            </div>
        )
        else
          return <EmptyList des={"شما خدمت فعالی ندارید"} />
    }
    return null

}

export default ServiceList

import React from 'react';
import classes from './HomeCard.module.css';
import Card from '../../Card/Card';
import { colors, formatMoney } from "../../../value";
import dec from '../../../Assets/myAsset/bleachRed.svg';
import inc from '../../../Assets/myAsset/bleachGrn.svg';
import lamp from '../../../Assets/myAsset/plus.svg'


const HomeCard = props => {
    return (
        <Card width={'100%'}>
            <div className={`w-100 p-2`}>
                <div className={`d-flex justify-content-around align-items-center w-100 `}>
                    <div className={` text-center`} >
                        <div className={`gradian d-flex align-items-center justify-content-center ${classes.botton}`} style={{ height: props.bedehi ? '' : 70 }}>
                            {
                                props.bedehi ?
                                    <div style={{ color: colors.bg, margin: 5 }} onClick={props.pay}>پرداخت</div>
                                    :
                                    <div className={`w-50 m-2 h-100`}>
                                    <img className={`w-100`} src={lamp} alt={'alt'} />
                                    </div>
                            }

                        </div>
                    </div>
                    <div className={`d-flex flex-column align-items-center w-75 `} >
                        <div className={`d-flex flex-row-reverse justify-content-start align-items-center w-100 pl-2`}>
                            <div  className={`${classes.title} rtl`}>{props.title}</div>
                            <div onClick={props.clickMore} style={{ fontSize: '11px', color: colors.app2 }}>اطلاعات بیشتر</div>
                        </div>

                        <div className={`d-flex flex-row-reverse justify-content-between align-items-center w-100 pl-2`}>
                            <div className={`${classes.subTitle} rtl`}>{props.subTitle}</div>
                            <div>
                                <div className={`d-flex ${classes.amount}`}>
                              
                                    {formatMoney(props.amount)}
                                    <div style={{ width: '12px'}}>
                                    <img style={{ width:'100%', marginLeft: 5}} src={props.change ? inc : dec} alt={'alt'} />
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>

                    </div>
                </div>
                {props.rows.map(item => {
                    return <div key={item.id} className={`w-100 p-2 mt-1 d-flex flex-row-reverse justify-content-between ${classes.eachCase}`}>
                        <div className={`rtl text-right`} style={{ fontSize: '13px' }}>
                            {item.subject}:
                        </div>
                        <div className={`d-flex justify-content-between`} style={{ width: '30%', fontSize: '12px', color: colors.app6 }}>
                        <div>ریال</div>
                            <div>
                                {formatMoney(item.amount)}</div>
                            <img style={{ width: '10px', marginLeft: 5,height:'100%' }} src={dec} alt={'alt'} />

                        </div>
                    </div>
                })}

                {/* <div className={`w-100 p-2 mt-1 d-flex flex-row-reverse justify-content-between ${classes.eachCase}`}>
                    <div style={{ fontSize: '13px' }}>
                        :هزینه تعمیر درب پارکینگ
               </div>
                    <div className={`d-flex  justify-content-between `} style={{ width: '30%', fontSize: '12px', color: colors.app6 }}>
                        <div>{formatMoney(100000125)}</div>
                        <img style={{ width: '10px', marginLeft: 5 }} src={dec} alt={'alt'} />

                    </div>
                </div> */}



            </div>

        </Card>
    );
};

export default HomeCard;
import React from 'react';
import Date from '../../Date/Date';
import Card from '../../Card/Card';
import Doer from '../../Doer/Doer';





const RuleCard = props => {
    return (
        <Card width={'100%'} >
            <div className={`w-100 p-2`} style={{ lineHeight: 2 }} onClick={props.click}>
                <div className={`d-flex w-100 flex-row-reverse justify-content-between align-items-center `}>
                    {/* <div className={`d-flex align-items-center fntBold`}>
                        <div style={{ fontSize: 13, color: colors.app8 }}>عباس افرا</div>
                        <img style={{ borderRadius: '50%', width: 30, height: 30, backgroundColor: 'salmon', marginLeft: 5 }} src={props.image} alt={'alt'} />
                    </div> */}
                    <Doer profileImage={props.img} creator={props.creator}/>
                    <div>
                        <Date date={props.date} font={13} />
                    </div>

                </div>
                <div className={`fntBold mediumF rtl text-right`} >{props.title}</div>
                
                    <div className={`grey smallF text-right`}> 
                     {props.description}
                    {/* <span>...</span>
                    <span className={`mr-3`} style={{color: colors.app1,width:100}}>بیشتر  </span> */}
                </div>
                    

               

            </div>
        </Card>
    );
};

export default RuleCard;
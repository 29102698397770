

import react, { Component } from 'react';
import { ApiService, colors, formatMoney } from '../../../value';
import SBotton from '../../Buttons/SBotton/SBotton';
import ReceiptState from '../../Cards/ReceiptState/ReceiptState';
import Date from '../../Date/Date';
import Doer from '../../Doer/Doer';
import Modal from '../Modal';
import del from '../../../Assets/myAsset/delete.png'



let Self = null;
export class ModalPayDetail extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {

        }
    }
    // setAnswer(key, answer) {
    //     ApiService('Get', `/App/SetQuestion/${key}/${answer}`)
    //         .then(res => {
    //             if (res.data.result) {

    //             } else {

    //             }
    //         })
    // }



    render() {
        return (<Modal show={this.props.showModal} btn={true} click={this.props.close}>
            {this.props.itemInfo ?
                <div>
                    <div className={`text-center mediumF`}>{this.props.subject}</div>
                  
                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                        {this.props.itemInfo.payLogData.map((elem, index) => {
                            return <div key={index} className={`borderShadow p-2 mt-2`} style={{ width: '98%', margin: '1%', borderRadius: 10 }}>
                                <div >
                                    {elem.pBtnAllowDelete ? <div className={`w-100`}>
                                        <div style={{ width: 20 }} >
                                            <img src={del} className={`w-100`} onClick={(e) => {
                                                this.props.delete(elem);
                                                e.stopPropagation();
                                            }} />
                                        </div>
                                    </div> : null}
                                    <div className={` d-flex flex-row-rverse align-items-center`} >
                                        <div style={{ marginRight: 5 }}>
                                            <Date font={10} date={elem.dateCreated} />
                                        </div>
                                        <Doer creator={elem.payer.name} profileImage={elem.payer.profileImageUrl} />
                                    </div>
                                    <div className={` d-flex mt-1 mb-1 align-items-center justify-content-between flex-row-reverse`}>
                                        {/* <div className={`rtl text-right`} style={{ fontSize: 13 }}>مبلغ:</div> */}
                                        <div className={` d-flex mt-1 mb-1 align-items-center justify-content-between flex-row-reverse`}>
                                            <div className={`smallF mr-1 ml-2`}>{
                                                elem.paidMethod == 0 ? '0' :
                                                    elem.paidMethod == 1 ? ':آنلاین' :
                                                        elem.paidMethod == 2 ? ':نقدی' :
                                                            elem.paidMethod == 3 ? ':کیف‌پول' :
                                                                elem.paidMethod == 4 ? ':کارت‌به‌کارت' :
                                                                    elem.paidMethod == 5 ? ':بستانکاری' :
                                                                        elem.paidMethod == 6 ? ':چک' :
                                                                          elem.paidMethod == 7 ? ':انتقال بدهی' :
                                                                            ''

                                            }</div>
                                            <div className={`rtl pr-2 pl-2 pt-1 pb-1 ml-1`}
                                             style={{ borderRadius: 15, backgroundColor: '#eaeaea', fontSize: 12, }}> 
                                             {formatMoney(elem.amount)}</div>
                                            <div style={{ fontSize: 12, color: '#53545f' }}>
                                                ریال
                                           </div>

                                        </div>
                                        <div >
                        <ReceiptState state={this.props.itemInfo.payStatus} />
                    </div>
                                    </div>
                                    {elem.verifyQuestion ? <div className={`mt-2`}>
                                        <div className={`rtl text-center`} style={{ fontSize: '12px ' }}>{elem.verifyQuestion.text}</div>

                                        {elem.verifyQuestion.file ? <div style={{ width: '100%' }}>
                                            {/* <a href={elem.verifyQuestion.file.path}> */}
                                            <div className={` `} onClick={(e) => {
                                                window.open(elem.verifyQuestion.file.path);
                                                e.stopPropagation();
                                            }} style={{
                                                width: 110, height: 80,
                                                backgroundImage: "url(" + elem.verifyQuestion.file.path + ")",
                                                backgroundPosition: 'center',
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                margin: '0 auto'
                                            }}>
                                            </div>
                                            {/* </a> */}
                                        </div> : null}
                                        <div className={`d-flex justify-content-center w-100`} >
                                            {elem.pBtnAllowVerify ? <div style={{ width: '25%' }} className={`m-1`}>
                                                <SBotton title={'بله'} gradian click={() => {
                                                    this.props.setAnswer(elem.verifyQuestion.key, 'YES', elem)
                                                }} />
                                            </div> : null}
                                            <div style={{ width: '25%' }} className={`m-1`}>
                                                <SBotton title={'خیر'} click={() => {
                                                    this.props.setAnswer(elem.verifyQuestion.key, 'No', elem)
                                                }} />
                                            </div>

                                        </div>
                                    </div> : elem.file? <div style={{ width: '100%' }}>
                                            <div className={` `} onClick={(e) => {
                                                window.open(elem.file.path);
                                                e.stopPropagation();
                                            }} style={{
                                                width: 110, height: 80,
                                                backgroundImage: "url(" + elem.file.path + ")",
                                                backgroundPosition: 'center',
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                margin: '0 auto'
                                            }}>
                                            </div>
                                            {/* </a> */}
                                        </div> : null}
                                </div>
                            </div>
                        })}
                    </div>
                </div> : null}
        </Modal>
        )
    }
}

export default ModalPayDetail;
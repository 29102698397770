import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
let Self = null;

class Guid extends Component {
    constructor(props){
        super(props);
        Self = this;
        this.state = {}
    }
   

    
    componentDidMount() {
        window.open('https://homtick.com/guide/');
        this.props.history.goBack();
        
    }

    render() {
        return (<div></div>)
    }
    
}

export default withRouter(Guid);

import React from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { serviceAxios } from "../../axios"
import AddBotton from '../../Components/Buttons/AddBotton/AddBotton';
import Layout from "../../Components/Layout/Layout"
import ServiceInfo from "../../Components/ServiceInfo/ServiceInfo"
import searchImg from '../../Assets/myAsset/loupe.svg';
import TabBar from "../../Components/TabBar/TabBar"
import settings from '../../Assets/myAsset/settings.svg'
import PeriodList from "../../Components/PeriodList/PeriodList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import ServiceFilterModal from "../../Components/Modal/ServiceFilterModal/ServiceFilterModal";
import moment from "moment-jalaali";
import { useServiceContext } from "../../context/serviceContext";


const tabItems = [
    {
        id: 1,
        title: 'اطلاعات'
    },
    {
        id: 2,
        title: 'لیست سانس'
    }
]

const ServiceProviderServiceInfo = ({})=>{
    const location = useLocation()
    const [service] = useServiceContext()
    const [loading , setLoading] = React.useState(true)
    const [showFilterModal , setShowFilterModal] = React.useState(false)
    const [filterData , setFilterData] = React.useState({
        "date" : null,
        "nameLike": "",
        "passed": null
    })
    const [showSearch , setShowSearch] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState(2);
    const [periods , setPeriods] = React.useState([])
    const [totalPeriods , setTotalPeriods] = React.useState(0)
    const history = useHistory()
    const { serviceId } = useParams()
    let pageNumber = React.useRef(0)
    let nameLike = React.useRef("")

    React.useEffect(()=>{
        pageNumber.current = 0
        getPeriods()
    },[filterData])

    const getPeriods = ()=>{
        pageNumber.current++
        serviceAxios({
            method : "post",
            url : `/Period/List/${serviceId}`,
            data : {
                ...filterData , 
                date : (!!filterData.date && filterData.date !== "") ?moment(filterData.date , "jYYYY/jMM/jDD").format("YYYY-MM-DD") : null,
                "pageSize": 10,
                "pageNumber": pageNumber.current
            }
        }).then(res=>{
            setPeriods(pageNumber.current === 1 ? res.data.data : [...periods , ...res.data.data])
            setTotalPeriods(res.data.itemsCount)
            setLoading(false)
            console.log(res)
        })
    }

    const onTabPressed = (tabId)=>{
        setActiveTab(tabId)
    }

    const editClients = ()=>{
        history.push(`/service/provider/clients/${serviceId}`)
    }

    const addSession = ()=>{
        localStorage.removeItem("oldPeriod")
        history.push(`/service/provider/Period/Details/${serviceId}`)
    }

    const onFilterConfirmed = (filterModel)=>{
        setLoading(true)
        setFilterData({...filterData , date : filterModel.startDate})
    }

    const nameLikeChanged = (e)=>{
        nameLike.current = e.target.value
        const nl = e.target.value
        setPeriods([])
        setTimeout(()=>{    
            if(nl === nameLike.current) {
                setFilterData({...filterData , nameLike : nameLike.current})
            }
        },1000)
    }

    return(
        <Layout
            title={"جزییات خدمت"}
            loaded={true}
            loading={loading}
            showMenu={true}
            displayMenu={true}
        > 
        {
            service.pAllowAddPeriod &&
            <div 
                className="row justify-content-between px-4 my-3 align-items-center"
            >
                <div className="ml-3" style={{ width: 25 }}>
                    <img
                        src={settings} alt={''} 
                        style={{ width: '100%' }} 
                        onClick={editClients}
                    />
                </div>
                <div style={{width : "85%"}}>
                    <AddBotton 
                        title={'افزودن سانس'}
                        click={addSession} 
                    />
                </div>
            </div>
        }
            <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`}>
                {
                    tabItems.map(item => {
                        return (
                            <TabBar 
                                title={item.title} 
                                key={item.id} 
                                id={item.id} 
                                activeIndex={activeTab} 
                                click={()=>onTabPressed(item.id)} 
                            />
                        )
                    })
                }
            </div>

            <SearchBar
                click={() => {
                    if (showSearch) {
                        if(showSearch){
                            nameLike.current = ""
                        }
                        setShowSearch(false);
                        filterData.nameLike !== "" && setFilterData({...filterData , nameLike : ""})

                    } else {
                        setShowSearch(true);
                    }
                }}
                sortModule={true}
                clickSort={() => {
                    setShowFilterModal(true)
                }} 
            />
            {
                showSearch ?
                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                        style={{
                            padding: 5,
                            border: ' 1px solid grey',
                            borderRadius: 10
                        }}>
                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                            onKeyUp={event => {

                                if (event.key === 'Enter') {
                                    
                                }
                            }}
                            placeholder={'جستجو'}
                            onChange={(e) => {
                                nameLikeChanged(e)

                            }} />
                        <div style={{ width: 15, }}>
                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                
                            }} />
                        </div>

                    </div>
                    {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                </div>:
                null
            }

            <div 
                className={`d-flex flex-column align-items-end p-2 my-3 mx-1 pb-2`}
                style={{height : "calc(100% - 180px)"}}
            >
                {
                    activeTab === 1?
                    !!service && 
                    <ServiceInfo
                        service={service}
                    />:
                    <PeriodList
                        periods={periods}
                        getPeriods={getPeriods}
                        allPeriodsLength={totalPeriods}
                        serviceId={serviceId}
                        isEditable={service.pAllowAddPeriod}
                    />
                }
            </div>
            <ServiceFilterModal
                    show={showFilterModal} 
                    amount={false} 
                    sortInc={false}
                    typeOdDoc={false}
                    serviceId={serviceId}
                    click={() => {
                        setShowFilterModal(false);
                    }}
                    submit={(filterData) => {
                        console.log('>>>>>>>>>>>>>>>>>>',filterData);
                        setShowFilterModal(false)
                        onFilterConfirmed(filterData)
                    }} 
                />
        </Layout>
    )
}

export default ServiceProviderServiceInfo
import React from "react";
import AddBotton from "../../Components/Buttons/AddBotton/AddBotton";
import Layout from "../../Components/Layout/Layout";
import { useHistory } from "react-router-dom";
import { useAddressContext } from "../../context/addressContext";
import AddressRow from "../../Components/AdressRow/AddressRow";
import SBotton from "../../Components/Buttons/SBotton/SBotton";
import Modal from "../../Components/Modal/Modal";
import { useOrderContext } from "../../context/orderContext";
import TwoOptionModal from "../../Components/TwoOptionModal/TwoOptionModal";


const Addresses = ()=>{
    const {addressState , addressDispatch , getAddresses , deleteAddresses} = useAddressContext()
    const history = useHistory()
    const [showModal , setShowModal] = React.useState(false)
    let deleteAddressId = React.useRef(null)
    const {orderDispatch} = useOrderContext()

    React.useEffect(()=>{
        getAddresses(addressDispatch)
    },[])

    React.useEffect(()=>{
        console.log("addressState" , addressState)
    },[addressState])

    const deleteAddressesPressed = (e , addressId)=>{
        e.stopPropagation()
        deleteAddressId.current = addressId
        setShowModal(true)
    }
    const delelteAdd = ()=>{
        setShowModal(false)
        deleteAddresses(deleteAddressId.current , addressDispatch)
    }

    const addressSelected = (item)=>{
        orderDispatch({type : "SET_ORDER" , data : {addressId : item.id , addressString : item.addressString}})
        history.push(`/service/Groups`)
    }

    return(
        <Layout
            title="لیست آدرس ها"
            loading={addressState.loading}
            isHome={false}
            loaded={true}
            showMenu={true}
            displayMenu={true}
        >
            <div 
                className="px-2 mt-3"
            >
                <AddBotton
                    title="افزودن آدرس"
                    click={()=>history.push("/service/address/add")}
                />
            </div>
            <div className="mt-4 overflow-auto" style={{height : 'calc(100vh - 127px)'}}>
                {
                    addressState.list.map((item , index)=>(
                        <AddressRow
                            address={item}
                            key={item.id}
                            onDelete={deleteAddressesPressed}
                            onClick={()=>addressSelected(item)}
                        />
                    ))
                }
            </div>
            <TwoOptionModal
                title={"از حذف این آدرس مطمئن هستید؟"}
                showModal={showModal}
                setShowModal={setShowModal}
                callback1={delelteAdd}
                callback2={()=>setShowModal(false)}
            />
            
        </Layout>
    )
}

export default (Addresses);
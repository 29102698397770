import React from 'react';
import { default as Select } from 'react-select';





const MySelect = props => {


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: props.select2 ? '' :'#f7f7f7',
      borderRadius:  props.select2 ? '4px' : '15px',
      borderColor: props.select2 ? '424242':'#f7f7f7',
      marginBottom:'14px',
      flexDirection:'row-reverse'
  
  
    }),
    // valueContainer: (provided, state) => ({
    //   ...provided,
    //   direction: 'rtl'
  
    // }),
    option: (provided, state) => ({
      ...provided,
      textAlign: 'right',
      fontSize: 12,
      borderRadius: 0,
      backgroundColor: state.isDisabled
        ? null
        : state.isSelected
          ? '#ff5722'
          : state.isFocused
            ? '#f5f5f5'
            : null,
  
    }),
    menu:(provided, state)=>({
      ...provided,
      backgroundColor: 'white',
      zIndex:100
    }),
    indicatorSeparator:(provided, state) =>({
      ...provided,
      width: '0'
  
    }),
    valueContainer:(provided, state) =>({
      ...provided,
      padding: props.select2 ? '14.5px 10px' : '5px 8px',
      direction: 'rtl'
  
    }),
  
   
  }


  return (
    <Select 
      options={props.options}
      placeholder={props.placeholder}
      styles={customStyles}
      isRtl={true}
      defaultValue={props.value}
      theme={theme => ({
        ...theme,
        borderRadius: 12,
        border: '2px solid #ff5722',
        colors: {
          ...theme.colors,
          primary25: '#ff5722',
          primary: '#ff5722',
        },
      })}
    />);
};

export default MySelect;
import React from 'react';
import Date from '../../Date/Date';
import Card from '../../Card/Card';
import Doer from '../../Doer/Doer';
import SBotton from '../../Buttons/SBotton/SBotton';





const QuestionCard = props => {
    return (
        <Card width={'100%'} >
            <div className={`w-100 p-2`} style={{ lineHeight: 2 }} onClick={props.click}>
                <div className={`mt-2`}>
                    <div className={`rtl text-center`} style={{ fontSize: '12px ' }}>{props.text}</div>

                    {props.img ? <div style={{ width: '100%' }}>
                        {/* <a href={elem.verifyQuestion.file.path}> */}
                        <div className={` `} onClick={() => {
                            window.open(props.img)
                        }} style={{
                            width: 110, height: 80,
                            backgroundImage: "url(" + props.img + ")",
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            margin: '0 auto'
                        }}>
                        </div>
                        {/* </a> */}
                    </div> : null}
                    <div className={`d-flex align-content-stretch flex-wrap w-100  justify-content-center align-items-baseline `} >

                       { props.options.map((elem, index) =>{
                           return <div key={index} style={{ }} className={`m-1`}>
                           <SBotton title={elem.text} click={() => {
                               props.setAnswer(elem.id)
                           }} />
                       </div>
                       })  }
                        {/* <div style={{ width: '25%' }} className={`m-1`}>
                            <SBotton title={'خیر'} click={() => {
                                // this.props.setAnswer(elem.verifyQuestion.key, 'No')
                            }} />
                        </div> */}

                    </div>
                </div>
            </div>
        </Card>
    );
};

export default QuestionCard;
import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout/Layout';
import NewSelect from '../../Components/NewSelect/NewSelect';
import SearchBar from '../../Components/SearchBar/SearchBar';
import Toast from '../../Components/Toast/Toast';
import { ApiService, formatMoney, getBuildingId, MyBuildingList } from '../../value';
import { withRouter } from 'react-router-dom';
import EmptyList from '../../Components/EmptyList/EmptyList';
import FilterModal2 from '../../Components/Modal/FilterModal2/FilterModal2';
import searchImg from '../../Assets/myAsset/loupe.svg';
import DebtsCard from '../../Components/Cards/DebtsCard/DebtsCard';
import dec from '../../Assets/myAsset/bleachRed.svg';
import inc from '../../Assets/myAsset/bleachGrn.svg';
import NoAccess from '../../Components/NoAccess/NoAccess';
import { Checkbox } from '../../Components/CheckBox/CheckBox';







const Deposits = props => {
    const [height, setHeight] = useState();
    const [loading, setLoading] = useState(true);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [userType, setUserType] = useState(0);


    const [buildingList, setBuildingList] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);


    const [deposits, setDeposits] = useState([]);
    const [depositsToShow, setDepositsToShow] = useState([]);

    const [sum, setSum] = useState(0);

    const [search, setSearch] = useState(false);
    const [filterModal, setFilterModal] = useState(false);
    const [noAcc, setNoAcc] = useState(false)








    // useEffect(() => {
    //     const Hheight = document.getElementById('contentBx').clientHeight;
    //     setHeight(Hheight - 200);
    //     getMyBuildingList();
    // }, [height]);


    // useEffect(() => {
    //     if(localStorage.getItem('id'))
    //     getDepositsList()
    // }, [selectedBulding]);


    useEffect(() => {
        if (localStorage.getItem('id')) {
            getDepositsList()
        }
    }, [userType]);






    const getDepositsList = () => {
        setLoading(true)
        ApiService('Get', `/AccRequest/CreditorUnits/${getBuildingId()}`)
            .then(response => {
                if (response.data.result) {
                    setBuildingList(response.data.data.buildings);
                    if (response.data.data.currentBuilding) {
                        setNoAcc(false);
                        setSelectedBulding(response.data.data.currentBuilding);
                        let list = [];
                        let sum = 0
                        if (userType == 0) {
                            response.data.data.units.forEach(element => {
                                sum += element.residentBestankari + element.ownerBestankari
                                if (element.residentBestankari > 0) {
                                    list.push(element);
                                }
                            });
                            console.log('list0',list);
                            setDepositsToShow(list)
                            setDeposits(list);
                            setSum(sum)
                        } else {
                            response.data.data.units.forEach(element => {

                                if (element.ownerBestankari > 0) {
                                    list.push(element);
                                }
                            });
                            console.log('list1',list);
                            setDeposits(list);
                            setDepositsToShow(list)
                            //setSum(sum)
                        }
                        setLoading(false);
                    } else {
                        console.log('hhh');
                        setNoAcc(true);
                        setLoading(false);
                    }
                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    setTimeout(() => {
                        props.history.push('/building');
                    }, 2000);
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }
    const searchHandler = (text) => {
        console.log(text, '<<<<<<<<<<<');
        if (text.length > 3) {
            let tmp = [];
            //setSearchText(text);
            deposits.forEach(element => {
                console.log(element);
                if (
                    //element.area.toString().find(this.searchText)+   .search("ooo") 
                    (element.blockName ? element.blockName.search(text) : -1) >= 0 ||
                    (element.floor ? element.floor.search(text) : -1) >= 0 ||
                    (element.name ? element.name.search(text) : -1) >= 0 ||
                    ((element.owner && element.owner.name) ? element.owner.name.search(text) : -1) >= 0 ||
                    ((element.owner && element.owner.mobileNumber) ? element.owner.mobileNumber.search(text) : -1) >= 0 ||
                    ((element.resident && element.resident.name) ? element.resident.name.search(text) : -1) >= 0 ||
                    ((element.resident && element.resident.mobileNumber) ? element.resident.mobileNumber.search(text) : -1) >= 0
                ) {
                    tmp.push(element);
                }
            });
            setDepositsToShow(tmp)
        }
        else {
            setDepositsToShow(deposits);

        }

    }



    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    const closeSelect = () => {

        NewSelect && NewSelect.lastObj && NewSelect.lastObj.close();
    }
    const def =
    {
        id: '0',
        text: 'ساختمان خود را انتخاب کنید'
    };
    return (
        <Layout loaded={true} title={'لیست بستانکاران'} isHome={false} showMenu={true} loading={loading} displayMenu={!loading} customBack={true}
            path={'/'}>
            <Toast
                text={errText}
                type={errType}
            />
            <FilterModal2 show={filterModal} amount={true} sortInc ={true} click={() => {
                // setFilterModal(false);
                // setFilterData(null);
            }}
                submit={(filterData) => {

                    // setFilterData(filterData)
                    // setFilterModal(false)
                }} />

            <div style={{ height: 'inherit' }}>
                {noAcc ? <>

                    {buildingList.length > 0 ? <div className={`mt-2 mb-3`}>
                        <NewSelect options={buildingList} plachodler={def} closeOther={() => { closeSelect() }}
                            obj={false}
                            sel2={false}
                            select={selectedBulding ? true : false}
                            // desk={selectedBulding.cashDeskAmount}
                            label={'ساختمان خود را انتخاب کنید'}
                        >
                            <div>
                                {buildingList.map((item, index) => {
                                    return (
                                        <div key={index} className={`p-2 d-flex justify-content-between align-items-center flex-row-reverse`}
                                            onClick={() => {
                                                buildingList.find(elem => {
                                                    if (elem.id == item.id) {
                                                        localStorage.setItem('id', elem.id);
                                                        setSelectedBulding(elem);
                                                        getDepositsList();
                                                        //setSelectedBulding(elem);

                                                    }
                                                })
                                            }}>
                                            <div className={`pr-2 fntBold mediumF grey`}>{item.name}</div>
                                            {item.cashDeskAmount ? <div className={`fntBold mediumF`} style={{ display: 'flex' }} >{formatMoney(item.cashDeskAmount)}
                                                {item.cashDeskAmount != 0 ?
                                                    <div style={{ width: '10px' }}>
                                                        <img style={{ width: '100%', marginLeft: 5 }}
                                                            src={item.cashDeskAmount > 0 ? inc : item.cashDeskAmount < 0 ? dec : null} alt={'alt'} />
                                                    </div>
                                                    : null}
                                            </div> : null}
                                        </div>
                                    )
                                })}
                            </div>
                        </NewSelect>
                    </div> : null}
                    <NoAccess Text={'شما دسترسی لازم برای ورود به این لیست را ندارید'}></NoAccess>

                </> : <>
                        {buildingList.length > 1 ?
                            <div className={`mt-2 mb-3`} >
                                <NewSelect options={buildingList} plachodler={def} obj={false} sel2={false} value={selectedBulding} closeOther={() => { closeSelect() }}>
                                    <div onClick={(e) => {
                                        buildingList.find(elem => {
                                            if (elem.id == e.target.value) {
                                                setSelectedBulding(elem);
                                                localStorage.setItem('id', elem.id);
                                            }
                                        })
                                    }}>
                                        {buildingList.map(item => {
                                            return (
                                                <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                                    buildingList.find(elem => {
                                                        if (elem.id == item.id) {
                                                            localStorage.setItem('id', elem.id);
                                                            setSelectedBulding(elem);
                                                            getDepositsList();

                                                        }
                                                    })
                                                }}>{item.name}</div>
                                            )
                                        })
                                        }
                                    </div>

                                </NewSelect>
                            </div>
                            :
                            buildingList.length == 1 ?
                                <div className={`w-100 text-right pt-1 pb-1 pr-4 mt-2 mb-2 single`}>
                                    {buildingList[0].name}
                                </div>
                                : null
                        }
                        <div className={`d-flex pt-2 pb-2 p-1 justify-content-between align-items-center backGreyLight flex-row-reverse mb-3`}>
                            <div className={`rtl text-right mediumF `}>جمع کل بستانکاری  :</div>
                            <div className={`d-flex align-items-center`} style={{ color: '#757575' }}>
                                <div style={{ fontSize: 10, marginRight: 3 }}>ریال</div>
                                <div style={{ fontSize: 12, }}> {formatMoney(sum)}</div>
                                <div style={{ width: 20 }}>
                                    <img style={{ width: '50%', marginLeft: 5, marginBottom: 5 }} src={inc} alt={'alt'} />
                                </div>

                            </div>
                        </div>

                        <div className={`d-flex justify-content-between align-items-center m-2 p-2 borderShadow `} style={{ border: 1, borderRadius: 10 }}>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Type={'Radio'} Label={'ساکن '} checked={userType == 0} textStyle={{ fontSize: '10px' }}
                                    onChange={checked => {
                                        if (checked) {

                                            setUserType(0);
                                            setDeposits([])

                                        }
                                    }} Name={'dec'} />
                            </div>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Type={'Radio'} Label={'مالک '} textStyle={{ fontSize: '10px' }}
                                    checked={userType == 1}
                                    Name={'dec'} onChange={checked => {
                                        if (checked) {
                                            setUserType(1); setDeposits([])
                                        }
                                    }} />
                            </div>
                        </div>

                        <div >
                            {deposits.length > 0 ? <SearchBar
                                click={() => {
                                    // console.log('lllll', search);
                                    if (search) {

                                        setSearch(false);
                                        // getBuildingUnits(localStorage.getItem('id'), '')

                                    } else {
                                        setSearch(true);
                                    }
                                }}
                                // sortModule={true}
                                clickSort={() => {
                                    setFilterModal(true)
                                }}
                            /> : null}
                            {search ?
                                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                                        style={{
                                            padding: 5,
                                            border: ' 1px solid grey',
                                            borderRadius: 10
                                        }}>
                                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                            onKeyUp={event => {

                                                if (event.key === 'Enter') {
                                                    //getBuildingUnits(localStorage.getItem('id'), searchText)
                                                }
                                            }}
                                            placeholder={'جستجو'}
                                            onChange={(e) => {

                                                searchHandler(e.target.value)

                                            }} />
                                        <div style={{ width: 15, }}>
                                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                                //getBuildingUnits(localStorage.getItem('id'), searchText)
                                            }} />
                                        </div>

                                    </div>
                                    {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                                </div>
                                : null}
                        </div>

                        {depositsToShow.length > 0 ?
                            <>

                                <div style={{ height: `calc(100% - 12rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>
                                    <>
                                        {depositsToShow.map((item, index) => {
                                            return <DebtsCard key={index} subject={item.name}
                                                name={`${userType == 0 ? 'بستانکاری ساکن' : 'بستانکاری مالک'}  (${userType == 0 ? item.resident.name : item.owner.name})`}
                                                amount={userType == 0 ? item.residentBestankari : item.ownerBestankari} />
                                        })}
                                    </>

                                    {/* { deposits.map(item =>{
                   return 
               })} */}
                                </div>
                            </> :

                            <EmptyList des={'بستانکار وجود ندارد'} />

                        }
                    </>}
            </div>
        </Layout>
    )
}
export default withRouter(Deposits);



import React from "react"
import { serviceAxios } from "../../axios"
import SBotton from "../Buttons/SBotton/SBotton"
import DatePicker from "../DatePicker/DatePicker"
import CpInput from "../Input/Input"
import TimePicker from "../TimePicker/TimePicker"
import Toast from "../Toast/Toast"
import {useHistory, useParams} from "react-router-dom"
import moment from "moment-jalaali"
import { useServiceContext } from "../../context/serviceContext"
import { formatMoney } from "../../value"
import TwoOptionModal from "../TwoOptionModal/TwoOptionModal"
import Modal from "../Modal/Modal"

const initialState = {
    "id": 0,
    "title": "",
    "description": "",
    "extraPrice": 0,
    "capacity": "",
    "startTime": "",
    "endTime": "",
    "dateTime": "" , //(new Date()).toISOString().slice(0, 25)
    "dateTimeString": "",
    "dayOfWeek": 0,
    "price" : 0
}

const reducer = (state , action)=>{
    switch(action.type){
        case "EDIT_TITLE":
            return{...state , title : action.data}
        
        case "EDIT_DESC":
            return{...state , description : action.data}
                
        case "EDIT_DATE":
            return{...state , dateTime : action.data , dateTimeString : action.data}
        
        case "EDIT_START_TIME":
            return{...state , startTime : action.data}

        case "EDIT_END_TIME":
            return{...state , endTime : action.data}

        case "EDIT_CAPACITY":
                return{...state , capacity : action.data}

        case "EDIT_PRICE":
                return{...state , price : action.data}

        case "SET_PERIOD":
                return{...state , ...action.data}

        default : 
            return {...state}
    }
}

const Single = ({oldPeriod})=>{
    const [period , setPeriod] = React.useReducer(reducer , initialState)
    const [isEdit , setIsEdit] = React.useState(false)
    const [loading , setLoading] = React.useState(false)
    const [showDatePicker , setShowDatePicker] = React.useState(false)
    const [showSuccessModal , setShowSuccessModal] = React.useState(false)
    const [showModal , setShowModal] = React.useState(false)
    const [error , setError] = React.useState({errTxt : "" , errType : ""})
    const [timePicker , setTimePicker] = React.useState({
        show : false,
        label : "",
        onSetTime : ()=>false
    })
    const {serviceId} = useParams()
    const [service] = useServiceContext()
    const history = useHistory()

    console.log(service)
    React.useEffect(()=>{
        const oldPeriod = localStorage.getItem("oldPeriod")
        
        console.log()
        if(oldPeriod){
            const parsedOldPeriod = JSON.parse(oldPeriod)
            setPeriod({
                type : "SET_PERIOD" , 
                data : {
                    ...parsedOldPeriod , 
                    dateTimeString : moment(parsedOldPeriod.dateTime).format("jYYYY/jMM/jDD"),
                    dateTime : moment(parsedOldPeriod.dateTime).format("jYYYY/jMM/jDD"),
                    price : service.price + parsedOldPeriod.extraPrice
                }
            })
            setIsEdit(true)
        }
        else{
            setPeriod({
                type : "EDIT_PRICE" ,
                data : service.price
            })
        }
    },[])

    const setTitle = (e)=>{
        setPeriod({type : "EDIT_TITLE" , data : e.target.value})
    }

    const setDesc = (e)=>{
        setPeriod({type : "EDIT_DESC" , data : e.target.value})
    }

    const setStartTime = (time)=>{
        setTimePicker({show : false})
        setPeriod({type : "EDIT_START_TIME" , data : time})
        console.log(time)
    }

    const setEndTime = (time)=>{
        setTimePicker({show : false})
        setPeriod({type : "EDIT_END_TIME" , data : time})
        console.log(time)
    }


    const setDate = (date)=>{
        setShowDatePicker(false)
        setPeriod({type : "EDIT_DATE" , data : date})
    }

    const setCapacity = (e)=>{
        setPeriod({type : "EDIT_CAPACITY" , data : e.target.value})
    }

    const setPrice = (e)=>{
        setPeriod({type : "EDIT_PRICE" , data : e.target.value})
    }
    
    const register = ()=>{
        if(period.title !== "" && period.description !=="" && period.dateTime !== "" && 
            period.startTime !== "" && period.endTime !== "" && period.capacity !== ""){
            if(period.endTime > period.startTime){
                if(/^[0-9]*$/.test(period.capacity)){
                    setLoading(true)
                    serviceAxios({
                        method: 'Post',
                        url: `Period/Submit/${serviceId}`,
                        data: {
                            ...period,
                            capacity : parseInt(period.capacity),
                            dateTime : moment(period.dateTime , 'jYYYY/jMM/jDD').format("YYYY-MM-DD"),
                            dateTimeString : moment(period.dateTime , 'jYYYY/jMM/jDD').format("YYYY-MM-DD"),
                            extraPrice : parseInt(period.price) - parseInt(service.price)
                        }
                    })
                    .then(res => {
                        console.log(res);
                        setLoading(false)
                        if(res.data.result) {
                            errorHandler("سانس با موفقیت ثبت شد", "suc")
                            setShowSuccessModal(true)
                        }
                        else
                            errorHandler(res.data.message, "err")

                    })
                    .catch(err => {
                        console.log(err.response);
                        setLoading(false)
                    })
                }else{
                    errorHandler("ظرفیت را وارد کنید" , "err")
                }
            }
            else{
                errorHandler("زمان پایان باید بزرگتر از زمان شروع باشد")
            }
        }
        else{
            errorHandler("تمام فیلدها را پر کنید")
        }
    }

    const errorHandler = (text , type="war")=>{
        setError({
            errType : type,
            errTxt : text
        })
        setTimeout(()=>{
            setError({
                errType : "",
                errTxt : ""
            })
        },3000)
    }

    const delelteConfirmed = ()=>{
        setShowModal(false)
        serviceAxios.post("/Period/Delete/" + period.id)
        .then(res => {
            if(res.data.resultCode === 200){
                errorHandler("سانس با موفقیت حذف شد", 'suc')
                setTimeout(()=>{
                    history.goBack()
                },2000)
            }else{
                errorHandler(res.data.message , "err")
            }
        })
    }

    return(
        <div className="d-flex w-100 flex-column">
            <CpInput 
                plachodler={'عنوان'}
                disabled={false}
                wdth={"95%"}
                value={period.title} 
                onChange={setTitle}
            />
            <CpInput 
                plachodler={'توضیحات'}
                disabled={false}
                wdth={"95%"}
                value={period.description} 
                onChange={setDesc}
            />
            <div
                onClick={()=>isEdit? true : setShowDatePicker(true)}
            >
                <CpInput 
                    plachodler={'تاریخ'}
                    disabled={isEdit}
                    wdth={"95%"}
                    value={period.dateTime} 
                />
            </div>
            <div
                onClick={()=> isEdit?true : setTimePicker({show : true , onSetTime : setStartTime , label : "ساعت شروع"})}
            >
                <CpInput 
                    plachodler={'ساعت شروع'}
                    disabled={isEdit}
                    wdth={"95%"}
                    value={period.startTime} 
                />
            </div>
            <div
                onClick={()=> isEdit?true : setTimePicker({show : true , onSetTime : setEndTime , label : "ساعت پایان"})}
            >
                <CpInput 
                    plachodler={'ساعت پایان'}
                    disabled={isEdit}
                    wdth={"95%"}
                    value={period.endTime} 
                />
            </div>
            <CpInput 
                plachodler={'ظرفیت'}
                disabled={false}
                wdth={"95%"}
                onChange={setCapacity}
                value={period.capacity} 
            />
            {
                service.hasPrice &&
                <CpInput 
                    plachodler={'مبلغ (ریال)'}
                    disabled={false}
                    wdth={"95%"}
                    onChange={setPrice}
                    value={period.price} 
                />
            }
            <div className="d-flex flex-row" style={{width : "95%"}}>
                {
                    isEdit &&
                    <div className="d-flex flex-grow-1 mx-2">
                        <SBotton
                            title="حذف"
                            click={()=>setShowModal(true)}
                            sending={loading}
                            
                        />
                    </div>
                }
                <div className="d-flex flex-grow-1 mx-2">
                    <SBotton
                        title="ثبت"
                        click={register}
                        sending={loading}
                        gradian
                    />
                </div>
            </div>
            <DatePicker 
                showPicker={showDatePicker} 
                disMiss={() => {
                    setShowDatePicker(false)
                }} 
                click={setDate}
            /> 
            <TimePicker
                label={timePicker.label}
                onSetTime={timePicker.onSetTime}
                show={timePicker.show}
                onClose={()=>setTimePicker({show : false})}
            />
            <Toast
                text={error.errTxt}
                type={error.errType}
            />
            <TwoOptionModal
                title={"از حذف این سانس مطمئن هستید؟"}
                showModal={showModal}
                setShowModal={setShowModal}
                callback1={delelteConfirmed}
                callback2={()=>setShowModal(false)}
            />

            <Modal
                show={showSuccessModal}
                click={()=>setShowSuccessModal(false)}
            >
                <div className="d-flex flex-column align-items-center">
                    <div className="text-center m-3">
                        سانس با موفقیت ثبت شد
                    </div>
                    <div className="w-50 align-self-center">
                        <SBotton
                            title="تایید"
                            click={()=>setShowSuccessModal(false)}
                            sending={loading}
                            gradian
                        />
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default Single

import React from 'react';
import empty from '../../Assets/myAsset/empty.png';
import emptyAnim from '../../Assets/lotties/empty.json';

import Lottie from 'react-lottie'

const EmptyList = props => {  

    const defultOption ={
        loop:true,
        autoplay:true,
        animationData: emptyAnim,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        
    }
    return (
        <div className={`w-100 text-center mt-4 mb-4`} >
            {/* <img style={{ width: '50%' ,height:'100%'}} src={empty} alt={'alt'} /> */}
            <div style={{width:'50%',margin:'0 auto'}}>
                 <Lottie options={defultOption}/>
            </div>
           
            <div style={{ color: '#6c757d', fontSize: 12 }} >{props.des}</div>
        </div>
    )
}
export default EmptyList;
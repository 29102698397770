import React from 'react';
import { colors, formatMoney } from "../../../value";
import Card from '../../Card/Card';
import user from '../../../Assets/myAsset/user.png';
import phone from '../../../Assets/myAsset/smartphone.svg';
import hasApp from '../../../Assets/lotties/appinstall.json';
import hasnotApp from '../../../Assets/lotties/havenotapp.json';
import Lottie from 'react-lottie'


const haveNApp ={
    loop:false,
    autoplay:true,
    animationData: hasnotApp,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    
}
const haveApp ={
    loop:false,
    autoplay:true,
    animationData: hasApp,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    
}

const UnitCard = props => {
    return (
        <Card width={'100%'} >
            <div className={`w-100 p-2`} onClick={props.click}>
                <div className={`d-flex flex-row-reverse justify-content-between w-100 `}>
                    <div className={`d-flex align-items-center fntBold`}>
                      {/* {props.hasApp ?<div >
                      <Lottie options={  haveApp} width={40}/>
                        </div>:  <Lottie options={  haveNApp} width={40}/>}  */}
                         <div className={`mr-2`} style={{ fontSize: 10, color: colors.app8 }}>({props.who})</div>
                        
                        <div style={{ fontSize: 13, color: colors.app8  ,textAlign:"right"}}>{props.name}</div>
                        <div style={{ borderRadius: '50%', width: 30, height: 30, marginLeft: 5 }}>
                            <img className={`w-100 h-100`} src={props.image ? props.image : user} alt={'user'} />
                        </div>
                    </div>
                    {props.amount != null ?
                    
                        <div style={{ fontSize: 12 }} className={`d-flex flex-row-reverse  align-items-center mt-1 mb-1 `}>
                            <div style={{ direction: 'rtl', textAlign: 'right' }}>
                                {props.alternativeText}
                                {props.alternativeData}
                            </div>
                        </div>

                        :
                        <div style={{ fontSize: 12 }} className={`d-flex flex-row-reverse  align-items-center mt-1 mb-1 `}>
                            <div style={{ direction: 'rtl', textAlign: 'right' }}>
                                {props.textData}
                                {props.additionalData}
                            </div>
                        </div>
                        }


                </div>

                <div className={`d-flex flex-row-reverse justify-content-between w-100 `}>  
                     <div className={`d-flex flex-row-reverse justify-content-start  align-items-center fntBold w-100`}>
                    {props.block ? <div style={{ fontSize: 10, color: colors.app6, marginRight: 8 }}>  بلوک{props.block}</div> : null}
                    <div style={{ fontSize: 10, color: colors.app6, marginRight: 8 }}>  طبقه {` ${props.floor} `} </div>
                    <div style={{ fontSize: 10, color: colors.app6, marginRight: 8 }}>  {props.unit} </div>
                </div>
                {props.hasApp==true ?<div>
                      <Lottie options={  haveApp} width={40}/>
                        </div>:
                         props.hasApp==false ? <Lottie options={  haveNApp} width={40}/>: null} 
               
                </div>
               


                {props.amount != null ? <div className={` flex-row-reverse justify-content-between  mt-2 p-2 `}
                    style={{ fontSize: 12, display: 'flex', backgroundColor: '#f7f7f7', borderRadius: '10px' }}>
                    <div style={{direction:'rtl'}}>{props.desc}</div>
                    <div >{formatMoney(props.amount)}</div>
                </div> : null}

            </div>



        </Card>
    );
};

export default UnitCard;
import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout/Layout';
import settings from '../../Assets/myAsset/settings.svg'
import AddBotton from '../../Components/Buttons/AddBotton/AddBotton';
import NewSelect from '../../Components/NewSelect/NewSelect';
import TabBar from '../../Components/TabBar/TabBar';
import SearchBar from '../../Components/SearchBar/SearchBar';
import searchImg from '../../Assets/myAsset/loupe.svg';
import PayableCard from '../../Components/Cards/PayableCard/PayableCard';
import { ApiService, colors, MyBuildingList } from '../../value';
import { withRouter } from 'react-router-dom';
import Toast from '../../Components/Toast/Toast';
import EmptyList from '../../Components/EmptyList/EmptyList';
import FilterModal2 from '../../Components/Modal/FilterModal2/FilterModal2';
import Switch from "react-switch";









const Receipt = props => {
    // const [tab, setTab] = useState(true);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(null);
    const [renderItem, setRenderItem] = useState(null);

    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');

    const [buildingList, setBuildingList] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);

    const [withArchive, setWithArchive] = useState(false);

    const [permissions, setPermissions] = useState(null);

    const [allDocs, setAllDocs] = useState([]);
    const [myDocs, setMyDocs] = useState([]);
    const [filterModal, setFilterModal] = useState(false);
    const [search, setSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filterData, setFilterData] = useState(null)

 useEffect(() => {
        getMyBuildingList()
    }, []);
    useEffect(() => {
        if (activeTab == 2) {
            setRenderItem(renderAllReceipt())
            setLoading(false);
            setFilterData(null)
        } else {
            setRenderItem(renderMyReceipt())
            setLoading(false);
            setFilterData(null)
        }
    }, [activeTab, allDocs, myDocs, search, searchText, withArchive]);

   
    useEffect(() => {
        if (myDocs.length == 0) {
            setActiveTab(2);
        }
    }, []);




    useEffect(() => {
        console.log('2121');
        if (localStorage.getItem('id')) {
            if (activeTab == 1) {
                getMyListRequestDocuments()

            } else {
                getAllListRequestDocuments()
            }

        } else {
            return function cleanup() { }
        }
    }, [withArchive]);

    useEffect(() => {
        if (localStorage.getItem('id'))
           {
               
            if(activeTab==1)
           { 
               console.log('88888');
                getMyListRequestDocuments();
            }else{
                getAllListRequestDocuments()

           }
        
        }
    }, [ filterModal, search]);

    const getAllListRequestDocuments = () => {
        setLoading(true);
        let data = {
            withArchivedRequests: withArchive,
            assignedToMe: false,
            nameLike: searchText,
            pageSize: 10000,
            pageNumber: 0,
            sortIsDesc: true
        }
        if (filterData) {
            data.sortIsDesc = filterData.sortIsDesc;
            data.sortByType = filterData.sortByType;
            data.startAmount = filterData.startAmount;
            data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }
        ApiService('POST', `/AccRequest/ListRequestDocuments/${localStorage.getItem('id')}`, data)
            .then(res => {
                console.log('Alllllllllllll', res);
                if (res.data.result) {
                    setAllDocs(res.data.data);
                    setPermissions(res.data.data2);

                    // setActiveTab(2);
                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }


    const getMyListRequestDocuments = () => {
        setLoading(true);
        let data = {
            withArchivedRequests: withArchive,
            assignedToMe: true,
            nameLike: searchText,
            pageSize: 10000,
            pageNumber: 0,
            sortIsDesc: true
        }
        if (filterData) {
            data.sortIsDesc = filterData.sortIsDesc;
            data.sortByType = filterData.sortByType;
            data.startAmount = filterData.startAmount;
            data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }
        ApiService('POST', `/AccRequest/ListRequestDocuments/${localStorage.getItem('id')}`, data)
            .then(res => {
                if (res.data.result) {
                    setMyDocs(res.data.data);
                    setLoading(false);
                    setPermissions(res.data.data2);
                    //setActiveTab(1);
                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    setLoading(false);
                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }


    const getMyBuildingList = () => {
        MyBuildingList(false).then(res => {
            let list = res;
            if (list) {
                setBuildingList(list);
                setSelectedBulding(list.find(elem => { return elem.id == localStorage.getItem('id') }));
                //setActiveTab(1)
                //getMyListRequestDocuments()
                // if (activeTab == 1)
                //    { getMyListRequestDocuments();}
                // else
                //    { getAllListRequestDocuments();}
            }
        })
            .catch(err => {
                props.history.push('/')
                // setErrText(err.data.message);
                // setErrType('war');
                // clearErr();
                // setLoading(false);
            })
    }


    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }

    const closeSelect = () => {

        NewSelect && NewSelect.lastObj && NewSelect.lastObj.close();
    }


    const renderMyReceipt = () => {
        return (<div>
            { myDocs.length > 0 ? <SearchBar
                click={() => {
                    if (search) {
                        setSearchText('');
                        setSearch(false);
                        getMyListRequestDocuments()


                    } else {
                        setSearch(true);
                    }
                }}
                sortModule={true}
                clickSort={() => {
                    setFilterModal(true);
                    setSearchText('');
                    setSearch(false);
                }} /> : null}
            {search ?
                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                        style={{
                            padding: 5,
                            border: ' 1px solid grey',
                            borderRadius: 10
                        }}>
                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                            onKeyUp={event => {

                                if (event.key === 'Enter') {
                                    getMyListRequestDocuments()
                                }
                            }}
                            placeholder={'جستجو'}
                            onChange={(e) => {
                                setSearchText(e.target.value);

                            }} />
                        <div style={{ width: 15, }}>
                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                getMyListRequestDocuments()

                            }} />
                        </div>

                    </div>
                    {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                                   <img src={del} style={{ width: '100%' }} />
                                </div> */}
                </div>
                : null}

            <div className='d-flex align-items-center justify-content-end mt-3 mb-3 ' style={{
                width: '100%',
            }}>
                <div className='d-flex align-items-center mr-2 '>
                    {/* <img style={{ width: 15, height: 15, marginLeft: 10 }}
                                    src={BaseUrlImage + '/transaction.png'} alt={""} /> */}
                    <span style={{ color: colors.app7, fontSize: 12 }}>نمایش آرشیو</span>
                </div>
                {/* <div style={{width:50}}> */}
                <Switch
                    width={45}
                    height={23}
                    onChange={() => { setWithArchive(!withArchive) }
                    }
                    checked={withArchive}
                    className="react-switch"
                />
                {/* </div> */}
            </div>
            {myDocs.length > 0 ?
                <div>

                    {myDocs.map((item, index) => {
                        return <PayableCard key={index} onClick={() => {
                            props.history.push({
                                pathname: `/receiptDetail/${item.id}`,
                                // state: {
                                //     DocId: item.id
                                // }
                            })
                        }}
                            payable={item.pBtnPayment}
                            subject={item.subject}
                            date={item.dateCreated}
                            amount={item.amount}
                            usersRole={item.usersRole}
                            payableAmount={item.amountPayable}
                            click={(e) => {
                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: item.payData,
                                        callBackUrl: window.location.href
                                    }
                                });
                                e.stopPropagation();

                            }}
                            state={item.payStatus} />


                    })}
                </div>
                :
                <EmptyList des={'تاکنون فیشی ثبت نشده‌است'} />
            }

            {/* <PayableCard payable={false} state={3} title={'شارژ مهر'} /> */}
        </div>)
    }
    const renderAllReceipt = () => {
        return (<div>
            { allDocs.length > 0 ?
                <SearchBar
                    click={() => {
                        if (search) {
                            setSearchText('');
                            setSearch(false);
                            getAllListRequestDocuments()


                        } else {
                            setSearch(true);
                        }
                    }}
                    sortModule={true}
                    clickSort={() => {
                        setFilterModal(true);
                        setSearchText('');
                        setSearch(false);
                    }} /> : null}
            {search ?
                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                        style={{
                            padding: 5,
                            border: ' 1px solid grey',
                            borderRadius: 10
                        }}>
                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                            onKeyUp={event => {

                                if (event.key === 'Enter') {
                                    getAllListRequestDocuments();

                                }
                            }}
                            placeholder={'جستجو'}
                            onChange={(e) => {
                                setSearchText(e.target.value);

                            }} />
                        <div style={{ width: 15, }}>
                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                getAllListRequestDocuments()

                            }} />
                        </div>

                    </div>
                    {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                </div>
                : null}


            <div>
                <div className='d-flex align-items-center justify-content-end mt-3 mb-3 ' style={{
                    width: '100%',
                }}>
                    <div className='d-flex align-items-center mr-2 '>
                        {/* <img style={{ width: 15, height: 15, marginLeft: 10 }}
                                    src={BaseUrlImage + '/transaction.png'} alt={""} /> */}
                        <span style={{ color: colors.app7, fontSize: 12 }}>نمایش آرشیو</span>
                    </div>
                    {/* <div style={{width:50}}> */}
                    <Switch
                        width={45}
                        height={23}
                        onChange={() => { setWithArchive(!withArchive) }
                        }
                        checked={withArchive}
                        className="react-switch"
                    />
                    {/* </div> */}
                </div>
                {allDocs.length > 0 ?
                    (allDocs.map((item, index) => {
                        return <PayableCard key={index} onClick={() => {
                            props.history.push({
                                pathname: `/receiptDetail/${item.id}`,
                                // state: {
                                //     DocId: item.id
                                // }
                            })

                        }}
                            payable={null}
                            stateOfRec={item.payStatus}
                            subject={item.subject}
                            date={item.dateCreated}
                            amount={item.amount}
                            usersRole={item.usersRole}
                            payableAmount={item.amountPayable}
                            click={(e) => {

                                props.history.push({
                                    pathname: '/pay',
                                    state: {
                                        payData: item.payData,
                                        callBackUrl: window.location.href
                                    }
                                });
                                e.stopPropagation();

                            }}
                            state={null} />
                    })) : <EmptyList des={' لیست فیش‌ها خالی است'} />}
            </div>





        </div>)
    }


    const tabItems = [
        {
            id: 1,
            title: 'فیش‌های من',
            content: renderMyReceipt()
        },
        {
            id: 2,
            title: 'همه فیش‌ها',
            content: renderAllReceipt()
        },

    ]
    const def =
    {
        id: '0',
        text: 'ساختمان خود را انتخاب کنید'
    };
    return (
        <Layout loaded={true} title={' مدیریت فیش‌ها'} isHome={false} showMenu={true} loading={loading} displayMenu={!loading}
        customBack={true}
            path={'/'}  >
            <Toast
                text={errText}
                type={errType}
            />

            <FilterModal2 show={filterModal} amount={true} sortInc={true} click={() => {
                setFilterModal(false)
            }}
                submit={(filterData) => {
                    console.log(filterData);
                    setFilterData(filterData)
                    setFilterModal(false)
                }} />

            <div style={{ height: 'inherit' }}>
                {buildingList.length > 1 ?
                    <div className={`mt-2 mb-3`}>
                        <NewSelect options={buildingList} plachodler={def} obj={false} sel2={false} value={selectedBulding} closeOther={() => { closeSelect() }}>

                            {buildingList.map(item => {
                                // return (
                                //     <div style={{ padding: '2%' }} key={item.id} value={item.id}>{item.name}</div>
                                // )
                                return (
                                    <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                        buildingList.find(elem => {
                                            if (elem.id == item.id) {
                                                setSelectedBulding(elem);
                                                localStorage.setItem('id', elem.id);
                                                if (activeTab == 1)
                                                    getMyListRequestDocuments()
                                                else
                                                    getAllListRequestDocuments()
                                            }
                                        })
                                        // console.log(item);
                                    }}>{item.name}</div>
                                )
                            })
                            }


                        </NewSelect>
                    </div>
                    :
                    buildingList.length == 1 ?
                        <div className={`w-100 text-right pt-1 pb-1 pr-4 mt-2 mb-2 single`}>
                            {buildingList[0].name}
                        </div>
                        : null
                }
                <div className={`d-flex justify-content-between align-items-center flex-row-reverse w-100 mt-2 `}>
                    {permissions && permissions.pBtnInsert ? <AddBotton title={' ثبت فیش  '} click={() => {
                        props.history.push({
                            pathname: '/addReceipt',
                            state: {
                                buildingId: selectedBulding.id
                            }
                        })
                    }} /> : null} {permissions && permissions.pBtnSetting ?
                        <div className={`mr-3 ml-3`} style={{ width: 40 }}>
                            <img src={settings} alt={''} style={{ width: '100%' }} onClick={() => {
                                props.history.push('receipt/receiptSetting')

                            }} />
                        </div> : null}


                </div>
                <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`} onClick={props.click}>

                    {tabItems.map(item => {
                        return (<TabBar title={item.title} key={item.id} id={item.id} activeIndex={activeTab} click={() => {
                            if (item.id == 2) {
                                getAllListRequestDocuments()
                            } else {
                                getMyListRequestDocuments()
                            }
                            setActiveTab(item.id);
                            // setRenderItem(item.content);
                        }} />)
                    })}

                </div>
                <div className={`w-100 bb`} style={{ height: `calc(100% - 12rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>{renderItem}</div>
            </div>
        </Layout >
    );
};

export default withRouter(Receipt);
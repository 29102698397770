import React, { useEffect, useState } from 'react';
import classes from "./MenuDrawer.module.css"
import NavigationItems from './NavigationItems/NavigationItems';
import exit from '../../Assets/myAsset/exit.svg';
import back from '../../Assets/myAsset/arrow.svg';
import { formatMoney, colors, getProfile, MyBuildingList } from '../../value';
import building from '../../Assets/myAsset/mortgage.svg';
import wallet from '../../Assets/myAsset/wallet.svg';
import mali from '../../Assets/myAsset/money.svg';
import helpI from '../../Assets/myAsset/problem-solving.svg';
import newsI from '../../Assets/myAsset/newspaper.svg';



import service from '../../Assets/myAsset/customer-service.svg';
import img from '../../Assets/myAsset/user.png'
import { withRouter } from 'react-router-dom';
import Axios from 'axios';

import { DeviceUUID } from 'device-uuid'
import Modal from '../Modal/Modal';
import SBotton from '../Buttons/SBotton/SBotton';


const mainPage = [
    {
        id: 1,
        lable: 'صفحه اصلی',
        link: '/'
    }
];
const wallett = [
    {
        id: 1,
        lable: 'کیف‌پول',
        link: '/wallet'
    }
];

const news = [
    {
        id: 1,
        lable: 'اخبار',
        link: '/news'
    }
];

// const services = [
//     {
//         id: 1,
//         lable: 'خدمات',
//         link: '/'
//     }
// ];
const buildingItems = [
    {
        id: 1,
        lable: 'ساختمان من',
        link: '/building'
    },
    {
        id: 2,
        lable: 'قوانین ساختمان',
        link: '/rules'
    },
    {
        id: 3,
        lable: 'سوابق ساختمان',
        link: '/history'
    },
    // {
    //     id: 3,
    //     lable: 'اخبار',
    //     link: '/news'
    // },
    // {
    //     id: 4,
    //     lable: 'نظرسنجی',
    //     link:'/news'
    // },
    // {
    //     id: 5,
    //     lable: 'تقویم',
    //     link:'/news'
    // },
];


const financial = [
    {
        id: 1,
        lable: ' صندوق',
        link: '/fund'
    },
    {
        id: 2,
        lable: 'فیش‌ها',
        link: '/receipt'
    },
    {
        id: 3,
        lable: ' قبوض',
        link: '/bills'
    },
      {
            id: 4,
            lable: 'لیست بدهکاران و بستانکاران',
            link: '/debplist'
        },

    // {
    //     id: 4,
    //     lable: ' بدهکاران',
    //     link: '/debtors'
    // },
    // {
    //     id: 5,
    //     lable: ' بستانکارن',
    //     link: '/deposits'
    // },
   

];


const help = [
    {
        id: 1,
        lable: ' سوالات متداول',
        link: '/question'
    },
    {
        id: 2,
        lable: 'درباره ما',
        link: '/aboutus'
    },
    {
        id: 3,
        lable: 'پشتیبانی',
        link: '/chats/11111111-1111-1111-1111-111111111111'
    },
    {
        id: 4,
        lable: 'راهنما',
        link: '/guid'
    },
    // {
    //     id: 4,
    //     lable: 'لینک هوم‌تیک',
    //     link: '/'
    // },
    // {
    //     id: 5,
    //     lable: 'تقویم',
    //     link: '/'
    // },
];


const services = [
    {
        id: 1,
        lable: 'ثبت سفارش',
        link: '/service/address'
    },
    {
        id: 2,
        lable: 'تاریخچه سفارشات',
        link: '/service/orderHistory'
    },
    {
        id: 3,
        lable: 'مدیریت سفارشات',
        link: '/service/provider'
    },
];

const MenuDrawer = props => {
    const [userData, setUserData] = useState(null);
    const [H, setH] = useState(0);
    const [buildingList, setBuildingList] = useState([]);

    const [open1, setOpen1] = useState(true);
    const [open2, setOpen2] = useState(true);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);



    const [height1, setHeight1] = useState('0px');
    const [height2, setHeight2] = useState('0px');
    const [height3, setHeight3] = useState('0px');
    const [height4, setHeight4] = useState('0px');



    const [showModal, setShowModal] = useState(false);









    useEffect(() => {
        let forc = localStorage.getItem('profile') ? false : true;
        getProfile(forc).then(response => {
            setUserData(response);
            MyBuildingList(true).then(res => {
                let list = res;
                if (list) {
                  setBuildingList(list);
                }
              })
                .catch(err => {
                    setBuildingList([])
                 
                })
        });

        if (document.getElementById('header')) {
            const Hheight = document.getElementById('header').clientHeight;
            // //const Hheight = 500;
            // const WBase = document.getElementById('baseBx').clientWidth;
            setH(Hheight);
            // setW(WBase);
        }


        if (open1) { setHeight1('500px'); attachedClasses = [classes.collapsible]; } else { setHeight1('0px'); }
        if (open2) { setHeight2('500px'); attachedClasses = [classes.collapsible]; } else { setHeight2('0px'); }
        if (open3) { setHeight3('500px'); attachedClasses = [classes.collapsible]; } else { setHeight3('0px'); }
        if (open4) { setHeight4('500px'); attachedClasses = [classes.collapsible]; } else { setHeight4('0px'); }
        return function cleanup() {}
    }, [open1, open2, open3 , open4]);



    let attachedClasses = [classes.SideDrawer, classes.close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.open];
    }


    const logOut = () => {
        let data =
        {
            RegisterId: JSON.parse(localStorage.getItem('profile')).id,
            DeviceId: new DeviceUUID().get(),
            ClientType: 2
        }
        Axios.post(`https://push.homtick.com/api/push/UnRegisterUser/?token=7pFMlAcsoRHTLoHKIsyf7tsMNMNaCUqHMwveTZktMZhsd77dT1JLEXZvtRGbVmMbdsxf0q/jJCd/77/642G2km6A==`, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
        localStorage.clear();
        props.history.push('/login');
        window.location.reload();
        })
   
    }

    return (userData!= null && buildingList!= null ?
        <div className={attachedClasses.join(' ')} style={{ width: props.Width, display: props.display ? 'block' : 'none' }} >
            <Modal show={showModal} btn={true} heightTop={'40vh'} click={()=>{
                setShowModal(false)
            }} >
                <div className={`d-flex justify-content-between`}>
                <div style={{width:'45%'}}>
                       <SBotton  title={`حذف حساب کاربری`} click={() => {
                        logOut()
                    }}/>
                   </div>
                    <div style={{width:'45%'}}>
                         <SBotton gradian title={`خروج`} click={() => {
                        window.location.href = 'https://www.google.com'; 
                        // props.history.push('google.com')
                       // window.open('google.com');
                    }}/>
                    </div>
                   
                     
                    
                </div>

            </Modal>
            <div id={'header'} className={`p-2`}
                style={{ backgroundColor: colors.app1, borderBottomLeftRadius: 35, borderBottomRightRadius: 35 }}>
                <div className={`mr-2`} style={{ width: '20px' }} onClick={props.closed}>
                    <img className={`w-100 bold h-100`} src={back} alt={'بازگشت'} />
                </div>
                <div className={` d-flex flex-row-reverse align-items-center justify-content-start p-2 text-light`} onClick={() => {
                    props.history.push('/profile');
                }}>
                    {userData.profileImage ?
                        <div style={{
                            width: 80, height: 80,
                            backgroundImage: "url(" + userData.profileImage.path + ")",
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        }}></div> :
                        <div style={{
                            width: 60, height: 60,
                            backgroundImage: "url(" + img + ")",
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat', borderRadius: '50%'
                        }}>
                        </div>}
                    <div className={` text-right rtl mr-2`}>
                        <div> {userData.name} {userData.lastName}  </div>
                        <div>{userData.mobileNumber}</div>
                    </div>
                </div>
                <div className={`d-flex flex-row-reverse align-items-center justify-content-between m-2 p-2 pr-3 pl-3 text-light`} style={{ border: '2px solid white', borderRadius: 15, backgroundColor: 'transparent' }}>
                    <div>موجودی کیف پول من</div>
                    <div>{formatMoney(userData.cashDesk.balance)}</div>
                </div>

            </div>
            <div className={`p-2`} style={{ height: `calc(100% - ${32 + 'vh'})`, overflow: 'auto' }}>

                {/* ******************************************************************** */}
                <div className={`text-right mb-2`} >
                    <div className={`d-flex flex-row-reverse align-items-center justify-content-start `}>
                        <div className={`ml-2`} style={{ width: 23 }}>
                            <img className={` w-100`} onClick={props.closed} src={building} alt={''} />
                        </div>

                        <nav>
                            <NavigationItems items={mainPage} single={true} />
                        </nav>
                    </div>

                </div>


                {/**************************************************************************** */}
               {buildingList.length!=0 ?  
               <div className={`text-right mb-2`} >
                    <div className={`d-flex flex-row-reverse align-items-center justify-content-start `} onClick={() => {
                        console.log('buildingList',buildingList);
                        setOpen1(!open1)
                    }}>
                        <div className={`ml-2`} style={{ width: 23 }}>
                            <img className={`w-100`} src={building} alt={''} />
                        </div>
                        <div className={`fntBold  `} style={{fontSize:15}}>ساختمان</div>
                    </div>
                    <div className={`mr-4`} onClick={props.closed} style={{ maxHeight: height1, transition: 'max-height 0.2s ease-out', overflow: "hidden" }} >
                        <nav>
                            <NavigationItems items={buildingItems} />
                        </nav>
                    </div>
                </div>
                : null}
                {/* ******************************************************************** */}
                {/* ******************************************************************** */}
                <div className={`text-right mb-2`} >
                    <div className={`d-flex flex-row-reverse align-items-center justify-content-start `}>
                        <div className={`ml-2`} style={{ width: 23 }}>
                            <img className={` w-100`} onClick={props.closed} src={newsI} alt={''} />
                        </div>

                        <nav>
                            <NavigationItems items={news} single={true} />
                        </nav>
                    </div>

                </div>
                                {/* ******************************************************************** */}

                                {buildingList.length!=0 ? <div className={`text-right mb-2`} >
                    <div className={`d-flex flex-row-reverse align-items-center justify-content-start `} onClick={() => {
                        setOpen2(!open2)
                    }}>
                        <div className={`ml-2`} style={{ width: 23 }}>
                            <img className={` w-100`} src={mali} alt={''} />
                        </div>

                        <div className={`fntBold `} style={{fontSize:15}}>مالی</div>
                    </div>
                    <div className={`mr-4`} onClick={props.closed} style={{ maxHeight: height2, transition: 'max-height 0.2s ease-out', overflow: "hidden" }} >
                        <nav>
                            <NavigationItems items={financial} />
                        </nav>
                    </div>
                </div> : null}
                
                {/* **********************************خدمات********************************** */}
                <div className={`text-right mb-2`} >
                    <div className={`d-flex flex-row-reverse align-items-center justify-content-start `} onClick={() => {
                        setOpen4(!open4)
                    }}>
                        <div className={`ml-2`} style={{ width: 21 , marginLeft : 2}}>
                            <img className={` w-100`} src={service} alt={''} />
                        </div>

                        <div className={`fntBold `} style={{fontSize:15}}>رزرواسیون</div>
                    </div>
                    <div className={`mr-4`} onClick={props.closed} style={{ maxHeight: height4, transition: 'max-height 0.2s ease-out', overflow: "hidden" }} >
                        <nav>
                            <NavigationItems items={userData.pUserIsAProvider?services : services.slice(0,2)} />
                        </nav>
                    </div>
                </div>
                {/* ******************************************************************** */}
                <div className={`text-right mb-2`} >
                    <div className={`d-flex flex-row-reverse align-items-center justify-content-start `}>
                        <div className={`ml-2`} style={{ width: 23 }}>
                            <img className={`w-100`} onClick={props.closed} src={wallet} alt={''} />
                        </div>
                        <nav>
                            <NavigationItems items={wallett} single={true} />
                        </nav>
                    </div>

                </div>

                {/* ******************************************************************** */}
                <div className={`text-right mb-2`} >
                    <div className={`d-flex flex-row-reverse align-items-center justify-content-start `} onClick={() => {
                        setOpen3(!open3)
                    }}>
                        <div className={`ml-2 `} style={{ width: 23 }}>
                            <img className={`w-100`} src={helpI} alt={''} />
                        </div>

                        <div className={`fntBold `} style={{fontSize:15}}>کمک به من</div>
                    </div>
                    <div className={`mr-4`} onClick={props.closed} style={{ maxHeight: height3, transition: 'max-height 0.2s ease-out', overflow: "hidden" }} >
                        <nav>
                            <NavigationItems items={help} />
                        </nav>
                    </div>
                </div>



                <div className={`position-absolute`} style={{ bottom: '15px' }} >
                    <div onClick={() => {
                        setShowModal(true)
                       // logOut()
                         }} className={` d-flex flex-row-reverse align-items-center justify-content-around m-2`}>
                        <div>خروج</div>
                        <div className={`mr-2`} style={{ width: '20px' }}>
                            <img className={`w-100 bold `} src={exit} alt={'خروج'} />
                        </div>
                    </div>
                </div>

            </div>



        </div>
        : null

    )
}
export default withRouter(MenuDrawer);
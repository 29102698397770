import React from "react"
import { useParams } from "react-router-dom"
import { serviceAxios } from "../../axios"
import AddClient from "../../Components/AddClient/AddClient"
import AddBotton from "../../Components/Buttons/AddBotton/AddBotton"
import SBotton from "../../Components/Buttons/SBotton/SBotton"
import ClientCard from "../../Components/ClientCard/ClientCard"
import Layout from "../../Components/Layout/Layout"
import Modal from "../../Components/Modal/Modal"
import Toast from "../../Components/Toast/Toast"
import TwoOptionModal from "../../Components/TwoOptionModal/TwoOptionModal"
import { useProviderClientContext } from "../../context/providerClients"
import useError from "../../hooks/useError"


const ServiceProviderClients = ({})=>{
    const [loading , setLoading] = React.useState(true)
    const [showModal , setShowModal] = React.useState(false)
    const [showDialog , setShowDialog] = React.useState(false)
    const [clients , setClients] = useProviderClientContext()
    const { serviceId } = useParams()
    let deleteUserId = React.useRef(null)
    const {error , setError} = useError();

    React.useEffect(()=>{
        getClients()
    },[])

    const getClients = ()=>{
        setLoading(true)
        setShowModal(false)
        serviceAxios({
            method : "post",
            url : `/ProviderUser/List/${serviceId}`
        }).then(res=>{
            setLoading(false)
            setClients(res.data.data?res.data.data : [])
        })
    }

    const deletePressed = (userId)=>{
        setShowDialog(true)
        deleteUserId.current = userId
    }

    const deleteClient = ()=>{
        setShowDialog(false)
        setLoading(true)
        serviceAxios({
            method : "post",
            url : `/ProviderUser/Delete/${serviceId}/${deleteUserId.current}`
        }).then(res=>{
            getClients()
            deleteUserId.current = null
        })
    }

    const addClientPressed = ()=>{
        setShowModal(true)
    }
    
    return(
        <Layout
            title={"کارمندان"}
            loaded={true}
            loading={loading}
            showMenu={true}
            displayMenu={true}
        >
            <AddBotton
                title="اضافه کردن کارمند"
                click={addClientPressed}
            />
            {
                !loading?
                    clients.length === 0?
                    <p className="mt-5 text-center">
                        کارمندی ندارید
                    </p>:
                    clients.map(item=>(
                        <ClientCard
                            {...item}
                            deletePressed={deletePressed}
                        />
                    )):
                null
            }
            {
                showModal &&
                <Modal 
                    show={showModal} 
                    btn={true} 
                    click={() => {setShowModal(false) }}
                >
                    <AddClient
                        getClients={getClients}
                        showToast={(message)=>{setError(message , "war")}}
                    />
                </Modal>
            }
            {
                showDialog &&
                <TwoOptionModal
                    title={"از حذف این کاربر مطمئن هستید؟"}
                    showModal={true}
                    setShowModal={setShowDialog}
                    callback1={deleteClient}
                    callback2={()=>setShowDialog(false)}
                />
            }
            <Toast
                text={error.errTxt}
                type={error.errType}
            />
        </Layout>
    )
}

export default ServiceProviderClients

import classes from './FilterModal.module.css';
import React from 'react';
import Modal from '../Modal';
import SBotton from '../../Buttons/SBotton/SBotton';
import { Component } from 'react';
import { Checkbox } from '../../CheckBox/CheckBox';
import Collapsable from '../../Collapsable/Collapsable';
let Self = null;
class FilterModal extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            showtype: 'resident',
             showtype1: 'personsCount',
          

        }
        // this.showtype = 'personsCount';
        // this.showtype1= 'resident';

        this.filterData = {
            unitNumber: {},
            personsCount: {},
            parkingCount: {},
            bedehkari: {},
            bestankari: {},
            floor: {},
            area: {},
            sortByType: 1,
            sortIsDesc: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state > prevState) {
            this.props.submit(this.filterData, this.state.showtype, this.state.showtype1);
        }
    }
    componentWillReceiveProps(props) {
        console.log('>>>>>>>>>>>>>>>>', props);
    }

    async setS(text) {
        console.log('xzxzxz', text);
        await this.setState({
            showtype1: text
        }, console.log(this.state.showtype1))
    }

    collapseHeader() {
        return (
            <div>
                <div className={`fntBold mt-2 mb-2`} style={{ direction: 'rtl', textAlign: 'right', fontSize: 12 }} >نمایش براساس:</div>
            </div>
        )
    }

    collapseHeader2() {
        return (
            <div className={`d-flex justify-content-between align-items-center `}>
                <div>
                    <Checkbox Type={'Radio'} Label={'نام ساکن'} checked={this.state.showtype == 'resident'} onChange={(check) => {
                        check && this.setState({ showtype: 'resident' })
                    }} Name={'ownerRe'} textStyle={{ fontSize: '10px' }} />
                </div>
                <div >
                    <Checkbox Type={'Radio'} Label={'نام مالک'}
                        onChange={(check) => {
                            check && this.setState({ showtype: 'owner' })
                        }}
                        Name={'ownerRe'} textStyle={{ fontSize: '10px' }} />
                </div>
            </div>
        )
    }
    collapsContent() {
        return (<div className={`d-flex justify-content-between align-items-start pt-3 `} style={{ borderTop: '1px solid grey' }} >

            <div className={`d-flex flex-column align-items-end`}>
                {!this.props.permissions || this.props.permissions.pFilterBedehkar ?
                    <Checkbox Type={'Radio'} Label={' بدهکاری مالک '} textStyle={{ fontSize: '10px' }} Name={'showType'}
                        onChange={checked => {
                            if (checked)
                                this.setState({
                                    showtype1: 'ownerBedehkari'
                                })
                        }}
                    /> : null}{!this.props.permissions || this.props.permissions.pFilterBestankar ?
                        <Checkbox Type={'Radio'} Label={' بستانکاری مالک '} textStyle={{ fontSize: '10px' }} Name={'showType'}
                            onChange={checked => {
                                if (checked)
                                    this.setState({
                                        showtype1: 'ownerBestankari'
                                    })
                            }}
                        /> : null}

                <Checkbox Type={'Radio'} Label={'پارکینگ'} textStyle={{ fontSize: '10px' }} Name={'showType'}
                          onChange={checked => {
                              if (checked)
                                  this.setState({
                                      showtype1: 'parkingCount'
                                  })
                          }} />
                <Checkbox Type={'Radio'} Label={'کدپستی'} textStyle={{ fontSize: '10px' }} Name={'showType'}
                          onChange={checked => {
                              if (checked)
                                  this.setState({
                                      showtype1: 'postalCode'
                                  })
                          }} />



            </div>
            <div className={`d-flex flex-column align-items-end`}>
                {!this.props.permissions || this.props.permissions.pFilterBedehkar ? <Checkbox Type={'Radio'} Label={' بدهکاری ساکن'} textStyle={{ fontSize: '10px' }}
                    Name={'showType'}
                    onChange={checked => {
                        if (checked)
                            this.setState({
                                showtype1: 'residentBedehkari'
                            })
                    }} /> : null}

                {!this.props.permissions || this.props.permissions.pFilterBestankar ?
                    <Checkbox Type={'Radio'} Label={' بستانکاری ساکن '} textStyle={{ fontSize: '10px' }} Name={'showType'}
                        onChange={checked => {
                            if (checked)
                                this.setState({
                                    showtype1: 'residentBestankari'
                                })
                        }} /> : null}

                <Checkbox Type={'Radio'} Label={' متراژ'} textStyle={{ fontSize: '10px' }} Name={'showType'} onChange={checked => {
                    this.setState({
                        showtype1: 'area'
                    })
                }} />
                <Checkbox Type={'Radio'} Label={'نفرات'} checked={this.state.showtype1 == 'personsCount'} textStyle={{ fontSize: '10px' }} Name={'showType'}
                    onChange={checked => {
                        if (checked)
                            this.setState({
                                showtype1: 'personsCount'
                            })
                    }} />

            </div>



        </div>)
    }


    collapseHeaderOrder() {
        return (
            <div>
                <div className={`fntBold mt-2 mb-2`} style={{ direction: 'rtl', textAlign: 'right', fontSize: 12 }} >مرتب‌سازی براساس:</div>
            </div>
        )
    }
    collapseHeaderOrder2() {
        return (
            <div className={`d-flex justify-content-between align-items-center `}>
                <div>
                    <Checkbox Type={'Radio'} Label={'بیشترین '} textStyle={{ fontSize: '10px' }} onChange={checked => {
                        if (checked) { this.filterData.sortIsDesc = true }
                    }} Name={'dec'} />
                </div>
                <div >
                    <Checkbox Type={'Radio'} Label={'کمترین '} textStyle={{ fontSize: '10px' }} checked={!this.filterData.sortIsDesc} Name={'dec'} onChange={checked => {
                        if (checked) { this.filterData.sortIsDesc = false }
                    }} />
                </div>
            </div>
        )
    }
    collapsContentOrder() {
        return (
            <div className={`d-flex justify-content-between align-items-start shadow pt-3 `} style={{ borderTop: '1px solid grey' }} >
                <div className={`d-flex flex-column align-items-end`}>
                    {!this.props.permissions || this.props.permissions.pFilterBedehkar ? <Checkbox Type={'Radio'} Label={' بدهکاری'} textStyle={{ fontSize: '10px' }} Name={'order'}
                        onChange={checked => { if (checked) { this.filterData.sortByType = 3 } }} /> : null}
                    <Checkbox Type={'Radio'} Label={'واحد'} textStyle={{ fontSize: '10px' }} Name={'order'} checked={this.filterData.sortByType == 1}
                        onChange={checked => { if (checked) { this.filterData.sortByType = 1 } }} />
                    <Checkbox Type={'Radio'} Label={'متراژ'} textStyle={{ fontSize: '10px' }} Name={'order'}
                        onChange={checked => { if (checked) { this.filterData.sortByType = 5 } }} />
                </div>
                <div className={`d-flex flex-column align-items-end`}>
                    {!this.props.permissions || this.props.permissions.pFilterBestankar ? <Checkbox Type={'Radio'} Label={' بستانکاری'} textStyle={{ fontSize: '10px' }} Name={'order'}
                        onChange={checked => { if (checked) { this.filterData.sortByType = 4 } }} /> : null}

                    <Checkbox Type={'Radio'} Label={'نفرات'} textStyle={{ fontSize: '10px' }} Name={'order'}
                        onChange={checked => { if (checked) { this.filterData.sortByType = 2 } }} />
                    <Checkbox Type={'Radio'} Label={'پارکینگ'} textStyle={{ fontSize: '10px' }} Name={'order'}
                        onChange={checked => { if (checked) { this.filterData.sortByType = 6 } }} />
                </div>
            </div>)
    }

    render() {
        return (
            <Modal show={this.props.show} btn={true} click={this.props.click} >
                <div style={{ textAlign: 'center', height: '65vh', overflow: 'auto' }}>
                    <div style={{ height: '90%', overflow: 'auto', position: 'relative' }}>

                        <div style={{ border: '1px solid #e7e7e7', borderRadius: 10, paddingLeft: 10, paddingRight: 10, margin: 10 }} >
                            <Collapsable header={this.collapseHeader()} header2={this.collapseHeader2()} content={this.collapsContent()} />
                        </div>

                        <div style={{ border: '1px solid #e7e7e7', borderRadius: 10, paddingLeft: 10, paddingRight: 10, margin: 10 }} >
                            <Collapsable header={this.collapseHeaderOrder()} header2={this.collapseHeaderOrder2()} content={this.collapsContentOrder()} />
                        </div>

                        <div>
                            {!this.props.notfloor ? <div className={`d-flex justify-content-center flex-row-reverse w-100 align-items-center`}>
                                <div style={{ width: 80, paddingLeft: 10, fontSize: 10, textAlign: 'right' }}>طبقه</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {

                                    this.filterData.floor.start = parseInt(e.target.value);

                                }}
                                    type={'number'} />
                                <div className={`text-center`} style={{ width: 10, margin: 10 }}>تا</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.floor.end = parseInt(e.target.value);
                                }}

                                    type={'number'} />
                            </div> : null}

                            {!this.props.unitNumber ? <div className={`d-flex justify-content-center flex-row-reverse w-100 align-items-center`}>
                                <div style={{ width: 80, paddingLeft: 10, fontSize: 10, textAlign: 'right' }}>شماره واحد</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.unitNumber.start = parseInt(e.target.value);

                                }}
                                    type={'number'} />
                                <div className={`text-center`} style={{ width: 10, margin: 10 }}>تا</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.unitNumber.end = parseInt(e.target.value);
                                }}

                                    type={'number'} />
                            </div> : null}

                            {!this.props.personsCount ? <div className={`d-flex justify-content-center flex-row-reverse w-100 align-items-center`}>
                                <div style={{ width: 80, paddingLeft: 10, fontSize: 10, textAlign: 'right' }}>تعداد نفرات</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.personsCount.start = parseInt(e.target.value);

                                }}
                                    type={'number'} />
                                <div className={`text-center`} style={{ width: 10, margin: 10 }}>تا</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.personsCount.end = parseInt(e.target.value);
                                }}

                                    type={'number'} />
                            </div> : null}

                            {!this.props.parkingCount ? <div className={`d-flex justify-content-center flex-row-reverse w-100 align-items-center`}>
                                <div style={{ width: 80, paddingLeft: 10, fontSize: 10, textAlign: 'right' }}>تعداد پارکینگ</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.parkingCount.start = parseInt(e.target.value);

                                }}
                                    type={'number'} />
                                <div className={`text-center`} style={{ width: 10, margin: 10 }}>تا</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.parkingCount.end = parseInt(e.target.value);
                                }}

                                    type={'number'} />
                            </div> : null}

                            {!this.props.bedehkari ? <div className={`d-flex justify-content-center flex-row-reverse w-100 align-items-center`}>
                                <div style={{ width: 80, paddingLeft: 10, fontSize: 10, textAlign: 'right' }}>بدهکاری</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.bedehkari.start = parseInt(e.target.value);

                                }}
                                    type={'number'} />
                                <div className={`text-center`} style={{ width: 10, margin: 10 }}>تا</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.bedehkari.end = parseInt(e.target.value);
                                }}

                                    type={'number'} />
                            </div> : null}



                            {!this.props.bestankari ? <div className={`d-flex justify-content-center flex-row-reverse w-100 align-items-center`}>
                                <div style={{ width: 80, paddingLeft: 10, fontSize: 10, textAlign: 'right' }}>بستانکاری</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.bestankari.start = parseInt(e.target.value);

                                }}
                                    type={'number'} />
                                <div className={`text-center`} style={{ width: 10, margin: 10 }}>تا</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.bestankari.end = parseInt(e.target.value);
                                }}

                                    type={'number'} />
                            </div> : null}



                            {!this.props.area ? <div className={`d-flex justify-content-center flex-row-reverse w-100 align-items-center`}>
                                <div style={{ width: 80, paddingLeft: 10, fontSize: 10, textAlign: 'right' }}>متراژ</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.area.start = parseInt(e.target.value);

                                }}
                                    type={'number'} />
                                <div className={`text-center`} style={{ width: 10, margin: 10 }}>تا</div>
                                <input className={`${classes.customInput}`} onChange={(e) => {
                                    this.filterData.area.end = parseInt(e.target.value);
                                }}

                                    type={'number'} />
                            </div> : null}

                        </div>

                    </div>
                    <div style={{ width: '95%', position: 'absolute', backgroundColor: 'white', height: '10%' }}>
                        <div className={`w-50 mx-auto`}>
                            <SBotton title={'فیلتر'} gradian click={() => {

                                this.props.submit(this.filterData, this.state.showtype, this.state.showtype1);



                            }} />

                        </div>
                    </div>



                </div>
            </Modal>
        )
    }

}
export default FilterModal;








import React, { Component } from 'react';
import Layout from '../../../Components/Layout/Layout';
import CpInput from '../../../Components/Input/Input'
import { ApiService, attachFile } from '../../../value';
import { withRouter } from 'react-router-dom';
import Toast from '../../../Components/Toast/Toast';
import loadingGif from '../../../Assets/gifs/22.gif'
import UploadImg from '../../../Components/UploadImg/UploadImg';



let Self = null;
class AddRule extends Component {

    constructor(props) {
        super(props);
        Self = this;


        this.state = {
            loading: false,

            id:this.props.location.state ? this.props.location.state.rule.id : 0,

            errText: '',
            errType:'',

            imgId: null,
            imgPath: (this.props.location.state && this.props.location.state.rule.filePath) ? this.props.location.state.rule.filePath :'',
            uploading: false,

            title: this.props.location.state ? this.props.location.state.rule.title : '',
            description:this.props.location.state ? this.props.location.state.rule.description :'',

            buildingId :null

        }

    }

    componentDidMount(){
        if(localStorage.getItem('id'))
        {this.setState({
            buildingId:localStorage.getItem('id')
        })}else{
            this.props.history.push('/rules');
        }
    }

    uploadFile(files) {
        this.setState({
            uploading: true
        })
        attachFile(files).then(res => {
            this.setState({
                imgId: res[0].id,
                imgPath: res[0].path,
                uploading: false
            })
         
        })
            .catch(err => {

            })

    }


    submitRule(buildingId){
        console.log(this.state.title.length);

        if( this.state.title.length==0 || this.state.description.length==0){
            this.setState({
                errText: 'عنوان قانون و توضیحات لازم را وارد نمایید' ,
                errType :'suc'
               });
            return
        }
        this.setState({
            loading:true
        });
        let data = {
            id: this.state.id,
            title:  this.state.title,
            description: this.state.description,   
           // fileId: this.state.imgId? this.state.imgId : '', 
        }
        if(this.state.imgId){
            data.fileId = this.state.imgId
        }
        console.log('dataaaaa', data);
        ApiService('Post', `/BuildingRule/Submit/${buildingId}`, data)
        .then(response =>{
            console.log(response);
            if(response.data.result){
                this.setState({
                    loading: false,
                    errText: ' با موفقیت انجام شد.' ,
                    errType :'suc'
                   });
                   setTimeout(()=>{
                    this.props.history.push({
                        pathname: '/rules/View',
                        state: {
                            id: response.data.data.id
                        }
                    })
                   },2000)
            }else{
                this.setState({
                    loading: false,
                    errText: response.data.message ,
                    errType :'war'
                });
            }
        })
        .catch(err => {
            this.setState({
                errText: 'خطای داخلی سرور',
                errType:'err'  ,
                loading: false      
            });
            this.clearErr();
            return

        })
    }


   
    render() {
        return (
           
            <Layout loaded={true} title={ this.props.location.state && this.props.location.state.rule ? 'ویرایش قانون' : 'ثبت قانون ' } 
            isHome={false} btn={true} btnName={'ثبت'} loading={this.state.loading} gradian={true} click={()=>{
                this.submitRule(this.state.buildingId)
            }}>
                 <Toast
                text={this.state.errText}
                type={this.state.errType}
            />
                <div style={{height:'inherit'}}>
                    <div className={'pt-2'} style={{height: `calc(100% - 5rem)`, overflow: 'auto',}}>
                    <CpInput plachodler={'عنوان قانون'} type={"text"}  value={this.state.title} onChange={(event)=>{
                        this.setState({
                            title: event.target.value
                        })
                    }} />
                    <CpInput plachodler={' توضیحات'} type={"text"} style={{whiteSpace: "pre-wrap"}}  value={this.state.description}  multiline={true} onChange={(event)=>{
                        this.setState({
                            description: event.target.value
                        })
                    }} />
                    { !this.state.imgPath  ?
                        <>
                            <div className={`rtl fntBold mediumF text-right mt-2`} > عکس‌ مرتبط را اضافه کنید. </div>

                            {!this.state.uploading ?
                                <UploadImg
                                 onChange={(e) => {
                                    if (e.target.files[0]) {
                                        this.uploadFile(e.target.files[0])
                                    }
                                }}>
                                </UploadImg>
                                :
                                <div className={`w-100 text-center`}><img style={{ width: '10%' ,height:'100%'}} src={loadingGif} /></div>}
                        </>
                        :
                        <div className={`text-right pb-3 rtl d-flex align-items-center`}>
                            <div className={` `} style={{
                                width: 110, height: 80,
                                backgroundImage: "url(" + this.state.imgPath + ")",
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            </div>
                            {/* <img src={this.state.imgPath} style={{ width: 80 }} /> */}
                            <span style={{ color: 'salmon', fontSize: 12, paddingRight: '10%' }} onClick={() => {
                                this.setState({
                                    imgPath: ''
                                });
                            }}> حذف</span>
                        </div>
                    }
                </div>
                </div>
            </Layout>
        )
    }
}
export default withRouter(AddRule) ;
import React from 'react';
import user from '../../../Assets/myAsset/user.png'



const OthersChat = props => {
    return (
        <div className={`d-flex w-100 flex-row-reverse align-items-center rtl`}>
            <div style={{ width: 40, height: 40, backgroundColor: '#e7e7e7', borderRadius: '50%' }}>
                <img src={props.item.user.avatar ? props.item.user.avatar : user} className={`w-100 `} alt={''} />
            </div>
            <div className={`p-1 m-2 `} style={{ maxWidth: '80%',wordWrap:'break-word', borderRadius: 10, backgroundColor: '#FF3F3F', color: '#fff' }}>
                {props.item.user.name ? <div className={`text-right`} style={{ fontSize: 12 }}>{props.item.user.name.replace('آقای', '').replace('خانم', '').trim()} </div> : null}
                {props.item.text.length>0 ? <div className={`text-right`} style={{ fontSize: 14 }}>
                    {props.item.text}
                </div> :
                <div>
                    <div className={` `} onClick={(e) => {
                                    window.open(props.item.image);
                                    e.stopPropagation();
                                }} style={{
                        backgroundColor:'white',
                                width: 100, height: 80,
                                backgroundImage: "url(" + props.item.image+ ")",
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            </div> 
                    
                    </div>}
                <div className={`d-flex  align-items-center mt-1`}>
                    <div style={{ fontSize: 10, color: 'white', marginRight: 5 }}>{props.item.createdAtFa} {props.item.createdTimeFa}</div>
                </div>
            </div>
        </div>
    )
}
export default OthersChat;
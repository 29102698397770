import React from 'react';
import { colors, formatMoney } from "../../../value";
import Card from '../../Card/Card';
import dec from '../../../Assets/myAsset/bleachRed.svg';
import inc from '../../../Assets/myAsset/bleachGrn.svg';
import Date from '../../Date/Date';




const WalletTransCard = props => {
    return (
        <Card width={'100%'} onClick={props.click} >
            <div className={`w-100 p-2`}>
                <div className={`fntBold mediumF text-right mb-2`}>{props.itemInfo.subject}</div>
                <div className={`d-flex flex-row-reverse justify-content-between grey align-items-center`} >
                    <div><Date date={props.itemInfo.dateCreated} font={12} /></div>
                    <div className={`d-flex flex-row-reverse justify-content-between grey  align-items-center pl-3 pr-3 pt-1 pb-2`}
                        style={{ backgroundColor: colors.app9, borderRadius: 20 }} >
                            <div style={{ width: '12px',}}>
                            <img style={{ width: '100%', marginLeft: 5,  }} src={props.itemInfo.priceType == 0 ? inc : dec} alt={'alt'} />

                            </div>
                       
                        <div style={{fontSize:12}}>{formatMoney(props.itemInfo.amount)}</div>
                        <div style={{ fontSize: 11, opacity: 0.6, marginRight:5 }}> ریال</div>
                    </div>
                </div>

            </div>



        </Card>
    );
};

export default WalletTransCard;
import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Addresses from "./Addresses";
import AddAddress from "./AddAddress";
import ServicesGroup from "./ServicesGroup";
import ServicesList from "./ServiceList";
import { AddressProvider } from "../../context/addressContext";
import { OrderProvider } from "../../context/orderContext";
import ServiceProvidersList from "./ServiceProvidersList";
import ServicePeriod from "./ServicePeriod";
import OrderConfirm from "./OrderConfirm";
import PayService from "./PayService";
import ServiceCallback from "./ServiceCallback";
import OrdersHistory from "./OrdersHistory";
import OrderDetails from "./OrderDetails";
import ProviderOrderDetails from "./ProviderOrderDetails";
import { ProviderClientContextProvider } from "../../context/providerClients";
import ServiceProviderClients from "./ServiceProviderClients";
import { ServiceContextProvider } from "../../context/serviceContext";
import ServiceProviderServiceInfo from "./ServiceProviderServiceInfo";
import SeviceProviderInfo from "./SeviceProviderInfo";
import Period from "./Period";


const ServiceStepsWrapper = ()=>{
    return(
        <Switch>
            {/********  Provider Routes **********/}
            <Route 
                path="/service/provider/clients/:serviceId" 
                render={() => (
                    <ProviderClientContextProvider>
                        <ServiceProviderClients />
                    </ProviderClientContextProvider>
                )}
            />
            <Route path="/service/providerOrderDetails/:id" render={() => <ProviderOrderDetails />} />
            <Route
                path="/service/provider"  render={() =>(
                        <ServiceContextProvider>
                            <Switch>
                                <Route 
                                path="/service/provider/period/details/:serviceId" 
                                render={() => (
                                    <Period/>
                                )}
                                />
                                <Route path="/service/provider/:serviceId"  render={() => <ServiceProviderServiceInfo />} />
                                <Route path="/service/provider"  render={() => <SeviceProviderInfo />} />
                            </Switch>
                        </ServiceContextProvider>

                    )
                }
            />

            {/********  Consumer Routes **********/}
            <Route path="/service/orderHistory" render={() => <OrdersHistory />} />
            <Route path="/service/orderDetails/:id" render={() => <OrderDetails />} />
            <OrderProvider>
                <AddressProvider>
                    <Switch>
                        <Route path="/service/address/add" render={() => <AddAddress />} />
                        <Route path="/service/address" exact render={() => <Addresses />} />
                    </Switch>
                </AddressProvider>
                <Route path="/service/Groups" render={() => <ServicesGroup />} />
                <Route path="/service/GroupServices" render={() => <ServicesList />} />
                <Route path="/service/providers" render={() => <ServiceProvidersList />} />
                <Route path="/service/periods" render={() => <ServicePeriod />} />
                <Route path="/service/pay/callback/:id" render={() => <ServiceCallback />} />
                <Route path="/service/pay" render={() => <PayService />} />
                <Route path="/service/orderConfirm" render={() => <OrderConfirm />} />
            </OrderProvider>
        </Switch>
    )
}

export default withRouter(ServiceStepsWrapper);
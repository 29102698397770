import React from "react";
import CpInput from "../Input/Input"
import SBotton from "../Buttons/SBotton/SBotton"
import NewSelect from "../NewSelect/NewSelect"
import axios , {serviceAxios} from "../../axios"
import { useParams } from "react-router-dom";

const initialState = {
    "roleName": "",
    "id": "",
    "name": "",
    "profileImageUrl": "",
    "mobileNumber": "",
    "hasApplication": true,
    "isPanelPermi": true
}

const roleNames = [
    "کارمند",
    "مدیر"
]

const clientReducer = (state , action)=>{

    switch(action.type){
        case "EDIT_NAME":
            return{...state , name : action.data}
        
        case "EDIT_MOBILE":
            return{...state , mobileNumber : action.data}
                
        case "EDIT_ROLE":
            return{...state , roleName : action.data}
        
        case "SET_DETAIL":
            return{...state , ...action.data}

        default : 
            return {...state}
    }
}

const AddClient = ({getClients , showToast})=>{
    const [showMobileInput , setshowMobileInput] = React.useState({show : false , disabled : false})
    const [loading , setLoading] = React.useState(false)
    const [clientDetail , clientDispatch] = React.useReducer(clientReducer ,initialState)
    const {serviceId} = useParams()

    const mobileChanged = (e)=>{
        if(e.target.value.length < 12){
            clientDispatch({type : "EDIT_MOBILE" , data : e.target.value})
        }
        if(e.target.value.length === 11){
            getUserByMobile(e.target.value)
        }
    }

    const getUserByMobile = (mobileNumber)=>{
        setLoading(true)
        axios({
            method : "post",
            url : `User/IsAnHomTickUser?mobileOrEmail=${mobileNumber}`
        }).then(res=>{
            console.log("clients" , res.data.data)
            setLoading(false)
            if(res.data.data){
                clientDispatch({type : "SET_DETAIL" , data : {...res.data.data}})
                setshowMobileInput({show : true , disabled : true})
            }else{
                clientDispatch({type : "SET_DETAIL" , data : {...initialState , mobileNumber}})
                setshowMobileInput({show : true , disabled : false})
            }
        })
        .catch(()=>{
            setLoading(false)
        })
    }

    const registerClient = ()=>{
        if(clientDetail.mobileNumber.length === 11 && clientDetail.name != "" && clientDetail.roleName != ""){

            setLoading(true)
            serviceAxios({
                method : "post",
                url : `ProviderUser/Submit/${serviceId}`,
                data : {...clientDetail}
            }).then(res=>{
                console.log("res" , res.data.data)
                getClients()
            })
            .catch(()=>{
                setLoading(false)
            })
        }
        else{
            showToast("تمام فیلد ها را تکمیل کنید" ,  "war")
        }
    }

    return(
        <div >
            <p className="my-3 text-center">
                افزودن کارمند
            </p>
            <CpInput 
                plachodler={'شماره موبایل'} 
                type={"phone"}
                disabled={false}
                value={clientDetail.mobileNumber} 
                maxLength={11} 
                onChange={mobileChanged} 
            />
            {
                (clientDetail.mobileNumber.length === 11 && showMobileInput.show && !loading) &&
                <>
                    <CpInput 
                        plachodler={'نام'} 
                        maxLength={20}
                        type={"text"}
                        disabled={showMobileInput.disabled}
                        value={clientDetail.name} 
                        onChange={(e)=>clientDispatch({type : "EDIT_NAME" , data : e.target.value})}
                    />
                    <NewSelect
                        options={roleNames} 
                        plachodler={null} 
                        obj={false} 
                        sel2={false} 
                        value={clientDetail.roleName} 
                        closeOther={() => {}}
                    >
                        {roleNames.map(item => {
                            return (
                                <div 
                                    style={{ padding: '2%' }} 
                                    key={item} 
                                    value={item} 
                                    onClick={() => clientDispatch({type : "EDIT_ROLE" , data : item})}
                                >
                                    {item}
                                </div>
                            )
                        })}
                    </NewSelect>
                </>
            }
            <div className={`w-50 mx-auto`}>
                <SBotton 
                    title={'ثبت '} 
                    click={registerClient} 
                    sending={loading}
                    gradian 
                />
            </div>
        </div>
    )
}

export default AddClient
import React from 'react';
import { colors, formatMoney } from "../../../value";
import SBotton from '../../Buttons/SBotton/SBotton';
import Card from '../../Card/Card';
import Date from '../../Date/Date';
import Doer from '../../Doer/Doer';




const ChargeCard = props => {
    return (
        <Card 
        //onClick={props.click}
         width={'100%'} >
            <div className={`w-100 p-2`}>
                <div className={`d-flex justify-content-between align-items-center rtl w-100 `}>
                    <div className={` text-center`} style={{ fontSize: '15px', fontWeight: '600', marginLeft: '10px' }} >
                      {props.mounth}
                    </div>
                    <div style={{ width: '40%' }}>
                        <div onClick={props.dontShow} className={`p-1`} style={{fontSize:10, 
                            textAlign:'center',direction:'rtl', 
                            marginRight:10, marginLeft:10, 
                            border:'1px solid #ff5722',
                            borderRadius:15}}>{'به من نشان نده'}</div>
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    <SBotton title={'صدور'} click={props.click} gradian />
                </div>
            </div>
        </Card>
    );
};

export default ChargeCard;
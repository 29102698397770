import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from 'react-service-worker';
import firebase from 'firebase';

import * as serviceWorker from './serviceWorker';
import { initializeFirebase } from './push-notification';




// firebase.initializeApp({
//     apiKey: "AIzaSyCwp_edRYwRIdEByBCcTkI9KYzJNOelrMY",
//     authDomain: "homtick-67bab.firebaseapp.com",
//     projectId: "homtick-67bab",
//     storageBucket: "homtick-67bab.appspot.com",
//     messagingSenderId: "799765177791",
//     appId: "1:799765177791:web:4c5084230020a7f4d2ed0a",
//     measurementId: "G-4TN5TMTE7D"
//   });
//   const messaging = firebase.messaging();
//   messaging.setBackgroundMessageHandler(function(payload) {
//     console.log(
//         "[firebase-messaging-sw.js] Received background message ",
//         payload,
//     );
//     // Customize notification here
//     const notificationTitle = "Background Message Title";
//     const notificationOptions = {
//         body: "Background Message body.",
//         icon: "/itwonders-web-logo.png",
//     };

//     return self.registration.showNotification(
//         notificationTitle,
//         notificationOptions,
//     );
// });


ReactDOM.render(<App /> ,document.getElementById('root'));
initializeFirebase();

const messaging = firebase.messaging();
messaging.onMessage(notification => {
    alert('Notification: ' + JSON.stringify(notification))
});
serviceWorker.register();
registerServiceWorker();
reportWebVitals();

//import React from "react";

import axios from "./axios";
import { DeviceUUID } from 'device-uuid'
import { askForPermissioToReceiveNotifications } from "./push-notification";



export const  reservbaseURL = 'https://api2.homtick.com/api/Reservation/V1'
//export const reservbaseURL= 'http://localhost:5003/api/Reservation/V1'

export const baseURL= 'https://api2.homtick.com/api/V1'
//export const baseURL= 'http://localhost:5003/api/V1'
export const colors = {

    bg: "#FFF",
    app1: "#ff5722",
    app2: "#ff5722",
    app3: "#ff9800",
    app4: "#FFF",
    app5: "#bdbdbd",
    app6: "#757575",
    app7: "#757575",
    app8: "#424242",
    app9: "#f5f5f5",
    app10: "#00ab66",
    app11: '#f44336',
    app12: '#ff572233',
    app13: '#00ab6633',
    app14: '#ff980033',
    app15: '#ed2939',
    app16: '#fa8072',


};

export const formatMoney = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

export const FaToEnNum = (text) => {
    if (!text)
        return '';
    text = text.toString();
    let faNum = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    if (typeof text === 'string') {
        for (var i = 0; i < 10; i++) {
            text = text.replace(new RegExp(faNum[i], 'g'), i)
        }
    }
    faNum = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    if (typeof text === 'string') {
        for (var i = 0; i < 10; i++) {
            text = text.replace(new RegExp(faNum[i], 'g'), i)
        }
    }



    return text.replace(new RegExp(',', 'g'), '');
};


export const getBuildingId = () => {
    if (localStorage.getItem('id')) {
        return localStorage.getItem('id')
    } else {
        return 0
    }
}

export const ApiService = (method, url, data) => {
    return axios({
        method: method,
        url: url,
        data: data
    })
        .then(res => {
            if (res.data.result) {
                return res;
            }
            else if (!res.data.result, res.data.resultCode === 555) {
                return res
            }
        })
        .catch(err => {
            console.log('oooooooooooooo', err);
            if (err.response) {
                if (err.response.status === 404) {
                    console.log('pppp 404');
                } else if (err.response.status === 401) {
                    console.log('pppp 401');

                    if (localStorage.getItem('ref')) {
                        let data = `grant_type=refresh_token&refresh_token=${localStorage.getItem('ref')}&client_id=HomTickMobileClient&client_secret=4f5b555b83f844ef8d8022646a6fb4c2-HomTickMobileSecret`;
                        console.log('data', data);
                        axios.post('https://auth3.homtick.com/connect/token', data, {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        })
                            .then(response => {
                                console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', response);
                                if (response.data) {
                                    localStorage.setItem('token', response.data.access_token);
                                    localStorage.setItem('ref', response.data.refresh_token);
                                    localStorage.setItem('codeTime', new Date().getTime());   
                                    // props.history.push({
                                    //     pathname: '/',
                                    //     state: {
                                    //         mobileNum: mobileNumber,
                                    //         deviceCode: uuid
                                    //     }
                                    // });
                                } else {
                                    console.log('lklklklk');
                                    localStorage.clear();
                                }
                            })
                            .catch(err => {
                                console.log('qqqqqqq');
                                if (err.response.status === 401 || err.response.status === 400) {
                                    localStorage.clear();
                                    window.location.href = '/login'
                                }

                            });
                    } else {
                        localStorage.clear();
                        window.location.href = '/login'
                    }
                } else if (err.response.status === 500) {
                    return err
                }
            }
            else {
                console.log('bargozari?????');
                if (window.location.pathname != '/retry') {
                    window.location.href = '/retry'
                }
            }
        })

}


const onError = (res) => {

}
// const getBuildingInfo = (id) => {
//     console.log(id);
//     setLoading(true);
//     ApiService('Get', `/Building/Detail/${id}`)
//         .then(response => {
//             console.log('getBuildingInfo',response.data);
//             if (response.data.result) {
//                 setBuildingInfo(response.data.data);
//                 setActiveTab(1);
//                 getBuildingUnits(id);

//             } else {
//                 setErrText(response.data.message);
//                 setErrType('war');
//                 clearErr();
//                 // setTab(false);
//                 setLoading(false);
//             }
//         })
// }
export const MyBuildingList = (forced) => {

    if (forced || !localStorage.getItem('list')) {
        return new Promise((resolve, reject) => {
            ApiService('Get', '/Building/MyBuildings')
                .then(response => {
                    if (response.data.result) {
                        localStorage.setItem('list', JSON.stringify(response.data.data));
                        if (localStorage.getItem('id')) {

                            if (response.data.data.find(elem => { return elem.id == localStorage.getItem('id') })) {

                                return resolve(response.data.data);
                            } else {

                                localStorage.setItem('id', response.data.data[0].id);
                                return resolve(response.data.data);
                            }
                        } else {

                            localStorage.setItem('id', response.data.data[0].id);
                            return resolve(response.data.data);
                        }
                    } else {
                        reject(response);

                    }
                })
                .catch(err => {

                })
        });
    }
    else
        return new Promise((resolve, reject) => {

            resolve(JSON.parse(localStorage.getItem('list')));
        });

}


export const getProfile = (forced) => {
    if (forced || !localStorage.getItem('profile')) {
        return new Promise((resolve, reject) => {
            ApiService('Post', '/User/Profile')
                .then(response => {
                    if (response.data.result) {
                        localStorage.setItem('profile', JSON.stringify(response.data.data));
                        localStorage.setItem('uuid', JSON.stringify(response.data.data.id).replace('"','').replace('"',''));


                        //console.log('&&&&&&&&&&&&&>>>>>>>>>', response.data.data);
                        resolve(response.data.data);
                    } else {
                        reject(response);
                    }

                })

        });

    } else {
        //console.log('without req');
        return new Promise((resolve, reject) => {

            resolve(JSON.parse(localStorage.getItem('profile')));
        });
    }

}


export const getBuildingInfo = (id) => {
    return new Promise((resolve, reject) => {
        ApiService('Get', `/Building/Detail/${id}`)
            .then(response => {
                if (response.data.result) {
                    resolve(response.data.data);
                } else {
                    reject(response);
                }
            })
    });
}

export const attachFile = (files) => {
    const formData = new FormData();
    formData.append('file', files);
    return new Promise((resolve, reject) => {
        axios.post(baseURL+'/Attachment/Upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                if (response.data.result) {
                    resolve(response.data.data);
                } else {
                    reject(response);
                }
            })
            .catch(err => {
                console.log('?????', err.response);
                console.log('!!!!!!!!!!', err.request);
            })


    })
}


export const isUserHomtick = (mobile) => {

    return new Promise((resolve, reject) => {
        ApiService('Post', `/User/IsAnHomTickUser?mobileOrEmail=${mobile}`)
            .then(response => {

                if (response.data.result) {
                    resolve(response.data);
                } else {

                    reject(response);
                }
            })
    })
}


export const registerUserForNotify = () => {
    if (JSON.parse(localStorage.getItem('profile')).id && !localStorage.getItem('fbt')) {
        askForPermissioToReceiveNotifications()
            .then(token => {
                let data =
                {
                    RegisterId: JSON.parse(localStorage.getItem('profile')).id,
                    Token: token,
                    DeviceModel: 'webApp',
                    DeviceId: JSON.parse(localStorage.getItem('profile')).mobileNumber,
                    ClientType: 1
                }
                axios.post(`https://push.homtick.com/api/push/RegisterUser/?token=7pFMlAcsoRHTLoHKIsyf7tsMNMNaCUqHMwveTZktMZhsd77dT1JLEXZvtRGbVmMbdsxf0q/jJCd/77/642G2km6A==`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(response => {

                    localStorage.setItem('fbt', token);
                    //console.log('????????????????',response);
                })
            })
            .catch(err => {
                console.log(err);

            })

    }
}


export const downloadPdf = (method, url, data,isreserv=false) => {
    return new Promise((resolve, reject) => {
        // console.log('abbasgholi', data==null?'ghiiiiii': JSON.stringify( data).replaceAll('"','\"'))
        if(window.flutter_inappwebview)
        {window.flutter_inappwebview.
        callHandler('webappdownloader',
            'report',method,
            (isreserv?reservbaseURL:baseURL) + url,
            `{ "Authorization": "bearer ${localStorage.getItem('token')}","Content-Type": "application/json"}`,
           data==null?'': JSON.stringify( data).replaceAll('"','\"')).then(  res=>{
            resolve(res);
        }).catch(err => {
            reject(err)
        });}
        else {
            axios({
                method: method,
                url: (isreserv?reservbaseURL:baseURL) + url,
                data: data,
                headers: {

                    'Authorization': `bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'

                },
                responseType: 'blob',
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'report.pdf');
                    document.body.appendChild(link);
                    link.click();
                    resolve(response);
                })
                .catch(err => {
                    reject(err)
                })
        }
    })


}

export const downloadExcel = ( url, name) => {
    return new Promise((resolve, reject) => {
        if(window.flutter_inappwebview)
        {window.flutter_inappwebview.
        callHandler('webappdownloader',
            name,'post',
            baseURL + url,
            `{ "Authorization": "bearer ${localStorage.getItem('token')}","Content-Type": "attachment; filename=template.xlsx","Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}`,
            null).then(  res=>{
            resolve(res);
        }).catch(err => {
            reject(err)
        });}
        else {

            axios.post(baseURL + url , null,
                {
                headers: {

                    'Authorization': `bearer ${localStorage.getItem('token')}`,
                    'Content-Disposition': "attachment; filename=template.xlsx",
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

                },
                responseType: 'arraybuffer',
            })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${name}.xlsx`);
                    document.body.appendChild(link);
                    link.click();

                    resolve(response);

                })


                .catch(err => {
                    reject(err)
                })
        }
    })


}



export const user = [];
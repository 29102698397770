import React, { useEffect, useState } from 'react';
import classes from './Collaps.module.css';
import arrow from '../../Assets/myAsset/down-arrow.svg'
import { Component } from 'react';
let Self = null;
class Collapsable extends Component {
    constructor(props) {
        super(props);
        Self = this;
        this.state = {
            openn: false
        }
    }
    componentDidMount() {

    }

    render() {
        return (
            <div>
                <div>
                    <div className={`d-flex justify-content-between align-items-center flex-row-reverse p-2`}
                     onClick={() => { this.setState({ openn: !this.state.openn }) }}>
                        {this.props.header}
                        <img style={{ width: 15, height: '100%', transform: `rotate(${this.state.openn ? 180 : 0}deg)` }} src={arrow} alt={'alt'} />
                    </div>
                    {this.props.header2}
                </div>
                {/* <div style={{ height: this.state.openn ? 'fit-content' : 0 }}>{this.props.content}</div> */}
                <div className={classes.content} style={{ height: this.state.openn ? 'fit-content' : 0 }}>{this.props.content}</div>
            </div>

        )
    }
}
export default Collapsable;
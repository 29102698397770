import React from "react";
import { useHistory } from "react-router-dom";
import { serviceAxios } from "../../axios";
import SBotton from "../Buttons/SBotton/SBotton";

const OrderPass= ({orders , setError , getDashbordData})=>{
    const history = useHistory()
    const [sending , setSending] = React.useState(false)


    return orders.map(order =>{
        return(
            <div className="shadow-card mt-4 mb-3 p-1" onClick={()=>history.push(`/service/orderDetails/${order.id}`)} key={order.id.toString()}>
                <div className="d-flex flex-column w-100 p-1 px-2">
                    <div className="d-flex flex-row justify-content-between rtl">
                        <span>
                            {
                                `پسورد سفارش ${order.titel}`
                            }
                        </span>
                        <span>
                            {
                                order.reservationDate
                            }
                        </span>
                    </div>
                    {/*<div className="d-flex flex-row rtl mt-1">*/}
                    {/*    <span>*/}
                    {/*        {*/}
                    {/*            `برای ورود  "${order.provider.name}" امتیاز دهید`*/}
                    {/*        }*/}
                    {/*    </span>*/}
                    {/*</div>*/}
                    <div className="d-flex flex-row align-self-center">
                        <div className="d-flex flex-grow-1  m-4 ">
                           <p  class="fs-1">{order.password}</p>
                        </div>

                    </div>
                </div>
            </div>
        )
    })
}


export default OrderPass
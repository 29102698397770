import React from "react";

const ServiceContext = React.createContext()

const useServiceContext = ()=>{
    const serviceContext = React.useContext(ServiceContext)
    if(serviceContext === undefined){
        throw new Error("useServiceContext must be used inside a client")
    }

    return serviceContext
}

const ServiceContextProvider = ({children})=>{
    const [service , setService] = React.useState(null)

    React.useEffect(()=>{
        const oldService = localStorage.getItem("selectedService")
        console.log("HHHHHHHHH" , oldService)
        if(!!oldService){
            setService(JSON.parse(oldService))
        }
        else{
            setService({})
        }
    },[])

    return(
        <ServiceContext.Provider value={[service , setService]}>
            {service? children : null}
        </ServiceContext.Provider>
    )
}

const setServiceAsCache = (setService , service)=>{
    localStorage.setItem("selectedService" , JSON.stringify(service))
    setService(service)
}

export {ServiceContextProvider , useServiceContext , setServiceAsCache }
import React from "react";
import { serviceAxios } from "../axios";

const AddressContext = React.createContext()

const useAddressContext = ()=>{
    const addContext = React.useContext(AddressContext)
    if(addContext === undefined){
        throw new Error("useAddressContext must use inside a addressProvider")
    }
    return addContext
}

const getAddresses = (dispatch)=>{
    dispatch({type : "GET_ADDRESS"})
    serviceAxios.get(
        `/Address/MyAddresses`
    ).then(res=>{
        dispatch({type : "SET_ADDRESS" , data : res.data.data})
    }).catch(err =>{
        console.error(err)
    })
}

const deleteAddresses = (addressId , dispatch)=>{
    dispatch({type : "DELETE_ADDRESS"})
    serviceAxios.get(
        `/Address/Delete/${addressId}`
    ).then(res=>{
        getAddresses(dispatch)
    }).catch(err =>{
        console.error(err)
    })
}
const initialState = {
    list : [],
    loading : true
}

const addressReducer = (state , action)=>{
    switch(action.type){
        case "GET_ADDRESS":
            return {...state , loading : true}
        case "SET_ADDRESS":
            return {...state , loading : false , list : action.data}
        case "DELETE_ADDRESS":
            return {...state , loading : true}
        default : 
            return state
    }
}

const AddressProvider = ({children})=>{
    const [addressState , addressDispatch] = React.useReducer(addressReducer , initialState)
    return(
        <AddressContext.Provider value={{addressState , addressDispatch , getAddresses , deleteAddresses}}>
            {children}
        </AddressContext.Provider>
    )
}

export {AddressProvider , useAddressContext}
import React, { useEffect, useState } from 'react';



const Toast = props => {
    const [autoHide, setAutoHide] = useState(false);
    const [Width, setW] = useState(null);
    const [show, setShow] = useState(false);
    useEffect(() => {
        if(props.text && props.type){
            setW(document.getElementById('baseBx').clientWidth);
            setShow(true);
            setAutoHide(true);
            setTimeout(() => {
                setAutoHide(false);
            }, props.second? props.second:25000)
        }
        else{
            return function cleanup() {}
        }
       
        
    }, [props.text, props.type]);
    useEffect(() => {
        return () => {
          console.log("cleaned up");
        };
      }, []);
    return (
        <div style={{
            zIndex: 99999999,
            width: Width,
            alignItems: 'center',
            position: 'absolute',
            right: 0,
            transform: show && autoHide ? 'translateY(0%)' : 'translateY(100%)',
            bottom: show ? 0 : -100,
            transition: 'all 0.9s',
            textAlign: 'center',
            direction: 'rtl',
            color: props.type == 'err' ? '#f8f9fa' : ' #343a40',
            padding: '3%',
            backgroundColor: props.type == 'war' ? '#ecd424' : props.type == 'err' ? '#ff4f4f' : props.type == 'suc' ? '#84dc35' : '',
            fontSize: 15
        }}>
            <div>{props.text}</div>

        </div>
    )
}
export default Toast;
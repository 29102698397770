import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import err from '../../Assets/myAsset/error_connect.png';
import logo from '../../Assets/myAsset/logo.png';
import ContentBx from '../BaseBx/ContentBx/ContentBx';
import Layout from '../Layout/Layout';

const RetryPage = props => {
    const [W, setW] = useState(0);
    const [height, seHeight] = useState(0);
    useEffect(() => {
        // const WBase = document.getElementById('baseBx').clientWidth;
        // const HBx = document.getElementById('baseBx').clientHeight;
        // setW(WBase);
        // seHeight(HBx);
    }, [])


    return (
        <Layout  loaded={true} isHome={false} showMenu={false}   retry={true}>
           
            <div className={`d-flex  align-items-center h-100 w-100`}>
            <div className={`d-flex justify-content-center flex-column align-items-center h-100 w-100`}>
                <div className={`text-center w-100 mb-4  `} >
                    <img src={logo} style={{ width: '60%' }} />
                </div>
                <div>
                    <div className={`w-50 mx-auto`}>
                        <img style={{ width: '100%' }} src={err} />
                    </div>

                    <div>
                        <div className={`m-2 rtl`} style={{ fontSize: 12, textAlign: 'center' }}>امکان اتصال به سرورهای هومتیک وجود ندارد. لطفا دسترسی به اینترنت خود را بررسی کنید و یا دقایقی دیگر دوباره برای ارتباط تلاش کنید.</div>
                        <div className={`fntBold`} style={{ fontSize: 13, textAlign: 'center' , color: '#ff5722' }} onClick={()=>{
                           props.history.goBack();
                        }} >تلاش مجدد</div>
                    </div>

                </div>


            </div>

        </div>

          
       
        </Layout>
        
    )
}
export default withRouter(RetryPage);
import React, { useState } from 'react';
import classes from './Login.module.css';
import logo from '../../../Assets/myAsset/LOGO-HOMTICK.png';
import SInput from '../../../Components/Input/SInput';
import BaseBX from "../../../Components/BaseBx/BaseBx";
import axios from 'axios';
import { DeviceUUID } from 'device-uuid'
import loadingGif from '../../../Assets/gifs/22.gif'
import { useHistory, withRouter } from 'react-router-dom';
import { FaToEnNum } from '../../../value';



const Login = props => {
    const [mobileNum, setMobileNum] = useState('');
    const [errMess, setErrMess] = useState('');
    const [loading, setLoading] = useState(false);

    const onchangeHandler = (e) => {
        console.log(0 + e);
        setMobileNum(0 + e);
        // console.log('mobileNum',mobileNum);
    }
    const IsAuthenticated = () => {
        console.log('IsAuthenticated func');
        axios({
            method: 'Get',
            url: '/User/IsAuthenticated'
        })
            .then(res => {
                console.log(res);
                if (res.result) {
                    props.history.push({
                        pathname: '/'
                    })
                } else {
                    console.log('jhjhj');
                    props.history.push({
                        pathname: '/login'
                    })
                }
            })
            .catch(err => {
                console.log(err.response);
                props.history.push({
                    pathname: '/login'
                })
            })
    }

    const getVerifyCodeHandler = () => {


        let mobileNumber = 0 + mobileNum;
        if (!loading) {
            const pattern = /[0-9]|\./;
            if (!pattern.test(mobileNumber)||mobileNum.length<10) {
                setErrMess('شماره وارد شده اشتباه است')
                console.log('mobileNum', mobileNumber);
                console.log('wrong mobile num');
                setLoading(false);
                return
            } else {
                console.log('mobileNum the right way', mobileNumber);
                var uuid = new DeviceUUID().get();
                setLoading(true);
                let data = {
                    mobileOrEmail: `${FaToEnNum(mobileNumber)}`,
                    deviceCode: uuid,
                    firstName: '',
                    lastName: '',
                    password: ''
                }
                console.log('data>>>', data);
                axios.post('https://auth3.homtick.com/api/V1/User/GetVerifyCode', data, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(response => {
                    console.log(response);
                    if (response.data.result) {
                        localStorage.setItem('mobileNum', mobileNumber);
                        localStorage.setItem('codeTime', new Date().getTime());
                        props.history.push({
                            pathname: '/confirmation',
                            state: {
                                mobileNum: mobileNumber,
                                deviceCode: uuid
                            }
                        });
                    } else {
                        setErrMess(response.data.message);
                        setLoading(false);
                    }
                })
                    .catch(err => {
                        setErrMess('خطا'+err);

                        console.log(err.Message);
                        setLoading(false);
                    });
            }

        }





    }

    return (

        <BaseBX>
            <div className={`${classes.imgBack} `} style={{ height: '100vh', minHeight: 600, overflow: "hidden" }}>
                <div className={`d-flex flex-column align-content-between justify-content-between align-items-center h-100  `}>
                    <div className={`d-flex flex-column align-content-around justify-content-around align-items-center p-4 w-100  `} style={{ height: '45%' }}>
                        <img src={logo} style={{ width: '60%' }} />
                        <div className={`text-light mediumF`}>شماره موبایل خود را وارد کنید</div>
                        <div className={`w-75 mx-auto`}>
                            <SInput placeholder={'9121234567'}
                                prefix={true} type={'number'}
                                value={mobileNum}

                                onKeyUp={(event) => {
                                    console.log('11111');

                                    if (event.key === 'Enter') {
                                        getVerifyCodeHandler()
                                    }
                                }}
                                maxLength={'10'}
                                onChange={(event) => {
                                    if (event.target.value.length == 1 && event.target.value.charAt(0) == '0') {
                                        setMobileNum('')
                                    } else {
                                        setMobileNum(event.target.value)
                                    }
                                }} />
                        </div>
                        <div className={`text-dark mediumF text-center rtl `}>{errMess}</div>


                    </div>
                    <div className={`d-flex flex-column align-content-around justify-content-center align-items-center p-4 w-100   `} style={{ height: '40%' }}>
                        <div onClick={() => { getVerifyCodeHandler() }} className={`pl-3 pr-3 pt-2 pb-2 text-light w-50 mx-auto text-center fntBold `}
                            style={{ backgroundColor: !loading ? '#FF3F3F' : '#cdcdcd', borderRadius: 25, fontSize: 20 }}>
                            {!loading ?
                                'تایید'
                                : <div style={{ width: '20%', marginLeft: '40%' }}><img src={loadingGif} style={{ width: '100%', margin: ' 0 auto' }} /></div>
                            }
                        </div>
                        <a href={'tel: 02188731823'}>
                            <div className={`grey smallF `} style={{ marginTop: '10%' }}>تماس با پشتیبانی</div>
                        </a>
                    </div>
                </div>

            </div>

        </BaseBX>



    )
}
export default withRouter(Login);

import React, { useEffect, useState } from 'react';
import AddBotton from '../../Components/Buttons/AddBotton/AddBotton';
import SBotton from '../../Components/Buttons/SBotton/SBotton';
import Layout from '../../Components/Layout/Layout';
import TabBar from '../../Components/TabBar/TabBar';
import { ApiService, downloadPdf, formatMoney, getBuildingInfo, getProfile, MyBuildingList } from '../../value';
import searchImg from '../../Assets/myAsset/loupe.svg';
import { withRouter } from 'react-router-dom';
import EmptyList from '../../Components/EmptyList/EmptyList';
import PayGatesModal from '../../Components/Modal/PayGatesModal/PayGatesModal';
import ChargeWalletModal from '../../Components/Modal/ChargeWalletModal/ChargeWalletModal';
import RequestMoney from '../../Components/Modal/RequestMoney/RequestMoney';
import Toast from '../../Components/Toast/Toast';
import WalletTransCard from '../../Components/Cards/WalletTransCard/WalletTransCard';
import FilterModal2 from '../../Components/Modal/FilterModal2/FilterModal2';
import SearchBar from '../../Components/SearchBar/SearchBar';
import RequestCard from '../../Components/Cards/RequestCard/RequestCard';
import BillCard from '../../Components/Cards/BillCard/BillCard';
import DeleteModal from '../../Components/Modal/DeleteModal/DeleteModal';
import pdf from '../../Assets/myAsset/pdf.svg'





const amount = [
    {
        id: 1,
        amount: 3000000,

    },
    {
        id: 2,
        amount: 2000000,
    },
    {
        id: 3,
        amount: 1000000,
    },

]
const def = [
    {
        id: 1,
        value: 1,
        label: 'نگین',
        amount: 285000000,
        change: true
    },
    {
        id: 2,
        value: 2,
        label: 'پارس',
        amount: 2500000,
        change: false
    },
    {
        id: 3,
        value: 3,
        label: 'آریا',
        amount: 800000,
        change: true
    },

];
const Wallet = props => {
    const [loading, setLoading] = useState(true);
    const [showModalReq, setShowModalReq] = useState(false);
    const [showModalCharge, setShowModalCharge] = useState(false);

    const [paymentGate, setPaymentGate] = useState(false);
    const [gateList, setGateList] = useState([]);
    const [myWallet, setMyWallet] = useState(0);
    const [buildingWallet, setBuildingWallet] = useState(0);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');


    const [profileInfo, setProfileInfo] = useState(0);
    const [myTransaction, setTransaction] = useState([]);
    const [myRequests, setMyRequests] = useState([]);



    const [buildingList, setBuildingList] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);

    const [activeTab, setActiveTab] = useState(props.location.state && props.location.state.tab ? props.location.state.tab : null);
    const [renderItem, setRenderItem] = useState(null);
    const [search, setSearch] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [filterData, setFilterData] = useState(null);
    const [filterModal, setFilterModal] = useState(false);
    const [billsList, setBillsList] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletId, setDeletId] = useState(null);
    const [dwnldPdf, setDwnld] = useState(false)



    useEffect(() => {
        getMyBuildingList();

    }, [])



    useEffect(() => {
        if(dwnldPdf)
       { downloadPdfSample();}
      
   }, [dwnldPdf]);




    useEffect(() => {
        if (activeTab == 1) {
            setRenderItem(renderTransactions())
        } else if (activeTab == 2) {
            setRenderItem(renderMyRequest())
        } else if (activeTab == 3) {
            setRenderItem(renderMyBills())
        }
    }, [activeTab, buildingWallet, billsList,
        myWallet, myRequests, myTransaction,
        searchText, search, filterData]);



    const getMyBuildingList = () => {
        MyBuildingList(false).then(res => {
            let list = res;
            if (list) {
                setBuildingList(list);
                setSelectedBulding(list.find(elem => { return elem.id == localStorage.getItem('id') }));
                if (activeTab) {
                    if (activeTab == 3) { getBillsList() }
                    else if (activeTab == 2) {
                        getMyRequests()

                    } else {
                        getData(activeTab, true)
                    }

                } else {
                    getData(1, true)

                }

            }
        })
            .catch(err => {
                setErrText(err.data.message);
                setErrType('war');
                clearErr();
                setLoading(false);
            })
    }

    const getData = (index, force) => {
        getBuildingInfo(localStorage.getItem('id')).then(res => {
            setBuildingWallet(res.cashDesks.list.find(elem => { return elem.isOnline }));
            getProfile(force).then(response => {
                setProfileInfo(response)
                setMyWallet(response.cashDesk.balance);
                getTransactions(response.cashDesk.id, index);



            })


        })
    }
    const getTransactions = (id, index) => {
        setLoading(true);
        let data = {
            cashDeskId: id,
            buildingId: 0,
            nameLike: searchText,
            pageSize: 1000,
            pageNumber: 0,
            sortIsDesc: true
        }
        if (filterData) {
            data.sortIsDesc = filterData.sortIsDesc;
            data.sortByType = filterData.sortByType;
            data.startAmount = filterData.startAmount;
            data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }
        ApiService('Post', `/AccRequest/ListDocuments/${0}/list`, data)
            .then(res => {
                if (res.data.result) {
                    setTransaction(res.data.data)
                    setLoading(false);
                    setActiveTab(index)
                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr();
                    setLoading(false);
                }


            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })

    }


    const getMyRequests = () => {
        setLoading(false)
        let data = {
            nameLike: searchText,
            pageSize: 100,
            pageNumber: 0
        }
        if (filterData) {

            // data.sortIsDesc = filterData.sortIsDesc;
            // data.sortByType = filterData.sortByType;
            // data.startAmount = filterData.startAmount;
            // data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }
        ApiService('Post', `/AccRequest/RequestTransferList/${0}`, data)
            .then(res => {
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!', res);
                if (res.data.result) {
                    setMyRequests(res.data.data)

                }

            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const getBillsList = () => {
        setLoading(true);
        ApiService('Get', `/Bill/GetList/${0}`)
            .then(res => {
                console.log('ssssssss', res);
                if (res.data.result) {
                    setBillsList(res.data.data);
                    setRenderItem(renderMyBills());
                    setLoading(false);


                } else {
                    setErrText(res.data.message);
                    setErrType('war');
                    setLoading(false);
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }



    const downloadPdfSample = () => {
        setLoading(true)

        let data = {
            cashDeskId: profileInfo.cashDesk.id,
            buildingId: 0,
            nameLike: searchText,
            pageSize: 1000,
            pageNumber: 0,
            sortIsDesc: true
        }
        if (filterData) {
            data.sortIsDesc = filterData.sortIsDesc;
            data.sortByType = filterData.sortByType;
            data.startAmount = filterData.startAmount;
            data.endAmount = filterData.endAmount;
            data.startDate = filterData.startDate;
            data.endDate = filterData.endDate;
        }

        downloadPdf('post',`/AccRequest/ListDocuments/${0}/export`, data)
        .then(res => {
            // console.log(res);
            setDwnld(false);
            setLoading(false);
        })
        .catch(err=>{
            setLoading(false);
            setErrText('درخواست با خطا مواجه شد ');
            setErrType('err');
            clearErr();


        })
       

    };

    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }



    const submitTransfer = (fData) => {
        
        fData.number = `IR`.concat(fData.number);
        let data = fData;
        data.sourceCashDeskId = profileInfo.cashDesk.id;
        data.destinationCashDeskId = null;
        ApiService('Post', `/AccRequest/RequestTransferAmount/${0}`, data)
            .then(res => {
                if (res.data.result) {
                    setErrText('درخواست شما در 48 ساعت آینده بررسی خواهد شد');
                    setErrType('suc');
                    setShowModalReq(false);
                    getMyRequests();
                    setActiveTab(2)
                    clearErr();
                } else {

                    setErrText(res.data.message);
                    setErrType('war');
                    clearErr()
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const renderTransactions = () => {
        return (myTransaction && myTransaction.length > 0 ? <div>

            <div className={`d-flex align-items-center pl-2 pr-2`}>
                <div style={{ width: 25 }} onClick={() => {
                    setDwnld(true)
                }}>
                    <img className={`w-100`} src={pdf} />
                </div>
                <SearchBar
                    click={() => {
                        if (search) {
                            setSearchText('');
                            setSearch(false);
                            getTransactions(profileInfo.cashDesk.id, 1)

                        } else {
                            setSearch(true);
                        }
                    }}
                    sortModule={true}
                    clickSort={() => {
                        setFilterModal(true);
                        setSearchText('');
                        setSearch(false);
                    }} />
            </div>
            {search ?
                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                        style={{
                            padding: 5,
                            border: ' 1px solid grey',
                            borderRadius: 10
                        }}>
                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                            onKeyUp={event => {

                                if (event.key === 'Enter') {
                                    getTransactions(profileInfo.cashDesk.id, 1)
                                }
                            }}
                            placeholder={'جستجو'}
                            onChange={(e) => {
                                setSearchText(e.target.value);

                            }} />
                        <div style={{ width: 15, }}>
                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                getTransactions(profileInfo.cashDesk.id, 1)
                            }} />
                        </div>

                    </div>
                    {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                </div>
                : null}


            {myTransaction.map((item, index) => {
                return <div key={index}>
                    <WalletTransCard
                    click={() => {
                        props.history.push({
                            pathname: `/wallet/${item.id}`,
                            state: {
                           //     id: item.id,
                                backUrl:'/wallet'
                            }
                        })
                    }}
                        itemInfo={item}
                    />
                </div>
            })

            }


        </div> : <EmptyList des={'تاکنون تراکنشی ثبت نشده‌است'} />)

    }
    const renderMyRequest = () => {
        return (<div>



            {myRequests.length > 0 ? <SearchBar
                click={() => {
                    if (search) {
                        setSearchText('');
                        setSearch(false);
                        getMyRequests()

                    } else {
                        setSearch(true);
                    }
                }}
                sortModule={true}
                clickSort={() => {
                    setFilterModal(true);
                    setSearchText('');
                    setSearch(false);
                }} /> : null}
            {search ?
                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                        style={{
                            padding: 5,
                            border: ' 1px solid grey',
                            borderRadius: 10
                        }}>
                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                            onKeyUp={event => {

                                if (event.key === 'Enter') {
                                    getMyRequests()
                                }
                            }}
                            placeholder={'جستجو'}
                            onChange={(e) => {
                                setSearchText(e.target.value);

                            }} />
                        <div style={{ width: 15, }}>
                            <img className={`w-100`} src={searchImg} alt={'alt'} onClick={() => {
                                getMyRequests()
                            }} />
                        </div>

                    </div>
                    {/* <div style={{ width: 15, padding: 3 }} onClick={() => { setSearch(false) }}>
                            <img src={del} style={{ width: '100%' }} />
                        </div> */}

                </div>
                : null}
            {myRequests.length > 0 ?
                myRequests.map((item, index) => {
                    return <RequestCard key={index} item={item}
                    />
                })

                : <EmptyList des={'تاکنون تراکنشی ثبت نشده‌است'} />
            }
        </div>)
    }
    const renderMyBills = () => {
        return (<>{billsList && billsList.length > 0 ?
            <div>
                {billsList.map((item, index) => {
                    return <BillCard key={index} item={item}
                        delete={() => {
                            setDeleteModal(true);
                            setDeletId(item.id)

                        }}
                                     update={(e)=>{updateBill(item.id);

                                         e.stopPropagation();}} edit={() => {
                            props.history.push({
                                pathname: '/bills/add',
                                state: {
                                    billDetail: item,
                                    userBill: true
                                }
                            })
                        }}
                        click={(e) => {
                            e.stopPropagation();
                            console.log('item', item);
                            props.history.push({
                                pathname: '/pay',
                                state: {
                                    payData: item.paymentData,
                                    callBackUrl: window.location.href
                                }
                            });

                        }} />
                })}
            </div> : <EmptyList des={'قبض وجود ندارد'} />}</>)


    }
    const tabItems = [
        {
            id: 1,
            title: 'تراکنش‌ها ',
            content: renderTransactions()
            //content: renderMyWallet()
        },
        {
            id: 2,
            title: 'درخواست‌های من',
            content: renderMyRequest()
            // content: renderBuildingWallet()
        },
        {
            id: 3,
            title: 'قبض های من',
            content: renderMyBills()
            //content: Bills()
            //  content: renderBuildingWallet()
        },

    ]


    const submitPay = (amount) => {
        if (parseInt(amount) < 100000) {
            setErrText('مبلغ وارد شده مجاز نمی‌باشد');
            setErrType('war');
            clearErr();
            return;

        }

        setLoading(true);
        let data = {};

        data = {
            callBackUrl: window.location.href,
            amountPayable: parseInt(amount),
            payFor: 0,

        }
        ApiService('Post', `/Payment/GeneratePaymentUrl`, data)
            .then(response => {
                if (response.data.result) {
                    setGateList(response.data.data);
                    setShowModalCharge(false);
                    setPaymentGate(true);
                    setLoading(false);

                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    setLoading(false);

                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })
    }

    const deleteBill = () => {
        console.log('delete', deletId);
        if (deletId) {
            ApiService('Get', `/Bill/Delete/${deletId}`)
                .then(res => {
                    if (res.data.result) {
                        setErrText('با موفقیت حذف شد');
                        setErrType('suc');
                        clearErr()
                        setDeleteModal(false)
                        getBillsList()
                        setDeletId(null)



                    } else {
                        setErrText(res.data.message);
                        setErrType('war');
                        clearErr();
                        setDeletId(null)


                    }
                })
                .catch(err => {
                    setErrText('خطای داخلی سرور');
                    setErrType('err');
                    setLoading(false);
                    clearErr();
                    return
                })
        }
    }
    const updateBill = (id) => {

        if (id) {
            ApiService('Get', `/Bill/Update/${id}`)
                .then(res => {
                    console.log(res);
                    if (res.data.result) {
                        setErrText('با موفقیت به روز گردید');
                        setErrType('suc');
                        clearErr()
                        setDeleteModal(false)
                        getBillsList()
                        setDeletId(null)



                    } else {
                        setErrText(res.data.message);
                        setErrType('war');
                        clearErr();
                        setDeletId(null)


                    }
                })
                .catch(err => {
                    setErrText('خطای داخلی سرور');
                    setErrType('err');
                    clearErr();
                    return
                })
        }
    }



    return (<>
        <Layout loaded={true} title={'کیف پول من '} isHome={false}
            btn={false} showMenu={true} loading={loading}
            displayMenu={!loading}
            customBack={true}
            path={'/'} >
            <Toast text={errText}
                type={errType} />
            <DeleteModal show={deleteModal} deletable={true}
                delete={() => { deleteBill() }}
                doNotDelete={() => { setDeleteModal(false) }}
                text={'از حذف قبض اطمینان دارید؟'}
                click={() => {
                    setDeleteModal(false)
                }} />
            <FilterModal2 show={filterModal} amount={true}
                sortInc={true}
                type2={true}
                click={() => {
                    setFilterModal(false);
                    setFilterData(null);
                }}
                submit={(filterData) => {
                    setFilterData(filterData);
                    setFilterModal(false);
                    if (activeTab == 1) {
                        getTransactions(profileInfo.cashDesk.id, 1)
                    } else {
                        getMyRequests()
                    }
                }} />





            <RequestMoney
                showModalReq={showModalReq}
                user={true}
                submit={(finalData) => {
                    if ((!finalData.number || finalData.number.length!=24 || !finalData.ownerName)) {
                        setErrText('شماره شبا را به درستی و نام دارنده حساب را مشخص کنید');
                        setErrType('war');
                        clearErr();
                        return
                    }
                    if (!finalData.amount || finalData.amount == 0 || finalData.amount < 500000) {
                        setErrText('حداقل مبلغ 500000 ریال می‌باشد');
                        setErrType('war');
                        clearErr();
                        return
                    }
                    submitTransfer(finalData)

                }}
                close={() => {
                    setShowModalReq(false);
                }} />



            <PayGatesModal
                showModal={paymentGate}
                close={() => {
                    setPaymentGate(false)
                }}

                gateList={gateList} />

            <ChargeWalletModal
                showModalCharge={showModalCharge}
                close={() => {
                    setShowModalCharge(false)
                }}
                submitPay={(amount) => {
                    console.log('1', amount);
                    submitPay(amount)

                }} />
            <div style={{ height: 'inherit' }}>
                <div>
                    <div className={`d-flex justify-content-between align-items-center flex-row-reverse mb-3`}>
                        <div className={`rtl text-right mediumF `}>موجودی کیف پول من  :</div>
                        <div className={`d-flex justify-content-between align-items-center flex-row-reverse `} >
                            <div className={`backGreyLight pr-3 pl-3`} style={{ borderRadius: 25 }} >{formatMoney(myWallet)}</div>
                            <div className={`grey smallF`}>ریال</div>
                        </div>
                    </div>
                    <AddBotton title={'افزایش اعتبار'} click={() => {
                        setShowModalCharge(true);
                    }} />
                    <div className={`d-flex justify-content-around align-items-center flex-row-reverse `}>
                        <div className={`pl-1 w-50`}>
                            <SBotton title={' درخواست وجه'} click={() => { setShowModalReq(true) }} />
                        </div>
                        <div className={`pr-1 w-50`}>
                            <SBotton title={' ثبت قبض '} click={() => {
                                props.history.push({
                                    pathname: '/bills/add',
                                    state: {
                                        userBill: true
                                    }
                                })
                            }} />
                        </div>


                    </div>

                </div>
                <div className={`d-flex flex-row-reverse align-items-center justify-content-center w-100`} onClick={props.click}>
                    {tabItems.map(item => {
                        return (<TabBar title={item.title} font={'0.8rem'} key={item.id} id={item.id} activeIndex={activeTab} click={() => {
                            setActiveTab(item.id);
                            setRenderItem(item.content);
                            if (item.id == 2) {
                                getMyRequests()
                            } else if (item.id == 3) {
                                console.log('kijjijii');
                                getBillsList()
                            }
                        }} />)
                    })}
                    {/* <TabBar
                active={1}
                items={tabItems}
                contentOfActive={renderMyWallet()}
            /> */}
                </div>
                <div className={`w-100 bb`} style={{ height: `calc(100% - 11rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>{renderItem}</div>
            </div>
        </Layout>
    </>)
}

export default withRouter(Wallet);



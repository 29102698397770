import React from "react"
import Layout from "../../Components/Layout/Layout"
import { getOrderHistory } from "../../mocks/orderHistory"
import { useHistory, useParams } from "react-router-dom"
import { Rating } from "@mui/material"
import CpInput from '../../Components/Input/Input';
import SBotton from "../../Components/Buttons/SBotton/SBotton"
import { formatMoney } from "../../value"
import { serviceAxios } from "../../axios"
import useError from "../../hooks/useError"
import Toast from "../../Components/Toast/Toast"
import ProviderInfo from "../../Components/ProviderInfo/ProviderInfo"
import defaultImg from "../../Assets/myAsset/user.png"
import ServiceBanner from "../../Components/ServiceBanner/ServiceBanner"
import serviceImg from '../../Assets/myAsset/customer-service.svg';

const OrderDetails = ()=>{
    const [loading , setLoading] = React.useState(true)
    const [sending , setsending] = React.useState(false)
    const [showProviderInfo , setShowProviderInfo] = React.useState(false)
    const [rate , setRate] = React.useState(null)
    const [comment , setComment] = React.useState("")
    const [order , setOrder] = React.useState(null)
    const {error , setError} = useError();
    const { id } = useParams()

    React.useEffect(()=>{
        getOrder()
    },[])

    const getOrder = ()=>{
        setLoading(true)
        serviceAxios.get(
            `/Order/Detail/${id}`
        ).then(res=>{
            setOrder(res.data.data)
            setLoading(false)
        }).catch(error=>{
            setLoading(false)
            setError("خطایی رخ داده است. لطفا مجددا تلاش کنید" , "err")
        })
    }

    const registerComment = ()=>{
        setsending(true)
        serviceAxios.post(
            "/Order/SubmitRate",
            {
                "orderId": parseInt(id),
                "rate": rate * 2,
                "comment": comment
              }
        ).then(res=>{
            setsending(false)
            setOrder({...order , needRating : false , rate , comment})
            setError("نظر شما ثبت شد" , "suc")

        }).catch(error =>{
            setsending(false)
            setError("خطایی رخ داده است. لطفا مجددا تلاش کنید" , "err")
        })
    }

    return(
        
        <Layout
            title={"جزییات سفارشات"}
            loaded={true}
            loading={loading}
            showMenu={true}
            displayMenu={true}
        > 
        {
            order &&
            <div className="d-flex flex-column align-items-center h-100 overflow-auto">
                <ServiceBanner
                    banner={!!order.providerService.image?order.providerService.image.path:serviceImg}
                />
                <div className="d-flex flex-row w-100 justify-content-end mb-2 mt-4">
                    <h6>
                        {
                            order.provider.name
                        }
                    </h6>
                </div>
                <div className="d-flex flex-row w-100 justify-content-end mb-2">
                    <p>
                        {
                            "شماره سفارش : " + order.id
                        }
                    </p>
                </div>
                <div className="d-flex flex-row w-100 justify-content-end mb-2">
                    <p>
                        {
                            "سانس : " + order.period.title
                        }
                    </p>
                </div>
                <div className="d-flex flex-row w-100 justify-content-end mb-2">
                    <p>
                        {
                            `تاریخ : ${order.reservationDate}`
                        }
                    </p>
                </div>
                <div className="d-flex flex-row w-100 justify-content-end mb-2">
                    <p>
                        {
                            `ساعت : ${order.period.startTime} الی ${order.period.endTime}`
                        }
                    </p>
                </div>
                <div className="d-flex flex-row w-100 justify-content-end mb-2">
                    <p>
                        {
                            `تعداد : ${order.count}`
                        }
                    </p>
                </div>
                <div className="d-flex flex-row w-100 justify-content-end mb-2">
                    <p>
                        {
                            `مبلغ : ${order.totalPrice > 0 ? (formatMoney(order.totalPrice) + " ریال") : "رایگان"}`
                        }
                    </p>
                </div>
                
                <div className="d-flex flex-column w-100 align-items-start rtl mb-3">
                    <span>
                        خدمات دهنده :
                    </span>
                    <div className="d-flex flex-row w-100 justify-content-start rtl mt-3">
                        <img 
                            src={
                                !!order.provider.image &&
                                !!order.provider.image.path &&
                                order.provider.image.path != "" ? 
                                order.provider.image.path : defaultImg
                            }
                            style={{width : 30 , height : 30 , borderRadius : "50%" , marginLeft : 5 , marginRight: 5}}
                        />
                        <span>
                            {
                                order.provider.name 
                            }
                        </span>
                        <span className="mx-2" style={{color : "#ff5722"}} onClick={()=>setShowProviderInfo(!showProviderInfo)}>
                            {
                                showProviderInfo ? "کمتر" : "بیشتر"
                            }
                        </span>
                    </div>
                </div>
                {
                    showProviderInfo &&
                    <div className={`m-2`}>
                        <ProviderInfo
                            info={order.provider}
                        />
                    </div>
                }
                {
                    order.needRating ?
                    <>                
                        <div className="d-flex flex-row w-100 justify-content-end mb-2 mt-2">
                            <h6 className="rtl">
                                امتیاز شما به این خدمت:
                            </h6>
                        </div>
                        <Rating 
                            value={rate} 
                            precision={0.5}
                            onChange={(event, rate) => {
                                rate === null?setRate(0):setRate(rate);
                            }}
                            size="large"
                            sx={{
                                color : "#ff5d1c"
                            }}
                        />
                        <div className="w-100">
                            <CpInput
                                plachodler={'ثبت نظر'} 
                                value={comment} 
                                type={"text"} 
                                onChange={(e) => {setComment(e.target.value)}}
                            />
                        </div>
                        <div className="w-50">
                            <SBotton
                                title={"ارسال نظر"} 
                                gradian
                                disabled={rate === null}
                                click={rate === null ?()=>false : registerComment}
                                sending={sending}
                            />
                        </div>
                    </>:
                    order.commentDate !== ""?
                    <>             
                        <div className="d-flex flex-row w-100 justify-content-end mb-2 mt-2">
                            <h6 className="rtl">
                                امتیاز شما به این خدمت:
                            </h6>
                        </div>
                        <Rating 
                            readOnly
                            value={order.rate * 0.5} 
                            precision={0.5}
                            size="large"
                            sx={{
                                color : "#ff5d1c"
                            }}
                        />

                        <p className="w-100 text-right mt-2 px-3">
                            {
                                order.comment
                            }
                        </p>
                    </>:
                    null
                }
            </div>
        }
        <Toast
            text={error.errTxt}
            type={error.errType}
        />
        </Layout>
    )
}

export default OrderDetails;
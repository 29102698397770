import React from 'react';
import classes from './Backdrop.module.css';

const Backdrop = props => {
  return props.show ? (
    <div
      style={{ backgroundColor: props.backgroundColor, position: props.position ? ''  : 'absolute' }}
      onClick={props.click}
      className={classes.Backdrop}
    ></div>
  ) : null;
};

export default Backdrop;

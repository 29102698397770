import React from 'react';
import { colors, formatMoney } from "../../../value";
import SBotton from '../../Buttons/SBotton/SBotton';
import Card from '../../Card/Card';
import Date from '../../Date/Date';
import Doer from '../../Doer/Doer';




const EventCard = props => {
    return (
        <Card onClick={props.click} width={'100%'} >
            <div className={`w-100 p-1 d-flex flex-row-reverse text-right align-items-start  justify-content-between`}>
                <div style={{ lineHeight: 2 }}>
                    <div className={` d-flex flex-row-rverse align-items-center`} >
                        {/* <div style={{ marginRight: 5 }}>
                            <Date date={props.startDate} font={10} />
                        </div> */}

                        <Doer creator={props.creator} profileImage={props.profileImage} />
                    </div>

                    <div className={`fntBold mediumF text-right `}>{props.title.substring(0,35)}{props.title.length>35 ? '...' : '' }</div>
                    <div className={` grey  rtl mediumF text-right`} > {props.description.substring(0,25)}{props.description.length>25 ? '...' : '' } </div>
                </div>
                <div style={{ lineHeight: 2 }}>
                <div style={{ marginRight: 5 }}>
                            <Date date={props.startDate} font={13} />
                        </div>
                    <div className={` d-flex flex-row-reverse    align-items-center`} >
                        <div className={`rtl fntBold `} style={{fontSize:12}}>ساعت شروع:</div>
                        <div className={`mediumF grey mr-2`}>{props.startTime}</div>
                    </div>
                    <div className={` d-flex flex-row-reverse    align-items-center`}>
                        <div className={`rtl fntBold `} style={{fontSize:12}}>ساعت پایان:</div>
                        <div className={`mediumF grey mr-2`}>{props.endTime}</div>
                    </div>
                   
                    {/* <SBotton title={'اضافه کردن به تقویم'} gradian={true} /> */}
                </div>
            </div>



        </Card>
    );
};

export default EventCard;
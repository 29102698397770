import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../../../Components/Layout/Layout';
import Toast from '../../../Components/Toast/Toast';



const SubmitVote = props => {
    const [loading, setLoading] = useState(true);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');

    
    useEffect(() => {

    }, [])
    
    return (
        <Layout loaded={true} title={'ثبت نظرسنجی'} isHome={false} showMenu={false} loading={loading} >
            <Toast
                text={errText}
                type={errType}
            />
        </Layout>
        )
}


export default withRouter(SubmitVote);
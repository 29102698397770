





import React from 'react'
import { Chart } from 'react-charts'
import ResizableBox from "./ResizableBox";
const MyChart = props => {


    const series = React.useMemo(
        () => ({
          type: props.type ? props.type : 'area',
          showPoints: false
        }),
        []
      )

      const data = React.useMemo(
        () => [
          {  
            label: 'Series 1',
            data:props.data
            
            
          },
       
        ],
        []
      )
      const getSeriesStyle = React.useCallback(
        (series) => ({
          color: `url(#${2})`,
          opacity:1
              
        }),
        []
      );

 
 
  const axes = React.useMemo(
    () => [
      { primary: true, type: 'ordinal', position: 'bottom' },
      {
        type: "linear",
        position: "left",
        stacked: true
    }
    ],
    []
  )
 
  return (
    // A react-chart hyper-responsively and continuously fills the available
    // space of its parent element automatically
    <div className={`fnt`}
      style={{
        width: '300px',
        height: '300px',
        
      }}
    >
       {/* <ResizableBox> */}
       <Chart data={data}  series={series} axes={axes}
      getSeriesStyle={getSeriesStyle}
       renderSVG={() => (
            <defs>
              <linearGradient id="2" x1="0" x2="0" y1="1" y2="0">
                <stop offset="0%" stopColor="#FFAB1B" />
                <stop offset="100%" stopColor="#ff5e1b" />
              </linearGradient>
            </defs>
          )} />
       {/* </ResizableBox> */}
      
    </div>
  )
}
export default MyChart;





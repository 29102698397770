import React, { useEffect, useState } from 'react';
import Layout from '../../Components/Layout/Layout';
import FilterModal2 from '../../Components/Modal/FilterModal2/FilterModal2';
import NewSelect from '../../Components/NewSelect/NewSelect';
import NoAccess from '../../Components/NoAccess/NoAccess';
import Toast from '../../Components/Toast/Toast';
import { ApiService, downloadPdf, formatMoney, getBuildingId } from '../../value';
import dec from '../../Assets/myAsset/bleachRed.svg';
import inc from '../../Assets/myAsset/bleachGrn.svg';
import searchImg from '../../Assets/myAsset/loupe.svg';
import UserDebtCard from '../../Components/Cards/UserDebtCard/UserDebtCard';
import EmptyList from '../../Components/EmptyList/EmptyList';
import { Checkbox } from '../../Components/CheckBox/CheckBox';
import ReceiptListBasedOnUser from '../Receipt/ReceiptListBasedOnUser/ReceiptListBasedOnUser';
import SearchBar from '../../Components/SearchBar/SearchBar';
import pdf from '../../Assets/myAsset/pdf.svg';





const UserStatePage = props => {
    const [loading, setLoading] = useState(true);
    const [errText, setErrText] = useState('');
    const [errType, setErrType] = useState('');
    const [type, setType] = useState(0);

    const [buildingList, setBuildingList] = useState([]);
    const [selectedBulding, setSelectedBulding] = useState([]);
    const [debtors, setDebtors] = useState([]);
    const [debtorsToShow, setDebtorsToShow] = useState([]);
    const [sum, setSum] = useState(0);
    const [search, setSearch] = useState(false);
    const [dwnldPdf, setDwnld] = useState(false)
    const [searchText, setSearchText] = useState('');
    // const [filterModal, setFilterModal] = useState(false);
    // const [filterData, setFilterData] = useState(null);


    const [noAcc, setNoAcc] = useState(false);



    const [debtModal, setDebtModal] = useState(false);
    const [userId, setuserId] = useState(null);


    // let userId = null






    useEffect(() => {
        if (localStorage.getItem('id')) {
            getDebtorsList()
        }
    }, [type]);

    useEffect(() => {
        if(dwnldPdf)
       { downloadPdfReport();}
      
   }, [dwnldPdf]);

    // useEffect(()=>{
    //     setDebtModal(true)
    // },[userId])


    const getDebtorsList = () => {
        setLoading(true);
        let url ='';
        if(type == 0){
            url=`/AccRequest/DebtorUsers/${getBuildingId()}/list`
        }else{
            url=`/AccRequest/CreditorUsers/${getBuildingId()}/list`
        }
        ApiService('Get', url)
            .then(response => {
                console.log(response);

                if (response.data.result) {
                    setBuildingList(response.data.data.buildings);
                    if (response.data.data.currentBuilding) {
                        setNoAcc(false);
                        setSelectedBulding(response.data.data.currentBuilding);
                        let sum = 0
                            response.data.data.users.forEach(element => {
                                sum += element.amount
                                
                            });
                            setDebtors(response.data.data.users);
                            setDebtorsToShow(response.data.data.users)
                            setSum(sum)
                        setLoading(false);
                    } else {
                        setNoAcc(true);
                        setLoading(false);
                    }

                } else {
                    setErrText(response.data.message);
                    setErrType('war');
                    clearErr();
                    setTimeout(() => {
                        props.history.push('/building');
                    }, 2000);
                }
            })
            .catch(err => {
                setErrText('خطای داخلی سرور');
                setErrType('err');
                setLoading(false);
                clearErr();
                return
            })

    }

    const downloadPdfReport = () => {
        setLoading(true);
        downloadPdf('get',`/AccRequest/${type == 0 ? 'DebtorUsers' : 'CreditorUsers'}/${localStorage.getItem('id')}/export`, null)
        .then(res => {
            setDwnld(false);
            setLoading(false);
        })
        .catch(err=>{
            setLoading(false);
            setErrText('درخواست با خطا مواجه شد ');
            setErrType('err');
            clearErr();


        })


        // axios.get(`https://api2.homtick.com/api/V1/AccRequest/${type == 0 ? 'DebtorUsers' : 'CreditorUsers'}/${localStorage.getItem('id')}/export`, {
        //     headers: {

        //         'Authorization': `bearer ${localStorage.getItem('token')}`,
        //         'Content-Type': 'application/json'

        //     },
        //     responseType: 'blob',
        // })
        //     .then(response => {
        //         console.log(response.data);
        //         const url = window.URL.createObjectURL(new Blob([response.data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'report.pdf');
        //         document.body.appendChild(link);
        //         link.click();
                
        //     })

    }


    const searchHandler = (text) => {
        if (text.length > 3) {
            let tmp = [];
            debtors.forEach(element => {
                if ((element.name ? element.name.search(text) : -1) >= 0 ) {
                    tmp.push(element);
                }
            });
            setDebtorsToShow(tmp)
        }
        else {
            setDebtorsToShow(debtors);

        }

    }


    const errorshow=(errtext,errtype)=>{
        setErrType(errtype);
        setErrText(errtext);
        clearErr();

    }


    const clearErr = () => {
        setTimeout(() => {
            setErrText('');
            setErrType('');
        }, 3000)
    }


    return (
        <Layout loaded={true} title={type==0 ?
            'لیست کاربران بدهکار ' : 'لیست کاربران بستانکار'} isHome={false} showMenu={true} loading={loading} displayMenu={!loading} customBack={true}
            path={'/'}>
            <Toast
                text={errText}
                type={errType}
            />
          
               
                <ReceiptListBasedOnUser  show={ debtModal } btn={true} click={()=>{setDebtModal(false)} } userId={userId}  showerr={errorshow}/>

           
            {/* <FilterModal2 show={filterModal} amount={true}
                sortInc={true}
                click={() => {
                    setFilterModal(false);
                    setFilterData(null);
                }}
                submit={(filterData) => {

                    setFilterData(filterData)
                    setFilterModal(false)
                }} /> */}

            <div style={{ height: 'inherit' }}>
                {noAcc ? <>

                    {buildingList.length > 0 ? <div className={`mt-2 mb-3`}>
                        <NewSelect options={buildingList}  
                            obj={false}
                            sel2={false}
                            select={selectedBulding ? true : false}
                            // desk={selectedBulding.cashDeskAmount}
                            label={'ساختمان خود را انتخاب کنید'}
                        >
                            <div>
                                {buildingList.map((item, index) => {
                                    return (
                                        <div key={index} className={`p-2 d-flex justify-content-between align-items-center flex-row-reverse`}
                                            onClick={() => {
                                                buildingList.find(elem => {
                                                    if (elem.id == item.id) {
                                                        localStorage.setItem('id', elem.id);
                                                        setSelectedBulding(elem);
                                                        getDebtorsList();
                                                        //setSelectedBulding(elem);

                                                    }
                                                })
                                            }}>
                                            <div className={`pr-2 fntBold mediumF grey`}>{item.name}</div>
                                            {item.cashDeskAmount ? <div className={`fntBold mediumF`} style={{ display: 'flex' }} >{formatMoney(item.cashDeskAmount)}
                                                {item.cashDeskAmount != 0 ?
                                                    <div style={{ width: '10px' }}>
                                                        <img style={{ width: '100%', marginLeft: 5 }}
                                                            src={item.cashDeskAmount > 0 ? inc : item.cashDeskAmount < 0 ? dec : null} alt={'alt'} />
                                                    </div>
                                                    : null}
                                            </div> : null}
                                        </div>
                                    )
                                })}
                            </div>
                        </NewSelect>
                    </div> : null}
                    <NoAccess Text={'شما دسترسی لازم برای ورود به این لیست را ندارید'}></NoAccess>

                </> : <>
                        {!noAcc && buildingList.length > 1 ?
                            <div className={`mt-2 mb-3`} >
                                <NewSelect options={buildingList}  obj={false} sel2={false} value={selectedBulding} >
                                    <div onClick={(e) => {
                                        buildingList.find(elem => {
                                            if (elem.id == e.target.value) {
                                                setSelectedBulding(elem);
                                                localStorage.setItem('id', elem.id);
                                            }
                                        })
                                    }}>
                                        {buildingList.map(item => {
                                            return (
                                                <div style={{ padding: '2%' }} key={item.id} value={item.id} onClick={() => {
                                                    buildingList.find(elem => {
                                                        if (elem.id == item.id) {
                                                            localStorage.setItem('id', elem.id);
                                                            setSelectedBulding(elem);
                                                            getDebtorsList();

                                                            //  getRuleList( elem.id)

                                                        }
                                                    })
                                                }}>{item.name}</div>
                                            )
                                        })
                                        }
                                    </div>

                                </NewSelect>
                            </div>
                            :
                            buildingList.length == 1 ?
                                <div className={`w-100 text-right pt-1 pb-1 pr-4 mt-2 mb-2 single`}>
                                    {buildingList[0].name}
                                </div>
                                : null
                        }
                    


                    <div className={`d-flex pt-2 pb-2 p-1 justify-content-between align-items-center backGreyLight flex-row-reverse mb-3`}>
                            <div className={`rtl text-right mediumF `}>{type==0 ? 'جمع کل بدهکاری'
                            
                              : 'جمع کل بستانکاری'}</div>
                            <div className={`d-flex align-items-center`} style={{ color: '#757575' }}>
                                <div style={{ fontSize: 10, marginRight: 3 }}>ریال</div>
                                <div style={{ fontSize: 12, }}> {formatMoney(sum)}</div>
                                <div style={{ width: 20 }}>
                                    <img style={{ width: '50%', marginLeft: 5, marginBottom: 5 }} src={type == 0 ?dec : inc} alt={'alt'} />
                                </div>

                            </div>
                        </div>


                        <div className={`d-flex justify-content-between align-items-center m-2 p-2 borderShadow rtl`} style={{ border: 1, borderRadius: 10 }}>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Type={'Radio'} Label={'بدهکار '} checked={type == 0} textStyle={{ fontSize: '10px' }}
                                    onChange={checked => {
                                        if (checked) {

                                            setType(0);
                                            setDebtors([])

                                        }
                                    }} Name={'dec'} />
                            </div>
                            <div className={`rtl`} style={{ width: '45%' }}>
                                <Checkbox Type={'Radio'} Label={'بستانکار '} textStyle={{ fontSize: '10px' }}
                                    checked={type == 1}
                                    Name={'dec'} onChange={checked => {
                                        if (checked) {
                                            setType(1); 
                                            setDebtors([])
                                        }
                                    }} />
                            </div>
                        </div>




                    <>
                            {debtors.length > 0 ? 
                              <div className={`d-flex align-items-center pl-2 pr-2`}>
                              <div style={{width:25}} onClick={()=>{
                                  setDwnld(true)
                              }}>
                                  <img className={`w-100`} src={pdf}/>
                              </div>
                            <SearchBar
                                click={() => {
                                    if (search) {
                                        setSearchText('');
                                        setSearch(false);
                                        searchHandler('');


                                    } else {
                                        setSearch(true);
                                    }
                                }}
                           
                            />
                            </div> : null}
                            {search ?
                                <div className={`d-flex flex-row-reverse justify-content-between align-items-center mt-1 mb-2  w-100`}>
                                    <div className={`d-flex flex-row-reverse justify-content-between mt-1 mb-2  w-100`}
                                        style={{
                                            padding: 5,
                                            border: ' 1px solid grey',
                                            borderRadius: 10
                                        }}>
                                        <input type={'text'} style={{ width: '100%', border: 0, textAlign: 'right', direction: 'rtl' }}
                                            onKeyUp={event => {

                                                if (event.key === 'Enter') {
                                                    //  getBuildingUnits(localStorage.getItem('id'), searchText)
                                                }
                                            }}
                                            placeholder={'جستجو'}
                                            onChange={(e) => {

                                                searchHandler(e.target.value);


                                            }} />
                                        <div style={{ width: 15, }}>
                                            <img className={`w-100`} src={searchImg} alt={'alt'} />
                                        </div>

                                    </div>
                                </div>
                                : null}
                                {debtorsToShow.length > 0 ?
                            <>
                                <div style={{ height: `calc(100% - 10rem)`, overflow: 'auto', paddingRight: 3, paddingLeft: 3 }}>
                                    <>
                                        {debtorsToShow.map((item, index) => {                                       
                                            return <UserDebtCard key={index} item={item} type={type}
                                             click={()=>{
                                                 console.log('sfafa');
                                                setuserId(item.id);
                                               //  userId = item.id
                                                setDebtModal(true);
                                            }}
                                            />
                                        })}

                                    </>
                                </div>
                            </> :

                            <EmptyList des={type==0?
                                'واحد بدهکار وجود ندارد':
                            'واحد بستانکار وجود ندارد'} />

                        }
                        </>
                        </>}

            </div>
        </Layout>)


}

export default UserStatePage;
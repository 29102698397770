import React from 'react';





const Date = props => {
    return (
        <div className={`d-flex flex-row-reverse flex-start w-100 grey`} style={{ fontSize: props.font ? props.font : 8, color:props.color? props.color  : 'grey' }}>
            <div style={{
               // width:80
                }} className={`ml-2 mr-2`}>{props.date}</div>
            <div>{props.time}</div>
        </div>
    );
};

export default Date;
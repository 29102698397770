import React from 'react';
import NavigationItem from '../NavigationItem/NavigationItem';


const NavigationItems = props => {
    return(
        <>
            {props.items.map(item => {
            return(
                <div key={item.id} style={{textAlign:'right'}}>
                <NavigationItem  single={props.single} link={item.link}  >{item.lable}</NavigationItem>
             </div>
            )
        })}
        </>
    )
   
        
       
    
}
export default NavigationItems;
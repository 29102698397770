import React, {Component} from 'react';
import { colors} from "../../value";
import checked from '../../Assets/myAsset/check.png';
import check from '../../Assets/myAsset/checked.svg'
let lastObj = [];

export class Checkbox extends Component {

    constructor(props) {
        super(props);
        this.Name=props.Name || "name";
        this.state = {
            checked: this.props.checked
        };
    }

    getName() {
        return this.props.Name;
    }

    unCheck() {
        this.setState({
            checked: false
        })
    }

    Checked(check = true) {
        if (this.props.Type === 'Radio')
            lastObj[this.Name] && lastObj[this.Name] !== this && lastObj[this.Name].unCheck();
        lastObj[this.Name] = this;
        this.setState({
            checked: check
        }, () => {
            this.props.onChange && this.props.onChange(check);
        });

    }

    getChecked() {
        return this.state.checked
    }

    componentWillUnmount() {
       lastObj[this.Name]=undefined;
    }

    componentWillReceiveProps(nProps) {
        if (this.props.checked !== nProps.checked) {
            this.setState({
                checked: nProps.checked
            });
        }
    }


    render() {
        const {Label, Type = '', onChange, style, textStyle, color = colors.app8, disabled = false,UnCheckable=false,checkedColor=colors.app2 } = this.props;
        if (this.props.checked && this.props.Type === "Radio" && !lastObj[this.Name ]) {
            lastObj[this.Name] = this;
        }
        return (
            <div style={{
                width:'fit-content',
                direction:'rtl'
                
            }}  onClick={(e) => {
                if (disabled) return;
                if (Type === 'Radio') {
                    if (UnCheckable || !this.state.checked){
                        lastObj[this.Name] && lastObj[this.Name ] !== this && lastObj[this.Name ].unCheck();
                        lastObj[this.Name ] = this;
                        this.setState(state => ({
                            checked: !state.checked
                        }));
                        onChange && onChange(!this.state.checked, this.Name);
                    }
                } else {
                    this.setState(state => ({
                        checked: !state.checked
                    }));
                    onChange && onChange(!this.state.checked, this.Name);
                }

                e.stopPropagation();
            }}>
                <div className={`text-right `}>{this.props.title2}</div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    ...style,
                    cursor: 'pointer',
                    marginBottom: this.props.marginB ? this.props.marginB : '10px'
                }}>
                    <div style={{
                        width: 20,
                        height: 20,
                        borderRadius: Type === 'Radio' ?100 : 5,
                        display: 'flex',
                        direction:'rtl',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 5,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: checkedColor,
                        backgroundColor: this.state.checked ? checkedColor : '#FFF'
                    }}>
                        {this.state.checked ? <img style={{
                            width: 12,
                            height: 12,
                        }} src={checkedColor == '#fff' ? check :  checked} alt={''}/> : null}
                    </div>
                    <span
                        style={{
                            width: 'fit-content',
                            textAlign: 'right',
                            marginRight: 2,
                            fontSize: this.props.fontSize ? this.props.fontSize : 14,
                            color: this.props.color1 ? this.props.color1 : '#000',
                            ...textStyle
                        }}>{Label}</span>
                </div>
            </div>
        )
    }
}
import React, { useState } from 'react';
import  abutUs  from "../../Assets/myAsset/abutUs.png";
import  instagram  from "../../Assets/myAsset/instagram-2.png";
import  telegram  from "../../Assets/myAsset/telegram-2.png";
import  twitter  from "../../Assets/myAsset/twitter-2.png";
import Layout from '../../Components/Layout/Layout';
import SBotton from '../Buttons/SBotton/SBotton';

// import GradientButton from '../Buttons/GradientButton/GradientButton';

const AboutUs = props => {

  const [loading, setLoading] = useState(false);

  return (

    <Layout title={' درباره ما '} isHome={false}
      loaded={true} loading={loading}
      displayMenu={!loading} showMenu={true}
      customBack={true}
      path={'/'} >

      <div style={{
        direction: 'rtl',
        fontSize: 13,
        textAlign: 'justify',
        padding: 10,
        height: '90vh',
        overflow: ' auto',
      }}>





        <div style={{ textAlign: "justify" }}>
          <div style={{
            textAlign: 'center',
            padding: '25px 0'
          }}>
            <img style={{ width: '35%' }} src={abutUs} alt="" />
          </div>
          <div style={{ fontSize: 14, lineHeight: '24px' }}>
            <p>هومتیک یکی از محصولات پلتفرمی شرکت کمک یار اندیشه سبز است که در سال 1398 توسط یک تیم جوان و
            خبره طراحی و پیاده سازی شده است.
            هومتیک اولین پلتفرم مدیریت یکپارچه ساختمان است که با هدف آسان‌سازی انجام امور مربوط به
            مدیریت
            ساختمان و همچنین حفظ آسایش ساکنین فعالیت خود را آغاز کرده است. ما تلاش می‌کنیم تا با تکیه بر
            نیروی انسانی کاردان و سیستم هوشمند کنترل و هماهنگی امور ساختمان تغییری بزرگ و تاثیرگذار در
                            مدیریت ساختمان‌ها ایجاد کنیم.</p>
            <h2 className='font-weight-bold' style={{ fontSize: 14 }}>مهم‌ترین هدف‌های ما در هومتیک :</h2>
            <span>- از بین بردن دغدغه‌های مربوط به مساله‌های مالی صندوق ساختمان</span><br />
            <span>- ایجاد اشتغال برای کارشناسان فنی</span><br />
            <span>- ایجاد تحول در زمینه ارتباط ساکنین با یکدیگر و با مدیر ساختمان</span><br />
            <span>- ایجاد آسایش بیشتر برای ساکنین و مدیر ساختمان</span><br />
            <span>- صرفه جویی در وقت و هزینه</span><br />
            <br />
            <p>ما در هومتیک از تمام کارشناسان گواهی عدم سوء‌پیشینه و عدم اعتیاد دریافت می‌کنیم و بعد از
            مصاحبه‌های حضوری و بررسی نتیجه این مصاحبه‌ها به آن‌ها اجازه فعالیت می‌دهیم. همچنین با
            راه‌اندازی سیستم ارزیابی و امتیازدهی توسط سفارش‌دهندگان این امکان را داریم که به صورت مداوم
            کیفیت انجام کار کارشناسان را بررسی کنیم و در صورت نیاز به بهبود این روند بپردازیم. امتیازی
                            که شما ثبت می‌کنید بزرگ‌ترین کمک به ما برای ارائه خدمات با کیفیت بالا است.</p>
          </div>
          <div className='w-50 d-flex justify-content-around mx-auto'>
            {/* <a target="_blank" href={this.state.set.Aparat}>
              <img alt={"Aparat"} src={BaseUrl + 'imgs/aparat-2.png'} style={{ width: 25 }} /></a> */}
            <a target="_blank" href={'https://www.instagram.com/homtick.ir'}>
              <img alt={"Instagram"} src={instagram} style={{ width: 25 }} /></a>
            <a target="_blank" href={'https://t.me/homtick'}>
              <img alt={"Telegram"} src={telegram} style={{ width: 25 }} /></a>
            {/* <a target="_blank" href={'https://t.me/homtick'}>
              <img alt={"FaceBook"} src={twitter} style={{ width: 25 }} /></a> */}
          </div>
          <div style={{ padding: '30px 0 0 0' }}>
            <a href={'tel: 02188730542' }>
              <SBotton
                title={'با ما تماس بگیرید'}>

              </SBotton>
            </a>
          </div>
        </div>







      </div>

    </Layout>

  );
}

export default AboutUs;

import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import SBotton from '../../Buttons/SBotton/SBotton';
import { Checkbox } from '../../CheckBox/CheckBox';
import { ApiService, formatMoney } from '../../../value';



const MessageModal = props => {


    const [sending, setSending] = useState(false);

    const [messageText, setMessageText] = useState({})
    const [MessageData, setMessageData] = useState(props.data ? props.data : {
        token:null,
        userRoleInUnit: props.role ? props.role : 1,
        messageType: 2,
        isValidMessage: false,
       

    });

    useEffect(()=>{
        if(!props.isClose){
            setMessageData({
                token:null,
                userRoleInUnit: props.role? props.role : 1,
                messageType: 2,
                isValidMessage: false,
                text : props.defaultText? props.defaultText : ''
               
        
            });
            setMessageText(props.defaultText? props.defaultText : '')
        }
    },[props.isClose])


    const sendMessage = (data) => {
        console.log('1', MessageData);


        if(data){
            console.log('2', data);
        if (!data.text || data.text.length == 0) {
            props.onErr('متن پیام را وارد کنید', 'war')
            return

        }

        if (!data.messageType) {
            props.onErr('متن پیام را وارد کنید', 'war')
        }

       


        setSending(true);
        let data1 = data;
        data1.units = props.selectedUnit;
        data1.metaData = props.ReceiptId ? `${props.ReceiptId}` : '';
        console.log('=-=-=-=-==', data1);
        
        ApiService('Post', `/Message/Send/${localStorage.getItem('id')}`, data1)
            .then(response => {
                console.log(response);
                if (response.data.result && response.data.resultCode == 200) {
                    setMessageData(response.data.data);
                    setSending(false);
                }
                else if (response.data.result && response.data.resultCode == 100) {
                    setSending(false);
                    props.onClose();
                    setMessageData({
                        // messageType: 0,
                        // userRoleInUnit: 1,
                        // text: ''
                    });
                   // setselectedUnitForMess([]);
                   // setSendMess(false);
                   props.onErr(response.data.message, 'suc')
                } else {
                    props.onErr(response.data.message, 'war')
                }
            })

        }
    }
    const patternClicked = (e)=>{
        console.log(e.target.innerText.split(":")[1].trim())
        setMessageText(`${messageText} ${e.target.innerText.split(":")[1].trim()} `.trim())
    }
    // ارسال پیام برای {props.selectedUnit.length} واحد
    return (
        <Modal    show={props.show} btn={true} click={props.click}   >
            <div  style={{  height:'70vh', overflow:'auto' }}>
                        <div className={`text-right rtl fntBold `} style={{ fontSize: 12 }} >{props.text}</div>
                        <div className={`w-100 mt-2 mb-2 text-center`}>



                           {props.type ? <div className={`text-right rtl  w-75 mx-auto `}>
                                <Checkbox Type={'Radio'} disabled={MessageData.token ? true : false} textStyle={{ fontSize: '10px' }}
                                    checked={MessageData.userRoleInUnit === 1} Label={' ارسال پیام به ساکنین'}
                                    onChange={checked => {
                                        if (checked) {
                                            let tmp = MessageData;
                                            tmp.userRoleInUnit = 1;
                                            setMessageData(tmp);
                                        }
                                    }} />
                                <Checkbox Type={'Radio'} disabled={MessageData.token ? true : false} textStyle={{ fontSize: '10px' }}
                                    checked={MessageData.userRoleInUnit === 2} Label={'ارسال پیام به مالکین'}
                                    onChange={checked => {
                                        if (checked) {
                                            let tmp = MessageData;
                                            tmp.userRoleInUnit = 2;
                                            setMessageData(tmp);
                                        }
                                    }} />
                            </div> : null}
                            <div className={`text-right rtl  w-75 mx-auto pt-2 pb-2 `} style={{ borderTop: '1px solid grey' }}>
                                <div className={`text-right rtl mediumF mb-2`}>انتخاب نوع پیام:</div>
                                <Checkbox Type={'Radio'} textStyle={{ fontSize: '10px' }} disabled={MessageData.token ? true : false}
                                    checked={(MessageData.messageType === 0)} Label={'ارسال با اولویت نوتیفیکیشن'} Name={'stype'}
                                    onChange={checked => {

                                        //console.log('1',MessageData);
                                        if (checked) {
                                            let tmp = MessageData;
                                            tmp.messageType = 0;
                                            setMessageData(tmp);
                                        }
                                    }} />
                                <Checkbox Type={'Radio'} textStyle={{ fontSize: '10px' }} disabled={MessageData.token ? true : false}
                                    checked={MessageData.messageType === 1} Label={'نوتیفیکیشن'} Name={'stype'}
                                    onChange={checked => {
                                        //console.log('2',MessageData);
                                        if (checked) {
                                            let tmp = MessageData;
                                            tmp.messageType = 1;
                                            setMessageData(tmp);
                                        }
                                    }} />
                                <Checkbox Type={'Radio'} textStyle={{ fontSize: '10px' }} disabled={MessageData.token ? true : false}
                                    checked={MessageData.messageType === 2} Label={'پیامک'} Name={'stype'}
                                    onChange={checked => {
                                        if (checked) {
                                            let tmp = MessageData;
                                            console.log('3', MessageData);
                                            tmp.messageType = 2;
                                            setMessageData(tmp);
                                        }
                                    }} />
                            </div>
                            <textarea maxlength='700'  disabled={MessageData.token ? true : false}
                                className={`w-100 rtl text-right`}
                                style={{
                                    border: '1px solid grey', borderRadius: 10,
                                    padding: 10, fontSize: 15, textAlign: 'right', height: 100
                                }}
                                placeholder={'متن پیام'}
                                value={messageText}
                                onChange={(e) => {

                                    let tmp = MessageData;
                                    tmp.text = e.target.value;

                                    setMessageData(tmp);
                                    setMessageText(e.target.value)
                                }} />

<div>
                                <div className={`text-right rtl fntBold mb-2 `} style={{ fontSize: 12 }} >راهنمای متن پیام:</div>
                                <div className={`text-right   `} style={{ fontSize: 14,lineHeight:2 }}>
                                    
                                        { props.guide.map((i,j) =>{
                                            return (<div  onClick={patternClicked}>{i} </div> )} )}
                                    
                                   {/* <div > نام واحد   : [vahed]  </div>
                                   <div>  نام ساکن/مالک   :    [name]  </div>
                                   <div> مبلغ قابل پرداخت در فیش   :    [amount]  </div>
                                   <div > بدهی کل شخص   : [bedehi]  </div> */}
                                    </div>
                            </div>


                            <div className={`text-center rtl mediumF fntBold`} 
                            style={{ color: MessageData.validationMessage && MessageData.validationMessage.length>0 ? 'salmon' : '' }}>
                            {MessageData.validationMessage && MessageData.validationMessage.length>0 ? MessageData.validationMessage : MessageData.infoMessage }
                               </div>
                        </div>
                        <div className={`w-75 mx-auto`}>

                            <SBotton gradian 
                            sending={sending} 
                            title={MessageData.isValidMessage ? ' ارسال با هزینه' + formatMoney(MessageData.cost) + 'ریال' : 'تایید پیام'} 
                            click={() => {
                                sendMessage(MessageData)
                            }} />
                        </div>
                    </div>
        </Modal>
    )
}
export default MessageModal;
import React from 'react';
import { formatMoney } from "../../../value";
import Date from '../../Date/Date';
import Card from '../../Card/Card';
import done from '../../../Assets/myAsset/done-1.svg';





const TransactionCard = props => {
    return (
        <Card width={'100%'} >
            <div className={`w-100 p-2`} style={{ lineHeight: 2 }}>
                <div className={`d-flex justify-content-between flex-row-reverse align-items-center  `} style={{ fontSize: 12 }} >
                    <div className={`d-flex  flex-row-reverse  `}>
                        <div className={`rtl ml-2 text-right`}>پرداخت بابت:</div>
                        <div className={`rtl text-right `} style={{ fontSize: 11 }}> {props.itemInfo.subject}</div>

                    </div>
                    <div>
                        <Date date={props.itemInfo.dateCreated} font={11} />

                    </div>


                </div>
                <div className={`d-flex flex-row-reverse align-items-center `}>
                    {/* <Date date={props.itemInfo.dateCreated} font={11} /> */}
                    <div className={`d-flex align-items-center justify-content-between w-100 `}>

                        <div className={`customBorderR  d-flex align-items-center justify-content-between pr-2`} style={{ backgroundColor: '#f7f7f7' }}>
                            <div style={{ width: 30 }} className={`mr-2`}>
                                <img className={`w-100 p-1`} src={done} alt={'alt'} />
                            </div>
                            {formatMoney(props.itemInfo.amount)}
                        </div>
                        <div className={`smallF mr-1 ml-2`}>{
                            props.itemInfo.paidMethod == 0 ? '0' :
                                props.itemInfo.paidMethod == 1 ? 'آنلاین' :
                                    props.itemInfo.paidMethod == 2 ? 'نقدی' :
                                        props.itemInfo.paidMethod == 3 ? 'کیف‌پول' :
                                            props.itemInfo.paidMethod == 4 ? 'کارت‌به‌کارت' :
                                                props.itemInfo.paidMethod == 5 ? 'بستانکاری' :
                                                    props.itemInfo.paidMethod == 6 ? ':چک' :
                                                        props.itemInfo.paidMethod == 7 ? ':انتقال بدهی' :
                                                            ''

                        }</div>
                    </div>
                </div>

            </div>
        </Card>
    );
};

export default TransactionCard;
import React from 'react';
import Card from '../Card/Card';
import serviceImg from '../../Assets/myAsset/customer-service.svg';
import classes from "./RadioImage.module.css"


const RadioImage = ({service , onClick}) => {
    return (
        <div className='col-6 mt-3' style={{height : "180px"}} onClick={onClick}>
            <Card width={"100%"} height={"180px"} >
                <div className={`m-3 text-center w-100`}>
                    <img 
                        style={{ 
                            width: "80%",
                            height : "90px"

                        }}
                        src={service.icon?service.icon.thumbnileImagePath:serviceImg}
                    />
                    <div  className={`rtl mt-3 overflow-hidden ${classes.text}`}>{service.name}</div>
                </div>
            </Card>
        </div>
    )
}
export default RadioImage;
import React, { useEffect, useState } from 'react';
import classes from '../Login/Login.module.css';
import logo from '../../../Assets/myAsset/LOGO-HOMTICK.png';
import SInput from '../../../Components/Input/SInput';
import BaseBX from "../../../Components/BaseBx/BaseBx";
import { withRouter } from 'react-router-dom';
import loadingGif from '../../../Assets/gifs/22.gif'

import axios from 'axios';


const ConfirmCode = props => {
    const [mobileNum, setMobileNum] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deviceCode, setDeviceCode] = useState(null);

    const [reCodeBtn, setReCodeBtn] = useState(false);
    const [token, setToken] = useState('');


    const [confirmCode, setConfirmCode] = useState(null);
    const [codeError, setCodeError] = useState(false);
    const [codeTime, setCodeCodeTime] = useState(localStorage.getItem('codeTime'));
    const [countDownMin, setCountDownMin] = useState(0);
    const [countDownSec, setCountDownSec] = useState(0);

    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }


    useEffect(() => {
        if (props.location.state) {
            setMobileNum(props.location.state.mobileNum);
            setDeviceCode(props.location.state.deviceCode);
            counterHandler();
        } else {
            props.history.push({
                pathname: '/login'
            })
        }
    }, [codeTime]);
    useEffect(()=>{
        
        if(token.length>0)
        
        props.history.push({
            pathname: '/'
        });

    }, [token])


    const counterHandler = () => {

        let now = new Date().getTime();
        let counter = now - codeTime;
        if (counter > 120000) {
            setCountDownMin(0);
            setCountDownSec(0)
        } else {
            counter = Math.floor((120000 - counter) / 1000);
            let handler = setInterval(() => {
                if (counter > 0) {
                    counter = counter - 1;
                    let seconds = Math.floor(counter % 60);
                    let minutes = Math.floor(counter / 60);
                    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;
                    seconds = seconds.toString().length === 1 ? '0' + seconds : seconds;
                    setCountDownMin(minutes);
                    setCountDownSec(seconds);
                } else {
                    setCountDownMin(0);
                    setCountDownSec(0);
                    clearInterval(handler);
                }
            }, 1000)
        }

    }
    const resendCode = () => {

        let data = {
            mobileOrEmail: mobileNum,
            deviceCode: deviceCode,
            firstName: '',
            lastName: '',
            password: ''
        }
        if (countDownMin == 0 && countDownSec == 0) {
            setReCodeBtn(true);
            axios.post('https://auth3.homtick.com/api/V1/User/GetVerifyCode', data, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(response => {
                console.log(response);
                if (response.data.result) {
                    setReCodeBtn(false);
                    localStorage.setItem('codeTime', new Date().getTime());
                    setCodeCodeTime(new Date().getTime());
                    // window.location.reload();
                    counterHandler();

                } else {
                    console.log(response);
                }
            })
                .catch(err => {
                    console.log(err.response);
                });

        }

    }

    const onChangeHandler = (e) => {
        setConfirmCode(parseInt(e.target.value))
    }


    const sendConfirmCode = () => {
        setLoading(true)
        let data = `grant_type=phone_number_token&userName=${mobileNum}&scope=openid%20offline_access%20HomTick.Apis&client_id=HomTickMobileClient&client_secret=4f5b555b83f844ef8d8022646a6fb4c2-HomTickMobileSecret&response_type=code%20id_token&verification_token=${confirmCode}&deviceCode=${deviceCode}`;
        console.log('data', data);
        axios.post('https://auth3.homtick.com/connect/token', data, config)
            .then(res => {
                console.log('sendConfirmCode:::::::::::::;',res);
                if (res.data.access_token) {
                   localStorage.setItem('token', res.data.access_token);
                   localStorage.setItem('ref', res.data.refresh_token);
                  setToken(res.data.access_token)
                  window.location.href='/'
                                // props.history.push({
                                //     pathname: '/'
                                // });
                                //  window.location.reload();





                    // axios.post('https://api2.homtick.com/api/V1/User/Profile', null, {
                    //     headers: {
                    //         'Authorization': `bearer ${res.data.access_token}`,
                    //         'Content-Type': 'application/json',
                    //     }
                    // })
                    //     .then(response => {
                    //         console.log(response);
                    //         //window.location.reload();
                    //         // props.history.push({
                    //         //     pathname: '/info'
                    //         // })
                    //         if (response.data.data.name.length < 1 && response.data.data.lastName.length < 1) {
                                 

                    //             props.history.push({
                    //                 pathname: '/info'
                    //             })
                    //         } else {
                    //             props.history.push({
                    //                 pathname: '/'
                    //             });
                    //             window.location.reload();
                    //         }


                      //  })
                        // .catch(err => {
                        //     console.log(err.response);
                        //     console.log(err.request);
                        // })
                } else {
                    console.log('eshtebah');
                }

            })
            .catch(err => {
                setCodeError(true);
                setLoading(false)
                
                // console.log('catch error', err.request);
                // console.log('catch error', err.response);
            });
    }
    return (
        <BaseBX>
           <div className={`${classes.imgBack} `} style={{ height: '100vh', minHeight: 600, overflow: "hidden" }}>
                 <div className={`d-flex flex-column align-content-between justify-content-between align-items-center h-100  `}>
                    <div className={`d-flex flex-column align-content-around justify-content-around align-items-center p-4 w-100  `} style={{ height: '45%' }}>
                        <img src={logo} style={{ width: '60%' }} />
                        <div className={`text-light mediumF text-center rtl `}>لطفا کد چهار رقمی ارسال شده به  {mobileNum}  را وارد کن</div>
                        <div className={` d-flex w-75 mx-auto justify-content-center align-items-center`}>
                            <SInput prefix={false} type={'number'} error={codeError} id='first'
                             onChange={(event) => { onChangeHandler(event) }}
                             maxLength={'4'} 
                             onKeyUp={(event) => {
                                console.log('11111');

                                if (event.key === 'Enter') {
                                    sendConfirmCode() 
                                }
                            }}/>


                        </div>
                        {!codeError ? null :
                            <div className={`text-dark mediumF text-center rtl `}>کد وارد شده اشتباه است دوباره تلاش کن</div>
                        }
                        <div onClick={() => { resendCode() }} className={`text-light mediumF text-center rtl `}>
                            {
                                reCodeBtn ?
                                    <img src={loadingGif} style={{ width: '30%', margin: ' 0 auto',height:'100%' }} /> :
                                    <span> ارسال دوباره کد در {`${countDownSec} : ${countDownMin}`}</span>
                            }
                        </div>


                    </div>
                    <div className={`d-flex flex-column align-content-around justify-content-center align-items-center p-4 w-100   `} style={{ height: '40%' }}>
                        <div onClick={() => { sendConfirmCode() }} className={`pl-3 pr-3 pt-2 pb-2 text-light w-50 mx-auto text-center fntBold `}
                             style={{ backgroundColor: !loading ? '#FF3F3F' : '#cdcdcd', borderRadius: 25, fontSize: 20 }}>
                             {!loading ?
                                'ورود'
                                :  <div style={{ width: '20%',marginLeft:'40%'}}><img src={loadingGif} style={{ width: '100%', margin: ' 0 auto' }} /></div>
                            }
                        </div>
                        <div className={`grey smallF  rtl`} onClick={()=>{
                             props.history.push({
                                pathname: '/login'
                            })
                        }} style={{ marginTop: '10%' }}> شماره اشتباهه؟ </div>
                    </div>
                </div>

            </div>
        </BaseBX>

    )
}
export default withRouter(ConfirmCode);
